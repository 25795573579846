<main class="text-center">
  <section class="text-5xl" *ngIf="shouldShowIcon">
    <i *ngIf="transaction.status === TransactionStatus.PENDING; else transactionIconTemplate" class="fa fa-spinner fa-pulse"></i>
    <ng-template #transactionIconTemplate>
      <img *ngIf="transaction.type === TransactionType.TRANSFER && transaction.recipientAccountId == selectedAccount?.id; else iconTypeTemplate" src="./assets/icons/receive.png" class="w-12" />
      <ng-template #iconTypeTemplate>
        <i [class]="transaction | transactionIcon: selectedAccount"></i>
      </ng-template>
    </ng-template>
  </section>

  <section>
    <span class="font-bold text-lg block">{{ transaction | transactionText: selectedAccount }}</span>
    <span class="text-xs font-bold">{{ transaction | transactionTarget: selectedAccount }}</span>
  </section>

  <div class="text-sm text-grey-dark mt-2">Created on: {{ transaction.created | date: 'medium' }}</div>

  <section class="text-lg">
    <div class="flex mt-2 pt-2">
      <div>Status</div>
      <div class="flex-1 text-right font-bold" [class.text-error]="transaction.status === TransactionStatus.ERROR">
        {{ transaction.status | replace : '_' : ' ' | titlecase }}
      </div>
    </div>

    <div *ngIf="transaction.statusMessage" class="flex border-top mt-2 pt-2">
      <div>Status Message:</div>
      <div class="flex-1 text-right font-bold">
        {{ transaction.statusMessage }}
      </div>
    </div>

    <div *ngIf="transaction.token">
      <div class="flex border-top mt-2 pt-2">
        <div>Amount</div>
        <div class="flex-1 text-right font-bold">
          <span>{{ transaction.type === TransactionType.SELL || (transaction.type === TransactionType.TRANSFER && transaction.accountId === selectedAccount?.id) ? '-' : '+' }}</span>
          {{ transaction.fiatAmount | currency }}
        </div>
      </div>

      <div class="flex border-top mt-2 pt-2">
        <div>Tokens</div>
        <div class="flex-1 text-right font-bold">
          <span>{{ transaction.type === TransactionType.SELL || (transaction.type === TransactionType.TRANSFER && transaction.accountId === selectedAccount?.id) ? '-' : '+' }}</span>
          {{ transaction.amount.toFixed(6) }} {{ transaction.token.symbol }}
        </div>
      </div>

      <div class="flex border-top mt-2 pt-2" *ngIf="processingFee > 0 && (transaction.type === TransactionType.TRANSFER && transaction.accountId === selectedAccount?.id)">
        <div>Fee</div>
        <div class="flex-1 text-right font-bold">- {{ processingFee | currency }}</div>
      </div>

      <div class="flex border-top mt-2 pt-2" *ngIf="processingFee > 0 && (transaction.type === TransactionType.TRANSFER && transaction.accountId === selectedAccount?.id)">
        <div>Total</div>
        <div class="flex-1 text-right font-bold">- {{ total | currency }}</div>
      </div>
    </div>

    <div *ngIf="transaction.type === TransactionType.WIRE">
      <div class="flex border-top mt-2 pt-2">
        <div>Amount</div>
        <div class="flex-1 text-right font-bold">
          {{ transaction.fiatAmount | currency }}
        </div>
      </div>
    </div>


    <div class="flex border-top mt-2 pt-2" *ngIf="selectedAccount.shared">
      <div>Initiated by</div>
      <div class="flex-1 text-right font-bold">{{ transaction.user.firstName }} {{ transaction.user.lastName }}</div>
    </div>

    <div class="flex border-top mt-2 pt-2" *ngIf="transaction.memo">
      <div>Memo</div>
      <div class="flex-1 text-right font-bold">
        {{ transaction.memo }}
      </div>
    </div>

    <div *ngIf="transaction.signatures.length > 0" class="flex border-top mt-2 pt-2">
      <div>Signed By:</div>
      <div class="flex-1 text-right font-bold">
        <span *ngFor="let signature of transaction.signatures" class="block">
          {{ signature.type === SignatureType.VESTO ? 'Vesto' : signature.user?.firstName + ' ' + signature.user?.lastName }}
        </span>
      </div>
    </div>

    <div class="flex border-top mt-2 pt-2" *ngIf="transaction.url">
      <div>View on {{ transaction.blockchain | titlecase }}</div>
      <div class="flex-1 text-right font-bold">
        <a href="{{ transaction.url }}" target="_blank"><img [src]="transaction?.blockchain === 'ETHEREUM' ? './assets/icons/ethereum.svg' : './assets/icons/polygon-icon.png'" [style.width]="transaction?.blockchain === 'ETHEREUM' ? '10px' : '16px'" class="mr-2 -mt-1" /></a>
      </div>
    </div>

    <div *ngIf="transaction.status === TransactionStatus.TRANSFERRING_TO_VESTO && transaction.type === TransactionType.BUY && transaction.token.ceFi" class="text-left border-top mt-2 pt-2">
      <p>
        Wiring instructions<br>
        <span class="italic text-xs">A copy of these instructions has been sent to {{ user?.email }}</span>
      </p>
      <p class="mt-2 text-sm">
        Burling Bank<br>
        141 W. Jackson Blvd.<br>
        Chicago, IL 60604<br>
        United States<br>
        Beneficiary: Vesto LLC<br>
        Tel: +1 (312) 408-8400<br>
        ABA #: 071005254<br>
        Account #: 128414<br>
      </p>
      <p class="mt-2 text-xs">Wiring from outside the US? Please click the chat icon in the lower right screen for support or contact us at <a href="mailto:support@vesto.io" class="underline">support@vesto.io</a></p>
    </div>
  </section>
</main>

<gbl-alert type="error" [message]="transaction.error"></gbl-alert>
