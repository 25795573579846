import {Component, OnInit} from '@angular/core';
import { BaseComponent, CoreService, StorageKeys, uiCloseModal } from '@vesto/xplat/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { take, takeUntil } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';
import { TransactionActions } from '@vesto/ngx-vesto/state';
import {ITransaction} from '@vesto/vesto';

@Component({
  selector: 'gbl-add-yearn-vault-modal',
  templateUrl: 'add-yearn-vault-modal.component.html'
})
export class AddYearnVaultModalComponent extends BaseComponent implements OnInit {
  step: 'FORM_GROUP' | 'BUSY' | 'TWO_FACTOR' | 'SUCCESS' | 'TRANSACTION_DETAIL' = 'FORM_GROUP';
  formGroup: UntypedFormGroup
  transaction: ITransaction;
  error: string;

  ngOnInit() {
    this.formGroup = CoreService.formBuilder.group(
      {
        address: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ]
      },
      { updateOn: 'change' }
    );
  }

  sendSmsCode(isResendSms: boolean) {
    CoreService.transactionFacade.sendSmsCode();

    if (isResendSms) {
      CoreService.uiFacade.notification(`SMS code sent!`);
    }
  }

  addYearnVault() {
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }
    this.step ='TWO_FACTOR';
  }

  confirmCode(code: string) {
    this.step = 'BUSY';
    const value = this.formGroup.value;
    CoreService.transactionFacade.addYearnVault(value.address, code);

    CoreService.actions$
      .pipe(
        ofType(TransactionActions.addYearnVaultSuccess),
        take(1), takeUntil(this.destroy$)
      )
      .subscribe((result) => {
        CoreService.store.dispatch(TransactionActions.sign({ transaction: result.transaction, code, key: new Buffer(CoreService.storageService.getItem(StorageKeys.KEY), 'hex').toString() }));
    });

    CoreService.actions$.pipe(ofType(TransactionActions.addYearnVaultFailure, TransactionActions.signFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
      this.step = 'FORM_GROUP';
    });

    CoreService.actions$.pipe(ofType(TransactionActions.signSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.transaction = result.transaction;
      this.step = 'SUCCESS';
      CoreService.windowService.setTimeout(() => {
        this.step = 'TRANSACTION_DETAIL';
      }, 1600);
    });
  }

  close() {
    uiCloseModal();
  }
}
