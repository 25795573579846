import { Component } from '@angular/core';
import { uiCloseModal } from '@vesto/xplat/core';

@Component({
  selector: 'gbl-alert-dialog',
  templateUrl: './alert-dialog.component.html'
})
export class AlertDialogComponent {
  message: string;
  html: string;
  actions: Array<any>;

  doAction(action) {
    if (action.action) {
      action.action();
    }
    if (action.close) {
      this.close();
    }
  }

  close() {
    uiCloseModal();
  }
}
