import { Directive, OnInit } from '@angular/core';
import { BaseComponent, CoreService, uiCloseModal } from '@vesto/xplat/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';
import { UserActions } from '@vesto/ngx-vesto/state';

@Directive()
export abstract class ChangeEmailModalBaseComponent extends BaseComponent {
  step: 'FORM_GROUP' | 'SUCCESS' = 'FORM_GROUP';
  formGroup: UntypedFormGroup;
  emailSent = false;
  newEmail: string;
  error: string;

  constructor() {
    super();
    this.formGroup = CoreService.formBuilder.group(
      {
        email: [
          null,
          {
            validators: [Validators.required, Validators.email, Validators.nullValidator]
          }
        ]
      },
      { updateOn: 'change' }
    );
  }

  changeEmail() {
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }
    const value = this.formGroup.value;
    this.newEmail = this.formGroup.value.email;
    CoreService.userFacade.changeEmail(value.email);

    CoreService.actions$.pipe(ofType(UserActions.changeEmailSuccess), takeUntil(this.destroy$)).subscribe((result) => {
      this.step = 'SUCCESS';
    });

    CoreService.actions$.pipe(ofType(UserActions.changeEmailFailure), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
    });
  }

  close() {
    uiCloseModal();
  }
}
