export enum AccountType {
  VWALLET = 'VWALLET',
  VTOKEN = 'VTOKEN',
  VLIGHTHOUSE = 'VLIGHTHOUSE',
  VDEFI = 'VDEFI',
  VUSER = 'VUSER',
  VSWAP = 'VSWAP',
  VBRIDGE = 'VBRIDGE',
  WALLET = 'WALLET',
  GAS_TANK = 'GAS_TANK',
  FIAT = 'FIAT',
  PAYOUT = 'PAYOUT',
  OPERATIONAL = 'OPERATIONAL'
}
