import { Inject, Injectable } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { CryptoUtil, IEnvironment, StringtUtil } from '@vesto/vesto';
import { ethers } from 'ethers';
import { isObject, FeatureSwitch } from '@vesto/xplat/utils';
import { LogService } from './log.service';
import { WindowService } from './window.service';
import { LocaleService } from './locale.service';
import { NetworkService } from './network.service';
import { UserFacade, VestoSelectors } from '@vesto/ngx-vesto/state';
import { UiActions } from '../state/ui/ui.actions';
import { EnvironmentToken } from '@vesto/ngx-vesto';
import { StorageKeys, StorageService } from './storage.service';
import {CoreService} from './core.service';

// backend error messages related to maintenance
const maintenanceMessages = ['unavailable', 'gateway timeout'];
// when checking error codes, ignore certain endpoints or error types since they are handled independently
const ignoreErrorCodeChecks = ['/todo'];
// ignore various endpoints from 503 maintenace handling since can safely ignore and fallback to standard error handling
const ignore503MaintenanceMatches = ['/market-data/defi'];

@Injectable()
export class AuthHttpInterceptorService implements HttpInterceptor {
  private _isRefreshingToken = false;
  private _renewTokenError = false;
  private _token$: BehaviorSubject<string> = new BehaviorSubject(null);
  private _appType: 'mobile' | 'web';
  public expiredJwt$: BehaviorSubject<any> = new BehaviorSubject(null);
  private authenticated = false;

  constructor(@Inject(EnvironmentToken) private environment: IEnvironment, private _store: Store<any>, private _log: LogService, private _win: WindowService, private _locale: LocaleService, private _network: NetworkService, private _storage: StorageService, private _ngRouter: RouterModule, private userFacade: UserFacade) {
    this._appType = this._win.isBrowser || this._win.isServer ? 'web' : 'mobile';
    this._store
      .pipe(select(VestoSelectors.authenticated))
      .subscribe(authenticated => this.authenticated = !!authenticated);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const options: any = {};
    const headers: any = {};

    if (req.url) {
      if (req.url.indexOf('vesto.io') > -1 || req.url.indexOf('localhost') > -1) {
        if (FeatureSwitch.mockEndpoints) {
          // for development purposes only
          // check if mock endpoint to return
          const apiPath = req.url.replace(/^[a-zA-Z]{3,5}\:\/{2}[a-zA-Z0-9_.:-]+\//, '');
          // this._log.debug(`mock apiPath:`, apiPath);
          const basePath = FeatureSwitch.mockEndpoints[apiPath];
          // this._log.debug(`mock basePath:`, basePath);
          if (basePath) {
            const mockUrl = `${basePath}${apiPath}.json`;
            req = req.clone({ url: mockUrl });
            this._log.debug(`using mock endpoint: ${req.url}`);
            // No special error handling needed for mock endpoints since json files either exist or not and developer will see clearly that a json file wasn't found
            // so we hijack all the common http handling below and just handle directly here
            return next.handle(req).pipe(
              tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                  // just to get log output of mock responses when desired
                  this._logResponse(event);
                }
              })
            );
          }
        }

        this._prepHeaders(headers);

        if (this._isTokenRefreshHandler(req)) {
          options.setHeaders = headers;
          req = req.clone(options);
          if (LogService.DEBUG_HTTP.enable) {
            this._logRequest(req);
          }
          // short circuit all the common http handling in order to finish refreshing token
          return next.handle(req);
        } else {
          if (this.authenticated) {
            req = this._addHeaders(req, options, headers);
          } else {
            options.setHeaders = headers;
            req = req.clone(options);
          }
        }
      } else if (StringtUtil.isImageUrl(req.url)) {
        options.setHeaders = {
          'Content-Type': `image/${req.url.split('.').pop()}`
        };
      }
    }

    if (LogService.DEBUG_HTTP.enable) {
      this._logRequest(req);
    }

    return this._commonHttpHandling(req, next);
  }

  private _commonHttpHandling(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        // console.log('Inteceptor response', event);
        if (event instanceof HttpResponse) {
          this._renewTokenError = false; // reset on any success (meaning it made it through)
          // got a response, cleanup network error handling
          // this._network.cleanup(event.url);
          // configurable debug output
          if (LogService.DEBUG_HTTP.enable) {
            this._logResponse(event);
          }
          this._checkErrorCode(event);

          if (event.headers) {
            // console.log('x-app-version-status:', event.headers.get('x-app-version-status'))
            if (event.headers.get('x-app-version-status') === 'deprecated') {
              // suggest user to upgrade
              this._win.suggestVersionUpgrade$.next(true);
            }
          }
        }
      }),
      catchError((err: any) => {
        console.log('Intercept error', err);
        let url = null;
        let status = 0;

        if (err instanceof HttpErrorResponse) {
          url = err.url;
          let isBlankUrl = false;
          if (!url && req) {
            isBlankUrl = true;
            url = req.url;
          }
          status = err.status;
          // a null url and status 0 is an api timing out from responsding or user could be offline, just reset them all
          // const isOffline = isBlankUrl && status === 0;
          const isOffline = status === 0;
          // this._network.cleanup(url, isOffline);
          if (isOffline) {
            return this._offlineHandler(req, next, err);
          }
        } else if (err && err.status) {
          // this could happen under some rare cases of network flakiness which did not return a valid instance of HttpErrorResponse - make sure to still capture the right http status code if possible
          status = err.status;
        }

        if (LogService.DEBUG_HTTP.enable) {
          // log out error detail
          this._log.debug(`http error --- ${url}`);
          for (const key in err) {
            if (!['headers', 'ok', 'name'].includes(key) && typeof err[key] !== 'function') {
              this._log.debug('http error - ' + key, err[key]);
            }
          }
        }

        // global http status code handling, ensure it's an endpoint that isn't setup to ignore global error status code handling
        if (!this._ignoreAutoErrorHandling(url)) {
          switch (status) {
            case 400:
              return this._handle400Error(req, next, err);
            case 401:
              return this._handle401Error(req, next, err);
            case 449:
              // app version issues
              return this._handle449Error(req, next, err);
            case 500:
              // this indicates a backend code error
              // unknown error payload, just throw back null to cause error chains to fire
              return this._handleMaintenanceError(req, next, err);
            case 503:
              return this._handleMaintenanceError(req, next, err);
            case 504:
              // gateway timeout - usually means api is deploying or under maintenance
              return this._handleMaintenanceError(req, next, err);
            case 0:
              // likely the response was never constructed as HttpErrorResponse, api timed out responding or user is offline
              return this._offlineHandler(req, next, err);
          }
        }
        return throwError(err);
      })
    );
  }

  private _offlineHandler(req: HttpRequest<any>, next: HttpHandler, err: any) {
    this._log.debug(`OFFLINE or NETWORK PROBLEMS.`);
    if (this._win.isBrowser || this._win.isServer) {
      return throwError(() => err);
    } else {
      // for now suppress discover endpoints from showing offline page cause discover api is very flakey right now
      // eliot/jc working on improving
      // DISABLING OFFLINE PAGE FOR NOW - nrw - 2019-09-25
      // if (req && req.url && req.url.indexOf('discover/card') > -1) {

      // this emits a Subject which allows each app (no matter the platform) to determine best case to actual show offline page or not
      this._network.showOfflinePage = { show: true, httpReq: req };
      return throwError(() => err);

      // } else {
      //   return this._handleOfflineError(req, next, err);
      // }
    }
  }

  private _logRequest(req: HttpRequest<any>) {
    this._log.debug(`http request --- ${req.url}`);
    // this._log.debug(`http version header?:`, this._win.appVersion);
    if (LogService.DEBUG_HTTP.includeRequestHeaders && req.headers) {
      const headerKeys = req.headers.keys();
      this._log.debug('headers:', headerKeys);
      // const authHeader = req.headers.get('Authorization');
      // if (authHeader) {
      //   this._log.debug('using auth:', authHeader);
      // }
      for (const key of headerKeys) {
        this._log.debug(key, req.headers.get(key));
      }
    }
    if (req.body && LogService.DEBUG_HTTP.includeRequestBody) {
      this._log.debug('body:', req.body);
      if (isObject(req.body)) {
        for (const key in req.body) {
          this._log.debug(`   ${key}:`, req.body[key]);
        }
      }
    }
  }

  private _logResponse(event) {
    if (LogService.DEBUG_HTTP.includeResponse) {
      // exclude various responses where never care to see
      if (event.url && event.url.indexOf('assets/i18n') === -1) {
        this._log.debug(`http response --- ${event.url}`);
        this._log.debug('status:', event.status);
        const result = event.body;
        this._log.debug('result:', result && isObject(result) ? JSON.stringify(result) : result);
        this._log.debug(`http response end ---`);
      }
    }
  }

  private _checkErrorCode(event: HttpResponse<any>) {
    // if (event && event.status === 200 && isErrorCode(event.body) && !this._ignoreErrorEndpoint(event)) {
    //   if (this._appType === 'mobile' && !this._win.isDialogOpen) {
    //     // show critical errors in dialogs
    //     // exclude some since a few can be handled transparently
    //     if (errorCodeSourceServiceExclusions.includes(event.body.sourceService)) {
    //       // let system know this occured so can be handled
    //       this._network.errorCodeHandler$.next(event.body.sourceService);
    //     } else {
    //       this._win.alert({
    //         title: 'Oops',
    //         message: `${event.body.message || ''}${
    //           FeatureSwitch.enableDevMessages ? (event.body.developerMessage ? ' dev: ' + event.body.developerMessage : '') : ''
    //         }`
    //       });
    //     }
    //   }
    // }
  }

  private _ignoreErrorEndpoint(event: HttpResponse<any>) {
    if (event && event.url) {
      for (const endpoint of ignoreErrorCodeChecks) {
        if (event.url.indexOf(endpoint) > -1) {
          return true;
        }
      }
    }
    return false;
  }

  private _ignoreAutoErrorHandling(url: string) {
    // NOTE: leaving this here as something like this can become very useful in dialing specific error handling cases
    // if (url) {
    //   // console.log('_ignoreAutoErrorHandling:', url);
    //   if (url.indexOf('/tokens') > 1 && url.indexOf('/refresh') === -1) {
    //     return true;
    //   }
    // }
    return false;
  }

  //   private _prepOptions(options: any, url: string) {
  //     if (options) {
  //       options.url = url;
  //       if (url.startsWith(environment.backend.prefix) && this._basePath) {
  //         // override to include basePath
  //         options.url = this._basePath + url;
  //       }
  //     }
  //   }

  private _prepHeaders(headers: any) {
    if (headers) {
      // TODO: will need to have a mapping file of supported languages to add proper suffix to the code prefix here
      headers['Accept-Language'] = `${this._locale.inMemoryLocale || 'en'}-us`;
      // console.log(`headers['Accept-Language']:`, headers['Accept-Language']);
      // headers['X-App-Type'] = this._appType;
      // if (this._appType === 'mobile') {
      //   headers['X-App-Environment'] = environment.apiHeader;
      //   // todo: may wanna discuss with JC about using similar headers for web and not only for mobile
      //   headers['X-Mobile-OS'] = this._platformDeviceInfo.os;
      //   headers['X-Mobile-OS-Version'] = this._platformDeviceInfo.deviceDetails;
      // }
      // if (this._win.appVersion) {
      //   headers['X-App-Version'] = this._win.appVersion;
      // }

      // if (this._win.isServer) {
      // ssr context may need json headers
      headers['Accept'] = 'application/json';
      // headers['Content-Type'] = 'application/json';
      // }

      // if (this._network.useHttpCacheControlHeaders) {
      //   // always reset after setting these as this flag should be used on per request basis
      //   this._network.useHttpCacheControlHeaders = false;
      //   // cache disable headers
      //   headers['Cache-control'] = 'no-cache';
      //   headers['Cache-control'] = 'no-store';
      //   headers['Expires'] = '0';
      //   headers['Pragma'] = 'no-cache';
      // }
    }
  }

  private _addHeaders(request: HttpRequest<any>, options: any, headers: any) {
    const url = this.environment.vesto.url.endsWith('/') ? this.environment.vesto.url.substring(0, this.environment.vesto.url.length - 1) : this.environment.vesto.url;
    const uri = request.url.split(url)[1].split('?')[0]; // Just the URI and no parameters.
    const signature = this.sign(new Buffer(this._storage.getItem(StorageKeys.KEY), 'hex').toString(), uri, request.method, !!request.body ? JSON.stringify(request.body) : '');
    headers['V'] = signature.v;
    headers['R'] = signature.r;
    headers['S'] = signature.s;
    headers['Timestamp'] = signature.timestamp;
    options.setHeaders = headers;
    return request.clone(options);
  }

  // /*
  //   private _handle500Error(error) {
  //     // leaving here for now as we may want this

  //     return throwError(error);
  //   }
  // */
  private _handle400Error(req: HttpRequest<any>, next: HttpHandler, err: HttpErrorResponse): Observable<HttpEvent<any>> {
    const logData: any = {};
    if (req.body && typeof req.body === 'object') {
      logData.body = JSON.stringify(req.body);
    }
    if (req.url) {
      logData.url = req.url;
    }
    // todo: could hook up embrace to report errors
    // this._embrace.logError('api-400', logData, 0);

    // if (err && err.error && err.error.message) {
    //   this._win.alert(err.error.message);
    // }

    return throwError(() => err);
  }

  private _handle401Error(req: HttpRequest<any>, next: HttpHandler, err: HttpErrorResponse): Observable<HttpEvent<any>> {
    if (err && err.error) {
      this._log.debug('err.error:', err.error);
      switch (err.error.error) {
        case 'InvalidOrExpiredJwt':
          console.log('EMIT EXPIRED');
          return throwError(() => err);
          // try to refresh token
          // if (!this._isRefreshingToken && !this._renewTokenError) {
          //   this._log.debug(
          //     '*** interceptor:',
          //     ` _handle401Error __ begin refreshToken sequence with -- ${
          //       req && req.url ? req.url : 'unknown'
          //     }`
          //   );
          //   return this._refreshToken(req, next);
          // } else {
          //   this._log.debug(
          //     '*** interceptor:',
          //     ` _handle401Error __ queue request until refresh token complete -- ${
          //       req && req.url ? req.url : 'unknown'
          //     }`
          //   );
          //   return this._token$.pipe(
          //     filter(token => !!token),
          //     take(1),
          //     switchMap(token => {
          //       if (token === 'error') {
          //         // ensure streams of pending calls continue and don't become broken
          //         return EMPTY; // throwError(new Error('Invalid token.'));
          //       } else {
          //         const options: any = {};
          //         const headers: any = {};
          //         this._prepHeaders(headers);
          //         req = this._addToken(token, req, options, headers);
          //         this._log.debug(
          //           '*** interceptor:',
          //           ` _handle401Error __ queue request until refresh token complete -- ${
          //             req && req.url ? req.url : 'unknown'
          //           }`
          //         );
          //         return this._commonHttpHandling(req, next);
          //       }
          //     })
          //   );
          // }
          break;
        case 'InvalidEmailOrPassword':
        case 'InvalidCode':
        case 'EmailNotConfirmed':
          //this._userService.apiValidationErrors$.next(err.error);
          break;
        case 'EmailAlreadyTaken':
          this._win.alert({
            title: 'Use a different email',
            message: err.error.message || 'Please use a different email address',
            okButtonText: 'Ok'
          });
          break;
      }
    }
    // otherwise just throw error normally
    return throwError(() => err);
  }

  private _handleMaintenanceError(req: HttpRequest<any>, next: HttpHandler, err: HttpErrorResponse): Observable<HttpEvent<any>> {
    // check for exclusion cases which should *NEVER* show maintenance page
    // exclusion cases are those which may desire special UX handling vs. the maintenance page
    if (req && req.url) {
      for (const url of ignore503MaintenanceMatches) {
        if (req.url.indexOf(url) > -1 || err.status === 500) {
          // just throw standard error handling
          return throwError(() => err);
        }
      }
    }
    if (!this._win.maintenanceMode && err && err.statusText) {
      for (const msg of maintenanceMessages) {
        if (err.statusText.toLowerCase().indexOf(msg) > -1) {
          // show maintenance page
          this._routeToErrorPage('/maintenance', req);
          break;
        }
      }
    }
    return throwError(() => err);
  }

  private _routeToErrorPage(routePath: string, req: HttpRequest<any>) {
    if (!this._win.maintenanceMode) {
      this._win.maintenanceMode = true; // prevent multiple api's from calling this
      if (req) {
        this._win.maintenanceApiErrorUrl = req.url;
        this._log.debug('*** interceptor:', 'routing to error page due to issue with:', req.url);
      }
      this._win.setTimeout((_) => {
        this._store.dispatch(
          UiActions.go({
            path: [routePath],
            extras: {
              clearHistory: true,
              animated: false
            }
          })
        );
      }, 300);
    }
  }

  private _handleOfflineError(req: HttpRequest<any>, next: HttpHandler, err: HttpErrorResponse): Observable<HttpEvent<any>> {
    this._routeToErrorPage('/offline', req);
    return throwError(err);
  }

  // private _refreshToken(req: HttpRequest<any>, next: HttpHandler) {
  //   this._isRefreshingToken = true;
  //   // Reset here so that the following requests wait until the token
  //   // comes back from the refreshToken call.
  //   this._token$.next(null);
  //
  //
  //
  //   // TODO: refresh token wiring...
  //   // return this._userService.renewToken(this._userService.token).pipe(
  //   return this._userService.generateRenewToken(refreshToken).pipe(
  //     switchMap((result: any) => {
  //       this._log.debug('*** interceptor:', ` TOKEN REFRESHED with`, result);
  //
  //       if (result && result.access_token) {
  //         // tokens endpoints put the tokens outside ther user object
  //         this._userService.token = result.access_token;
  //         this._userService.refreshToken = result.refresh_token;
  //         this._isRefreshingToken = false;
  //         // emit token out for any queued up http requests which were waiting for token to refresh
  //         this._token$.next(result.access_token);
  //         const options: any = {};
  //         const headers: any = {};
  //         this._prepHeaders(headers);
  //         req = this._addToken(result.access_token, req, options, headers);
  //         return this._commonHttpHandling(req, next);
  //       }
  //
  //       // If we don't get a new token, we are in trouble so logout.
  //       this._renewErrorHandler();
  //       // return of(null);
  //       return EMPTY; // throwError(new Error('Invalid token.'));
  //     }),
  //     catchError(err => {
  //       console.log('TOKEN REFRESH ERROR!!!!');
  //       console.log('result:', err);
  //       if (err && err.url && this._isTokenRefreshHandler(req)) {
  //         this._renewErrorHandler();
  //         // If there is an exception calling 'refreshToken', bad news so logout.
  //         return EMPTY; // throwError(err);
  //       } else {
  //         this._isRefreshingToken = false;
  //         // don't rethrow an error for any other url other than refresh token url
  //         // this chain may pick up errors from other in flight requests
  //         return EMPTY; // of(null);
  //       }
  //     })
  //   );
  // }

  private _handle449Error(req: HttpRequest<any>, next: HttpHandler, err: HttpErrorResponse): Observable<HttpEvent<any>> {
    // console.log('449 error!');
    this._win.needsUpdate = true;
    this._routeToErrorPage('/offline', req);
    return throwError(err);
  }

  private _isTokenRefreshHandler(req: HttpRequest<any>) {
    // todo
    return false;
  }

  private _renewErrorHandler() {
    if (!this._renewTokenError) {
      // only handle once in a queued stack
      this._renewTokenError = true;
      this._isRefreshingToken = false;
      // cancels any queued calls to prevent observable streams from getting stuck
      this._token$.next('error');
      this._log.debug('*** interceptor:', `_renewErrorHandler, could not refresh token`);
      if (this._win.isBrowser || this._win.isServer) {
        // only do this automatically on browser or server
        // mobile apps have some extra logout items to process which are better handled via the renewTokenError$ Subject
        this._win.setTimeout((_) => {
          this._log.debug('*** interceptor:', `_renewTokenError, logging out!`);
          // this._store.dispatch(new UserActions.Logout());
        }, 50);
      }
      // notify platforms to handle (usually routes to home and shows login)
      //this._userService.renewTokenError$.next(true);
    }
  }

  private sign(privateKey: string, uri: string, method: string, body: string) {
    const signingKey = new ethers.utils.SigningKey(privateKey);
    const timestamp = (new Date().getTime() / 1000).toFixed(0).toString();
    const message = `${timestamp}${uri}${method}${body}`;
    const hash = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(message));
    const signature = signingKey.signDigest(hash);

    return {
      v: signature.v.toString(),
      r: signature.r,
      s: signature.s,
      timestamp: timestamp
    };
  }
}
