import { Pipe, PipeTransform } from '@angular/core';
import { Symbol } from '@vesto/vesto';
import { CoreService } from '@vesto/xplat/core';

@Pipe({
  name: 'symbolIcon',
  pure: true
})
export class SymbolIconPipe implements PipeTransform {
  transform(symbol: string): string {
    const rootPath = CoreService.platformDeviceInfo.deviceDetails === 'browser' ? '.' : '~';
    const fileType = CoreService.platformDeviceInfo.deviceDetails === 'browser' ? 'svg' : 'png';
    switch (symbol) {
      case Symbol.VUSDC:
        return `${rootPath}/assets/icons/USDC.${fileType}`;
      case Symbol.YVUSDC:
        return `${rootPath}/assets/icons/USDC.${fileType}`;
      case Symbol.VDAI:
        return `${rootPath}/assets/icons/DAI.${fileType}`;
      case Symbol.AUSDC:
        return `${rootPath}/assets/images/usdc-icon.${fileType}`;
      case Symbol.DAI:
        return `${rootPath}/assets/images/dai-icon.png`;
      case Symbol.ETH:
        return '';
      case Symbol.AEROS:
        return `${rootPath}/assets/images/aeros-icon.png`;
      // case Symbol.Pax:
      //   return '';
      default:
        return `${rootPath}/assets/images/default-token-icon.${fileType}`;
    }
  }
}
