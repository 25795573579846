<div>
  <!-- <div class="circle-container relative">
    <div class="circle first absolute"></div>
  </div> -->

  <router-outlet></router-outlet>
  <ngx-spinner
    bdColor="#00000099"
    size="medium"
    color="#fff"
    type="ball-pulse"
    template="<div class='animation-container'>
  <div>
    <div class='blue-sprinkle-container'>
      <div class='blue-sprinkle'></div>
    </div>
    <div class='yellow-sprinkle-container'>
      <div class='yellow-sprinkle'></div>
    </div>
  </div>
</div>"
  >
  </ngx-spinner>
</div>
