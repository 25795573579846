import { IToken } from './token.interface';
import { IGoogle } from './google.interface';
import { InstitutionStatus } from '../enums/institution-status.enum';
import {IAccount} from "./account.interface";
import {ITemplate} from './template.interface';
import {InstitutionFeeType} from '../enums/institution-fee-type.enum';

export interface IInstitution {
  id?: string;
  operationalAccount: IAccount;
  tokenAccount: IAccount;
  userAccount: IAccount;
  payoutAccount: IAccount;
  walletAccount: IAccount;
  status?: InstitutionStatus;
  feeType?: InstitutionFeeType;
  name?: string;
  url?: string;
  address?: string;
  google?: IGoogle;
  defaultDailyBuyLimit: number;
  defaultDailySellLimit: number;
  defaultDailyTransferLimit: number;
  defaultDailyReceiveLimit: number;
  merchantEnabled: boolean;
  plaidEnabled: boolean;
  instantSettleEnabled: boolean;
  settleTransactionLimit: number;
  settleDailyLimit: number;
  feeFixed?: number;
  feePercentage?: number;
  error: string;
  updated?: string;
  created?: string;
  iosAppId?: string;
  androidAppId?: string;
  referrer?: string;
  // Joins
  templates: ITemplate[];
  tokens: IToken[];
}
