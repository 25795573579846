import { Inject, Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { mergeMap, catchError, withLatestFrom, delay } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { IEnvironment, ObjectUtil } from '@vesto/vesto';
import { EnvironmentToken } from '../../services/tokens';
import { VestoState } from '../vesto.state';
import { ApiActions } from '../api';
import { UserFacade } from '../user/user.facade';
import { SignUpActions } from './sign-up.actions';
import { SignUpApiService } from '../../services/sign-up-api.service';

@Injectable()
export class SignUpEffects {
  constructor(
    private actions$: Actions,
    private signUpUsersApiService: SignUpApiService,
    @Inject(EnvironmentToken)
    protected environment: IEnvironment,
    protected store: Store<VestoState.IState>,
    private userFacade: UserFacade
  ) {}

  findUserByToken$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(SignUpActions.findUserByToken),
        mergeMap(({ token }) =>
          this.signUpUsersApiService.findUserByToken(token).pipe(
            mergeMap((result) => [
              SignUpActions.findUserByTokenSuccess({
                token: result.token,
                user: result.user
              })
            ]),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return [SignUpActions.findUserByTokenFailure({ apiError }), ApiActions.setError({ apiError })];
            })
          )
        )
      )
  );

  signUp$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(SignUpActions.signUp),
        // NOTE: UX polish point
        // ensure any UI activity handling is processed on the UI thread before background thread crypto handling gets involved
        // e.g. this ensures any purely UX related spinners fluidly show and spin while processing cryptographic handling on background threads (if enabled)
        delay(100),
        withLatestFrom(this.userFacade.token$),
        mergeMap(([{ user, password }, token]) => {
          return this.signUpUsersApiService.signUp(this.environment.vesto.institutionId, token, user, password).pipe(
            mergeMap((result) => [
              SignUpActions.signUpSuccess({
                token: result.token,
                user: result.user,
                password
              })
            ]),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return [SignUpActions.signUpFailure({ apiError }), ApiActions.setError({ apiError })];
            })
          );
        })
      )
  );

  sendSmsCode$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(SignUpActions.sendSmsCode),
        withLatestFrom(this.userFacade.token$),
        mergeMap(([{ mobile }, token]) =>
          this.signUpUsersApiService.sendSmsCode(token, mobile).pipe(
            mergeMap((result) => [
              SignUpActions.sendSmsCodeSuccess({
                token: result.token,
                user: result.user
              })
            ]),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return [SignUpActions.sendSmsCodeFailure({ apiError }), ApiActions.setError({ apiError })];
            })
          )
        )
      )
  );

  verifySmsCode$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(SignUpActions.verifySmsCode),
        withLatestFrom(this.userFacade.token$),
        mergeMap(([{ code }, token]) => {
          return this.signUpUsersApiService.verifySmsCode(token, code).pipe(
            mergeMap((result) => [
              SignUpActions.verifySmsCodeSuccess({
                token: result.token,
                user: result.user
              })
            ]),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return [SignUpActions.verifySmsCodeFailure({ apiError }), ApiActions.setError({ apiError })];
            })
          );
        })
      )
  );

  sendEmail$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(SignUpActions.sendEmail),
        withLatestFrom(this.userFacade.token$),
        mergeMap(([{}, token]) =>
          this.signUpUsersApiService.sendEmail(token).pipe(
            mergeMap((result) => [
              SignUpActions.sendEmailSuccess({
                token: result.token,
                user: result.user
              })
            ]),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([SignUpActions.sendEmailFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          )
        )
      )
  );

  verifyEmail$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(SignUpActions.verifyEmail),
        mergeMap(({ token }) =>
          this.signUpUsersApiService.verifyEmail(token).pipe(
            mergeMap((result) => [
              SignUpActions.verifyEmailSuccess({
                privateKey: result.privateKey,
                user: result.user
              })
            ]),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return [SignUpActions.verifyEmailFailure({ apiError }), ApiActions.setError({ apiError })];
            })
          )
        )
      )
  );
}
