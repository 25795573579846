import { createAction, props } from '@ngrx/store';
import { IApiError, IUser } from '@vesto/vesto';
import {VestoState} from "../vesto.state";

export namespace UserActions {

  export const initialize = createAction('[@vesto/user] initialize');

  export const reset = createAction('[@vesto/user] reset', props<{ resetState: VestoState.IState }>());

  export const set = createAction('[@vesto/user] set', props<{ user: IUser }>());

  export const authenticate = createAction('[@vesto/user] set authenticated');

  export const signIn = createAction('[@vesto/user] signIn', props<{ email: string; password: string }>());

  export const signInSuccess = createAction('[@vesto/user] signInSuccess', props<{ token: string; user: IUser; password: string }>());

  export const signInFailure = createAction('[@vesto/user] signInFailure', props<{ apiError: IApiError }>());

  export const signOut = createAction('[@vesto/user] signOut');

  export const sendSmsCode = createAction('[@vesto/user] sendSmsCode');

  export const sendSmsCodeSuccess = createAction('[@vesto/user] sendSmsCodeSuccess');

  export const sendSmsCodeFailure = createAction('[@vesto/user] sendSmsCodeFailure', props<{ apiError: IApiError }>());

  export const verifyTwoFactorCode = createAction('[@vesto/user] verifyTwoFactorCode', props<{ code: string }>());

  export const verifyTwoFactorCodeSuccess = createAction('[@vesto/user] verifyTwoFactorCodeSuccess', props<{ user: IUser; privateKey: string }>());

  export const verifyTwoFactorCodeFailure = createAction('[@vesto/user] verifyTwoFactorCodeFailure', props<{ apiError: IApiError }>());

  export const find = createAction('[@vesto/user] find');

  export const findSuccess = createAction('[@vesto/user] findSuccess', props<{ user: IUser }>());

  export const findFailure = createAction('[@vesto/user] findFailure', props<{ apiError: IApiError }>());

  export const findByMerchant = createAction('[@vesto/user] findByMerchant', props<{ merchantId: string }>());

  export const findByMerchantSuccess = createAction('[@vesto/user] findByMerchantSuccess', props<{ users: IUser[] }>());

  export const findByMerchantFailure = createAction('[@vesto/user] findByMerchantFailure', props<{ apiError: IApiError }>());

  export const invite = createAction('[@vesto/user] invite', props<{ institutionId: string; email: string }>());

  export const inviteSuccess = createAction('[@vesto/user] inviteSuccess');

  export const inviteFailure = createAction('[@vesto/user] inviteFailure', props<{ apiError: IApiError }>());

  export const inviteToMerchant = createAction('[@vesto/user] inviteToMerchant', props<{ merchantId: string; email: string }>());

  export const inviteToMerchantSuccess = createAction('[@vesto/user] inviteToMerchantSuccess');

  export const inviteToMerchantFailure = createAction('[@vesto/user] inviteToMerchantFailure', props<{ apiError: IApiError }>());

  export const changePassword = createAction('[@vesto/user] changePassword', props<{ oldPassword: string; newPassword: string }>());

  export const changePasswordSuccess = createAction('[@vesto/user] changePasswordSuccess');

  export const changePasswordFailure = createAction('[@vesto/user] changePasswordFailure', props<{ apiError: IApiError }>());

  export const verifyChangePassword = createAction('[@vesto/user] verifyChangePassword', props<{ code: string }>());

  export const verifyChangePasswordSuccess = createAction('[@vesto/user] verifyChangePasswordSuccess');

  export const verifyChangePasswordFailure = createAction('[@vesto/user] verifyChangePasswordFailure', props<{ apiError: IApiError }>());

  export const changePersonalInfo = createAction('[@vesto/user] changePersonalInfo', props<{ firstName: string; lastName: string; address: string; googlePlaceId: string }>());

  export const changePersonalInfoSuccess = createAction('[@vesto/user] changePersonalInfoSuccess', props<{ user: IUser }>());

  export const changePersonalInfoFailure = createAction('[@vesto/user] changePersonalInfoFailure', props<{ apiError: IApiError }>());

  export const changeEmail = createAction('[@vesto/user] changeEmail', props<{ email: string }>());

  export const changeEmailSuccess = createAction('[@vesto/user] changeEmailSuccess');

  export const changeEmailFailure = createAction('[@vesto/user] changeEmailFailure', props<{ apiError: IApiError }>());

  export const verifyChangeEmail = createAction('[@vesto/user] verifyChangeEmail', props<{ token: string }>());

  export const verifyChangeEmailSuccess = createAction('[@vesto/user] verifyChangeEmailSuccess', props<{ user: IUser }>());

  export const verifyChangeEmailFailure = createAction('[@vesto/user] verifyChangeEmailFailure', props<{ apiError: IApiError }>());

  export const changeMobile = createAction('[@vesto/user] changeMobile', props<{ mobile: string }>());

  export const changeMobileSuccess = createAction('[@vesto/user] changeMobileSuccess', props<{ token: string }>());

  export const changeMobileFailure = createAction('[@vesto/user] changeMobileFailure', props<{ apiError: IApiError }>());

  export const verifyChangeMobile = createAction('[@vesto/user] verifyChangeMobile', props<{ code: string }>());

  export const verifyChangeMobileSuccess = createAction('[@vesto/user] verifyChangeMobileSuccess', props<{ user: IUser }>());

  export const verifyChangeMobileFailure = createAction('[@vesto/user] verifyChangeMobileFailure', props<{ apiError: IApiError }>());

  export const forgotPassword = createAction('[@vesto/user] forgotPassword', props<{ email: string }>());

  export const forgotPasswordSuccess = createAction('[@vesto/user] forgotPasswordSuccess');

  export const forgotPasswordFailure = createAction('[@vesto/user] forgotPasswordFailure', props<{ apiError: IApiError }>());

  export const setPassword = createAction('[@vesto/user] setPassword', props<{ token: string; password: string }>());

  export const setPasswordSuccess = createAction('[@vesto/user] setPasswordSuccess');

  export const setPasswordFailure = createAction('[@vesto/user] setPasswordFailure', props<{ apiError: IApiError }>());

  export const createOnfidoSdkToken = createAction('[@vesto/user] createOnfidoSdkToken', props<{ device: string }>());

  export const createOnfidoSdkTokenSuccess = createAction('[@vesto/user] createOnfidoSdkTokenSuccess', props<{ token: string }>());

  export const createOnfidoSdkTokenFailure = createAction('[@vesto/user] createOnfidoSdkTokenFailure', props<{ apiError: IApiError }>());

  export const verifyIdentity = createAction('[@vesto/user] verifyIdentity', props<{ address: string; googlePlaceId: string; documentType: string; documentFrontId; documentBackId: string; faceId: string; dob?: string; ssn?: string }>());

  export const verifyIdentitySuccess = createAction('[@vesto/user] verifyIdentitySuccess', props<{ user: IUser }>());

  export const verifyIdentityFailure = createAction('[@vesto/user] verifyIdentityFailure', props<{ apiError: IApiError }>());

  export const typeahead = createAction('[@vesto/user] typeahead', props<{ keyword: string }>());

  export const typeaheadSuccess = createAction('[@vesto/user] typeaheadSuccess', props<{ users: IUser[] }>());

  export const typeaheadFailure = createAction('[@vesto/user] typeaheadFailure', props<{ apiError: IApiError }>());

  export const selectUser = createAction('[@vesto/user] selectUser', props<{ user: IUser }>());

  export const clearUsers = createAction('[@vesto/user] clearUsers');

  export const kycClear = createAction('[@vesto/user] kycClear');

  export const kycClearSuccess = createAction('[@vesto/user] kycClearSuccess', props<{ user: IUser }>());

  export const kycClearFailure = createAction('[@vesto/user] kycClearFailure', props<{ apiError: IApiError }>());

  export const kycConsider = createAction('[@vesto/user] kycConsider');

  export const kycConsiderSuccess = createAction('[@vesto/user] kycConsiderSuccess', props<{ user: IUser }>());

  export const kycConsiderFailure = createAction('[@vesto/user] kycConsiderFailure', props<{ apiError: IApiError }>());

  export const suspend = createAction('[@vesto/user] suspend');

  export const suspendSuccess = createAction('[@vesto/user] suspendSuccess', props<{ user: IUser }>());

  export const suspendFailure = createAction('[@vesto/user] suspendFailure', props<{ apiError: IApiError }>());

  export const activate = createAction('[@vesto/user] activate');

  export const activateSuccess = createAction('[@vesto/user] activateSuccess', props<{ user: IUser }>());

  export const activateFailure = createAction('[@vesto/user] activateFailure', props<{ apiError: IApiError }>());

  export const requestDailyLimitsIncrease = createAction('[@vesto/user] requestDailyLimitsIncrease');

  export const requestDailyLimitsIncreaseSuccess = createAction('[@vesto/user] requestDailyLimitsIncreaseSuccess');

  export const requestDailyLimitsIncreaseFailure = createAction('[@vesto/user] requestDailyLimitsIncreaseFailure', props<{ apiError: IApiError }>());
}
