import { StoreStatus } from '../enums/store-status.enum';

export interface IStore {
  id: string;
  status: StoreStatus;
  name: string;
  address: string;
  updated: string;
  created: string;
  operationalAccountId: string;
}
