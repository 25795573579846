import { IToken } from './token.interface';
import { IUser } from './user.interface';
import { IBankAccount } from './bank-account.interface';
import { TransactionStatus } from '../enums/transaction-status.enum';
import { ISignature } from './signature.interface';
import { TransactionSellStatus } from '../enums/transaction-sell-status.enum';
import {IFee} from "./fee.interface";

export interface ITransaction {
  id?: string;
  accountId?: string;
  address?: string;
  recipientAccountId?: string;
  recipientAddress?: string;
  user?: IUser;
  recipientUser?: IUser;
  bankAccount?: IBankAccount;
  hash?: string;
  token?: IToken;
  underlyingToken?: IToken;
  referenceTransaction?: ITransaction;
  blockchain?: string;
  merchantId?: string;
  type?: string;
  status?: TransactionStatus;
  sellStatus?: TransactionSellStatus;
  fiatAmount?: number;
  amount?: number;
  underlyingAmount?: any;
  fee?: number;
  underlyingFee?: number;
  gasFee?: number;
  message?: string;
  messageHash?: string;
  statusMessage?: string;
  url?: string;
  memo?: string;
  requiredSignatures?: number;
  error?: string;
  updated?: string;
  created?: string;
  // Joins.
  signatures?: ISignature[];
  fees?: IFee[];
}
