<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <h3 class="section-number"><i class="fal fa-money-bill"></i> Fiat Balances</h3>
</div>
<mat-dialog-content>
  <ng-container [ngSwitch]="step">
    <section *ngSwitchCase="'FORM_GROUP'">
      <div class="grid grid-cols-2 mb-4 gap-3 text-sm">
        <div>
          <span class="block font-bold">FBO Account Balance:</span>
          {{ fiatBalances?.fboAccountBalance | currency}}
        </div>
        <div>
          <span class="block font-bold">FBO Available Amount:</span>
          {{ fiatBalances?.fboAvailableAmount | currency}}
        </div>
        <div>
          <span class="block font-bold">Ready to Wire to Circle Amount:</span>
          {{ fiatBalances?.readyToWireToCircleAmount | currency}}
        </div>
        <div>
          <span class="block font-bold">Ready to Wire to FBO Amount:</span>
          {{ fiatBalances?.readyToWireToFboAmount | currency}}
        </div>
        <div>
          <span class="block font-bold">Circle Balance:</span>
          {{ fiatBalances?.circleBalance | currency}}
        </div>
      </div>

      <gbl-alert [type]="error" [message]="error"></gbl-alert>

      <div class="grid grid-cols-2 gap-2">
        <button class="button button-primary w-full" (click)="wire('fbo')" [disabled]="fiatBalances?.readyToWireToFboAmount === 0">Wire {{ fiatBalances?.readyToWireToFboAmount | currency}} to FBO</button>
        <button class="button button-primary w-full" (click)="wire('circle')" [disabled]="fiatBalances?.readyToWireToCircleAmount === 0">Wire {{ fiatBalances?.readyToWireToCircleAmount | currency}} to Circle</button>
      </div>
    </section>

    <section *ngSwitchCase="'TWO_FACTOR'">
      <gbl-two-factor (sendSmsCode)="sendSmsCode($event)" (code)="confirmCode($event)"></gbl-two-factor>
      <gbl-alert [type]="error" [message]="error"></gbl-alert>
    </section>

    <section *ngSwitchCase="'BUSY'" class="mt-4">
      <div class="placeholder-long bg-flash"></div>
      <div class="placeholder-area bg-flash mt-4"></div>
    </section>

    <section *ngSwitchCase="'SUCCESS'">
      <gbl-lottie-player></gbl-lottie-player>
    </section>

    <section *ngSwitchCase="'DETAIL'">
      <div class="slide-in">
        <div class="text-center">
          <p>Success!</p>
        </div>
        <div class="p-4 bg-trans-blue rounded-lg" *ngIf="transaction">
          <gbl-transaction [transaction]="transaction"></gbl-transaction>
        </div>
        <button class="button button-primary my-4 mx-auto w-full" (click)="close()">Close</button>
      </div>
    </section>
  </ng-container>
</mat-dialog-content>
