import { createAction, props } from '@ngrx/store';
import {
  Blockchain,
  IAccount,
  IApiError,
  IInstitution,
  ITransaction,
  IPaymentInfo,
  RoleType,
  TransactionStatus, TransactionType
} from '@vesto/vesto';

export namespace TransactionActions {
  export const set = createAction('[@vesto/transaction] set', props<{ transaction: ITransaction }>());

  export const add = createAction('[@vesto/transaction] add', props<{ transaction: ITransaction }>());

  export const find = createAction('[@vesto/transaction] find', props<{ transactionId: string; wasPending?: boolean }>());

  export const findSuccess = createAction('[@vesto/transaction] findSuccess', props<{ transaction: ITransaction; wasPending?: boolean }>());

  export const findFailure = createAction('[@vesto/transaction] findFailure', props<{ apiError: IApiError }>());

  export const findByAccount = createAction('[@vesto/transaction] findByAccount', props<{ accountId: string; page: number; size: number }>());

  export const findByAccountSuccess = createAction('[@vesto/transaction] findByAccountSuccess', props<{ transactions: ITransaction[] }>());

  export const findByAccountFailure = createAction('[@vesto/transaction] findByAccountFailure', props<{ apiError: IApiError }>());

  export const findByAccountAndSymbol = createAction('[@vesto/transaction] findByAccountAndSymbol', props<{ accountId: string; symbol: string; page: number; size: number }>());

  export const findByAccountAndSymbolSuccess = createAction('[@vesto/transaction] findByAccountAndSymbolSuccess', props<{ transactions: ITransaction[] }>());

  export const findByAccountAndSymbolFailure = createAction('[@vesto/transaction] findByAccountAndSymbolFailure', props<{ apiError: IApiError }>());

  export const findByTypeAndStatus = createAction('[@vesto/transaction] findByTypeAndStatus', props<{ _type: TransactionType; status: TransactionStatus; page: number; size: number }>());

  export const findByTypeAndStatusSuccess = createAction('[@vesto/transaction] findByTypeAndStatusSuccess', props<{ transactions: ITransaction[] }>());

  export const findByTypeAndStatusFailure = createAction('[@vesto/transaction] findByTypeAndStatusFailure', props<{ apiError: IApiError }>());

  export const findBuyOrSellActionRequired = createAction('[@vesto/transaction] findBuyOrSellActionRequired', props<{ page: number; size: number }>());

  export const findBuyOrSellActionRequiredSuccess = createAction('[@vesto/transaction] findBuyOrSellActionRequiredSuccess', props<{ transactions: ITransaction[] }>());

  export const findBuyOrSellActionRequiredFailure = createAction('[@vesto/transaction] findBuyOrSellActionRequiredFailure', props<{ apiError: IApiError }>());

  export const changeStatus = createAction('[@vesto/transaction] changeStatus', props<{ transactionId: string; status: TransactionStatus; code: string }>());

  export const changeStatusSuccess = createAction('[@vesto/transaction] changeStatusSuccess', props<{ transaction: ITransaction }>());

  export const changeStatusFailure = createAction('[@vesto/transaction] changeStatusFailure', props<{ apiError: IApiError }>());

  export const transferGas = createAction('[@vesto/transaction] transferGas', props<{ gasTankId: string; blockchain: Blockchain; to: string; code: string }>());

  export const transferGasSuccess = createAction('[@vesto/transaction] transferGasSuccess', props<{ transaction: ITransaction }>());

  export const transferGasFailure = createAction('[@vesto/transaction] transferGasFailure', props<{ apiError: IApiError }>());

  export const syncAddresses = createAction('[@vesto/transaction] syncAddresses', props<{ blockchain: Blockchain; code: string }>());

  export const syncAddressesSuccess = createAction('[@vesto/transaction] syncAddressesSuccess', props<{ transaction: ITransaction }>());

  export const syncAddressesFailure = createAction('[@vesto/transaction] syncAddressesFailure', props<{ apiError: IApiError }>());

  export const syncImplementationAddresses = createAction('[@vesto/transaction] syncImplementationAddresses', props<{ blockchain: Blockchain; code: string }>());

  export const syncImplementationAddressesSuccess = createAction('[@vesto/transaction] syncImplementationAddressesSuccess', props<{ transaction: ITransaction }>());

  export const syncImplementationAddressesFailure = createAction('[@vesto/transaction] syncImplementationAddressesFailure', props<{ apiError: IApiError }>());

  export const syncApys = createAction('[@vesto/transaction] syncYieldPercents', props<{ code: string }>());

  export const syncApysSuccess = createAction('[@vesto/transaction] syncYieldPercentsSuccess', props<{ transaction: ITransaction }>());

  export const syncApysFailure = createAction('[@vesto/transaction] syncYieldPercentsFailure', props<{ apiError: IApiError }>());

  export const setUnderlyingSupplyLimit = createAction('[@vesto/transaction] setUnderlyingSupplyLimit', props<{ symbol: string; underlyingLimit: number; code: string }>());

  export const setUnderlyingSupplyLimitSuccess = createAction('[@vesto/transaction] setUnderlyingSupplyLimitSuccess', props<{ transaction: ITransaction }>());

  export const setUnderlyingSupplyLimitFailure = createAction('[@vesto/transaction] setUnderlyingSupplyLimitFailure', props<{ apiError: IApiError }>());

  export const swapVSigningAddress = createAction('[@vesto/transaction] swapVSigningAddress', props<{ code: string }>());

  export const swapVSigningAddressSuccess = createAction('[@vesto/transaction] swapVSigningAddressSuccess', props<{ transaction: ITransaction }>());

  export const swapVSigningAddressFailure = createAction('[@vesto/transaction] swapVSigningAddressFailure', props<{ apiError: IApiError }>());

  export const buy = createAction('[@vesto/transaction] buy', props<{ accountId: string; symbol: string; bankAccountId: string; amount: number; memo: string; code: string }>());

  export const buySuccess = createAction('[@vesto/transaction] buySuccess', props<{ transaction: ITransaction }>());

  export const buyFailure = createAction('[@vesto/transaction] buyFailure', props<{ apiError: IApiError }>());

  export const addYearnVault = createAction('[@vesto/transaction] addYearnVault', props<{ address: string; code: string }>());

  export const addYearnVaultSuccess = createAction('[@vesto/transaction] addYearnVaultSuccess', props<{ transaction: ITransaction }>());

  export const addYearnVaultFailure = createAction('[@vesto/transaction] addYearnVaultFailure', props<{ apiError: IApiError }>());

  export const mint = createAction('[@vesto/transaction] mint', props<{ symbol: string; code: string }>());

  export const mintSuccess = createAction('[@vesto/transaction] mintSuccess', props<{ transaction: ITransaction }>());

  export const mintFailure = createAction('[@vesto/transaction] mintFailure', props<{ apiError: IApiError }>());

  export const sign = createAction('[@vesto/transaction] sign', props<{ transaction: ITransaction; code: string; key: string }>());

  export const signSuccess = createAction('[@vesto/transaction] signSuccess', props<{ transaction: ITransaction }>());

  export const signFailure = createAction('[@vesto/transaction] signFailure', props<{ apiError: IApiError }>());

  export const sell = createAction('[@vesto/transaction] sell', props<{ accountId: string; symbol: string; bankAccountId: string; amount: number; memo: string; code: string }>());

  export const sellSuccess = createAction('[@vesto/transaction] sellSuccess', props<{ transaction: ITransaction }>());

  export const sellFailure = createAction('[@vesto/transaction] sellFailure', props<{ apiError: IApiError }>());

  export const transferToEmail = createAction('[@vesto/transaction] transferToEmail', props<{ accountId: string; email: string; symbol: string; amount: number; memo: string; code: string }>());

  export const transferToEmailSuccess = createAction('[@vesto/transaction] transferToEmailSuccess', props<{ transaction: ITransaction }>());

  export const transferToEmailFailure = createAction('[@vesto/transaction] transferToEmailFailure', props<{ apiError: IApiError }>());

  export const transferToAddress = createAction('[@vesto/transaction] transferToAddress', props<{ accountId: string; address: string; symbol: string; amount: number; memo: string; code: string }>());

  export const transferToAddressSuccess = createAction('[@vesto/transaction] transferToAddressSuccess', props<{ transaction: ITransaction }>());

  export const transferToAddressFailure = createAction('[@vesto/transaction] transferToAddressFailure', props<{ apiError: IApiError }>());

  export const createPaymentToken = createAction('[@vesto/transaction] createPaymentToken', props<{ symbol: string; amount: number; memo?: string }>());

  export const createPaymentTokenSuccess = createAction('[@vesto/transaction] createPaymentTokenSuccess', props<{ token: string }>());

  export const createPaymentTokenFailure = createAction('[@vesto/transaction] createPaymentTokenFailure', props<{ apiError: IApiError }>());

  export const findPaymentInfo = createAction('[@vesto/transaction] findPaymentInfo', props<{ token: string }>());

  export const findPaymentInfoSuccess = createAction('[@vesto/transaction] findPaymentInfoSuccess', props<{ paymentInfo: IPaymentInfo }>());

  export const findPaymentInfoFailure = createAction('[@vesto/transaction] findPaymentInfoFailure', props<{ apiError: IApiError }>());

  export const payByToken = createAction('[@vesto/transaction] payByToken', props<{ accountId: string; paymentToken: string; tip: number; code: string }>());

  export const payByTokenSuccess = createAction('[@vesto/transaction] payByTokenSuccess', props<{ transaction: ITransaction }>());

  export const payByTokenFailure = createAction('[@vesto/transaction] payByTokenFailure', props<{ apiError: IApiError }>());

  export const addUser = createAction('[@vesto/transaction] addUser', props<{ accountId: string; email: string; code: string }>());

  export const addUserSuccess = createAction('[@vesto/transaction] addUserSuccess', props<{ account: IAccount; transaction: ITransaction }>());

  export const addUserFailure = createAction('[@vesto/transaction] addUserFailure', props<{ apiError: IApiError }>());

  export const changeRole = createAction('[@vesto/transaction] changeRole', props<{ userId: string; roleType: RoleType; code: string }>());

  export const changeRoleSuccess = createAction('[@vesto/transaction] changeRoleSuccess', props<{ transaction: ITransaction }>());

  export const changeRoleFailure = createAction('[@vesto/transaction] changeRoleFailure', props<{ apiError: IApiError }>());

  export const swapSigningAddresses = createAction('[@vesto/transaction] swapSigningAddresses', props<{ code: string }>());

  export const swapSigningAddressesSuccess = createAction('[@vesto/transaction] swapSigningAddressesSuccess', props<{ transaction: ITransaction }>());

  export const swapSigningAddressesFailure = createAction('[@vesto/transaction] swapSigningAddressesFailure', props<{ apiError: IApiError }>());

  export const removeUser = createAction('[@vesto/transaction] removeUser', props<{ additionalUserId: string; code: string }>());

  export const removeUserSuccess = createAction('[@vesto/transaction] removeUserSuccess', props<{ account: IAccount; transaction: ITransaction }>());

  export const removeUserFailure = createAction('[@vesto/transaction] removeUserFailure', props<{ apiError: IApiError }>());

  export const setSettleLimits = createAction('[@vesto/transaction] setSettleLimits', props<{ enabled: boolean; transactionLimit: number; dailyLimit: number; code: string }>());

  export const setSettleLimitsSuccess = createAction('[@vesto/transaction] setSettleLimitsSuccess', props<{ institution: IInstitution; transaction: ITransaction }>());

  export const setSettleLimitsFailure = createAction('[@vesto/transaction] setSettleLimitsFailure', props<{ apiError: IApiError }>());

  export const setDailyLimits = createAction('[@vesto/transaction] setDailyLimits', props<{ email: string; dailyBuyLimit: number; dailySellLimit: number; dailyTransferLimit: number; code: string }>());

  export const setDailyLimitsSuccess = createAction('[@vesto/transaction] setDailyLimitsSuccess', props<{ transaction: ITransaction }>());

  export const setDailyLimitsFailure = createAction('[@vesto/transaction] setDailyLimitsFailure', props<{ apiError: IApiError }>());

  export const sendSmsCode = createAction('[@vesto/transaction] sendSmsCode');

  export const sendSmsCodeSuccess = createAction('[@vesto/transaction] sendSmsCodeSuccess', props<{ token: string }>());

  export const sendSmsCodeFailure = createAction('[@vesto/transaction] sendSmsCodeFailure', props<{ apiError: IApiError }>());

  export const syncElement = createAction('[@vesto/transaction] syncElement', props<{ accountId: string; trancheAddress: string; principalTokenPoolAddress: string; yieldTokenPoolAddress: string }>());

  export const syncElementSuccess = createAction('[@vesto/transaction] syncElementSuccess', props<{ transaction: ITransaction }>());

  export const syncElementFailure = createAction('[@vesto/transaction] syncElementFailure', props<{ apiError: IApiError }>());

  export const redeem = createAction('[@vesto/transaction] redeem', props<{ transactionId: string; code: string }>());

  export const redeemSuccess = createAction('[@vesto/transaction] redeemSuccess', props<{ transaction: ITransaction }>());

  export const redeemFailure = createAction('[@vesto/transaction] redeemFailure', props <{ apiError: IApiError }>());

  export const wireToCircle = createAction('[@vesto/transaction] wireToCircle', props<{ code: string }>());

  export const wireToCircleSuccess = createAction('[@vesto/transaction] wireToCircleSuccess', props<{ transaction: ITransaction }>());

  export const wireToCircleFailure = createAction('[@vesto/transaction] wireToCircleFailure', props <{ apiError: IApiError }>());

  export const wireToFbo = createAction('[@vesto/transaction] wireToFbo', props<{ code: string }>());

  export const wireToFboSuccess = createAction('[@vesto/transaction] wireToFboSuccess', props<{ transaction: ITransaction }>());

  export const wireToFboFailure = createAction('[@vesto/transaction] wireToFboFailure', props <{ apiError: IApiError }>());
}
