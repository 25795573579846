<div class="mb-3 text-right">
  <a class="text-sm underline" (click)="openCreateStore()"><i class="fal fa-plus-circle"></i> Create Store</a>
</div>
<div class="bg-white shadowed rounded p-3">
  <ng-container *ngIf="stores && stores.length > 0 else noStoresTemplate">
    <div *ngFor="let store of stores; let i = index" class="flex bg-white mt-2 p-2 justify-between items-center">
      <div class="flex-grow">
        <span class="text-sm font-bold block">{{ store.name }}</span>
        <span class="text-xs">{{ store.address }}</span>
      </div>
      <div [matMenuTriggerFor]="menu" class="ml-5 mr-2">
        <i class="fal fa-ellipsis-h text-3xl"></i>
      </div>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="changeStore(store)">
          Edit
        </button>
        <button mat-menu-item>
          Remove
        </button>
      </mat-menu>
    </div>
  </ng-container>

  <ng-template #noStoresTemplate>
    <div class="text-xl-center">
      <h4>No stores.</h4>
      <a class="text-sm underline" (click)="openCreateStore()"><i class="fal fa-plus-circle"></i> Create Store</a>
    </div>
  </ng-template>
</div>
