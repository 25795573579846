import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { VestoSelectors, VestoState } from '../vesto.state';
import { Observable } from 'rxjs';
import {IAccount, IContract} from '@vesto/vesto';
import { AccountActions } from './account.actions';

@Injectable({
  providedIn: 'root'
})
export class AccountFacade {
  accounts$: Observable<IAccount[]> = this.store.pipe(select(VestoSelectors.accounts));

  constructor(private store: Store<VestoState.IState>) {}

  set(account: IAccount) {
    this.store.dispatch(AccountActions.set({ account }));
  }

  setContract(contract: IContract) {
    this.store.dispatch(AccountActions.setContract({ contract }));
  }

  find(accountId: string) {
    this.store.dispatch(AccountActions.find({ accountId }));
  }

  findByUser() {
    this.store.dispatch(AccountActions.findByUser());
  }

  create(name: string) {
    this.store.dispatch(AccountActions.create({ name }));
  }

  changeSettings(
    id: string,
    name: string
  ) {
    this.store.dispatch(AccountActions.changeSettings({ id, name }));
  }

  suspendUser(additionalUserId: string) {
    this.store.dispatch(AccountActions.suspendUser({ additionalUserId }));
  }

  reactivateUser(additionalUserId: string) {
    this.store.dispatch(AccountActions.activateUser({ additionalUserId }));
  }
}
