import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  public articles: Array<any> = [
    {
      title: 'Vesto on Native Assets Podcast',
      url: 'news/vesto-co-founders-christopher-mcgregor-and-wolfgang-decker-join-saevon-springer-to-discuss-vestos-digital-asset-infrastructure',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: 'Vesto Co-Founders, Christopher McGregor and Wolfgang Decker join Sae\'Von Springer on Native Assets and dive into Vesto\'s infrastructure and the future of digital assets.',
      date: '02/06/2023'
    },
    {
      title: 'Vesto pitches at Quantum Miami',
      url: 'news/vesto-quantum-miami-pitch-competition-round-one-with-christopher-mcgregor-and-wolfgang-decker',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: ' Watch Vesto vo-founders Christopher McGregor and Wolfgang Decker compete in the qualifier round. If Vesto is chosen to be a finalist, Vesto will be at Quantum Miami next week to compete in front of a live audience!',
      date: '01/20/2023'
    },
    {
      title: 'Vesto is honored to welcome Nitin Gaur to its Advisor Team',
      url: 'news/vesto-welcomes-nitin-gaur-to-advisor-team-for-global-compliance-regulation-partnerships',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: ' Vesto, a turnkey digital asset infrastructure for businesses, announces today that Nitin Gaur is joining its Advisor Team, working to assist in fundraising, global compliance, tech innovation, and introductions to potential customers and partners.',
      date: '12/15/2022'
    },
    {
      title: 'Christopher McGregor, CEO of Vesto, on Amun\'s Inside Crypto podcast.',
      url: 'news/crian-padayachee-and-vesto-ceo-christopher-mcgregor-discuss-payment-solutions-remittance-for-unbanked-fiat-and-empowerment',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: 'Christopher McGregor, CEO of Vesto, and Crian Payadachee, host of Amun podcast, discuss payments, remittances and how Vesto is modernizing the way businesses transact',
      date: '10/09/2022'
    },
    {
      title: 'Vesto and United Cities North America sign Letter of Intent for Digital Remittance and Payment Platform',
      url: '/news/vesto-partners-with-united-cities-un-for-remittance-cross-border-payments-for-smart-cities-residents',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: ' Vesto, a turnkey digital asset infrastructure for businesses, and United Cities North America (UCNA), an organization partnered with the United Nations and The United Cities Global Program, announce that they signed a Letter of Intent (LOI) on September 16, 2022, to deliver digital wallets and remittance services to smart cities residents.',
      date: '09/29/2022'
    },
    {
      title: 'Vesto Partners with Latamex to Add Fiat-Crypto On/Off Ramp into LatAm',
      url: '/news/vesto-partners-with-latamex-to-add-fiat-crypto-on-off-ramp-into-latam',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: 'Vesto\'s powerful digital asset infrastructure for businesses, today announced that it has partnered with Latamex (part of Settle Network company), a fiat-crypto gateway into Latin America, namely Mexico, Brazil and Argentina.',
      date: '09/20/2022'
    },
    {
      title: 'Listen to Vesto\'s Co-Founders on The Crypto Podcast with Roy Coughlan.',
      url: '/news/roy-coughlan-the-crypto-podcast-and-vesto-co-founders-christopher-mcgregor-wolfgang-decker-discuss-vestos-crypto-journey-and-multi-token-protocol-for-decentralized-services',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: 'Christopher McGregor, CEO of Vesto & Co-Founder, and Wolfgang Decker, CSO & Co-Founder, speak about Vesto\'s crypto journey, explain Vesto\'s comprehensive, multi-token protocol, and how it provides innovators the infrastructure to create an inclusive digital future. Touching on investor protection, providing liquidity & yield at the same time, utilizing Ethereum & Polygon... and so much more.',
      date: '08/09/2022'
    },
    {
      title: 'Vesto Powers FinTech Aeros to Service the Underbanked.',
      url: '/news/aeros-adapts-vesto-white-label-infrastructure-crypto-cashless-transactions-payments-instant-settlement-for-underserved-businesses',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: 'Vesto, a comprehensive, multi-token protocol for decentralized services including DeFi, today announced that Aeros has white labeled its infrastructure to deliver crypto and cashless transactions, payments, and instant settlement for its clients across the US.',
      date: '06/14/2022'
    },
    {
      title: 'Vesto\'s CEO Chris McGregor speaks with the @cryptohipster on the Irish Tech News Podcast about Vesto\'s multi-token protocol for institutional DeFi adoption.',
      url: '/news/irish-tech-news-podcast-vesto-multi-token-protocol-for-institutional-defi-adoption-christopher-mcgregor',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: 'Listen to Vesto\'s CEO, Christopher McGregor, discuss how institutions are adopting DeFi with Vesto\'s multi-token protocol on The Irish Tech News Podcast with Jamil Hasan (@cryptohipster).',
      date: '05/16/2022'
    },
    {
      title: 'Vesto\'s Chris McGregor and Wolfgang Decker discuss \'How Conventional Banks are Adapting to Decentralized Finance\' on the DWealth Muse Podcast with Dara Albright',
      url: '/news/vesto-cofounders-on-dwealth-muse-podcast-how-conventional-banks-are-adapting-to-decentralized-finance',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: 'Listen to Vesto cofounders, Christopher McGregor and Wolfgang Decker, discuss How Conventional Banks are Adapting to Decentralized Finance on the DWealth Muse Podcast with host, Dara Albright.',
      date: '05/10/2022'
    },
    {
      title: 'Vesto Launches on Mainnet for Decentralized Financial Services, Crypto-Linked Offerings',
      url: '/news/vesto-multi-token-protocol-launches-on-mainnet-for-decentalized-financial-services-crypto-linked-offerings',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: 'Vesto, a comprehensive, multi-token protocol for decentralized financial services (DeFi), today announced that it has gone live on mainnet. The protocol is facilitating transactions with customers, such as Oldham Global, demonstrating successful integrations after an extensive testing period and security audit review with ChainSecurity.',
      date: '05/02/2022'
    },
    {
      title: 'Vesto CEO Chris McGregor - International Tech Summit Bermuda 2022',
      url: '/news/international-tech-summit-bermuda-2022-crypto-defi-panel',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: 'Vesto CEO, Chris McGregor, speaks about innovation, security, and emerging trends on the Crypto & Defi Panel at the 2022 International Tech Summit in Bermuda.',
      date: '04/27/2022'
    },
    {
      title: 'Vesto Partners with Element Finance to Bring DeFi to Broader Set of Financial Institutions',
      url: 'https://www.prnewswire.com/news-releases/vesto-partners-with-element-finance-to-bring-defi-to-broader-set-of-financial-institutions-301514159.html',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: 'Vesto, a comprehensive, multi-token protocol for decentralized financial services, today announced that it is bringing to market  DeFi products that meet the risk tolerances and yield generation goals of financial services organizations, in partnership with Element Finance.',
      date: '03/31/2022'
    },
    {
      title: 'Part 2: Must High-Tech Expo Smart Payment Day featuring panelist Wolfgang Decker, Vesto CSO and co-founder.',
      url: 'https://vesto.s3.us-west-2.amazonaws.com/videos/wolfgang-panel-2.mp4',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: 'In this conference panelists deep dive and explore the latest trends and innovations on the Smart Payment market, both for in-store and on-line / digital payments, including Payment cards innovations, POS, Biometrics, Bitcoin, Cryptocurrencies and Banking transformation.',
      date: '01/12/2022'
    },
    {
      title: 'Must High-Tech Expo Smart Payment Day featuring panelist Wolfgang Decker, Vesto CSO and co-founder.',
      url: 'https://vesto.s3.us-west-2.amazonaws.com/videos/wolfgang-panel.mp4',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: 'In this conference panelists deep dive and explore the latest trends and innovations on the Smart Payment market, both for in-store and on-line / digital payments, including Payment cards innovations, POS, Biometrics, Bitcoin, Cryptocurrencies and Banking transformation.',
      date: '01/12/2022'
    },
    {
      title: 'Vesto and Matryoshka Capital Announce they have entered into a definitive Pilot License Agreement to deploy Vesto\'s Multi-Token On-Chain Protocol for seamless access into DeFi.',
      url: 'https://www.prnewswire.com/news-releases/vesto-and-matryoshka-capital-announce-they-have-entered-into-a-definitive-pilot-license-agreement-to-deploy-vestos-multi-token-on-chain-protocol-for-seamless-access-into-defi-301422571.html?tc=eml_cleartime',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: "Vesto, a market leading Multi-Token On-Chain Protocol for seamless access into DeFi, has signed a license agreement with Matryoshka Capital.Vesto's protocol is an easy to use but powerful gateway into the cryptocurrency ecosystem, enabling the next generation of DeFi, dApps, and protocols.",
      date: '11/11/2021'
    },
    {
      title: "Watch Vesto's Mariano Conti at the DeFi-ing Expectations Conference",
      url: 'https://vesto.s3.us-west-2.amazonaws.com/videos/mariano-at-defing-expectations.mp4',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: 'Mariano was a featured guest speaker for the Defi to the Moon panel at the Defi-ing Expectations Conference hosted by TabbFORUM & Global DCA',
      date: '11/10/2021'
    },
    {
      title: "Vesto and Stably Announce a Strategic Partnership to leverage each other's platforms to bring real world Tokenized assets to the masses",
      url: 'https://www.prnewswire.com/news-releases/vesto-and-stably-announce-a-strategic-partnership-to-leverage-each-others-platforms-to-bring-real-world-tokenized-assets-to-the-masses-301394613.html?tc=eml_cleartime',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: 'Stably, a market leading technology platform provider of enterprise asset tokenization solutions has signed an API license with Vesto for its core protocol-level crypto infrastructure.',
      date: '10/06/2021'
    },
    {
      title: 'Vesto and Oldham Global Announce a Strategic Partnership To Provide Alternative Pharmaceutical Financing',
      url: 'https://www.linkedin.com/posts/travis-mcgregor-33a41219_vesto-and-oldham-global-announce-a-strategic-activity-6838646680062169089-l5C7',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: 'Vesto and Oldham Global have partnered to provide advanced, efficient, and alternative lending options to finance high price pharmaceuticals by use of advanced DeFi offered by Vesto’s, always on-chain, vCore-Infrastructure.',
      date: '08/31/2021'
    },
    {
      title: 'Vesto and FinClusive Announce a Strategic Partnership',
      url: 'https://www.linkedin.com/posts/travis-mcgregor-33a41219_vesto-and-finclusive-announce-their-global-activity-6837090771578052608-dTiX',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: 'Joint technology significantly increases the banking compliance and regulatory support of Vesto’s crypto banking-as-a-service platform by using FinClusive’s Compliance-as-a-Service',
      date: '08/25/2021'
    },
    {
      title: 'Vesto welcomes Stephan K. W. Stamm as SVP of Compliance & Regulatory.',
      url: 'https://www.linkedin.com/posts/travis-mcgregor-33a41219_welcome-to-the-vesto-team-stephan-stamm-activity-6811103007225323520-7hTJ',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: 'Stephan is a seasoned German executive officer with a long-proven track record in international Banking and Management Consulting industries.',
      date: '06/10/2021'
    },
    {
      title: 'Vesto and Ether Cards Partner to Scale NFTs Globally!',
      url: 'https://www.linkedin.com/posts/travis-mcgregor-33a41219_vesto-ether-cards-partner-to-scale-nfts-activity-6807828708267843584-Zid-',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: 'With the announcement of its strategic partnership with Ether Cards, Vesto expands the breadth of its Crypto Banking-as-a-Service offerings.',
      date: '06/02/2021'
    },
    {
      title: 'Vesto Welcomes Eloisa Cadenas as Strategic Crypto Advisor!\n',
      url: 'https://www.linkedin.com/posts/travis-mcgregor-33a41219_welcome-to-the-vesto-team-eloisa-activity-6795861073837850624-4r6t',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: 'We are glad to welcome Eloisa Cadenas as Strategic Crypto Advisor in Mexico.',
      date: '03/31/2021'
    },
    {
      title: 'Vesto Welcomes its new Migrant Worker Outreach Team Leader!',
      url: 'https://www.linkedin.com/posts/travis-mcgregor-33a41219_tony-herrera-joins-the-vesto-team-activity-6777625032932884480-0CN2',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: 'We are glad to welcome Tony Herrera to the Vesto Team as the Director of Community Relations!',
      date: '03/11/2021'
    },
    {
      title: 'Vesto welcomes Mariano Conti as a Technical Advisor for DeFi.',
      url: 'https://www.linkedin.com/posts/travis-mcgregor-33a41219_welcome-to-the-vesto-team-mariano-activity-6768372977114464256--DQj',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: 'Vesto is proud to announce that legendary crypto and DeFi tech expert Mariano Conti has joined the team as we accelerate our mission to revolutionize digital banking.',
      date: '01/20/2021'
    },
    {
      title: 'Vesto proudly welcomes Welcomes Enrique Nassim Zablah to our Team!',
      url: 'https://www.linkedin.com/posts/travis-mcgregor-33a41219_enrique-nassim-zablah-joins-as-honduras-country-activity-6758048204085964800-pMtD',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: 'We are glad to welcome Enrique Nassim Zablah to the Vesto Team as the Honduras Country Manager. Enrique joins Vesto at a critical time in history. As of this year, top bank regulators are supporting and providing guidance that stablecoins',
      date: '01/20/2021'
    },
    {
      title: 'Vesto proudly welcomes Welcomes Oscar Márquez-Mees to our Team!',
      url: 'https://www.linkedin.com/posts/travis-mcgregor-33a41219_oscar-joins-vesto-as-managing-director-of-activity-6757099551263481856-xqG9',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: 'We are glad to welcome Oscar Márquez-Mees to the Vesto team as our new Managing Director LATAM. Oscar joins Vesto with a rich and long array of professional experience, that makes him an ideal match with Vesto’s purposes.',
      date: '01/14/2021'
    },
    {
      title: 'Vesto proudly welcomes Nisa Amoils to its board of advisors.',
      url: 'https://www.linkedin.com/posts/travis-mcgregor-33a41219_nisa-amoils-joins-vestos-advisor-team-activity-6753106814780502016-4KoR/',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: 'Such a pleasure and an honor to have Nisa join our Advisor Team! As Vesto goes “live” in the marketplace this Q1 - 2021.',
      date: '12/08/2020'
    },
    {
      title: 'Vesto proudly welcomes Ben Cade to its board of advisors.',
      url: 'https://www.linkedin.com/feed/update/urn:li:activity:6737166692289523712',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: 'Vesto is today proud to announce that cyber security and growth tech expert Ben Cade has joined the team as we accelerate our mission to disrupt digital banking.',
      date: '11/24/2020'
    },
    {
      title: 'Vesto proudly welcomes Lou Kerner to its board of advisors.',
      url: 'https://www.linkedin.com/feed/update/urn:li:activity:6734979107932778497',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: 'US, UK, and Germany-based Vesto is proud to have Lou Kerner join its elite team of advisors.  Lou was a Wall Street analyst and company Founder before becoming a VC and emerging as one of the most followed thought leaders in the crypto industry globally.',
      date: '11/18/2020'
    },
    {
      title: 'Vesto partners with Onfido',
      url: 'https://www.linkedin.com/feed/update/urn:li:activity:6734979107932778497',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: 'US and Germany-based Vesto is partnering with global identity verification and authentication provider, Onfido, in its quest to provide the most secure and transparent crypto banking-as-a-service solution - now with banking grade KYC (“Know Your Customer” – i.e. customer identity verification).',
      date: '11/02/2020'
    },
    {
      title: 'Vesto - Coincover Press Release',
      url: 'https://www.linkedin.com/feed/update/urn:li:ugcPost:6713903123834007552/',
      author: {
        name: 'Travis McGregor',
        photo: './assets/images/team/travis.jpg'
      },
      category: 'Vesto News',
      snippet: "Following up from Professor Carsten Bartsch's (CMO of Vesto) article this month, I'm excited that Vesto and Coincover have formerly announced their partnership. This again demonstrates that Vesto is at the forefront of Crypto Banking-as-a-Service tech globally.",
      date: '09/14/2020'
    },
    {
      title: 'The Safest Crypto Solution You Can Find',
      url: 'https://www.linkedin.com/posts/carstenbartsch_the-safest-crypto-solution-you-can-find-activity-6709512136558686208-cwdM/',
      author: {
        name: 'Carsten Bartsch',
        photo: './assets/images/team/carsten.jpg'
      },
      category: 'Vesto News',
      date: '09/08/2020'
    },
    {
      title: 'A new era in cryptobanking!',
      url: 'https://www.linkedin.com/posts/carstenbartsch_us-banks-now-officially-entering-the-cryptocurrency-activity-6704742928398462976-ZtF7/',
      author: {
        name: 'Carsten Bartsch',
        photo: './assets/images/team/carsten.jpg'
      },
      category: 'Vesto News',
      date: '08/25/2020'
    },
    {
      title: 'Vesto Featured on Digital Perspectives with Brad Kimes',
      url: 'https://www.youtube.com/watch?v=QH_sXEKPWYM',
      author: {
        name: 'Vesto Team',
        photo: './assets/images/vesto-icon-square-lilac.svg'
      },
      category: 'Vesto News',
      snippet: "Vesto's own Chris McGregor, Wolfgang Decker, and Travis McGregor discuss the Vesto platform and the future of banking with Brad Kimes",
      date: '08/26/2020'
    },
    {
      title: 'Vesto - The Secure Cryptocurrency Solution',
      url: 'https://www.linkedin.com/feed/update/urn:li:ugcPost:6699294921561530368/',
      author: {
        name: 'Carsten Bartsch',
        photo: './assets/images/team/carsten.jpg'
      },
      category: 'Vesto News',
      date: '08/12/2020'
    },
    {
      title: 'Vesto MVP Press Release - Crypto Banking-as-a-Service',
      url: 'https://www.linkedin.com/posts/travis-mcgregor-33a41219_vesto-mvp-release-crypto-banking-as-a-service-activity-6693960389740384256--IJf',
      author: {
        name: 'Travis McGregor',
        photo: './assets/images/team/travis.jpg'
      },
      category: 'Vesto News',
      date: '07/27/2020'
    },
    {
      title: 'Banks in US Can Now Offer Crypto Custody Services, Regulator Says',
      url: 'https://www.coindesk.com/banks-in-us-can-now-offer-crypto-custody-services-regulator-says',
      author: {
        name: 'Nikhilesh De',
        photo: 'https://static.coindesk.com/wp-content/uploads/2019/11/nik-249x250.png'
      },
      category: 'Cryptocurrency',
      date: '07/22/2020'
    }
  ];

  constructor() {}
}
