<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="flex items-center">
    <h3 class="section-number mr-2"><i class="fal fa-store-alt"></i></h3>
    <div>
      <h3 class="section-number">{{ store ? 'Update ' + store?.name : 'Create store'}}</h3>
      <span class="text-sm">{{ merchant.name }}</span>
    </div>
  </div>
</div>
<mat-dialog-content>
  <ng-container [ngSwitch]="step">
    <form [formGroup]="formGroup" (ngSubmit)="submit()" *ngSwitchCase="'FORM_GROUP'">
      <div class="vesto-input vesto-input-inline mb-4">
        <label class="inline" for="name">Store Name</label>
        <input type="text" focusMe="true" formControlName="name" id="name" name="name" />
      </div>
      <div class="vesto-input vesto-input-inline my-4">
        <label for="address">Address</label>
        <input type="text" id="address" ngx-google-places-autocomplete #placesRef="ngx-places" (onAddressChange)="addressChange($event)" placeholder="Enter your address..." name="address" formControlName="address" />
      </div>

      <gbl-alert [type]="error" [message]="error"></gbl-alert>

      <div class="text-right mt-3">
        <button class="button button-primary" type="submit">{{ store ? 'Update' : 'Create' }}</button>
      </div>
    </form>

    <section *ngSwitchCase="'SUCCESS'">
      <gbl-lottie-player></gbl-lottie-player>
    </section>

    <section *ngSwitchCase="'BUSY'" class="mt-4">
      <div class="placeholder-long bg-flash"></div>
      <div class="placeholder-area bg-flash mt-4"></div>
    </section>

    <section *ngSwitchCase="'DETAIL'" class="slide-in">
      <div class="text-center">
        <p class="mt-4">Success!</p>
      </div>
      <div class="p-3 mt-4 bg-trans-blue rounded-lg text-center">
        <p class="text-sm" *ngIf="!store; else defaultTemplate">Created store "{{ storeName }}"</p>
        <ng-template #defaultTemplate>
          <p class="text-sm">Updated "{{ store?.name }}"</p>
        </ng-template>
      </div>
      <button class="button button-primary my-4 mx-auto w-full" (click)="close()">Close</button>
    </section>
  </ng-container>
</mat-dialog-content>
