<div class="mb-3 text-right">
  <a class="text-sm underline" (click)="createMerchant()"><i class="fal fa-plus-circle"></i> Create Merchant</a>
</div>
<div class="bg-white shadowed rounded p-3">
  <ng-container *ngIf="merchants?.length > 0; else noMerchantsTemplate">
    <div *ngFor="let merchant of merchants; let i = index">
      <div class="bg-trans-blue p-3 cursor-pointer" [class.mt-3]="i > 0">
        <div class="flex justify-between" [ngClass]="merchant.id === selectedMerchant?.id ? 'pb-2 border-bottom-light' : ''" (click)="selectMerchant(merchant)">
          <span class="text-lg font-bold">{{merchant.name}}</span>
          <i class="fal fa-chevron-down" [class.rotate-180]="merchant.id === selectedMerchant?.id"></i>
        </div>

        <section *ngIf="merchant.id === selectedMerchant?.id" [@expandCollapseDetails]>
          <!-- MERCHANT INFO -->
          <div class="grid grid-cols-4 gap-3 mt-3">
            <div class="text-sm">
              <p>Created:</p>
              <b>{{ merchant.created | date }}</b>
            </div>
            <div class="text-sm">
              <p>Updated:</p>
              <b>{{ merchant.updated | date }}</b>
            </div>
            <div class="text-sm">
              <p>Status:</p>
              <b>{{ merchant.status | titlecase }}</b>
            </div>

            <div class="text-sm">
              <p>Fee Type:</p>
              <b>{{ merchant.feeType | titlecase }}</b>
            </div>
            <div class="text-sm" *ngIf="merchant.feeType !== MerchantFeeType.PERCENTAGE">
              <p>Fixed Fee:</p>
              <b>{{ merchant.feeFixed | currency }}</b>
            </div>
            <div class="text-sm" *ngIf="merchant.feeType !== MerchantFeeType.FIXED">
              <p>Fee Percent:</p>
              <b>{{ merchant.feePercentage }}%</b>
            </div>
            <div [ngClass]="merchant.feeType !== MerchantFeeType.BOTH ? 'col-span-2' : ''"></div>

            <div class="text-sm col-span-2">
              <p>Address:</p>
              <b>{{ merchant.address }}</b>
            </div>
            <div class="text-sm col-span-2 text-right">
              <button (click)="changeSettings(merchant)" class="button button-primary button-sm">Edit</button>
            </div>
          </div>

          <!-- USERS -->
          <div class="flex justify-between border-bottom-light mt-4">
            <span class="text-sm font-bold">Users</span>
            <a class="text-sm underline" (click)="openInviteToMerchant()"><i class="fal fa-plus-circle"></i> Invite User</a>
          </div>

          <div *ngFor="let user of users; let i = index" class="flex bg-white mt-2 p-2 justify-between items-center">
            <div class="flex-grow">
              <span class="text-sm font-bold block">{{ user.firstName }} {{ user.lastName }}</span>
              <span class="text-xs">{{ user.email }}</span>
            </div>
            <div *ngIf="UserUtil.hasRole(user, RoleType.MERCHANT)"><i class="fal fa-store-alt"></i></div>
            <div [matMenuTriggerFor]="menu" class="ml-5 mr-2">
              <i class="fal fa-ellipsis-h text-3xl"></i>
            </div>
            <mat-menu #menu="matMenu">
              <button mat-menu-item *ngIf="!UserUtil.hasRole(user, RoleType.MERCHANT)" (click)="promoteUser(user.id)">
                Promote
              </button>
              <button mat-menu-item *ngIf="UserUtil.hasRole(user, RoleType.MERCHANT)" (click)="demoteUser(user.id)">
                Demote
              </button>
              <button mat-menu-item (click)="removeUser(user.id)">Remove</button>
            </mat-menu>
          </div>

          <!-- STORES -->
          <div class="flex justify-between border-bottom-light mt-4">
            <span class="text-sm font-bold">Stores</span>
            <a class="text-sm underline" (click)="openCreateStore()"><i class="fal fa-plus-circle"></i> Create Store</a>
          </div>

          <ng-container *ngIf="stores && stores.length > 0; else noStoresTemplate">
            <div *ngFor="let store of stores; let i = index" class="flex bg-white mt-2 p-2 justify-between items-center">
              <div class="flex-grow">
                <span class="text-sm font-bold block">{{ store.name }}</span>
                <span class="text-xs">{{ store.address }}</span>
              </div>
              <div [matMenuTriggerFor]="menu" class="ml-5 mr-2">
                <i class="fal fa-ellipsis-h text-3xl"></i>
              </div>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="openChangeStore(store)">
                  Edit
                </button>
                <button mat-menu-item (click)="removeStore(store.id)">
                  Remove
                </button>
              </mat-menu>
            </div>
          </ng-container>

          <ng-template #noStoresTemplate>
            <p class="my-4 text-center text-sm">No stores found.</p>
            <div class="text-center">
              <button class="button button-primary button-sm" (click)="openCreateStore()">Create Store</button>
            </div>
          </ng-template>
        </section>
      </div>
    </div>
  </ng-container>

  <ng-template #noMerchantsTemplate>
    <div class="bg-trans-blue p-3">
      <span class="text-lg font-bold">No merchants yet, please create a merchant</span>
    </div>
  </ng-template>
</div>
