import {RxStomp, RxStompConfig, RxStompState} from '@stomp/rx-stomp';
import {inject, Injectable} from '@angular/core';
import {Message} from '@stomp/stompjs';
import {StorageKeys} from "./storage.service";
import {CoreService} from "./core.service";
import {ethers} from "ethers";
import {EnvironmentToken} from "@vesto/ngx-vesto";
import {LogService} from './log.service';

@Injectable({
  providedIn: 'root'
})
export class StompService {
  private rxStomp: RxStomp = null;
  private watchUserSubscription$: any = null;
  private watchTopicSubscription$: any = null;
  private connectedSubscription$: any = null;
  private environment = inject(EnvironmentToken);
  private log = inject(LogService);

  constructor() {
    setInterval(() => {
      const key = CoreService.storageService.getItem(StorageKeys.KEY);
      if (!key) {
        this.disconnect();
        return;
      }

      if (!this.rxStomp) {
        this.connect(key);
      }
    }, 750);
  }

  public connect(
    key: string
  ) {
    const hexKey = new Buffer(key, 'hex').toString();
    const rxStompConfig: RxStompConfig = {
      brokerURL: this.environment.vesto.wsUrl,
      connectHeaders: this.getConnectHeaders(hexKey),
      heartbeatIncoming: 0,
      heartbeatOutgoing: 20000,
      reconnectDelay: 200,
      beforeConnect: (rxStomp: RxStomp) => {
      },
      debug: (msg: string): void => {
       // this.log.debug(new Date(), msg);
      },
    };

    this.rxStomp = new RxStomp();
    this.rxStomp.configure(rxStompConfig);
    this.rxStomp.activate();
    this.connectedSubscription$ = this.rxStomp
      .connected$
      .subscribe(rxStompState => {
        switch (rxStompState) {
          case RxStompState.OPEN:
            // User messages.
            const signingAddress = new ethers.Wallet(hexKey).address;
            this.watchUserSubscription$ = this.rxStomp
              .watch(`/user/${signingAddress}/messages`)
              .subscribe((message: Message) => {
                const body = JSON.parse(message.body);
                const payload = JSON.parse(Buffer.from(body.payload, 'base64').toString("utf8"));

                switch (payload.type) {
                  case 'system':
                    CoreService.systemFacade.set(payload.data);
                    break;
                  case 'institution':
                    CoreService.institutionFacade.set(payload.data);
                    break;
                  case 'user':
                    CoreService.userFacade.set(payload.data);
                    break;
                  case 'account':
                    CoreService.accountFacade.set(payload.data);
                    break;
                  case 'transaction':
                    CoreService.transactionFacade.set(payload.data);
                    break;
                  case 'contract':
                    CoreService.accountFacade.setContract(payload.data);
                    break;
                  case 'token':
                    CoreService.institutionFacade.setToken(payload.data);
                    break;
                  default:
                    CoreService.log.warn('invalid STOMP data type');
                }
              });

            // Topic (broadcast) messages.
            this.watchTopicSubscription$ = this.rxStomp
              .watch(`/topic/messages`)
              .subscribe((message: Message) => {
                const body = JSON.parse(message.body);
                const payload = JSON.parse(Buffer.from(body.payload, 'base64').toString("utf8"));
                switch (payload.type) {
                  case 'gastank':
                    CoreService.gasTankFacade.set(payload.data);
                    break;
                  default:
                    CoreService.log.warn('invalid STOMP data type');
                }
              });
            break;
          case RxStompState.CLOSED:
            this.disconnect();
            break;
        }
      });
  }

  disconnect() {
    if (!!this.watchUserSubscription$) {
      this.watchUserSubscription$.unsubscribe();
      this.watchUserSubscription$ = null;
    }
    if (!!this.watchTopicSubscription$) {
      this.watchTopicSubscription$.unsubscribe();
      this.watchTopicSubscription$ = null;
    }
    if (!!this.connectedSubscription$) {
      this.connectedSubscription$.unsubscribe();
      this.connectedSubscription$ = null;
    }
    if (!!this.rxStomp) {
      this.rxStomp.deactivate();
      this.rxStomp = null;
    }
  }

  private getConnectHeaders(
    key: string
  ) {
    const signingKey = new ethers.utils.SigningKey(key);
    const timestamp = (new Date().getTime() / 1000).toFixed(0).toString(); // TODO: Revise using second vs. milliseconds!!!
    const message = `${timestamp}STOMP-CONNECT`;
    const hash = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(message));
    const signature = signingKey.signDigest(hash);

    return {
      V: signature.v.toString(),
      R: signature.r,
      S: signature.s,
      Timestamp: timestamp
    };
  }
}
