import { Component, OnInit } from '@angular/core';
import { BaseComponent, CoreService, StorageKeys, UiActions } from '@vesto/xplat/core';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Blockchain, IToken, ITransaction, TokenType } from '@vesto/vesto';
import { ofType } from '@ngrx/effects';
import { TransactionActions } from '@vesto/ngx-vesto/state';

@Component({
  selector: 'gbl-choose-token-modal',
  templateUrl: './choose-token-modal.component.html'
})
export class ChooseTokenModalComponent extends BaseComponent implements OnInit {
  step: 'CHOOSE_TOKEN' | 'TWO_FACTOR' | 'BUSY' | 'SUCCESS' | 'TRANSACTION_DETAIL' = 'CHOOSE_TOKEN';
  action: 'mint';
  tokens: IToken[] = [];
  error: string;
  selectedToken: IToken;
  transaction: ITransaction;

  ngOnInit() {
    CoreService.institutionFacade.institution$
      .pipe(
        filter((institution) => !!institution),
        takeUntil(this.destroy$)
      )
      .subscribe((institution) => {
        institution.tokens.forEach((token) => {
          if (token.type === TokenType.VERC20 && token.blockchain === Blockchain.ETHEREUM) {
            this.tokens.push(token);
          }
        });
      });
  }

  sendSmsCode(isResendSms: boolean) {
    CoreService.transactionFacade.sendSmsCode();

    if (isResendSms) {
      CoreService.uiFacade.notification(`SMS code sent!`);
    }
  }

  chooseToken(selectedToken: IToken) {
    this.selectedToken = selectedToken;
    this.step = 'TWO_FACTOR';
  }

  confirmCode(code: string) {
    switch (this.action) {
      case 'mint':
        CoreService.transactionFacade.mint(this.selectedToken.symbol, code);
        break;
    }
    this.step = 'BUSY';

    CoreService.actions$.pipe(ofType(TransactionActions.mintSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      CoreService.store.dispatch(TransactionActions.sign({ transaction: result.transaction, code, key: new Buffer(CoreService.storageService.getItem(StorageKeys.KEY), 'hex').toString() }));
    });

    CoreService.actions$.pipe(ofType(TransactionActions.mintFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
      this.step = 'CHOOSE_TOKEN';
    });

    CoreService.actions$.pipe(ofType(TransactionActions.signSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.step = 'SUCCESS';
      this.transaction = result.transaction;
      CoreService.windowService.setTimeout(() => {
        this.step = 'TRANSACTION_DETAIL';
      }, 1600);
    });

    CoreService.actions$.pipe(ofType(TransactionActions.signFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
    });
  }

  close() {
    CoreService.store.dispatch(UiActions.closeModal());
  }
}
