import { BaseApi } from './base-api';
import { IAccount } from '../interfaces/account.interface';
import { IEnvironment } from '../interfaces/environment.interface';
import { IHttpClient } from '../interfaces/http.interface';
import { IThreadHandler } from '../interfaces/thread.interface';
import { IUser } from '../interfaces/user.interface';
import { ethers } from 'ethers';
import * as CryptoJS from 'crypto-js';
import { Observable } from 'rxjs';

export class SignUpApi extends BaseApi {
  public accounts: IAccount[];

  constructor(protected environment: IEnvironment, protected httpClient: IHttpClient, protected threadHandler: IThreadHandler) {
    super(environment);
  }

  findUserByToken(token: string) {
    return this.httpClient.get(this.formatUrl(`/v${this.environment.vesto.version}/sign-up/find-user-by-token/${token}`), { headers: this.getHeaders() });
  }

  signUp(institutionId: string, token: string, user: IUser, password: string): Observable<any> {
    const signUpNow = (): Observable<any> => {
      const wallet = ethers.Wallet.createRandom();
      return this.httpClient.post(
        this.formatUrl(`/v${this.environment.vesto.version}/sign-up/${institutionId}`),
        {
          token,
          user,
          passwordHash: ethers.utils.keccak256(ethers.utils.toUtf8Bytes(password)),
          signingAddress: wallet.address,
          privateKey: CryptoJS.AES.encrypt(wallet.privateKey, password.trim()).toString()
        },
        { headers: this.getHeaders() }
      );
    };
    if (this.threadHandler) {
      return this.threadHandler.runOnBackgroundThread(signUpNow);
    } else {
      return signUpNow();
    }
  }

  sendSmsCode(token: string, mobile: string) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/sign-up/send-sms-code/${token}`), { mobile }, { headers: this.getHeaders() });
  }

  verifySmsCode(token: string, code: string) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/sign-up/verify-sms-code/${token}/${code}`), null, { headers: this.getHeaders() });
  }

  sendEmail(token: string) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/sign-up/send-email/${token}`), null, { headers: this.getHeaders() });
  }

  verifyEmail(token: string) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/sign-up/verify-email/${token}`), null, { headers: this.getHeaders() });
  }
}
