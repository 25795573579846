import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { VestoSelectors, VestoState } from '../vesto.state';
import { Observable } from 'rxjs';
import { MarketDataActions } from './market-data.actions';

@Injectable({
  providedIn: 'root'
})
export class MarketDataFacade {
  deFi$: Observable<any> = this.store.pipe(select(VestoSelectors.deFi));
  deFiHistories$: Observable<any[]> = this.store.pipe(select(VestoSelectors.deFiHistories));

  constructor(private store: Store<VestoState.IState>) {}

  getDeFi() {
    this.store.dispatch(MarketDataActions.getDeFi());
  }

  /**
   *
   * @param period - 1w, 1m, 3m, 1y or all
   */
  getDeFiHistory(period: string) {
    this.store.dispatch(MarketDataActions.getDeFiHistory({ period }));
  }
}
