import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class FormService {
  validate(formGroup: UntypedFormGroup): { ok: boolean; error$: BehaviorSubject<string> } {
    for (const key of Object.keys(formGroup.controls)) {
      const control = formGroup.get(key);
      if (!control.validator) {
        continue;
      }

      const validationResult = control.validator(control);
      if (validationResult == null) {
        continue;
      }

      const error$ = new BehaviorSubject(`invalidMessages.${key}`);
      formGroup.valueChanges.pipe(take(1)).subscribe((result) => {
        error$.next(null);
        error$.complete();
      });
      return { ok: false, error$ };
    }

    return { ok: true, error$: null };
  }
}
