<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <h3 class="section-number"><i class="fal fa-university"></i> {{ action === 'create' ? 'Create Institution' : 'Change Settings'}}</h3>
</div>
<mat-dialog-content>
  <ng-container [ngSwitch]="step">
    <form [formGroup]="formGroup" (ngSubmit)="submit()" *ngSwitchCase="'FORM_GROUP'">
      <div class="vesto-input mb-2">
        <label class="inline" for="name">Institution Name</label>
        <input type="text" focusMe="true" formControlName="name" id="name" name="name" />
      </div>

      <section class="grid grid-cols-2 gap-3 mb-2">
        <div class="vesto-input" *ngIf="action === 'create'">
          <label class="inline" for="adminEmail">Admin Email</label>
          <input type="email" formControlName="adminEmail" id="adminEmail" name="adminEmail" />
        </div>

        <div class="vesto-input">
          <label class="inline" for="iosAppId">Apple App ID</label>
          <input type="text" formControlName="iosAppId" id="iosAppId" name="iosAppId" />
        </div>

        <div class="vesto-input">
          <label class="inline" for="androidAppId">Android App ID</label>
          <input type="text" formControlName="androidAppId" id="androidAppId" name="androidAppId" />
        </div>

        <div class="vesto-input">
          <label class="inline" for="url">URL</label>
          <input type="text" formControlName="url" id="url" name="url" />
        </div>

        <div class="vesto-input col-span-2">
          <label class="inline" for="referrer">Referrer</label>
          <input type="text" formControlName="referrer" id="referrer" name="referrer" />
        </div>
      </section>
      <p class="text-sm font-bold">
        Fees
      </p>

      <section class="grid grid-cols-2 gap-3 my-4 align-items-center">
        <div class="vesto-input">
          <ng-select formControlName="feeType" id="feeType" name="feeType">
            <ng-option [value]="InstitutionFeeType.FIXED">
              Fixed
            </ng-option>
            <ng-option [value]="InstitutionFeeType.PERCENTAGE">
              Percentage
            </ng-option>
            <ng-option [value]="InstitutionFeeType.BOTH">
              Both
            </ng-option>
          </ng-select>
        </div>

        <div class="vesto-input vesto-input-inline" [hidden]="formGroup.controls.feeType.value === InstitutionFeeType.PERCENTAGE">
          <label>Fee</label>
          <gbl-input class="currency-input" mask="currency" formControlName="feeFixed"></gbl-input>
        </div>

        <div class="vesto-input vesto-input-inline"  [hidden]="formGroup.controls.feeType.value === InstitutionFeeType.FIXED">
          <label>Fee</label>
          <gbl-input class="currency-input" mask="percent" formControlName="feePercent"></gbl-input>
        </div>
      </section>

      <div class="flex text-sm items-center">
        <span class="mr-3">Merchant Enabled</span>
        <mat-slide-toggle formControlName="merchantEnabled"></mat-slide-toggle>
      </div>

      <gbl-alert [type]="error" [message]="error"></gbl-alert>

      <div class="text-right mt-3">
        <button class="button button-primary" type="submit">{{ action === 'create' ? 'Create' : 'Change' }}</button>
      </div>
    </form>

    <section *ngSwitchCase="'BUSY'" class="mt-4">
      <div class="placeholder-long bg-flash"></div>
      <div class="placeholder-area bg-flash mt-4"></div>
    </section>

    <section *ngSwitchCase="'SUCCESS'">
      <div class="relative mt-16">
        <div style="width: 350px; height: 350px; overflow: hidden; margin: -24px auto 0">
          <gbl-lottie-player></gbl-lottie-player>
        </div>
      </div>
    </section>

    <section *ngSwitchCase="'DETAIL'" class="slide-in">
      <div class="text-center">
        <p class="mt-4">Success!</p>
      </div>
      <div class="p-3 mt-3 bg-trans-blue rounded-lg text-center">
        <p class="text-sm" *ngIf="action === 'create'; else defaultTemplate">Created {{ institutionName }}</p>
        <ng-template #defaultTemplate>
          <p class="text-sm">Updated {{ institution.name }}</p>
        </ng-template>
      </div>
      <button class="button button-primary my-3 mx-auto w-full" (click)="close()">Close</button>
    </section>

  </ng-container>
</mat-dialog-content>
