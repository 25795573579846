import { Component } from '@angular/core';
import { CreateUserBaseComponent } from '@vesto/xplat/features';
@Component({
  selector: 'gbl-create-user',
  templateUrl: 'create-user.component.html'
})
export class CreateUserComponent extends CreateUserBaseComponent {
  constructor() {
    super();
  }
}
