<div class="text-center">
  <span class="fa-stack fa-3x inline-block my-6">
    <i class="fal fa-circle-notch fa-stack-2x fa-spin text-lilac"></i>
    <i class="fal fa-envelope-open-text fa-stack-1x"></i>
  </span>
  <h3 class="mb-5">Confirm Your Email</h3>
  <small
    >We've sent an email to <strong>{{ user?.email }}</strong
    >. Click the link in the email to confirm your email address and continue.</small
  >
  <div class="text-center"><hr class="border w-24 mt-10" /></div>
  <div class="flex items-center justify-center">
    <span class="text-grey-dark text-sm">Didn't receive an email? </span>
    <button class="button-sm button button-xs outline-lilac ml-2" (click)="sendEmail()">Resend</button>
  </div>

  <gbl-alert [type]="error" [message]="error"> </gbl-alert>
</div>
