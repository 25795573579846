import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';
import { DashboardActions } from './dashboard.actions';
import { from, Observable } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { UiActions } from '@vesto/xplat/core';
import { ContactActions } from '@vesto/ngx-vesto/state';

import { DashboardState } from './dashboard.state';

@Injectable()
export class DashboardContactEffects {
  constructor(private store: Store<DashboardState.IState>, private actions$: Actions) {}

  emailReport$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(ContactActions.emailReport),
        mergeMap(({}) => {
          return from([UiActions.showSpinner()]);
        })
      )
  );

  emailReportSuccess$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(ContactActions.emailReportSuccess),
        mergeMap(({}) => {
          return from([DashboardActions.closeModal(), UiActions.hideSpinner(), UiActions.notification({ message: 'Report sent. Please check your email.' })]);
        })
      )
  );

  emailReportFailure$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(ContactActions.emailReportFailure),
        mergeMap(({ apiError }) => {
          return from([UiActions.hideSpinner(), UiActions.notification({ message: apiError.error })]);
        })
      )
  );
}
