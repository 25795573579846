import { IEnvironment } from '../interfaces/environment.interface';
import { IHttpClient } from '../interfaces/http.interface';
import { BaseApi } from './base-api';
import { IContact } from '../interfaces/contact.interface';

export class ContactsApi extends BaseApi {
  constructor(protected environment: IEnvironment, protected httpClient: IHttpClient) {
    super(environment);
  }

  emailReport(contact: IContact) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/contacts/email-report`), contact, { headers: this.getHeaders() });
  }
}
