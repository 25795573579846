import {createAction, props} from '@ngrx/store';
import {IApiError, IStore} from '@vesto/vesto';

export namespace StoreActions {
  export const find = createAction('[@vesto/store] find', props<{ id: string }>());

  export const findSuccess = createAction('[@vesto/store] findSuccess', props<{ store: IStore }>());

  export const findFailure = createAction('[@vesto/store] findFailure', props<{ apiError: IApiError }>());

  export const findByMerchant = createAction('[@vesto/store] findByMerchant', props<{ merchantId: string }>());

  export const findByMerchantSuccess = createAction('[@vesto/store] findByMerchantSuccess', props<{ stores: IStore[] }>());

  export const findByMerchantFailure = createAction('[@vesto/store] findByMerchantSuccess', props<{ apiError: IApiError }>());

  export const create = createAction('[@vesto/store] create', props<{ merchantId: string; name: string; address: string }>());

  export const createSuccess = createAction('[@vesto/store] createSuccess', props<{ store: IStore }>());

  export const createFailure = createAction('[@vesto/store] createFailure', props<{ apiError: IApiError }>());

  export const changeSettings = createAction('[@vesto/store] changeSettings', props<{ id: string; name: string; address: string }>());

  export const changeSettingsSuccess = createAction('[@vesto/store] changeSettingsSuccess', props<{ store: IStore }>());

  export const changeSettingsFailure = createAction('[@vesto/store] changeSettingsFailure', props<{ apiError: IApiError }>());

  export const remove = createAction('[@vesto/store] remove', props<{ id: string }>());

  export const removeSuccess = createAction('[@vesto/store] removeSuccess', props<{ store: IStore }>());

  export const removeFailure = createAction('[@vesto/store] removeFailure', props<{ apiError: IApiError }>());
}
