import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule]
})
export class NgxVestoModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: NgxVestoModule
  ) {
    if (parentModule) {
      throw new Error(`NgxVestoModule has already been loaded. Import in the AppModule only.`);
    }
  }
}
