import { IContract } from './contract.interface';
import { Blockchain } from '../enums/blockchain.enum';
import { TokenType } from '../enums/token-type.enum';
import { TokenStatus } from '../enums/token-status.enum';

export interface IToken {
  id: string;
  institutionId: string;
  contract: IContract;
  blockchain: Blockchain;
  type: TokenType;
  status: TokenStatus;
  symbol: string;
  underlyingSymbol: string;
  name: string;
  address: string;
  url: string;
  decimals: string;
  apy: number;
  underlyingBalance: number;
  totalSupply: number;
  totalUnderlyingSupply: number;
  totalUnderlyingSupplyLimit: number;
  deFiBalance: number;
  deFiUnderlyingBalance: number;
  ceFi: boolean;
}
