<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <h3 class="section-number"><i class="fal fa-user-plus"></i> Invite to {{ user?.merchant ? user?.merchant?.name : 'merchant' }}</h3>
</div>
<mat-dialog-content>
  <ng-container [ngSwitch]="step">
    <section *ngSwitchCase="'FORM_GROUP'">
      <p class="text-sm mb-3">This user will be able to make transactions on behalf of {{ user?.merchant ? user?.merchant?.name : 'the selected merchant' }}, any sales they make will got to {{ user?.merchant ? user?.merchant?.name : 'the selected merchant' }}'s operational account.</p>

      <form [formGroup]="formGroup" (ngSubmit)="inviteToMerchant()">
        <div class="vesto-input vesto-select mb-4" [hidden]="UserUtil.hasRole(user, RoleType.MERCHANT)">
          <ng-select formControlName="merchantId" placeholder="Merchant">
            <ng-option *ngFor="let merchant of merchants" [value]="merchant.id">
              {{ merchant.name }}
            </ng-option>
          </ng-select>
        </div>

        <div class="vesto-input mb-4">
          <label for="email">Email</label>
          <input type="text" formControlName="email" id="email" name="email" />
        </div>

      <!--<div class="vesto-input mb-4">
        <input focusMe="true" type="text" placeholder="Start typing" aria-label="Number" matInput [formControl]="formControl" [matAutocomplete]="auto" />
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="getOptionText" (optionSelected)="addToMerchant($event.option.value)">
          <mat-option *ngFor="let user of users$ | async" [value]="user"> {{ user.firstName }} {{ user.lastName }} {{ user.email }} </mat-option>
        </mat-autocomplete>
      </div>-->

        <gbl-alert [type]="error" [message]="error"></gbl-alert>

        <button class="button button-primary w-full mt-4" type="submit">Invite</button>
      </form>
    </section>

    <section *ngSwitchCase="'BUSY'" class="mt-4">
      <div class="placeholder-long bg-flash"></div>
      <div class="placeholder-area bg-flash mt-4"></div>
    </section>

    <section *ngSwitchCase="'SUCCESS'">
      <gbl-lottie-player></gbl-lottie-player>
    </section>

    <section *ngSwitchCase="'DETAIL'" class="slide-in">
      <div class="text-center">
        <p class="mt-4">Success!</p>
      </div>
      <div class="p-4 bg-trans-blue rounded-lg text-center">
        <p class="text-sm">
          You invited {{ inviteEmail }} to {{ inviteMerchant.name }}
        </p>
      </div>
      <button class="button button-primary my-4 mx-auto w-full" (click)="close()">Close</button>
    </section>
  </ng-container>
</mat-dialog-content>
