import { NgModule, Optional, SkipSelf, PLATFORM_ID, ModuleWithProviders, Inject } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { throwIfAlreadyLoaded } from '@vesto/xplat/utils';
import { CoreModule, PlatformLanguageToken, PlatformWindowToken, RouterToken, VestoServerWindow, WindowService, PlatformDeviceInfoToken, OnfidoSDKToken, PlatformLoaderService, CoreServices, ContentService, PlatformModalToken } from '@vesto/xplat/core';
import { isPlatformBrowser } from '@angular/common';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { WebWindowService } from './services/web-window.service';
import { EffectsModule } from '@ngrx/effects';
import { WebCoreEffects } from './state/web-core.effects';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

// bring in custom web services here...

// factories
export function winFactory(platformId, webWindowService: WebWindowService) {
  if (isPlatformBrowser(platformId)) {
    return webWindowService;
  }
  return new VestoServerWindow();
}

export function platformLangFactory() {
  const browserLang = window.navigator.language || 'en'; // fallback English
  // browser language has 2 codes, ex: 'en-US'
  return browserLang.split('-')[0];
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, `./assets/i18n/`, '.json');
}

export function loadingIndicatorFactory(spinner: NgxSpinnerService) {
  return {
    show: () => spinner.show(),
    hide: () => spinner.hide()
  };
}

export function platformDeviceInfoFactory(win: WindowService) {
  /**
   * Done here to ensure version info is available to the earliest api calls made on app launch
   */
  let versionName: string;
  let buildNumber: string;
  let deviceDetails: string;

  // TODO: web team - you could fill this in with various web info
  versionName = '1.0.0';
  buildNumber = '1.0.0';
  deviceDetails = 'browser'; // this can be a collection of any client details
  // console.log('version:', versionName, ' versionDisplay:', `v${versionName} (${buildNumber})`);
  return {
    version: versionName,
    versionDisplay: `v${versionName} (${buildNumber})`,
    os: 'web', // could report client platform
    deviceDetails
  };
}

export function onfidoSdkFactory() {
  // TODO: may want to remove DI Token for Onfido since sdk's will init differently between web/mobile
  return {
    init: null
  };
}

export const datepickerModuleForRoot: ModuleWithProviders<BsDatepickerModule> = BsDatepickerModule.forRoot();
// export const dropdownModuleForRoot: ModuleWithProviders<BsDropdownModule> = BsDropdownModule.forRoot();
// export const tooltipModuleForRoot: ModuleWithProviders<TooltipModule> = TooltipModule.forRoot();

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    NgxSpinnerModule,
    datepickerModuleForRoot,
    // dropdownModuleForRoot,
    // tooltipModuleForRoot,
    EffectsModule.forFeature([WebCoreEffects]),
    MatSnackBarModule,
    CoreModule.forRoot([
      {
        provide: PlatformLanguageToken,
        useFactory: platformLangFactory
      },
      {
        provide: PlatformWindowToken,
        useFactory: winFactory,
        deps: [PLATFORM_ID, WebWindowService]
      },
      {
        provide: PlatformLoaderService,
        useFactory: loadingIndicatorFactory,
        deps: [NgxSpinnerService]
      },
      {
        provide: PlatformModalToken,
        useClass: MatDialog
      },
      {
        provide: RouterToken,
        // just nullify: we rely on Router injection in ui.effect
        // however the token at least needs a provider
        useValue: null
      },
      {
        // stub since used in auth-http-interceptor
        // todo: could use this to fill in various browser/web info
        provide: PlatformDeviceInfoToken,
        useFactory: platformDeviceInfoFactory,
        deps: [WindowService]
      },
      {
        provide: OnfidoSDKToken,
        useFactory: onfidoSdkFactory
      }
    ]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ]
})
export class GblCoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: GblCoreModule,
    @Inject(PlatformLanguageToken) lang: string,
    translateService: TranslateService
  ) {
    throwIfAlreadyLoaded(parentModule, 'GblCoreModule');

    // ensure default platform language is set
    translateService.use(lang);
  }
}
