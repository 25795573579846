import { createAction, props } from '@ngrx/store';
import {IApiError, IMerchant, MerchantFeeType} from '@vesto/vesto';

export namespace MerchantActions {

  export const findByInstitution = createAction('[@vesto/merchant] findByInstitution');

  export const findByInstitutionSuccess = createAction('[@vesto/merchant] findByInstitutionSuccess', props<{ merchants: IMerchant[] }>());

  export const findByInstitutionFailure = createAction('[@vesto/merchant] findByInstitutionFailure', props<{ apiError: IApiError }>());

  export const create = createAction('[@vesto/merchant] create', props<{ name: string; address: string; feeType: MerchantFeeType; feeFixed: number; feePercentage: number; email: string}>());

  export const createSuccess = createAction('[@vesto/merchant] createSuccess', props<{ merchant: IMerchant }>());

  export const createFailure = createAction('[@vesto/merchant] createFailure', props<{ apiError: IApiError }>());

  export const changeSettings = createAction('[@vesto/merchant] changeSettings', props<{ merchantId: string; name: string; address: string; feeType: MerchantFeeType; feeFixed: number; feePercentage: number; }>());

  export const changeSettingsSuccess = createAction('[@vesto/merchant] changeSettingsSuccess', props<{ merchant: IMerchant }>());

  export const changeSettingsFailure = createAction('[@vesto/merchant] changeSettingsFailure', props<{ apiError: IApiError }>());

  export const addUser = createAction('[@vesto/merchant] addUser', props<{ merchantId: string; userId: string}>());

  export const addUserSuccess = createAction('[@vesto/merchant] addUserSuccess');

  export const addUserFailure = createAction('[@vesto/merchant] addUserFailure', props<{ apiError: IApiError }>());

  export const removeUser = createAction('[@vesto/merchant] removeUser', props<{ merchantId: string; userId: string}>());

  export const removeUserSuccess = createAction('[@vesto/merchant] removeUserSuccess');

  export const removeUserFailure = createAction('[@vesto/merchant] removeUserFailure', props<{ apiError: IApiError }>());

  export const promoteUser = createAction('[@vesto/merchant] promoteUser', props<{ merchantId: string; userId: string}>());

  export const promoteUserSuccess = createAction('[@vesto/merchant] promoteUserSuccess');

  export const promoteUserFailure = createAction('[@vesto/merchant] promoteUserFailure', props<{ apiError: IApiError }>());

  export const demoteUser = createAction('[@vesto/merchant] demoteUser', props<{ merchantId: string; userId: string}>());

  export const demoteUserSuccess = createAction('[@vesto/merchant] demoteUserSuccess');

  export const demoteUserFailure = createAction('[@vesto/merchant] demoteUserFailure', props<{ apiError: IApiError }>());
}
