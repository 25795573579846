import { Component } from '@angular/core';
import { LineChartBaseComponent } from '@vesto/xplat/features';
import * as Highcharts from 'highcharts';

declare var require: any;
const Boost = require('highcharts/modules/boost');
const noData = require('highcharts/modules/no-data-to-display');
const More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: 'gbl-line-chart',
  templateUrl: 'line-chart.component.html'
})
export class LineChartComponent extends LineChartBaseComponent {
  highcharts = Highcharts;

  constructor() {
    super();
  }
}
