import { GasTankStatus } from '../enums/gas-tank-status.enum';
import {IGasSettings} from './gas-settings.interface';

export interface IGasTank {
  id: string;
  address: string;
  type: string;
  status: GasTankStatus;
  updated: string;
  created: string;
  // Joins.
  gasSettings: IGasSettings[];
}
