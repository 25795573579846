import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { VestoSelectors, VestoState } from '../vesto.state';
import { Observable } from 'rxjs';
import { ISnapShot } from '@vesto/vesto';
import { SnapShotActions } from './snap-shot.actions';

@Injectable({
  providedIn: 'root'
})
export class SnapShotFacade {
  accountSnapShots$: Observable<ISnapShot[]> = this.store.pipe(select(VestoSelectors.accountSnapShots));
  userSnapShots$: Observable<ISnapShot[]> = this.store.pipe(select(VestoSelectors.userSnapShots));
  institutionSnapShots$: Observable<ISnapShot[]> = this.store.pipe(select(VestoSelectors.institutionSnapShots));
  systemSnapShots$: Observable<ISnapShot[]> = this.store.pipe(select(VestoSelectors.systemSnapShots));

  constructor(private store: Store<VestoState.IState>) {}

  findByAccount(accountId: string) {
    this.store.dispatch(SnapShotActions.findByAccount({ accountId }));
  }

  findByUser() {
    this.store.dispatch(SnapShotActions.findByUser());
  }

  findByInstitution() {
    this.store.dispatch(SnapShotActions.findByInstitution());
  }

  findBySystem() {
    this.store.dispatch(SnapShotActions.findBySystem());
  }
}
