import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MaintenanceBaseComponent } from '@vesto/xplat/features';

@Component({
  selector: 'gbl-maintenance',
  templateUrl: 'maintenance.component.html'
})
export class MaintenanceComponent extends MaintenanceBaseComponent {
  constructor(activeRoute: ActivatedRoute) {
    super(activeRoute);
  }
}
