import {Injectable} from '@angular/core';
import {ApiActions} from '../api/api.actions';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ObjectUtil} from '@vesto/vesto';
import {Action} from '@ngrx/store';
import {from, Observable} from 'rxjs';
import {catchError, mergeMap, withLatestFrom} from 'rxjs/operators';
import {SystemApiService} from '../../services/system-api.service';
import {SystemActions} from './system.actions';
import {UserFacade} from '../../state/user';

@Injectable()
export class SystemEffects {
  constructor(private actions$: Actions, private systemApiService: SystemApiService, private userFacade: UserFacade) {
  }

  find$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(SystemActions.find),
        mergeMap(({}) => {
          return this.systemApiService.find().pipe(
            mergeMap((result) => {
              return from([SystemActions.findSuccess({system: result})]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([SystemActions.findFailure({apiError}), ApiActions.setError({apiError})]);
            })
          );
        })
      )
  );

  deployContracts$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(SystemActions.deployContracts),
        withLatestFrom(this.userFacade.token$),
        mergeMap(([{code}, token]) => {
          return this.systemApiService.deployContracts(token, code).pipe(
            mergeMap((result) => {
              return from([SystemActions.deployContractsSuccess({system: result})]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([SystemActions.deployContractsFailure({apiError}), ApiActions.setError({apiError})]);
            })
          );
        })
      )
  );

  initializeContracts$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(SystemActions.initializeContracts),
        mergeMap(({}) => {
          return this.systemApiService.initializeContracts().pipe(
            mergeMap((result) => {
              return from([SystemActions.initializeContractsSuccess({system: result})]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([SystemActions.initializeContractsFailure({apiError}), ApiActions.setError({apiError})]);
            })
          );
        })
      )
  );

  deployContract$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(SystemActions.deployContract),
        withLatestFrom(this.userFacade.token$),
        mergeMap(([{contractId, code}, token]) => {
          return this.systemApiService.deployContract(contractId, token, code).pipe(
            mergeMap((result) => {
              return from([SystemActions.deployContractSuccess({system: result})]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([SystemActions.deployContractFailure({apiError}), ApiActions.setError({apiError})]);
            })
          );
        })
      )
  );

  getFiatBalances$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(SystemActions.getFiatBalances),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{}]) => {
          return this.systemApiService.getFiatBalances().pipe(
            mergeMap(result => {
              return from([SystemActions.getFiatBalancesSuccess({fiatBalances: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([SystemActions.getFiatBalancesFailure({apiError}), ApiActions.setError({apiError})]);
            })
          );
        })
      )
  );

  sendSmsCode$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(SystemActions.sendSmsCode),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{}, user]) => {
          return this.systemApiService.sendSmsCode(user.id).pipe(
            mergeMap(result => {
              return from([SystemActions.sendSmsCodeSuccess({token: result.token })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([SystemActions.sendSmsCodeFailure({apiError}), ApiActions.setError({apiError})]);
            })
          );
        })
      )
  );
}
