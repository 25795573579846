import { ContractType } from '../enums/contract-type.enum';
import { ContractStatus } from '../enums/contract-status.enum';
import { Blockchain } from '../enums/blockchain.enum';

export interface IContract {
  id: string;
  accountId; string;
  address: string;
  status: ContractStatus;
  type: ContractType;
  version: string;
  blockchain: Blockchain;
  url: string;
  updated: string;
  created: string;
  error: string;
}
