<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>

  <h3 class="section-number"><i class="fal fa-user-plus"></i> Add Yearn Vault</h3>
</div>
<mat-dialog-content>
  <ng-container [ngSwitch]="step">
    <section *ngSwitchCase="'FORM_GROUP'">
      <form [formGroup]="formGroup" (ngSubmit)="addYearnVault()">
        <div class="vesto-input">
          <label for="address">Address:</label>
          <input type="text" formControlName="address" id="address" name="address" />
        </div>

        <gbl-alert [type]="error" [message]="error"></gbl-alert>

        <button class="button button-primary mt-6 w-full text-center block" type="submit">Add Yearn Vault</button>
      </form>
    </section>

    <section *ngSwitchCase="'TWO_FACTOR'">
      <div class="mt-16">
        <gbl-two-factor (sendSmsCode)="sendSmsCode($event)" (code)="confirmCode($event)"></gbl-two-factor>
      </div>
    </section>

    <section *ngSwitchCase="'SUCCESS'">
      <div class="relative mt-16">
        <div style="width: 350px; height: 350px; overflow: hidden; margin: -24px auto 0">
          <gbl-lottie-player></gbl-lottie-player>
        </div>
      </div>
    </section>

    <section *ngSwitchCase="'TRANSACTION_DETAIL'">
      <div class="slide-in mt-16">
        <div class="text-center">
          <p class="mt-4">Success!</p>
        </div>
        <div class="p-4 bg-trans-blue rounded-lg mt-4">
          <p class="text-sm">You added Yearn Vault {{ transaction?.address }}</p>
        </div>
        <button class="button button-primary mt-4 mx-auto w-full" (click)="close()">Close</button>
      </div>
    </section>
  </ng-container>
</mat-dialog-content>
