import { Inject, Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import {Actions, ofType, createEffect, OnInitEffects} from '@ngrx/effects';
import { from, Observable, mergeMap, catchError, withLatestFrom } from 'rxjs';
import { IEnvironment, ObjectUtil } from '@vesto/vesto';
import { UsersApiService, OnfidoApiService, EnvironmentToken } from '../../services';
import { VestoState } from '../vesto.state';
import { UserActions } from './user.actions';
import { ApiActions } from '../api';
import { UserFacade } from './user.facade';
import { CoreActions } from '@vesto/xplat/core';

@Injectable()
export class UserEffects {
  constructor(private actions$: Actions, private usersApiService: UsersApiService, private onfidoApiService: OnfidoApiService, @Inject(EnvironmentToken) protected environment: IEnvironment, protected store: Store<VestoState.IState>, private userFacade: UserFacade) {}

  signIn$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UserActions.signIn),
        mergeMap(({ email, password }) => {
          return this.usersApiService.signIn(email, password).pipe(
            mergeMap((result) => {
              return from([UserActions.signInSuccess({ token: result.token, user: result.user, password })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([UserActions.signInFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  createSdkToken$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UserActions.createOnfidoSdkToken),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{ device }, user]) => {
          return this.onfidoApiService.createSdkToken(user.id, device).pipe(
            mergeMap((result) => {
              return from([UserActions.createOnfidoSdkTokenSuccess({ token: result.token })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([UserActions.createOnfidoSdkTokenFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  sendSmsCode$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UserActions.sendSmsCode),
        withLatestFrom(this.userFacade.token$),
        mergeMap(([{}, token]) => {
          return this.usersApiService.sendSmsCode(token).pipe(
            mergeMap((result) => {
              return from([UserActions.sendSmsCodeSuccess()]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([UserActions.sendSmsCodeFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  verifySmsCode$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UserActions.verifyTwoFactorCode),
        withLatestFrom(this.userFacade.token$),
        mergeMap(([{ code }, token]) => {
          return this.usersApiService.verifyTwoFactorCode(token, code).pipe(
            mergeMap((result) => {
              return from([
                UserActions.verifyTwoFactorCodeSuccess({
                  user: result.user,
                  privateKey: result.privateKey
                })
              ]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([UserActions.verifyTwoFactorCodeFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  verifyIdentity$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UserActions.verifyIdentity),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{ address, googlePlaceId, documentType, documentFrontId, documentBackId, faceId, dob, ssn }, user]) => {
          return this.usersApiService.verifyIdentity(user.id, address, googlePlaceId, documentType, documentFrontId, documentBackId, faceId, dob, ssn).pipe(
            mergeMap((result) => {
              return from([UserActions.verifyIdentitySuccess({ user: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([UserActions.verifyIdentityFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  find$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UserActions.find),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{}, user]) => {
          if (!user) {
            return from([CoreActions.noop()]);
          }
          return this.usersApiService.find(user.id).pipe(
            mergeMap((result) => {
              return from([UserActions.findSuccess({ user: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([UserActions.findFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  findByMerchant$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UserActions.findByMerchant),
        mergeMap(({merchantId}) => {
          return this.usersApiService.findByMerchant(merchantId).pipe(
            mergeMap((result) => {
              return from([UserActions.findByMerchantSuccess({ users: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([UserActions.findByMerchantFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  invite$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UserActions.invite),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{institutionId, email}, user]) => {
          return this.usersApiService.invite(!!institutionId ? institutionId : user.institutionId, email).pipe(
            mergeMap((result) => {
              return from([UserActions.inviteSuccess()]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([UserActions.inviteFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  inviteToMerchant$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UserActions.inviteToMerchant),
        mergeMap(({merchantId, email}) => {
          return this.usersApiService.inviteToMerchant(merchantId, email).pipe(
            mergeMap((result) => {
              return from([UserActions.inviteToMerchantSuccess()]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([UserActions.inviteToMerchantFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  forgotPassword$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UserActions.forgotPassword),
        mergeMap(({ email }) => {
          return this.usersApiService.forgotPassword(email).pipe(
            mergeMap((result) => {
              return from([UserActions.forgotPasswordSuccess()]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([UserActions.forgotPasswordFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  setPassword$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UserActions.setPassword),
        mergeMap(({ token, password }) => {
          return this.usersApiService.setPassword(token, password).pipe(
            mergeMap((result) => {
              return from([UserActions.setPasswordSuccess()]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([UserActions.setPasswordFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  changePersonalInfo$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UserActions.changePersonalInfo),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{ firstName, lastName, address, googlePlaceId }, user]) => {
          return this.usersApiService.changePersonalInfo(user.id, firstName, lastName, address, googlePlaceId).pipe(
            mergeMap((result) => {
              return from([UserActions.changePersonalInfoSuccess({ user: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([UserActions.changePersonalInfoFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  changeEmail$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UserActions.changeEmail),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{ email }, user]) => {
          return this.usersApiService.changeEmail(user.id, email).pipe(
            mergeMap((result) => {
              return from([UserActions.changeEmailSuccess()]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([UserActions.changeEmailFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  verifyChangeEmail$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UserActions.verifyChangeEmail),
        mergeMap(({ token }) => {
          return this.usersApiService.verifyChangeEmail(token).pipe(
            mergeMap((result) => {
              return from([UserActions.verifyChangeEmailSuccess({ user: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([UserActions.verifyChangeEmailFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  changeMobile$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UserActions.changeMobile),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{ mobile }, user]) => {
          return this.usersApiService.changeMobile(user.id, mobile).pipe(
            mergeMap((result) => {
              return from([UserActions.changeMobileSuccess({ token: result.token })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([UserActions.changeMobileFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  verifyChangeMobile$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UserActions.verifyChangeMobile),
        withLatestFrom(this.userFacade.token$),
        mergeMap(([{ code }, token]) => {
          return this.usersApiService.verifyChangeMobile(token, code).pipe(
            mergeMap((result) => {
              return from([UserActions.verifyChangeMobileSuccess({ user: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([UserActions.verifyChangeMobileFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  changePassword$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UserActions.changePassword),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{ oldPassword, newPassword }, user]) => {
          return this.usersApiService.changePassword(user.id, oldPassword, newPassword).pipe(
            mergeMap((result) => {
              return from([UserActions.changePasswordSuccess()]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([UserActions.changePasswordFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  typeahead$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UserActions.typeahead),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{ keyword }, user]) => {
          return this.usersApiService.typeahead(user.id, keyword).pipe(
            mergeMap((result) => {
              return from([UserActions.typeaheadSuccess({ users: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([UserActions.typeaheadFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  kycClear$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UserActions.kycClear),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{}, user]) => {
          return this.usersApiService.kycClear(user.id).pipe(
            mergeMap((result) => {
              return from([UserActions.kycClearSuccess({ user: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([UserActions.kycClearFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  kycConsider$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UserActions.kycConsider),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{}, user]) => {
          return this.usersApiService.kycConsider(user.id).pipe(
            mergeMap((result) => {
              return from([UserActions.kycConsiderSuccess({ user: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([UserActions.kycConsiderFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  suspend$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UserActions.suspend),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{}, user]) => {
          return this.usersApiService.suspend(user.id).pipe(
            mergeMap((result) => {
              return from([UserActions.suspendSuccess({ user: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([UserActions.suspendFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  activate$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UserActions.activate),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{}, user]) => {
          return this.usersApiService.activate(user.id).pipe(
            mergeMap((result) => {
              return from([UserActions.activateSuccess({ user: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([UserActions.activateFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  requestDailyLimitsIncrease$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UserActions.requestDailyLimitsIncrease),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{}, user]) => {
          return this.usersApiService.requestDailyLimitsIncrease(user.id).pipe(
            mergeMap((result) => {
              return from([UserActions.requestDailyLimitsIncreaseSuccess()]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([UserActions.requestDailyLimitsIncreaseFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );
}


