import {Component, OnInit} from "@angular/core";
import {BaseComponent, CoreService, StorageKeys, UiActions} from "@vesto/xplat/core";
import {UntypedFormControl} from "@angular/forms";
import {IUser, UserStatus, UserUtil} from "@vesto/vesto";
import {take, takeUntil} from "rxjs/operators";
import {ofType} from "@ngrx/effects";
import {TransactionActions} from "@vesto/ngx-vesto/state";

@Component({
  selector: 'gbl-promote-user-modal',
  templateUrl: './swap-signing-address-modal.component.html'
})

export class SwapSigningAddressModalComponent extends BaseComponent implements OnInit {
  step: 'FORM_GROUP' | 'TWO_FACTOR' | 'BUSY' | 'SUCCESS' | 'DETAIL' = 'FORM_GROUP';
  UserUtil = UserUtil;
  error: string;
  recipientEmail: string;
  users$ = CoreService.userFacade.users$;
  selectedUser: IUser = null;
  UserStatus = UserStatus;
  formControl = new UntypedFormControl();

  ngOnInit() {
    this.formControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      if (typeof result === 'string') {
        this.search(result);
      }
    });
  }

  getOptionText(option) {
    return option ? `${option.firstName} ${option.lastName} ${option.email}` : null;
  }

  search(keyword: string) {
    if (!keyword) {
      return;
    }
    CoreService.userFacade.typeahead(keyword);
  }

  selectUser(user: IUser) {
    this.selectedUser = user;
  }

  close() {
    CoreService.store.dispatch(UiActions.closeModal());
  }

  sendSmsCode(isResendSms: boolean) {
    CoreService.transactionFacade.sendSmsCode();

    if (isResendSms) {
      CoreService.uiFacade.notification(`SMS code sent!`);
    }
  }

  swapSigningAddress() {
    this.step = 'TWO_FACTOR';
  }

  confirmCode(code: string) {
    this.step = 'BUSY';
    CoreService.actions$.pipe(ofType(TransactionActions.swapSigningAddressesSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = null;
      CoreService.store.dispatch(TransactionActions.sign({ transaction: result.transaction, code, key: new Buffer(CoreService.storageService.getItem(StorageKeys.KEY), 'hex').toString() }));
    });

    CoreService.actions$.pipe(ofType(TransactionActions.signSuccess), take(1), takeUntil(this.destroy$)).subscribe(() => {
      this.step = 'SUCCESS';
      CoreService.windowService.setTimeout(() => {
        this.step = 'DETAIL';
      }, 1600);
    });

    CoreService.actions$.pipe(ofType(TransactionActions.swapSigningAddressesFailure, TransactionActions.signFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors${result.apiError.error}`;
      this.step = 'FORM_GROUP';
    });

    // CoreService.transactionFacade.swapSigningAddresses(code);
  }
}
