<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <h3 class="section-number"><i class="fal fa-swimming-pool"></i> Sync Element</h3>
</div>
<mat-dialog-content>
  <ng-container [ngSwitch]="step">
    <form [formGroup]="formGroup" (ngSubmit)="syncElement()" *ngSwitchCase="'FORM_GROUP'">
      <div class="vesto-input vesto-input-inline mb-4">
        <label for="trancheAddress">Tranche Address</label>
        <input type="text" formControlName="trancheAddress" id="trancheAddress" name="trancheAddress" />
      </div>
      <div class="vesto-input vesto-input-inline mb-4">
        <label for="principalTokenPoolAddress">Principal Pool Address</label>
        <input type="text" formControlName="principalTokenPoolAddress" id="principalTokenPoolAddress" name="principalTokenPoolAddress" />
      </div>
      <div class="vesto-input vesto-input-inline mb-4">
        <label for="yieldTokenPoolAddress">Yield Token Pool Address</label>
        <input type="text" formControlName="yieldTokenPoolAddress" id="yieldTokenPoolAddress" name="yieldTokenPoolAddress" />
      </div>
      <gbl-alert [type]="error" [message]="error"></gbl-alert>

      <div class="text-right mt-3">
        <button class="button button-primary" type="submit">Sync</button>
      </div>
    </form>

    <section *ngSwitchCase="'TWO_FACTOR'">
      <gbl-two-factor (sendSmsCode)="sendSmsCode($event)" (code)="confirmCode($event)"></gbl-two-factor>
      <gbl-alert [type]="error" [message]="error"></gbl-alert>
    </section>

    <section *ngSwitchCase="'BUSY'" class="mt-4">
      <div class="placeholder-long bg-flash"></div>
      <div class="placeholder-area bg-flash mt-4"></div>
    </section>

    <section *ngSwitchCase="'SUCCESS'">
      <div style="width: 350px; height: 350px; overflow: hidden; margin: -24px auto 0">
        <gbl-lottie-player></gbl-lottie-player>
      </div>
    </section>

    <section *ngSwitchCase="'TRANSACTION_DETAIL'" class="slide-in">
      <div class="text-center">
        <p class="mt-4">Success!</p>
        <p class="text-sm mb-4">You transaction has been submitted to the blockchain</p>
      </div>
      <div class="p-4 bg-trans-blue rounded-lg">
        <gbl-transaction [transaction]="transaction"></gbl-transaction>
      </div>
      <button class="button button-primary my-4 mx-auto w-full" (click)="close()">Close</button>
    </section>
  </ng-container>
</mat-dialog-content>
