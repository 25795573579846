<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>

  <h3 class="section-number"><i class="fal fa-user-plus"></i> Add User</h3>
</div>
<mat-dialog-content>
  <ng-container [ngSwitch]="step">
    <section *ngSwitchCase="'FORM_GROUP'">
      <p>Add an additional user to one of your accounts.</p>
      <p class="text-sm mb-3">This user will be able to make transactions using the account of your choice.</p>
      <form [formGroup]="formGroup" (ngSubmit)="addUser()">
        <div class="vesto-input">
          <label for="email">Recipient email:</label>
          <input type="text" formControlName="email" id="email" name="email" />
        </div>

        <div class="vesto-input vesto-select mt-2">
          <label>Account to join:</label>
          <ng-select formControlName="accountId" id="account" name="account" placeholder="Account">
            <ng-option *ngFor="let account of accounts" [value]="account.id">
              <div class="flex">
                <div class="flex-grow">{{ account.name }} &nbsp;</div>
              </div>
            </ng-option>
          </ng-select>
        </div>

        <gbl-alert [type]="error" [message]="error"></gbl-alert>

        <button class="button button-primary mt-6 w-full text-center block" type="submit">Add User</button>
      </form>
    </section>

    <section *ngSwitchCase="'TWO_FACTOR'">
      <div class="mt-16">
        <gbl-two-factor (sendSmsCode)="sendSmsCode($event)" (code)="confirmCode($event)"></gbl-two-factor>
      </div>
    </section>

    <section *ngSwitchCase="'BUSY'" class="mt-4">
      <div class="placeholder-long bg-flash"></div>
      <div class="placeholder-area bg-flash mt-4"></div>
    </section>

    <section *ngSwitchCase="'SUCCESS'">
      <div class="relative mt-16">
        <div style="width: 350px; height: 350px; overflow: hidden; margin: -24px auto 0">
          <gbl-lottie-player></gbl-lottie-player>
        </div>
      </div>
    </section>

    <section *ngSwitchCase="'TRANSACTION_DETAIL'">
      <div class="slide-in mt-16">
        <div class="text-center">
          <p class="mt-4">Success!</p>
        </div>
        <div class="p-4 bg-trans-blue rounded-lg mt-4" *ngIf="transaction else noTransactionTemplate">
          <p class="text-sm">You added {{ transaction.recipientUser.firstName }} {{ transaction.recipientUser.lastName }} to your {{ selectedAccount.name }} account</p>
        </div>
        <ng-template #noTransactionTemplate>
          You added a user to your account.
        </ng-template>
        <button class="button button-primary mt-4 mx-auto w-full" (click)="close()">Close</button>
      </div>
    </section>
  </ng-container>
</mat-dialog-content>
