import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { BaseComponent } from '@vesto/xplat/core';

@Component({
  selector: 'gbl-form-field',
  templateUrl: 'form-field.component.html'
})
export class FormFieldComponent extends BaseComponent implements OnInit {
  @Input() label: string;
  @Input() mask: any;
  @Input() type: string = 'text';
  @Input() placeholder: string;
  @Input() formControlName: string;
  @Input() defaultValue: string;
  @Input() id: string;
  @Input() model: any;
  @Input() name: string;
  @Output() blur: EventEmitter<boolean> = new EventEmitter();
  @Output() changed: EventEmitter<any> = new EventEmitter();

  value: string;
  dirty: boolean;

  constructor() {
    super();
  }

  ngOnInit() {
    if (this.defaultValue) this.value = this.defaultValue;
  }

  keyup(args) {
    if (this.value) {
      this.dirty = true;
    }
    this.changed.next({ value: this.value, id: this.id, event: args });
  }

  blurred() {
    if (!this.value) this.dirty = false;
    this.blur.next(true);
  }

  focused() {
    this.dirty = true;
  }
}
