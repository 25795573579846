/**
 * UI state utilities
 */

import { UiActions } from './ui.actions';
import { CoreService } from '../../services/core.service';
import { UiState } from '..';

/**
 * Insert into effect flows where needed to close modals
 * @param result optional value to close modal with
 * @returns UiActions.closeModal
 */
export function uiCloseModalAction(result?: any) {
  return UiActions.closeModal(result);
}

/**
 * Dispatch ngrx action to close active modal
 * @param result optional value to close modal with
 */
export function uiCloseModal(result?: any) {
  CoreService.store.dispatch(uiCloseModalAction(result));
}

/**
 * Insert into effect flows where needed to open modals
 * @param result optional data to open modal with
 * @returns UiActions.openModal
 */
export function uiOpenModalAction(options?: UiState.IModalStateOptions) {
  return UiActions.openModal(options);
}

/**
 * Dispatch ngrx action to open modal
 * @param result options to open modal with
 */
export function uiOpenModal(options?: UiState.IModalStateOptions) {
  CoreService.store.dispatch(uiOpenModalAction(options));
}
