import { Component } from '@angular/core';

import { BaseComponent } from '@vesto/xplat/core';

@Component({
  selector: 'gbl-sign-up',
  templateUrl: 'sign-up.component.html'
})
export class AerosSignUpComponent extends BaseComponent {

}
