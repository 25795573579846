import { Directive, OnInit } from '@angular/core';
import { BaseComponent, CoreService, StorageKeys, UiActions, uiCloseModal } from '@vesto/xplat/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';
import { IAccount, ITransaction, IUser } from '@vesto/vesto';
import { DashboardFacade } from '../../state';
import { ofType } from '@ngrx/effects';
import { TransactionActions } from '@vesto/ngx-vesto/state';
import { FeatureService } from '../../../services';

@Directive()
export abstract class AddUserModalBaseComponent extends BaseComponent implements OnInit {
  step: 'FORM_GROUP' | 'BUSY' | 'TWO_FACTOR' | 'SUCCESS' | 'TRANSACTION_DETAIL' = 'FORM_GROUP';
  formGroup: UntypedFormGroup;
  accounts: IAccount[] = [];
  selectedAccount: IAccount;
  user: IUser;
  error: string;
  transaction: ITransaction;

  constructor(protected dashboardFacade: DashboardFacade) {
    super();
    CoreService.userFacade.user$
      .pipe(
        takeUntil(this.destroy$),
        filter((user) => !!user)
      )
      .subscribe((user) => {
        this.user = user;
      });
    CoreService.accountFacade.accounts$
      .pipe(
        filter((accounts) => !!accounts),
        takeUntil(this.destroy$)
      )
      .subscribe((accounts) => {
        this.accounts = [];
        accounts.forEach((account) => {
          if (!account.shared) {
            this.accounts.push(account);
          }
        });
      });
    FeatureService.dashboardFacade.selectedAccount$
      .pipe(
        filter((selectedAccount) => !!selectedAccount),
        takeUntil(this.destroy$)
      )
      .subscribe((selectedAccount) => {
        this.selectedAccount = selectedAccount;
      });
    this.formGroup = CoreService.formBuilder.group(
      {
        email: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator, Validators.email]
          }
        ],
        accountId: [
          !this.selectedAccount.shared ? this.selectedAccount.name : null,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ]
      },
      { updateOn: 'change' }
    );
  }

  ngOnInit() {
    this.formGroup.controls.accountId.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((result) => {
      this.accounts.forEach((account) => {
        if (account.id === result) {
          this.selectedAccount = account;
          this.dashboardFacade.selectAccount(this.selectedAccount);
          return;
        }
      });
    });
  }

  sendSmsCode(isResendSms: boolean) {
    CoreService.transactionFacade.sendSmsCode();

    if (isResendSms) {
      CoreService.uiFacade.notification(`SMS code sent!`);
    }
  }

  addUser() {
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }
    this.step = 'TWO_FACTOR';
  }

  confirmCode(code: string) {
    this.step = 'BUSY';
    const value = this.formGroup.value;
    CoreService.transactionFacade.addUser(this.selectedAccount.id, value.email, code);

    CoreService.actions$.pipe(ofType(TransactionActions.addUserSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      if (!!result.transaction) {
        CoreService.store.dispatch(TransactionActions.sign({ transaction: result.transaction, code, key: new Buffer(CoreService.storageService.getItem(StorageKeys.KEY), 'hex').toString() }));
      } else {
        this.step = 'SUCCESS';
        CoreService.windowService.setTimeout(() => {
          this.step = 'TRANSACTION_DETAIL';
        }, 1600);
      }
    });

    CoreService.actions$.pipe(ofType(TransactionActions.addUserFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.step = 'FORM_GROUP';
      this.error = `apiErrors.${result.apiError.error}`;
    });

    CoreService.actions$.pipe(ofType(TransactionActions.signFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.step = 'FORM_GROUP';
      this.error = `apiErrors.${result.apiError.error}`;
    });

    CoreService.actions$.pipe(ofType(TransactionActions.signSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.transaction = result.transaction;
      this.step = 'SUCCESS';
      CoreService.windowService.setTimeout(() => {
        this.step = 'TRANSACTION_DETAIL';
      }, 1600);
    });
  }

  close() {
    CoreService.store.dispatch(UiActions.closeModal());
  }
}
