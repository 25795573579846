import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tipAmount',
  pure: true
})
export class CalculateTipPipe implements PipeTransform {
  transform(total: number, tipPercent: number): any {
    return total * (tipPercent/100);
  }
}
