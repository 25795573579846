import {Component, OnInit} from "@angular/core";
import {BaseComponent, CoreService} from "@vesto/xplat/core";

@Component({
  selector: 'gbl-global-transactions',
  templateUrl: 'global-transactions.component.html'
})

export class GlobalTransactionsComponent extends BaseComponent implements OnInit {
  ngOnInit() {
  }
}
