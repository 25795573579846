import { createAction, props } from '@ngrx/store';
import {Blockchain, IApiError, IGasTank, IInstitution, IToken, InstitutionFeeType} from '@vesto/vesto';

export namespace InstitutionActions {
  export const set = createAction('[@vesto/institution] set', props<{ institution: IInstitution }>());

  export const setToken = createAction('[@vesto/institution] setToken', props<{ token: IToken }>());

  export const create = createAction('[@vesto/institution] create', props<{ name: string; adminEmail: string; iosAppId: string; androidAppId: string; url: string; referrer: string; merchantEnabled: boolean }>());

  export const createSuccess = createAction('[@vesto/institution] createSuccess', props<{ institution: IInstitution }>());

  export const createFailure = createAction('[@vesto/institution] createFailure', props<{ apiError: IApiError }>());

  export const createAndDeployTokens = createAction('[@vesto/institution] createAndDeployTokens', props<{ institutionId: string; name: string; symbol: string; underlyingSymbol: string; code: string}>())

  export const createAndDeployTokensSuccess = createAction('[@vesto/institution] createAndDeployTokensSuccess', props<{ institution: IInstitution }>());

  export const createAndDeployTokensFailure = createAction('[@vesto/institution] createAndDeployTokensFailure', props<{ apiError: IApiError }>());

  export const changeSettings = createAction('[@vesto/institution] changeSettings', props<{ id: string; name: string; address: string; iosAppId: string; androidAppId: string; referrer: string; url: string; merchantEnabled: boolean; instantSettleEnabled: boolean; settleTransactionLimit: number; settleDailyLimit: number, feeType: InstitutionFeeType; feeFixed: number; feePercentage: number}>());

  export const changeSettingsSuccess = createAction('[@vesto/institution] changeSettingsSuccess', props<{ institution: IInstitution }>());

  export const changeSettingsFailure = createAction('[@vesto/institution] changeSettingsFailure', props<{ apiError: IApiError }>());

  export const changeTemplate = createAction('[@vesto/institution] changeTemplate', props<{ templateId: string; referenceId: string }>());

  export const changeTemplateSuccess = createAction('[@vesto/institution] changeTemplateSuccess', props<{ institution: IInstitution }>());

  export const changeTemplateFailure = createAction('[@vesto/institution] changeTemplateFailure', props<{ apiError: IApiError }>());

  export const changeAml = createAction('[@vesto/institution] changeAml', props<{ defaultDailyBuyLimit: number; defaultDailySellLimit: number; defaultDailyTransferLimit: number; defaultDailyReceiveLimit: number; plaidEnabled: boolean }>());

  export const changeAmlSuccess = createAction('[@vesto/institution] changeAmlSuccess', props<{ institution: IInstitution }>());

  export const changeAmlFailure = createAction('[@vesto/institution] changeAmlFailure', props<{ apiError: IApiError }>());

  export const changeDeFi = createAction('[@vesto/institution] changeDeFi', props<{ apy: number }>());

  export const changeDeFiSuccess = createAction('[@vesto/institution] changeDeFiSuccess', props<{ institution: IInstitution }>());

  export const changeDeFiFailure = createAction('[@vesto/institution] changeDeFiFailure', props<{ apiError: IApiError }>());

  export const find = createAction('[@vesto/institution] find');

  export const findSuccess = createAction('[@vesto/institution] findSuccess', props<{ institution: IInstitution }>());

  export const findFailure = createAction('[@vesto/institution] findFailure', props<{ apiError: IApiError }>());

  export const findByUser = createAction('[@vesto/institution] findByUser');

  export const findByUserSuccess = createAction('[@vesto/institution] findByUserSuccess', props<{ institution: IInstitution }>());

  export const findByUserFailure = createAction('[@vesto/institution] findByUserFailure', props<{ apiError: IApiError }>());

  export const findAll = createAction('[@vesto/institution] findAll');

  export const findAllSuccess = createAction('[@vesto/institution] findAllSuccess', props<{ institutions: IInstitution[] }>());

  export const findAllFailure = createAction('[@vesto/institution] findAllFailure', props<{ apiError: IApiError }>());

  export const sendSmsCode = createAction('[@vesto/institution] sendSmsCode');

  export const sendSmsCodeSuccess = createAction('[@vesto/institution] sendSmsCodeSuccess', props<{ token: string }>());

  export const sendSmsCodeFailure = createAction('[@vesto/institution] sendSmsCodeFailure', props<{ apiError: IApiError }>());
}
