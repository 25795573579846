export interface IExchange {
  id: string;
  symbol: string;
  pairedSymbol: string;
  decimals: number;
  pairedDecimals: number;
  rate: number;
  apy: number;
  created: string;
}
