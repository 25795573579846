import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { VestoSelectors, VestoState } from '../vesto.state';
import { Observable } from 'rxjs';
import { IBankAccount } from '@vesto/vesto';
import { BankAccountActions } from './bank-account.actions';

@Injectable({
  providedIn: 'root'
})
export class BankAccountFacade {
  bankAccounts$: Observable<IBankAccount[]> = this.store.pipe(select(VestoSelectors.bankAccounts));

  constructor(private store: Store<VestoState.IState>) {}

  createLinkToken(extras?: any) {
    this.store.dispatch(BankAccountActions.createLinkToken({ extras }));
  }

  findByUser() {
    this.store.dispatch(BankAccountActions.findByUser());
  }

  create(
    bankAccount: IBankAccount
  ) {
    this.store.dispatch(BankAccountActions.create({ bankAccount }));
  }

  setDefault(
    id: string
  ) {
    this.store.dispatch(BankAccountActions.setDefault({ id }));
  }

  remove(
    id: string
  ) {
    this.store.dispatch(BankAccountActions.remove({ id }));
  }
}
