<ng-container *ngIf="title">
  <div class="flex p-2 my-2 text-sm rounded-lg slide-in items-center" [style.background-color]="user.status === UserStatus.NOT_PROVISIONED || user.status === UserStatus.SUSPENDED ? '#fee2e2' : '#dde7f998'" [style.color]="user.status === UserStatus.NOT_PROVISIONED || user.status === UserStatus.SUSPENDED ? '#991b1b' : '#3c5185'">
    <i [ngClass]="iconClass" class="text-2xl"></i>
    <div class="ml-2 mr-4">
      <p class="text-sm" [innerHTML]="title"></p>
      <p class="text-xs" [innerHTML]="message"></p>
    </div>
    <button *ngIf="user && (user.status === UserStatus.IDENTITY_NOT_VERIFIED)" class="button button-primary button-sm" (click)="openOrGoToVerifyIdentity()">Verify</button>
    <button *ngIf="user && user.status === UserStatus.ACTIVE && wasPending" class="button button-primary button-sm" (click)="dismiss()">Dismiss</button>
    <div *ngIf="user && user.status === UserStatus.VERIFYING_IDENTITY" class="pr-4">
      <i class="fa fa-spinner fa-pulse fa-2x"></i>
    </div>
  </div>
</ng-container>
