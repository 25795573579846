import { Component } from '@angular/core';

// xplat
import { AppBaseComponent, WebHelperService, MetaTagsService } from '@vesto/xplat/web/core';
import { ActivatedRoute, RouterStateSnapshot, Router, NavigationExtras, NavigationEnd, NavigationStart } from '@angular/router';
import { UserFacade, UserActions, VestoSelectors } from '@vesto/ngx-vesto/state';
import { takeUntil, take } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { Actions, ofType } from '@ngrx/effects';
import { WindowService, StorageService, IContentPage, UiActions, environment, LogService, CoreService } from '@vesto/xplat/core';
import { appLaunchParams } from '@vesto/xplat/utils';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gbl-root',
  templateUrl: './app.component.html'
})
export class AppComponent extends AppBaseComponent {
  private isSignedIn = false;
  private _defaultSeo: IContentPage;

  constructor(
    private _store: Store<any>,
    public actions$: Actions,
    public activatedRoute: ActivatedRoute,
    public userFacade: UserFacade,
    private router: Router,
    private log: LogService,
    private _win: WindowService,
    private _storageService: StorageService,
    // Helps initialize these singletons on app boot
    private _webHelper: WebHelperService,
    private metaTagsService: MetaTagsService,
    private _translateService: TranslateService
  ) {
    super();
    this.log.debug('Env type:', environment.name);
    CoreService.store.pipe(select(VestoSelectors.authenticated)).subscribe(authenticated => {
      this.isSignedIn = !!authenticated;
    });

    this.router.events.subscribe((result) => {
      if (result instanceof NavigationStart) {
        const pageTitle = 'page-titles.' + result.url;
        const pageDesc = 'meta-descriptions.' + result.url;
        this._translateService
          .get([pageTitle, pageDesc])
          .pipe(take(1))
          .subscribe((localized) => {
            // always set default seo for first page load
            // helps when other pages may not have localized seo data yet
            if (!this._defaultSeo) {
              this._defaultSeo = {};
            }
            // handle seo per page
            const page: IContentPage = {};
            if (localized[pageTitle] !== pageTitle) {
              page.seoTitle = localized[pageTitle];
              if (!this._defaultSeo.seoTitle) {
                this._defaultSeo.seoTitle = localized[pageTitle];
              }
            }
            if (localized[pageDesc] !== pageDesc) {
              page.seoDescription = localized[pageDesc];
              if (!this._defaultSeo.seoDescription) {
                this._defaultSeo.seoDescription = localized[pageTitle];
              }
            } else if (this._defaultSeo) {
              // fallback to initial page load from static render seo data
              if (this._defaultSeo.seoTitle) {
                page.seoDescription = this._defaultSeo.seoTitle;
              }
              if (this._defaultSeo.seoDescription) {
                page.seoDescription = this._defaultSeo.seoDescription;
              }
            }
            if (Object.keys(page).length) {
              // only modify head if in fact an seo update is needed
              this.metaTagsService.updateSeoTags(page);
            }
          });
      }
    });
  }
}
