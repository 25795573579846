export interface IAlert {
  id: string;
  userId: string;
  accountId: string;
  type: string;
  status: string;
  notes: string;
  updated: string;
  created: string;
  // Formulas.
  userFirstName: string;
  userLastName: string;
  userEmail: string;
}
