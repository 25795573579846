import {Injectable} from '@angular/core';
import {ApiActions, VestoState} from '../../state';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {GasTanksApiService} from '@vesto/ngx-vesto';
import {from, Observable} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {ObjectUtil} from '@vesto/vesto';
import {GasTankActions} from './gas-tank.actions';

@Injectable()
export class GasTankEffects {
  constructor(private actions$: Actions, protected store: Store<VestoState.IState>, private gasTanksApiService: GasTanksApiService) {
  }

  findActive$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(GasTankActions.findActive),
        mergeMap(({}) => {
          return this.gasTanksApiService.findActive().pipe(
            mergeMap((result) => {
              return from([GasTankActions.findActiveSuccess({gasTanks: result})]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([GasTankActions.findActiveFailure({apiError}), ApiActions.setError({apiError})]);
            })
          );
        })
      )
  );

  changeSettings$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(GasTankActions.changeSettings),
        mergeMap(({gasTankId, blockchain, minPrice, maxPrice, limit}) => {
          return this.gasTanksApiService.changeSettings(gasTankId, blockchain, minPrice, maxPrice, limit).pipe(
            mergeMap((result) => {
              return from([GasTankActions.changeSettingsSuccess({gasTank: result})]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([GasTankActions.changeSettingsFailure({apiError}), ApiActions.setError({apiError})]);
            })
          );
        })
      )
  );


}
