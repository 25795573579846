<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <h3 class="section-number"><i class="fal fa-sliders-v"></i> AML Settings</h3>
</div>
<mat-dialog-content>
  <ng-container [ngSwitch]="step">
    <form [formGroup]="formGroup" (ngSubmit)="changeAml()" *ngSwitchCase="'FORM_GROUP'">
      <div class="my-4 vesto-input vesto-input-inline">
        <label>Daily Buy Limit</label>
        <gbl-input class="currency-input my-4" mask="currency" formControlName="defaultDailyBuyLimit"></gbl-input>
      </div>
      <div class="my-4 vesto-input vesto-input-inline">
        <label>Daily Sell Limit</label>
        <gbl-input class="currency-input my-4" mask="currency" formControlName="defaultDailySellLimit"></gbl-input>
      </div>
      <div class="my-4 vesto-input vesto-input-inline">
        <label>Daily Receive Limit</label>
        <gbl-input class="currency-input my-4" mask="currency" formControlName="defaultDailyReceiveLimit"></gbl-input>
      </div>
      <div class="my-4 vesto-input vesto-input-inline">
        <label>Daily Transfer Limit</label>
        <gbl-input class="currency-input my-4" mask="currency" formControlName="defaultDailyTransferLimit"></gbl-input>
      </div>
      <div class="my-4 flex text-sm items-center">
        <span class="mr-3">Plaid Enabled</span>
        <mat-slide-toggle formControlName="plaidEnabled" [checked]="institution.plaidEnabled"></mat-slide-toggle>
      </div>

      <gbl-alert [type]="error" [message]="error"></gbl-alert>

      <div class="text-right mt-3 mb-1">
        <button class="button button-primary" type="submit">Update</button>
      </div>
    </form>
    <section *ngSwitchCase="'BUSY'" class="mt-4">
      <div class="placeholder-long bg-flash"></div>
      <div class="placeholder-area bg-flash mt-4"></div>
    </section>

    <section *ngSwitchCase="'SUCCESS'">
      <div class="relative mt-16">
        <div style="width: 350px; height: 350px; overflow: hidden; margin: -24px auto 0">
          <gbl-lottie-player></gbl-lottie-player>
        </div>
      </div>
    </section>

    <section *ngSwitchCase="'DETAIL'" class="slide-in">
      <div class="text-center">
        <p class="mt-4">Success!</p>
      </div>
      <div class="p-4 bg-trans-blue rounded-lg text-center">
        <p class="text-sm">You updated your AML settings </p>
      </div>
      <button class="button button-primary my-4 mx-auto w-full" (click)="close()">Close</button>
    </section>
  </ng-container>
</mat-dialog-content>
