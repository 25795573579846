import { Directive, Input } from '@angular/core';
import { BaseComponent, CoreService } from '@vesto/xplat/core';

@Directive()
export abstract class AlertBaseComponent extends BaseComponent {
  _type = 'error';
  _message: string = null;
  icon: string = null;
  backgroundColor: string = null;
  fontColor: string = null;
  @Input() dark = false;

  constructor() {
    super();
  }

  @Input()
  set type(value: string) {
    this._type = value;
    switch (this._type) {
      case 'error':
        this.icon = CoreService.platformDeviceInfo.deviceDetails === 'browser' ? 'fal fa-exclamation-circle' : String.fromCharCode(parseInt('f06a', 16));
        this.backgroundColor = '#f8d7da';
        this.fontColor = '#842029';
        break;
      case 'warning':
        this.icon = CoreService.platformDeviceInfo.deviceDetails === 'browser' ? 'fas fa-exclamation-triangle' : String.fromCharCode(parseInt('f071', 16));
        this.backgroundColor = '#fff3cd';
        this.fontColor = '#664d03';
        break;
      case 'info':
        this.icon = CoreService.platformDeviceInfo.deviceDetails === 'browser' ? 'fal fa-info-circle' : String.fromCharCode(parseInt('f05a', 16));
        this.backgroundColor = '#ECF1FA';
        this.fontColor = '';
        break;
      default:
        this.icon = CoreService.platformDeviceInfo.deviceDetails === 'browser' ? 'fas fa-times-circle' : String.fromCharCode(parseInt('f06a', 16));
        this.backgroundColor = '#f8d7da';
        this.fontColor = this.dark ? '#E53E3E' : '#842029';
    }
  }

  get type(): string {
    return this._type;
  }

  @Input()
  set message(value: string) {
    if (value) {
      this._message = CoreService.translateService.instant(value);
    }
  }

  get message(): string {
    return this._message;
  }
}
