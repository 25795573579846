<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>

  <h3 class="section-number"><i class="fal fa-user-edit"></i> Change User Role</h3>
</div>
<mat-dialog-content>
  <ng-container [ngSwitch]="step">
    <form [formGroup]="formGroup" (ngSubmit)="changeRole()" *ngSwitchCase="'FORM_GROUP'">

      <div class="vesto-input mt-4 mb-2">
        <input focusMe="true" type="text" placeholder="Start typing" aria-label="Number" matInput [formControl]="formControl" [matAutocomplete]="auto" />
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="getOptionText" (optionSelected)="selectUser($event.option.value)">
          <mat-option *ngFor="let user of users$ | async" [value]="user"> {{ user.firstName }} {{ user.lastName }} {{ user.email }} </mat-option>
        </mat-autocomplete>
      </div>

      <div class="text-sm ml-4 slide-in" *ngIf="selectedUser">
        <span class="font-bold">Current Role:</span>
        {{ currentRole | replace : '_' : ' ' | titlecase }}
      </div>

      <div class="vesto-input vesto-select my-4">
        <ng-select formControlName="roleType" id="roleType" name="roleType" placeholder="New Role">
          <ng-option *ngFor="let roleType of RoleType | keyvalue" [value]="roleType.value">
            {{ roleType.value | replace : '_' : ' ' | titlecase }}
          </ng-option>
        </ng-select>
      </div>

      <gbl-alert [type]="error" [message]="error"></gbl-alert>

      <button class="button button-primary mt-6 w-full text-center block" type="submit">Change Role</button>
    </form>

    <section *ngSwitchCase="'TWO_FACTOR'">
      <div class="mt-4">
        <gbl-two-factor (sendSmsCode)="sendSmsCode($event)" (code)="confirmCode($event)"></gbl-two-factor>
      </div>
    </section>

    <section *ngSwitchCase="'BUSY'" class="mt-4">
      <div class="placeholder-long bg-flash"></div>
      <div class="placeholder-area bg-flash mt-4"></div>
    </section>

    <section *ngSwitchCase="'SUCCESS'">
      <gbl-lottie-player></gbl-lottie-player>
    </section>

    <section *ngSwitchCase="'DETAIL'" class="slide-in">
      <div class="text-center">
        <p class="mt-4">Success!</p>
      </div>
      <div class="p-3 mt-3 bg-trans-blue rounded-lg text-center text-sm">
        <p>
          {{ selectedUser.email }}'s role changed to {{ formGroup.controls.roleType.value | replace : '_' : ' ' | titlecase }}
        </p>
      </div>
      <button class="button button-primary my-3 mx-auto w-full" (click)="close()">Close</button>
    </section>
  </ng-container>
</mat-dialog-content>
