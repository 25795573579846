import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LinkService {
  constructor(
    @Inject(DOCUMENT)
    private document
  ) {}

  /**
   * Inject the State into the bottom of the <head>
   */
  addTag(tag: LinkDefinition, forceCreation?: boolean) {
    try {
      const selector = this._parseSelector(tag);
      const linkElement = <HTMLLinkElement>this.document.head.querySelector(selector) || this.document.head.appendChild(this.document.createElement('link'));
      if (linkElement) {
        Object.keys(tag).forEach((prop: string) => {
          linkElement[prop] = tag[prop];
        });
      }
    } catch (e) {
      console.error('Error within linkService : ', e);
    }
  }

  removeByAttribute(attr: string, attrValue?: string) {
    if (!attr) {
      return null;
    }
    if (attrValue) {
      attr += `=${attrValue}`;
    }
    const linkElement = <HTMLLinkElement>this.document.head.querySelector(`link[${attr}]`);
    if (linkElement) {
      this.document.head.removeChild(linkElement);
    }
  }

  private _parseSelector(tag: LinkDefinition): string {
    let selectorParams = '';
    if (tag.rel) {
      selectorParams = `[rel="${tag.rel}"]`;
    }
    if (tag.hreflang) {
      selectorParams = selectorParams + `[hreflang="${tag.hreflang}"]`;
    }
    // some can have multiple queries, ie: 'link[rel=alternate][hreflang=es]'
    return `link${selectorParams}`;
  }
}

export declare type LinkDefinition = {
  charset?: string;
  crossorigin?: string;
  href?: string;
  hreflang?: string;
  media?: string;
  rel?: string;
  rev?: string;
  sizes?: string;
  target?: string;
  type?: string;
} & {
  [prop: string]: string;
};
