import {IEnvironment} from '../interfaces/environment.interface';
import {IHttpClient} from '../interfaces/http.interface';
import {BaseApi} from './base-api';
import {InstitutionFeeType} from '../enums/institution-fee-type.enum';

export class InstitutionsApi extends BaseApi {
  constructor(protected environment: IEnvironment, protected httpClient: IHttpClient) {
    super(environment);
  }

  find(
    userId: string,
    institutionId: string
  ) {
    return this.httpClient.get(this.formatUrl(`/v${this.environment.vesto.version}/institutions/find/${userId}/${institutionId}`), {
      headers: this.getHeaders()
    });
  }

  findByUser(userId: string) {
    return this.httpClient.get(this.formatUrl(`/v${this.environment.vesto.version}/institutions/find-by-user/${userId}`), {
      headers: this.getHeaders()
    });
  }

  findAll() {
    return this.httpClient.get(this.formatUrl(`/v${this.environment.vesto.version}/institutions/find-all`), {
      headers: this.getHeaders()
    });
  }

  create(
    name: string,
    adminEmail: string,
    iosAppId: string,
    androidAppId: string,
    url: string,
    referrer: string,
    merchantEnabled: boolean
  ) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/institutions/create`),
      {
        name,
        adminEmail,
        iosAppId,
        androidAppId,
        url,
        referrer,
        merchantEnabled
      },
      {
        headers: this.getHeaders()
      }
    );
  }

  createAndDeployTokens(
    institutionId: string,
    name: string,
    symbol: string,
    underlyingSymbol: string,
    token: string,
    code: string
  ) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/institutions/create-and-deploy-tokens/${institutionId}/${token}/${code}`),
      {
        name,
        symbol,
        underlyingSymbol
      },
      {headers: this.getHeaders()}
    );
  }

  changeSettings(
    id: string,
    name: string,
    address: string,
    iosAppId: string,
    androidAppId: string,
    referrer: string,
    url: string,
    merchantEnabled: boolean,
    instantSettleEnabled: boolean,
    settleTransactionLimit: number,
    settleDailyLimit: number,
    feeType: InstitutionFeeType,
    feeFixed: number,
    feePercentage: number
  ) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/institutions/change-settings/${id}`),
      {
        name,
        address,
        iosAppId,
        androidAppId,
        referrer,
        url,
        merchantEnabled,
        instantSettleEnabled,
        settleTransactionLimit,
        settleDailyLimit,
        feeType,
        feeFixed,
        feePercentage
      },
      {
        headers: this.getHeaders()
      }
    );
  }

  changeTemplate(
    templateId: string,
    referenceId: string
  ) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/institutions/change-template/${templateId}/${referenceId}`),
      null,
      {
        headers: this.getHeaders()
      }
    );
  }

  changeAml(institutionId: string, defaultDailyBuyLimit: number, defaultDailySellLimit: number, defaultDailyTransferLimit: number, defaultDailyReceiveLimit: number, plaidEnabled: boolean) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/institutions/change-aml/${institutionId}`),
      {
        defaultDailyBuyLimit,
        defaultDailySellLimit,
        defaultDailyTransferLimit,
        defaultDailyReceiveLimit,
        plaidEnabled
      },
      {
        headers: this.getHeaders()
      }
    );
  }

  changeDeFi(institutionId: string, apy: number) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/institutions/change-defi/${institutionId}`),
      {
        apy
      },
      {
        headers: this.getHeaders()
      }
    );
  }

  sendSmsCode(
    userId: string
  ) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/institutions/send-sms-code/${userId}`), null, {
      headers: {
        ...this.getHeaders()
      }
    });
  }
}
