import { Directive, OnInit } from '@angular/core';
import {BaseComponent, CoreService, StorageKeys, uiCloseModal} from '@vesto/xplat/core';
import {distinctUntilChanged, filter, take, takeUntil} from 'rxjs/operators';
import {
  AccountType,
  AdditionalUserStatus,
  IAccount,
  IAdditionalUser,
  ITransaction,
  IUser,
  UserStatus
} from '@vesto/vesto';
import { ofType } from '@ngrx/effects';
import {AccountActions, TransactionActions} from '@vesto/ngx-vesto/state';
import {FeatureService} from "../../../services";
import {FormGroup} from "@angular/forms";

@Directive()
export abstract class AdditionalUsersModalBaseComponent extends BaseComponent implements OnInit {
  step: 'USERS' | 'TWO_FACTOR' | 'BUSY' | 'SUCCESS' | 'DETAIL' = 'USERS';
  user: IUser = null;
  accounts: IAccount[] = [];
  selectedAccount: IAccount;
  AccountType = AccountType;
  UserStatus = UserStatus;
  AdditionalUserStatus = AdditionalUserStatus;
  transaction: ITransaction;
  error: string;
  formGroup: FormGroup;
  additionalUser: IAdditionalUser;
  menuOpen = false;

  ngOnInit() {
    CoreService.userFacade.user$
      .pipe(
        filter(user => !! user),
        takeUntil(this.destroy$)
      )
      .subscribe((user) => {
        this.user = user;
      });
    CoreService.accountFacade.accounts$
      .pipe(
        filter(accounts => !!accounts),
        takeUntil(this.destroy$)
      )
      .subscribe((accounts) => {
        this.accounts = [];
        accounts.forEach(account => {
          if (!!this.selectedAccount && this.selectedAccount.id === account.id) {
            this.selectedAccount = account;
          }
          if (!account.shared) {
            this.accounts.push(account);
          }
        });
      });
    FeatureService.dashboardFacade.selectedAccount$
      .pipe(
        filter(selectedAccount => !!selectedAccount),
        takeUntil(this.destroy$)
      )
      .subscribe((selectedAccount) => {
        this.selectedAccount = selectedAccount;
      });

    this.formGroup = CoreService.formBuilder.group({
      accountId: !this.selectedAccount.shared ? this.selectedAccount.id : null
    })

    this.formGroup.controls.accountId.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((result) => {
      this.accounts.forEach((account) => {
        if (account.id === result) {
          this.selectedAccount = account;
          FeatureService.dashboardFacade.selectAccount(this.selectedAccount);
          return;
        }
      });
    });
  }

  sendSmsCode(isResendSms: boolean) {
    CoreService.transactionFacade.sendSmsCode();

    if (isResendSms) {
      CoreService.uiFacade.notification(`SMS code sent!`);
    }
  }

  suspendOrReactivateUser(additionalUser: IAdditionalUser) {
    this.menuOpen = false;
    this.step = 'BUSY';
    CoreService.actions$.pipe(ofType(AccountActions.activateUserSuccess, AccountActions.suspendUserSuccess), take(1), takeUntil(this.destroy$)).subscribe(() => {
      this.step = 'SUCCESS';
      CoreService.windowService.setTimeout(() => {
        this.step = 'USERS';
        CoreService.uiFacade.notification(additionalUser.status === AdditionalUserStatus.SUSPENDED ? `Reactivated ${additionalUser.user.firstName} ${additionalUser.user.lastName}` : `Suspended ${additionalUser.user.firstName} ${additionalUser.user.lastName}`);
      }, 1600);
    });

    CoreService.actions$.pipe(ofType(AccountActions.activateUserFailure, AccountActions.suspendUserFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.step = 'USERS';
      CoreService.uiFacade.notification(`apiErrors.${result.apiError.error}`);
    });

    if (additionalUser.status === AdditionalUserStatus.SUSPENDED) {
      CoreService.accountFacade.reactivateUser(additionalUser.id);
    } else {
      CoreService.accountFacade.suspendUser(additionalUser.id);
    }
  }

  removeUser(additionalUser: IAdditionalUser) {
    this.additionalUser = additionalUser;
    this.step = 'TWO_FACTOR';
  }

  confirmCode(code: string) {
    CoreService.actions$.pipe(ofType(TransactionActions.signSuccess)).subscribe(() => {
      this.step = 'SUCCESS';
      CoreService.windowService.setTimeout(() => {
        this.step = 'DETAIL';
      }, 1600);
    });

    CoreService.actions$.pipe(ofType(TransactionActions.removeUserSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      const transaction = result.transaction;
      CoreService.store.dispatch(TransactionActions.sign({ transaction, code, key: new Buffer(CoreService.storageService.getItem(StorageKeys.KEY), 'hex').toString() }));
    });

    CoreService.actions$.pipe(ofType(TransactionActions.removeUserFailure, TransactionActions.signFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.step = 'USERS';
      this.error = `apiErrors.${result.apiError.error}`;
    });

    CoreService.transactionFacade.removeUser(this.additionalUser.id, code);
  }

  close() {
    uiCloseModal();
  }

  back() {
    this.step = 'USERS';
  }

  openOrGoToAddUser() {
    FeatureService.dashboardFacade.openOrGoToAddUser();
  }
}
