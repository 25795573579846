<main class="sidenav">
  <section class="p-6 sidenav-header flex">
    <img class="w-32 flex-grow self-center" src="/assets/images/logo.svg" />
    <p *ngIf="UserUtil.hasRole(user, RoleType.ADMIN) && !UserUtil.hasRole(user, RoleType.SUPER_ADMIN)" class="uppercase tracking-wide text-sm text-right"><span *ngIf="user"></span>{{ institution?.name }} admin</p>
    <p *ngIf="UserUtil.hasRole(user, RoleType.ADMIN) && UserUtil.hasRole(user, RoleType.SUPER_ADMIN)" class="uppercase tracking-wide text-sm text-right self-end">super admin</p>
    <p *ngIf="UserUtil.hasRole(user, RoleType.MERCHANT)" class="uppercase tracking-wide text-sm text-right"> {{ user?.merchant?.name }}</p>
  </section>

  <section class="sidenav-body px-6 text-lg">
    <div class="rounded-60 bg-grey-light border-grey-light border-4 grid grid-cols-2 mt-4 text-sm mx-4 mb-2 relative cursor-pointer" *ngIf="UserUtil.hasRole(user, RoleType.ADMIN) || UserUtil.hasRole(user, RoleType.SUPER_ADMIN) || UserUtil.hasRole(user, RoleType.AUDITOR) || UserUtil.hasRole(user, RoleType.MERCHANT)">
      <div class="text-center p-2 relative z-10 transition-all uppercase text-xs" [ngClass]="isAdminView ? 'text-grey-dark' : 'text-white'" (click)="setIsAdminView(false)">
        Personal
      </div>
      <div class="text-center p-2 relative z-10 transition-all uppercase text-xs" [ngClass]="isAdminView ? 'text-white' : 'text-grey-dark'" (click)="setIsAdminView(true)">
        {{ UserUtil.hasRole(user, RoleType.MERCHANT) ? 'Merchant' : 'Admin' }}
      </div>
      <div class="absolute w-1/2 h-full bg-black transition-all rounded-60" [style.left]="isAdminView ? '50%' : '0'"></div>
    </div>
    <a class="sidenav-item" [class.selected]="selectedSection === Section.OVERVIEW" (click)="selectSection(Section.OVERVIEW)"><i class="fal fa-chart-line mr-1"></i> Overview</a>

    <a *ngIf="!isAdminView" class="sidenav-item" [class.selected]="selectedSection === Section.PERSONAL_ACCOUNTS" (click)="selectSection(Section.PERSONAL_ACCOUNTS)"><i class="fal fa-wallet mr-1"></i> Accounts</a>
    <div *ngIf="selectedSection === Section.PERSONAL_ACCOUNTS && !isAdminView" class="sidenav-panel" [@expandCollapseDetails]>
      <div *ngFor="let account of accounts" class="flex sidenav-subitem cursor-pointer items-center relative" [class.selected]="selectedAccount && selectedAccount.id === account.id" (click)="selectAccount(account)">
        <span *ngIf="account.requiresSignatures"><i class="fal fa-file-signature text-gold absolute" style="left: 18px; top: 50%; transform: translateY(-50%);"></i></span>
        <div class="flex-grow">
          {{ account.name }}
          <span class="font-light text-xs block" *ngIf="account.shared">(Shared by {{account.user.firstName}} {{account.user.lastName}})</span>
          <div *ngIf="account?.status !== AccountStatus.DEPLOYING_WALLET && account?.type !== AccountType.VLIGHTHOUSE" class="font-light text-sm">{{ account.balance.toFixed(2) | currency: 'USD' }}</div>
        </div>
        <span *ngIf="account.shared" class="ml-2" [matTooltip]="'This account is shared with you by ' + account.user.firstName + ' ' + account.user.lastName">
            <i class="fal fa-user-friends"></i>
          </span>
        <span *ngIf="account.additionalUsers?.length > 0" class="ml-2" [matTooltip]="additionalUserTooltip(account.additionalUsers)" (click)="openAdditionalUsers($event, account)">
            <i class="fal fa-user-plus"></i>
          </span>
        <span *ngIf="account.status === AccountStatus.DEPLOYING_WALLET" class="ml-2">
            <i class="fa fa-spinner fa-pulse"></i>
          </span>
      </div>
    </div>

    <a *ngIf="isAdminView"  class="sidenav-item" [class.selected]="selectedSection === Section.ADMIN_ACCOUNTS" (click)="selectSection(Section.ADMIN_ACCOUNTS)"><i class="fal fa-wallet mr-1"></i> Accounts</a>
    <div *ngIf="selectedSection === Section.ADMIN_ACCOUNTS  && isAdminView" class="sidenav-panel" [@expandCollapseDetails]>
      <div *ngFor="let account of adminAccounts" class="flex sidenav-subitem cursor-pointer items-center relative" [class.selected]="selectedAccount && selectedAccount.id === account.id" (click)="selectAccount(account)">
        <span *ngIf="account.requiresSignatures"><i class="fal fa-file-signature text-gold absolute" style="left: 18px; top: 50%; transform: translateY(-50%);"></i></span>
        <div class="flex-grow">
          {{ account.name }}
          <span class="font-light text-xs block" *ngIf="account.shared">(Shared by {{account.user.firstName}} {{account.user.lastName}})</span>
          <div *ngIf="account?.status !== AccountStatus.DEPLOYING_WALLET && account?.type !== AccountType.VLIGHTHOUSE" class="font-light text-sm">{{ account.balance.toFixed(2) | currency: 'USD' }}</div>
        </div>
        <span *ngIf="account.shared" class="ml-2" [matTooltip]="'This account is shared with you by ' + account.user.firstName + ' ' + account.user.lastName">
            <i class="fal fa-user-friends"></i>
          </span>
        <span *ngIf="account.additionalUsers?.length > 0" class="ml-2" [matTooltip]="additionalUserTooltip(account.additionalUsers)" (click)="openAdditionalUsers($event, account)">
            <i class="fal fa-user-plus"></i>
          </span>
        <span *ngIf="account.status === AccountStatus.DEPLOYING_WALLET" class="ml-2">
            <i class="fa fa-spinner fa-pulse"></i>
          </span>
      </div>
    </div>

    <a class="sidenav-item" *ngIf="(UserUtil.hasRole(user, RoleType.AUDITOR) || UserUtil.hasRole(user, RoleType.SUPER_ADMIN)) && isAdminView" [class.selected]="selectedSection === Section.SMART_CONTRACTS" (click)="selectSection(Section.SMART_CONTRACTS)"><i class="fal fa-file-contract ml-1 mr-1"></i> Smart Contracts <i *ngIf="mintingRequired || swappingSigningAddressesRequired" class="fal fa-exclamation-triangle text-gold"></i></a>
    <div *ngIf="selectedSection === Section.SMART_CONTRACTS" class="sidenav-panel" [@expandCollapseDetails]>
      <div *ngFor="let smartContract of smartContracts" class="sidenav-subitem cursor-pointer items-center flex relative" [class.selected]="selectedAccount && selectedAccount.id === smartContract.id" (click)="selectAccount(smartContract)">
        <span *ngIf="smartContract.requiresSignatures"><i class="fal fa-file-signature text-gold absolute" style="left: 18px; top: 50%; transform: translateY(-50%);"></i></span>
        <div class="flex-grow">
          <span *ngIf="smartContract.requiresSignatures"><i class="fal fa-file-signature text-gold" style="position: absolute;left: -31px;top: 5px"></i></span>
          {{ smartContract.name }}
          <i *ngIf="(smartContract.type === AccountType.VTOKEN && mintingRequired) || (smartContract.type === AccountType.VUSER && swappingSigningAddressesRequired)" class="fal fa-exclamation-triangle text-gold" [matTooltip]="mintingRequired ? 'Minting required' : 'Swap signing addresses required'"></i>
          <span *ngIf="smartContract.shared" class="font-light text-xs block">(Shared by {{smartContract.user.firstName}} {{smartContract.user.lastName}})</span>
        </div>
        <span *ngIf="smartContract.shared" class="ml-2" [matTooltip]="'This account is shared with you by ' + smartContract.user.firstName + ' ' + smartContract.user.lastName">
            <i class="fal fa-user-friends"></i>
          </span>
        <span *ngIf="smartContract.additionalUsers?.length > 0" class="ml-2" [matTooltip]="additionalUserTooltip(smartContract.additionalUsers)" (click)="openAdditionalUsers($event, smartContract)">
            <i class="fal fa-user-plus"></i>
          </span>
        <span *ngIf="smartContract.status === AccountStatus.DEPLOYING_WALLET" class="ml-2">
            <i class="fa fa-spinner fa-pulse"></i>
          </span>
      </div>
    </div>

    <a *ngIf="(UserUtil.hasRole(user, RoleType.SUPER_ADMIN)) && isAdminView" class="sidenav-item" [class.selected]="selectedSection?.startsWith(Section.GAS_TANKS)" (click)="selectGasTanks()"><i class="fal fa-gas-pump mr-1"></i> Gas Tanks <i *ngIf="gasTanksLow" class="fal fa-exclamation-triangle text-gold" matTooltip="Low Gas"></i></a>

    <a *ngIf="!isAdminView" class="sidenav-item" [class.selected]="selectedSection?.startsWith(Section.SETTINGS)" (click)="selectSection(Section.SETTINGS_PROFILE)"><i class="fal fa-cog mr-1"></i> Settings</a>
    <div *ngIf="selectedSection?.startsWith(Section.SETTINGS)" class="sidenav-panel flex flex-col" [@expandCollapseDetails]>
      <a class="sidenav-subitem" [class.selected]="selectedSection === Section.SETTINGS_PROFILE" (click)="selectSection(Section.SETTINGS_PROFILE)"> Profile </a>
      <a class="sidenav-subitem" [class.selected]="selectedSection === Section.SETTINGS_BANK_ACCOUNTS" (click)="selectSection(Section.SETTINGS_BANK_ACCOUNTS)"> Payment Methods </a>
    </div>

    <a *ngIf="(UserUtil.hasRole(user, RoleType.ADMIN) || UserUtil.hasRole(user, RoleType.SUPER_ADMIN)) && isAdminView" class="sidenav-item" [class.selected]="selectedSection?.startsWith(Section.INSTITUTION_SETTINGS)" (click)="selectSection(Section.INSTITUTION_SETTINGS)"><i class="fal fa-cog mr-1"></i> Settings</a>

    <a *ngIf="UserUtil.hasRole(user, RoleType.SUPER_ADMIN) && isAdminView" [class.selected]="selectedSection === Section.INSTITUTIONS" class="sidenav-item" (click)="selectSection(Section.INSTITUTIONS)"><i class="fal fa-university mr-1"></i> Institutions </a>

    <a *ngIf="UserUtil.hasRole(user, RoleType.SUPER_ADMIN) && isAdminView" [class.selected]="selectedSection === Section.GLOBAL_TRANSACTIONS" class="sidenav-item" (click)="selectSection(Section.GLOBAL_TRANSACTIONS)"><i class="fal fa-list-ul mr-1"></i> Transactions <i *ngIf="actionRequired" class="fal fa-exclamation-triangle text-gold" matTooltip="Action Required"></i></a>

    <a *ngIf="UserUtil.hasRole(user, RoleType.ADMIN) && !UserUtil.hasRole(user, RoleType.SUPER_ADMIN) && isAdminView" [class.selected]="selectedSection === Section.INSTITUTION_MERCHANTS" class="sidenav-item" (click)="selectSection(Section.INSTITUTION_MERCHANTS)"><i class="fal fa-store-alt mr-1"></i> Merchants </a>

    <!--<a *ngIf="(UserUtil.hasRole(user, RoleType.ADMIN) || UserUtil.hasRole(user, RoleType.SUPER_ADMIN))  && isAdminView" class="sidenav-item" [class.selected]="selectedSection === Section.ALERTS" (click)="selectedSectionChange(Section.ALERTS)"><i class="fal fa-exclamation-circle mr-1"></i> Alerts</a>-->

    <a *ngIf="UserUtil.hasRole(user, RoleType.MERCHANT) && isAdminView" class="sidenav-item" [class.selected]="selectedSection === Section.STORES" (click)="selectSection(Section.STORES)"><i class="fal fa-store-alt mr-1"></i> Stores</a>

    <a *ngIf="UserUtil.hasRole(user, RoleType.MERCHANT) && isAdminView" class="sidenav-item" [class.selected]="selectedSection === Section.USERS" (click)="selectSection(Section.USERS)"><i class="fal fa-users mr-1"></i> Users</a>

    <a *ngIf="UserUtil.hasRole(user, RoleType.MERCHANT) && isAdminView" class="sidenav-item" [class.selected]="selectedSection === Section.MERCHANT_SETTINGS" (click)="selectSection(Section.MERCHANT_SETTINGS)"><i class="fal fa-cog mr-1"></i> Merchant Settings</a>
    <!--<a *ngIf="(UserUtil.hasRole(user, RoleType.ADMIN) || UserUtil.hasRole(user, RoleType.SUPER_ADMIN))  && isAdminView" class="sidenav-item" [class.selected]="selectedSection === Section.ALERTS" (click)="selectedSectionChange(Section.ALERTS)"><i class="fal fa-exclamation-circle mr-1"></i> History</a>-->

    <div class="border border-grey mt-6"></div>
  </section>

  <section class="absolute bottom-0 p-6">
    <span [matMenuTriggerFor]="sddMenu"><i class="fal fa-plus-circle text-3xl"></i></span>
    <mat-menu #sddMenu="matMenu">
      <button mat-menu-item (click)="createAccount()">Create Account</button>
      <button mat-menu-item (click)="addUser()">Add User to Account</button>
      <button mat-menu-item (click)="openOrGoToInviteUser()">Invite a Friend</button>

      <ng-container *ngIf="(UserUtil.hasRole(user, RoleType.MERCHANT) || UserUtil.hasRole(user, RoleType.ADMIN)) && !UserUtil.hasRole(user, RoleType.SUPER_ADMIN)">
        <div class="uppercase tracking-wide text-xs border-top-light pt-2 pl-2">merchant</div>
        <button mat-menu-item (click)="openOrGoToInviteToMerchant()">Invite to Merchant</button>
      </ng-container>

      <ng-container *ngIf="UserUtil.hasRole(user, RoleType.ADMIN) || UserUtil.hasRole(user, RoleType.SUPER_ADMIN)">
        <div class="uppercase tracking-wide text-xs border-top-light pt-2 pl-2">ADMIN</div>
        <button mat-menu-item (click)="openOrGoToUserSearch()">User Search</button>
      </ng-container>

      <ng-container *ngIf="UserUtil.hasRole(user, RoleType.SUPER_ADMIN)">
        <div class="uppercase tracking-wide text-xs border-top-light pt-2 pl-2">SUPER ADMIN</div>
        <button mat-menu-item (click)="openOrGoToSetSupplyLimit()">Set Supply Limit</button>
        <button mat-menu-item (click)="openOrGoToCreateInstitution()">Create Institution</button>
        <button mat-menu-item (click)="deployContracts()">Deploy Contracts</button>
      </ng-container>
    </mat-menu>
  </section>
</main>
