import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IEnvironment, ObjectUtil } from '@vesto/vesto';
import {Action, Store} from '@ngrx/store';
import { TransactionActions } from './transaction.actions';
import { EnvironmentToken, TransactionsApiService } from '../../services';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { ApiActions } from '../api';
import { UserFacade } from '../../state/user';
import { VestoState } from '../vesto.state';
import {CoreActions} from "@vesto/xplat/core";

@Injectable()
export class TransactionEffects {
  constructor(private userFacade: UserFacade, private actions$: Actions, @Inject(EnvironmentToken) protected environment: IEnvironment, protected store: Store<VestoState.IState>, private transactionsApiService: TransactionsApiService) {}

  find$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.find),
        mergeMap(({ transactionId, wasPending }) => {
          return this.transactionsApiService.find(transactionId).pipe(
            mergeMap((result) => {
              return from([TransactionActions.findSuccess({ transaction: result, wasPending })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.findFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  findByAccount$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.findByAccount),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{ accountId, page, size }, user]) => {
          return this.transactionsApiService.findByAccount(user.id, accountId, page, size).pipe(
            mergeMap((result) => {
              return from([TransactionActions.findByAccountSuccess({ transactions: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.findByAccountFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  findByAccountAndSymbol$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.findByAccountAndSymbol),
        mergeMap(({ accountId, symbol, page, size }) => {
          return this.transactionsApiService.findByAccountAndSymbol(accountId, symbol, page, size).pipe(
            mergeMap((result) => {
              return from([TransactionActions.findByAccountAndSymbolSuccess({ transactions: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.findByAccountAndSymbolFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  findByTypeAndStatus$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.findByTypeAndStatus),
        mergeMap(({ _type, status, page, size }) => {
          return this.transactionsApiService.findByTypeAndStatus(_type, status, page, size).pipe(
            mergeMap((result) => {
              return from([TransactionActions.findByTypeAndStatusSuccess({ transactions: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.findByTypeAndStatusFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  findBuyOrSellActionRequired$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.findBuyOrSellActionRequired),
        mergeMap(({ page, size }) => {
          return this.transactionsApiService.findBuyOrSellActionRequired(page, size).pipe(
            mergeMap((result) => {
              return from([TransactionActions.findBuyOrSellActionRequiredSuccess({ transactions: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.findBuyOrSellActionRequiredFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  changeStatus$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.changeStatus),
        withLatestFrom(this.userFacade.token$),
        mergeMap(([{ transactionId, status, code }, token]) => {
          return this.transactionsApiService.changeStatus(transactionId, status, token, code).pipe(
            mergeMap((result) => {
              return from([TransactionActions.changeStatusSuccess({ transaction: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.changeStatusFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  transferGas$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.transferGas),
        withLatestFrom(this.userFacade.user$, this.userFacade.token$),
        mergeMap(([{ gasTankId, blockchain, to, code }, user, token]) => {
          return this.transactionsApiService.transferGas(user.id, gasTankId, blockchain, to, token, code).pipe(
            mergeMap((result) => {
              return from([TransactionActions.transferGasSuccess({ transaction: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.transferGasFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  syncAddresses$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.syncAddresses),
        withLatestFrom(this.userFacade.token$),
        mergeMap(([{ blockchain, code }, token]) => {
          return this.transactionsApiService.syncAddresses(blockchain, token, code).pipe(
            mergeMap((result) => {
              return from([TransactionActions.syncAddressesSuccess({ transaction: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.syncAddressesFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  syncImplementationAddresses$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.syncImplementationAddresses),
        withLatestFrom(this.userFacade.token$),
        mergeMap(([{ blockchain, code }, token]) => {
          return this.transactionsApiService.syncImplementationAddresses(blockchain, token, code).pipe(
            mergeMap((result) => {
              return from([TransactionActions.syncImplementationAddressesSuccess({ transaction: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.syncImplementationAddressesFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  syncApys$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.syncApys),
        withLatestFrom(this.userFacade.token$),
        mergeMap(([{ code }, token]) => {
          return this.transactionsApiService.syncApys(token, code).pipe(
            mergeMap((result) => {
              return from([TransactionActions.syncApysSuccess({ transaction: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.syncApysFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  setUnderlyingSupplyLimit$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.setUnderlyingSupplyLimit),
        withLatestFrom(this.userFacade.token$),
        mergeMap(([{ symbol, underlyingLimit, code }, token]) => {
          return this.transactionsApiService.setUnderlyingSupplyLimit(symbol, underlyingLimit, token, code).pipe(
            mergeMap((result) => {
              return from([TransactionActions.setUnderlyingSupplyLimitSuccess({ transaction: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.setUnderlyingSupplyLimitFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  swapVSigningAddress$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.swapVSigningAddress),
        withLatestFrom(this.userFacade.user$, this.userFacade.token$),
        mergeMap(([{ code }, user, token]) => {
          return this.transactionsApiService.swapVSigningAddress(user.id, token, code).pipe(
            mergeMap((result) => {
              return from([TransactionActions.swapVSigningAddressSuccess({ transaction: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.swapVSigningAddressFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  buy$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.buy),
        withLatestFrom(this.userFacade.user$, this.userFacade.token$),
        mergeMap(([{ accountId, symbol, bankAccountId, amount, memo, code }, user, token]) => {
          return this.transactionsApiService.buy(user.id, accountId, symbol, bankAccountId, amount, memo, token, code).pipe(
            mergeMap((result) => {
              return from([TransactionActions.buySuccess({ transaction: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.buyFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  addYearnVault$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.addYearnVault),
        withLatestFrom(this.userFacade.user$, this.userFacade.token$),
        mergeMap(([{ address, code }, user, token]) => {
          return this.transactionsApiService.addYearnVault(user.id, address, token, code).pipe(
            mergeMap((result) => {
              return from([TransactionActions.addYearnVaultSuccess({ transaction: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.addYearnVaultFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  mint$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.mint),
        withLatestFrom(this.userFacade.user$, this.userFacade.token$),
        mergeMap(([{ symbol, code }, user, token]) => {
          return this.transactionsApiService.mint(user.id, symbol, token, code).pipe(
            mergeMap((result) => {
              return from([TransactionActions.mintSuccess({ transaction: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.mintFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  sign$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(TransactionActions.sign),
      withLatestFrom(this.userFacade.user$, this.userFacade.token$),
      mergeMap(([{ transaction, code, key }, user, token]) => {
        return this.transactionsApiService.sign(key, user.id, transaction, token, code).pipe(
          mergeMap((result) => {
            return from([TransactionActions.signSuccess({ transaction: result })]);
          }),
          catchError((error) => {
            const apiError = ObjectUtil.toApiError(error);
            return from([TransactionActions.signFailure({ apiError }), ApiActions.setError({ apiError })]);
          })
        );
      })
    )
  );

  sell$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.sell),
        withLatestFrom(this.userFacade.user$, this.userFacade.token$),
        mergeMap(([{ accountId, symbol, bankAccountId, amount, memo, code }, user, token]) => {
          return this.transactionsApiService.sell(user.id, accountId, symbol, bankAccountId, amount, memo, token, code).pipe(
            mergeMap((result) => {
              return from([TransactionActions.sellSuccess({ transaction: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.sellFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  transferToEmail$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.transferToEmail),
        withLatestFrom(this.userFacade.user$, this.userFacade.token$),
        mergeMap(([{ accountId, email, symbol, amount, memo, code }, user, token]) => {
          return this.transactionsApiService.transferToEmail(user.id, accountId, email, symbol, amount, memo, token, code).pipe(
            mergeMap((result) => {
              return from([TransactionActions.transferToEmailSuccess({ transaction: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.transferToEmailFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  transferToAddress$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.transferToAddress),
        withLatestFrom(this.userFacade.user$, this.userFacade.token$),
        mergeMap(([{ accountId, address, symbol, amount, memo, code }, user, token]) => {
          return this.transactionsApiService.transferToAddress(user.id, accountId, address, symbol, amount, memo, token, code).pipe(
            mergeMap((result) => {
              return from([TransactionActions.transferToAddressSuccess({ transaction: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.transferToAddressFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  createPaymentToken$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.createPaymentToken),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{ symbol, amount, memo }, user]) => {
          return this.transactionsApiService.createPaymentToken(symbol, amount, memo).pipe(
            mergeMap((result) => {
              return from([TransactionActions.createPaymentTokenSuccess({ token: result.token })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.createPaymentTokenFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  findPaymentInfo$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.findPaymentInfo),
        mergeMap(({ token }) => {
          return this.transactionsApiService.findPaymentInfo(token).pipe(
            mergeMap((result) => {
              return from([TransactionActions.findPaymentInfoSuccess({ paymentInfo: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.findPaymentInfoFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  payByToken$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.payByToken),
        withLatestFrom(this.userFacade.token$),
        mergeMap(([{ accountId, paymentToken, tip, code }, token]) => {
          return this.transactionsApiService.payByToken(accountId, paymentToken, tip, token, code).pipe(
            mergeMap((result) => {
              return from([TransactionActions.payByTokenSuccess({ transaction: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.payByTokenFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  deployElement$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.syncElement),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{ accountId, trancheAddress, principalTokenPoolAddress, yieldTokenPoolAddress }, user]) => {
          return this.transactionsApiService.syncElement(user.id, accountId, trancheAddress, principalTokenPoolAddress, yieldTokenPoolAddress).pipe(
            mergeMap((result) => {
              return from([TransactionActions.syncElementSuccess({ transaction: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.syncElementFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  addUser$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.addUser),
        withLatestFrom(this.userFacade.user$, this.userFacade.token$),
        mergeMap(([{ accountId, email, code }, user, token]) => {
          return this.transactionsApiService.addUser(user.id, accountId, email, token, code).pipe(
            mergeMap((result) => {
              return from([TransactionActions.addUserSuccess({ account: result.account, transaction: result.transaction })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.addUserFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  changeRole$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.changeRole),
        withLatestFrom(this.userFacade.token$),
        mergeMap(([{ userId, roleType, code }, token]) => {
          return this.transactionsApiService.changeRole(userId, roleType, token, code).pipe(
            mergeMap((result) => {
              return from([TransactionActions.changeRoleSuccess({ transaction: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.changeRoleFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  swapSigningAddresses$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.swapSigningAddresses),
        withLatestFrom(this.userFacade.token$),
        mergeMap(([{ code }, token]) => {
          return this.transactionsApiService.swapSigningAddresses(token, code).pipe(
            mergeMap((result) => {
              return from([TransactionActions.swapSigningAddressesSuccess({ transaction: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.swapSigningAddressesFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  removeUser$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.removeUser),
        withLatestFrom(this.userFacade.user$, this.userFacade.token$),
        mergeMap(([{ additionalUserId, code }, user, token]) => {
          return this.transactionsApiService.removeUser(user.id, additionalUserId, token, code).pipe(
            mergeMap((result) => {
              return from([TransactionActions.removeUserSuccess({ account: result.account, transaction: result.transaction })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.removeUserFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  setDailyLimits$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.setDailyLimits),
        withLatestFrom(this.userFacade.user$, this.userFacade.token$),
        mergeMap(([{ email, dailyBuyLimit, dailySellLimit, dailyTransferLimit, code }, user, token]) => {
          return this.transactionsApiService.setDailyLimits(user.id, email, dailyBuyLimit, dailySellLimit, dailyTransferLimit, token, code).pipe(
            mergeMap((result) => {
              return from([TransactionActions.setDailyLimitsSuccess({ transaction: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.setDailyLimitsFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  setSettleLimits$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.setSettleLimits),
        withLatestFrom(this.userFacade.user$, this.userFacade.token$),
        mergeMap(([{ enabled, transactionLimit, dailyLimit, code }, user, token]) => {
          return this.transactionsApiService.setSettleLimits(user.id, enabled, transactionLimit, dailyLimit, token, code).pipe(
            mergeMap((result) => {
              return from([TransactionActions.setSettleLimitsSuccess({ institution: result.institution, transaction: result.transaction })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.setSettleLimitsFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  wireToCircle$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.wireToCircle),
        withLatestFrom(this.userFacade.token$, this.userFacade.user$),
        mergeMap(([{code}, token, user]) => {
          return this.transactionsApiService.wireToCircle(user.id, token, code).pipe(
            mergeMap(result => {
              return from([TransactionActions.wireToCircleSuccess({transaction: result})]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.wireToCircleFailure({apiError}), ApiActions.setError({apiError})]);
            })
          );
        })
      )
  );

  wireToFbo$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.wireToFbo),
        withLatestFrom(this.userFacade.token$, this.userFacade.user$),
        mergeMap(([{code}, token, user]) => {
          return this.transactionsApiService.wireToFbo(user.id, token, code).pipe(
            mergeMap(result => {
              return from([TransactionActions.wireToFboSuccess({transaction: result})]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.wireToFboFailure({apiError}), ApiActions.setError({apiError})]);
            })
          );
        })
      )
  );

  redeem$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.redeem),
        withLatestFrom(this.userFacade.token$, this.userFacade.user$),
        mergeMap(([{transactionId, code}, token, user]) => {
          return this.transactionsApiService.redeem(user.id, transactionId, token, code).pipe(
            mergeMap(result => {
              return from([TransactionActions.redeemSuccess({transaction: result})]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.redeemFailure({apiError}), ApiActions.setError({apiError})]);
            })
          );
        })
      )
  );

  sendSmsCode$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.sendSmsCode),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{}, user]) => {
          return this.transactionsApiService.sendSmsCode(user.id).pipe(
            mergeMap((result) => {
              return from([TransactionActions.sendSmsCodeSuccess({ token: result.token })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([TransactionActions.sendSmsCodeFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  success$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.addUserSuccess, TransactionActions.removeUserSuccess, TransactionActions.setSettleLimitsSuccess, TransactionActions.redeemSuccess, TransactionActions.wireToCircleSuccess, TransactionActions.setDailyLimitsSuccess, TransactionActions.changeRoleSuccess, TransactionActions.addYearnVaultSuccess, TransactionActions.transferGasSuccess, TransactionActions.syncImplementationAddressesSuccess, TransactionActions.syncAddressesSuccess, TransactionActions.swapVSigningAddressSuccess, TransactionActions.syncElementSuccess, TransactionActions.transferToAddressSuccess, TransactionActions.transferToEmailSuccess, TransactionActions.payByTokenSuccess, TransactionActions.sellSuccess, TransactionActions.buySuccess, TransactionActions.addUserSuccess, TransactionActions.removeUserSuccess, TransactionActions.mintSuccess),
        mergeMap(({transaction}) => {
            return !!transaction ? from([TransactionActions.set({transaction})]) : from([CoreActions.noop()]);
        })
      )
  );
}
