<div *ngIf="user" class="bg-white shadowed rounded mb-3 p-3">
  <h4>Personal Info</h4>
  <div class="flex mt-4 text-sm">
    <div class="flex-1">
      <span class="block font-bold">First Name</span>
      {{ user.firstName }}
    </div>
    <div class="flex-1">
      <span class="block font-bold">Last Name</span>
      {{ user.lastName }}
    </div>
  </div>
  <div class="flex mt-4">
    <div class="flex-1 text-sm">
      <span class="block font-bold">Address</span>
      {{ user.address }}
    </div>
    <button class="button button-primary button-sm self-end" (click)="openOrGoToChangePersonalInfo()">Change Personal Info</button>
  </div>
</div>
<div *ngIf="user" class="bg-white shadowed rounded my-4 p-3">
  <h4>Security</h4>
  <div class="flex border-bottom-light py-3">
    <div class="flex-1 text-sm">
      <span class="block font-bold">Email</span>
      {{ user.email }}
    </div>
    <button class="button button-primary button-sm self-end" (click)="openOrGoToChangeEmail()">Change Email</button>
  </div>
  <div class="flex border-bottom-light py-3 mb-4">
    <div class="flex-1 text-sm">
      <span class="block font-bold">Mobile</span>
      {{ user.mobile }}
    </div>
    <button class="button button-primary button-sm self-end" (click)="openOrGoToChangeMobile()">Change Mobile</button>
  </div>
  <div class="flex text-sm">
    <div class="flex-1">
      <span class="block font-bold">Daily Buy Limit</span>
      {{ user.dailyBuyLimit | currency }}
    </div>
    <div class="flex-1">
      <span class="block font-bold">Daily Sell Limit</span>
      {{ user.dailySellLimit | currency }}
    </div>
    <div class="flex-1">
      <span class="block font-bold">Daily Transfer Limit</span>
      {{ user.dailyTransferLimit | currency }}
    </div>
  </div>
  <p>
    <a class="underline text-sm mt-4" (click)="openOrGoToChangePassword()">{{ 'labels.changePassword' | translate }}</a>
  </p>
  <a class="underline text-sm mt-4" (click)="requestLimitsIncrease()">Request Limit Increase</a>
</div>
