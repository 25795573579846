<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <h3 class="section-number"><i class="fal fa-sliders-v"></i> Set Settle Limits</h3>
</div>
<mat-dialog-content>
  <ng-container [ngSwitch]="step">
    <form [formGroup]="formGroup" (ngSubmit)="updateLimits()" *ngSwitchCase="'FORM_GROUP'">
      <div class="vesto-input my-2">
        <label>
          Instant Settle Per Transaction Limit
          <i class="fas fa-info-circle" matTooltip="Any buy transaction above this amount will not be instantly settled and instead will take 1-3 days"></i>
        </label>
        <gbl-input class="currency-input" mask="currency" formControlName="settleTransactionLimit"></gbl-input>
      </div>

      <div class="vesto-input my-2">
        <label class="inline">
          Instant Settle Daily Limit
          <i class="fas fa-info-circle" matTooltip="After reaching this limit any transactions on the same day will not be instantly settled"></i>
        </label>
        <gbl-input class="currency-input" mask="currency" formControlName="settleDailyLimit"></gbl-input>
      </div>

      <div class="mt-3">
        <mat-slide-toggle formControlName="instantSettleEnabled">Instant Settlement Enabled</mat-slide-toggle>
      </div>

      <gbl-alert [type]="error" [message]="error"></gbl-alert>

      <button class="button button-primary w-full mt-4" type="submit">Update</button>
    </form>

    <section *ngSwitchCase="'TWO_FACTOR'">
      <gbl-two-factor (sendSmsCode)="sendSmsCode($event)" (code)="confirmCode($event)"></gbl-two-factor>
      <gbl-alert [type]="error" [message]="error"></gbl-alert>
    </section>

    <section *ngSwitchCase="'BUSY'" class="mt-4">
      <div class="placeholder-long bg-flash"></div>
      <div class="placeholder-area bg-flash mt-4"></div>
    </section>

    <section *ngSwitchCase="'SUCCESS'">
      <gbl-lottie-player></gbl-lottie-player>
    </section>

    <section *ngSwitchCase="'DETAIL'">
      <div class="slide-in">
        <div class="text-center">
          <p>Success!</p>
          <p class="text-sm mb-4">You transaction has been submitted to the blockchain</p>
        </div>
        <div class="p-4 bg-trans-blue rounded-lg">
          <gbl-transaction [transaction]="transaction"></gbl-transaction>
        </div>
        <button class="button button-primary my-4 mx-auto w-full" (click)="close()">Close</button>
      </div>
    </section>
  </ng-container>
</mat-dialog-content>
