<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <h3 class="section-number"><i class="fal fa-search"></i> User Search</h3>
</div>
<mat-dialog-content>
  <form>
    <p class="mb-2 text-sm">Enter the user's first &amp; last name, email, or mobile number</p>
    <div class="vesto-input">
      <input focusMe="true" type="text" placeholder="Start typing" aria-label="Number" matInput [formControl]="formControl" [matAutocomplete]="auto" />
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="getOptionText" (optionSelected)="selectUser($event.option.value)">
        <mat-option *ngFor="let user of users$ | async" [value]="user"> {{ user.firstName }} {{ user.lastName }} {{ user.email }} </mat-option>
      </mat-autocomplete>
    </div>
  </form>
</mat-dialog-content>
