<mat-dialog-content>
  <div class="text-center">
    <h3 class="my-3">Verify your identity</h3>
  </div>
  <p>Before you can make any transactions we need to make sure we know who you are.</p>
  <a class="underline text-grey text-sm">Why does Vesto need this information?</a>

  <form [formGroup]="formGroup" (ngSubmit)="verifyIdentity()">
    <div class="vesto-input w-full mt-3">
      <label>Home Address</label>
      <input type="text" id="address" ngx-google-places-autocomplete #placesRef="ngx-places" (onAddressChange)="addressChange($event)" placeholder="Enter your address" name="address" formControlName="address" />
    </div>

    <gbl-alert [type]="error" [message]="error"></gbl-alert>

    <button class="button button-primary w-full mt-5" type="submit" [disabled]="!formGroup.valid">Verify Identity</button>
  </form>
</mat-dialog-content>
