<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <h3 class="section-number"><i class="fal fa-user-edit"></i> Change Personal Info</h3>
</div>
<mat-dialog-content [ngSwitch]="step">
  <form [formGroup]="formGroup" (ngSubmit)="changePersonalInfo()" *ngSwitchCase="'FORM_GROUP'">
    <div class="vesto-input my-2">
      <label for="firstName">First Name</label>
      <input type="text" formControlName="firstName" id="firstName" name="firstName" />
    </div>
    <div class="vesto-input my-2">
      <label for="firstName">Last Name</label>
      <input type="text" formControlName="lastName" id="lastName" name="firstName" />
    </div>
    <div class="vesto-input my-2">
      <label for="address">Address</label>
      <input type="text" id="address" ngx-google-places-autocomplete #placesRef="ngx-places" (onAddressChange)="addressChange($event)" placeholder="Enter your address..." name="address" formControlName="address" />
    </div>

    <div class="bg-light-gold rounded-lg p-3 mt-4 flex text-darker-gold text-xs items-center" *ngIf="user && user?.status !== UserStatus.IDENTITY_NOT_VERIFIED">
      <i class="fal fa-exclamation-triangle text-2xl mr-4"></i>
      <div>
        <p class="font-bold mb-2">
          Changing your personal info will require you to verify your identity again.
        </p>
        <p>
          After successfully changing your personal info you will not be able to transact until you have verified your identity.
        </p>
      </div>
    </div>

    <gbl-alert [type]="error" [message]="error"></gbl-alert>

    <button class="button button-primary w-full mt-4" type="submit">Submit</button>
  </form>

  <section *ngSwitchCase="'BUSY'" class="mt-4">
    <div class="placeholder-long bg-flash"></div>
    <div class="placeholder-area bg-flash mt-4"></div>
  </section>

  <section *ngSwitchCase="'SUCCESS'">
    <gbl-lottie-player></gbl-lottie-player>
  </section>

  <section *ngSwitchCase="'DETAIL'">
    <div class="slide-in">
      <div class="text-center mb-2">
        Success!
      </div>
      <div class="p-4 bg-trans-blue rounded-lg text-sm">
        <p class="font-bold mb-2">
          You updated your personal info.
        </p>
        <p>
          Before you can make any transactions you'll need to verify your identity again.
        </p>
      </div>
      <button class="button button-primary my-4 mx-auto w-full" (click)="openVerifyIdentity()">Verify Identity</button>
    </div>
  </section>
</mat-dialog-content>
