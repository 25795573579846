import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IEnvironment, ObjectUtil } from '@vesto/vesto';
import { Action, Store } from '@ngrx/store';
import { EnvironmentToken, MarketDataApiService } from '../../services';
import { catchError, mergeMap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { VestoState } from '../../state';
import { MarketDataActions } from './market-data.actions';
import { ApiActions } from '../api/api.actions';

@Injectable()
export class MarketDataEffects {
  constructor(private actions$: Actions, @Inject(EnvironmentToken) protected environment: IEnvironment, protected store: Store<VestoState.IState>, private marketDataApiService: MarketDataApiService) {}

  getDeFi$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MarketDataActions.getDeFi),
        mergeMap(() => {
          return this.marketDataApiService.getDeFi().pipe(
            mergeMap((result) => {
              return from([MarketDataActions.getDeFiSuccess({ data: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([MarketDataActions.getDeFiFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  getDeFiHistory$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MarketDataActions.getDeFiHistory),
        mergeMap(({ period }) => {
          return this.marketDataApiService.getDeFiHistory(period).pipe(
            mergeMap((result) => {
              return from([MarketDataActions.getDeFiHistorySuccess({ histories: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([MarketDataActions.getDeFiHistoryFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );
}
