import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { VestoSelectors, VestoState } from '../vesto.state';
import { Observable } from 'rxjs';
import {IMerchant, MerchantFeeType} from '@vesto/vesto';
import {MerchantActions} from './merchant.actions';

@Injectable({
  providedIn: 'root'
})
export class MerchantFacade {
  merchants$: Observable<IMerchant[]> = this.store.pipe(select(VestoSelectors.merchants));

  constructor(private store: Store<VestoState.IState>) {}

  findByInstitution() {
    this.store.dispatch(MerchantActions.findByInstitution());
  }

  create(
    name: string,
    address: string,
    feeType: MerchantFeeType,
    feeFixed: number,
    feePercentage: number,
    email: string
  ) {
    this.store.dispatch(MerchantActions.create({name, address, feeType, feeFixed, feePercentage, email}));
  }

  changeSettings(
    merchantId: string,
    name: string,
    address: string,
    feeType: MerchantFeeType,
    feeFixed: number,
    feePercentage: number
  ) {
    this.store.dispatch(MerchantActions.changeSettings({merchantId, name, address, feeType, feeFixed, feePercentage}));
  }

  addUser(
    merchantId: string,
    userId: string
  ) {
    this.store.dispatch(MerchantActions.addUser({merchantId, userId}));
  }

  removeUser(
    merchantId: string,
    userId: string
  ) {
    this.store.dispatch(MerchantActions.removeUser({merchantId, userId}));
  }

  promoteUser(
    merchantId: string,
    userId: string
  ) {
    this.store.dispatch(MerchantActions.promoteUser({merchantId, userId}));
  }

  demoteUser(
    merchantId: string,
    userId: string
  ) {
    this.store.dispatch(MerchantActions.demoteUser({merchantId, userId}));
  }
}
