import { IUser } from './user.interface';
import { SignatureType } from '../enums/signature-type.enum';

export interface ISignature {
  id: string;
  user: IUser;
  type: SignatureType;
  updated: string;
  created: string;
}
