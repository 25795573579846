import {Injectable} from '@angular/core';
import {Action} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {from, Observable} from 'rxjs';
import { mergeMap, withLatestFrom} from 'rxjs/operators';
import {DashboardActions} from './dashboard.actions';
import {DashboardFacade} from './dashboard.facade';
import {Section} from '../state/dashboard.state';
import {AccountActions, UserFacade} from '@vesto/ngx-vesto/state';

@Injectable()
export class DashboardAccountEffects {
  constructor(private actions$: Actions, private dashboardFacade: DashboardFacade, private userFacade: UserFacade) {
  }

  findByUserSuccess$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(AccountActions.findByUserSuccess),
        withLatestFrom(this.dashboardFacade.selectedSection$),
        mergeMap(([{accounts}, selectedSection]) => {
          return from([DashboardActions.selectSection({section: selectedSection})]);
        })
      )
  );

  createSuccess$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(AccountActions.createSuccess),
        mergeMap(({account}) => {
          return from([
            AccountActions.find({accountId: account.id}),
            DashboardActions.selectSection({section: Section.PERSONAL_ACCOUNTS}),
            DashboardActions.selectAccount({account})
          ]);
        })
      )
  );
}
