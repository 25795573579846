import { ITransaction, IUser, RoleType } from '@vesto/vesto';

export class UserUtil {
  static hasRole(user: IUser, type: RoleType): boolean {
    if (!user) {
      return false;
    }
    for (const role of user.roles) {
      if (role.type === type) {
        return true;
      }
    }
    return false;
  }

  static hasSigned(user: IUser, transaction: ITransaction): boolean {
    for (const signature of transaction.signatures) {
      if (!signature.user) {
        continue;
      }
      if (signature.user.id === user.id) {
        return true;
      }
    }
    return false;
  }
}
