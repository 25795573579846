import {Directive, Inject, OnInit} from '@angular/core';
import { BaseComponent, CoreService, uiCloseModal } from '@vesto/xplat/core';
import {IAccount, IBankAccount, IUser} from '@vesto/vesto';
import {UntypedFormControl} from "@angular/forms";
import {filter, takeUntil} from "rxjs/operators";
import {FeatureService} from "../../../services";

@Directive()
export abstract class UserSearchModalBaseComponent extends BaseComponent implements OnInit{
  users: IUser[] = [];
  user: IUser;
  users$ = CoreService.userFacade.users$; //TODO: do it this way?
  formControl = new UntypedFormControl();
  accounts: IAccount[] = [];

  ngOnInit() {
    CoreService.userFacade.user$
      .pipe(
        filter(user => !!user),
        takeUntil(this.destroy$)
      )
      .subscribe((user) => {
        this.user = user;
      })
    this.formControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      if (typeof result === 'string') {
        this.search(result);
      }
    });
  }

  getOptionText(option) {
    return option ? `${option.firstName} ${option.lastName} ${option.email}` : null;
  }

  search(keyword: string) {
    if (!keyword) {
      return;
    }
    CoreService.userFacade.typeahead(keyword);
  }

  selectUser(user: IUser) {
    FeatureService.dashboardFacade.setIsAdminView(false);
    CoreService.userFacade.selectUser(user);
    this.close();
    FeatureService.dashboardFacade.refresh();
  }

  close() {
    uiCloseModal();
  }
}
