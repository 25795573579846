import {Injectable} from '@angular/core';
import {Action} from '@ngrx/store';
import {DashboardActions} from './dashboard.actions';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  AccountFacade,
  SystemActions,
  TransactionActions, TransactionFacade, UserFacade
} from '@vesto/ngx-vesto/state';
import {from, Observable} from 'rxjs';
import {mergeMap, withLatestFrom} from 'rxjs/operators';
import {AccountType, ITransaction} from '@vesto/vesto';
import {DashboardFacade, Section} from '@vesto/xplat/features';
import {CoreActions} from "@vesto/xplat/core";

@Injectable()
export class DashboardTransactionEffects {
  constructor(private actions$: Actions, private dashboardFacade: DashboardFacade, private accountFacade: AccountFacade, private userFacade: UserFacade, private transactionFacade: TransactionFacade) {
  }

  success$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(SystemActions.deployContractSuccess, TransactionActions.redeemSuccess, TransactionActions.wireToCircleSuccess, TransactionActions.setSettleLimitsSuccess, TransactionActions.changeRoleSuccess, TransactionActions.addYearnVaultSuccess, TransactionActions.transferGasSuccess, TransactionActions.syncImplementationAddressesSuccess, TransactionActions.syncAddressesSuccess, TransactionActions.swapVSigningAddressSuccess, TransactionActions.syncApys, TransactionActions.syncElementSuccess, TransactionActions.transferToAddressSuccess, TransactionActions.transferToEmailSuccess, TransactionActions.payByTokenSuccess, TransactionActions.sellSuccess, TransactionActions.buySuccess, TransactionActions.addUserSuccess, TransactionActions.removeUserSuccess, TransactionActions.mintSuccess, TransactionActions.signSuccess),
        withLatestFrom(this.dashboardFacade.selectedAccount$, this.accountFacade.accounts$, this.userFacade.user$),
        mergeMap(([action, selectedAccount, accounts, user]) => {
          let accountId: string;
          const transaction: ITransaction = action['transaction'];
          if (!!transaction) {
            if (transaction.user.id === user.id) {
              accountId = transaction.accountId;
            } else if (transaction?.recipientUser.id) {
              accountId = transaction.recipientAccountId;
            } else {
              accountId = selectedAccount.id;
            }
          } else {
            accountId = selectedAccount.id;
          }

          const account = accounts.find(_account => _account.id === accountId);
          let section: Section;
          if (account.type === AccountType.WALLET || account.type === AccountType.FIAT || account.type === AccountType.PAYOUT || account.type === AccountType.OPERATIONAL) {
            section = Section.PERSONAL_ACCOUNTS;
          } else if (account.type === AccountType.GAS_TANK) {
            section = Section.GAS_TANKS;
          } else {
            section = Section.SMART_CONTRACTS;
          }

          return from([
            DashboardActions.selectSection({section}),
            DashboardActions.selectAccount({account}),
            DashboardActions.selectSymbol({symbol: 'all'})
          ]);
        })
      )
  );

  set$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TransactionActions.set),
        withLatestFrom(this.transactionFacade.transactions$, this.dashboardFacade.selectedAccount$),
        mergeMap(([{transaction}, transactions, selectedAccount]) => {
          if (!!transaction.id && !transactions.find(_transaction => _transaction.id === transaction.id) && (selectedAccount?.id === transaction.accountId || selectedAccount?.id === transaction.recipientAccountId )) {
            return from([TransactionActions.add({transaction})]);
          } else {
            return from([CoreActions.noop()]);
          }
        })
      )
  );
}
