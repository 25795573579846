<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <h3 class="section-number"><i class="fal fa-sliders-v"></i> Set Supply Limit</h3>
</div>
<mat-dialog-content>
  <ng-container [ngSwitch]="step">
    <section *ngSwitchCase="'FORM_GROUP'">

      <form [formGroup]="formGroup" (ngSubmit)="update()">
        <div class="vesto-input vesto-select mt-4 mb-2">
          <ng-select formControlName="symbol" id="symbol" name="symbol" placeholder="Symbol">
            <ng-option *ngFor="let symbol of token" [value]="symbol">
              {{ symbol }}
            </ng-option>
          </ng-select>
        </div>

        <div class="vesto-input vesto-input-inline mb-3">
          <label for="amount">Amount</label>
          <gbl-input mask="currency" formControlName="amount" id="amount" name="amount"></gbl-input>
        </div>

        <gbl-alert [type]="error" [message]="error"></gbl-alert>

        <button class="button button-primary my-4 w-full text-center block" type="submit">Update</button>
      </form>
    </section>

    <section *ngSwitchCase="'TWO_FACTOR'">
      <div>
        <gbl-two-factor (sendSmsCode)="sendSmsCode($event)" (code)="confirmCode($event)"></gbl-two-factor>
      </div>
    </section>

    <section *ngSwitchCase="'BUSY'">
      <div>
        <div class="placeholder-long bg-flash"></div>
        <div class="placeholder-short bg-flash mt-4"></div>
        <div class="placeholder-area bg-flash mt-4"></div>
      </div>
    </section>

    <section *ngSwitchCase="'SUCCESS'">
      <gbl-lottie-player></gbl-lottie-player>
    </section>

    <section *ngSwitchCase="'TRANSACTION_DETAIL'">
      <div class="slide-in">
        <div class="text-center">
          <p>Success!</p>
          <p class="text-sm mb-4">You transaction has been submitted to the blockchain</p>
        </div>
        <div class="p-4 bg-trans-blue rounded-lg">
          <gbl-transaction [transaction]="transaction"></gbl-transaction>
        </div>
        <button class="button button-primary my-4 mx-auto w-full" (click)="close()">Close</button>
      </div>
    </section>
  </ng-container>
</mat-dialog-content>
