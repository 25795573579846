import { NgModule } from '@angular/core';
import { DashboardModule as SharedDashboardModule } from '@vesto/xplat/features';
import { DASHBOARD_COMPONENTS } from './components';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatCardModule } from '@angular/material/card';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CommonModule } from '@angular/common';
import { UIModule } from '../ui';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxCleaveDirectiveModule } from 'ngx-cleave-directive';
import { EffectsModule } from '@ngrx/effects';
import { WebDashboardEffects } from './state/web-dashboard.effects';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  imports: [SharedDashboardModule, CommonModule, UIModule, MatAutocompleteModule, MatExpansionModule, MatSnackBarModule, NgxChartsModule, MatCardModule, NgxCleaveDirectiveModule, MatSidenavModule, EffectsModule.forFeature([WebDashboardEffects])],
  declarations: [...DASHBOARD_COMPONENTS],
  exports: [...DASHBOARD_COMPONENTS]
})
export class DashboardModule {}
