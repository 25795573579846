import {Component, OnInit} from "@angular/core";
import {BaseComponent, CoreService, uiCloseModal} from "@vesto/xplat/core";
import {UntypedFormGroup, Validators} from "@angular/forms";
import {take, takeUntil} from "rxjs/operators";
import {ofType} from "@ngrx/effects";
import {InstitutionActions} from "@vesto/ngx-vesto/state";

@Component({
  selector: 'gbl-create-and-deploy-token',
  templateUrl: './create-and-deploy-token-modal.component.html'
})

export class CreateAndDeployTokenModalComponent extends BaseComponent implements OnInit {
  step : 'FORM_GROUP' | 'TWO_FACTOR' | 'BUSY' | 'SUCCESS' | 'DETAIL' = 'FORM_GROUP';
  formGroup: UntypedFormGroup;
  error: string;
  institutionId: string;

  ngOnInit() {
    this.formGroup = CoreService.formBuilder.group({
      name: [
        null,
        {
          validators: [Validators.required, Validators.nullValidator]
        }
      ],
      symbol: [
        null,
        {
          validators: [Validators.required, Validators.nullValidator]
        }
      ],
      underlyingSymbol: [
        null,
        {
          validators: [Validators.required, Validators.nullValidator]
        }
      ]
    })
  }

  sendSmsCode(isResendSms: boolean) {
    CoreService.institutionFacade.sendSmsCode();

    if (isResendSms) {
      CoreService.uiFacade.notification(`SMS code sent!`);
    }
  }

  createAndDeployToken() {
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }
    this.error = null;
    this.step = 'TWO_FACTOR';
  }

  confirmCode(code: string) {
    this.step = 'BUSY';
    const value = this.formGroup.value;

    CoreService.institutionFacade.createAndDeployToken(this.institutionId, value.name, value.symbol, value.underlyingSymbol, code);

    CoreService.actions$.pipe(ofType(InstitutionActions.createAndDeployTokensSuccess), take(1), takeUntil(this.destroy$)).subscribe(() => {
      this.step = 'SUCCESS';
      CoreService.windowService.setTimeout(() => {
        this.step = 'DETAIL';
      }, 1600);
    });

    CoreService.actions$.pipe(ofType(InstitutionActions.createAndDeployTokensFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
      this.step = 'FORM_GROUP';
    });
  }

  close() {
    uiCloseModal();
  }
}
