// TODO: Should this be export const enum UserStatus....the problem you cannot import to templates with const I think (UserStatus = UserStatus;)???
// https://twitter.com/mgechev/status/1222538039055941632
export enum UserStatus {
  INVITED = 'INVITED',
  MOBILE_NOT_VERIFIED = 'MOBILE_NOT_VERIFIED',
  EMAIL_NOT_VERIFIED = 'EMAIL_NOT_VERIFIED',
  PROVISIONING = 'PROVISIONING',
  NOT_PROVISIONED = 'NOT_PROVISIONED',
  VERIFYING_IDENTITY = 'VERIFYING_IDENTITY',
  IDENTITY_NOT_VERIFIED = 'IDENTITY_NOT_VERIFIED',
  KYC_CONSIDER = 'KYC_CONSIDER',
  SWAPPING_USER_ADDRESS = 'SWAPPING_USER_ADDRESS',
  SUSPENDED = 'SUSPENDED',
  ACTIVE = 'ACTIVE'
}
