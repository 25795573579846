import { Inject, Injectable, forwardRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { LogService } from './log.service';
import { UiState } from '../state/ui/ui.state';
import { PlatformLanguageToken } from './tokens';
import { Cache, StorageKeys, StorageService } from './storage.service';

@Injectable()
export class LocaleService extends Cache {
  // used by http interceptor
  // since api calls are made frequently avoid roundtrips to persitence
  private _inMemoryLocale: UiState.Locale;

  private _zoneCode: string;

  constructor(@Inject(forwardRef(() => Store)) private _store: Store<UiState.IState>, @Inject(forwardRef(() => PlatformLanguageToken)) private _languageToken: string, @Inject(forwardRef(() => LogService)) log: LogService, @Inject(forwardRef(() => StorageService)) storageService: StorageService) {
    super(storageService);
    this.isObjectCache = true;
    this.key = StorageKeys.LOCALE;

    if (this._languageToken) {
      this._languageToken = this._languageToken.split('-')[0];
    }
    if (!Object.keys(UiState.Locale).includes(this._languageToken)) {
      this._languageToken = 'en';
    }
  }

  public get locale(): UiState.Locale {
    this._inMemoryLocale = this.cache ? this.cache.locale : this._languageToken;
    return this._inMemoryLocale;
  }

  public set locale(locale: UiState.Locale) {
    // store in persistence
    this.cache = { locale };
    // always update in-memory locale for rapid access elsewhere
    this._inMemoryLocale = locale;
  }

  public get inMemoryLocale(): UiState.Locale {
    return this._inMemoryLocale;
  }
}
