import { createAction, props } from '@ngrx/store';
import { UiState } from './ui.state';
import { ICountryCode } from '../../interfaces';

export namespace UiActions {
  export const initLocale = createAction('[@vesto/ui] initLocale', props<{ locale: UiState.Locale }>());

  export const setLocale = createAction('[@vesto/ui] setLocale', props<{ locale: UiState.Locale }>());

  export const setLocaleSuccess = createAction('[@vesto/ui] setLocale');

  export const initCountryCodes = createAction('[@vesto/ui] initCountryCodes');

  export const initCountryCodesSuccess = createAction('[@vesto/ui] initCountryCodesSuccess', props<{ countryCodes: ICountryCode[] }>());

  export const initCountryCodesFailure = createAction('[@vesto/ui] initCountryCodesFailure', props<{ error: any }>());

  export const showSpinner = createAction('[@vesto/ui] showSpinner');

  export const hideSpinner = createAction('[@vesto/ui] hideSpinner');

  export const showBottomOverlay = createAction('[@vesto/ui] showBottomOverlay', props<{ options: any[]; inModal?: boolean; message?: string; showAction?: boolean, requiresSignature?: boolean }>());

  export const hideBottomOverlay = createAction('[@vesto/ui] hideBottomOverlay');

  export const selectOption = createAction('[@vesto/ui] selectOption', props<{ option: any }>());

  // TODO: Might want to create INotification (type, message, and etc).
  export const notification = createAction('[@vesto/ui] notification', props<{ message: string }>());

  export const go = createAction('[@vesto/ui] go', props<{ path: any[]; extras?: any }>());

  export const back = createAction('[@vesto/ui] back');

  export const forward = createAction('[@vesto/ui] forward');

  export const openCountrySelector = createAction('[@vesto/ui] openCountrySelector');

  export const selectCountryCode = createAction('[@vesto/ui] selectCountryCode', props<{ countryCode: ICountryCode }>());

  export const openModal = createAction('[@vesto/ui] openModal', props<UiState.IModalStateOptions>());

  export const openedModal = createAction('[@vesto/ui] openedModal', props<UiState.IModalState>());

  export const closeModal = createAction('[@vesto/ui] closeModal', (latestResult: any = null) => latestResult);

  export const closedModal = createAction('[@vesto/ui] closedModal', props<UiState.IModalState>());
}
