import {Directive, OnInit} from '@angular/core';
import { BaseComponent, CoreService } from '@vesto/xplat/core';
import {IBankAccount, IUser, UserStatus} from '@vesto/vesto';
import {filter, takeUntil} from 'rxjs/operators';
import {ofType} from "@ngrx/effects";
import {BankAccountActions} from "@vesto/ngx-vesto/state";
import {take} from "rxjs";

@Directive()
export abstract class PaymentMethodsBaseComponent extends BaseComponent implements OnInit {
  bankAccounts: IBankAccount[] = [];
  user: IUser;
  UserStatus = UserStatus;

  ngOnInit() {
    CoreService.bankAccountFacade.bankAccounts$
      .pipe(
        takeUntil(this.destroy$),
        filter((bankAccounts) => !!bankAccounts)
      )
      .subscribe((bankAccounts) => {
      CoreService.ngZone.run(() => {
        this.bankAccounts = bankAccounts;
      });
    });
    CoreService.userFacade.user$
      .pipe(
        takeUntil(this.destroy$),
        filter((user) => !!user)
      ).subscribe((user) => {
      this.user = user
      });
  }

  remove(bankAccount: IBankAccount) {
    CoreService.uiFacade.showSpinner();
    CoreService.actions$.pipe(ofType(BankAccountActions.removeSuccess), takeUntil(this.destroy$), take(1)).subscribe((result) => {
      CoreService.uiFacade.notification(`Successfully removed ${bankAccount.institution} ${bankAccount.name} ending in ${bankAccount.mask}`);
      CoreService.uiFacade.hideSpinner();
    });
    CoreService.actions$.pipe(ofType(BankAccountActions.removeFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      CoreService.uiFacade.notification(`apiErrors.${result.apiError.error}`);
      CoreService.uiFacade.hideSpinner();
    });

    CoreService.bankAccountFacade.remove(bankAccount.id);
  }

  setDefault(bankAccountId: string) {
    CoreService.bankAccountFacade.setDefault(bankAccountId);
  }
}
