import { Directive, OnInit } from '@angular/core';
import {BaseComponent, CoreService, UiActions} from '@vesto/xplat/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { IUser } from '@vesto/vesto';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { FormGroupUtil } from '@vesto/xplat/utils';
import { filter, takeUntil } from 'rxjs/operators';
import { FeatureService } from '../../services/feature.service';
import { ofType } from '@ngrx/effects';
import { SignUpActions } from '@vesto/ngx-vesto/state';

@Directive()
export abstract class VerifyMobileBaseComponent extends BaseComponent implements OnInit {
  step: 'FORM_GROUP' | 'TWO_FACTOR' = 'FORM_GROUP';
  user: IUser;
  formGroup: UntypedFormGroup;
  codeFormGroup: UntypedFormGroup; // TODO: JEFF ADD TWO FACTOR COMPONENT!!!!
  activeTab = 'app';
  CountryISO;
  SearchCountryField;
  smsCodeSent = false;
  show2faAnswer = false;
  error: string;

  ngOnInit() {
    CoreService.progress.toggleSpinner(false);
    this.CountryISO = CountryISO;
    this.SearchCountryField = SearchCountryField;
    this.formGroup = CoreService.formBuilder.group({
      mobile: [
        null,
        {
          validators: [Validators.required]
        }
      ]
    });

    this.codeFormGroup = CoreService.formBuilder.group({
      code: [
        null,
        {
          validators: [Validators.required, Validators.minLength(6), Validators.maxLength(6)]
        }
      ]
    });

    FeatureService.signUpFacade.user$
      .pipe(
        filter((user) => !!user),
        takeUntil(this.destroy$)
      )
      .subscribe((user) => {
        this.user = user;
      });

    CoreService.actions$.pipe(ofType(SignUpActions.sendSmsCodeSuccess, SignUpActions.verifySmsCodeSuccess), takeUntil(this.destroy$)).subscribe((result) => {
      CoreService.progress.toggleSpinner(false);
    });

    CoreService.actions$.pipe(ofType(SignUpActions.sendSmsCodeFailure, SignUpActions.verifySmsCodeFailure), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
      CoreService.progress.toggleSpinner(false);
    });
  }

  validateAndSendSmsCode() {
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }

    CoreService.progress.toggleSpinner(true);
    const value = {
      ...FormGroupUtil.trim(this.formGroup.value)
    };
    this.smsCodeSent = true;
    FeatureService.signUpFacade.sendSmsCode(value.mobile);
  }

  validateAndVerifyCode() {
    const { ok, error$ } = CoreService.formService.validate(this.codeFormGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }

    CoreService.progress.toggleSpinner(true);
    const value = {
      ...FormGroupUtil.trim(this.codeFormGroup.value)
    };
    FeatureService.signUpFacade.verifySmsCode(value.code);

    CoreService.actions$.pipe(ofType(SignUpActions.verifySmsCodeSuccess), takeUntil(this.destroy$)).subscribe((result) => {
      FeatureService.signUpFacade.sendEmail();
    });
  }
}
