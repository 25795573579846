export enum TransactionType {
  DEPLOY_VLIGHTHOUSE = 'DEPLOY_VLIGHTHOUSE',
  DEPLOY_VUSER = 'DEPLOY_VUSER',
  DEPLOY_VDEFI = 'DEPLOY_VDEFI',
  DEPLOY_VBRIDGE = 'DEPLOY_VBRIDGE',
  DEPLOY_VSWAP = 'DEPLOY_VSWAP',
  DEPLOY_VERC20 = 'DEPLOY_VERC20',
  DEPLOY_VWALLET = 'DEPLOY_VWALLET',
  DEPLOY_YEARN_VAULT = 'DEPLOY_YEARN_VAULT',
  DEPLOY_VLIGHTHOUSE_PROXY = 'DEPLOY_VLIGHTHOUSE_PROXY',
  DEPLOY_VERC20_PROXY = 'DEPLOY_VERC20_PROXY',
  DEPLOY_VWALLET_PROXY = 'DEPLOY_VWALLET_PROXY',
  INITIALIZE = 'INITIALIZE',
  ADD_USER = 'ADD_USER',
  CHANGE_ROLE = 'CHANGE_ROLE',
  BUY = 'BUY',
  SELL = 'SELL',
  TRANSFER = 'TRANSFER',
  FEE = 'FEE',
  TIP = 'TIP',
  MINT = 'MINT',
  SYNC = 'SYNC',
  SYNC_IMPLEMENTATION_ADDRESSES = 'SYNC_IMPLEMENTATION_ADDRESSES',
  SYNC_ADDRESSES = 'SYNC_ADDRESSES',
  INTEREST_PAYMENT = 'INTEREST_PAYMENT',
  ADD_ADDITIONAL_USER = 'ADD_ADDITIONAL_USER',
  REMOVE_ADDITIONAL_USER = 'REMOVE_ADDITIONAL_USER',
  SWAP_SIGNING_ADDRESSES = 'SWAP_SIGNING_ADDRESSES',
  SWAP_VSIGNING_ADDRESS = 'SWAP_VSIGNING_ADDRESS',
  ADD_YEARN_VAULT = 'ADD_YEARN_VAULT',
  PAYOUT = 'PAYOUT',
  SYNC_APYS = 'SYNC_APYS',
  SYNC_PRICE_PER_TOKENS = 'SYNC_PRICE_PER_TOKENS',
  SETTLE = 'SETTLE',
  SET_SETTLE_LIMITS = 'SET_SETTLE_LIMITS',
  SET_DAILY_LIMITS = 'SET_DAILY_LIMITS',
  WIRE = 'WIRE',
  PAYMENT = 'PAYMENT',
  SET_UNDERLYING_SUPPLY_LIMIT = 'SET_UNDERLYING_SUPPLY_LIMIT'
}
