import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {IEnvironment, AccountsApi, GasTankApi} from '@vesto/vesto';
import { EnvironmentToken } from './tokens';

@Injectable({
  providedIn: 'root'
})
export class GasTanksApiService extends GasTankApi {
  constructor(@Inject(EnvironmentToken) protected environment: IEnvironment, protected httpClient: HttpClient) {
    super(environment, httpClient);
  }
}
