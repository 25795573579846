import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { WindowService } from '@vesto/xplat/core';
// import { localizeRouteMap } from '../../features/app.routes-localized';

import { LinkService } from './link.service';
import { Store, select } from '@ngrx/store';
import { IContentPage } from '@vesto/xplat/core';

export interface MetaData {
  title?: string;
  description?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
  ogUrl: string;
  ogLocale?: string;
  publishedTime?: string;
  ogVideo?: string;
  ogVideoType?: string;
  ogVideoWidth?: string;
  ogVideoHeight?: string;
}

@Injectable({
  providedIn: 'root'
})
export class MetaTagsService {
  constructor(private _store: Store<any>, private _meta: Meta, private _title: Title, private _linkService: LinkService, private _translateService: TranslateService, private _win: WindowService) {}

  updateSeoTags(page: IContentPage) {
    // Update title
    this._title.setTitle((page && page.seoTitle) || '');
    this._insertMetaPrefix('title', (page && page.seoTitle) || '');
    // Update description
    this._insertMeta('description', (page && page.seoDescription) || '');
    // Update locale
    this._insertMetaPrefix('locale', this._getOgLocale());
  }

  /**
   * Safe way to set meta tags with preventing translate service race issue
   * @param pageMeta hashTable with meta tags that should be setted
   */
  setMetaData(pageMeta: MetaData) {
    const metaMap: Map<string, string> = new Map();
    Object.keys(pageMeta).forEach((_metaKey) => {
      if (pageMeta[_metaKey]) {
        metaMap.set(_metaKey, pageMeta[_metaKey]);
      }
    });
    // must be get and not instant since called so early and translations might not be available yet
    this._translateService
      .get(Array.from(metaMap.values()))
      .pipe(take(1))
      .subscribe((_res) => {
        /**
         * Title
         */
        if (pageMeta.title) {
          this._title.setTitle(_res[pageMeta.title]);
          this._insertMetaPrefix('title', _res[pageMeta.title]);
        }
        /**
         * Description
         */
        if (pageMeta.description) {
          this._insertMeta('description', _res[pageMeta.description]);
        }
        /**
         * Og title
         */
        if (pageMeta.ogTitle) {
          this._insertMetaPrefix('title', _res[pageMeta.ogTitle]);
        }
        /**
         * Og description
         */
        if (pageMeta.ogDescription) {
          this._insertMetaPrefix('description', _res[pageMeta.ogDescription]);
        }
        /**
         * Og image and image secure url
         */
        if (pageMeta.ogImage) {
          this._insertMetaPrefix('image', _res[pageMeta.ogImage]);
          this._insertMetaPrefix('image:secure_url', _res[pageMeta.ogImage]);
          if (pageMeta.ogTitle) {
            this._insertMetaPrefix('image:alt', _res[pageMeta.ogTitle]);
          } else {
            this._insertMetaPrefix('image:alt', _res[pageMeta.ogImage]);
          }
        }
        /**
         * Og url
         */
        if (pageMeta.ogUrl) {
          this._insertMetaPrefix('url', _res[pageMeta.ogUrl]);
        } else {
          // default to full url
          this._insertMetaPrefix('url', this._win.location.href);
        }
        /**
         * Og locale
         */
        if (pageMeta.ogLocale) {
          this._insertMetaPrefix('locale', this._getOgLocale(pageMeta.ogLocale));
        } else {
          // default based off user set locale
          this._insertMetaPrefix('locale', this._getOgLocale());
        }
        /**
         * Published time
         */
        if (pageMeta.publishedTime) {
          const tag: MetaDefinition = {
            property: 'article:published_time',
            content: pageMeta.publishedTime,
            name: 'article:published_time'
          };
          this._meta.updateTag(tag);
        }
        /**
         * Og video and secure url
         */
        if (pageMeta.ogVideo) {
          this._insertMetaPrefix('video', _res[pageMeta.ogImage]);
          this._insertMetaPrefix('video:secure_url', _res[pageMeta.ogImage]);
        }
        /**
         * Og video type
         */
        if (pageMeta.ogVideoType) {
          this._insertMetaPrefix('video:type', _res[pageMeta.ogVideoType]);
        }
        /**
         * Og video width
         */
        if (pageMeta.ogVideoWidth) {
          this._insertMetaPrefix('video:width', _res[pageMeta.ogVideoWidth]);
        }
        /**
         * Og video height
         */
        if (pageMeta.ogVideoHeight) {
          this._insertMetaPrefix('video:height', _res[pageMeta.ogVideoHeight]);
        }
      });
  }

  setAlternate(url: string, locale: string, languages: string[]) {
    const origin = this._win.location.origin;

    this._linkService.removeByAttribute('rel', 'alternate');

    const routeKey = url.match(/\/([\w-]+)/);
    let localePath;
    let urlParts;
    urlParts = url.split('/');
    // if (routeKey) {
    //   localePath = this._findLocalePath(routeKey[1]);
    // }
    // const langInUrl = urlParts[1];
    // if (langInUrl === localePath) {
    //   // it's not the english version
    //   localePath = this._findLocalePath(urlParts[2]);
    // }

    // const localPathDefaultValue = localizeRouteMap[locale][localePath] || '';
    const localPathDefaultValue = '';

    this._linkService.addTag({
      rel: 'alternate',
      hreflang: 'x-default',
      href: locale === 'en' ? origin + (localPathDefaultValue ? `/${localPathDefaultValue}` : '') : `${origin}/${locale}` + (localPathDefaultValue ? `/${localPathDefaultValue}` : '')
    });

    languages.forEach((lang) => {
      // const localPathValue = localizeRouteMap[lang][localePath] || '';
      const localPathValue = '';
      this._linkService.addTag({
        rel: 'alternate',
        hreflang: lang,
        href: lang === 'en' ? origin + (localPathValue ? `/${localPathValue}` : '') : `${origin}/${lang}` + (localPathValue ? `/${localPathValue}` : '')
      });
    });
  }

  setCanonical(url: string, languages: string[]) {
    languages.forEach((lang) => {
      let canonicalUrl;
      if (lang === 'en') {
        canonicalUrl = url.replace(/\/\w+\//, `/`);
      } else {
        // PNP-18991
        canonicalUrl = `${url}`;
      }
      this._linkService.addTag({
        rel: 'canonical',
        href: this._win.location.origin + canonicalUrl
      });
    });
  }

  noIndexPage(content = 'noindex,follow') {
    const noIndexMeta: MetaDefinition = {
      name: 'robots',
      content: content
    };
    this._meta.updateTag(noIndexMeta);
  }

  updateTag(tag: MetaDefinition) {
    this._meta.updateTag(tag);
  }

  removeTag(name: string) {
    this._meta.removeTag(`name="${name}"`);
    this._meta.removeTag(`property="${name}"`);
  }

  private _getOgLocale(locale?: string) {
    if (!locale) {
      // use user set locale
      // this._store.pipe(select(UIState.selectLocale), take(1)).subscribe(_lang => {
      //   locale = _lang;
      // });
      locale = 'en';
    }
    // http://ogp.me/
    // og:locale - The locale these tags are marked up in. Of the format language_TERRITORY. Default is en_US.
    // https://developers.facebook.com/docs/internationalization/#locales
    switch (locale) {
      case 'es':
        return 'es_LA';
      case 'fr':
        return 'fr_FR';
      case 'it':
        return 'it_IT';
      default:
        return 'en_US';
    }
  }

  private _insertMeta(property: string, content: string) {
    const tag: MetaDefinition = {
      property,
      content,
      name: property
    };
    this._meta.updateTag(tag);
    this._insertMetaPrefix(property, content);
    if (property === 'description') {
      // when updating description, always update twitter description to be in sync with all other meta
      this._meta.updateTag({
        name: 'twitter:description',
        content
      });
    }
  }

  // private _findLocalePath(path: string) {
  //   let res: string = path;
  //   for (const i in localizeRouteMap) {
  //     if (localizeRouteMap.hasOwnProperty(i)) {
  //       for (const j in localizeRouteMap[i]) {
  //         if (localizeRouteMap[i].hasOwnProperty(j)) {
  //           if (localizeRouteMap[i][j] === path) {
  //             res = j;
  //           }
  //         }
  //       }
  //     }
  //   }
  //   return res;
  // }

  /**
   * @param property name of the meta property
   * @param content content. Ex: title, description
   */
  private _insertMetaPrefix(property: string, content: string, removePrev: boolean = true) {
    // Twitter will piggyback off og and og hates 'name'; only property="og:*"
    // https://developer.twitter.com/en/docs/tweets/optimize-with-cards/guides/getting-started.html
    const prefixedProperty = `og:${property}`;
    if (removePrev) {
      this.removeTag(prefixedProperty);
    }
    const tag: MetaDefinition = {
      property: prefixedProperty,
      content
    };
    this._meta.addTag(tag);
  }
}
