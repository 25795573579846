import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { LogService } from './log.service';

export interface IAnalyticsProperties {
  category?: string;
  label?: string;
  action?: string;
  // https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference#hitType
  hitType?: 'pageview' | 'screenview' | 'event' | 'transaction' | 'item' | 'social' | 'exception' | 'timing';
  value?: number;
  page?: string;

  [key: string]: any;
}

@Injectable()
export class AnalyticsService {
  constructor(protected _log: LogService) {}

  track(action: string, properties?: IAnalyticsProperties) {
    if (environment.enableAnalytics) {
      if (LogService.DEBUG_ANALYTICS) {
        this._log.debug('track:', action, properties);
      }
      // TODO: implement for web
    }
  }

  /**
   * Identify authenticated users
   **/
  identify(userHash: string, properties?: any) {
    if (environment.enableAnalytics) {
      if (LogService.DEBUG_ANALYTICS) {
        this._log.debug('identify:', properties);
      }
      // TODO: implement for web
    }
  }

  /**
   * set screen names
   **/
  setScreenName(screenName: string) {
    if (environment.enableAnalytics) {
      if (LogService.DEBUG_ANALYTICS) {
        this._log.debug('setScreenName:', screenName);
      }
      // TODO: implement for web
    }
  }
}
