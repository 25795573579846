import {Component, OnInit} from "@angular/core";
import {BaseComponent, CoreService} from "@vesto/xplat/core";
import {IInstitution, InstitutionFeeType} from "@vesto/vesto";
import {filter, takeUntil} from "rxjs/operators";
import {FeatureService} from "@vesto/xplat/features";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'gbl-institutions',
  templateUrl: './institutions.component.html',
  animations: [
    trigger('expandCollapseDetails', [
      state(
        'void',
        style({
          height: '0px',
          overflow: 'hidden'
        })
      ),
      //element being added into DOM.
      transition(':enter', [
        animate(
          '500ms ease-in-out',
          style({
            height: '*',
            overflow: 'hidden'
          })
        )
      ]),
      //element being removed from DOM.
      transition(':leave', [
        animate(
          '500ms ease-in-out',
          style({
            height: '0px',
            overflow: 'hidden'
          })
        )
      ])
    ])
  ]
})

export class InstitutionsComponent extends BaseComponent implements OnInit {
  institutions: IInstitution[] = []
  selectedInstitution: string;
  InstitutionFeeType = InstitutionFeeType;

  ngOnInit() {
    CoreService.institutionFacade.institutions$
      .pipe(
        filter(institutions => !!institutions),
        takeUntil(this.destroy$)
      )
      .subscribe((institutions) => {
        this.institutions = institutions
      })
  }

  createInstitution() {
    FeatureService.dashboardFacade.openOrGoToInstitution('create');
  }

  selectInstitution(id: string) {
    this.selectedInstitution = id;
  }

  openOrGoToInstitution(institution: IInstitution) {
    FeatureService.dashboardFacade.openOrGoToInstitution(null, institution);
  }

  openOrGoToCreateAndDeployTokens(institutionId: string) {
    FeatureService.dashboardFacade.openOrGoToCreateAndDeployTokens(institutionId);
  }
}
