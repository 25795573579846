import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SignInActions } from './sign-in.actions';

@Injectable({
  providedIn: 'root'
})
export class SignInFacade {
  constructor(private store: Store<any>) {}

  openOrGoToSignIn() {
    this.store.dispatch(SignInActions.openOrGoToSignIn());
  }

  openOrGoToSetPassword(token: string) {
    this.store.dispatch(SignInActions.openOrGoToSetPassword({ token }));
  }
}
