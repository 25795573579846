import { SystemStatus } from '../enums/system-status.enum';
import {SystemAlert} from '../enums/system-alert.enum';

export interface ISystem {
  id: string;
  status: SystemStatus;
  defaultDailyBuyLimit: number;
  defaultDailySellLimit: number;
  defaultDailyTransferLimit: number;
  defaultDailyReceiveLimit: number;
  apy: number;
  error: string;
  message: string;
  updated: string;
  created: string;
  // Transients.
  alerts: SystemAlert[];
  circleBalance: number;
  fboBalance: number;
  fboAvailableBalance: number;
  ceFiTransferAmount: number;
}
