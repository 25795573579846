import {Component, Input, OnInit} from "@angular/core";
import {BaseComponent, CoreService} from "@vesto/xplat/core";
import {Blockchain, ContractStatus, ContractType, IContract, IUser, RoleType, UserUtil} from "@vesto/vesto";
import {filter, takeUntil} from "rxjs/operators";
import {FeatureService} from "@vesto/xplat/features";

@Component({
  selector: 'gbl-smart-contract',
  templateUrl: 'smart-contract.component.html'
})

export class SmartContractComponent extends BaseComponent implements OnInit {
  @Input() contract: IContract;
  ContractStatus = ContractStatus;
  Blockchain = Blockchain;
  RoleType = RoleType;
  UserUtil = UserUtil;
  user: IUser;

  ngOnInit() {
    CoreService.userFacade.user$
      .pipe(
        filter( user => !!user),
        takeUntil(this.destroy$)
      ).subscribe((user) => {
      this.user = user;
    });
  }

  isProxy(contract: IContract) {
    return contract.type.endsWith('PROXY');
  }

  onEvent(event) {
    event.stopPropagation();
  }

  // TODO: PIPE!!!
  getType(contract: IContract) {
    switch (contract.type) {
      case ContractType.VLIGHTHOUSE:
      case ContractType.VLIGHTHOUSE_PROXY:
        return 'VLighthouse';
      case ContractType.VUSER:
      case ContractType.VUSER_PROXY:
        return 'VUser';
      case ContractType.VBRIDGE:
      case ContractType.VBRIDGE_PROXY:
        return 'VBridge';
      case ContractType.VDEFI:
      case ContractType.VDEFI_PROXY:
        return 'VDeFi';
      case ContractType.VSWAP:
      case ContractType.VSWAP_PROXY:
        return 'VSwap';
      case ContractType.VERC20:
      case ContractType.VERC20_PROXY:
        return 'VERC20';
      case ContractType.VWALLET:
      case ContractType.VWALLET_PROXY:
        return 'VWallet';
      case ContractType.YEARN_VAULT:
        return 'Yearn Vault';
      default:
        return 'undefined';
    }
  }

  deployContract(
    contract: IContract,
  ) {
    FeatureService.dashboardFacade.openOrGoToTwoFactor('deploy contract', null, null, null, contract);
  }
}
