import {inject, Injectable} from '@angular/core';
import {Action} from '@ngrx/store';
import {DashboardActions} from './dashboard.actions';
import {Actions, createEffect, ofType, OnInitEffects} from '@ngrx/effects';
import {SignUpActions, UserActions, UserFacade} from '@vesto/ngx-vesto/state';
import {CoreActions, CoreService, StompService, StorageKeys, StorageService, UiActions} from '@vesto/xplat/core';
import {from, Observable} from 'rxjs';
import {map, mergeMap, withLatestFrom} from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class DashboardUserEffects implements OnInitEffects {
  constructor(private actions$: Actions) {
  }

  userFacade = inject(UserFacade);
  stompService = inject(StompService);
  storageService = inject(StorageService);

  initialize$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.initialize),
        withLatestFrom(this.userFacade.user$, this.userFacade.authenticated$),
        map(([{}, user, authenticated]) => {
          if (!!user && !!user.id && authenticated) {
            this.stompService.connect(this.storageService.getItem(StorageKeys.KEY));
          }
        })
      ),
    {dispatch: false}
  );

  signInUpSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.signInSuccess, SignUpActions.signUpSuccess),
        map(({password}) => {
          CoreService.storageService.setItem(StorageKeys.PASSWORD, password);
        })
      ),
    {dispatch: false}
  );

  verifyTwoFactorCodeSuccess$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UserActions.verifyTwoFactorCodeSuccess, SignUpActions.verifyEmailSuccess),
        mergeMap(({privateKey}) => {
          const password = CoreService.storageService.getItem(StorageKeys.PASSWORD);
          if (password) {
            const key = CryptoJS.AES.decrypt(privateKey, password.trim()).toString();
            CoreService.storageService.setItem(StorageKeys.KEY, key);
            CoreService.store.dispatch(UserActions.authenticate());
            CoreService.storageService.removeItem(StorageKeys.PASSWORD);
            CoreService.stompService.connect(key);
            return from([UserActions.authenticate(), DashboardActions.refresh()]);
          }
          return from([CoreActions.noop()]);
        })
      )
  );

  signOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.signOut),
        map(({}) => {
          CoreService.storageService.removeItem(StorageKeys.PASSWORD);
          CoreService.storageService.removeItem(StorageKeys.KEY);
          CoreService.storageService.removeItem(StorageKeys.STATE);
        })
      ),
    {dispatch: false}
  );

  createOnfidoSdkTokenSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.createOnfidoSdkTokenSuccess),
      map(({token}) => {
        return DashboardActions.openOnfido({token});
      })
    )
  );

  createOnfidoSdkTokenFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.createOnfidoSdkTokenFailure),
        mergeMap(({apiError}) => {
          return from([UiActions.hideSpinner(), UiActions.notification({message: apiError.error})]);
        })
      ),
    {dispatch: false}
  );

  verifyIdentitySuccess$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UserActions.verifyIdentitySuccess),
        map(({}) => {
          return UserActions.find();
        })
      )
  );

  verifyChangeEmailSuccess$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UserActions.verifyChangeEmailSuccess),
        mergeMap(({}) => {
          return from([UiActions.notification({message: 'Your email has been successfully changed.'})]);
        })
      )
  );

  ngrxOnInitEffects(): Action {
    return UserActions.initialize();
  }
}
