<div *ngIf="menuOpen" (click)="closeMenu()" style="position:absolute;top:0;right:0;bottom:0;left:0"></div>
<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <h3 class="section-number"><i class="fal fa-user-friends"></i> Additional Users</h3>
  <p *ngIf="step === 'TWO_FACTOR'" class="mb-2 text-sm">Remove user {{ additionalUser.user.firstName }} {{ additionalUser.user.lastName }} from account {{ selectedAccount.name }}</p>
</div>
<mat-dialog-content>
  <ng-container [ngSwitch]="step">
    <form *ngSwitchCase="'USERS'" [formGroup]="formGroup">
      <div>
        <div class="vesto-input vesto-select mb-4">
          <ng-select id="accountId" name="accountId" placeholder="choose an account" formControlName="accountId">
            <ng-option *ngFor="let account of accounts" [value]="account.id">
              <div class="flex">
                <div class="flex-grow">{{ account.name }} &nbsp;</div>
                ${{ account.balance.toFixed(2) }}
              </div>
            </ng-option>
          </ng-select>
        </div>

        <ng-container *ngIf="selectedAccount.additionalUsers.length > 0 else noAdiitionalUsersTemplate">
          <div *ngFor="let additionalUser of selectedAccount.additionalUsers let i = index">
            <div class="flex mt-2 pt-2 items-center" [class.border-top]="i > 0">
              <div class="text-sm flex-grow">
                {{ additionalUser.user.firstName}} {{ additionalUser.user.lastName}}
                <span class="block text-xs">{{ additionalUser.user.email}} - Added: {{ additionalUser.user.created | date }}</span>
              </div>
              <span class="text-sm mr-3">{{ additionalUser.status | titlecase }}</span>
              <i class="fal text-2xl fa-ellipsis-h" [matMenuTriggerFor]="menu" (menuOpened)="menuOpen = true" (menuClosed)="menuOpen = false"></i>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="suspendOrReactivateUser(additionalUser)" *ngIf="additionalUser.status !== AdditionalUserStatus.PENDING">
                  {{ additionalUser.status === AdditionalUserStatus.ACTIVE ? 'Suspend' : 'Activate' }}
                </button>
                <button mat-menu-item (click)="removeUser(additionalUser)">Remove</button>
              </mat-menu>
            </div>
          </div>
        </ng-container>

        <ng-template #noAdiitionalUsersTemplate>
          <div class="p-4 bg-trans-blue rounded-lg text-sm text-center">
            <p>No additional users for this account.</p>
            <button class="button button-primary mt-4 mx-auto w-full" (click)="openAddUser()">Add User</button>
          </div>
        </ng-template>

        <gbl-alert [type]="error" [message]="error"></gbl-alert>
      </div>
    </form>

    <section *ngSwitchCase="'TWO_FACTOR'">
      <gbl-two-factor (sendSmsCode)="sendSmsCode($event)" (code)="confirmCode($event)"></gbl-two-factor>
      <gbl-alert [type]="error" [message]="error"></gbl-alert>
    </section>

    <section *ngSwitchCase="'BUSY'" class="mt-4">
      <div class="placeholder-long bg-flash"></div>
      <div class="placeholder-area bg-flash mt-4"></div>
    </section>

    <section *ngSwitchCase="'SUCCESS'">
      <gbl-lottie-player></gbl-lottie-player>
    </section>

    <section *ngSwitchCase="'DETAIL'">
      <div class="slide-in">
        <div class="text-center">
          <p>Success!</p>
        </div>
        <div class="p-4 bg-trans-blue rounded-lg text-sm text-center">
          You removed {{ additionalUser.user.firstName }} {{ additionalUser.user.lastName }} from your account {{ selectedAccount.name }}.
          <p class="mt-2 text-xs">It may take a minute to reflect these changes.</p>
        </div>
        <button class="button button-primary my-4 mx-auto w-full" (click)="back()">Back</button>
      </div>
    </section>
  </ng-container>
</mat-dialog-content>
