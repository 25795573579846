import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@vesto/xplat/core';
import {filter, takeUntil} from 'rxjs/operators';
import {FeatureService, Section} from '@vesto/xplat/features';

@Component({
  selector: 'gbl-settings',
  templateUrl: 'settings.component.html'
})
export class SettingsComponent extends BaseComponent implements OnInit {
  selectedSection: Section;
  Section = Section;

  ngOnInit() {
    FeatureService.dashboardFacade.selectedSection$.
    pipe(
      filter(selectedSection => !!selectedSection),
      takeUntil(this.destroy$)
    ).subscribe((selectedSection) => {
      this.selectedSection = selectedSection;
    });
  }
}
