<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="flex items-center">
    <h3 class="section-number mr-2"><i class="fal fa-gas-pump"></i></h3>
    <div>
      <h3 class="section-number">Change Gas Tank Settings</h3>
      <span class="text-sm">({{ blockchain | titlecase }} {{ gasTank.type | titlecase }})</span>
    </div>
  </div>
</div>
<mat-dialog-content class="gas-tank-modal">
  <ng-container [ngSwitch]="step">
    <form [formGroup]="formGroup" (ngSubmit)="changeGasLimits()" *ngSwitchCase="'FORM_GROUP'">
      <div class="vesto-input vesto-input-inline mb-3">
        <label for="minPrice">Min Price</label>
        <gbl-input mask="wholeNumber" focusMe="true" formControlName="minPrice" id="minPrice" name="minPrice"></gbl-input>
      </div>
      <div class="vesto-input vesto-input-inline mb-3">
        <label for="maxPrice">Max Price</label>
        <gbl-input mask="wholeNumber" formControlName="maxPrice" id="maxPrice" name="maxPrice"></gbl-input>
      </div>
      <div class="vesto-input vesto-input-inline mb-3">
        <label for="limit">Limit</label>
        <gbl-input mask="wholeNumber" formControlName="limit" id="limit" name="limit"></gbl-input>
      </div>

      <gbl-alert [type]="error" [message]="error"></gbl-alert>

      <div class="text-right mt-3">
        <button class="button button-primary" type="submit">Change</button>
      </div>
    </form>

    <section *ngSwitchCase="'SUCCESS'">
      <gbl-lottie-player></gbl-lottie-player>
    </section>

    <section *ngSwitchCase="'BUSY'" class="mt-4">
      <div class="placeholder-long bg-flash"></div>
      <div class="placeholder-area bg-flash mt-4"></div>
    </section>

    <section *ngSwitchCase="'DETAIL'" class="slide-in">
      <div class="text-center">
        <p class="mt-4">Success!</p>
      </div>
      <div class="p-3 bg-trans-blue rounded-lg text-center">
        <p class="text-sm">Gas tank has be changed</p>
      </div>
      <button class="button button-primary my-4 mx-auto w-full" (click)="close()">Close</button>
    </section>
  </ng-container>
</mat-dialog-content>
