import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Blockchain } from '@vesto/vesto';
import { UiActions } from '@vesto/xplat/core';

@Component({
  selector: 'gbl-change-currency-apy-modal',
  templateUrl: './change-currency-modal.component.html'
})
export class ChangeCurrencyModalComponent implements OnInit {
  formGroup: UntypedFormGroup;
  Blockchain = Blockchain;
  confirmCode = false;
  token: any;
  error: string;

  constructor(private store: Store, private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      apy: [
        this.token.apy * 100.0,
        {
          validators: [Validators.required, Validators.nullValidator]
        }
      ],
      redeemAddress: [
        this.token.redeemAddress,
        {
          validators: [Validators.required, Validators.nullValidator, Validators.maxLength(42), Validators.minLength(42)]
        }
      ]
    });
  }

  changeToken() {
    // if (!this.validateFormGroup(this.formGroup)) {
    //   return;
    // }

    this.confirmCode = true;
    // const value = {
    //   ...this.formGroup.value
    // }
    // Constants.systemFacade.changeCurrency(this.data.token.id, parseFloat(value.apy) / 100, value.redeemAddress);
  }

  close() {
    this.store.dispatch(UiActions.closeModal());
  }
}
