import { Directive, OnInit } from '@angular/core';
import { BaseComponent, CoreService, StorageKeys, UiActions, WindowService } from '@vesto/xplat/core';
import { filter, takeUntil } from 'rxjs/operators';
import { IUser, UserStatus } from '@vesto/vesto';
import { FeatureService } from '../../services/feature.service';
import { ofType } from '@ngrx/effects';
import { SignUpActions, UserActions, VestoState } from '@vesto/ngx-vesto/state';

@Directive()
export abstract class SignUpBaseComponent extends BaseComponent implements OnInit {
  user: IUser = null;
  UserStatus = UserStatus;
  error: string;
  step: 'CREATE_USER' | 'VERIFY_MOBILE' | 'VERIFY_EMAIL' | 'LOADING' = 'CREATE_USER';

  ngOnInit() {
    FeatureService.signUpFacade.user$
      .pipe(
        filter((user) => !!user),
        takeUntil(this.destroy$)
      )
      .subscribe((user) => {
        this.user = user;

        switch (user?.status) {
          case UserStatus.INVITED:
            this.step = 'CREATE_USER';
            break;
          case UserStatus.MOBILE_NOT_VERIFIED:
            this.step = 'VERIFY_MOBILE';
            break;
          case UserStatus.EMAIL_NOT_VERIFIED:
            this.step = 'VERIFY_EMAIL';
            break;
        }
      });

    CoreService.actions$.pipe(ofType(SignUpActions.verifyEmailSuccess), takeUntil(this.destroy$)).subscribe((result) => {
      CoreService.store.dispatch(
        UiActions.go({
          path: ['/dashboard'],
          extras: {
            animated: false,
            clearHistory: true
          }
        })
      );
    });

    CoreService.actions$.pipe(ofType(SignUpActions.sendEmailFailure), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
    });

    CoreService.actions$.pipe(ofType(SignUpActions.verifyEmailFailure), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
      this.step = 'VERIFY_EMAIL';
    });
  }

  cancel() {
    this.resetState();
    if (CoreService.windowService.isBrowser) {
      // ensure browser history completely cleared
      CoreService.windowService.setTimeout(() => {
        CoreService.windowService.location.href = `${CoreService.windowService.location.protocol}//${CoreService.windowService.location.host}`;
      }, 100);
    } else {
      CoreService.uiFacade.go(['/home'], { clearHistory: true, replaceUrl: true });
    }
  }

  resetState() {
    CoreService.storageService.removeItem(StorageKeys.KEY);
    CoreService.storageService.removeItem(StorageKeys.PASSWORD);
    CoreService.store.dispatch(
      UserActions.reset({
        resetState: {
          initialized: true, // make sure initialized is not reset!
          ...VestoState.initialState
        }
      })
    );
  }
}
