<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <h3 class="section-number"><i class="fal fa-file-invoice"></i> Request Report</h3>
</div>
<mat-dialog-content>
  <p class="text-sm">Please enter your information in order to receive your copy of {{ title }}.</p>
  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <div class="vesto-input mt-3">
      <label for="email">Name</label>
      <input type="text" focusMe="true" formControlName="name" id="name" name="name" />
    </div>

    <div class="vesto-input mt-3">
      <label for="email">Email</label>
      <input type="email" formControlName="email" id="email" name="email" />
    </div>

    <div class="vesto-input mt-3">
      <label for="email">Organization (optional)</label>
      <input type="text" formControlName="organization" id="organization" name="organization" />
    </div>

    <gbl-alert [type]="error" [message]="error"></gbl-alert>

    <button class="button button-primary w-full mt-5" type="submit">Submit</button>
  </form>
</mat-dialog-content>
