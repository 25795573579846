<section *ngIf="adminUser && user?.id !== adminUser?.id" class="bg-lilac-light">
  <div class="text-center p-4 flex items-center justify-center">
    <div class="mr-2">
      Viewing <b>{{ user.firstName }} {{ user.lastName }}</b> as admin
    </div>
    <span (click)="clearUser()" class="cursor-pointer"><i class="fas fa-times-circle"></i></span>
  </div>
  <div class="pb-4 text-center admin-buttons">
    <button class="button button-primary button-sm mr-3" (click)="kycClearConsider()">{{ user.status === UserStatus.KYC_CONSIDER ? 'KYC Clear' : 'KYC Consider' }}</button>
    <button class="button button-primary button-sm mr-3" *ngIf="user?.status === UserStatus.ACTIVE || user?.status === UserStatus.SUSPENDED" (click)="suspendOrActivate()">{{ user.status === UserStatus.SUSPENDED ? 'Activate' : 'Suspend' }}</button>
    <!--<button class="button button-primary small">Send Money</button>-->
  </div>
</section>
<main class="dashboard-page flex">
  <gbl-sidenav></gbl-sidenav>
  <section class="dashboard-content">
    <gbl-dashboard-header></gbl-dashboard-header>
    <div class="p-3">
      <gbl-overview *ngIf="selectedSection === Section.OVERVIEW"></gbl-overview>
      <ng-container *ngIf="selectedSection === Section.PERSONAL_ACCOUNTS || selectedSection == Section.ADMIN_ACCOUNTS || selectedSection === Section.SMART_CONTRACTS || selectedSection === Section.GAS_TANKS" class="flex flex-grow flex-col">
        <gbl-account></gbl-account>
        <div class="w-full bg-white shadowed rounded-lg p-3">
          <gbl-transactions></gbl-transactions>
        </div>
      </ng-container>
      <gbl-institution *ngIf="selectedSection?.startsWith(Section.INSTITUTION)"></gbl-institution>
      <gbl-settings *ngIf="selectedSection?.startsWith(Section.SETTINGS)"></gbl-settings>
      <gbl-alerts *ngIf="selectedSection === Section.ALERTS"></gbl-alerts>
      <gbl-users *ngIf="selectedSection === Section.USERS"></gbl-users>
      <gbl-merchant-settings *ngIf="selectedSection === Section.MERCHANT_SETTINGS"></gbl-merchant-settings>
      <gbl-global-transactions *ngIf="selectedSection === Section.GLOBAL_TRANSACTIONS"></gbl-global-transactions>
      <gbl-stores *ngIf="selectedSection === Section.STORES"></gbl-stores>
    </div>
  </section>
</main>
<div id="onfido-mount"></div>
