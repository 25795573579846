import { IApiError } from '../interfaces/api-error.interface';

export class ObjectUtil {
  static isNullOrEmpty(value: string): boolean {
    return typeof value === 'undefined' || value == null || (typeof value === 'string' && value.length === 0) ? true : false;
  }

  static toApiError(error: any): IApiError {
    if (!error || error.status === 0 || error.status === 502) {
      return {
        status: 0,
        error: 'OfflineOrNetworkProblems',
        path: error.url
      };
    } else if (!error.error) {
      return {
        status: error.status,
        error: 'UnknownError',
        message: error.message,
        path: error.url
      };
    }

    return {
      status: error.error.status,
      error: error.error.error,
      message: error.error.message,
      data: error.error.data,
      path: error.error.path,
      timestamp: error.error.timestamp
    };
  }
}
