import { NgModule } from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@vesto/xplat/core';
import { CoreModule } from './core/core.module';
import { SharedModule } from './features/shared/shared.module';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { WebAlertComponentToken, WebConfirmComponentToken } from '@vesto/xplat/web/core';
import { AlertDialogComponent, ConfirmDialogComponent } from '@vesto/xplat/web/features';

@NgModule({
  imports: [
    CoreModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ScullyLibModule,
    // Instrumentation must be imported after importing StoreModule (config is optional)
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production // Restrict extension to log-only mode
    })
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: WebAlertComponentToken,
      useValue: AlertDialogComponent
    },
    {
      provide: WebConfirmComponentToken,
      useValue: ConfirmDialogComponent
    }
  ]
})
export class AppModule {}
