import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BlogSnippetsComponent } from './blog-snippets/blog-snippets.component';
import { FormFieldComponent } from './form-field/form-field.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AlertComponent } from './alert/alert.component';
import { InputComponent } from './input/input.component';
import { MODALS_COMPONENTS } from './modals';
import { LineChartComponent } from './line-chart/line-chart.component';
import { RewardsComponent } from './rewards/rewards.component';
import { TwoFactorComponent } from './two-factor/two-factor.component';
import { LottiePlayerComponent } from './lottie-player/lottie-player.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import {PaginatorComponent} from "./paginator/paginator.component";

export const UI_COMPONENTS = [HeaderComponent, FooterComponent, BlogSnippetsComponent, FormFieldComponent, NotFoundComponent, InputComponent, AlertComponent, ...MODALS_COMPONENTS, LineChartComponent, RewardsComponent, TwoFactorComponent, LottiePlayerComponent, MaintenanceComponent, PaginatorComponent];

export * from './header/header.component';
export * from './footer/footer.component';
export * from './blog-snippets/blog-snippets.component';
export * from './form-field/form-field.component';
export * from './not-found/not-found.component';
export * from './input/input.component';
export * from './alert/alert.component';
export * from './modals';
export * from './line-chart/line-chart.component';
export * from './rewards/rewards.component';
export * from './two-factor/two-factor.component';
export * from './lottie-player/lottie-player.component';
export * from './maintenance/maintenance.component';
export * from './paginator/paginator.component';
