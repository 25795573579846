import {ModuleWithProviders, NgModule, Optional, SkipSelf, Inject} from '@angular/core';
import {APP_BASE_HREF, CommonModule} from '@angular/common';
import {throwIfAlreadyLoaded} from '@vesto/xplat/utils';
import {environment} from './environments/environment';
import {CoreService, CoreServices, CORE_PROVIDERS, LogService, PlatformLanguageToken, StorageKeys} from './services';
import {MetaReducer, select, StoreModule} from '@ngrx/store';
import {EffectsModule, ofType} from '@ngrx/effects';
import {NgxVestoModule, EnvironmentToken, ThreadHandlerToken} from '@vesto/ngx-vesto';
import {SignUpActions, UserActions, VestoSelectors, VestoStateModule} from '@vesto/ngx-vesto/state';
import {UiEffects} from './state/ui/ui.effects';
import {coreReducer} from './state/core.state';
import {filter, take} from 'rxjs';
import * as CryptoJS from 'crypto-js';
import {hydrateMetaReducer} from './state/hydration';
import {HydrationEffects} from './state/hydration/hydration.effects';

export const metaReducers: MetaReducer[] = [hydrateMetaReducer];

/**
 * DEBUGGING
 */
LogService.DEBUG.LEVEL_4 = !environment.production;
/**
 * any log.debug argument can be specified to isolate logs to only output when these are matched
 * useful when debugging specific flows/features and you need to reduce other logging noise
 * example: this.log.debug('UserService', something);
 */
LogService.DEBUG.ISOLATE_ARGS = ['UserService'];

/**
 * Http request/responses
 */
LogService.DEBUG_HTTP.enable = !environment.production;
LogService.DEBUG_HTTP.includeRequestBody = !environment.production;
LogService.DEBUG_HTTP.includeRequestHeaders = !environment.production;
LogService.DEBUG_HTTP.includeResponse = !environment.production;
// optionally debug analytics
// LogService.DEBUG_ANALYTICS = !environment.production;

export const BASE_PROVIDERS: any[] = [
  ...CORE_PROVIDERS,
  {
    provide: APP_BASE_HREF,
    useValue: '/'
  },
  {
    provide: EnvironmentToken,
    useValue: environment
  },
  {
    // defaults to no multi-threading handling
    provide: ThreadHandlerToken,
    useValue: null
  }
];

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(coreReducer, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      },
      metaReducers
    }),
    EffectsModule.forRoot([UiEffects, HydrationEffects]),
    // Vesto angular sdk
    NgxVestoModule,
    VestoStateModule
  ],
  providers: []
})
export class CoreModule {
  // configuredProviders: *required to configure WindowService and others per platform
  static forRoot(configuredProviders: Array<any>): ModuleWithProviders<any> {
    return {
      ngModule: CoreModule,
      providers: [...BASE_PROVIDERS, ...configuredProviders]
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
      parentModule: CoreModule,
    coreServices: CoreServices
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
