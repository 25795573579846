import { UiState } from './ui/ui.state';
import { ActionReducerMap } from '@ngrx/store';
import { uiReducer } from './ui/ui.reducer';

export interface ICoreState {
  ui: UiState.IState; // TODO
}
export const coreReducer: ActionReducerMap<ICoreState> = {
  ui: uiReducer
};
