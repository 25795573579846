import { Inject, Injectable } from '@angular/core';
import { ApiActions } from '../api';
import { VestoState } from '../vesto.state';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IEnvironment, ObjectUtil } from '@vesto/vesto';
import { Action, Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ContactActions } from './contact.actions';
import { ContactsApiService } from '../../services/contacts-api.service';
import { EnvironmentToken } from '../../services/tokens';

@Injectable()
export class ContactsEffects {
  constructor(private actions$: Actions, @Inject(EnvironmentToken) protected environment: IEnvironment, protected store: Store<VestoState.IState>, private contactsApiService: ContactsApiService) {}

  emailReport$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(ContactActions.emailReport),
        mergeMap(({ contact }) => {
          return this.contactsApiService.emailReport(contact).pipe(
            mergeMap((result) => {
              return from([ContactActions.emailReportSuccess()]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([ContactActions.emailReportFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );
}
