import {Directive, OnInit} from '@angular/core';
import { BaseComponent, CoreService, uiCloseModal } from '@vesto/xplat/core';
import { IBankAccount } from '@vesto/vesto';
import {ofType} from "@ngrx/effects";
import {DashboardActions} from "../../state";
import {take, takeUntil} from "rxjs/operators";
import {BankAccountActions, TransactionActions} from "@vesto/ngx-vesto/state";
import {UntypedFormGroup, Validators} from "@angular/forms";
import {FeatureService} from "../../../services";

@Directive()
export abstract class AddPaymentMethodModalBaseComponent extends BaseComponent {
  step: 'CHOOSE_ADD_PAYMENT_METHOD_TYPE' | 'CHOOSE_PLAID_ACCOUNT' | 'BUSY' | 'SUCCESS' | 'DETAIL' = 'CHOOSE_ADD_PAYMENT_METHOD_TYPE';
  bankAccounts: IBankAccount[];
  plaidBankAccounts: IBankAccount[] = [];
  selectedBankAccount: IBankAccount;
  formGroup: UntypedFormGroup;
  showAddBankAccount = false;
  error: string;

  constructor() {
    super();
    this.formGroup = CoreService.formBuilder.group(
      {
        institution: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        routing: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator, Validators.minLength(9), Validators.maxLength(9)]
          }
        ],
        account: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator, Validators.minLength(5)]
          }
        ],
        // confirmAccount: [
        //   null,
        //   {
        //     validators: [Validators.required, Validators.nullValidator, Validators.minLength(5)]
        //   }
        // ],
      },
      { updateOn: 'blur' }
    );
  }

  openPlaid() {
    FeatureService.dashboardFacade.openPlaid();

    CoreService.actions$.pipe(ofType(DashboardActions.plaidSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.plaidBankAccounts = result.bankAccounts;
      this.step = 'CHOOSE_PLAID_ACCOUNT';
    });
  }

  choosePlaidAccount(bankAccount: IBankAccount) {
    this.step = 'BUSY';
    CoreService.bankAccountFacade.create(bankAccount);

    CoreService.actions$.pipe(ofType(BankAccountActions.createSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.selectedBankAccount = result.bankAccount;
      this.step = 'SUCCESS';
    });
  }

  createPaymentMethod() {
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }

    const value = this.formGroup.value;
    const bankAccount: IBankAccount = {
      institution: value.institution,
      ach: {
        account: value.account,
        routing: value.routing
      },
      mask: value.account.slice(-4)
    };
    CoreService.bankAccountFacade.create(bankAccount);
    CoreService.actions$.pipe(ofType(BankAccountActions.createSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      bankAccount.name = bankAccount.institution;
      CoreService.store.dispatch(DashboardActions.selectBankAccount({ bankAccount }));
      this.selectedBankAccount = bankAccount;
      this.step = 'SUCCESS';
      CoreService.windowService.setTimeout(() => {
        this.step = 'DETAIL';
      }, 1600);
    });

    CoreService.actions$.pipe(ofType(BankAccountActions.createFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
    });
  }

  close() {
    uiCloseModal();
  }
}
