import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IFiatBalances, ISystem} from '@vesto/vesto';
import {select, Store} from '@ngrx/store';
import {VestoSelectors, VestoState} from '../vesto.state';
import {SystemActions} from './system.actions';

@Injectable({
  providedIn: 'root'
})
export class SystemFacade {
  system$: Observable<ISystem> = this.store.pipe(select(VestoSelectors.system));
  fiatBalances$: Observable<IFiatBalances> = this.store.pipe(select(VestoSelectors.fiatBalances));

  constructor(private store: Store<VestoState.IState>) {
  }

  set(
    system: ISystem
  ) {
    this.store.dispatch(SystemActions.set({ system }));
  }

  find() {
    this.store.dispatch(SystemActions.find());
  }

  deployContracts(
    code: string
  ) {
    this.store.dispatch(SystemActions.deployContracts({code}));
  }

  initializeContracts() {
    this.store.dispatch(SystemActions.initializeContracts());
  }

  deployContract(
    contractId: string,
    code: string
  ) {
    this.store.dispatch(SystemActions.deployContract({contractId, code}));
  }

  sendSmsCode() {
    this.store.dispatch(SystemActions.sendSmsCode());
  }

  getFiatBalances() {
    this.store.dispatch(SystemActions.getFiatBalances());
  }
}
