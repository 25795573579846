export enum ContractType {
  VLIGHTHOUSE = 'VLIGHTHOUSE',
  VLIGHTHOUSE_PROXY = 'VLIGHTHOUSE_PROXY',
  VUSER = 'VUSER',
  VUSER_PROXY = 'VUSER_PROXY',
  VBRIDGE = 'VBRIDGE',
  VBRIDGE_PROXY = 'VBRIDGE_PROXY',
  VDEFI = 'VDEFI',
  VDEFI_PROXY = 'VDEFI_PROXY',
  VSWAP = 'VSWAP',
  VSWAP_PROXY = 'VSWAP_PROXY',
  VERC20 = 'VERC20',
  VERC20_PROXY = 'VERC20_PROXY',
  VWALLET = 'VWALLET',
  VWALLET_PROXY = 'VWALLET_PROXY',
  YEARN_VAULT = 'YEARN_VAULT',
  VERC721 = 'VERC721'
}
