import { createAction, props } from '@ngrx/store';
import {IApiError, IFee} from '@vesto/vesto';

export namespace FeeActions {
  export const calculate = createAction('[@vesto/fee] calculate', props<{ amount: number }>());

  export const calculateSuccess = createAction('[@vesto/fee] calculateSuccess', props<{ fees: IFee[] }>());

  export const calculateFailure = createAction('[@vesto/fee] calculateFailure', props<{ apiError: IApiError }>());

}
