import {Component, OnInit} from '@angular/core';
import { BaseComponent, CoreService, uiCloseModal } from '@vesto/xplat/core';
import { IUser, UserStatus } from '@vesto/vesto';
import {filter, take, takeUntil} from 'rxjs/operators';
import { UntypedFormGroup, Validators } from '@angular/forms';
import {ofType} from "@ngrx/effects";
import {UserActions} from "@vesto/ngx-vesto/state";

@Component({
  selector: 'gbl-kyc-clear-consider-modal',
  templateUrl: 'kyc-clear-consider-modal.component.html'
})
export class KycClearConsiderModalComponent extends BaseComponent implements OnInit {
  step: 'FORM_GROUP' | 'BUSY' | 'SUCCESS' | 'DETAIL' = 'FORM_GROUP';
  user: IUser;
  UserStatus = UserStatus;
  formGroup: UntypedFormGroup;
  error: string;

  ngOnInit() {
    CoreService.userFacade.user$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user)
      )
      .subscribe((result) => {
      this.user = result;
    });
    this.formGroup = CoreService.formBuilder.group(
      {
        memo: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ]
      },
      { updateOn: 'change' }
    );
  }

  clearConsider() {
    this.step = 'BUSY';
    if (this.user.status === UserStatus.KYC_CONSIDER) {
      CoreService.userFacade.kycClear();
    } else {
      CoreService.userFacade.kycConsider();
    }

    CoreService.actions$.pipe(ofType(UserActions.kycClearSuccess, UserActions.kycConsiderSuccess), take(1), takeUntil(this.destroy$)).subscribe(() => {
      this.step = 'SUCCESS';
      CoreService.windowService.setTimeout(() => {
        this.step = 'DETAIL';
      }, 1600);
    });

    CoreService.actions$.pipe(ofType(UserActions.kycConsiderFailure, UserActions.kycClearFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
      this.step = 'FORM_GROUP';
    });
  }

  close() {
    uiCloseModal();
  }
}
