import { Component } from '@angular/core';

import { BaseComponent } from '@vesto/xplat/core';

@Component({
  selector: 'gbl-sign-in',
  templateUrl: 'sign-in.component.html'
})
export class AerosSignInComponent extends BaseComponent {

}
