import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, Observable } from 'rxjs';
import {InstitutionActions, UserFacade} from '@vesto/ngx-vesto/state';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class DashboardInstitutionEffects {
  constructor(private actions$: Actions, private userFacade: UserFacade) {}
}
