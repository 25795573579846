import { MerchantStatus } from '../enums/merchant-status.enum';
import { MerchantFeeType} from '../enums/merchant-fee-type.enum';

export interface IMerchant {
  id: string;
  institutionId: string;
  status: MerchantStatus;
  feeType: MerchantFeeType;
  name: string;
  address: string;
  feeFixed: number;
  feePercentage: number;
  updated: string;
  created: string;
}
