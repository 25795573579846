import { createAction, props } from '@ngrx/store';
import {IAccount, IApiError, IContract} from '@vesto/vesto';

export namespace AccountActions {
  export const set = createAction('[@vesto/account] set', props<{ account: IAccount }>());

  export const setContract = createAction('[@vesto/account] setContract', props<{ contract: IContract }>());

  export const find = createAction('[@vesto/account] find', props<{ accountId: string }>());

  export const findSuccess = createAction('[@vesto/account] findSuccess', props<{ account: IAccount }>());

  export const findFailure = createAction('[@vesto/account] findFailure', props<{ apiError: IApiError }>());

  export const findByUser = createAction('[@vesto/account] findByUser');

  export const findByUserSuccess = createAction('[@vesto/account] findByUserSuccess', props<{ accounts: IAccount[] }>());

  export const findByUserFailure = createAction('[@vesto/account] findByUserFailure', props<{ apiError: IApiError }>());

  export const create = createAction('[@vesto/account] create', props<{ name: string }>());

  export const createSuccess = createAction('[@vesto/account] createSuccess', props<{ account: IAccount }>());

  export const createFailure = createAction('[@vesto/account] createFailure', props<{ apiError: IApiError }>());

  export const changeSettings = createAction('[@vesto/account] changeSettings', props<{ id: string; name: string }>());

  export const changeSettingsSuccess = createAction('[@vesto/account] changeSettingsSuccess', props<{ account: IAccount }>());

  export const changeSettingsFailure = createAction('[@vesto/account] changeSettingsFailure', props<{ apiError: IApiError }>());

  export const suspendUser = createAction('[@vesto/account] suspendUser', props<{ additionalUserId: string }>());

  export const suspendUserSuccess = createAction('[@vesto/account] suspendUserSuccess', props<{ account: IAccount }>());

  export const suspendUserFailure = createAction('[@vesto/account] suspendUserFailure', props<{ apiError: IApiError }>());

  export const activateUser = createAction('[@vesto/account] activateUser', props<{ additionalUserId: string }>());

  export const activateUserSuccess = createAction('[@vesto/account] activateUserSuccess', props<{ account: IAccount }>());

  export const activateUserFailure = createAction('[@vesto/account] activateUserFailure', props<{ apiError: IApiError }>());
}
