import { Directive } from '@angular/core';
import { BaseComponent } from '@vesto/xplat/core';

@Directive()
export abstract class CurrenciesBaseComponent extends BaseComponent {
  public text: string = 'Currencies';

  constructor() {
    super();
  }
}
