import {IEnvironment} from '../interfaces/environment.interface';
import {IHttpClient} from '../interfaces/http.interface';
import {BaseApi} from './base-api';

export class FeesApi extends BaseApi {
  constructor(protected environment: IEnvironment, protected httpClient: IHttpClient) {
    super(environment);
  }

  calculate(
    amount: number,
  ) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/fees/calculate`),
      {
        amount
      },
      {
        headers: {
          ...this.getHeaders()
        }
      }
    );
  }
}
