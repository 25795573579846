<section *ngIf="institution" class="bg-white shadowed rounded mb-3 p-3">
  <h4>Institution Settings</h4>
  <div class="flex mt-4 text-sm">
    <div class="flex-1">
      <span class="block font-bold">Name</span>
      {{ institution.name }}
    </div>
    <div class="flex-1">
      <span class="block font-bold">URL</span>
      {{ institution.url }}
    </div>
    <div class="flex-1">
      <span class="block font-bold">Address</span>
      {{ institution.address }}
    </div>
  </div>

  <div class="flex mt-4 text-sm">
    <div class="flex-1">
      <span class="block font-bold">Fee Type:</span>
      {{ institution.feeType | titlecase }}
    </div>
    <div class="flex-1" *ngIf="institution.feeType !== InstitutionFeeType.PERCENTAGE">
      <span class="block font-bold">Fixed Fee:</span>
      {{ institution.feeFixed | currency }}
    </div>
    <div class="flex-1" *ngIf="institution.feeType !== InstitutionFeeType.FIXED">
      <span class="block font-bold">Fee Percent:</span>
      {{ institution.feePercentage }}%
    </div>
  </div>

  <div class="flex mt-4 text-sm">
    <div class="flex-1">
      <span class="block font-bold">iOS App ID</span>
      {{ institution.iosAppId }}
    </div>
    <div class="flex-1">
      <span class="block font-bold">Android App ID</span>
      {{ institution.androidAppId }}
    </div>
    <div class="flex-1">
      <span class="block font-bold">Referrer</span>
      {{ institution.referrer }}
    </div>
  </div>
  <div class="flex mt-4 text-sm">
    <ng-container *ngIf="institution.instantSettleEnabled else instantSettleDisabled">
      <div class="flex-1">
        <span class="block font-bold">Instant Settlement</span>
        Enabled
      </div>
      <div class="flex-1">
        <span class="block font-bold">Settle Transaction Limit</span>
        {{ institution.settleTransactionLimit | currency }}
      </div>
      <div class="flex-1">
        <span class="block font-bold">Settle Daily Limit</span>
        {{ institution.settleDailyLimit | currency }}
      </div>
    </ng-container>
    <ng-template #instantSettleDisabled>
      <div class="flex-1">
        <span class="block font-bold">Instant Settlement</span>
        Disabled
      </div>
    </ng-template>
  </div>
  <div class="flex mt-4 text-sm">
    <div class="flex-grow"></div>
    <button (click)="openOrGoToChangeSettings()" class="button button-primary button-sm self-end">Change</button>
  </div>
</section>

<section *ngIf="institution" class="bg-white shadowed rounded p-3">
  <h4>AML Settings</h4>
  <div class="flex mt-4 text-sm">
    <div class="flex-1">
      <span class="block font-bold">Default Daily Buy Limit</span>
      {{ institution.defaultDailyBuyLimit | currency }}
    </div>
    <div class="flex-1">
      <span class="block font-bold">Default Daily Sell Limit</span>
      {{ institution.defaultDailySellLimit | currency }}
    </div>
  </div>
  <div class="flex mt-4 text-sm">
    <div class="flex-1">
      <span class="block font-bold">Default Daily Transfer Limit</span>
      {{ institution.defaultDailyTransferLimit | currency }}
    </div>
    <div class="flex-1">
      <span class="block font-bold">Default Daily Receive Limit</span>
      {{ institution.defaultDailyReceiveLimit | currency }}
    </div>
  </div>
  <div class="flex mt-4 text-sm">
    <div class="flex-grow">
      <span class="block font-bold">Enable Plaid to link bank accounts</span>
      {{ institution.plaidEnabled ? 'On' : 'Off' }}
    </div>
    <button (click)="openOrGoToChangeAml()" class="button button-primary button-sm self-end">Change</button>
  </div>
</section>
