import {Component, OnInit} from '@angular/core';
import {Blockchain, IGasTank, IInstitution} from '@vesto/vesto';
import {BaseComponent, CoreService} from '@vesto/xplat/core';
import {AccountHeader, FeatureService} from '@vesto/xplat/features';
import {filter, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'gbl-gas-tanks',
  templateUrl: './gas-tanks.component.html'
})
export class GasTanksComponent extends BaseComponent implements OnInit {
  gasTanks: IGasTank[] = [];
  institution: IInstitution;
  showGasTanks = false;
  Blockchain = Blockchain;
  selectedBlockchain = Blockchain.ETHEREUM;
  AccountHeader = AccountHeader;
  selectedAccountHeader = AccountHeader.ETHEREUM;

  ngOnInit() {
    CoreService.gasTankFacade.gasTanks$
      .pipe(
        filter(gasTanks => !!gasTanks),
        takeUntil(this.destroy$)
      )
      .subscribe(gasTanks => {
        this.gasTanks = gasTanks;
      });
    FeatureService.dashboardFacade.selectedAccountHeader$
      .pipe(
        filter(selectedAccountHeader => !!selectedAccountHeader),
        takeUntil(this.destroy$)
      ).subscribe((selectedAccountHeader) => {
      this.selectedAccountHeader = selectedAccountHeader;
      this.selectedBlockchain = this.selectedAccountHeader === AccountHeader.ETHEREUM ? Blockchain.ETHEREUM : Blockchain.POLYGON
    });
  }

  openOrGoToGasTank(gasTank: IGasTank) {
   FeatureService.dashboardFacade.openOrGoToGasTank(this.selectedBlockchain, gasTank);
  }

  transfer(gasTankId: string) {
    FeatureService.dashboardFacade.openOrGoToTransferGas(this.selectedBlockchain, gasTankId)
  }

  switchBlockchain() {
    if (this.selectedBlockchain === Blockchain.ETHEREUM) {
      this.selectedBlockchain = Blockchain.POLYGON;
    } else {
      this.selectedBlockchain = Blockchain.ETHEREUM;
    }
  }
}
