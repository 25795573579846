import {Directive, OnInit} from '@angular/core';
import { BaseComponent, CoreService } from '@vesto/xplat/core';
import {
  IAccount,
  IInstitution,
  ISnapShot,
  ITransaction,
  IUser,
  RoleType,
  TransactionStatus, TransactionType,
  UserUtil
} from '@vesto/vesto';
import {distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';
import {FeatureService} from "../../services";

@Directive()
export abstract class OverviewBaseComponent extends BaseComponent implements OnInit {
  institution: IInstitution;
  user: IUser;
  selectedAccount: IAccount;
  userSnapShots: ISnapShot[] = [];
  userChartType = 'balances';
  userChartSeries: any[];
  userBalance = 0;
  userRewards = 0;
  institutionSnapShots: ISnapShot[] = [];
  institutionChartType = 'balances';
  institutionChartSeries: any[];
  institutionBalance = 0;
  institutionRewards = 0;
  systemSnapShots: ISnapShot[] = [];
  systemChartType = 'balances';
  systemChartSeries: any[];
  systemBalance = 0;
  systemRewards = 0;
  RoleType = RoleType;
  UserUtil = UserUtil;
  accountsRequiringSignature: any = [];
  transactions: ITransaction[] = [];
  TransactionStatus = TransactionStatus;
  TransactionType = TransactionType;

  ngOnInit() {
    CoreService.institutionFacade.institution$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.institution = result;
    });
    CoreService.userFacade.user$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.user = result;
    });
    FeatureService.dashboardFacade.selectedAccount$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.selectedAccount = result;
    });
    CoreService.snapShotFacade.userSnapShots$.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((result) => {
      this.userSnapShots = result;
      this.setUserChart('balances');
    });
    CoreService.snapShotFacade.institutionSnapShots$.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((result) => {
      this.institutionSnapShots = result;
      this.setInstitutionChart('balances');
    });
    CoreService.snapShotFacade.systemSnapShots$.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((result) => {
      this.systemSnapShots = result;
      this.setSystemChart('balances');
    });
    // if (CoreService.windowService.isBrowser) {
    //   // note: mobile implementations use ObservableArrays for platform optimized listviews
    //   // see mobile components for wiring upchain
    //   CoreService.accountFacade.accounts$
    //     .pipe(
    //       takeUntil(this.destroy$),
    //       filter(account => !!account)
    //     )
    //     .subscribe((accounts) => {
    //       console.log('ACCOUNTS', accounts);
    //       this.accountsRequiringSignature = [];
    //       this.accountsRequiringSignature = accounts;
    //       // accounts.forEach(account => {
    //       //   if (account.requiresSignatures) {
    //       //     this.accountsRequiringSignature.push(account);
    //       //   }
    //       // });
    //     });
    // }
    CoreService.transactionFacade.transactions$
      .pipe(
        takeUntil(this.destroy$),
        filter((transaction) => !!transaction)
      )
      .subscribe((transactions) => {
        this.transactions = [];
        transactions.forEach(transaction => {
          if (transaction.status === TransactionStatus.PENDING && transaction.requiredSignatures > 1) {
            this.transactions.push(transaction);
          }
        });
      });
  }

  setUserChart(userChartType: string) {
    this.userChartType = userChartType;
    const data = [];
    if (this.userChartType === 'balances') {
      for (const snapShot of this.userSnapShots) {
        data.push([new Date(snapShot.created).getTime(), snapShot.balance]);
        this.userBalance = snapShot.balance;
      }
    } else if (this.userChartType === 'rewards') {
      for (const snapShot of this.userSnapShots) {
        data.push([new Date(snapShot.created).getTime(), snapShot.interest]);
        this.userRewards = snapShot.interest;
      }
    }
    this.userChartSeries = [
      {
        name: this.userChartType,
        turboThreshold: 500000,
        data: data
      }
    ];
  }

  setInstitutionChart(chartType: string) {
    this.institutionChartType = chartType;
    const data = [];
    if (this.institutionChartType === 'balances') {
      for (const snapShot of this.institutionSnapShots) {
        data.push([new Date(snapShot.created).getTime(), snapShot.balance]);
        this.institutionBalance = snapShot.balance;
      }
    } else if (this.institutionChartType === 'rewards') {
      for (const snapShot of this.institutionSnapShots) {
        data.push([new Date(snapShot.created).getTime(), snapShot.interest]);
        this.institutionRewards = snapShot.interest;
      }
    }
    this.institutionChartSeries = [
      {
        name: this.institutionChartType,
        turboThreshold: 500000,
        data: data
      }
    ];
  }

  setSystemChart(chartType: string) {
    this.systemChartType = chartType;
    const data = [];
    if (this.systemChartType === 'balances') {
      for (const snapShot of this.systemSnapShots) {
        data.push([new Date(snapShot.created).getTime(), snapShot.balance]);
        this.systemBalance = snapShot.balance;
      }
    } else if (this.systemChartType === 'rewards') {
      for (const snapShot of this.systemSnapShots) {
        data.push([new Date(snapShot.created).getTime(), snapShot.interest]);
        this.systemRewards = snapShot.interest;
      }
    }
    this.systemChartSeries = [
      {
        name: this.institutionChartType,
        turboThreshold: 500000,
        data: data
      }
    ];
  }
}
