import { Directive, ElementRef, HostBinding, HostListener, Input, OnInit, Self, ViewChild } from '@angular/core';
import { BaseComponent, CoreService, ICountryCode } from '@vesto/xplat/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { isNullOrNaN, strongPasswordPattern } from '@vesto/xplat/utils';

@Directive()
export abstract class InputBaseComponent extends BaseComponent implements OnInit, ControlValueAccessor {
  @Input() mask: string;
  @Input() formControlName: string;
  showPassword = false;
  inputType: string;
  value: any;
  options: any = [];
  @Input() placeholder: string;
  countryCode: ICountryCode;
  defaultCountryCode: ICountryCode;
  countryCodes: ICountryCode[] = [];
  @ViewChild('input') input: ElementRef;
  @HostBinding('tabindex') tabindex = 0;
  strongPasswordPattern: RegExp;

  onChange: any = () => {};
  onTouched: any = () => {};
  pwRequirementsSatisfied: any = {
    min8chars: {
      test: new RegExp(/^.{8,}$/),
      satisfied: false
    },
    oneCapitalLetter: {
      test: new RegExp(/^(?=.*[A-Z])/),
      satisfied: false
    },
    oneNumber: {
      test: new RegExp(/^(?=.*[0-9])/),
      satisfied: false
    },
    oneSpecialChar: {
      test: new RegExp(/^(?=.*[@$!%*?&.])/),
      satisfied: false
    }
  };

  constructor(@Self() public ngControl: NgControl) {
    super();
    this.ngControl.valueAccessor = this;
    CoreService.uiFacade.countryCodes$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.countryCodes = result;
    });
    this.strongPasswordPattern = strongPasswordPattern;
    for (const countryCode of this.countryCodes) {
      if (countryCode.code === 'us') {
        this.defaultCountryCode = countryCode;
        break;
      }
    }
  }

  ngOnInit() {
    switch (this.mask) {
      case 'currency':
        this.options = { prefix: '$', numeral: true, numericOnly: true, numeralThousandsGroupStyle: 'thousand' }; // TODO: Use locale to get prefix!!
        break;
      case 'code':
        this.options = { numericOnly: true, blocks: [6] };
        break;
      case 'mobile':
        this.options = { numericOnly: true, blocks: [12] };
        break;
    }

    if (this.mask === 'password' || this.mask === 'strong-password') {
      this.showPassword = true;
    }
  }

  @HostListener('focus')
  focus() {
    if (this.input && this.input.nativeElement) this.input.nativeElement.focus();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: string) {
    switch (this.mask) {
      case 'mobile':
        if (!this.countryCode) {
          this.countryCode = this.defaultCountryCode;
        }
        if (!value) {
          return;
        }
        // TODO: We need to lookup area codes for US and Canada.
        if (value && value.startsWith(this.defaultCountryCode.dial_code)) {
          this.countryCode = this.defaultCountryCode;
        } else {
          for (const countryCode of this.countryCodes) {
            if (value && value.startsWith(countryCode.dial_code)) {
              this.countryCode = countryCode;
              break;
            }
          }
        }
        this.value = value.replace(this.countryCode.dial_code, '');
        break;
      default:
        this.value = value;
    }
  }

  countryCodeChange(countryCode: any) {
    this.value = null;
    this.countryCode = countryCode;
  }

  inputChange(value) {
    if (this.ngControl.control.updateOn === 'change') {
      this.ngControl.control.setValue(this.parse(value), { emitEvent: true });
    } else {
      this.ngControl.control.setValue(this.parse(value), { emitEvent: false });
    }
    this.onChange(this.ngControl.control.value);
  }

  inputBlur(event): void {
    if (this.ngControl.control.updateOn === 'blur') {
      this.ngControl.control.setValue(this.parse(this.value), { emitEvent: true });
    } else {
      this.ngControl.control.setValue(this.parse(this.value), { emitEvent: false });
    }
    this.onTouched(event);
  }

  parse(value) {
    switch (this.mask) {
      case 'currency':
        if (isNullOrNaN(value)) {
          return 0;
        }
        if (typeof value === 'string') {
          return parseFloat(value.replace(/[^0-9.]/g, ''));
        }
        return value;
      case 'mobile':
        return value ? `${this.countryCode.dial_code}${value}` : null;
      default:
        return value;
    }
  }
}
