import { TransactionsComponent } from './transactions/transactions.component';
import { PaymentMethodsComponent } from './payment-methods/payment-methods.component';
import { SettingsComponent } from './settings/settings.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AccountComponent } from './account/account.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { DashboardHeaderComponent } from './header/dashboard-header.component';
import { ProfileComponent } from './profile/profile.component';
import { AdditionalUsersComponent } from './additional-users/additional-users.component';
import { OverviewComponent } from './overview/overview.component';
import { DASHBOARD_MODALS_COMPONENTS } from './modals';
import { AlertsComponent } from './alerts/alerts.component';
import { InstitutionComponent } from './institution/institution.component';
import { GasTanksComponent } from './gas-tanks/gas-tanks.component';
import { UserStatusComponent } from './user-status/user-status.component';
import { AccountStatusComponent } from './account-status/account-status.component';
import { TransactionComponent } from './transaction/transaction.component';
import { SmartContractComponent } from './smart-contract/smart-contract.component';
import {MerchantsComponent} from "./merchants/merchants.component";
import {InstitutionsComponent} from "./institutions/institutions.component";
import {InstitutionSettingsComponent} from "./institution-settings/institution-settings.component";
import {UsersComponent} from "./users/users.component";
import {MerchantSettingsComponent} from "./merchant-settings/merchant-settings.component";
import {GlobalTransactionsComponent} from "./global-transactions/global-transactions.component";
import {StoresComponent} from "./stores/stores.component";

export const DASHBOARD_COMPONENTS = [AccountStatusComponent, UserStatusComponent, TransactionsComponent, PaymentMethodsComponent, SettingsComponent, DashboardComponent, AccountComponent, SidenavComponent, DashboardHeaderComponent, ProfileComponent, AdditionalUsersComponent, OverviewComponent, ...DASHBOARD_MODALS_COMPONENTS, AlertsComponent, InstitutionComponent, GasTanksComponent, TransactionComponent, SmartContractComponent, MerchantsComponent, InstitutionsComponent, InstitutionSettingsComponent, UsersComponent, MerchantSettingsComponent, GlobalTransactionsComponent, StoresComponent];

export * from './account-status/account-status.component';
export * from './transactions/transactions.component';
export * from './payment-methods/payment-methods.component';
export * from './settings/settings.component';
export * from './transactions/transactions.component';
export * from './dashboard/dashboard.component';
export * from './account/account.component';
export * from './sidenav/sidenav.component';
export * from './header/dashboard-header.component';
export * from './profile/profile.component';
export * from './additional-users/additional-users.component';
export * from './overview/overview.component';
export * from './modals';
export * from './alerts/alerts.component';
export * from './institution/institution.component';
export * from './gas-tanks/gas-tanks.component';
export * from './user-status/user-status.component';
export * from './transaction/transaction.component';
export * from './smart-contract/smart-contract.component';
export * from './merchants/merchants.component';
export * from './institutions/institutions.component';
export * from './institution-settings/institution-settings.component';
export * from './users/users.component';
export * from './merchant-settings/merchant-settings.component';
export * from './stores/stores.component';
