import { Directive, OnInit } from '@angular/core';
import {BaseComponent, CoreService, ModalContextToken, UiActions, uiCloseModal, WindowService} from '@vesto/xplat/core';
import {IAccount, IBankAccount} from '@vesto/vesto';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import {ofType} from "@ngrx/effects";
import {AccountActions, VestoSelectors} from "@vesto/ngx-vesto/state";
import {take} from "rxjs";
import apiError = VestoSelectors.apiError;

@Directive()
export abstract class CreateAccountModalBaseComponent extends BaseComponent {
  step: 'FORM_GROUP' | 'BUSY' | 'SUCCESS' | 'DETAIL' = 'FORM_GROUP';
  formGroup: UntypedFormGroup;
  error: string;
  account: IAccount;

  constructor() {
    super();
    this.formGroup = CoreService.formBuilder.group({
      name: [
        null,
        {
          validators: [Validators.nullValidator, Validators.required]
        }
      ]
    });
  }

  create() {
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }

    this.step = 'BUSY';
    const value = this.formGroup.value;
    CoreService.accountFacade.create(value.name);

    CoreService.actions$.pipe(ofType(AccountActions.createSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.account = result.account;
      this.step = 'SUCCESS';
      CoreService.windowService.setTimeout(() => {
        this.step = 'DETAIL';
      }, 1600);
    });

    CoreService.actions$.pipe(ofType(AccountActions.createFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.step = 'FORM_GROUP';
      this.error = `apiErrors.${result.apiError.error}`;
    })
  }

  close() {
    uiCloseModal();
  }
}
