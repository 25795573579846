import {createAction, props} from '@ngrx/store';
import {IApiError, IFiatBalances, ISystem} from '@vesto/vesto';

export namespace SystemActions {
  export const set = createAction('[@vesto/system] set', props<{ system: ISystem }>());

  export const find = createAction('[@vesto/system] find');

  export const findSuccess = createAction('[@vesto/system] findSuccess', props<{ system: ISystem }>());

  export const findFailure = createAction('[@vesto/system] findFailure', props<{ apiError: IApiError }>());

  export const deployContracts = createAction('[@vesto/system] deployContracts', props<{ code: string }>());

  export const deployContractsSuccess = createAction('[@vesto/system] deployContractsSuccess', props<{ system: ISystem }>());

  export const deployContractsFailure = createAction('[@vesto/system] deployContractsFailure', props<{ apiError: IApiError }>());

  export const initializeContracts = createAction('[@vesto/system] initializeContracts');

  export const initializeContractsSuccess = createAction('[@vesto/system] initializeContractsSuccess', props<{ system: ISystem }>());

  export const initializeContractsFailure = createAction('[@vesto/system] initializeContractsFailure', props<{ apiError: IApiError }>());

  export const deployContract = createAction('[@vesto/system] deployContract', props<{ contractId: string; code: string }>());

  export const deployContractSuccess = createAction('[@vesto/system] deployContractSuccess', props<{ system: ISystem }>());

  export const deployContractFailure = createAction('[@vesto/system] deployContractFailure', props<{ apiError: IApiError }>());

  export const deployToken = createAction('[@vesto/system] deployToken', props<{ tokenId: string }>());

  export const deployTokenSuccess = createAction('[@vesto/system] deployTokenSuccess', props<{ system: ISystem }>());

  export const deployTokenFailure = createAction('[@vesto/system] deployTokenFailure', props<{ apiError: IApiError }>());

  export const getFiatBalances = createAction('[@vesto/system] getFiatBalances');

  export const getFiatBalancesSuccess = createAction('[@vesto/system] getFiatBalancesSuccess', props<{ fiatBalances: IFiatBalances }>());

  export const getFiatBalancesFailure = createAction('[@vesto/system] getFiatBalancesFailure', props<{ apiError: IApiError }>());

  export const sendSmsCode = createAction('[@vesto/system] sendSmsCode');

  export const sendSmsCodeSuccess = createAction('[@vesto/system] sendSmsCodeSuccess', props<{ token: string }>());

  export const sendSmsCodeFailure = createAction('[@vesto/system] sendSmsCodeFailure', props<{ apiError: IApiError }>());
}
