import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { UI_PIPES } from './pipes';
import { UI_DIRECTIVES } from './directives';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CurrencyPipe, TitleCasePipe } from '@angular/common';
import { FeatureServices } from '../services/feature.service';

const MODULES = [TranslateModule, FormsModule, ReactiveFormsModule];

@NgModule({
  imports: [...MODULES],
  declarations: [...UI_PIPES, ...UI_DIRECTIVES],
  exports: [...MODULES, ...UI_PIPES, ...UI_DIRECTIVES],
  providers: [CurrencyPipe, TitleCasePipe]
})
export class UISharedModule {
  constructor(featureServices: FeatureServices) {}
}
