import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gwei',
  pure: true
})
export class GweiPipe implements PipeTransform {
  transform(value: number) {
    return (value / 1000000000).toFixed(0) + ' Gwei';
  }
}
