<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <h3 class="section-number"><i class="fal fa-check-circle"></i> {{ action | titlecase }}</h3>
</div>
<mat-dialog-content>
  <ng-container [ngSwitch]="step">
    <section *ngSwitchCase="'TWO_FACTOR'">
      <gbl-two-factor (code)="confirmCode($event)" (sendSmsCode)="sendSmsCode($event)"></gbl-two-factor>
    </section>

    <section *ngSwitchCase="'BUSY'" class="mt-4">
      <div class="placeholder-long bg-flash"></div>
      <div class="placeholder-area bg-flash mt-4"></div>
    </section>

    <section *ngSwitchCase="'SUCCESS'">
      <div style="width: 350px; height: 350px; overflow: hidden; margin: -24px auto 0">
        <gbl-lottie-player></gbl-lottie-player>
      </div>
    </section>

    <section *ngSwitchCase="'ERROR'">
      <gbl-alert [type]="error" [message]="error"></gbl-alert>
      <button class="button button-primary my-4 mx-auto w-full" (click)="tryAgain()">Try Again</button>
    </section>

    <section *ngSwitchCase="'TRANSACTION_DETAIL'">
      <div class="text-center">
        <p>Success!</p>
        <p *ngIf="transaction" class="text-sm mb-4">You transaction has been submitted to the blockchain</p>
      </div>
      <div class="p-4 bg-trans-blue rounded-lg" *ngIf="transaction">
        <gbl-transaction [transaction]="transaction"></gbl-transaction>
      </div>
      <button class="button button-primary my-4 mx-auto w-full" (click)="close()">Close</button>
    </section>
  </ng-container>
</mat-dialog-content>
