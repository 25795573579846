import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IContact } from '@vesto/vesto';
import { ContactActions } from './contact.actions';
import { VestoState } from '../vesto.state';

@Injectable({
  providedIn: 'root'
})
export class ContactsFacade {
  constructor(private store: Store<VestoState.IState>) {}

  emailReport(contact: IContact) {
    this.store.dispatch(ContactActions.emailReport({ contact }));
  }
}
