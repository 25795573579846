import { NgModule } from '@angular/core';
import { SignUpModule as SharedSignUpModule } from '@vesto/xplat/features';
import { UIModule } from '../ui/ui.module';
import { SIGNUP_COMPONENTS } from './components';

@NgModule({
  imports: [SharedSignUpModule, UIModule],
  declarations: [...SIGNUP_COMPONENTS],
  exports: [...SIGNUP_COMPONENTS]
})
export class SignUpModule {}
