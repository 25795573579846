<main class="accounts">
  <section class="grid grid-cols-2 gap-3">
    <div *ngIf="UserUtil.hasRole(user, RoleType.SUPER_ADMIN)" class="bg-white shadowed rounded-lg p-3">
      <h4>System Portfolio</h4>
      <div class="mb-3 flex items-center">
        <div *ngIf="systemChartType === 'balances'" class="flex-grow">
          Balance: <span class="font-light">{{ systemBalance.toFixed(2) | currency: 'USD' }}</span>
        </div>
        <div *ngIf="systemChartType === 'rewards'" class="flex-grow">
          Rewards: <span class="font-light">{{ systemRewards.toFixed(6) | currency: 'USD' }}</span>
        </div>
        <div class="chart-type-button mr-3" [class.selected]="systemChartType === 'balances'" (click)="setSystemChart('balances')">Balances</div>
        <div class="chart-type-button" [class.selected]="systemChartType === 'rewards'" (click)="setSystemChart('rewards')">Rewards</div>
      </div>

      <gbl-line-chart [series]="systemChartSeries" [height]="'320px'"></gbl-line-chart>
      <div class="clearfix"></div>
    </div>

    <div *ngIf="UserUtil.hasRole(user, RoleType.ADMIN) || UserUtil.hasRole(user, RoleType.SUPER_ADMIN)" class="bg-white shadowed rounded-lg p-3">
      <h4>{{ institution?.name }} Portfolio</h4>
      <div class="mb-3 flex items-center">
        <div *ngIf="institutionChartType === 'balances'" class="flex-grow">
          Balance: <span class="font-light">{{ institutionBalance.toFixed(2) | currency: 'USD' }}</span>
        </div>
        <div *ngIf="institutionChartType === 'rewards'" class="flex-grow">
          Rewards: <span class="font-light">{{ institutionRewards.toFixed(6) | currency: 'USD' }}</span>
        </div>
        <div class="chart-type-button mr-3" [class.selected]="institutionChartType === 'balances'" (click)="setInstitutionChart('balances')">Balances</div>
        <div class="chart-type-button" [class.selected]="institutionChartType === 'rewards'" (click)="setInstitutionChart('rewards')">Rewards</div>
      </div>

      <gbl-line-chart [series]="institutionChartSeries" [height]="'320px'"></gbl-line-chart>
      <div class="clearfix"></div>
    </div>

    <div class="bg-white shadowed rounded-lg p-3">
      <h4>Your Portfolio</h4>
      <div class="mb-3 flex items-center">
        <div *ngIf="userChartType === 'balances'" class="flex-grow">
          Balance: <span class="font-light">{{ userBalance.toFixed(2) | currency: 'USD' }}</span>
        </div>
        <div *ngIf="userChartType === 'rewards'" class="flex-grow">
          Rewards: <span class="font-light">{{ userRewards.toFixed(6) | currency: 'USD' }}</span>
        </div>
        <div class="chart-type-button mr-3" [class.selected]="userChartType === 'balances'" (click)="setUserChart('balances')">Balances</div>
        <div class="chart-type-button" [class.selected]="userChartType === 'rewards'" (click)="setUserChart('rewards')">Rewards</div>
      </div>

      <gbl-line-chart [series]="userChartSeries" [height]="'320px'"></gbl-line-chart>
      <div class="clearfix"></div>
    </div>
  </section>
</main>
