import {Directive, OnInit} from '@angular/core';
import {BaseComponent, CoreService} from '@vesto/xplat/core';
import {
  AccountStatus,
  AccountType,
  Blockchain,
  IAccount, IGasTank,
  IInstitution,
  ISnapShot,
  ISystem,
  IToken,
  TokenStatus,
  TokenType
} from '@vesto/vesto';
import {distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';
import {FeatureService} from '../../services';

export enum AccountChartType {
  BALANCES = 0,
  REWARDS = 1
}

@Directive()
export abstract class AccountBaseComponent extends BaseComponent implements OnInit {
  selectedAccount: IAccount;
  accountSnapShots: ISnapShot[] = [];
  AccountStatus = AccountStatus;
  accountChartType = AccountChartType.BALANCES;
  chartSeries;
  AccountType = AccountType;
  erc20Tokens: IToken[] = [];
  yearnVaultTokens: IToken[] = [];
  TokenStatus = TokenStatus;
  institution: IInstitution;
  Blockchain = Blockchain;
  selectedToken: IToken;
  system: ISystem;

  ngOnInit() {
    CoreService.snapShotFacade.accountSnapShots$.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((result) => {
      this.accountSnapShots = result;
      this.setChartSeries(AccountChartType.BALANCES);
    });
    CoreService.institutionFacade.institution$
      .pipe(
        filter((institution) => !!institution),
        takeUntil(this.destroy$)
      ).subscribe(institution => {
      this.institution = institution;
      this.erc20Tokens = [];
      this.yearnVaultTokens = [];
      this.institution.tokens.forEach(token => {
        if (token.type == TokenType.VERC20) {
          this.erc20Tokens.push(token);
        } else if (token.type == TokenType.YEARN_VAULT) {
          this.yearnVaultTokens.push(token);
        }
      });
    });

    FeatureService.dashboardFacade.selectedAccount$
      .pipe(
        filter((selectedAccount) => !!selectedAccount),
        takeUntil(this.destroy$)
      )
      .subscribe((selectedAccount) => {
        CoreService.ngZone.run(() => {
          this.selectedAccount = selectedAccount;
        });
      });

    FeatureService.dashboardFacade.selectedToken$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.selectedToken = result;
    });
  }

  setChartSeries(accountChartType: AccountChartType) {
    this.accountChartType = accountChartType;
    const data = [];
    if (this.accountChartType === AccountChartType.BALANCES) {
      for (const snapShot of this.accountSnapShots) {
        data.push([new Date(snapShot.created).getTime(), snapShot.balance]);
      }
    } else if (this.accountChartType === AccountChartType.REWARDS) {
      for (const snapShot of this.accountSnapShots) {
        data.push([new Date(snapShot.created).getTime(), snapShot.interest]);
      }
    }

    this.chartSeries = [
      {
        name: this.accountChartType,
        turboThreshold: 500000,
        data: data
      }
    ];
  }

  selectToken(token: IToken) {
    FeatureService.dashboardFacade.selectToken(token);
  }
}
