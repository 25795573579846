export function sanitizePhone(phone: string) {
  return phone ? phone.replace(/ /gi, '').replace(/-/gi, '') : '';
}

export const strongPasswordPattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]){8,}/);

export function capitalize(str): string {
  // being defensize here since some edge cases have arised around it's usage
  if (str) {
    const firstChar = str.charAt(0);
    if (firstChar) {
      return firstChar.toUpperCase() + str.slice(1);
    } else {
      return str;
    }
  }

  return '';
}

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return [s4(), s4(), '-', s4(), '-', s4(), '-', s4(), s4(), s4()].join('');
}

export function formatSsn(value: string) {
  const strValue = value.toString();
  let displayVal = strValue;
  if (strValue.length === 3) {
    displayVal = value + '-';
  } else if (strValue.length === 4) {
    displayVal = strValue[0] + strValue[1] + strValue[2] + '-' + strValue[3];
  } else if (strValue.length === 5) {
    displayVal = strValue[0] + strValue[1] + strValue[2] + '-' + strValue[3] + strValue[4] + '-';
  } else if (strValue.length === 6) {
    displayVal = strValue[0] + strValue[1] + strValue[2] + '-' + strValue[3] + strValue[4] + '-' + strValue[5];
  } else if (strValue.length > 6) {
    displayVal = strValue[0] + strValue[1] + strValue[2] + '-' + strValue[3] + strValue[4] + '-' + strValue[5] + strValue[6] + (strValue[7] ? strValue[7] : '') + (strValue[8] ? strValue[8] : '');
  }
  return displayVal;
}
