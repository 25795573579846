import { uiCloseModal } from 'libs/xplat/core/src/lib/state/ui/ui.util';
import { delay, MonoTypeOperatorFunction, Observable, pipe, tap } from 'rxjs';

/**
 * Default UX/UI settings for the web
 */
const transitionDelay = 250;
const modalWidth = '500px';
const keyboard = true;

export const uxSettings = {
  // default rxjs transition delays
  flowDelay: delay(transitionDelay),
  // default rxjs modal flow transition handling (ensures any prior modals are closed and transition from close to open is smooth)
  modalRxFlow: () => {
    return pipe(
      tap(() => uiCloseModal()),
      delay(transitionDelay)
    );
  },
  // default modal settings for material
  modalOptions: {
    width: modalWidth,
    keyboard: keyboard
  }
};
