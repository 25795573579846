import { NgModule, Injectable } from '@angular/core';
import { RouterModule, Routes, CanActivate } from '@angular/router';
import { SharedModule } from './features/shared/shared.module';
import { CoreService, UiFacade } from '@vesto/xplat/core';
import { SignUpFacade, UserActions, UserFacade, VestoSelectors } from '@vesto/ngx-vesto/state';
import { WebHelperService } from '@vesto/xplat/web/core';
import { IUser } from '@vesto/vesto';
import { SignInFacade } from '@vesto/xplat/features';
import { appLaunchParams } from '@vesto/xplat/utils';
import { select } from '@ngrx/store';
import { filter, take } from 'rxjs';

@Injectable()
export class HomeGuardService implements CanActivate {
  private user: IUser;
  private isSignedIn;

  constructor(private webHelperService: WebHelperService, private userFacade: UserFacade, private uiFacade: UiFacade, private signInFacade: SignInFacade, private signUpFacade: SignUpFacade) {
    this.userFacade.user$.subscribe((result) => {
      this.user = result;
    });
    CoreService.store.pipe(select(VestoSelectors.authenticated)).subscribe((authenticated) => {
      this.isSignedIn = !!authenticated;
    });
  }

  canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      CoreService.store
        .pipe(
          select(VestoSelectors.initialized),
          filter((init) => !!init),
          take(1)
        )
        .subscribe(() => {
          const launchOptions = this.webHelperService.parseUrlParams();
          if (launchOptions) {
            if (launchOptions.isInviteUser) {
              CoreService.store.dispatch(UserActions.signOut());
              this.signUpFacade.findUserByToken(launchOptions.queryParams[appLaunchParams.inviteUserToken]);
              this.uiFacade.go(['/sign-up']);
              resolve(false);
            } else if (launchOptions.isVerifySignUpEmail) {
              this.uiFacade.go(['/sign-up/verify-email']);
              this.signUpFacade.verifyEmail(launchOptions.queryParams[appLaunchParams.verifySignUpEmailToken]);
              resolve(false);
            } else if (launchOptions.isVerifyChangeEmail) {
              this.uiFacade.go(['/dashboard']);
              this.userFacade.verifyChangeEmail(launchOptions.queryParams[appLaunchParams.verifyChangeEmailToken]);
              resolve(false);
            } else if (launchOptions.isForgotPassword) {
              this.signInFacade.openOrGoToSetPassword(launchOptions.queryParams[appLaunchParams.forgotPasswordToken]);
              resolve(true);
            } else {
              resolve(true);
            }
          } else {
            if (this.isSignedIn) {
              this.uiFacade.go(['/dashboard']);
              resolve(false);
            } else {
              resolve(true);
            }
          }
        });
    });
  }
}

@Injectable()
export class SignUpGuardService implements CanActivate {
  private isSignedIn;

  constructor(private uiFacade: UiFacade) {
    CoreService.store.pipe(select(VestoSelectors.authenticated)).subscribe((authenticated) => {
      this.isSignedIn = !!authenticated;
    });
  }

  canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      CoreService.store
        .pipe(
          select(VestoSelectors.initialized),
          filter((init) => !!init),
          take(1)
        )
        .subscribe(() => {
          if (this.isSignedIn) {
            this.uiFacade.go(['/dashboard']);
            resolve(false);
          } else {
            resolve(true);
          }
        });
    });
  }
}

@Injectable()
export class DashboardGuardService implements CanActivate {
  constructor(private uiFacade: UiFacade) {}

  canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      CoreService.store
        .pipe(
          select(VestoSelectors.initialized),
          filter((init) => !!init),
          take(1)
        )
        .subscribe(() => {
          CoreService.store.pipe(select(VestoSelectors.authenticated), take(1)).subscribe((authenticated) => {
            if (authenticated) {
              CoreService.store.dispatch(UserActions.find());
              resolve(true);
            } else {
              this.uiFacade.go(['/']);
              resolve(false);
            }
          });
        });
    });
  }
}

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./features/home/home.module').then((m) => m.HomeModule),
    canActivate: [HomeGuardService]
  },
  {
    path: 'home',
    loadChildren: () => import('./features/home/home.module').then((m) => m.HomeModule),
    canActivate: [HomeGuardService]
  },
  {
    path: 'home/:section',
    loadChildren: () => import('./features/home/home.module').then((m) => m.HomeModule),
    canActivate: [HomeGuardService]
  },
  {
    path: 'about-us',
    loadChildren: () => import('./features/about-us/about-us.module').then((m) => m.AboutUsModule)
  },
  {
    path: 'compliance',
    loadChildren: () => import('./features/compliance/compliance.module').then((m) => m.ComplianceModule)
  },
  {
    path: 'corporate-treasury',
    loadChildren: () => import('./features/corporate-treasury/corporate-treasury.module').then((m) => m.CorporateTreasuryModule)
  },
  {
    path: 'FDIC-disclosure',
    loadChildren: () => import('./features/fdic-disclosure/fdic-disclosure.module').then((m) => m.FdicDisclosureModule)
  },
  {
    path: 'legal',
    loadChildren: () => import('./features/terms/terms.module').then((m) => m.TermsModule)
  },
  {
    path: 'legal/:section',
    loadChildren: () => import('./features/terms/terms.module').then((m) => m.TermsModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./features/sign-up/sign-up.module').then((m) => m.SignUpModule),
    canActivate: [SignUpGuardService]
  },
  {
    path: 'sign-up/verify-email',
    loadChildren: () => import('./features/sign-up/sign-up.module').then((m) => m.SignUpModule),
    canActivate: [SignUpGuardService]
  },
  {
    path: 'news',
    loadChildren: () => import('./features/blog-page/blog-page.module').then((m) => m.BlogPageModule)
  },
  {
    path: 'webinars',
    loadChildren: () => import('./features/webinars/webinars.module').then((m) => m.WebinarsModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./features/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [DashboardGuardService]
  },
  {
    path: 'reports',
    loadChildren: () => import('./features/reports/reports.module').then((m) => m.ReportsModule)
  },
  {
    path: 'introducing-vesto',
    loadChildren: () => import('./features/white-paper/white-paper.module').then((m) => m.WhitePaperModule)
  },
  // {
  //   path: 'financial-industries-new-markets-overview',
  //   loadChildren: () => import('./features/overview/overview.module').then((m) => m.OverviewModule)
  // },
  // {
  //   path: 'twelve-ways-to-increase-profits-with-vesto',
  //   loadChildren: () => import('./features/twelve-ways/twelve-ways.module').then((m) => m.TwelveWaysModule)
  // },
  // {
  //   path: 'twelve-ways-to-increase-profits-with-vesto/:section',
  //   loadChildren: () => import('./features/twelve-ways/twelve-ways.module').then((m) => m.TwelveWaysModule)
  // },
  {
    path: 'news/international-tech-summit-bermuda-2022-crypto-defi-panel',
    loadChildren: () => import('./features/rummit/rummit.module').then((m) => m.RummitModule)
  },
  {
    path: 'news/vesto-multi-token-protocol-launches-on-mainnet-for-decentalized-financial-services-crypto-linked-offerings',
    loadChildren: () => import('./features/mainnet-press-release/mainnet-press-release.module').then((m) => m.MainnetPressReleaseModule)
  },
  {
    path: 'news/vesto-cofounders-on-dwealth-muse-podcast-how-conventional-banks-are-adapting-to-decentralized-finance',
    loadChildren: () => import('./features/d-wealth-podcast/d-wealth-podcast.module').then((m) => m.DWealthPodcastModule)
  },
  {
    path: 'news/irish-tech-news-podcast-vesto-multi-token-protocol-for-institutional-defi-adoption-christopher-mcgregor',
    loadChildren: () => import('./features/irish-tech-news-podcast/irish-tech-news-podcast.module').then((m) => m.IrishTechNewsPodcastModule)
  },
  {
    path: 'news/aeros-adapts-vesto-white-label-infrastructure-crypto-cashless-transactions-payments-instant-settlement-for-underserved-businesses',
    loadChildren: () => import('./features/aeros-press-release/aeros-press-release.module').then((m) => m.AerosPressReleaseModule)
  },
  {
    path: 'news/roy-coughlan-the-crypto-podcast-and-vesto-co-founders-christopher-mcgregor-wolfgang-decker-discuss-vestos-crypto-journey-and-multi-token-protocol-for-decentralized-services',
    loadChildren: () => import('./features/tom-coughlan/tom-coughlan.module').then((m) => m.TomCoughlanModule)
  },
  {
    path: 'news/vesto-partners-with-latamex-to-add-fiat-crypto-on-off-ramp-into-latam',
    loadChildren: () => import('./features/latamex-press-release/latamex-press-release.module').then((m) => m.LatamexPressReleaseModule)
  },
  {
    path: 'news/vesto-partners-with-united-cities-un-for-remittance-cross-border-payments-for-smart-cities-residents',
    loadChildren: () => import('./features/ucna-press-release/ucna-press-release.module').then((m) => m.UcnaPressReleaseModule)
  },
  {
    path: 'news/crian-padayachee-and-vesto-ceo-christopher-mcgregor-discuss-payment-solutions-remittance-for-unbanked-fiat-and-empowerment',
    loadChildren: () => import('./features/amun-podcast/amun-podcast.module').then((m) => m.AmunPodcastModule)
  },
  {
    path: 'news/vesto-welcomes-nitin-gaur-to-advisor-team-for-global-compliance-regulation-partnerships',
    loadChildren: () => import('./features/nitin/nitin.module').then((m) => m.NitinModule)
  },
  {
    path: 'news/vesto-quantum-miami-pitch-competition-round-one-with-christopher-mcgregor-and-wolfgang-decker',
    loadChildren: () => import('./features/miami/miami.module').then((m) => m.MiamiModule)
  },
  {
    path: 'news/vesto-co-founders-christopher-mcgregor-and-wolfgang-decker-join-saevon-springer-to-discuss-vestos-digital-asset-infrastructure',
    loadChildren: () => import('./features/native-assets/native-assets.module').then((m) => m.NativeAssetsModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./features/page-error/page-error.module').then((m) => m.PageErrorModule)
  },
  {
    path: '404',
    loadChildren: () => import('./features/page-error/page-error.module').then((m) => m.PageErrorModule)
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  providers: [DashboardGuardService, HomeGuardService, SignUpGuardService]
})
export class AppRoutingModule {}
