import { IGoogle } from './google.interface';
import { IOnfido } from './onfido.interface';
import { IPrimeTrust } from './primetrust.interface';
import { UserStatus } from '../enums/user-status.enum';
import { IRole } from './role.interface';
import { IMerchant } from './merchant.interface';

export interface IUser {
  id?: string;
  institutionId?: string;
  merchant?: IMerchant;
  defaultAccountId?: string;
  google?: IGoogle;
  onfido?: IOnfido;
  primeTrust?: IPrimeTrust;
  status?: UserStatus;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email?: string;
  mobile?: string;
  mobileLast4?: string;
  address?: string;
  dob?: string;
  dailyBuyLimit?: number;
  dailySellLimit?: number;
  dailyTransferLimit?: number;
  dailyReceiveLimit?: number;
  updated?: string;
  created?: string;
  // Joins.
  roles?: IRole[];
  // Transients
  ssn?: string;
  institutionName?: string;
}
