import {Component, ViewChild} from "@angular/core";
import {AdditionalUsersModalBaseComponent, FeatureService} from "@vesto/xplat/features";
import {MatMenuTrigger} from "@angular/material/menu";
import {uiCloseModal} from "@vesto/xplat/core";

@Component({
  selector: 'gbl-additional-users-modal',
  templateUrl: 'additional-user-modal.component.html'
})

export class AdditionalUserModalComponent extends AdditionalUsersModalBaseComponent {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  closeMenu() {
    this.trigger.closeMenu();
  }

  openAddUser() {
    uiCloseModal();
    FeatureService.dashboardFacade.openOrGoToAddUser();
  }
}
