import { Component } from '@angular/core';
import { DashboardFacade, UserStatusBaseComponent } from '@vesto/xplat/features';

@Component({
  selector: 'gbl-user-status',
  templateUrl: 'user-status.component.html'
})
export class UserStatusComponent extends UserStatusBaseComponent {
  constructor(protected dashboardFacade: DashboardFacade) {
    super(dashboardFacade);
  }
}
