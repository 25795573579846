<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="flex items-center">
    <h3 class="section-number mr-2"><i class="fal fa-store-alt"></i></h3>
    <div>
      <h3 class="section-number">{{ action === 'create' ? 'Create Merchant' : 'Change Settings'}}</h3>
      <span class="text-sm" *ngIf="merchant">{{ merchant.name }}</span>
    </div>
  </div>
</div>
<mat-dialog-content>
  <ng-container [ngSwitch]="step">
    <form [formGroup]="formGroup" (ngSubmit)="submit()" *ngSwitchCase="'FORM_GROUP'">
      <div class="vesto-input vesto-input-inline mb-4">
        <label class="inline" for="name">Merchant Name</label>
        <input type="text" focusMe="true" formControlName="name" id="name" name="name" />
      </div>
      <div class="vesto-input vesto-input-inline my-4">
        <label for="address">Address</label>
        <input type="text" id="address" ngx-google-places-autocomplete #placesRef="ngx-places" (onAddressChange)="addressChange($event)" placeholder="Enter your address..." name="address" formControlName="address" />
      </div>

      <p class="text-sm font-bold">
        Fees
      </p>

      <section class="grid grid-cols-2 gap-3 my-4 align-items-center">
        <div class="vesto-input">
          <ng-select formControlName="feeType" id="feeType" name="feeType">
            <ng-option [value]="MerchantFeeType.FIXED">
              Fixed
            </ng-option>
            <ng-option [value]="MerchantFeeType.PERCENTAGE">
              Percentage
            </ng-option>
            <ng-option [value]="MerchantFeeType.BOTH">
              Both
            </ng-option>
          </ng-select>
        </div>

        <div class="vesto-input vesto-input-inline" [hidden]="formGroup.controls.feeType.value === MerchantFeeType.PERCENTAGE">
          <label>Fee</label>
          <gbl-input class="currency-input" mask="currency" formControlName="feeFixed"></gbl-input>
        </div>

        <div class="vesto-input vesto-input-inline"  [hidden]="formGroup.controls.feeType.value === MerchantFeeType.FIXED">
          <label>Fee</label>
          <gbl-input class="currency-input" mask="percent" formControlName="feePercent"></gbl-input>
        </div>
      </section>
      <div *ngIf="action === 'create'" class="vesto-input vesto-input-inline mb-4">
        <label class="inline" for="email">Merchant Invite Email</label>
        <input type="email" formControlName="email" id="email" name="email" />
      </div>

      <div *ngIf="action === 'create'" class="p-2 bg-trans-blue rounded-lg text-sm">
        An invite to sign up as a merchant will be sent to the email entered above. Please instruct the invitee to accept the invite and sign up using the Aeros mobile app
      </div>

      <gbl-alert [type]="error" [message]="error"></gbl-alert>

      <div class="text-right mt-3">
        <button class="button button-primary" type="submit">{{ action === 'create'? 'Create' : 'Update' }}</button>
      </div>
    </form>

    <section *ngSwitchCase="'SUCCESS'">
      <gbl-lottie-player></gbl-lottie-player>
    </section>

    <section *ngSwitchCase="'BUSY'" class="mt-4">
      <div class="placeholder-long bg-flash"></div>
      <div class="placeholder-area bg-flash mt-4"></div>
    </section>

    <section *ngSwitchCase="'DETAIL'" class="slide-in">
      <div class="text-center">
        <p class="mt-4">Success!</p>
      </div>
      <div class="p-3 mt-4 bg-trans-blue rounded-lg">
        <p class="text-sm" *ngIf="action === 'create'; else defaultTemplate">Created merchant "{{ merchantName }}"</p>
        <ng-template #defaultTemplate>
          <p class="text-sm">Updated "{{ merchant.name }}"</p>
        </ng-template>
      </div>
      <button class="button button-primary my-4 mx-auto w-full" (click)="close()">Close</button>
    </section>
  </ng-container>
</mat-dialog-content>
