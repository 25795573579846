<mat-dialog-content>
  <p *ngIf="message" [innerHTML]="message"></p>
  <p *ngIf="html" [innerHTML]="html"></p>

  <div fxLayout="row">
    <div class="mt-10 text-right mb-1" *ngIf="!actions">
      <button mat-button class="button button-primary" (click)="close()">Ok</button>
    </div>

    <div class="mt-10 text-right mb-1" *ngIf="actions">
      <button *ngFor="let btn of actions" mat-button [class]="btn.class ? btn.class + ' ml-2' : 'button button-primary'" (click)="doAction(btn)">{{ btn.button_label }}</button>
    </div>
  </div>
</mat-dialog-content>
