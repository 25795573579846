import { ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LogService } from './log.service';
import { AnalyticsService } from './analytics.service';
import { AuthHttpInterceptorService } from './auth-http-interceptor.service';
import { ConfigService } from './config.service';
import { LocaleService } from './locale.service';
import { EventBusService } from './eventbus.service';
import { WindowService } from './window.service';
import { OnfidoService } from './onfido.service';
import { NetworkService } from './network.service';
import { StorageService } from './storage.service';
import { ContentService } from './content.service';
import { CoreServices } from './core.service';
import { GlobalErrorHandler } from './error-handler.service';
import { ModalContextToken } from './tokens';
import { FormService } from './form.service';
import { StompService } from "./stomp.service";

export const CORE_PROVIDERS: any[] = [
  CoreServices,
  LogService,
  AnalyticsService,
  ConfigService,
  LocaleService,
  EventBusService,
  AuthHttpInterceptorService,
  WindowService,
  NetworkService,
  StorageService,
  OnfidoService,
  ContentService,
  StompService,
  FormService,
  { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptorService, multi: true },
  {
    provide: ErrorHandler,
    useClass: GlobalErrorHandler
  },
  { provide: ModalContextToken, useValue: {} }
];

export * from './auth-http-interceptor.service';
export * from './log.service';
export * from './config.service';
export * from './window.service';
export * from './eventbus.service';
export * from './tokens';
export * from './storage.service';
export * from './locale.service';
export * from './network.service';
export * from './onfido.service';
export * from './content.service';
export * from './progress.service';
export * from './form.service';
export * from './stomp.service';
export * from './core.service';
