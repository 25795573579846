export const enum Symbol {
  AUSDC = 'aUSDC',
  ETH = 'ETH',
  VUSDC = 'vUSDC',
  VDAI = 'vDAI',
  DAI = 'DAI',
  PAX = 'PAX',
  AEROS = 'AEROS',
  YVUSDC = 'yvUSDC',
  VSUSD = 'VSUSD',
  VYUSD = 'VYUSD'
}
