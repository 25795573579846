import { Directive, OnInit } from '@angular/core';
import { BaseComponent, CoreService, UiActions, uiCloseModal } from '@vesto/xplat/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { strongPasswordPattern } from '@vesto/xplat/utils';
import {take, takeUntil} from 'rxjs/operators';
import {ofType} from "@ngrx/effects";
import {UserActions} from "@vesto/ngx-vesto/state";

@Directive()
export abstract class ChangePasswordModalBaseComponent extends BaseComponent {
  step: 'FORM_GROUP' | 'TWO_FACTOR' | 'BUSY' | 'SUCCESS' | 'DETAIL' = 'FORM_GROUP';
  formGroup: UntypedFormGroup;
  error: string;

  constructor() {
    super();
    this.formGroup = CoreService.formBuilder.group(
      {
        oldPassword: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        newPassword: [
          null,
          Validators.compose([
            Validators.required,
            Validators.nullValidator,
            Validators.minLength(8),
            (control: UntypedFormControl) => {
              return !control.root.get('newPassword') || (control.root.get('newPassword') && strongPasswordPattern.test(control.root.get('newPassword').value)) ? null : { invalid: true };
            }
          ])
        ]
      },
      { updateOn: 'blur' }
    );
  }

  sendSmsCode(isResendSms: boolean) {
    CoreService.userFacade.sendSmsCode();

    if (isResendSms) {
      CoreService.uiFacade.notification(`SMS code sent!`);
    }
  }

  changePassword() {
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }

    this.step = 'BUSY';
    const value = this.formGroup.value;
    CoreService.userFacade.changePassword(value.oldPassword, value.newPassword); //TODO: Pass code

    CoreService.actions$.pipe(ofType(UserActions.changePasswordSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.step = 'SUCCESS';
      CoreService.windowService.setTimeout(() => {
        this.step = 'DETAIL';
        CoreService.store.dispatch(UserActions.signOut());
      }, 1600);
    });

    CoreService.actions$.pipe(ofType(UserActions.changePasswordFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      UiActions.hideSpinner();
      this.error = `apiErrors.${result.apiError.error}`;
    });
  }

  close() {
    uiCloseModal();
  }
}
