import { IEnvironment } from '../interfaces/environment.interface';
import { IHttpClient } from '../interfaces/http.interface';
import { BaseApi } from './base-api';
import { IBankAccount } from '../interfaces/bank-account.interface';

export class BankAccountsApi extends BaseApi {
  constructor(protected environment: IEnvironment, protected httpClient: IHttpClient) {
    super(environment);
  }

  createLinkToken(userId: string) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/bank-accounts/create-link-token/${userId}`), null, { headers: this.getHeaders() });
  }

  findByUser(userId: string) {
    return this.httpClient.get(this.formatUrl(`/v${this.environment.vesto.version}/bank-accounts/find-by-user/${userId}`), { headers: this.getHeaders() });
  }

  create(
    userId: string,
    bankAccount: IBankAccount
  ) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/bank-accounts/create/${userId}`), bankAccount, { headers: this.getHeaders() });
  }

  setDefault(
    id: string
  ) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/bank-accounts/set-default/${id}`),
      { headers: this.getHeaders() }
    );
  }

  remove(
    id: string
  ) {
    return this.httpClient.delete(
      this.formatUrl(`/v${this.environment.vesto.version}/bank-accounts/remove/${id}`),
      { headers: this.getHeaders() }
    );
  }
}
