import {Blockchain, IGasTank, IInstitution} from '@vesto/vesto';
import {InstitutionActions, VestoSelectors, VestoState} from '@vesto/ngx-vesto/state';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {GasTankActions} from './gas-tank.actions';

@Injectable({
  providedIn: 'root'
})
export class GasTankFacade {
  gasTanks$: Observable<IGasTank[]> = this.store.pipe(select(VestoSelectors.gasTanks));

  constructor(private store: Store<VestoState.IState>) {
  }

  set(
    gasTank: IGasTank
  ) {
    this.store.dispatch(GasTankActions.set({gasTank}));
  }

  findActive() {
    this.store.dispatch(GasTankActions.findActive());
  }

  changeSettings(
    gasTankId: string,
    blockchain: Blockchain,
    minPrice: number,
    maxPrice: number,
    limit: number
  ) {
    this.store.dispatch(GasTankActions.changeSettings({gasTankId, blockchain, minPrice, maxPrice, limit}));
  }
}

