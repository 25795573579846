/**
 * Web & Mobile
 * various query param strings which can be used to control UX on app boot or website load
 * whether used for web url's or app launch arguments via things like branch.io, push notifications or other
 */
export const enum appLaunchParams {
  inviteUserToken = 'invite-user-token',
  verifySignUpEmailToken = 'verify-sign-up-email-token',
  verifyChangeEmailToken = 'verify-change-email-token',
  forgotPasswordToken = 'forgot-password-token'
}
