import { createAction, props } from '@ngrx/store';
import { IApiError } from '@vesto/vesto';

export namespace MarketDataActions {
  export const getDeFi = createAction('[@vesto/market-data] getDeFi');

  export const getDeFiSuccess = createAction('[@vesto/market-data] getDeFiSuccess', props<{ data: any }>());

  export const getDeFiFailure = createAction('[@vesto/market-data] getDeFiFailure', props<{ apiError: IApiError }>());

  export const getDeFiHistory = createAction('[@vesto/market-data] getDeFiHistory', props<{ period: string }>());

  export const getDeFiHistorySuccess = createAction('[@vesto/market-data] getDeFiHistorySuccess', props<{ histories: any[] }>());

  export const getDeFiHistoryFailure = createAction('[@vesto/market-data] getDeFiHistoryFailure', props<{ apiError: IApiError }>());
}
