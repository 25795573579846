import { Pipe, PipeTransform } from '@angular/core';
import { IAccount, ITransaction, TransactionType } from '@vesto/vesto';
import { TitleCasePipe } from '@angular/common';
import { capitalize } from '@vesto/xplat/utils';

@Pipe({
  name: 'transactionTarget',
  pure: true
})
export class TransactionTargetPipe implements PipeTransform {
  constructor(private titleCasePipe: TitleCasePipe) {}
  transform(transaction: ITransaction, selectedAccount: IAccount): any {
    if (!transaction || !selectedAccount) {
      return '';
    }
    switch (transaction.type) {
      case TransactionType.TRANSFER:
      case TransactionType.PAYMENT:
        if (transaction.accountId === selectedAccount.id && transaction.recipientAccountId) {
          if (transaction?.recipientUser?.merchant) {
            return `Paid ${transaction?.recipientUser?.merchant?.name ?? 'merchant'}`;
          }
          if (selectedAccount.shared === true || (selectedAccount.additionalUsers && selectedAccount.additionalUsers.length > 0)) {
            return `To ${transaction.recipientUser.firstName} ${transaction.recipientUser.lastName} by ${transaction.user.firstName} ${transaction.user.lastName}`;
          } else {
            return `To ${transaction.recipientUser.firstName} ${transaction.recipientUser.lastName}`;
          }
        } else if (transaction.accountId === selectedAccount.id && !transaction.recipientAccountId) {
          return `To ${transaction.recipientAddress}`;
        } else {
          return `From ${transaction.user.firstName} ${transaction.user.lastName}`;
        }
      case TransactionType.BUY:
      case TransactionType.SELL:
        if (selectedAccount.shared === true || (selectedAccount.additionalUsers && selectedAccount.additionalUsers.length > 0)) {
          return `Using ${transaction.bankAccount.institution} ${this.titleCasePipe.transform(transaction.bankAccount.type)} ******${transaction.bankAccount.mask} by ${transaction.user.firstName} ${transaction.user.lastName}`;
        }
        return `Using ${transaction.bankAccount.institution} ${this.titleCasePipe.transform(transaction.bankAccount.type)} ******${transaction.bankAccount.mask}`;
      case TransactionType.INTEREST_PAYMENT:
        return 'From Vesto';
      case TransactionType.FEE:
        return !transaction.referenceTransaction ? '' : `Transaction initiated by ${transaction.referenceTransaction.user.firstName} ${transaction.referenceTransaction.user.lastName}`;
      case TransactionType.ADD_ADDITIONAL_USER:
        return `Added ${transaction.recipientUser.firstName} ${transaction.recipientUser.lastName}`;
      case TransactionType.REMOVE_ADDITIONAL_USER:
        return `Removed ${transaction.recipientUser.firstName} ${transaction.recipientUser.lastName}`;
      case TransactionType.ADD_USER:
        return `Added ${transaction.recipientUser?.firstName} ${transaction.recipientUser?.lastName}`;
      case TransactionType.TIP:
        return `From ${transaction.user.firstName} ${transaction.user.lastName}`;
      case TransactionType.MINT:
      case TransactionType.SYNC:
      case TransactionType.DEPLOY_VWALLET:
      default:
        return '';
    }
  }
}
