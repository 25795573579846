import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IEnvironment, SignUpApi, IThreadHandler } from '@vesto/vesto';
import { EnvironmentToken, ThreadHandlerToken } from './tokens';

@Injectable({
  providedIn: 'root'
})
export class SignUpApiService extends SignUpApi {
  constructor(@Inject(EnvironmentToken) protected environment: IEnvironment, protected httpClient: HttpClient, @Optional() @Inject(ThreadHandlerToken) protected threadHandler: IThreadHandler) {
    super(environment, httpClient, threadHandler);
  }
}
