<main class="accounts">
  <section *ngIf="selectedSection !== Section.GLOBAL_TRANSACTIONS" class="flex mb-3 items-center">
    <div *ngIf="selectedAccount?.assets.length !== 0" class="filter-button filter-button-no-meta flex items-center mr-3" (click)="selectSymbol('all')" [class.selected]="selectedSymbol === 'all'"><i class="fal fa-coins mr-2" style="font-size: 20px"></i> <span class="text-sm">All</span></div>
    <div *ngFor="let asset of selectedAccount?.assets" class="filter-button mr-3 flex items-center text-sm" (click)="selectSymbol(asset.symbol)" [class.selected]="asset.symbol === selectedSymbol">
      <div class="mr-3 flex items-center"><img [src]="asset.symbol | symbolIcon" class="w-8 mr-2" />{{ asset.symbol }}</div>
      <div *ngIf="asset.apy > 0">
        <b>{{ asset.apy | percent: '1.2' }} </b>APY
      </div>
    </div>

    <!--<div class="vesto-input vesto-select mt-4 mb-2"> TODO: Select instead of filter buttons?
      <ng-select id="symbol" name="symbol">
        <ng-option>
          <span (click)="selectSymbol('all')">All Transactions</span>
        </ng-option>
        <ng-option *ngFor="let asset of selectedAccount?.assets" (click)="selectSymbol(asset.symbol)">
          <div class="flex">
            <div class="flex-grow">{{ asset?.symbol }} &nbsp;</div>
            <div *ngIf="asset.apy > 0">
              <b>{{ asset.apy | percent: '1.2' }} </b>APY
            </div>
          </div>
        </ng-option>
      </ng-select>
    </div>-->

    <ng-container *ngIf="selectedAccount?.type === AccountType.VTOKEN || selectedAccount?.type === AccountType.VDEFI">
      <button *ngIf="accountHeader === AccountHeader.CONTRACT" class="circle-icon-button text-vesto-primary" matTooltip="Switch to Token" (click)="selectAccountHeader(AccountHeader.TOKEN)">
        <i class="fal fa-coins fa-fw"></i>
      </button>
      <button *ngIf="accountHeader === AccountHeader.TOKEN" class="circle-icon-button text-vesto-primary" matTooltip="Switch to Contract" (click)="selectAccountHeader(AccountHeader.CONTRACT)">
        <i class="fal fa-file-contract fa-fw"></i>
      </button>
    </ng-container>

    <ng-container *ngIf="selectedAccount?.type === AccountType.GAS_TANK">
      <div class="text-sm text-white">
        <button *ngIf="accountHeader === AccountHeader.ETHEREUM" class="circle-icon-button text-vesto-primary" matTooltip="Switch to Polygon" (click)="selectAccountHeader(AccountHeader.POLYGON)">
          <img src="./assets/icons/ethereum-icon-blue.svg" class="w-4" />
        </button>
        <button *ngIf="accountHeader === AccountHeader.POLYGON" class="circle-icon-button text-vesto-primary" matTooltip="Switch to Ethereum" (click)="selectAccountHeader(AccountHeader.ETHEREUM)">
          <img src="./assets/icons/polygon-icon-blue.svg" class="w-6" />
        </button>
      </div>
    </ng-container>

    <div class="flex-grow"></div>
    <ng-container *ngIf="selectedAccount?.type === AccountType.VTOKEN">
      <button class="circle-icon-button text-vesto-primary ml-3" matTooltip="Mint" (click)="mint()">
        <i class="fal fa-coins fa-fw"></i>
      </button>
    </ng-container>

    <ng-container *ngIf="selectedAccount?.type === AccountType.VUSER && UserUtil.hasRole(user, RoleType.SUPER_ADMIN)">
      <button class="circle-icon-button ml-3 text-vesto-primary" matTooltip="Set Daily Lmits" (click)="openSetDailyLimits()">
        <i class="fal fa-sliders-v fa-fw"></i>
      </button>
      <button class="circle-icon-button ml-3 text-vesto-primary" matTooltip="Change User Role" (click)="openChangeRole()">
        <i class="fal fa-user-edit fa-fw"></i>
      </button>
      <button class="circle-icon-button ml-3 text-vesto-primary" matTooltip="Swap Signing Address" (click)="openSwapSigningAddress()">
        <i class="fal fa-exchange fa-fw"></i>
      </button>
    </ng-container>

    <ng-container *ngIf="selectedAccount?.type === AccountType.VSWAP">
      <button class="circle-icon-button small ml-3 text-vesto-primary" matTooltip="Sync APYs" (click)="syncApys()">
        <i class="fal fa-sync-alt fa-fw text-xl"></i>
      </button>
    </ng-container>

    <ng-container *ngIf="selectedAccount?.type === AccountType.VLIGHTHOUSE">
      <button class="circle-icon-button small ml-3 text-vesto-primary" matTooltip="Sync Implementation Addresses" (click)="openSyncImplementationAddresses()">
        <i class="fal fa-sync-alt fa-fw text-xl"></i>
      </button>
      <button class="circle-icon-button small ml-3 text-vesto-primary" matTooltip="Sync Addresses" (click)="openSyncAddresses()">
        <i class="fal fa-sync-alt fa-fw text-xl"></i>
      </button>
      <button class="circle-icon-button small ml-3 text-vesto-primary" matTooltip="Swap VSigning Address" (click)="swapVSigningAddress()">
        <i class="fal fa-file-contract fa-fw text-xl"></i>
      </button>
    </ng-container>

    <ng-container *ngIf="selectedAccount?.type === AccountType.VDEFI">
      <button class="circle-icon-button small ml-3 text-vesto-primary" matTooltip="Add" (click)="openAddYearnVault()">
        <i class="fal fa-plus-circle fa-fw text-xl"></i>
      </button>
    </ng-container>

    <ng-container *ngIf="selectedAccount?.id === institution?.operationalAccount?.id">
      <button class="circle-icon-button small ml-3 text-vesto-primary" matTooltip="Set Settle Limits" (click)="openSetSettleLimits()">
        <i class="fal fa-sliders-v fa-fw text-xl"></i>
      </button>
    </ng-container>

    <ng-container *ngIf="selectedAccount?.type === AccountType.FIAT">
      <button class="circle-icon-button small ml-3 text-vesto-primary" matTooltip="Fiat Balances" (click)="openFiatbalances()">
        <i class="fal fa-money-bill fa-fw text-xl"></i>
      </button>
    </ng-container>

    <ng-container *ngIf="selectedAccount?.type === AccountType.WALLET || selectedAccount?.type === AccountType.OPERATIONAL || selectedAccount?.type === AccountType.PAYOUT">
      <div *ngFor="let contract of selectedAccount.contracts">
        <div class="circle-icon-button ml-3" matTooltip="View on Polygon">
          <a href="{{ contract.url }}" target="_blank">
            <img [src]="contract.blockchain | blockchain" style="width:22px" class="-mt-1" />
          </a>
        </div>
      </div>
    </ng-container>

    <div class="circle-icon-button ml-3" (click)="refresh()" matTooltip="Refresh">
      <i class="fal fa-redo cursor-pointer fa-fw"></i>
    </div>
  </section>

  <section *ngIf="UserUtil.hasRole(user, RoleType.SUPER_ADMIN) && selectedSection === Section.GLOBAL_TRANSACTIONS" class="flex mb-3 p-3 -m-4 rounded-t-lg items-center">
    <div class="rounded-60 bg-grey-light border-grey-light border-4 grid grid-cols-2 text-sm relative cursor-pointer" *ngIf="UserUtil.hasRole(user, RoleType.ADMIN) || UserUtil.hasRole(user, RoleType.SUPER_ADMIN) || UserUtil.hasRole(user, RoleType.AUDITOR) || UserUtil.hasRole(user, RoleType.MERCHANT)">
      <div class="text-center p-2 relative z-10 transition-all uppercase text-xs" [ngClass]="showAllTransactions ? 'text-grey-dark' : 'text-white'" (click)="setShowAllTransactions(false)">
        Requires Action
      </div>
      <div class="text-center p-2 relative z-10 transition-all uppercase text-xs" [ngClass]="showAllTransactions ? 'text-white' : 'text-grey-dark'" (click)="setShowAllTransactions(true)">
        All Transactions
      </div>
      <div class="absolute w-1/2 h-full bg-black rounded-60 transition-all" [style.left]="showAllTransactions ? '50%' : '0'"></div>
    </div>

    <form [formGroup]="formGroup" class="flex ml-4" [hidden]="!showAllTransactions">
      <div class="vesto-input vesto-select vesto-select-small vesto-select-small-placeholder mr-4 bg-white rounded-lg">
        <ng-select formControlName="transactionType" placeholder="Transaction Type">
          <ng-option *ngFor="let type of transactionTypes" [value]="TransactionType[type]">
            {{ type  | replace : '_' : ' ' | titlecase }}
          </ng-option>
        </ng-select>
      </div>

      <div class="vesto-input vesto-select vesto-select-small vesto-select-small-placeholder bg-white rounded-lg">
        <ng-select formControlName="transactionStatus" placeholder="Transaction Status">
          <ng-option *ngFor="let status of transactionStatuses" [value]="TransactionStatus[status]">
            {{ status  | replace : '_' : ' ' | titlecase }}
          </ng-option>
        </ng-select>
      </div>
    </form>

    <div class="flex-grow"></div>

    <div class="circle-icon-button ml-3" (click)="refreshGlobal()" matTooltip="Refresh">
      <i class="fal fa-redo cursor-pointer fa-fw"></i>
    </div>
  </section>

  <ng-container *ngIf="selectedAccount?.status !== AccountStatus.DEPLOYING_WALLET; else pendingTemplate">
    <section style="height: calc(100vh - {{ height }}); overflow: hidden auto; position: relative" *ngIf="transactions && transactions.length > 0; else noTransactionsTemplate">
      <div *ngFor="let transaction of transactions" class="transaction-item flex items-center cursor-pointer" (click)="openDetail(transaction)">
        <!-- DATE -->
        <div class="text-center mr-4">
          <span class="uppercase">{{ transaction.created | date: 'MMM' }}</span
          ><br />
          {{ transaction.created | date: 'dd' }}<br />
        </div>

        <!-- ICON -->
        <div class="mr-4">
          <i *ngIf="transaction.status !== TransactionStatus.SUCCESS && transaction.status !== TransactionStatus.ERROR; else transactionIconTemplate" class="fa fa-spinner fa-pulse"></i>
          <ng-template #transactionIconTemplate>
            <img *ngIf="transaction.type === TransactionType.TRANSFER && transaction.recipientAccountId == selectedAccount?.id; else iconTypeTemplate" src="./assets/icons/receive.png" class="w-6" />
            <ng-template #iconTypeTemplate>
              <i [class]="transaction | transactionIcon: selectedAccount"></i>
            </ng-template>
            <i class="" *ngIf="transaction.type === TransactionType.TRANSFER && transaction?.accountId == selectedAccount?.id"></i>
          </ng-template>
        </div>

        <!-- DETAILS -->
        <div>
          <span class="font-bold text-lg block">{{ transaction | transactionText: selectedAccount }}</span>
          <span class="text-xs font-bold">{{ transaction | transactionTarget: selectedAccount }}</span>
        </div>

        <!-- AMOUNT -->
        <div class="flex-1 text-right" *ngIf="transaction.status !== TransactionStatus.ERROR; else errorTemplate">
          <ng-container *ngIf="transaction.status === TransactionStatus.PENDING && transaction.requiredSignatures > transaction?.signatures.length && !UserUtil.hasSigned(user, transaction)">
            <button class="circle-icon-button small ml-3 text-vesto-primary" matTooltip="Sign Transaction" (click)="sign($event, transaction)">
              <i class="fal fa-file-signature fa-fw text-xl"></i>
            </button>
          </ng-container>
          <ng-container *ngIf="transaction.type !== TransactionType.ADD_ADDITIONAL_USER && transaction.type !== TransactionType.REMOVE_ADDITIONAL_USER && transaction.type !== TransactionType.SET_SETTLE_LIMITS && transaction.type !== TransactionType.ADD_USER && selectedAccount.type !== AccountType.VLIGHTHOUSE && transaction.type !== TransactionType.DEPLOY_VWALLET  && transaction.type !== TransactionType.SWAP_SIGNING_ADDRESSES  && transaction.type !== TransactionType.SYNC_PRICE_PER_TOKENS && transaction.type !== TransactionType.DEPLOY_VBRIDGE && transaction.type !== TransactionType.DEPLOY_VDEFI && transaction.type !== TransactionType.DEPLOY_YEARN_VAULT && transaction.type !== TransactionType.DEPLOY_VSWAP && transaction.type !== TransactionType.DEPLOY_VERC20_PROXY && transaction.type !== TransactionType.DEPLOY_VERC20 && transaction.type !== TransactionType.DEPLOY_VUSER && transaction.type !== TransactionType.DEPLOY_VWALLET_PROXY  && transaction.type !== TransactionType.SET_DAILY_LIMITS && transaction.type !== TransactionType.CHANGE_ROLE && transaction.type !== TransactionType.SET_UNDERLYING_SUPPLY_LIMIT"> <!-- TODO: clean this mess up -->
            <span>{{ transaction.type === TransactionType.SELL || (transaction.type === TransactionType.TRANSFER && transaction.accountId === selectedAccount?.id) ? '-' : '+' }}</span>
            <span class="font-bold text-lg"> ${{ transaction.fiatAmount.toFixed(2) }}</span
            ><br />
            <span *ngIf="transaction.token" class="text-lg">{{ transaction.amount.toFixed(6) }} {{ transaction.token.symbol }}</span>
          </ng-container>
        </div>
        <ng-template #errorTemplate>
          <div class="flex-1 text-right">
            <i [class]="transaction.status === TransactionStatus.ERROR ? 'fal fa-exclamation-circle text-2xl' : 'fal fa-clock text-2xl'" [style.color]="transaction.status === TransactionStatus.ERROR ? '#842029' : ''"></i>
          </div>
        </ng-template>
      </div>
    </section>

    <ng-template #noTransactionsTemplate>
      <section style="height: calc(100vh - {{ height }}); overflow: hidden auto; position: relative" class="text-center pt-4 relative flex flex-col justify-center items-center" *ngIf="transactions && !transactions.length">
        <span class="fa-stack fa-2x text-xl">
          <i class="far fa-search fa-stack-2x"></i>
          <i class="fas fa-question fa-stack-1x" style="margin-left: -5px; margin-top: -4px"></i>
        </span>
        <h4 class="mt-3">No transactions found.</h4>
        <span class="text-sm">Buy digital currency to get started!</span>
        <br /><br />
        <button class="button button-primary button-sm" (click)="buySell()" [disabled]="user?.status === UserStatus.KYC_CONSIDER">Buy</button>
      </section>
    </ng-template>

    <gbl-paginator></gbl-paginator>
  </ng-container>

  <ng-template #pendingTemplate>
    <section class="text-center p-4 flex flex-col justify-center items-center" style="height: calc(100vh - {{ height }}); overflow: hidden auto; position: relative">
      <i class="fa fa-spinner fa-pulse text-4xl mb-3"></i>
      <h4>Please wait while we finish setting up your account.</h4>
    </section>
  </ng-template>
</main>
