import { IUser } from './user.interface';
import { AdditionalUserStatus } from '../enums/additional-user-status.enum';

export interface IAdditionalUser {
  id: string;
  user: IUser;
  token: string;
  status: AdditionalUserStatus;
  email: string;
  suspended: string;
  reactivated: string;
  updated: string;
  created: string;
}
