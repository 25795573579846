import { IEnvironment } from '@vesto/vesto';
import { deepMerge } from '@vesto/xplat/utils';
import { environmentBase } from './environment.base';

export const environmentChris = deepMerge(environmentBase, <IEnvironment>{
  production: false,
  enableAnalytics: false,
  name: 'local',
  vesto: {
    url: 'https://api-chris.vesto.io',
    wsUrl: 'wss://api-chris.vesto.io/ws',
    version: 1,
    institutionId: 'd9472c8c-f24e-48df-965c-fcb2113cb77a'
  },
  enableSignIn: true
});
