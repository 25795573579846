<div class="mb-3 text-right">
  <a class="text-sm underline" (click)="openInvite()"><i class="fal fa-plus-circle"></i> Invite User</a>
</div>
<div class="bg-white shadowed rounded p-3">
  <ng-container *ngIf="users.length > 1 else noUsersTemplate">
    <div *ngFor="let merchantUser of users; let i = index" class="flex bg-white mt-2 p-2 justify-between items-center">
      <ng-container *ngIf="user.id !== merchantUser.id">
        <div class="flex-grow">
          <span class="text-sm font-bold block">{{ merchantUser.firstName }} {{ merchantUser.lastName }}</span>
          <span class="text-xs">{{ merchantUser.email }}</span>
        </div>
        <div *ngIf="UserUtil.hasRole(merchantUser, RoleType.MERCHANT)"><i class="fal fa-store-alt"></i></div>
        <div *ngIf="!UserUtil.hasRole(merchantUser, RoleType.MERCHANT)"><i class="fal fa-times text-red-dark" (click)="removeUser(merchantUser.id)"></i></div>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #noUsersTemplate>
    <div class="text-xl-center">
      <h4>No users.</h4>
      <a class="text-sm underline" (click)="openInvite()"><i class="fal fa-plus-circle"></i> Invite User</a>
    </div>
  </ng-template>
</div>
