import { IEnvironment } from '../interfaces/environment.interface';
import { IHttpClient } from '../interfaces/http.interface';

export abstract class BaseApi {
  protected environment: IEnvironment;
  protected jwt: string;
  protected httpClient: IHttpClient;
  private _defaultHeaders = {
    'Content-Type': 'application/json'
  };

  constructor(env: IEnvironment) {
    this.environment = env;
  }

  protected formatUrl(path: string): string {
    const url = this.environment.vesto.url.endsWith('/') ? this.environment.vesto.url.substring(0, this.environment.vesto.url.length - 1) : this.environment.vesto.url;
    return `${url}${path}`;
  }

  protected getHeaders() {
    return this._defaultHeaders;
  }
}
