<div class="flex flex-wrap flex-row article-snippet justify-around">
  <ng-container *ngFor="let article of displayedArticles">
    <div class="snippet-container relative flex-1 m-2 p-4 rounded-lg" (click)="viewArticle(article.url)">
      <div class="justify-between flex-col">
        <div class="flex items-center mb-5">
          <span class="text-xs text-lilac font-bold uppercase flex-1">{{ article.category }}</span>
          <span class="text-xs text-lilac">{{ article.date }}</span>
        </div>

        <span class="text-lg font-bold block">{{ article.title }}</span>
        <span class="text-sm" *ngIf="article.snippet && showSnippets">{{ article.snippet }}... </span>
        <div class="flex items-center mt-5 absolute bottom-0 pb-8">
          <img [src]="article.author.photo" />
          <span class="ml-5 font-bold text-xs">{{ article.author.name }}</span>
        </div>
      </div>
    </div>
  </ng-container>
</div>
