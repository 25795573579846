import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { LogService } from './log.service';

export interface IOfflineData {
  // whether to enable an offline page or not
  show: boolean;
  // an optional http request if thrown from interceptor
  httpReq?: any;
}
/**
 * This provides common network handling
 * Each platform web/mobile can inject this and use to help facilitate network handling
 */
@Injectable()
export class NetworkService {
  /**
   * Platforms can observe this for when users attempt to retry the connection
   * Each platform should implement it's own specific connection retry logic
   */
  retryConnection$: Subject<boolean> = new Subject();
  /**
   * some network errors can be handled transparently by the system
   * this lets the system know which occur so they can respond
   */
  private _offline$: Subject<boolean> = new Subject();
  private _isOffline = false;
  private _showOfflineNotice$: Subject<boolean> = new Subject();
  private _showOfflinePage$: BehaviorSubject<IOfflineData> = new BehaviorSubject(null);

  constructor(private _log: LogService) {}

  get offline$() {
    return this._offline$;
  }

  set offline(value: boolean) {
    this._isOffline = value;
    this._offline$.next(value);
  }

  get isOffline() {
    return this._isOffline;
  }

  /**
   * Platforms can observe this to implement their own reaction flows for when
   * the error of an api call is related to user losing connection and going offline
   */
  get showOfflineNotice$() {
    return this._showOfflineNotice$;
  }

  set showOfflineNotice(value: boolean) {
    this.offline = true; // ensure flag is set here
    this._showOfflineNotice$.next(value);
  }

  get showOfflinePage$() {
    return this._showOfflinePage$;
  }

  set showOfflinePage(value: IOfflineData) {
    this._showOfflinePage$.next(value);
  }

  /**
   * Components can use this to trigger platform specific network connections retries
   */
  retry() {
    this.retryConnection$.next(true);
  }
}
