<div class="fixed w-full" style="top: 0px; z-index: 99" [class.floating]="scrolledHeader" [class.dark-mode]="darkMode">
  <div class="container header relative flex items-center py-3" [ngClass]="{ scrolled: scrolledHeader }">
    <div class="mr-12 xl:-ml-8 flex-1" routerLink="/">
      <img [src]="darkMode ? '/assets/images/logo-light.svg' : '/assets/images/logo.svg'" class="w-48" />
    </div>

    <div class="top-links hidden md:flex">
      <ng-container *ngFor="let headerItem of headerItems">
        <a [routerLink]="headerItem.routerLink" routerLinkActive="font-bold selected" class="mr-3 sm:mr-5 lg:mr-10" [title]="headerItem.text">{{ headerItem.text }}</a>
      </ng-container>
    </div>

    <div class="hidden md:inline-block">
      <a href="mailto:info@vesto.io?subject=I’d like to learn more about Vesto" class="mr-5 button button-primary button-sm">Request Demo</a>
    </div>
    <i *ngIf="env.enableSignIn" (click)="signIn.emit()" class="fal fa-user-circle text-2xl hidden md:inline-block cursor-pointer xl:-mr-6"></i>

    <div class="hamburger openDropdown flex md:hidden">
      <div class="flex cursor-pointer items-center openDropdown" (click)="dropdownVisible = !dropdownVisible">
        <i [class]="dropdownVisible ? 'fal fa-times openDropdown' : 'fal fa-chevron-down openDropdown'"></i>
      </div>
      <div class="dropdown text-center text-sm slide-in-down bg-lilac-light" *ngIf="dropdownVisible">
        <ng-container *ngFor="let headerItem of headerItems">
          <a class="dropdown-option mb-2 text-xl block" [title]="headerItem.text" [routerLink]="headerItem.routerLink">{{ headerItem.text }}</a>
        </ng-container>
        <div><span *ngIf="env.enableSignIn" (click)="signIn.emit()" class="font-bold button button-primary inline-block button-sm w-full mt-3 mb-2">Sign In</span></div>
      </div>
    </div>
  </div>
</div>
