<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content>
  <ng-container [ngSwitch]="step">
    <section *ngSwitchCase="'TRANSACTION'">
      <gbl-transaction [transaction]="transaction" [shouldShowIcon]="true"></gbl-transaction>

      <gbl-alert [type]="error" [message]="error"></gbl-alert>

      <div *ngIf="shouldShowMemo && UserUtil.hasRole(user, RoleType.SUPER_ADMIN)" class="bg-light-gold rounded-lg p-3 mt-4 flex text-darker-gold text-xs items-center">
        <i class="fal fa-exclamation-triangle text-2xl mr-4"></i>
        <div>
          <p>Wire amount: {{ transaction.fiatAmount | currency }}</p>
          Be sure to include <pre class="inline">CIR2UEFYYL</pre> in the memo field when Wiring to Circle
        </div>
      </div>

      <div *ngIf="buttonText && UserUtil.hasRole(user, RoleType.SUPER_ADMIN)" class="text-left border-top mt-2 pt-2 vesto-input vesto-select">
        <button class="button button-primary my-4 w-full text-center block" (click)="updateStatus()">{{ buttonText }}</button>
      </div>
    </section>

    <section *ngSwitchCase="'TWO_FACTOR'">
      <div class="mt-32">
        <gbl-two-factor (sendSmsCode)="sendSmsCode($event)" (code)="confirmCode($event)"></gbl-two-factor>
      </div>
    </section>

    <section *ngSwitchCase="'BUSY'">
      <div class="mt-32">
        <div class="placeholder-long bg-flash"></div>
        <div class="placeholder-short bg-flash mt-4"></div>
        <div class="placeholder-area bg-flash mt-4"></div>
      </div>
    </section>

    <section *ngSwitchCase="'SUCCESS'">
      <gbl-lottie-player></gbl-lottie-player>
    </section>

    <section *ngSwitchCase="'DETAIL'">
      <div class="slide-in">
        <div class="text-center">
          <p>Success!</p>
          <p class="text-sm mb-4">You updated the transaction status.</p>
        </div>
        <button class="button button-primary my-4 mx-auto w-full" (click)="close()">Close</button>
      </div>
    </section>
  </ng-container>
</mat-dialog-content>
