import { NgModule } from '@angular/core';
import { AerosUISharedModule } from '@vesto/xplat/features';
import { UIModule } from '../../ui/ui.module';
import { UI_COMPONENTS } from './components';

@NgModule({
  imports: [AerosUISharedModule, UIModule],
  declarations: [...UI_COMPONENTS],
  exports: [...UI_COMPONENTS]
})
export class AerosUIModule {}
