import { Component, OnInit } from '@angular/core';
import { BaseComponent, CoreService, uiCloseModal } from '@vesto/xplat/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { FormGroupUtil } from '@vesto/xplat/utils';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'gbl-request-form-dialog',
  templateUrl: './request-form-dialog.component.html'
})
export class RequestFormDialogComponent extends BaseComponent implements OnInit {
  formGroup: UntypedFormGroup;
  title: string;
  documentName: string;
  error: string;

  ngOnInit() {
    this.formGroup = CoreService.formBuilder.group({
      name: [
        null,
        {
          validators: [Validators.required, Validators.nullValidator]
        }
      ],
      email: [
        null,
        {
          validators: [Validators.required, Validators.email, Validators.nullValidator]
        }
      ],
      organization: [null]
    });
  }

  submit() {
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }

    const value = FormGroupUtil.trim(this.formGroup.value);
    CoreService.contactFacade.emailReport({
      ...value,
      documentName: this.documentName,
      title: this.title
    });
  }

  close() {
    uiCloseModal();
  }
}
