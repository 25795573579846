import { Directive } from '@angular/core';
import { BaseComponent, CoreService, uiCloseModal } from '@vesto/xplat/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import {IUser, UserStatus} from '@vesto/vesto';
import {ofType} from "@ngrx/effects";
import {UserActions} from "@vesto/ngx-vesto/state";
import {filter, take} from "rxjs";
import {FeatureService} from "../../../services";

@Directive()
export abstract class ChangePersonalInfoModalBaseComponent extends BaseComponent {
  step: 'FORM_GROUP' | 'TWO_FACTOR' | 'BUSY' | 'SUCCESS' | 'DETAIL' = 'FORM_GROUP';
  formGroup: UntypedFormGroup;
  googlePlaceId: string;
  error: string;
  user: IUser;
  UserStatus = UserStatus;

  constructor() {
    super();
    CoreService.userFacade.user$
      .pipe(
        takeUntil(this.destroy$),
        filter((user) => !!user)
      ).subscribe((user) => {
          this.user = user;
      });
    this.googlePlaceId = this.user?.google?.placeId;
    this.formGroup = CoreService.formBuilder.group(
      {
        firstName: [
          this.user?.firstName,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        lastName: [
          this.user?.lastName,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        address: [
          this.user?.address,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ]
      },
      { updateOn: 'change' }
    );
  }

  addressChange(event: any) {
    if (event && event.formatted_address) {
      this.formGroup.patchValue(
        {
          ...this.formGroup.value,
          address: event.formatted_address
        },
        { emitEvent: false }
      );
      this.googlePlaceId = event.place_id;
    }
  }

  changePersonalInfo() {
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }

    CoreService.actions$.pipe(ofType(UserActions.changePersonalInfoSuccess), take(1), takeUntil(this.destroy$)).subscribe(() => {
      this.step = 'SUCCESS';
      CoreService.windowService.setTimeout(() => {
        this.step = 'DETAIL';
      }, 1600);
    });

    CoreService.actions$.pipe(ofType(UserActions.changePersonalInfoFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
      this.step = 'FORM_GROUP';
    });

    this.step = 'BUSY';
    const value = this.formGroup.value;
    CoreService.userFacade.changePersonalInfo(value.firstName, value.lastName, value.address, this.googlePlaceId);
  }

  close() {
    uiCloseModal();
  }

  openVerifyIdentity() {
    this.close();
    FeatureService.dashboardFacade.openOrGoToVerifyIdentity();
  }
}
