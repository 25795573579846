import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WebAlertComponentToken, WebConfirmComponentToken } from './tokens';

declare var process: any;
declare var require: any;

@Injectable({
  providedIn: 'root'
})
export class WebWindowService {
  isDialogOpen = false;
  private matDialogRef: MatDialogRef<any>;

  constructor(private matDialog: MatDialog, @Inject(PLATFORM_ID) private platformId: any, @Inject(WebAlertComponentToken) private alertComponent: any, @Inject(WebConfirmComponentToken) private confirmComponent: any) {}

  get navigator() {
    if (isPlatformBrowser(this.platformId)) {
      return window.navigator;
    }
    return null;
  }

  get localStorage() {
    if (isPlatformBrowser(this.platformId)) {
      return window.localStorage;
    }
    return null;
  }

  get location() {
    if (isPlatformBrowser(this.platformId)) {
      return window.location;
    }
    return null;
  }

  get pageYOffset() {
    if (isPlatformBrowser(this.platformId)) {
      return window.pageYOffset;
    }
    return null;
  }

  get process() {
    if (typeof process !== 'undefined') {
      return process;
    }
    return null;
  }

  get require() {
    if (typeof require !== 'undefined') {
      return process;
    }
    return null;
  }

  get outerWidth() {
    if (isPlatformBrowser(this.platformId)) {
      return window.outerWidth;
    }
    return 800;
  }

  get Onfido() {
    if (isPlatformBrowser(this.platformId)) {
      return (<any>window).Onfido;
    }
    return null;
  }

  alert(message: string, authentication = false, actions?: Array<any>) {
    return this._openDialog(this.alertComponent, message, authentication, actions);
  }

  confirm(message: string, authentication: boolean) {
    return this._openDialog(this.confirmComponent, message, authentication);
  }

  setTimeout(handler: (...args: any[]) => void, timeout?: number): number {
    if (isPlatformBrowser(this.platformId)) {
      return window.setTimeout(handler, timeout);
    }
    return 0;
  }

  clearTimeout(timeoutId: number): void {
    if (isPlatformBrowser(this.platformId)) {
      window.clearTimeout(timeoutId);
    }
  }

  setInterval(handler: (...args: any[]) => void, ms?: number, ...args: any[]): number {
    if (isPlatformBrowser(this.platformId)) {
      return window.setInterval(handler, ms, args);
    }
    return 0;
  }

  clearInterval(intervalId: number): void {
    if (isPlatformBrowser(this.platformId)) {
      window.clearInterval(intervalId);
    }
  }

  hideDialog() {
    this._closeDialog();
    return Promise.resolve();
  }

  scroll(x: number, y: number) {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(x, y);
    }
  }

  scrollTo(x: number, y: number) {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(x, y);
    }
  }

  open(...args: any[]) {
    if (isPlatformBrowser(this.platformId)) {
      window.open(...args);
    }
  }

  private _openDialog(cmpType: any, message: string, authentication: boolean, actions?: Array<any>) {
    this._closeDialog();
    this.isDialogOpen = true;
    let html: string;
    if (message && message.indexOf('<') === 0) {
      html = message;
    }
    const options: any = {
      disableClose: true,
      width: '500px',
      height: '',

      data: {
        html,
        message,
        authentication
      }
    };
    if (actions) {
      options.data.actions = actions;
    }
    this.matDialogRef = this.matDialog.open(cmpType, options);
    const afterClosed = this.matDialogRef.afterClosed().toPromise();
    afterClosed.then(
      () => {
        this.isDialogOpen = false;
      },
      () => {}
    );
    return afterClosed;
  }

  private _closeDialog(): void {
    if (!this.matDialogRef) {
      return;
    }
    this.matDialogRef.close();
    this.matDialogRef = null;
    this.isDialogOpen = false;
  }
}
