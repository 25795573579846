import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { VestoSelectors, VestoState } from '../vesto.state';
import { IUser } from '@vesto/vesto';
import { SignUpActions } from './sign-up.actions';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignUpFacade {
  user$: Observable<IUser> = this.store.pipe(select(VestoSelectors.user));

  constructor(private store: Store<VestoState.IState>) {}

  findUserByToken(token: string) {
    this.store.dispatch(SignUpActions.findUserByToken({ token }));
  }

  signUp(user: IUser, password: string) {
    this.store.dispatch(SignUpActions.signUp({ user, password }));
  }

  sendSmsCode(mobile: string) {
    this.store.dispatch(SignUpActions.sendSmsCode({ mobile }));
  }

  verifySmsCode(code: string) {
    this.store.dispatch(SignUpActions.verifySmsCode({ code }));
  }

  sendEmail() {
    this.store.dispatch(SignUpActions.sendEmail());
  }

  verifyEmail(token: string) {
    this.store.dispatch(SignUpActions.verifyEmail({ token }));
  }

  cancel() {
    this.store.dispatch(SignUpActions.cancel());
  }
}
