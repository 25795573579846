import { ElementRef, Renderer2, Input, Directive, AfterViewInit } from '@angular/core';
import { WindowService } from '@vesto/xplat/core';

@Directive({ selector: '[focusMe]' })
export class AutofocusDirective implements AfterViewInit {
  @Input('focusMe') isFocused: boolean;

  constructor(private _el: ElementRef, private _win: WindowService, private renderer: Renderer2) {}

  ngAfterViewInit() {
    if (this.isFocused && this._el && this._el.nativeElement) {
      this._win.setTimeout(() => {
        if (this._el && this._el.nativeElement) {
          this._el.nativeElement.focus();
        }
      }, 300);
    }
  }
}
