import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

import { BaseComponent } from '@vesto/xplat/core';
import {Router} from "@angular/router";

@Component({
  selector: 'gbl-blog-snippets',
  templateUrl: 'blog-snippets.component.html'
})
export class BlogSnippetsComponent extends BaseComponent implements OnInit {
  public displayedArticles: Array<any>;
  @Input() articles: any;
  @Input() limit: number;
  @Input() showSnippets: boolean = true;
  constructor(private router: Router) {
    super();
  }

  ngOnInit() {
    if (this.limit) {
      this.displayedArticles = [];
      this.articles.forEach((item, index) => {
        if (index < this.limit) this.displayedArticles.push(item);
      });
    } else {
      this.displayedArticles = [...this.articles];
    }
  }

  viewArticle(route: string) {
    if (route.startsWith('http')) {
      window.open(route);
    }
    this.router.navigate([route]);
  }
}
