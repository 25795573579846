import {Directive} from '@angular/core';
import {BaseComponent, CoreService} from '@vesto/xplat/core';
import {IUser, UserStatus} from '@vesto/vesto';
import {filter, takeUntil} from 'rxjs/operators';
import {DashboardFacade} from '../state';

@Directive()
export abstract class UserStatusBaseComponent extends BaseComponent {
  user: IUser;
  title: string = null;
  message: string = null;
  UserStatus = UserStatus;
  iconClass: string = null;
  wasPending = false;

  constructor(protected dashboardFacade: DashboardFacade) {
    super();
    CoreService.userFacade.user$.pipe(
      takeUntil(this.destroy$),
      filter(user => !!user),
      ).subscribe((user) => {
      this.user = user;
      this.setTitleAndMessage();
    });
  }

  private setTitleAndMessage() {
    const fontWeight = CoreService.platformDeviceInfo.deviceDetails === 'browser' ? 'fal ' : '';
    if (this.user) {
      switch (this.user.status) {
        case UserStatus.IDENTITY_NOT_VERIFIED:
          this.title = 'Verify your identity';
          this.message = 'Please complete your registration by verifying your identity';
          this.iconClass = `${fontWeight}fa-id-card`;
          return;
        case UserStatus.PROVISIONING:
          this.title = 'Finalizing';
          this.message = 'Please wait while we finish setting up your account';
          this.iconClass = `${fontWeight}fa-spinner fa-pulse`;
          return;
        case UserStatus.VERIFYING_IDENTITY:
          this.wasPending = true;
          this.title = 'Verifying your identity';
          this.message = 'Please wait...';
          this.iconClass = `${fontWeight}fa-id-card`;
          return;
        case UserStatus.SUSPENDED:
          this.title = 'You have been suspended';
          this.message = 'Please email support@vesto.io';
          this.iconClass = `${fontWeight}fa-lock-alt`;
          return;
        case UserStatus.ACTIVE:
          if (this.wasPending) {
            this.title = 'Your identity has been verified.';
            this.message = null;
            this.iconClass = `${fontWeight}fa-id-card`;
          } else {
            this.title = null;
            this.message = null;
          }
          return;
        case UserStatus.KYC_CONSIDER:
          this.title = 'Identity verification pending';
          this.message = 'Your verification is taking a little longer than expected, please wait 1-3 days';
          this.iconClass = `${fontWeight}fa-question-circle`;
          return;
        case UserStatus.SWAPPING_USER_ADDRESS:
          this.title = 'Swapping user address';
          this.message = 'Please wait 5-10 minutes while your signing address is being deployed to the blockchain.';
          this.iconClass = `${fontWeight}fa-cube`;
          return;
        default:
          this.title = null;
          this.message = null;
          return;
      }
    }
  }

  openOrGoToVerifyIdentity() {
    this.dashboardFacade.openOrGoToVerifyIdentity();
  }

  dismiss() {
    this.wasPending = false;
    this.title = null;
    this.message = null;
  }
}
