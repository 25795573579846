import { Component } from '@angular/core';
import { BaseComponent } from '@vesto/xplat/core';

@Component({
  selector: 'gbl-alerts',
  templateUrl: 'alerts.component.html'
})
export class AlertsComponent extends BaseComponent {
  constructor() {
    super();
  }
}
