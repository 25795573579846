import { ethers } from 'ethers';

export class CryptoUtil {
  static sign(privateKey: string, hash: string) {
    const signingKey = new ethers.utils.SigningKey(privateKey);
    const signature = signingKey.signDigest(hash);

    return {
      v: signature.v,
      r: signature.r,
      s: signature.s
    };
  }
}
