import {Component, Input, OnInit} from '@angular/core';

import { BaseComponent } from '@vesto/xplat/core';

@Component({
  selector: 'gbl-footer',
  templateUrl: 'footer.component.html'
})
export class FooterComponent extends BaseComponent implements OnInit {
  @Input() darkMode = false;
  year: number;

  constructor() {
    super();
  }

  ngOnInit() {
    var date = new Date();
    this.year = date.getFullYear();
  }
}
