import {Component, OnInit} from "@angular/core";
import {BaseComponent, CoreService, UiActions} from "@vesto/xplat/core";
import {UntypedFormGroup, Validators} from "@angular/forms";
import {take, takeUntil} from "rxjs/operators";
import {ofType} from "@ngrx/effects";
import {MerchantActions} from "@vesto/ngx-vesto/state";
import {IMerchant, MerchantFeeType} from "@vesto/vesto";

@Component({
  selector: 'gbl-merchant-modal',
  templateUrl: './merchant-modal.component.html'
})

export class MerchantModalComponent extends BaseComponent implements OnInit {
  step: 'FORM_GROUP' | 'BUSY' | 'SUCCESS' | 'DETAIL' = 'FORM_GROUP';
  formGroup: UntypedFormGroup;
  action: 'create';
  error: string;
  googlePlaceId: string;
  merchant: IMerchant
  merchantName: string;
  MerchantFeeType = MerchantFeeType;

  ngOnInit() {
    if (this.action === 'create') {
      this.formGroup = CoreService.formBuilder.group({
        name: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        address: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        feeType: [
          MerchantFeeType.FIXED,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        feeFixed: [
          0,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        feePercent: [
          0,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        email: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator, Validators.email]
          }
        ]
      });
    } else {
      this.formGroup = CoreService.formBuilder.group({
        name: [
          this.merchant.name,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        address: [
          this.merchant.address,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        feeType: [
          MerchantFeeType.FIXED,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        feeFixed: [
          0,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        feePercent: [
          0,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ]
      });
    }
  }

  close() {
    CoreService.store.dispatch(UiActions.closeModal());
  }

  addressChange(event: any) {
    if (event && event.formatted_address) {
      this.formGroup.patchValue(
        {
          ...this.formGroup.value,
          address: event.formatted_address
        },
        { emitEvent: false }
      );
      this.googlePlaceId = event.place_id;
    }
  }

  submit() {
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }

    CoreService.actions$.pipe(ofType(MerchantActions.createSuccess, MerchantActions.changeSettingsSuccess), take(1), takeUntil(this.destroy$)).subscribe(() => {
      this.step = 'SUCCESS';
      CoreService.windowService.setTimeout(() => {
        this.step = 'DETAIL'
      });
    });

    CoreService.actions$.pipe(ofType(MerchantActions.createFailure,MerchantActions.changeSettingsFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.step = 'FORM_GROUP';
      this.error = `apiErrors.${result.apiError.error}`;
    });

    const value = this.formGroup.value;
    this.step = 'BUSY';
    switch (this.action) {
      case 'create':
        this.merchantName = value.name;
        CoreService.merchantFacade.create(value.name, value.address, value.feeType, value.feeFixed, value.feePercent, value.email);
        break;
      default:
        CoreService.merchantFacade.changeSettings(this.merchant.id, value.name, value.address, value.feeType, value.feeFixed, value.feePercent);
        break;
    }
  }
}
