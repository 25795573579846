import { Component } from '@angular/core';
import { BaseComponent, CoreService } from '@vesto/xplat/core';
import {IInstitution, InstitutionFeeType} from '@vesto/vesto';
import { takeUntil } from 'rxjs/operators';
import { DashboardFacade } from '@vesto/xplat/features';

@Component({
  selector: 'gbl-institution-settings',
  templateUrl: './institution-settings.component.html'
})
export class InstitutionSettingsComponent extends BaseComponent {
  institution: IInstitution;
  InstitutionFeeType = InstitutionFeeType;

  constructor(protected dashboardFacade: DashboardFacade) {
    super();
    CoreService.institutionFacade.institution$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.institution = result;
    });
  }

  openOrGoToChangeSettings() {
    this.dashboardFacade.openOrGoToInstitution(null, this.institution)
  }

  openOrGoToChangeAml() {
    this.dashboardFacade.openOrGoToChangeAml();
  }
}
