import {Directive, OnInit} from '@angular/core';
import {BaseComponent, CoreService} from '@vesto/xplat/core';
import {AccountStatus, IAccount, IUser} from '@vesto/vesto';
import {takeUntil} from 'rxjs/operators';
import {filter} from "rxjs";
import {FeatureService} from "../../services"

@Directive()
export abstract class AccountStatusBaseComponent extends BaseComponent implements OnInit {
  user: IUser;
  selectedAccount: IAccount;
  title: string = null;
  message: string = null;
  iconClass: string = null;

  ngOnInit() {
    CoreService.userFacade.user$
      .pipe(
        takeUntil(this.destroy$),
        filter((user) => !!user)
      )
      .subscribe((user) => {
        this.user = user;
      });
    FeatureService.dashboardFacade.selectedAccount$
      .pipe(
        takeUntil(this.destroy$),
        filter((selectedAccount) => !!selectedAccount)
      )
      .subscribe((selectedAccount) => {
        this.selectedAccount = selectedAccount;
        this.setTitleAndMessage();
      });
  }

  private setTitleAndMessage() {
    if (CoreService.platformDeviceInfo.deviceDetails === 'browser') {
      switch (this.selectedAccount.status) {
        case AccountStatus.NOT_DEPLOYED:
          this.title = 'Account Not Deployed';
          this.message = this.selectedAccount.error;
          this.iconClass = `fal fa-exclamation-circle`;
          return;
        case AccountStatus.SUSPENDED:
          this.title = 'Account suspended';
          this.message = `Your account has been suspended, if you hav any questions please contact support@vesto.io`;
          this.iconClass = `fal fa-lock-alt`;
          return;
        default:
          this.title = null;
          this.message = null;
      }
      if (this.selectedAccount.additionalUserSuspended) {
        this.title = 'Account suspended';
        this.message = `${this.selectedAccount.user.firstName} ${this.selectedAccount.user.lastName} suspended you from this joint account`;
        this.iconClass = `fal fa-lock-alt`;
        return;
      }
    } else {
      switch (this.selectedAccount.status) {
        case AccountStatus.NOT_DEPLOYED:
          this.title = 'Account error';
          this.message = this.selectedAccount.error;
          this.iconClass = String.fromCharCode(0xf06a);
          return;
        case AccountStatus.SUSPENDED:
          this.title = 'Account suspended';
          this.message = `${this.selectedAccount.user.firstName} ${this.selectedAccount.user.lastName} suspended you from this joint account`;
          this.iconClass = String.fromCharCode(0xf30d);
          return;
        default:
          this.title = null;
          this.message = null;
      }
    }
  }
}
