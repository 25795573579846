import { IEnvironment } from '../interfaces/environment.interface';
import { IHttpClient } from '../interfaces/http.interface';
import { BaseApi } from './base-api';
import { Blockchain } from "../enums/blockchain.enum";

export class GasTankApi extends BaseApi {
  constructor(protected environment: IEnvironment, protected httpClient: IHttpClient) {
    super(environment);
  }

  findActive() {
    return this.httpClient.get(this.formatUrl(`/v${this.environment.vesto.version}/gas-tanks/find-active`), {
      headers: this.getHeaders()
    });
  }


  changeSettings(
    gasTankId: string,
    blockchain: Blockchain,
    minPrice: number,
    maxPrice: number,
    limit: number
  ) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/gas-tanks/change-settings/${gasTankId}`),
      {
        blockchain,
        minPrice,
        maxPrice,
        limit
      },
      {
        headers: this.getHeaders()
      }
    );
  }


}
