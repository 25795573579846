import { Component } from '@angular/core';
import { SetPasswordBaseComponent, SignInFacade } from '@vesto/xplat/features';

@Component({
  selector: 'gbl-set-password-modal',
  templateUrl: 'set-password-modal.component.html'
})
export class SetPasswordModalComponent extends SetPasswordBaseComponent {
  constructor(protected signInFacade: SignInFacade) {
    super(signInFacade);
  }
}
