import { Component } from '@angular/core';
import { FeatureService, SendReceiveModalBaseComponent } from '@vesto/xplat/features';
import { CoreService } from '@vesto/xplat/core';
import { ClipboardService } from 'ngx-clipboard';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'gbl-send-receive-modal',
  templateUrl: 'send-receive-modal.component.html',
  animations: [
    trigger('slideInOut', [
      state(
        'void',
        style({
          left: '1000px',
          opacity: '0'
        })
      ),
      //element being added into DOM.
      transition(':enter', [
        animate(
          '300ms 300ms ease-in-out',
          style({
            left: '0',
            opacity: '1'
          })
        )
      ]),
      //element being removed from DOM.
      transition(':leave', [
        animate(
          '300ms ease-in-out',
          style({
            left: '-1000px',
            opacity: '0'
          })
        )
      ])
    ])
  ]
})
export class SendReceiveModalComponent extends SendReceiveModalBaseComponent {
  constructor(protected clipboardService: ClipboardService) {
    super();
  }

  openOrGoToBuySell() {
    FeatureService.dashboardFacade.openOrGoToBuySell();
  }

  copy(account: string) {
    // Constants.clipboardService.copy(account); TODO: why isn't this working?
    this.clipboardService.copy(account);
    CoreService.uiFacade.notification('Address copied!');
  }
}
