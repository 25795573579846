import { Directive } from '@angular/core';
import { BaseComponent, CoreService, uiCloseModal } from '@vesto/xplat/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import {take, takeUntil} from 'rxjs/operators';
import {ofType} from "@ngrx/effects";
import {UserActions} from "@vesto/ngx-vesto/state";

@Directive()
export abstract class ChangeMobileModalBaseComponent extends BaseComponent {
  step: 'FORM_GROUP' | 'TWO_FACTOR' | 'SUCCESS' | 'DETAIL' = 'FORM_GROUP';
  formGroup: UntypedFormGroup
  error: string;
  newMobile: string;

  constructor() {
    super();
    this.formGroup = CoreService.formBuilder.group(
      {
        mobile: [
          null,
          {
            validators: [Validators.required]
          }
        ]
      },
      { updateOn: 'change' }
    );
  }

  sendSmsCode(isResendSms: boolean) {
    CoreService.userFacade.sendSmsCode();

    if (isResendSms) {
      CoreService.uiFacade.notification(`SMS code sent!`);
    }
  }

  changeMobile() {
    this.error = null;
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }

    CoreService.actions$.pipe(ofType(UserActions.changeMobileSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.step = 'TWO_FACTOR';
    });

    CoreService.actions$.pipe(ofType(UserActions.changeMobileFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
    });
    const value = this.formGroup.value;
    CoreService.userFacade.changeMobile(value.mobile);
  }

  confirmCode(code: string) {
    CoreService.userFacade.verifyChangeMobile(code);

    CoreService.actions$.pipe(ofType(UserActions.verifyChangeMobileSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.newMobile = result.user.mobile;
      this.step = 'SUCCESS';
      CoreService.windowService.setTimeout(() => {
        this.step = 'DETAIL';
      }, 2200)
    });

    CoreService.actions$.pipe(ofType(UserActions.verifyChangeMobileFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
    });
  }

  close() {
    uiCloseModal();
  }
}
