export enum TransactionStatus {
  PENDING = 'PENDING',
  TRANSFERRING_TO_VESTO = 'TRANSFERRING_TO_VESTO',
  TRANSFERRED_TO_VESTO = 'TRANSFERRED_TO_VESTO',
  TRANSFERRING_TO_CIRCLE = 'TRANSFERRING_TO_CIRCLE',
  TRANSFERRED_TO_CIRCLE = 'TRANSFERRED_TO_CIRCLE',
  TRANSFERRING_TO_CEFI = 'TRANSFERRING_TO_CEFI',
  TRANSFERRED_TO_CEFI = 'TRANSFERRED_TO_CEFI',
  TRANSFERRING_TO_USER = 'TRANSFERRING_TO_USER',
  MINTING = 'MINTING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}
