import {Component, OnInit} from "@angular/core";
import {BaseComponent, CoreService, UiActions} from "@vesto/xplat/core";
import {Blockchain, ISystem, ITransaction} from "@vesto/vesto";
import {take, takeUntil} from "rxjs/operators";
import {ofType} from "@ngrx/effects";
import {TransactionActions} from "@vesto/ngx-vesto/state";
import {UntypedFormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'gbl-transfer-gas',
  templateUrl: './transfer-gas-modal.component.html'
})

export class TransferGasModalComponent extends BaseComponent  implements OnInit {
  step: 'FORM_GROUP' | 'TWO_FACTOR' | 'BUSY' | 'SUCCESS' | 'TRANSACTION_DETAIL' = 'FORM_GROUP';
  error: string;
  transaction: ITransaction;
  formGroup: UntypedFormGroup;
  gasTankId: string;
  blockchain: Blockchain;

  ngOnInit() {
    this.formGroup = CoreService.formBuilder.group(
      {
        to: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ]
      });
  }

  sendSmsCode(isResendSms: boolean) {
    CoreService.transactionFacade.sendSmsCode();

    if (isResendSms) {
      CoreService.uiFacade.notification(`SMS code sent!`);
    }
  }

  transfer() {
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }
    this.step = 'TWO_FACTOR';
  }

  confirmCode(code: string) {
    this.step = 'BUSY';
    const value = this.formGroup.value;
    CoreService.transactionFacade.transferGas(this.gasTankId, this.blockchain, value.to, code);

    CoreService.actions$.pipe(ofType(TransactionActions.transferGasSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.step = 'SUCCESS';
      this.transaction = result.transaction;
      CoreService.windowService.setTimeout(() => {
        this.step = 'TRANSACTION_DETAIL';
      }, 2200);
    });

    CoreService.actions$.pipe(ofType(TransactionActions.transferGasFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
      this.step = 'FORM_GROUP';
    });
  }

  close() {
    CoreService.store.dispatch(UiActions.closeModal());
  }

}
