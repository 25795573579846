import {Component, OnInit} from "@angular/core";
import {BaseComponent, CoreService, UiActions, WindowService} from "@vesto/xplat/core";
import {IMerchant, IStore} from "@vesto/vesto";
import {UntypedFormGroup, Validators} from "@angular/forms";
import {take, takeUntil} from "rxjs/operators";
import {ofType} from "@ngrx/effects";
import {MerchantActions, StoreActions} from "@vesto/ngx-vesto/state";

@Component({
  selector: 'gbl-store-modal',
  templateUrl: 'store-modal.component.html'
})

export class StoreModalComponent extends BaseComponent implements OnInit {
  step: 'FORM_GROUP' | 'BUSY' | 'SUCCESS' | 'DETAIL' = 'FORM_GROUP';
  formGroup: UntypedFormGroup;
  action: 'create';
  error: string;
  googlePlaceId: string;
  merchant: IMerchant;
  store: IStore;
  storeName: string;

  ngOnInit() {
    this.formGroup = CoreService.formBuilder.group({
      name: [
        this.store ? this.store.name : null,
        {
          validators: [Validators.required, Validators.nullValidator]
        }
      ],
      address: [
        this.store ? this.store.address : null,
        {
          validators: [Validators.required, Validators.nullValidator]
        }
      ]
    });
  }

  close() {
    CoreService.store.dispatch(UiActions.closeModal());
  }

  addressChange(event: any) {
    if (event && event.formatted_address) {
      this.formGroup.patchValue(
        {
          ...this.formGroup.value,
          address: event.formatted_address
        },
        { emitEvent: false }
      );
      this.googlePlaceId = event.place_id;
    }
  }

  submit() {
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }

    const value = this.formGroup.value;
    this.step = 'BUSY';

    CoreService.actions$.pipe(ofType(StoreActions.createSuccess, StoreActions.changeSettingsSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.storeName = result.store.name;
      CoreService.accountFacade.changeSettings(result.store.operationalAccountId, result.store.name + 'Operational');
      this.step = 'SUCCESS';
      CoreService.windowService.setTimeout(() => {
        this.step = 'DETAIL'
      });
    });

    CoreService.actions$.pipe(ofType(StoreActions.createFailure, StoreActions.changeSettingsFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.step = 'FORM_GROUP';
      this.error = `apiErrors.${result.apiError.error}`;
    });

    if (this.store) {
      CoreService.storeFacade.changeSettings(this.store.id, value.name, value.address);
    } else {
      CoreService.storeFacade.create(this.merchant.id, value.name, value.address);
    }
  }
}
