import { IEnvironment } from '../interfaces/environment.interface';
import { IHttpClient } from '../interfaces/http.interface';
import { BaseApi } from './base-api';

export class OnfidoApi extends BaseApi {
  constructor(protected environment: IEnvironment, protected httpClient: IHttpClient) {
    super(environment);
  }

  createSdkToken(userId: string, device: string) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/onfido/create-sdk-token/${userId}/${device}`), null, { headers: this.getHeaders() });
  }
}
