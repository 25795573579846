import {Injectable} from '@angular/core';
import {Action} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {from, Observable} from 'rxjs';
import {MerchantActions, UserActions} from '@vesto/ngx-vesto/state';
import {mergeMap, withLatestFrom} from 'rxjs/operators';
import {DashboardFacade} from "@vesto/xplat/features";
import {CoreActions} from "@vesto/xplat/core";

@Injectable()
export class DashboardMerchantEffects {
  constructor(private actions$: Actions, private dashboardFacade: DashboardFacade) {
  }

  success$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MerchantActions.addUserSuccess, MerchantActions.removeUserSuccess, MerchantActions.promoteUserSuccess, MerchantActions.demoteUserSuccess),
        withLatestFrom(this.dashboardFacade.selectedMerchant$),
        mergeMap(([{}, selectedMerchant]) => {
          return !!selectedMerchant ? from([UserActions.findByMerchant({merchantId: selectedMerchant.id})]) : from([CoreActions.noop()]);
        })
      )
  );
}
