import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { VestoSelectors, VestoState } from '../vesto.state';
import { Observable } from 'rxjs';
import { IApiError } from '@vesto/vesto';
import { ApiActions } from './api.actions';

@Injectable({
  providedIn: 'root'
})
export class ApiFacade {
  error$: Observable<IApiError> = this.store.pipe(select(VestoSelectors.apiError));

  constructor(private store: Store<VestoState.IState>) {}

  setError(apiError: IApiError) {
    this.store.dispatch(ApiActions.setError({ apiError: apiError }));
  }

  clearError() {
    this.store.dispatch(ApiActions.clearError());
  }
}
