<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>

  <h3 class="section-number">Link Account</h3>
</div>
<mat-dialog-content>
  <ng-container [ngSwitch]="step">
    <section *ngSwitchCase="'CHOOSE_ADD_PAYMENT_METHOD_TYPE'">
      <div class="slide-in mt-6">
        <div class="rounded-lg p-4 my-4 bg-trans-blue">
          <p class="text-sm">Link your online bank account using <img src="/assets/images/plaid-logo-black.png" class="w-16 ml-1" /></p>
          <button class="button button-primary mt-3 w-full" (click)="openPlaid()">Link</button>
        </div>
        <p class="my-4 text-center text-sm">- or -</p>
        <p class="text-sm mb-3 cursor-pointer" (click)="showAddBankAccount = !showAddBankAccount">Enter Bank Account Details <i [class]="showAddBankAccount ? 'fal fa-minus-circle' : 'fal fa-plus-circle'"></i></p>
        <form [formGroup]="formGroup" (ngSubmit)="createPaymentMethod()" [hidden]="!showAddBankAccount" class="slide-in">
          <div class="vesto-input mb-3">
            <label for="institution">Bank Name</label>
            <gbl-input formControlName="institution" id="institution" name="institution"></gbl-input>
          </div>
          <div class="vesto-input mb-3">
            <label for="routing">Routing Number</label>
            <gbl-input mask="numeric" formControlName="routing" id="routing" name="routing"></gbl-input>
          </div>
          <div class="vesto-input mb-3">
            <label for="account">Account Number (must be a checking account)</label>
            <gbl-input mask="numeric" formControlName="account" id="account" name="account"></gbl-input>
          </div>
          <!--<div class="vesto-input mb-3">
            <label for="confirmAccount">Confirm Account Number</label>
            <gbl-input mask="wholeNumber" formControlName="confirmAccount" id="confirmAccount" name="confirmAccount"></gbl-input>
          </div>-->
          <gbl-alert [type]="error" [message]="error"></gbl-alert>
          <button class="button button-primary my-4 mx-auto w-full" type="submit" [disabled]="formGroup.invalid || formGroup.untouched">Add Account</button>
        </form>
      </div>
    </section>
    <section *ngSwitchCase="'CHOOSE_PLAID_ACCOUNT'">
      <p class="mb-4">Chose which account to link:</p>
      <div *ngFor="let plaidpaymentMethod of plaidBankAccounts" class="flex choose-linked-account-option cursor-pointer items-center content-center my-4" (click)="choosePlaidAccount(plaidpaymentMethod)">
        <i class="fal fa-building mr-3" style="font-size: 30px"></i>
        <div>
      <span>{{ plaidpaymentMethod.name }}</span
      ><br />
          <small>{{ 'x' + plaidpaymentMethod.mask }}</small>
        </div>
      </div>
    </section>

    <section *ngSwitchCase="'BUSY'" class="mt-4">
      <div class="placeholder-long bg-flash"></div>
      <div class="placeholder-area bg-flash mt-4"></div>
    </section>

    <section *ngSwitchCase="'SUCCESS'">
      <p class="">Success!</p>
      <div class="p-4 bg-trans-blue rounded-lg flex items-center mt-2 text-sm">
        <i class="fal fa-check-circle text-3xl mr-4"></i>
        Successfully added {{ selectedBankAccount.institution }} account ending in {{ selectedBankAccount.mask }}
      </div>
      <button class="button button-primary mt-4 mx-auto w-full" (click)="close()">Close</button>
    </section>
  </ng-container>
</mat-dialog-content>
