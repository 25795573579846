import { Inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';
import {
  AccountFacade,
  ApiFacade,
  BankAccountFacade,
  MarketDataFacade,
  SnapShotFacade,
  TransactionFacade,
  UserFacade,
  InstitutionFacade,
  SystemFacade,
  ContactsFacade,
  MerchantFacade,
  FeeFacade,
  GasTankFacade,
  StoreFacade
} from '@vesto/ngx-vesto/state';
import { IPlatformDeviceInfo, PlatformDeviceInfoToken } from './tokens';
import { UiFacade } from '../state';
import { WindowService } from './window.service';
import { ProgressService } from './progress.service';
import { FormService } from './form.service';
import { NetworkService } from './network.service';
import { LogService } from './log.service';
import { RouterToken } from './tokens';
import { StorageService } from './storage.service';
import { EventBusService } from './eventbus.service';
import { StompService } from "./stomp.service";

export class CoreService {
  static uiFacade: UiFacade;
  static apiFacade: ApiFacade;
  static systemFacade: SystemFacade;
  static gasTankFacade: GasTankFacade;
  static contactFacade: ContactsFacade;
  static userFacade: UserFacade;
  static institutionFacade: InstitutionFacade;
  static merchantFacade: MerchantFacade;
  static storeFacade: StoreFacade;
  static accountFacade: AccountFacade;
  static bankAccountFacade: BankAccountFacade;
  static transactionFacade: TransactionFacade;
  static snapShotFacade: SnapShotFacade;
  static marketDataFacade: MarketDataFacade;
  static feeFacade: FeeFacade;
  static translateService: TranslateService;
  static formBuilder: UntypedFormBuilder;
  static platformDeviceInfo: IPlatformDeviceInfo;
  static platformRouter: any;
  static windowService: WindowService;
  static networkService: NetworkService;
  static storageService: StorageService;
  static progress: ProgressService;
  static stompService: StompService;
  static clipboardService: ClipboardService;
  static eventBus: EventBusService;
  static store: Store;
  static actions$: Actions;
  static formService: FormService;
  static ngRouter: Router;
  static log: LogService;
  static ngZone: NgZone;
}

@Injectable({
  providedIn: 'root'
})
export class CoreServices {
  constructor(
    uiFacade: UiFacade,
    apiFacade: ApiFacade,
    systemFacade: SystemFacade,
    gasTankFacade: GasTankFacade,
    userFacade: UserFacade,
    institutionFacade: InstitutionFacade,
    merchantFacade: MerchantFacade,
    storeFacade: StoreFacade,
    accountFacade: AccountFacade,
    contactFacade: ContactsFacade,
    bankAccountFacade: BankAccountFacade,
    transactionFacade: TransactionFacade,
    snapShotFacade: SnapShotFacade,
    marketDataFacade: MarketDataFacade,
    feeFacade: FeeFacade,
    eventBus: EventBusService,
    translateService: TranslateService,
    formBuilder: UntypedFormBuilder,
    @Inject(PlatformDeviceInfoToken)
    platformDeviceInfo: IPlatformDeviceInfo,
    @Inject(RouterToken)
    platformRouter: any,
    windowService: WindowService,
    networkService: NetworkService,
    storageService: StorageService,
    progress: ProgressService,
    stompService: StompService,
    store: Store,
    actions$: Actions,
    formService: FormService,
    ngRouter: Router,
    log: LogService,
    ngZone: NgZone
  ) {
    CoreService.uiFacade = uiFacade;
    CoreService.apiFacade = apiFacade;
    CoreService.systemFacade = systemFacade;
    CoreService.gasTankFacade = gasTankFacade;
    CoreService.userFacade = userFacade;
    CoreService.institutionFacade = institutionFacade;
    CoreService.merchantFacade = merchantFacade;
    CoreService.storeFacade = storeFacade;
    CoreService.accountFacade = accountFacade;
    CoreService.contactFacade = contactFacade;
    CoreService.bankAccountFacade = bankAccountFacade;
    CoreService.transactionFacade = transactionFacade;
    CoreService.snapShotFacade = snapShotFacade;
    CoreService.marketDataFacade = marketDataFacade;
    CoreService.feeFacade = feeFacade;
    CoreService.eventBus = eventBus;
    CoreService.translateService = translateService;
    CoreService.formBuilder = formBuilder;
    CoreService.platformDeviceInfo = platformDeviceInfo;
    CoreService.platformRouter = platformRouter;
    CoreService.windowService = windowService;
    CoreService.networkService = networkService;
    CoreService.storageService = storageService;
    CoreService.progress = progress;
    CoreService.stompService = stompService;
    CoreService.store = store;
    CoreService.actions$ = actions$;
    CoreService.formService = formService;
    CoreService.ngRouter = ngRouter;
    CoreService.log = log;
    CoreService.ngZone = ngZone;
  }
}
