import { Component, Input } from '@angular/core';
import { CurrenciesBaseComponent } from '@vesto/xplat/features';
import { IAsset } from '@vesto/vesto';

@Component({
  selector: 'gbl-rewards',
  templateUrl: 'rewards.component.html'
})
export class RewardsComponent extends CurrenciesBaseComponent {
  @Input() assets: IAsset[];

  constructor() {
    super();
  }
}
