<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <h3 class="section-number">Change Password</h3>
</div>
<mat-dialog-content>
  <ng-container [ngSwitch]="step">
    <form [formGroup]="formGroup" (ngSubmit)="changePassword()" *ngSwitchCase="'FORM_GROUP'">
      <div class="vesto-input mt-3">
        <label for="oldPassword">Old Password</label>
        <gbl-input mask="password" formControlName="oldPassword" id="oldPassword"></gbl-input>
      </div>

      <div class="vesto-input mt-3">
        <label for="newPassword">New Password</label>
        <gbl-input mask="strong-password" formControlName="newPassword" id="newPassword"></gbl-input>
      </div>

      <gbl-alert [type]="error" [message]="error"></gbl-alert>

      <div class="bg-light-gold rounded-lg p-3 mt-4 flex text-darker-gold text-xs items-center">
        <i class="fal fa-exclamation-triangle text-2xl mr-4"></i>
        <p>
          <b>Changing your password will log you out.</b> After successfully changing your password you will need to log in again with your new password.
        </p>
        <p>
          After you reset your password you will not be able to perform any transactions until your signing address has been swapped on the blockchain. <span class="font-bold">This could take 5-10 minutes.</span>
        </p>
      </div>

      <button class="button button-primary mt-5 w-full" type="submit">Change Password</button>
    </form>

    <section *ngSwitchCase="'BUSY'" class="mt-4">
      <div class="placeholder-long bg-flash"></div>
      <div class="placeholder-area bg-flash mt-4"></div>
    </section>

    <section *ngSwitchCase="'SUCCESS'">
      <gbl-lottie-player></gbl-lottie-player>
    </section>

    <section *ngSwitchCase="'DETAIL'" class="slide-in">
      <p class="text-center">Success!</p>
      <div class="p-4 bg-trans-blue rounded-lg text-center mt-2">
        <span class="text-sm">You have successfully changed your password. Please log in with your new password.</span>
      </div>
      <button class="button button-primary mt-4 mx-auto w-full" (click)="close()">Close</button>
    </section>
  </ng-container>
</mat-dialog-content>
