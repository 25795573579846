import { IEnvironment } from '../interfaces/environment.interface';
import { IHttpClient } from '../interfaces/http.interface';
import { BaseApi } from './base-api';

export class AlertsApi extends BaseApi {
  constructor(protected environment: IEnvironment, protected httpClient: IHttpClient) {
    super(environment);
  }

  findByInstitution(userId: string, institutionId: string) {
    return this.httpClient.get(this.formatUrl(`/v${this.environment.vesto.version}/alerts/find-by-institution/${userId}/${institutionId}`), { headers: this.getHeaders() });
  }

  findByUser(userId: string) {
    return this.httpClient.get(this.formatUrl(`/v${this.environment.vesto.version}/alerts/find-by-user/${userId}`), { headers: this.getHeaders() });
  }

  approve(alertId: string, percentage?: number) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/alerts/approve/${alertId}`), { percentage }, { headers: this.getHeaders() });
  }

  deny(alertId: string) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/alerts/deny/${alertId}`), null, { headers: this.getHeaders() });
  }
}
