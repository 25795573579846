import {Component, OnInit} from "@angular/core";
import {BaseComponent, CoreService} from "@vesto/xplat/core";
import {IStore, IUser} from "@vesto/vesto";
import {filter, takeUntil} from "rxjs/operators";
import {FeatureService} from "@vesto/xplat/features";

@Component({
  selector: 'gbl-stores',
  templateUrl: 'stores.component.html'
})

export class StoresComponent extends BaseComponent implements OnInit {
  stores: IStore[] = [];
  user: IUser

  ngOnInit() {
    CoreService.storeFacade.stores$
      .pipe(
        filter(store => !!store),
        takeUntil(this.destroy$)
      )
      .subscribe((stores) => {
        this.stores = stores;
    });
    CoreService.userFacade.user$
      .pipe(
        filter(user => !!user),
        takeUntil(this.destroy$)
      )
      .subscribe((user) => {
        this.user = user
      });
  }

  openCreateStore() {
    FeatureService.dashboardFacade.openOrGoToStore(this.user.merchant);
  }

  changeStore(store: IStore) {
    FeatureService.dashboardFacade.openOrGoToStore(this.user.merchant, store);
  }
}
