import { IEnvironment } from '@vesto/vesto';
import { deepMerge } from '@vesto/xplat/utils';
import { environmentBase } from './environment.base';

export const environmentProd = deepMerge(environmentBase, <IEnvironment>{
  production: true,
  enableAnalytics: false,
  name: 'production',
  appId: 'io.vesto.app',
  vesto: {
    url: 'https://api.vesto.io',
    wsUrl: 'wss://api.vesto.io/ws',
    version: 1,
    institutionId: 'd9472c8c-f24e-48df-965c-fcb2113cb77a'
  },
  plaid: {
    publicKey: 'e9278653f6e9ce853085557fe84126',
    env: 'production'
  },
  onfido: {
    api: 'https://api.onfido.com/v2',
    apiKey: 'api_live.d3OvBV72bBv.1N7fbMKi-gl9ZQ5oTTF1JxkgOyp2w2Vn'
  },
  enableSignIn: true
});


