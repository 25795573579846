import { SignUpComponent } from './sign-up/sign-up.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { VerifyMobileComponent } from './verify-mobile/verify-mobile.component';
import { CreateUserComponent } from './create-user/create-user.component';

export const SIGNUP_COMPONENTS = [SignUpComponent, VerifyEmailComponent, VerifyMobileComponent, CreateUserComponent];

export * from './sign-up/sign-up.component';
export * from './verify-email/verify-email.component';
export * from './verify-mobile/verify-mobile.component';
export * from './create-user/create-user.component';
