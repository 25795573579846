import { Component } from '@angular/core';
import { BaseComponent } from '@vesto/xplat/core';

@Component({
  selector: 'gbl-not-found',
  templateUrl: 'not-found.component.html'
})
export class NotFoundComponent extends BaseComponent {
  constructor() {
    super();
  }
}
