<form [formGroup]="formGroup" (ngSubmit)="confirm()" class="slide-in">
  <div class="bg-trans-blue br-10 p-2 flex items-center mb-3">
    <div class="fa-stack text-center mr-2">
      <i class="fas fa-circle fa-stack-2x opacity-50 text-white"></i>
      <i class="fal fa-mobile-android fa-stack-1x"></i>
    </div>
    <div>
      <span class="block text-sm font-bold">This action requires two-factor authentication</span>
      <span class="text-xs">Enter the code sent to your mobile ending in {{ user?.mobileLast4 }}.</span>
    </div>
  </div>
  <div class="vesto-input vesto-input-inline mb-3">
    <label for="code">Code</label>
    <gbl-input mask="code" type="code" focusMe="true" formControlName="code" id="code" name="code"></gbl-input>
  </div>
  <div class="mat-small mt-3">Didn't get a code? <a class="underline" (click)="sendSmsCode.emit(true)">Click here to resend.</a></div>

  <gbl-alert [type]="error" [message]="error"></gbl-alert>

  <button class="button button-primary w-full mt-4" type="submit">Confirm</button>
</form>
