<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <h3 class="section-number"><i class="fal fa-id-card"></i> {{ user.status === UserStatus.KYC_CONSIDER ? 'KYC Clear' : 'KYC Consider' }}</h3>
</div>
<mat-dialog-content [ngSwitch]="step">
  <form [formGroup]="formGroup" (ngSubmit)="clearConsider()" *ngSwitchCase="'FORM_GROUP'">
    <p class="text-sm mb-4">
      Set user <b>{{ user.firstName }} {{ user.lastName }}'s</b> KYC status to <span class="italic">{{ user.status === UserStatus.KYC_CONSIDER ? 'clear' : 'consider' }}?</span>
    </p>

    <div class="vesto-input">
      <textarea formControlName="memo" placeholder="Enter memo here"></textarea>
    </div>

    <gbl-alert [type]="error" [message]="error"></gbl-alert>

    <div class="text-right mt-3">
      <button class="button button-primary" type="submit">Submit</button>
    </div>
  </form>

  <section *ngSwitchCase="'BUSY'" class="mt-4">
    <div class="placeholder-long bg-flash"></div>
    <div class="placeholder-area bg-flash mt-4"></div>
  </section>

  <section *ngSwitchCase="'SUCCESS'">
    <gbl-lottie-player></gbl-lottie-player>
  </section>

  <section *ngSwitchCase="'DETAIL'">
    <div class="slide-in">
      <div class="text-center">
        <p>Success!</p>
      </div>
      <div class="p-4 bg-trans-blue rounded-lg">
        You set <b>{{ user.firstName }} {{ user.lastName }}'s</b> KYC status to <span class="italic">{{ user.status === UserStatus.KYC_CONSIDER ? 'clear' : 'consider' }}</span>
      </div>
      <button class="button button-primary my-4 mx-auto w-full" (click)="close()">Close</button>
    </div>
  </section>
</mat-dialog-content>
