<div class="{{ mask === 'currency' ? 'currency-input' : '' }}" [class.vesto-input-dark]="inputDark">
  <div *ngIf="mask === 'mobile'" class="vesto-country-select">
    <ng-select #select [ngModel]="countryCode" (ngModelChange)="countryCodeChange($event)" [clearable]="false" [searchable]="false">
      <ng-template ng-header-tmp>
        <div class="p-2">
          <input type="text" (input)="select.filter($event.target.value)" placeholder="Search..." />
        </div>
      </ng-template>
      <ng-template ng-label-tmp *ngIf="countryCode" class="flex items-center"> <img src="./assets/icons/flags/{{ countryCode.code }}.svg" class="w-6 mr-2" /> {{ countryCode.dial_code }} </ng-template>
      <ng-option *ngFor="let countryCode of countryCodes" [value]="countryCode">
        <div class="flex items-center"><img src="./assets/icons/flags/{{ countryCode.code }}.svg" class="w-6 mr-2" /> {{ countryCode.dial_code }} {{ countryCode.name }}</div>
      </ng-option>
    </ng-select>
  </div>

  <div *ngIf="mask === 'password' || mask === 'strong-password'" class="show-hide-password text-grey-dark cursor-pointer">
    <span (click)="showPassword = !showPassword">
      <i class="{{ showPassword ? 'fas fa-eye fa-fw' : 'fas fa-eye-slash fa-fw' }}"></i>
    </span>
  </div>

  <input #input [ngStyle]="{ 'padding-left': mask === 'mobile' ? '100px' : '' }" [class.vesto-currency-input]="mask === 'currency'" [ngModel]="value" (ngModelChange)="inputChange($event)" (blur)="inputBlur($event)" [cleave]="options" [type]="showPassword ? 'password' : 'text'" />

  <div *ngIf="mask === 'percent'" style="position: absolute; right: 10px; top: 8px">%</div>

  <div *ngIf="mask === 'strong-password'" class="mt-3 px-2">
    <mat-password-strength #passwordComponent [password]="input.value" [customValidator]="strongPasswordPattern"></mat-password-strength>
    <div class="mt-2 mat-small bold">
      Use at least <span [style.font-weight]="passwordComponent.containAtLeastMinChars ? 'bold' : 'normal'">8 characters</span>
      including <span [style.font-weight]="passwordComponent.containAtLeastOneDigit ? 'bold' : 'normal'">one number, </span>
      <span [style.font-weight]="passwordComponent.containAtLeastOneSpecialChar ? 'bold' : 'normal'">one special character</span>
      and <span [style.font-weight]="passwordComponent.containAtLeastOneUpperCaseLetter ? 'bold' : 'normal'">one capital letter.</span>
    </div>
  </div>
</div>
