import { Injectable, NgZone } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { AccountFacade, BankAccountFacade, SystemFacade, UserActions, UserFacade } from '@vesto/ngx-vesto/state';
import { Observable } from 'rxjs';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import {
  BuySellModalComponent,
  ChangeEmailModalComponent,
  ChangeMobileModalComponent,
  ChangePasswordModalComponent,
  ChangePersonalInfoModalComponent,
  AddPaymentMethodModalComponent,
  CreateAccountModalComponent,
  AddUserModalComponent,
  SendReceiveModalComponent,
  TransactionDetailModalComponent,
  VerifyIdentityModalComponent,
  UserSearchModalComponent,
  KycClearConsiderModalComponent,
  SuspendActivateModalComponent,
  ChangeAmlModalComponent,
  InstitutionModalComponent,
  InviteUserModalComponent,
  ElementModalComponent,
  ChangeGasTankModalComponent,
  ChangeCurrencyModalComponent,
  ChooseBlockchainModalComponent,
  TwoFactorModalComponent,
  ChooseTokenModalComponent,
  AddYearnVaultModalComponent,
  TransferGasModalComponent,
  MerchantModalComponent,
  ChangeRoleModalComponent,
  SetSettleLimitsModalComponent,
  CreateAndDeployTokenModalComponent,
  FiatBalancesModalComponent,
  SetDailyLimitsModalComponent,
  AdditionalUserModalComponent,
  SwapSigningAddressModalComponent,
  AddToMerchantModalComponent,
  SetSupplyLimitModalComponent, StoreModalComponent
} from '../components';
import { IBankAccount, IUser, BankAccountSubType, BankAccountType, UserStatus } from '@vesto/vesto';
import { DashboardActions, DashboardFacade, DashboardState } from '@vesto/xplat/features';
import { Action, Store } from '@ngrx/store';
import { CoreService, environment, UiActions, uiCloseModalAction, WindowService } from '@vesto/xplat/core';
import { NgxPlaidLinkService, LegacyPlaidConfig, PlaidLinkHandler } from 'ngx-plaid-link';
import { RequestFormDialogComponent } from '../../ui/components';
import { uxSettings } from '../../utils';

const modalOptions = uxSettings.modalOptions;

@Injectable()
export class WebDashboardEffects {
  private onfidoInstance: any;
  private onfidoClosing = false;
  private plaidLinkHandler: PlaidLinkHandler;
  private plaidConfig: LegacyPlaidConfig = {
    apiVersion: 'v2',
    env: environment.plaid.env,
    countryCodes: ['US', 'CA'],
    key: environment.plaid.publicKey,
    product: ['auth'],
    onSuccess: (token, metadata) => {
      if (metadata && metadata.accounts) {
        const bankAccounts: IBankAccount[] = [];
        metadata.accounts.forEach((plaidAccount) => {
          if (plaidAccount.subtype === 'checking') {
            const bankAccount: IBankAccount = {
              id: null,
              plaid: {
                accountId: plaidAccount.id,
                publicToken: metadata.public_token
              },
              type: BankAccountType.CHECKING,
              subType: BankAccountSubType.DEPOSITORY,
              name: plaidAccount.name,
              institution: metadata.institution.name,
              mask: plaidAccount.mask,
              updated: null,
              created: null
            };
            bankAccounts.push(bankAccount);
          }
        });
        this.store.dispatch(DashboardActions.plaidSuccess({ bankAccounts }));
      }
    },
    onExit: (error, metadata) => {}
  };

  constructor(private systemFacde: SystemFacade, private ngZone: NgZone, private store: Store<DashboardState.IState>, private actions$: Actions, private ngxPlaidLinkService: NgxPlaidLinkService, private windowService: WindowService, private router: Router, private userFacade: UserFacade, private accountFacade: AccountFacade, private dashboardFacade: DashboardFacade) {}

  openOrGoToCreateAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToCreateAccount),
      uxSettings.modalRxFlow(),
      map(({}) =>
        UiActions.openModal({
          cmpType: CreateAccountModalComponent,
          modalOptions
        })
      )
    )
  );

  openOrGoToAddPaymentMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToAddbankAccount),
      uxSettings.modalRxFlow(),
      map(({}) =>
        UiActions.openModal({
          cmpType: AddPaymentMethodModalComponent,
          modalOptions
        })
      )
    )
  );

  openOrGoToAddUser$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(DashboardActions.openOrGoToAddUser),
        withLatestFrom(this.userFacade.user$, this.accountFacade.accounts$, this.dashboardFacade.selectedAccount$),
        mergeMap(([{}, user, accounts, selectedAccount]) => {
          if (user.status === UserStatus.IDENTITY_NOT_VERIFIED || user.status === UserStatus.VERIFYING_IDENTITY) {
            return [DashboardActions.openOrGoToVerifyIdentity()];
          } else {
            return [
              UiActions.openModal({
                cmpType: AddUserModalComponent,
                modalOptions: {
                  ...modalOptions,
                  minHeight: '540px',
                  panelClass: 'overflow-dialog'
                },
                props: {
                  accounts: accounts,
                  selectedAccount: selectedAccount
                }
              })
            ];
          }
        })
      )
  );

  openOrGoToChoosePlaidAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToChoosePlaidAccount),
      map(({ bankAccounts }) =>
        UiActions.openModal({
          cmpType: AddPaymentMethodModalComponent,
          modalOptions: {
            ...modalOptions,
            minHeight: '320px'
          },
          props: {
            bankAccounts
          }
        })
      )
    )
  );

  openOrGoToTransferGas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToTransferGas),
      map(({ blockchain, gasTankId }) =>
        UiActions.openModal({
          cmpType: TransferGasModalComponent,
          modalOptions: {
            ...modalOptions,
            minHeight: '320px'
          },
          props: {
            blockchain,
            gasTankId
          }
        })
      )
    )
  );

  openOrGoToVerifyIdentity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToVerifyIdentity),
      map(({}) =>
        UiActions.openModal({
          cmpType: VerifyIdentityModalComponent,
          modalOptions
        })
      )
    )
  );

  openOrGoToSetSettleLimits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToSetSettleLimits),
      map(({}) =>
        UiActions.openModal({
          cmpType: SetSettleLimitsModalComponent,
          modalOptions
        })
      )
    )
  );

  openOrGoToCreateAndDeployTokens$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToCreateAndDeployTokens),
      map(({institutionId}) => {
        return UiActions.openModal({
          cmpType: CreateAndDeployTokenModalComponent,
          modalOptions: {
            ...modalOptions
          },
          props: {
            institutionId
          }
        })}
      )
    )
  );

  openOrGoToTransactionDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToTransactionDetail),
      withLatestFrom(this.userFacade.user$, this.dashboardFacade.selectedAccount$),
      map(([{ transaction }, user, selectedAccount]) =>
        UiActions.openModal({
          cmpType: TransactionDetailModalComponent,
          modalOptions,
          props: {
            user: user,
            selectedAccount: selectedAccount,
            transaction: transaction
          }
        })
      )
    )
  );

  openOrGoToBuySell$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(DashboardActions.openOrGoToBuySell),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{}, user]) => {
          if (user.status === UserStatus.IDENTITY_NOT_VERIFIED) {
            return [DashboardActions.openOrGoToVerifyIdentity()];
          } else {
            return [
              UiActions.openModal({
                cmpType: BuySellModalComponent,
                modalOptions: {
                  ...modalOptions,
                  minHeight: '560px'
                }
              })
            ];
          }
        })
      )
  );

  openOrGoToSendReceive$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(DashboardActions.openOrGoToSendReceive),
        withLatestFrom(this.userFacade.user$, this.accountFacade.accounts$, this.dashboardFacade.selectedAccount$, this.dashboardFacade.selectedSymbol$),
        mergeMap(([{}, user, accounts, selectedAccount, selectedSymbol]) => {
          if (user.status === UserStatus.IDENTITY_NOT_VERIFIED || user.status === UserStatus.VERIFYING_IDENTITY) {
            return [DashboardActions.openOrGoToVerifyIdentity()];
          } else {
            return [
              UiActions.openModal({
                cmpType: SendReceiveModalComponent,
                modalOptions: {
                  ...modalOptions,
                  minHeight: '660px'
                },
                props: {
                  user: user,
                  accounts: accounts,
                  selectedAccount: selectedAccount,
                  selectedSymbol: selectedSymbol
                }
              })
            ];
          }
        })
      )
  );

  openOrGoToChangePersonalInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToChangePersonalInfo),
      withLatestFrom(this.userFacade.user$),
      map(([{}, user]) =>
        UiActions.openModal({
          cmpType: ChangePersonalInfoModalComponent,
          modalOptions,
          props: {
            user
          }
        })
      )
    )
  );

  openOrGoToChangeEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToChangeEmail),
      withLatestFrom(this.userFacade.user$),
      map(([{}, user]) =>
        UiActions.openModal({
          cmpType: ChangeEmailModalComponent,
          modalOptions,
          props: {
            user
          }
        })
      )
    )
  );

  openOrGoToChangeMobile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToChangeMobile),
      withLatestFrom(this.userFacade.user$),
      map(([{}, user]) =>
        UiActions.openModal({
          cmpType: ChangeMobileModalComponent,
          modalOptions: {
            ...modalOptions,
            minHeight: '400px'
          },
          props: {
            user
          }
        })
      )
    )
  );

  openOrGoToChangePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToChangePassword),
      map(({}) =>
        UiActions.openModal({
          cmpType: ChangePasswordModalComponent,
          modalOptions
        })
      )
    )
  );

  openOrGoToUserSearch$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(DashboardActions.openOrGoToUserSearch),
        mergeMap(({}) => [
          UiActions.openModal({
            cmpType: UserSearchModalComponent,
            modalOptions
          }),
          UserActions.clearUsers()
        ])
      )
  );

  openOrGoToKycClearConsider$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToKycClearConsider),
      map(({}) =>
        UiActions.openModal({
          cmpType: KycClearConsiderModalComponent,
          modalOptions
        })
      )
    )
  );

  openOrGoToSuspendActivate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToSuspendActivate),
      map(({}) =>
        UiActions.openModal({
          cmpType: SuspendActivateModalComponent,
          modalOptions
        })
      )
    )
  );

  openSetDailyLimits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openSetDailyLimits),
      map(({}) =>
        UiActions.openModal({
          cmpType: SetDailyLimitsModalComponent,
          modalOptions
        })
      )
    )
  );

  openOrGoToChangeAml$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToChangeAml),
      map(({}) =>
        UiActions.openModal({
          cmpType: ChangeAmlModalComponent,
          modalOptions
        })
      )
    )
  );

  openOrGoToInviteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToInviteUser),
      map(({}) =>
        UiActions.openModal({
          cmpType: InviteUserModalComponent,
          modalOptions
        })
      )
    )
  );

  openOrGoToInviteToMerchant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToInviteToMerchant),
      map(({}) =>
        UiActions.openModal({
          cmpType: AddToMerchantModalComponent,
          modalOptions
        })
      )
    )
  );

  openOrGoToElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToElement),
      map(({}) =>
        UiActions.openModal({
          cmpType: ElementModalComponent,
          modalOptions
        })
      )
    )
  );

  openOrGoToMerchant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToMerchant),
      map(({ action, merchant }) =>
        UiActions.openModal({
          cmpType: MerchantModalComponent,
          modalOptions,
          props: {
            action,
            merchant
          }
        })
      )
    )
  );

  openOrGoToStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToStore),
      map(({ merchant, store }) =>
        UiActions.openModal({
          cmpType: StoreModalComponent,
          modalOptions,
          props: {
            merchant,
            store
          }
        })
      )
    )
  );

  openOrGoToChangeGasTank$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToChangeGasTank),
      map(({ blockchain, gasTank }) =>
        UiActions.openModal({
          cmpType: ChangeGasTankModalComponent,
          modalOptions,
          props: {
            blockchain,
            gasTank
          }
        })
      )
    )
  );

  openOrGoToInstitution$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToInstitution),
      map(({ action, institution }) =>
        UiActions.openModal({
          cmpType: InstitutionModalComponent,
          modalOptions,
          props: {
            action,
            institution
          }
        })
      )
    )
  );

  openOrGoToBlockchain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToChooseBlockchain),
      map(({ data }) =>
        UiActions.openModal({
          cmpType: ChooseBlockchainModalComponent,
          modalOptions,
          props: data
        })
      )
    )
  );

  openOrGoToChooseToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToChooseToken),
      map(({ data }) =>
        UiActions.openModal({
          cmpType: ChooseTokenModalComponent,
          modalOptions: {
            ...modalOptions,
            minHeight: '440px'
          },
          props: data
        })
      )
    )
  );

  closeModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.closeModal),
      map(() => uiCloseModalAction())
    )
  );

  openPlaid$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActions.openPlaid),
        map(() => {
          this.ngxPlaidLinkService.createPlaid(this.plaidConfig).then((handler: PlaidLinkHandler) => {
            this.plaidLinkHandler = handler;
            this.plaidLinkHandler.open();
          });
        })
      ),
    { dispatch: false }
  );

  openOnfido$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActions.openOnfido),
        withLatestFrom(this.userFacade.user$),
        map(([{ token }, user]) => {
          this.initOnfido(user, token);
        })
      ),
    { dispatch: false }
  );

  openOrGoToChangeTokenApy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToChangeTokenApy),
      map(({ token }) =>
        UiActions.openModal({
          cmpType: ChangeCurrencyModalComponent,
          modalOptions,
          props: {
            token
          }
        })
      )
    )
  );

  openOrGoToRequestReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToRequestReport),
      map(({ title, documentName }) =>
        UiActions.openModal({
          cmpType: RequestFormDialogComponent,
          modalOptions,
          props: {
            title,
            documentName
          }
        })
      )
    )
  );

  openOrGoToTwoFactor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToTwoFactor),
      map(({ action, transaction, additionalUser, contract, token }) =>
        UiActions.openModal({
          cmpType: TwoFactorModalComponent,
          modalOptions,
          props: {
            action,
            transaction,
            additionalUser,
            contract,
            token
          }
        })
      )
    )
  );

  openOrGoToAddYearnVault$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToAddYearnVault),
      map(({}) =>
        UiActions.openModal({
          cmpType: AddYearnVaultModalComponent,
          modalOptions
        })
      )
    )
  );

  openOrGoToSetSupplyLimit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToSetSupplyLimit),
      map(({}) =>
        UiActions.openModal({
          cmpType: SetSupplyLimitModalComponent,
          modalOptions
        })
      )
    )
  );

  openOrGoToChangeRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openChangeRole),
      map(({}) =>
        UiActions.openModal({
          cmpType: ChangeRoleModalComponent,
          modalOptions,
        })
      )
    )
  );

  openOrGoToSwapSigningAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openOrGoToSwapSigningAddress),
      map(({}) =>
        UiActions.openModal({
          cmpType: SwapSigningAddressModalComponent,
          modalOptions,
        })
      )
    )
  );

  openFiatBalances$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openFiatBalances),
      map(({}) =>
        UiActions.openModal({
          cmpType: FiatBalancesModalComponent,
          modalOptions
        })
      )
    )
  );

  openAdditionalUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.openAdditionalUsers),
      map(({}) =>
        UiActions.openModal({
          cmpType: AdditionalUserModalComponent,
          modalOptions: {
            ...modalOptions,
            panelClass: 'overflow-dialog'
          }
        })
      )
    )
  );

  initOnfido(user: IUser, token: string) {
    this.onfidoInstance = this.windowService.Onfido.init({
      token,
      containerId: 'onfido-mount',
      useModal: true,
      isModalOpen: true,
      shouldCloseOnOverlayClick: false,
      smsNumberCountryCode: 'US', // TODO: make i18n
      language: 'en_US', // TODO: get my locale from store!!
      forceCrossDevice: true,
      userDetails: {
        smsNumber: user.mobile
      },
      steps: [
        {
          type: 'welcome',
          options: {
            title: `Thank you for that information! Just a few more steps to complete your identity verification.`,
            descriptions: [`Click the button below to begin this quick and secure process.`]
          }
        },
        'document',
        'face'
      ],
      onComplete: (data: any) => {
        if (this.onfidoInstance && !this.onfidoClosing) {
          // onComplete hits max callstack so prevent from repeatedly being invoked
          this.onfidoClosing = true;
          this.onfidoInstance.setOptions({ isModalOpen: false });
          this.closeOnfido();
          this.ngZone.run(() => {
            this.store.dispatch(DashboardActions.onfidoSuccess({ data: data }));
          });
        }
      },
      onError: (error: any) => {
        this.store.dispatch(DashboardActions.onfidoFailure({ error }));
        CoreService.uiFacade.notification('Onfido verification failed, please try again');
      },
      onModalRequestClose: () => {
        if (this.onfidoInstance && !this.onfidoClosing) {
          // onModalRequestClose can max callstack so prevent from repeatedly being invoked
          this.onfidoClosing = true;
          this.onfidoInstance.setOptions({ isModalOpen: false });
          this.closeOnfido();
        }
      }
    });
  }

  closeOnfido() {
    if (this.onfidoInstance) {
      // TODO: determine if we really need to tear whole sdk down here
      if (this.onfidoInstance.tearDown) {
        this.onfidoInstance.tearDown();
        this.onfidoInstance = null;
      }
    }
    // give some space before resetting
    setTimeout(() => {
      this.onfidoClosing = false;
    }, 2000);
  }
}
