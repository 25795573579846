import { createAction, props } from '@ngrx/store';
import { IApiError, IBankAccount } from '@vesto/vesto';

export namespace BankAccountActions {
  export const createLinkToken = createAction('[@vesto/bank-account] createLinkToken', props<{ extras?: any }>());

  export const createLinkTokenSuccess = createAction('[@vesto/bank-account] createLinkTokenSuccess', props<{ token: string; extras?: any }>());

  export const createLinkTokenFailure = createAction('[@vesto/bank-account] createLinkTokenFailure', props<{ apiError: IApiError }>());

  export const findByUser = createAction('[@vesto/bank-account] findByUser');

  export const findByUserSuccess = createAction('[@vesto/bank-account] findByUserSuccess', props<{ bankAccounts: IBankAccount[] }>());

  export const findByUserFailure = createAction('[@vesto/bank-account] findByUserFailure', props<{ apiError: IApiError }>());

  export const create = createAction('[@vesto/bank-account] create', props<{ bankAccount: IBankAccount }>());

  export const createSuccess = createAction('[@vesto/bank-account] createSuccess', props<{ bankAccount: IBankAccount }>());

  export const createFailure = createAction('[@vesto/bank-account] createFailure', props<{ apiError: IApiError }>());

  export const setDefault = createAction('[@vesto/bank-account] setDefault', props<{ id: string }>());

  export const setDefaultSuccess = createAction('[@vesto/bank-account] setDefaultSuccess', props<{ bankAccount: IBankAccount }>());

  export const setDefaultFailure = createAction('[@vesto/bank-account] setDefaultFailure', props<{ apiError: IApiError }>());

  export const remove = createAction('[@vesto/bank-account] remove', props<{ id: string }>());

  export const removeSuccess = createAction('[@vesto/bank-account] removeSuccess', props<{ bankAccount: IBankAccount }>());

  export const removeFailure = createAction('[@vesto/bank-account] removeFailure', props<{ apiError: IApiError }>());
}
