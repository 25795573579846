export class FeatureSwitch {
  static enableDevMessages = false;
  static localeId: string;
  static launchWithDemoAccount = null;
  static isDemoMode = false;
  static startingTab = 0;
  /**
   * mock api endpoints with local .json files
   * Setting mockEndpoints to an object of api path's mapped to the location in the app where the mock json files are
   * {
   *   '/sample/listings': 'assets/json'
   * }
   */
  static mockEndpoints: any;
}
