import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ProgressService, UiActions } from '@vesto/xplat/core';
import { map, mergeMap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { from, Observable } from 'rxjs';
import { UserActions } from '@vesto/ngx-vesto/state';
import { DashboardActions } from '@vesto/xplat/features';

@Injectable()
export class WebCoreEffects {
  constructor(private actions$: Actions, private progress: ProgressService, private store: Store<any>, private matSnackBar: MatSnackBar) {}

  signOut$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UserActions.signOut),
        mergeMap(() => {
          return from([
            DashboardActions.init(),
            UiActions.go({
              path: ['/']
            })
          ]);
        })
      )
  );

  notification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UiActions.notification),
        map(({ message }) => {
          this.matSnackBar.open(message, 'dismiss', {
            duration: 6500,
            verticalPosition: 'top'
          });
        })
      ),
    { dispatch: false }
  );
}
