import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IInstitution, IToken, InstitutionFeeType} from '@vesto/vesto';
import { Observable } from 'rxjs';
import { InstitutionActions } from './institution.actions';
import { VestoState, VestoSelectors } from '../vesto.state';

@Injectable({
  providedIn: 'root'
})
export class InstitutionFacade {
  institution$: Observable<IInstitution> = this.store.pipe(select(VestoSelectors.institution));
  institutions$: Observable<IInstitution[]> = this.store.pipe(select(VestoSelectors.institutions));

  constructor(private store: Store<VestoState.IState>) {}

  set(
    institution: IInstitution
  ) {
    this.store.dispatch(InstitutionActions.set({institution}));
  }

  setToken(
    token: IToken
  ) {
    this.store.dispatch(InstitutionActions.setToken({token}));
  }

  create(
    name: string,
    adminEmail: string,
    iosAppId: string,
    androidAppId: string,
    url: string,
    referrer: string,
    merchantEnabled: boolean
  ) {
    this.store.dispatch(InstitutionActions.create({ name, adminEmail, iosAppId, androidAppId, url, referrer, merchantEnabled }));
  }

  createAndDeployToken(
    institutionId: string,
    name: string,
    symbol: string,
    underlyingSymbol: string,
    code: string
  ) {
    this.store.dispatch(
      InstitutionActions.createAndDeployTokens({
        institutionId,
        name,
        symbol,
        underlyingSymbol,
        code
      })
    );
  }

  changeSettings(
    id: string,
    name: string,
    address: string,
    iosAppId: string,
    androidAppId: string,
    referrer: string,
    url: string,
    merchantEnabled: boolean,
    instantSettleEnabled: boolean,
    settleTransactionLimit: number,
    settleDailyLimit: number,
    feeType: InstitutionFeeType,
    feeFixed: number,
    feePercentage: number
  ) {
    this.store.dispatch(
      InstitutionActions.changeSettings({
        id,
        name,
        address,
        iosAppId,
        androidAppId,
        referrer,
        url,
        merchantEnabled,
        instantSettleEnabled,
        settleTransactionLimit,
        settleDailyLimit,
        feeType,
        feeFixed,
        feePercentage
      })
    );
  }

  changeTemplate(
    templateId: string,
    referenceId: string
  ) {
    this.store.dispatch(InstitutionActions.changeTemplate({ templateId, referenceId }));
  }

  changeAml(defaultDailyBuyLimit: number, defaultDailySellLimit: number, defaultDailyTransferLimit: number, defaultDailyReceiveLimit: number, plaidEnabled: boolean) {
    this.store.dispatch(
      InstitutionActions.changeAml({
        defaultDailyBuyLimit,
        defaultDailySellLimit,
        defaultDailyTransferLimit,
        defaultDailyReceiveLimit,
        plaidEnabled
      })
    );
  }

  changeDeFi(apy: number) {
    this.store.dispatch(InstitutionActions.changeDeFi({ apy }));
  }

  find(): void {
    this.store.dispatch(InstitutionActions.find());
  }

  findByUser(): void {
    this.store.dispatch(InstitutionActions.findByUser());
  }

  findAll(): void {
    this.store.dispatch(InstitutionActions.findAll());
  }

  sendSmsCode(
  ) {
    this.store.dispatch(InstitutionActions.sendSmsCode());
  }
}
