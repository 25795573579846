import {Directive, OnInit} from '@angular/core';
import {BaseComponent, CoreService} from '@vesto/xplat/core';
import {filter, takeUntil} from 'rxjs/operators';
import {Section} from '../state';
import {FeatureService} from "../../services";

@Directive()
export abstract class DashboardBaseComponent extends BaseComponent implements OnInit {
  selectedSection: string;
  Section = Section;

  protected constructor() {
    super();
    FeatureService.dashboardFacade.selectedSection$.pipe(
      filter(selectedSection => !!selectedSection),takeUntil(this.destroy$)).subscribe((selectedSection) => {
      this.selectedSection = selectedSection;
    });
  }

  ngOnInit() {
    // CoreService.windowService.setTimeout(() => {
    //   FeatureService.dashboardFacade.selectedSection$.pipe(
    //     filter(selectedSection => !!selectedSection),takeUntil(this.destroy$)).subscribe((selectedSection) => {
    //     this.selectedSection = selectedSection;
    //   });
    // }, 100);
  }
}
