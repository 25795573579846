<form [formGroup]="formGroup" (ngSubmit)="validateAndSignUp()">
  <h3 class="mt-2">Create your account <span *ngIf="user?.institutionName"> for {{ user.institutionName }}</span></h3>
  <div class="flex justify-around flex-wrap mt-4 form-dark">
    <span *ngIf="launchOptions && launchOptions.isJointAccountInvite" class="text-center mt-2 w-full">Sign up below to accept the invite.</span>
    <div class="w-full md:w-1/2 md:pr-4">
      <div class="vesto-input">
        <label for="firstName">First Name</label>
        <input type="text" focusMe="true" formControlName="firstName" id="firstName" name="firstName" />
      </div>
    </div>
    <div class="w-full md:w-1/2">
      <div class="vesto-input">
        <label for="lastName">Last Name</label>
        <input type="text" formControlName="lastName" id="lastName" name="lastName" />
      </div>
    </div>
    <div class="w-full mt-3">
      <div class="vesto-input">
        <label for="email">Email</label>
        <input type="text" formControlName="email" id="email" name="email" [disabled]="user?.institutionName !== null" />
      </div>
    </div>

    <div class="w-full mt-3">
      <div class="vesto-input">
        <label for="password">Password</label>
        <gbl-input mask="strong-password" formControlName="password" id="password" name="password"></gbl-input>
      </div>
    </div>
  </div>

  <gbl-alert [type]="error" [message]="error" [dark]="true"></gbl-alert>

  <button class="button button-lilac w-full mt-6" type="submit">Sign Up</button>
  <div class="text-sm m-2">By continuing you agree to Vesto's <a href="/legal" target="" class="underline">Terms of Service</a></div>
  <div class="text-xs mt-4 text-center text-grey">Already have an account? <a (click)="signIn()" class="uppercase font-bold text-lilac">Sign In</a></div>
</form>
