import { IEnvironment } from '../interfaces/environment.interface';
import { IHttpClient } from '../interfaces/http.interface';
import { BaseApi } from './base-api';
import { Blockchain } from '../enums/blockchain.enum';
import { ITransaction } from '../interfaces/transaction.interface';
import { CryptoUtil } from '../utils/crypto-util';
import {RoleType} from "../enums/role-type.enum";
import {TransactionStatus} from '../enums/transaction-status.enum';
import {TransactionType} from '../enums/transaction.type.enum';

export class TransactionsApi extends BaseApi {
  constructor(protected environment: IEnvironment, protected httpClient: IHttpClient) {
    super(environment);
  }

  find(transactionId: string) {
    return this.httpClient.get(this.formatUrl(`/v${this.environment.vesto.version}/transactions/find/${transactionId}`), {
      headers: {
        ...this.getHeaders()
      }
    });
  }

  findByAccount(
    userId: string,
    accountId: string,
    page: number,
    size: number
  ) {
    return this.httpClient.get(this.formatUrl(`/v${this.environment.vesto.version}/transactions/find-by-account/${userId}/${accountId}?page=${page}&size=${size}`), {
      headers: {
        ...this.getHeaders()
      }
    });
  }

  findByAccountAndSymbol(accountId: string, symbol: string, page: number, size: number) {
    return this.httpClient.get(this.formatUrl(`/v${this.environment.vesto.version}/transactions/find-by-account-and-symbol/${accountId}/${symbol}?page=${page}&size=${size}`), {
      headers: {
        ...this.getHeaders()
      }
    });
  }

  findBuyOrSellActionRequired(
    page: number,
    size: number
  ) {
    return this.httpClient.get(
      this.formatUrl(`/v${this.environment.vesto.version}/transactions/find-buy-or-sell-action-required?page=${page}&size=${size}`),
      {
        headers: {
          ...this.getHeaders()
        }
      });
  }

  findByTypeAndStatus(
    type: TransactionType,
    status: TransactionStatus,
    page: number,
    size: number
  ) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/transactions/find-by-type-and-status?page=${page}&size=${size}`),
      {
        type,
        status
      },
      {
        headers: {
          ...this.getHeaders()
        }
      }
    );
  }

  changeStatus(
    transactionId: string,
    status: TransactionStatus,
    token: string,
    code: string
  ) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/transactions/change-status/${transactionId}/${token}/${code}`),
      {
        status
      },
      {
        headers: {
          ...this.getHeaders()
        }
      }
    );
  }

  transferGas(
    userId: string,
    gasTankId: string,
    blockchain: Blockchain,
    to: string,
    token: string,
    code: string
  ) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/transactions/transfer-gas/${userId}/${gasTankId}/${token}/${code}`),
      {
        blockchain,
        to
      },
      {
        headers: {
          ...this.getHeaders()
        }
      }
    );
  }

  syncAddresses(
    blockchain: Blockchain,
    token: string,
    code: string
  ) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/transactions/sync-addresses/${token}/${code}`),
      {
        blockchain
      },
      {
        headers: {
          ...this.getHeaders()
        }
      }
    );
  }

  syncImplementationAddresses(
    blockchain: Blockchain,
    token: string,
    code: string
  ) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/transactions/sync-implementation-addresses/${token}/${code}`),
      {
        blockchain
      },
      {
        headers: {
          ...this.getHeaders()
        }
      }
    );
  }

  syncApys(
    token: string,
    code: string
  ) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/transactions/sync-apys/${token}/${code}`),
      null,
      {
        headers: {
          ...this.getHeaders()
        }
      });
  }

  swapVSigningAddress(userId: string, token: string, code: string) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/transactions/swap-vsigning-address/${userId}/${token}/${code}`), null, {
      headers: {
        ...this.getHeaders()
      }
    });
  }

  buy(userId: string, accountId: string, symbol: string, bankAccountId: string, amount: number, memo: string, token: string, code: string) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/transactions/buy/${userId}/${accountId}/${token}/${code}`),
      {
        accountId,
        symbol,
        bankAccountId,
        amount,
        memo
      },
      {
        headers: {
          ...this.getHeaders()
        }
      }
    );
  }

  sign(key: string, userId: string, transaction: ITransaction, token: string, code: string) {
    const signature = CryptoUtil.sign(key, transaction.messageHash);
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/transactions/sign/${userId}/${transaction.id}/${token}/${code}`),
      {
        v: signature.v,
        r: signature.r,
        s: signature.s
      },
      {
        headers: {
          ...this.getHeaders()
        }
      }
    );
  }

  addYearnVault(
    userId: string, address: string,
    token: string, code: string
  ) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/transactions/add-yearn-vault/${userId}/${address}/${token}/${code}`),
      null,
      {
        headers: {
          ...this.getHeaders()
        }
      });
  }

  mint(
    userId: string,
    symbol: string,
    token: string,
    code: string
  ) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/transactions/mint/${userId}/${symbol}/${token}/${code}`), null, {
      headers: {
        ...this.getHeaders()
      }
    });
  }

  setUnderlyingSupplyLimit(
    symbol: string,
    underlyingLimit: number,
    token: string,
    code: string
  ) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/transactions/set-underlying-supply-limit/${symbol}/${token}/${code}`),
      {
        underlyingLimit
      },
      {
      headers: {
        ...this.getHeaders()
      }
    });
  }

  sell(userId: string, accountId: string, symbol: string, bankAccountId: string, amount: number, memo: string, token: string, code: string) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/transactions/sell/${userId}/${accountId}/${token}/${code}`),
      {
        symbol,
        bankAccountId,
        amount,
        memo
      },
      {
        headers: {
          ...this.getHeaders()
        }
      }
    );
  }

  transferToEmail(userId: string, accountId: string, email: string, symbol: string, amount: number, memo: string, token: string, code: string) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/transactions/transfer-to-email/${userId}/${accountId}/${token}/${code}`),
      {
        email,
        symbol,
        amount,
        memo
      },
      {
        headers: {
          ...this.getHeaders()
        }
      }
    );
  }

  transferToAddress(userId: string, accountId: string, address: string, symbol: string, amount: number, memo: string, token: string, code: string) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/transactions/transfer-to-address/${userId}/${accountId}/${token}/${code}`),
      {
        address,
        symbol,
        amount,
        memo
      },
      {
        headers: {
          ...this.getHeaders()
        }
      }
    );
  }

  createPaymentToken(
    symbol: string,
    amount: number,
    memo?: string
  ) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/transactions/create-payment-token`),
      {
        symbol,
        amount,
        memo
      },
      {
        headers: {
          ...this.getHeaders()
        }
      }
    );
  }

  findPaymentInfo(
    token: string
  ) {
    return this.httpClient.get(this.formatUrl(`/v${this.environment.vesto.version}/transactions/find-payment-info/${token}`), {
      headers: {
        ...this.getHeaders()
      }
    });
  }

  payByToken(
    accountId: string,
    paymentToken,
    tip: number,
    token: string,
    code: string
  ) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/transactions/pay-by-token/${accountId}/${paymentToken}/${token}/${code}`),
      {
        tip
      }, {
      headers: {
        ...this.getHeaders()
      }
    });
  }

  addUser(userId: string, accountId: string, email: string, token: string, code: string) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/transactions/add-user/${userId}/${accountId}/${token}/${code}`),
      {
        email
      },
      {
        headers: {
          ...this.getHeaders()
        }
      }
    );
  }

  changeRole(
    userId: string,
    roleType: RoleType,
    token: string,
    code: string
  ) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/transactions/change-role/${userId}/${token}/${code}`),
      {
        roleType
      },
      {
        headers: {
          ...this.getHeaders()
        }
      }
    );
  }

  swapSigningAddresses(
    token: string,
    code: string
  ) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/transactions/swap-signing-addresses/${token}/${code}`),
    null,
      {
        headers: {
          ...this.getHeaders()
        }
      }
    );
  }

  removeUser(userId: string, additionalUserId: string, token: string, code: string) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/transactions/remove-user/${userId}/${additionalUserId}/${token}/${code}`), {
      headers: {
        ...this.getHeaders()
      }
    });
  }

  setDailyLimits(
    userId: string,
    email: string,
    dailyBuyLimit: number,
    dailySellLimit: number,
    dailyTransferLimit: number,
    token: string,
    code: string
  ) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/transactions/set-daily-limits/${userId}/${token}/${code}`),
      {
        email,
        dailyBuyLimit,
        dailySellLimit,
        dailyTransferLimit
      },
      {
        headers: {
          ...this.getHeaders()
        }
      });
  }

  setSettleLimits(
    userId: string,
    enabled: boolean,
    transactionLimit: number,
    dailyLimit: number,
    token: string,
    code: string
  ) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/transactions/set-settle-limits/${userId}/${token}/${code}`),
      {
        enabled,
        transactionLimit,
        dailyLimit
      },
      {
        headers: {
          ...this.getHeaders()
        }
      });
  }

  sendSmsCode(userId: string) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/transactions/send-sms-code/${userId}`), null, {
      headers: {
        ...this.getHeaders()
      }
    });
  }

  syncElement(userId: string, accountId: string, trancheAddress: string, principalTokenPoolAddress: string, yieldTokenPoolAddress: string) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/transactions/sync-element/${userId}/${accountId}`),
      {
        trancheAddress,
        principalTokenPoolAddress,
        yieldTokenPoolAddress
      },
      {
        headers: {
          ...this.getHeaders()
        }
      }
    );
  }

  redeem(
    userId: string,
    transactionId: string,
    token: string,
    code: string
  ) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/transactions/redeem/${userId}/${transactionId}/${token}/${code}`),
      null,
      {
        headers: this.getHeaders()
      }
    );
  }

  wireToCircle(
    userId: string,
    token: string,
    code: string
  ) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/transactions/wire-to-circle/${userId}/${token}/${code}`),
     null,
      {
        headers: this.getHeaders()
      }
    );
  }

  wireToFbo(
    userId: string,
    token: string,
    code: string
  ) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/transactions/wire-to-fbo/${userId}/${token}/${code}`),
      null,
      {
        headers: this.getHeaders()
      }
    );
  }
}
