<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>

  <h3 class="section-number">Create Account</h3>
</div>
<mat-dialog-content>
  <ng-container [ngSwitch]="step">
    <form [formGroup]="formGroup" (ngSubmit)="create()" *ngSwitchCase="'FORM_GROUP'">
      <div class="vesto-input my-4">
        <label for="name">Account Name</label>
        <input type="text" formControlName="name" id="name" name="name" />
        <span class="form-error" *ngIf="formGroup.controls.name.invalid && formGroup.controls.name.touched">Please enter a name</span>
      </div>
      <gbl-alert [type]="error" [message]="error"></gbl-alert>
      <button class="button button-primary my-4 w-full text-center block" type="submit">Create</button>
    </form>

    <section *ngSwitchCase="'BUSY'" class="mt-4">
      <div class="placeholder-long bg-flash"></div>
      <div class="placeholder-area bg-flash mt-4"></div>
    </section>

    <section *ngSwitchCase="'SUCCESS'">
      <gbl-lottie-player></gbl-lottie-player>
    </section>

    <section *ngSwitchCase="'DETAIL'">
      <div class="slide-in">
        <div class="text-center">
          <p>Success!</p>
        </div>
        <div class="p-4 bg-trans-blue rounded-lg">
          Created account "{{account.name}}"
        </div>
        <button class="button button-primary my-4 mx-auto w-full" (click)="close()">Close</button>
      </div>
    </section>
  </ng-container>
</mat-dialog-content>
