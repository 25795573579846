<div class="tab-header-container flex">
  <div (click)="step === 'FORM_GROUP' ? selectedTab = 'send' : selectedTab" class="tab-header flex-grow" [class.tab-header-active]="selectedTab === 'send'">Send</div>
  <div (click)="step === 'FORM_GROUP' ? selectedTab = 'receive' : selectedTab" class="tab-header flex-grow" [class.tab-header-active]="selectedTab == 'receive'">Receive</div>
</div>
<mat-dialog-content class="relative">
  <button type="button" class="close close-buy" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <ng-container *ngIf="selectedTab == 'send'" [ngSwitch]="step">
    <ng-container *ngIf="user?.status !== UserStatus.SWAPPING_USER_ADDRESS else swappingUserAddressTemplate">
      <form [formGroup]="formGroup" (ngSubmit)="transfer()" class="relative" *ngSwitchCase="'FORM_GROUP'">
        <div class="my-4" [hidden]="selectedAccount?.balance === 0 || selectedAccount.status === AccountStatus.SUSPENDED">
          <gbl-input mask="currency" formControlName="amount"></gbl-input>
        </div>

        <div class="vesto-input vesto-input-inline my-4" [hidden]="selectedAccount?.balance === 0 || selectedAccount.status === AccountStatus.SUSPENDED">
          <label class="inline" for="target">To:</label>
          <input type="text" formControlName="target" id="target" name="target" placeholder="Enter email address" />
        </div>

        <div class="vesto-input vesto-select my-4" [class.pt-16]="selectedAccount?.balance === 0">
          <ng-select formControlName="accountId" id="accountId" name="accountId" placeholder="Send from">
            <ng-option *ngFor="let account of accounts" [value]="account.id">
              <div class="flex">
                <div class="flex-grow">{{ account.name }} &nbsp;</div>
                ${{ account.balance.toFixed(2) }}
              </div>
            </ng-option>
          </ng-select>
        </div>

        <div class="vesto-input vesto-select my-4" [hidden]="selectedAccount?.balance === 0 || selectedAccount.status === AccountStatus.SUSPENDED">
          <ng-select formControlName="symbol" id="symbol" name="symbol" placeholder="Currency">
            <ng-option *ngFor="let asset of selectedAccount.assets" [value]="asset.symbol">
              <div class="flex">
                <div class="flex-grow">{{ asset?.symbol }} &nbsp;</div>
                ${{ asset?.fiatBalance.toFixed(2) }}
              </div>
            </ng-option>
          </ng-select>
        </div>

        <ng-container *ngIf="selectedAccount?.balance > 0 && selectedAccount.status !== AccountStatus.SUSPENDED; else disabledTemplate">
          <div class="vesto-input my-4" *ngIf="addMemo">
            <label for="memo"
            ><a (click)="addMemo = !addMemo">Memo <i class="fal fa-minus-circle"></i></a
            ></label>
            <textarea formControlName="memo" id="memo" name="memo"></textarea>
          </div>

          <div class="flex text-sm">
            <span class="flex-grow">Amount</span> {{ formGroup.controls.amount.value | currency }}
          </div>
          <div class="flex text-sm my-2 pb-2 border-b">
            <span class="flex-grow">Estimated Fee</span> {{ fee | currency }}
          </div>
          <div class="flex text-sm my-2">
            <span class="font-bold flex-grow">Total Transaction</span> <span class="font-bold">{{ formGroup.controls.amount.value + fee | currency }}</span>
          </div>

          <gbl-alert [type]="error" [message]="error"></gbl-alert>

          <button class="button button-primary my-4 w-full text-center block" type="submit">Send</button>

          <a *ngIf="!addMemo" class="text-sm underline mt-2" (click)="addMemo = !addMemo"><i class="fal fa-plus-circle mr-2"></i>Add Memo</a>
        </ng-container>

        <ng-template #disabledTemplate>
          <div class="text-center bg-trans-blue rounded-lg p-4" *ngIf="selectedAccount.status !== AccountStatus.SUSPENDED && !selectedAccount?.additionalUserSuspended; else suspendedTemplate">
            <span class="text-lg block">Your account balance is $0</span>
            <span class="text-sm">Buy more digital currency or choose a different account</span>
            <button class="button button-primary my-4 mt-4 w-full text-center block" (click)="openOrGoToBuySell()">Buy</button>
          </div>

          <ng-template #suspendedTemplate>
            <div class="text-center bg-red-lightest rounded-lg p-4 text-red-darker">
              <span class="text-lg block">You have been suspended from this account</span>
              <span class="text-sm">Please choose a different account</span>
            </div>
          </ng-template>
        </ng-template>
      </form>

      <section *ngSwitchCase="'TWO_FACTOR'">
        <div class="mt-16">
          <gbl-two-factor (sendSmsCode)="sendSmsCode($event)" (code)="confirmCode($event)"></gbl-two-factor>
        </div>
      </section>

      <section *ngSwitchCase="'BUSY'">
        <div class="mt-16">
          <div class="placeholder-long bg-flash"></div>
          <div class="placeholder-short bg-flash mt-4"></div>
          <div class="placeholder-area bg-flash mt-4"></div>
        </div>
      </section>

      <section *ngSwitchCase="'SUCCESS'">
        <div style="width: 350px; height: 350px; overflow: hidden; margin: -24px auto 0">
          <gbl-lottie-player></gbl-lottie-player>
        </div>
      </section>

      <section *ngSwitchCase="'TRANSACTION_DETAIL'">
        <div class="relative" [@slideInOut]>
          <div class="text-center">
            <p class="mt-4">Success!</p>
            <p class="text-sm mb-4">Your transaction has been submitted to the blockchain</p>
          </div>
          <div class="p-4 bg-trans-blue rounded-lg">
            <gbl-transaction [transaction]="transaction"></gbl-transaction>
          </div>
          <button class="button button-primary mt-4 mx-auto w-full" (click)="close()">Close</button>
        </div>
      </section>
    </ng-container>

    <ng-template #swappingUserAddressTemplate>
      <gbl-lottie-player [type]="'block'"></gbl-lottie-player>
      <p class="bg-trans-blue rounded-lg text-center p-4">Please wait while your signing address is being deployed to the blockchain. <span class="font-bold">This could take 5-10 minutes</span></p>
    </ng-template>
  </ng-container>

  <!-- RECEIVE TAB -->
  <ng-container *ngIf="selectedTab == 'receive'">
    <div class="vesto-input vesto-select my-4">
      <ng-select [(ngModel)]="selectedAccount.id" (change)="getAddress($event)" id="receive" name="receive" placeholder="Account">
        <ng-option *ngFor="let account of accounts" [value]="account.id">
          <div class="flex">
            <div class="flex-grow">{{ account.name }}</div>
            ${{ account.balance.toFixed(2) }}
          </div>
        </ng-option>
      </ng-select>
      <span class="form-error" *ngIf="selectedAccount === null"> Please choose an account </span>
    </div>

    <qrcode *ngIf="targetAddress" allowEmptyString="true" [qrdata]="targetAddress" [width]="256" errorCorrectionLevel="M"></qrcode>

    <div *ngIf="selectedAccount" class="flex justify-center text-gray-600 cursor-pointer" (click)="copy(selectedAccount.contract.address)">
      <span class="text-sm">{{ targetAddress }}</span>
      <i class="far fa-copy ml-2"></i>
    </div>

    <small class="block text-center">{{ copied ? 'Copied!' : '' }}</small>
  </ng-container>
</mat-dialog-content>
