import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { UISharedModule } from '@vesto/xplat/features';
import { UI_COMPONENTS, MODALS_COMPONENTS } from './components';
import { NgxSpinnerModule } from 'ngx-spinner';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxPlaidLinkModule } from 'ngx-plaid-link';
import { QRCodeModule } from 'angularx-qrcode';
import { ClipboardModule } from 'ngx-clipboard';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { StickyModule } from 'ng2-sticky-kit';
import { IntercomModule } from 'ng-intercom';
import { environment } from '@vesto/xplat/core';
import { MatInputModule } from '@angular/material/input';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxCleaveDirectiveModule } from 'ngx-cleave-directive';
import { HighchartsChartModule } from 'highcharts-angular';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatExpansionModule} from "@angular/material/expansion";

export function playerFactory() {
  return player;
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatSliderModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatGridListModule,
    MatMenuModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatExpansionModule,
    UISharedModule,
    NgxSpinnerModule,
    GooglePlaceModule,
    NgxPlaidLinkModule,
    QRCodeModule,
    ClipboardModule,
    MatPasswordStrengthModule,
    StickyModule,
    BsDropdownModule,
    TooltipModule,
    IvyCarouselModule,
    NgSelectModule,
    NgxChartsModule,
    NgxCleaveDirectiveModule,
    HighchartsChartModule,
    LottieModule.forRoot({ player: playerFactory }),
    IntercomModule.forRoot({
      appId: environment.intercom.appID,
      updateOnRouterChange: true
    })
  ],
  declarations: [...UI_COMPONENTS],
  exports: [HighchartsChartModule, CommonModule, RouterModule, FormsModule, ReactiveFormsModule, MatSlideToggleModule, MatCheckboxModule, MatSliderModule, MatDialogModule, MatFormFieldModule, MatInputModule, MatGridListModule, MatMenuModule, MatProgressSpinnerModule, UISharedModule, NgxSpinnerModule, GooglePlaceModule, NgxIntlTelInputModule, NgxPlaidLinkModule, QRCodeModule, ClipboardModule, MatPasswordStrengthModule, MatDatepickerModule, MatNativeDateModule, MatTooltipModule, MatPaginatorModule, StickyModule, BsDropdownModule, TooltipModule, IntercomModule, IvyCarouselModule, NgSelectModule, LottieModule, MatExpansionModule, ...UI_COMPONENTS]
})
export class UIModule {}
