import {Component, OnInit} from '@angular/core';
import {FeatureService, TransactionsBaseComponent} from '@vesto/xplat/features';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {AccountType, IInstitution, SystemStatus} from '@vesto/vesto';
import {CoreService} from "@vesto/xplat/core";
import {filter, takeUntil} from "rxjs/operators";

@Component({
  selector: 'gbl-transactions',
  templateUrl: 'transactions.component.html',
  animations: [
    trigger('slideInOut', [
      state(
        'void',
        style({
          left: '1000px',
          opacity: '0'
        })
      ),
      //element being added into DOM.
      transition(':enter', [
        animate(
          '300ms 300ms ease-in-out',
          style({
            left: '0',
            opacity: '1'
          })
        )
      ]),
      //element being removed from DOM.
      transition(':leave', [
        animate(
          '300ms ease-in-out',
          style({
            left: '-1000px',
            opacity: '0'
          })
        )
      ])
    ])
  ]
})
export class TransactionsComponent extends TransactionsBaseComponent implements OnInit {
  institution: IInstitution;
  AccountType = AccountType;

  ngOnInit() {
    super.ngOnInit();
    CoreService.institutionFacade.institution$
      .pipe(
        filter(institution => !!institution),
        takeUntil(this.destroy$)
      )
      .subscribe((institution) => {
        this.institution = institution;
      });
  }
}
