import {Component, OnInit} from '@angular/core';
import {AccountBaseComponent, AccountChartType, AccountHeader, FeatureService} from '@vesto/xplat/features';
import {ContractStatus, ContractType, IContract} from "@vesto/vesto";
import {filter, takeUntil} from "rxjs/operators";

@Component({
  selector: 'gbl-account',
  templateUrl: 'account.component.html'
})
export class AccountComponent extends AccountBaseComponent implements OnInit {
  AccountChartType = AccountChartType;
  ContractStatus = ContractStatus;
  AccountHeader = AccountHeader;
  accountHeader = AccountHeader.CONTRACT;

  ngOnInit() {
    super.ngOnInit();
    FeatureService.dashboardFacade.selectedAccountHeader$
      .pipe(
        filter(accountHeader => !!accountHeader),
        takeUntil(this.destroy$)
      ).subscribe((accountHeader) => {
      this.accountHeader = accountHeader;
    });
  }

  isProxy(contract: IContract) {
    return contract.type.endsWith('PROXY');
  }

  onEvent(event) {
    event.stopPropagation();
  }

  // TODO: PIPE!!!
  getType(contract: IContract) {
    switch (contract.type) {
      case ContractType.VLIGHTHOUSE:
      case ContractType.VLIGHTHOUSE_PROXY:
        return 'VLighthouse';
      case ContractType.VUSER:
      case ContractType.VUSER_PROXY:
        return 'VUser';
      case ContractType.VBRIDGE:
      case ContractType.VBRIDGE_PROXY:
        return 'VBridge';
      case ContractType.VDEFI:
      case ContractType.VDEFI_PROXY:
        return 'VDeFi';
      case ContractType.VSWAP:
      case ContractType.VSWAP_PROXY:
        return 'VSwap';
      case ContractType.VERC20:
      case ContractType.VERC20_PROXY:
        return 'VERC20';
      case ContractType.VWALLET:
      case ContractType.VWALLET_PROXY:
        return 'VWallet';
      case ContractType.YEARN_VAULT:
        return 'Yearn Vault';
      default:
        return 'undefined';
    }
  }
}
