import {Component, OnInit, HostListener, Output, EventEmitter, Input} from '@angular/core';
import { HeaderBaseComponent } from '@vesto/xplat/features';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { WindowService, environment } from '@vesto/xplat/core';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'gbl-header',
  templateUrl: 'header.component.html'
})
export class HeaderComponent extends HeaderBaseComponent implements OnInit {
  @Output() signIn = new EventEmitter<void>();
  @Input() darkMode = false;
  scrolledHeader: boolean;
  dropdownVisible = false;
  env = environment;
  headerItems = [
    {
      text: 'Treasury',
      routerLink: '/corporate-treasury'
    },
    {
      text: 'About Us',
      routerLink: '/about-us'
    },
    {
      text: 'News',
      routerLink: '/news'
    },
    {
      text: 'Reports',
      routerLink: '/reports'
    }
  ];
  // TODO: populate links dynamically

  @HostListener('document:click', ['$event'])
  clickout(event) {
    let clickedInside = false;
    event.target.classList.forEach((item) => {
      if (item === 'dropdown' || item === 'dropdown-option' || item === 'openDropdown') {
        clickedInside = true;
      }
    });
    if (!clickedInside) {
      this.dropdownVisible = false;
    }
  }

  constructor(private windowService: WindowService, public router: Router) {
    super();
  }

  ngOnInit() {
    if (this.windowService.isBrowser) {
      fromEvent(window, 'scroll').pipe(debounceTime(100), takeUntil(this.destroy$)).subscribe(this.scroll.bind(this));
    }
  }

  scroll(args) {
    if (this.windowService.pageYOffset > 10) {
      this.scrolledHeader = true;
    } else {
      this.scrolledHeader = false;
    }
  }
}
