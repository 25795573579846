import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IEnvironment, ObjectUtil } from '@vesto/vesto';
import { Action, Store } from '@ngrx/store';
import { EnvironmentToken, SnapShotApiService } from '../../services';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { ApiActions } from '../api';
import { UserFacade } from '../../state/user';
import { VestoState } from '../vesto.state';
import { SnapShotActions } from './snap-shot.actions';
import { CoreActions } from '@vesto/xplat/core';

@Injectable()
export class SnapShotEffects {
  constructor(private actions$: Actions, private userFacade: UserFacade, @Inject(EnvironmentToken) protected environment: IEnvironment, protected store: Store<VestoState.IState>, private snapShotApiService: SnapShotApiService) {}

  findByAccount$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(SnapShotActions.findByAccount),
        mergeMap(({ accountId }) => {
          return this.snapShotApiService.findByAccount(accountId).pipe(
            mergeMap((result) => {
              return from([SnapShotActions.findByAccountSuccess({ snapShots: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([SnapShotActions.findByAccountFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  findByUser$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(SnapShotActions.findByUser),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{}, user]) => {
          if (!user || !user.id) {
            return from([CoreActions.noop()]);
          }
          return this.snapShotApiService.findByUser(user.id).pipe(
            mergeMap((result) => {
              return from([SnapShotActions.findByUserSuccess({ snapShots: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([SnapShotActions.findByUserFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  findByInstitution = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(SnapShotActions.findByInstitution),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{}, user]) => {
          return this.snapShotApiService.findByInstitution(user.institutionId).pipe(
            mergeMap((result) => {
              return from([SnapShotActions.findByInstitutionSuccess({ snapShots: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([SnapShotActions.findByInstitutionFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  findBySystem$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(SnapShotActions.findBySystem),
        mergeMap(({}) => {
          return this.snapShotApiService.findBySystem().pipe(
            mergeMap((result) => {
              return from([SnapShotActions.findBySystemSuccess({ snapShots: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([SnapShotActions.findBySystemFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );
}
