import {Component, OnInit} from '@angular/core';
import {BaseComponent, CoreService} from '@vesto/xplat/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {
  AccountStatus,
  AccountType,
  IAccount,
  IAdditionalUser,
  IInstitution,
  ISystem,
  IUser,
  RoleType,
  SystemAlert,
  UserUtil
} from '@vesto/vesto';
import {FeatureService, Section} from '@vesto/xplat/features';
import {filter, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'gbl-sidenav',
  templateUrl: 'sidenav.component.html',
  animations: [
    trigger('expandCollapseDetails', [
      state(
        'void',
        style({
          height: '0px',
          overflow: 'hidden',
        })
      ),
      //element being added into DOM.
      transition(':enter', [
        animate(
          '500ms ease-in-out',
          style({
            height: '*',
            overflow: 'hidden',
          })
        )
      ]),
      //element being removed from DOM.
      transition(':leave', [
        animate(
          '500ms ease-in-out',
          style({
            height: '0px',
            overflow: 'hidden'
          })
        )
      ])
    ])
  ]
})
export class SidenavComponent extends BaseComponent implements OnInit {
  accounts: IAccount[] = [];
  adminAccounts: IAccount[] = [];
  smartContracts: IAccount [] = [];
  selectedAccount: IAccount;
  selectedSection: string;
  AccountStatus = AccountStatus;
  Section = Section;
  adminUser: IUser;
  user: IUser;
  RoleType = RoleType;
  UserUtil = UserUtil;
  AccountType = AccountType;
  institution: IInstitution;
  gasTankAccount: IAccount;
  isAdminView: boolean;
  system: ISystem;
  gasTanksLow = false;
  mintingRequired = false;
  swappingSigningAddressesRequired = false;
  actionRequired = false;

  ngOnInit() {
    CoreService.accountFacade.accounts$
      .pipe(
        filter(accounts => !!accounts),
        takeUntil(this.destroy$),
      )
      .subscribe((accounts) => {
        this.smartContracts = [];
        this.accounts = [];
        this.adminAccounts = [];
        accounts.forEach(account => {
          if (account.type === AccountType.WALLET) {
            this.accounts.push(account);
          } else if (account.type === AccountType.FIAT || account.type === AccountType.OPERATIONAL || account.type === AccountType.PAYOUT) {
            this.adminAccounts.push(account);
          } else if (account.type === AccountType.GAS_TANK) {
            this.gasTankAccount = account;
          } else {
            this.smartContracts.push(account);
          }
        });
      });
    CoreService.userFacade.user$
      .pipe(
        filter( user => !!user),
        takeUntil(this.destroy$)
      ).subscribe((user) => {
        this.user = user;
      });
    CoreService.userFacade.adminUser$
      .pipe(
        filter( adminUser => !!adminUser),
        takeUntil(this.destroy$)
      )
      .subscribe((adminUser) => {
        this.adminUser = adminUser;
      });
    FeatureService.dashboardFacade.selectedAccount$
      .pipe(
        filter(selectedAccount => !! selectedAccount),
        takeUntil(this.destroy$)
      )
      .subscribe((selectedAccount) => {
        this.selectedAccount = selectedAccount;
      });
    FeatureService.dashboardFacade.selectedSection$
      .pipe(
        filter(selectedSection => !!selectedSection),
        takeUntil(this.destroy$)
      )
      .subscribe((selectedSection) => {
        this.selectedSection = selectedSection;
      });
    CoreService.institutionFacade.institution$
      .pipe(
        filter(institution => !!institution),
        takeUntil(this.destroy$)
      ).subscribe((institution) => {
          this.institution = institution;
      });
    FeatureService.dashboardFacade.isAdminView$
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe((isAdminView) => {
        this.isAdminView = isAdminView;
      });
    CoreService.systemFacade.system$
      .pipe(
        filter(system => !!system),
        takeUntil(this.destroy$)
      ).subscribe(system => {
        this.system = system;
        this.system.alerts.forEach(alert => {
          if (alert === SystemAlert.GAS_TANKS_LOW) {
            this.gasTanksLow = true;
          }
          if (alert === SystemAlert.MINTING_REQUIRED) {
            this.mintingRequired = true;
          }
          if (alert === SystemAlert.SWAPPING_SIGNING_ADDRESSES_REQUIRED) {
            this.swappingSigningAddressesRequired = true;
          }
          if (alert === SystemAlert.BUY_OR_SELL_ACTION_REQUIRED || alert === SystemAlert.WIRE_REQUIRED) {
            this.actionRequired = true
          }
        });
      });
  }

  selectAccount(account: IAccount) {
    FeatureService.dashboardFacade.selectAccount(account);
  }

  selectSection(section: Section) {
    FeatureService.dashboardFacade.selectSection(section);
  }

  selectGasTanks() {
    FeatureService.dashboardFacade.selectSection(Section.GAS_TANKS);
  }

  createAccount() {
    FeatureService.dashboardFacade.openOrGoToCreateAccount();
  }

  addUser() {
    FeatureService.dashboardFacade.openOrGoToAddUser();
  }

  openOrGoToUserSearch() {
    FeatureService.dashboardFacade.openOrGoToUserSearch();
  }

  openOrGoToCreateInstitution() {
    FeatureService.dashboardFacade.openOrGoToInstitution('create');
  }

  openOrGoToSetSupplyLimit() {
    FeatureService.dashboardFacade.openOrGoToSetSupplyLimit();
  }

  openOrGoToInviteUser() {
    FeatureService.dashboardFacade.openOrGoToInviteUser();
  }

  openOrGoToInviteToMerchant() {
    FeatureService.dashboardFacade.openOrGoToInviteToMerchant();
  }

  deployContracts() {
    const code = '498745'; // TODO: JEff fix!!!!!
    CoreService.systemFacade.deployContracts(code);
  }

  initialize() {
    CoreService.systemFacade.initializeContracts();
  }

  additionalUserTooltip(additionalUsers: IAdditionalUser[]) {
    let tooltip = 'Shared with ';
    additionalUsers.forEach(additionalUser => {
      tooltip += `${additionalUser.user.firstName} ${additionalUser.user.lastName} `;
    });
    return tooltip;
  }

  setIsAdminView(isAdminView: boolean) {
    FeatureService.dashboardFacade.setIsAdminView(isAdminView);
   // this.selectSection(Section.ACCOUNTS);
   //  if (this.isAdminView) {
   //    if (this.adminAccounts.length > 0) {
   //      this.selectAccount(this.adminAccounts[0]);
   //    }
   //  } else {
   //    if (this.accounts.length > 0) {
   //      this.selectAccount(this.accounts[0]);
   //    }
   //  }
  }

  openAdditionalUsers(event: Event, account: IAccount) {
    event.stopPropagation();
    FeatureService.dashboardFacade.selectAccount(account);
    FeatureService.dashboardFacade.openAdditionalUsers();
  }
}
