import { Pipe, PipeTransform } from '@angular/core';
import { Blockchain } from '@vesto/vesto';

@Pipe({
  name: 'blockchain',
  pure: true
})
export class BlockchainIconPipe implements PipeTransform {
  transform(blockchain: Blockchain): any {
    switch (blockchain) {
      case Blockchain.ETHEREUM:
        return '/assets/icons/ethereum-icon-blue.svg';
      case Blockchain.POLYGON:
        return '/assets/icons/polygon-icon-blue.svg';
    }
  }
}
