<div class="tab-header-container flex">
  <div (click)="step === 'FORM_GROUP' ? selectedTab = 'buy' : selectedTab" class="tab-header flex-grow" [class.tab-header-active]="selectedTab === 'buy'">
    Buy
  </div>
  <div (click)="step === 'FORM_GROUP' ? selectedTab = 'sell' : selectedTab" class="tab-header flex-grow" [class.tab-header-active]="selectedTab === 'sell'">
    Sell
  </div>
</div>

<mat-dialog-content class="relative">
  <button type="button" class="close close-buy" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <ng-container *ngIf="user?.status !== UserStatus.SWAPPING_USER_ADDRESS else swappingUserAddressTemplate">

    <ng-container *ngIf="bankAccounts && bankAccounts.length > 0 || step == 'CHOOSE_PLAID_ACCOUNT'; else noPaymentMethodsTemplate" [ngSwitch]="step">
      <form [formGroup]="formGroup" (ngSubmit)="buyOrSell()" *ngSwitchCase="'FORM_GROUP'">
        <div class="mb-4 mt-12">
          <gbl-input class="currency-input my-4" mask="currency" formControlName="amount"></gbl-input>
        </div>

        <div class="vesto-input vesto-select mt-4 mb-2">
          <ng-select formControlName="symbol" id="symbol" name="symbol" placeholder="Currency" [hidden]="selectedAccount?.status === AccountStatus.SUSPENDED || selectedAccount?.additionalUserSuspended">
            <ng-option *ngFor="let asset of selectedAccount?.assets" [value]="asset.symbol">
              <div class="flex">
                <div class="flex-grow">{{ asset?.symbol }} &nbsp;</div>
                ${{ asset?.fiatBalance.toFixed(2) }}
              </div>
            </ng-option>
          </ng-select>
        </div>

        <div *ngIf="formGroup.controls.symbol.value === 'VSUSD'" class="mb-4 bg-lilac-light rounded-lg p-3 text-sm">
          Vesto's stable coin, purchase this to transact quickly and cheaply.
        </div>

        <div *ngIf="formGroup.controls.symbol.value === 'VYUSD'" class="mb-4 bg-lilac-light rounded-lg p-3 text-sm">
          Vesto's interest bearing coin, purchase this to earn yield.
        </div>

        <div class="flex flex-col">
          <div class="vesto-input vesto-select" [class.my-4]="selectedTab === 'buy' && !selectedAccount?.additionalUserSuspended" [style.order]="selectedTab == 'buy' ? '2' : '1'">
            <ng-select formControlName="accountId" id="accountId" name="accountId"
                       [placeholder]="selectedTab == 'buy' ? 'To' : 'From'">
              <ng-option *ngFor="let account of accounts" [value]="account.id">
                <div class="flex">
                  <div class="flex-grow">{{ account.name }} &nbsp;</div>
                  ${{ account.balance.toFixed(2) }}
                </div>
              </ng-option>
            </ng-select>
          </div>

          <div class="vesto-input vesto-select" [class.my-4]="selectedTab == 'sell'" [style.order]="selectedTab == 'buy' ? '1' : '2'" [hidden]="selectedAccount?.status === AccountStatus.SUSPENDED  || selectedAccount?.additionalUserSuspended">
            <ng-select formControlName="bankAccountId" id="from" name="from"
                       notFoundText="You have no payment methods" [placeholder]="selectedTab == 'buy' ? 'From' : 'To'">
              <ng-option *ngFor="let bankAccount of bankAccounts"
                         [value]="bankAccount.id">{{ bankAccount.institution }} {{ bankAccount.name }} </ng-option>
            </ng-select>
          </div>
        </div>

        <gbl-alert [type]="error" [message]="error"></gbl-alert>

        <div *ngIf="selectedAccount?.status !== AccountStatus.SUSPENDED && !selectedAccount?.additionalUserSuspended else suspendedTemplate">
          <button class="button button-primary mt-4 mb-2 w-full text-center block" type="submit">
            {{ selectedTab == 'buy' ? 'Buy' : 'Sell' }}
          </button>
          <a class="underline text-sm" (click)="chooseCreatePaymentMethodType()">Add Payment Method</a>
        </div>

        <ng-template #suspendedTemplate>
          <div class="text-center bg-red-lightest rounded-lg p-4 text-red-darker mt-6">
            <span class="text-lg block">You have been suspended from this account</span>
            <span class="text-sm">Please choose a different account</span>
          </div>
        </ng-template>
      </form>

      <section *ngSwitchCase="'TWO_FACTOR'">
        <div class="mt-32">
          <gbl-two-factor (sendSmsCode)="sendSmsCode($event)" (code)="confirmCode($event)"></gbl-two-factor>
        </div>
      </section>

      <section *ngSwitchCase="'BUSY'">
        <div class="mt-32">
          <div class="placeholder-long bg-flash"></div>
          <div class="placeholder-short bg-flash mt-4"></div>
          <div class="placeholder-area bg-flash mt-4"></div>
        </div>
      </section>

      <section *ngSwitchCase="'SUCCESS'">
        <gbl-lottie-player></gbl-lottie-player>
      </section>

      <section *ngSwitchCase="'TRANSACTION_DETAIL'">
        <div class="slide-in">
          <div class="text-center">
            <p>Success!</p>
            <p class="text-sm mb-4">You transaction has been submitted to the blockchain</p>
          </div>
          <div class="p-4 bg-trans-blue rounded-lg">
            <gbl-transaction [transaction]="transaction"></gbl-transaction>
          </div>
          <button class="button button-primary my-4 mx-auto w-full" (click)="close()">Close</button>
        </div>
      </section>

      <section *ngSwitchCase="'CHOOSE_CREATE_PAYMENT_METHOD_TYPE'">
        <ng-container *ngTemplateOutlet="chooseAddPaymentMethodTypeTemplate"></ng-container>
      </section>

      <section *ngSwitchCase="'CHOOSE_PLAID_ACCOUNT'">
        <div class="slide-in mt-4">
          <p>Which account would you like to link?</p>
          <div *ngFor="let plaidBankAccount of plaidBankAccounts"
               class="flex choose-linked-account-option cursor-pointer items-center content-center my-4"
               (click)="choosePlaidPaymentMethod(plaidBankAccount)">
            <i class="fal fa-building mr-3" style="font-size: 30px"></i>
            <div>
              <span>{{ plaidBankAccount.institution }}</span>
              <div class="flex">
                <span class="text-sm">{{ plaidBankAccount.name }} x{{ plaidBankAccount.mask }}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ng-container>

    <ng-template #noPaymentMethodsTemplate>
      <ng-container [ngSwitch]="step">
        <section *ngSwitchCase="'FORM_GROUP'">
          <div class="text-center mt-10">
            <img src="./assets/images/platform.svg" class="w-48"/>
          </div>
          <div class="my-4 text-center">In order to make a purchase you must first add a payment method.</div>

          <gbl-alert [type]="error" [message]="error"></gbl-alert>

          <button class="button button-primary mt-4 w-full text-center block" (click)="chooseCreatePaymentMethodType()">Add
            Payment Method
          </button>
        </section>
        <section *ngSwitchCase="'CHOOSE_CREATE_PAYMENT_METHOD_TYPE'">
          <ng-container *ngTemplateOutlet="chooseAddPaymentMethodTypeTemplate"></ng-container>
        </section>
      </ng-container>
    </ng-template>
  </ng-container>

  <ng-template #swappingUserAddressTemplate>
    <gbl-lottie-player [type]="'block'"></gbl-lottie-player>
    <p class="bg-trans-blue rounded-lg text-center p-4">Please wait while your signing address is being deployed to the
      blockchain. <span class="font-bold">This could take 5-10 minutes.</span></p>
  </ng-template>

  <ng-template #chooseAddPaymentMethodTypeTemplate>
    <div class="slide-in mt-16">
      <div class="rounded-lg p-4 my-4 bg-trans-blue">
        <p class="text-sm">Link your online bank account using <img src="/assets/images/plaid-logo-black.png"
                                                                    class="w-16 ml-1"/></p>
        <button class="button button-primary mt-3 w-full" (click)="openPlaid()">Link</button>
      </div>
      <p class="my-4 text-center text-sm">- or -</p>
      <p class="text-sm mb-3 cursor-pointer text-center" (click)="showAddBankAccount = !showAddBankAccount">Enter Bank Account
        Details <i [class]="showAddBankAccount ? 'fal fa-minus-circle' : 'fal fa-plus-circle'"></i></p>
      <form [formGroup]="paymentMethodFormGroup" (ngSubmit)="createPaymentMethod()" [hidden]="!showAddBankAccount"
            class="slide-in">
        <div class="vesto-input mb-3">
          <label for="institution">Bank Name</label>
          <gbl-input formControlName="institution" id="institution" name="institution"></gbl-input>
        </div>
        <div class="vesto-input mb-3">
          <label for="routing">Routing Number</label>
          <gbl-input mask="numeric" formControlName="routing" id="routing" name="routing"></gbl-input>
        </div>
        <div class="vesto-input mb-3">
          <label for="account">Account Number (must be a checking account)</label>
          <gbl-input mask="numeric" formControlName="account" id="account" name="account"></gbl-input>
        </div>
        <!--<div class="vesto-input mb-3">
          <label for="confirmAccount">Confirm Account Number</label>
          <gbl-input mask="wholeNumber" formControlName="confirmAccount" id="confirmAccount" name="confirmAccount"></gbl-input>
        </div>-->
        <gbl-alert [type]="error" [message]="error"></gbl-alert>
        <button class="button button-primary my-4 mx-auto w-full" type="submit"
                [disabled]="paymentMethodFormGroup.invalid || paymentMethodFormGroup.untouched">Add Account
        </button>
      </form>
    </div>
  </ng-template>
</mat-dialog-content>
