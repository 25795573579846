<div class="dashboard-header bg-lilac-icon p-3 flex items-center">
  <section class="flex" *ngIf="system?.status !== SystemStatus.ACTIVE">
    <div class="flex p-2 my-2 text-xs rounded-lg slide-in items-center mr-2" *ngIf="system?.status === SystemStatus.CONTRACTS_NOT_DEPLOYED">
      <i class="fal fa-info-circle fa-2x mr-2"></i> Contracts not deployed.
    </div>
    <div class="flex p-2 my-2 text-xs rounded-lg slide-in items-center mr-2" *ngIf="system?.status === SystemStatus.CONTRACTS_NOT_INITIALIZED">
      <i class="fal fa-info-circle fa-2x mr-2"></i> Initialize contracts.
    </div>
    <div class="flex p-2 my-2 text-xs rounded-lg slide-in items-center mr-2" *ngIf="system?.status === SystemStatus.BUSY">
      <i class="fa fa-spinner fa-pulse fa-2x mr-2"></i> {{ system?.message }}
    </div>
    <div class="flex p-2 my-2 text-xs rounded-lg slide-in text-red-dark items-center" *ngIf="system?.error">
      <i class="fas fa-times-circle text-xl mr-2"></i> {{ system?.error }}
    </div>
  </section>
  <gbl-user-status *ngIf="!system || system?.status === SystemStatus.ACTIVE"></gbl-user-status>
  <gbl-account-status *ngIf="!system || system?.status === SystemStatus.ACTIVE"></gbl-account-status>
  <div class="flex-grow"></div>
  <span class="button button-lilac button-sm mr-4"(click)="deployContracts()" *ngIf="system?.status === SystemStatus.CONTRACTS_NOT_DEPLOYED"><span class="hidden md:inline">Deploy Contracts</span></span>
  <span class="button button-lilac button-sm mr-4" (click)="initialize()" *ngIf="system?.status === SystemStatus.CONTRACTS_NOT_INITIALIZED"><span class="hidden md:inline">Initialize Contracts</span></span>
  <ng-container *ngIf="!system || system?.status === SystemStatus.ACTIVE">
    <button class="button button-white button-sm mr-4" (click)="openOrGoToBuySell()" [disabled]="user?.status === UserStatus.KYC_CONSIDER || user?.status === UserStatus.VERIFYING_IDENTITY"><span class="hidden md:inline">Buy / Sell</span></button>
    <button class="button outline-white button-sm mr-4" (click)="openOrGoToSendReceive()" [disabled]="user?.status === UserStatus.KYC_CONSIDER || user?.status === UserStatus.VERIFYING_IDENTITY"><span class="hidden md:inline">Send / Receive</span></button>
  </ng-container>

  <!--<span (click)="drawer.toggle()" class="fa-layers mx-3 text-3xl cursor-pointer relative">
    <i class="fal fa-bell"></i>
    <i class="fas fa-circle" style="font-size: 14px; color: #d8e2f5; position: absolute; top: 4px; right: -3px"></i>
    <i class="fas fa-circle text-red" style="font-size: 10px; position: absolute; top: 5px; right: -1px"></i>
  </span>-->

  <span *ngIf="!adminUser" [matMenuTriggerFor]="menu" class="cursor-pointer">
    <i class="fal fa-user-circle text-white text-3xl"></i>
  </span>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="selectProfile()">{{ user?.email }}</button>
    <button mat-menu-item (click)="signOut()">Sign Out</button>
  </mat-menu>

  <mat-drawer-container>
    <mat-drawer #drawer mode="over" position="end" disableClose="false">
      <div class="text-left">
        <div class="notifications-header flex items-center p-3">
          <h4>Notifications</h4>
          <button type="button" class="close" (click)="drawer.close()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="p-3 notification-item">
          <div class="font-bold flex-1">Welcome to Vesto!</div>
          <div class="text-sm"><a href="" class="underline">Click here</a> to finish setting up your account</div>
          <div class="font-light text-xs text-grey text-right">JUST NOW</div>
        </div>
      </div>
    </mat-drawer>
  </mat-drawer-container>
</div>
