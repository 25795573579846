import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  IUser,
  IInstitution,
  IAccount,
  IBankAccount,
  ITransaction,
  IApiError,
  ISnapShot,
  ISystem,
  IMerchant,
  IStore,
  IFiatBalances,
  IGasTank
} from '@vesto/vesto';

export namespace VestoState {
  export interface IState {
    initialized?: boolean;
    authenticated?: boolean;
    apiError?: IApiError;
    system?: ISystem;
    gasTanks?: IGasTank[];
    user?: IUser;
    adminUser?: IUser;
    users?: IUser[];
    token?: string;
    institution?: IInstitution;
    institutions?: IInstitution[];
    merchants?: IMerchant[];
    stores?: IStore[];
    principals?: IUser[];
    accounts?: IAccount[];
    bankAccounts?: IBankAccount[];
    transactions?: ITransaction[];
    accountSnapShots?: ISnapShot[];
    userSnapShots?: ISnapShot[];
    institutionSnapShots?: ISnapShot[];
    systemSnapShots?: ISnapShot[];
    fiatBalances?: IFiatBalances;
    deFi?: any;
    deFiHistories?: any[];
  }

  export const initialState: IState = {
    authenticated: false,
    apiError: null,
    system: null,
    gasTanks: [],
    user: null,
    adminUser: null,
    users: [],
    token: null,
    institution: null,
    institutions: [],
    merchants: [],
    stores: [],
    accounts: [],
    bankAccounts: [],
    transactions: [],
    accountSnapShots: [],
    userSnapShots: [],
    institutionSnapShots: [],
    systemSnapShots: [],
    fiatBalances: null,
    deFi: null,
    deFiHistories: []
  };
}

export namespace VestoSelectors {
  export const featureState = createFeatureSelector<VestoState.IState>('vesto');
  export const apiError = createSelector(featureState, (state: VestoState.IState) => state.apiError);
  export const system = createSelector(featureState, (state: VestoState.IState) => state.system);
  export const gasTanks = createSelector(featureState, (state: VestoState.IState) => state.gasTanks);
  export const initialized = createSelector(featureState, (state: VestoState.IState) => state.initialized);
  export const user = createSelector(featureState, (state: VestoState.IState) => state.user);
  export const authenticated = createSelector(featureState, (state: VestoState.IState) => state.authenticated);
  export const adminUser = createSelector(featureState, (state: VestoState.IState) => state.adminUser);
  export const users = createSelector(featureState, (state: VestoState.IState) => state.users);
  export const token = createSelector(featureState, (state: VestoState.IState) => state.token);
  export const institution = createSelector(featureState, (state: VestoState.IState) => state.institution);
  export const institutions = createSelector(featureState, (state: VestoState.IState) => state.institutions);
  export const merchants = createSelector(featureState, (state: VestoState.IState) => state.merchants);
  export const stores = createSelector(featureState, (state: VestoState.IState) => state.stores);
  export const accounts = createSelector(featureState, (state: VestoState.IState) => state.accounts);
  export const bankAccounts = createSelector(featureState, (state: VestoState.IState) => state.bankAccounts);
  export const transactions = createSelector(featureState, (state: VestoState.IState) => state.transactions);
  export const accountSnapShots = createSelector(featureState, (state: VestoState.IState) => state.accountSnapShots);
  export const userSnapShots = createSelector(featureState, (state: VestoState.IState) => state.userSnapShots);
  export const institutionSnapShots = createSelector(featureState, (state: VestoState.IState) => state.institutionSnapShots);
  export const systemSnapShots = createSelector(featureState, (state: VestoState.IState) => state.systemSnapShots);
  export const fiatBalances = createSelector(featureState, (state: VestoState.IState) => state.fiatBalances);
  export const deFi = createSelector(featureState, (state: VestoState.IState) => state.deFi);
  export const deFiHistories = createSelector(featureState, (state: VestoState.IState) => state.deFiHistories);
}
