import { createReducer, on, Action } from '@ngrx/store';
import { UiState } from './ui.state';
import { UiActions } from './ui.actions';

const reducer = createReducer(
  UiState.initialState,
  on(UiActions.setLocale, (state, { locale }) => {
    return {
      ...state,
      locale: locale
    };
  }),
  on(UiActions.showSpinner, (state, {}) => {
    return {
      ...state,
      spinner: true
    };
  }),
  on(UiActions.hideSpinner, (state, {}) => {
    return {
      ...state,
      spinner: false
    };
  }),
  on(UiActions.initCountryCodesSuccess, (state, { countryCodes }) => {
    const _countryCodes = countryCodes.map((_countryCode) => ({
      ..._countryCode,
      code: _countryCode.code.toLowerCase()
    }));

    return {
      ...state,
      countryCodes: _countryCodes
    };
  }),
  on(UiActions.openedModal, (state, modalState) => {
    return {
      ...state,
      modal: modalState
    };
  }),
  on(UiActions.closedModal, (state, modalState) => {
    return {
      ...state,
      modal: modalState
    };
  })
);

export function uiReducer(state: UiState.IState | undefined, action: Action) {
  return reducer(state, action);
}
