import {IEnvironment} from '../interfaces/environment.interface';
import {IHttpClient} from '../interfaces/http.interface';
import {BaseApi} from './base-api';

export class AccountsApi extends BaseApi {
  constructor(protected environment: IEnvironment, protected httpClient: IHttpClient) {
    super(environment);
  }

  find(userId: string, accountId: string) {
    return this.httpClient.get(this.formatUrl(`/v${this.environment.vesto.version}/accounts/find/${userId}/${accountId}`), {headers: this.getHeaders()});
  }

  findByUser(userId: string) {
    return this.httpClient.get(this.formatUrl(`/v${this.environment.vesto.version}/accounts/find-by-user/${userId}`), {headers: this.getHeaders()});
  }

  create(userId: string, name: string) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/accounts/create/${userId}`), {name}, {headers: this.getHeaders()});
  }

  changeSettings(
    id: string,
    name: string
  ) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/accounts/change-settings/${id}`),
      {
        name
      },
      {
        headers: this.getHeaders()
      });
  }

  suspendUser(userId: string, additionalUserId: string) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/accounts/suspend-user/${userId}/${additionalUserId}`), null, {headers: this.getHeaders()});
  }

  activateUser(userId: string, additionalUserId: string) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/accounts/activate-user/${userId}/${additionalUserId}`), null, {headers: this.getHeaders()});
  }
}
