import { Component, OnInit } from '@angular/core';
import { BaseComponent, CoreService, UiActions } from '@vesto/xplat/core';
import { IInstitution } from '@vesto/vesto';
import {take, takeUntil} from 'rxjs/operators';
import { UntypedFormGroup, Validators } from '@angular/forms';
import {ofType} from "@ngrx/effects";
import {InstitutionActions} from "@vesto/ngx-vesto/state";
import changeAmlSuccess = InstitutionActions.changeAmlSuccess;
import changeAmlFailure = InstitutionActions.changeAmlFailure;

@Component({
  selector: 'gbl-change-aml-modal',
  templateUrl: './change-aml-modal.component.html'
})
export class ChangeAmlModalComponent extends BaseComponent implements OnInit {
  step: 'FORM_GROUP' | 'BUSY' | 'SUCCESS' | 'DETAIL' = 'FORM_GROUP';
  institution: IInstitution;
  formGroup: UntypedFormGroup;
  error: string;

  constructor() {
    super();
    CoreService.institutionFacade.institution$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.institution = result;
    });
    this.formGroup = CoreService.formBuilder.group({
      defaultDailyBuyLimit: [
        this.institution.defaultDailyBuyLimit,
        {
          validators: [Validators.required, Validators.nullValidator]
        }
      ],
      defaultDailySellLimit: [
        this.institution.defaultDailySellLimit,
        {
          validators: [Validators.required, Validators.nullValidator]
        }
      ],
      defaultDailyReceiveLimit: [
        this.institution.defaultDailyReceiveLimit,
        {
          validators: [Validators.required, Validators.nullValidator]
        }
      ],
      defaultDailyTransferLimit: [
        this.institution.defaultDailyTransferLimit,
        {
          validators: [Validators.required, Validators.nullValidator]
        }
      ],
      plaidEnabled: [
        this.institution.plaidEnabled,
        {
          validators: [Validators.required]
        }
      ]
    });
  }

  ngOnInit() {}

  changeAml() {
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }

    const value = this.formGroup.value;
    this.step = 'BUSY';
    CoreService.institutionFacade.changeAml(value.defaultDailyBuyLimit, value.defaultDailySellLimit, value.defaultDailyTransferLimit, value.defaultDailyReceiveLimit, value.plaidEnabled);

    CoreService.actions$.pipe(ofType(changeAmlSuccess), take(1), takeUntil(this.destroy$)).subscribe(() => {
      this.step = 'SUCCESS';
      CoreService.windowService.setTimeout(() => {
        this.step = 'DETAIL';
      }, 1600)
    });

    CoreService.actions$.pipe(ofType(changeAmlFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.step = 'FORM_GROUP';
      this.error = `apiErrors.${result.apiError.error}`;
    })
  }

  close() {
    CoreService.store.dispatch(UiActions.closeModal());
  }
}
