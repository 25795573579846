import { createAction, props } from '@ngrx/store';
import { IApiError, IContact } from '@vesto/vesto';

export namespace ContactActions {
  export const emailReport = createAction('[@vesto/contact] emailReport', props<{ contact: IContact }>());

  export const emailReportSuccess = createAction('[@vesto/contact] emailReportSuccess');

  export const emailReportFailure = createAction('[@vesto/contact] emailReportFailure', props<{ apiError: IApiError }>());
}
