<div class="flex p-2 text-xs items-center">
  <span>Items per page:</span>
  <div class="vesto-input vesto-select vesto-select-small ml-2 mr-4 w-16">
    <ng-select [ngModel]="pagination?.size" [clearable]="false" (ngModelChange)="changePageSize($event)">
      <ng-option [value]="5">
        5
      </ng-option>
      <ng-option [value]="10">
        10
      </ng-option>
      <ng-option [value]="25">
        25
      </ng-option>
      <ng-option [value]="100">
        100
      </ng-option>
    </ng-select>
  </div>
  <span>page {{pagination?.page + 1}}</span>
  <div class="mx-4">
    <button class="icon-button-small mr-2" (click)="previous()" [disabled]="pagination?.page === 0">
      <i class="fal fa-chevron-left fa-fw"></i>
    </button>
    <button class="icon-button-small" (click)="next()">
      <i class="fal fa-chevron-right fa-fw"></i>
    </button>
  </div>
</div>
