export interface IContact {
  id?: string;
  name?: string;
  email?: string;
  organization?: string;
  comments?: string;
  documentName?: string;
  created?: string;
  // Transients.
  title?: string;
}
