<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <h3 class="section-number"><i class="fal fa-gas-pump"></i> Transfer Gas</h3>
</div>
<mat-dialog-content class="synchronize-modal">
  <ng-container [ngSwitch]="step">

   <form [formGroup]="formGroup" (ngSubmit)="transfer()" class="relative" *ngSwitchCase="'FORM_GROUP'">
      <div class="vesto-input vesto-input-inline my-4">
        <label class="inline" for="to">To ({{ blockchain | titlecase }}):</label>
        <input type="text" formControlName="to" id="to" name="to" placeholder="Enter address" />
      </div>

      <gbl-alert [type]="error" [message]="error"></gbl-alert>

      <button class="button button-primary my-4 w-full text-center block" type="submit">Transfer</button>
    </form>

    <section *ngSwitchCase="'TWO_FACTOR'">
      <gbl-two-factor (sendSmsCode)="sendSmsCode($event)" (code)="confirmCode($event)"></gbl-two-factor>
      <gbl-alert [type]="error" [message]="error"></gbl-alert>
    </section>

    <section *ngSwitchCase="'BUSY'" class="mt-4">
      <div class="placeholder-long bg-flash"></div>
      <div class="placeholder-area bg-flash mt-4"></div>
    </section>

    <section *ngSwitchCase="'SUCCESS'">
      <div style="width: 350px; height: 350px; overflow: hidden; margin: -24px auto 0">
        <gbl-lottie-player></gbl-lottie-player>
      </div>
    </section>

    <section *ngSwitchCase="'TRANSACTION_DETAIL'" class="slide-in">
      <div class="text-center">
        <p class="mt-4">Success!</p>
        <p class="text-sm mb-4">You transaction has been submitted to the blockchain</p>
      </div>
      <button class="button button-primary my-4 mx-auto w-full" (click)="close()">Close</button>
    </section>
  </ng-container>
</mat-dialog-content>
