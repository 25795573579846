import { createAction, props } from '@ngrx/store';
import {Blockchain, IApiError, IGasTank, IInstitution} from '@vesto/vesto';

export namespace GasTankActions {

  export const set = createAction('[@vesto/gas-tank] set', props<{ gasTank: IGasTank }>());

  export const findActive = createAction('[@vesto/gas-tank] findActive');

  export const findActiveSuccess = createAction('[@vesto/gas-tank] findActiveSuccess', props<{ gasTanks: IGasTank[] }>());

  export const findActiveFailure = createAction('[@vesto/gas-tank] findActiveFailure', props<{ apiError: IApiError }>());

  export const changeSettings = createAction('[@vesto/gas-tank] changeSettings', props<{ gasTankId: string; blockchain: Blockchain; minPrice: number; maxPrice: number; limit: number }>());

  export const changeSettingsSuccess = createAction('[@vesto/gas-tank] changeSettingsSuccess', props<{ gasTank: IGasTank }>());

  export const changeSettingsFailure = createAction('[@vesto/gas-tank] changeSettingsFailure', props<{ apiError: IApiError }>());

}
