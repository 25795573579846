<main class="grid grid-cols-2 gap-3 mb-3">
  <section *ngFor="let gasTank of gasTanks">
    <div class="bg-white shadowed rounded p-3">
      <div class="flex items-center border-bottom-light pb-2">
        <h4 class="flex-grow text-sm">Gas Tank <span class="block text-xs">({{ gasTank.type | titlecase }})</span></h4>

        <button class="icon-button text-vesto-primary" (click)="transfer(gasTank.id)" matTooltip="Transfer">
          <i class="fal fa-exchange"></i>
        </button>

        <button class="icon-button text-vesto-primary" (click)="openOrGoToGasTank(gasTank)" matTooltip="Modify">
          <i class="fal fa-sliders-v"></i>
        </button>

        <ng-container *ngFor="let gasSettings of gasTank.gasSettings">
          <a target="_blank" *ngIf="gasSettings.blockchain === selectedBlockchain" [href]="gasSettings.url" class="w-4 text-center">
            <img [src]="gasSettings.blockchain | blockchain" [style.width]="gasSettings.blockchain === Blockchain.ETHEREUM ? '10px' : '16px'" class="mr-2 -mt-1" />
          </a>
        </ng-container>
      </div>
      <ng-container *ngFor="let gasSettings of gasTank.gasSettings">
        <ng-container *ngIf="gasSettings.blockchain === selectedBlockchain">
          <div class="flex mt-1 text-sm">
            <div class="flex-grow">Balance:</div>
            <b>{{ gasSettings.balance | number: '1.8-8' }}</b>
          </div>
          <div class="flex mt-1 text-sm">
            <div class="flex-grow">Price:</div>
            <b>{{ gasSettings.price | gwei }}</b>
          </div>
          <div class="flex mt-1 text-sm">
            <div class="flex-grow">Min Price:</div>
            <b>{{ gasSettings.minPrice | gwei }}</b>
          </div>
          <div class="flex mt-1 text-sm">
            <div class="flex-grow">Max Price:</div>
            <b>{{ gasSettings.maxPrice | gwei }}</b>
          </div>
          <div class="flex mt-1 text-sm">
            <div class="flex-grow">Limit:</div>
            <b>{{ gasSettings.limit | number }}</b>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </section>
</main>
