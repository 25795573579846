import { Component } from '@angular/core';
import { DashboardFacade, ProfileBaseComponent } from '@vesto/xplat/features';

@Component({
  selector: 'gbl-profile',
  templateUrl: 'profile.component.html'
})
export class ProfileComponent extends ProfileBaseComponent {
  constructor(protected dashboardFacade: DashboardFacade) {
    super(dashboardFacade);
  }
}
