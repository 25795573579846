import { Directive, OnInit } from '@angular/core';
import { BaseComponent, CoreService } from '@vesto/xplat/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import {FeatureService} from '../../../services';
import {IUser} from "@vesto/vesto";
import {filter} from "rxjs";

@Directive()
export abstract class VerifyIdentityModalBaseComponent extends BaseComponent {
  formGroup: UntypedFormGroup;
  googlePlaceId: string;
  error: string;
  user: IUser;

  constructor() {
    super();
    this.formGroup = CoreService.formBuilder.group(
      {
        address: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ]
      },
      { updateOn: 'change' }
    );

    CoreService.userFacade.user$
      .pipe(
        filter((user) => !!user),
        takeUntil(this.destroy$)
      )
      .subscribe((user) => {
        this.user = user;
        this.formGroup.controls.address.setValue(this.user.address);
        this.googlePlaceId = this.user.google.placeId;
      });
  }

  addressChange(event: any) {
    if (event && event.formatted_address) {
      this.formGroup.patchValue(
        {
          ...this.formGroup.value,
          address: event.formatted_address
        },
        { emitEvent: false }
      );
      this.googlePlaceId = event.place_id;
    }
  }

  verifyIdentity() {
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }

    const value = this.formGroup.value;
    FeatureService.dashboardFacade.verifyIdentity(value.address, this.googlePlaceId);
  }
}
