import {IFee} from './fee.interface';
import {ITransaction} from './transaction.interface';
import {IToken} from './token.interface';
import {IMerchant} from './merchant.interface';

export interface IPaymentInfo {
  accountId: string;
  merchant: IMerchant;
  currency: IToken;
  amount: number;
  fees: IFee[];
  memo: string;
  transaction: ITransaction;
}
