import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { isAndroid, isNativeScript, isObject } from '@vesto/xplat/utils';
import { BehaviorSubject } from 'rxjs';
import { filter, throttleTime } from 'rxjs/operators';
import { PlatformModalToken, IPlatformModal } from './tokens';
import { UiActions } from '../state/ui/ui.actions';
import { UiState } from '../state/ui/ui.state';
import { LogService } from './log.service';
import { WindowService } from './window.service';

// open return value type
export interface IOpenReturn {
  cmpType: any;
}

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  modalForceAction: boolean;
  private _modalRefs: Array<any> = [];
  // private _routed: boolean;
  // defaults
  private _defaultOptions: UiState.IModalOptions = {
    backdrop: 'static',
    keyboard: true
  };

  constructor(
    private _store: Store,
    @Inject(PlatformModalToken)
    private _platformModal: IPlatformModal,
    private _router: Router,
    private _ngZone: NgZone,
    private _log: LogService,
    private _win: WindowService,
    @Inject(DOCUMENT)
    private _document: Document
  ) {
    // Note: may want this at some point
    // if (this._win.isBrowser) {
    //   this._router.events
    //     .pipe(
    //       filter(e => e instanceof NavigationStart),
    //       throttleTime(600),
    //     )
    //     .subscribe(e => {
    //       // ignore first app launch
    //       if (this._routed && this._win.isBrowser) {
    //         // ensure web modals are closed if user begins to navigate
    //         if (e instanceof NavigationStart) {
    //           this._store.dispatch(UiActions.closeModal(null));
    //         }
    //       } else {
    //         this._routed = true;
    //       }
    //     });
    // }
  }

  get modalRefs() {
    return this._modalRefs;
  }

  // convenient for easy access to when a modal closes along with it's result
  private _closed$: BehaviorSubject<boolean>;
  get closed$() {
    if (!this._closed$) {
      // only construct if used
      this._closed$ = new BehaviorSubject<boolean>(false);
    }
    return this._closed$;
  }

  open(options: UiState.IModalStateOptions): IOpenReturn {
    let params: any = {};
    if (this._win.isBrowser) {
      params = materialAdapter(options.modalOptions);
      params.data = options.props;
    } else {
      params = {
        nativeOptions: {
          ...options.modalOptions,
          animated: true,
          fullscreen: isAndroid()
        },
        data: {
          ...(options.props || {})
        }
      };
    }
    const details: IOpenReturn = {
      cmpType: options.cmpType
    };
    if (!options.modalOptions) {
      options.modalOptions = this._defaultOptions;
    }
    // open modal using platform specific modal service
    const dialog: any = typeof options.cmpType !== 'string' ? this._platformModal.open(options.cmpType, params) : null;
    if (dialog && dialog.componentInstance) {
      if (options.props) {
        for (const key in options.props) {
          dialog.componentInstance[key] = options.props[key];
        }
      }
    }
    if (dialog) {
      if (dialog.afterClosed) {
        dialog.afterClosed().subscribe(
          (result: any) => {
            this._closeWithResult(result);
            this._log.debug('Modal closed with:', result);
          },
          (reason: any) => {
            this._closeWithResult();
            this._log.debug('Modal closed reason:', reason);
          }
        );
      }
    }
    this._modalRefs.push(dialog);
    return details;
  }

  close(latestResult?: any): any {
    const dialogToClose = this._modalRefs.pop();
    if (dialogToClose) {
      if (dialogToClose.close) {
        if (this._win.isBrowser) {
          const backdropSelector = '.cdk-overlay-backdrop';
          let modalBackdrops;
          let totalModals = 0;
          modalBackdrops = this._document.querySelectorAll(backdropSelector);
          // on web since multiple modals can be opened on top of each other
          // there's a case when closing them will not remove the underlying modals
          if (modalBackdrops) {
            totalModals = modalBackdrops.length;
          }
          // web material
          try {
            dialogToClose.close(latestResult);
          } catch (err) {
            // ignore, in future we may manually remove from dom
          }
          // ensure they are removed from DOM
          // this is probably a bug in ng-bootstrap but doing this will not hurt here for safety
          modalBackdrops = this._document.querySelectorAll(backdropSelector); // get latest
          if (modalBackdrops.length === totalModals) {
            // the _modalRef.close class above did not close any
            // manually remove multiple stacked modals in a row
            const modalWindows = this._document.querySelectorAll('mat-dialog-container');
            // these should be ultimately 0 after closed - if not remove top one (most recent one)
            if (modalBackdrops && modalBackdrops.length) {
              modalBackdrops[modalBackdrops.length - 1].remove();
            }
            if (modalWindows && modalWindows.length) {
              modalWindows[modalWindows.length - 1].remove();
            }
            this._document.querySelector('body').classList.remove('modal-open');
          }
        } else {
          // mobile
          dialogToClose.close(latestResult);
        }
        return latestResult;
      }
    }
    return null;
  }

  private _closeWithResult(result?: any) {
    this._ngZone.run(() => {
      // since this can fire in different contexts, ensure always in zone when fired
      if (this._closed$) {
        this._closed$.next(result);
      }
      // this._store.dispatch(UiActions.closeModal());
      this._store.dispatch(
        UiActions.closedModal({
          open: this.modalRefs.length === 0,
          cmpType: null,
          latestResult: typeof result === 'undefined' ? null : result
        })
      );
    });
  }
}

function materialAdapter(modalOptions: UiState.IModalOptions) {
  const config: any = {
    ...(modalOptions || {})
  };
  // In case if we didn't pass any prop
  if (!modalOptions) {
    return config;
  }
  config.disableClose = !modalOptions.keyboard;
  config.backdropClass = modalOptions.backdropClass;
  config.panelClass = modalOptions.panelClass;
  return config;
}
