import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IEnvironment, AlertsApi } from '@vesto/vesto';
import { EnvironmentToken } from './tokens';

@Injectable({
  providedIn: 'root'
})
export class AlertsApiService extends AlertsApi {
  constructor(@Inject(EnvironmentToken) protected environment: IEnvironment, protected httpClient: HttpClient) {
    super(environment, httpClient);
  }
}
