import { Component } from '@angular/core';
import { VerifyIdentityModalBaseComponent } from '@vesto/xplat/features';

@Component({
  selector: 'gbl-verify-identity-modal',
  templateUrl: 'verify-identity-modal.component.html'
})
export class VerifyIdentityModalComponent extends VerifyIdentityModalBaseComponent {
  options = { numericOnly: true, blocks: [2, 2, 4], delimiter: '/' };

  constructor() {
    super();
  }
}
