<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <h3 class="section-number">Set new password</h3>
</div>
<mat-dialog-content>
  <ng-container [ngSwitch]="step">
    <form [formGroup]="formGroup" (ngSubmit)="setPassword()" *ngSwitchCase="'FORM_GROUP'">
      <div class="vesto-input mt-3">
        <label for="password">New Password</label>
        <gbl-input mask="strong-password" formControlName="password" id="password" name="password"></gbl-input>
      </div>

      <gbl-alert [type]="error" [message]="error"> </gbl-alert>

      <button class="button button-primary mt-5 w-full" type="submit">Set Password</button>
    </form>

    <section *ngSwitchCase="'BUSY'" class="mt-16">
      <div class="placeholder-long bg-flash"></div>
      <div class="placeholder-area bg-flash mt-4"></div>
    </section>

    <section *ngSwitchCase="'SUCCESS'">
      <div style="width: 350px; height: 350px; overflow: hidden; margin: -24px auto 0">
        <gbl-lottie-player></gbl-lottie-player>
      </div>
    </section>
  </ng-container>
</mat-dialog-content>
