import {Directive, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseComponent, CoreService} from '@vesto/xplat/core';
import {
  AccountStatus,
  Blockchain,
  IAccount,
  ISnapShot,
  ISystem,
  IToken,
  ITransaction,
  IUser,
  RoleType,
  TransactionStatus,
  TransactionType, UserStatus,
  UserUtil
} from '@vesto/vesto';
import {distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';
import {FeatureService} from '../../services';
import {AccountHeader, DashboardActions, DashboardState, IPagination, Section} from "../state";
import {PageEvent} from '@angular/material/paginator';
import {FormGroup} from "@angular/forms";
import IFilter = DashboardState.IFilter;
import selectedPagination = DashboardState.selectedPagination;
import {TransactionActions} from '@vesto/ngx-vesto/state';

@Directive()
export abstract class TransactionsBaseComponent extends BaseComponent implements OnInit {
  user: IUser;
  selectedAccount: IAccount;
  selectedSymbol: string;
  transactions: ITransaction[] = [];
  TransactionType = TransactionType;
  TransactionStatus = TransactionStatus;
  AccountStatus = AccountStatus;
  selectedToken: IToken;
  Blockchain = Blockchain;
  accountSnapShots: ISnapShot[] = [];
  chartSeries = [];
  UserUtil = UserUtil;
  UserStatus = UserStatus;
  RoleType = RoleType;
  system: ISystem;
  readyForData = false;
  AccountHeader = AccountHeader;
  accountHeader = AccountHeader.CONTRACT;
  selectedSection: string;
  Section = Section;
  transactionStatuses = [];
  transactionTypes = [];
  formGroup: FormGroup;
  filter: IFilter;
  showAllTransactions = false;
  selectedPagination: IPagination;
  @Input() height = '503px';
  @Output() openBuy: EventEmitter<void> = new EventEmitter();

  ngOnInit() {
    CoreService.systemFacade.system$
      .pipe(
        filter((system) => !!system),
        takeUntil(this.destroy$)
      )
      .subscribe((system) => {
        this.system = system;
      });
    CoreService.userFacade.user$
      .pipe(
        filter(user => !!user),
        takeUntil(this.destroy$)
      ).subscribe((user) => {
      this.user = user;
    });
    FeatureService.dashboardFacade.selectedAccount$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.selectedAccount = result;
      if (this.selectedAccount && !this.readyForData) {
        // we only want to setup data after a selectedaccount is known
        this.readyForData = true;
        this.readyToSetupData();
      }
    });
    FeatureService.dashboardFacade.selectedAccountHeader$
      .pipe(
        filter(accountHeader => !!accountHeader),
        takeUntil(this.destroy$)
      ).subscribe((accountHeader) => {
      this.accountHeader = accountHeader;
    });
    FeatureService.dashboardFacade.selectedSymbol$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.selectedSymbol = result;
    });
    FeatureService.dashboardFacade.selectedToken$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.selectedToken = result;
    });
    CoreService.transactionFacade.transactions$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.transactions = result;
    });
    FeatureService.dashboardFacade.filter$.pipe(takeUntil(this.destroy$)).subscribe((filter) => {
      this.filter = filter;
      this.filter.action === TransactionActions.findByTypeAndStatus ? this.showAllTransactions = true : false;
    });

    //TODO: make this symbol specific
    CoreService.snapShotFacade.userSnapShots$.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((result) => {
      this.accountSnapShots = result;
      const data = [];
      for (const snapShot of this.accountSnapShots) {
        data.push([new Date(snapShot.created).getTime(), snapShot.balance]);
      }
      this.chartSeries = [
        {
          name: 'balances',
          turboThreshold: 500000,
          data: data
        }
      ];
    });

    FeatureService.dashboardFacade.selectedSection$.pipe(
      filter(selectedSection => !!selectedSection),
      takeUntil(this.destroy$)
    )
      .subscribe((selectedSection) => {
      this.selectedSection = selectedSection;
    });

    FeatureService.dashboardFacade.selectedPagination$.pipe(
      filter(pagination => !!pagination),
      takeUntil(this.destroy$)
    )
      .subscribe((selectedPagination) => {
        this.selectedPagination = selectedPagination;
    });

    this.transactionTypes = Object.keys(TransactionType);
    this.transactionStatuses = Object.keys(TransactionStatus);

    this.formGroup = CoreService.formBuilder.group({
      transactionType: [ this.filter.type ],
      transactionStatus: [ this.filter.status ]
    });

    this.formGroup.controls.transactionStatus.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((result) => {
      this.filter.status = result
      FeatureService.dashboardFacade.filter(this.filter);
    });

    this.formGroup.controls.transactionType.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((result) => {
      this.filter.type = result;
      FeatureService.dashboardFacade.filter(this.filter);
    });
  }

  readyToSetupData() {
    // impl in various apps for their own data handling
  }

  page(pageEvent: PageEvent) {
    console.log(pageEvent)
  }

  selectSymbol(symbol: string) {
    console.log('FOOK');
    FeatureService.dashboardFacade.selectSymbol(symbol);
  }

  openDetail(transaction: ITransaction) {
    FeatureService.dashboardFacade.openOrGoToTransactionDetail(transaction);
  }

  refresh() {
    FeatureService.dashboardFacade.selectAccount(this.selectedAccount);
  }

  buySell() {
    FeatureService.dashboardFacade.openOrGoToBuySell();
  }

  mint() {
    FeatureService.dashboardFacade.openOrGoToChooseToken({ action: 'mint' });
  }

  openChangeRole() {
    FeatureService.dashboardFacade.openChangeRole();
  }

  openSwapSigningAddress() {
    FeatureService.dashboardFacade.openOrGoToTwoFactor('swap signing addresses');
  }

  openSetDailyLimits() {
    FeatureService.dashboardFacade.openSetDailyLimits();
  }

  openSyncImplementationAddresses() {
    FeatureService.dashboardFacade.openOrGoToChooseBlockchain({ action: 'sync implementation addresses' });
  }

  openSyncAddresses() {
    FeatureService.dashboardFacade.openOrGoToChooseBlockchain({ action: 'sync addresses' });
  }

  syncApys() {
    FeatureService.dashboardFacade.openOrGoToTwoFactor('sync apys');
  }

  swapVSigningAddress() {
    FeatureService.dashboardFacade.openOrGoToTwoFactor('swap vsigning address');
  }

  openAddYearnVault() {
    FeatureService.dashboardFacade.openOrGoToAddYearnVault();
  }

  openElement() {
    FeatureService.dashboardFacade.openOrGoToElement();
  }

  refreshElement() {
    //Constants.elementFacade.refresh(this.selectedElement.id);
  }

  sign(event: Event, transaction: ITransaction) {
    event.stopPropagation();
    FeatureService.dashboardFacade.openOrGoToTwoFactor('sign', transaction);
  }

  redeem(event: Event, transaction: ITransaction) {
    event.stopPropagation();
    FeatureService.dashboardFacade.openOrGoToTwoFactor('redeem', transaction);
  }

  selectAccountHeader(accountHeader: AccountHeader) {
    FeatureService.dashboardFacade.selectAccountHeader(accountHeader);
  }

  openSetSettleLimits() {
    FeatureService.dashboardFacade.openOrGoToSetSettleLimits()
  }

  deployToken(event: Event, token: IToken) {
    event.stopPropagation();
    FeatureService.dashboardFacade.openOrGoToTwoFactor('deploy token', null, token);
  }

  openFiatbalances() {
    FeatureService.dashboardFacade.openFiatBalances();
  }

  setShowAllTransactions(
    showAllTransactions: boolean
  ) {
    this.showAllTransactions = showAllTransactions;
    FeatureService.dashboardFacade.filter({
      ...this.filter,
      action: showAllTransactions ? TransactionActions.findByTypeAndStatus : TransactionActions.findBuyOrSellActionRequired
    });
  }

  refreshGlobal() {
    // this.showAllTransactions ? FeatureService.dashboardFacade.setFilter(this.filter) :  CoreService.transactionFacade.findBuyOrSellActionRequired(this.selectedPagination.page, this.selectedPagination.size);
    console.log('SELECTED PAGINATION', this.selectedPagination);
    FeatureService.dashboardFacade.paginate(this.selectedPagination);
  }

  protected dataDisplayReady() {
    // implemented by each
  }
}
