import { IEnvironment } from '../interfaces/environment.interface';
import { IHttpClient } from '../interfaces/http.interface';
import { BaseApi } from './base-api';

export class SystemApi extends BaseApi {
  constructor(protected environment: IEnvironment, protected httpClient: IHttpClient) {
    super(environment);
  }

  find() {
    return this.httpClient.get(this.formatUrl(`/v${this.environment.vesto.version}/system`), { headers: this.getHeaders() });
  }

  deployContracts(
    token: string,
    code: string
  ) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/system/deploy-contracts/${token}/${code}`),
      null,
      {
        headers: this.getHeaders()
      });
  }

  initializeContracts() {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/system/initialize-contracts`), null, { headers: this.getHeaders() });
  }

  deployContract(
    contractId: string,
    token: string,
    code: string
  ) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/system/deploy-contract/${contractId}/${token}/${code}`),
      null,
      {
        headers: this.getHeaders()
      });
  }

  getFiatBalances() {
    return this.httpClient.get(this.formatUrl(`/v${this.environment.vesto.version}/system/fiat-balances`),
      {
        headers: this.getHeaders()
      });
  }

  sendSmsCode(
    userId: string
  ) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/system/send-sms-code/${userId}`),
      null,
      {
        headers: {
          ...this.getHeaders()
        }
      });
  }
}
