import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { VestoSelectors, VestoState } from '../vesto.state';
import { Observable } from 'rxjs';
import {Blockchain, ITransaction, RoleType, TransactionStatus, TransactionType} from '@vesto/vesto';
import {TransactionActions} from './transaction.actions';
import { Actions } from '@ngrx/effects';

@Injectable({
  providedIn: 'root'
})
export class TransactionFacade {
  transactions$: Observable<ITransaction[]> = this.store.pipe(select(VestoSelectors.transactions));

  constructor(private actions$: Actions, private store: Store<VestoState.IState>) {}

  set(
    transaction: ITransaction
  ): void {
    this.store.dispatch(TransactionActions.set({ transaction }));
  }

  findByAccount(accountId: string, page: number, size: number): void {
    this.store.dispatch(TransactionActions.findByAccount({ accountId, page, size }));
  }

  findByAccountAndSymbol(accountId: string, symbol: string, page: number, size: number): void {
    this.store.dispatch(TransactionActions.findByAccountAndSymbol({ accountId, symbol, page, size }));
  }

  findByTypeAndStatus(
    type: TransactionType,
    status: TransactionStatus,
    page: number,
    size: number
  ): void {
    this.store.dispatch(TransactionActions.findByTypeAndStatus({ _type: type, status, page, size }));
  }

  findBuyOrSellActionRequired(
    page: number,
    size: number
  ): void {
    this.store.dispatch(TransactionActions.findBuyOrSellActionRequired({ page, size }));
  }

  changeStatus(
    transactionId: string,
    status: TransactionStatus,
    code: string
  ): void {
    this.store.dispatch(TransactionActions.changeStatus({ transactionId, status, code }));
  }

  transferGas(gasTankId: string, blockchain: Blockchain, to: string, code: string): void {
    this.store.dispatch(TransactionActions.transferGas({ gasTankId, blockchain, to, code }));
  }

  syncAddresses(blockchain: Blockchain, code: string): void {
    this.store.dispatch(TransactionActions.syncAddresses({ blockchain, code }));
  }

  syncImplementationAddresses(blockchain: Blockchain, code: string): void {
    this.store.dispatch(TransactionActions.syncImplementationAddresses({ blockchain, code }));
  }

  syncApys(
    code: string
  ): void {
    this.store.dispatch(TransactionActions.syncApys({ code }));
  }

  swapVSigningAddress(code: string): void {
    this.store.dispatch(TransactionActions.swapVSigningAddress({ code }));
  }

  buy(accountId: string, symbol: string, bankAccountId: string, amount: number, memo: string, code: string): void {
    this.store.dispatch(
      TransactionActions.buy({
        accountId,
        symbol,
        bankAccountId: bankAccountId,
        amount,
        memo,
        code
      })
    );
  }

  addYearnVault(address: string, code: string) {
    this.store.dispatch(TransactionActions.addYearnVault({ address, code }));
  }

  mint(
    symbol: string,
    code: string
  ) {
    this.store.dispatch(TransactionActions.mint({ symbol, code }));
  }

  setUnderlyingSupplyLimit(
    symbol: string,
    underlyingLimit: number,
    code: string
  ) {
    this.store.dispatch(TransactionActions.setUnderlyingSupplyLimit({ symbol, underlyingLimit, code }));
  }

  sell(accountId: string, symbol: string, bankAccountId: string, amount: number, memo: string, code: string): void {
    this.store.dispatch(
      TransactionActions.sell({
        accountId,
        symbol,
        bankAccountId: bankAccountId,
        amount,
        memo,
        code
      })
    );
  }

  transferToEmail(accountId: string, email: string, symbol: string, amount: number, memo: string, code: string): void {
    this.store.dispatch(
      TransactionActions.transferToEmail({
        accountId,
        email,
        symbol,
        amount,
        memo,
        code
      })
    );
  }

  transferToAddress(accountId: string, address: string, symbol: string, amount: number, memo: string, code: string): void {
    this.store.dispatch(
      TransactionActions.transferToAddress({
        accountId,
        address,
        symbol,
        amount,
        memo,
        code
      })
    );
  }

  createPaymentToken(
    symbol: string,
    amount: number,
    memo?: string
  ): void {
    this.store.dispatch(
      TransactionActions.createPaymentToken({
        symbol,
        amount,
        memo
      })
    );
  }

  findPaymentInfo(
    token: string
  ) {
    this.store.dispatch(TransactionActions.findPaymentInfo({token}));
  }

  payByToken(
    accountId: string,
    paymentToken: string,
    tip: number,
    code: string
  ): void {
    this.store.dispatch(
      TransactionActions.payByToken({
        accountId,
        paymentToken,
        tip,
        code
      })
    );
  }

  addUser(accountId: string, email: string, code: string): void {
    this.store.dispatch(TransactionActions.addUser({ accountId, email, code }));
  }

  changeRole(
    userId: string,
    roleType: RoleType,
    code: string
  ): void {
    this.store.dispatch(TransactionActions.changeRole({ userId, roleType, code }));
  }

  swapSigningAddresses(
    code: string
  ): void {
    this.store.dispatch(TransactionActions.swapSigningAddresses({ code }));
  }

  removeUser(additionalUserId: string, code: string): void {
    this.store.dispatch(TransactionActions.removeUser({ additionalUserId, code }));
  }

  setDailyLimits(
    email: string,
    dailyBuyLimit: number,
    dailySellLimit: number,
    dailyTransferLimit: number,
    code: string
  ): void {
    this.store.dispatch(TransactionActions.setDailyLimits({ email, dailyBuyLimit, dailySellLimit, dailyTransferLimit, code }));
  }

  setSettleLimits(
    enabled: boolean,
    transactionLimit: number,
    dailyLimit: number,
    code: string
  ): void {
    this.store.dispatch(TransactionActions.setSettleLimits({ enabled, transactionLimit, dailyLimit, code }));
  }

  sendSmsCode() {
    this.store.dispatch(TransactionActions.sendSmsCode());
  }

  syncElement(accountId: string, trancheAddress: string, principalTokenPoolAddress: string, yieldTokenPoolAddress: string) {
    this.store.dispatch(TransactionActions.syncElement({ accountId, trancheAddress, principalTokenPoolAddress, yieldTokenPoolAddress }));
  }

  redeem(
    transactionId: string,
    code: string
  ) {
    this.store.dispatch(TransactionActions.redeem({transactionId, code}))
  }

  wireToCircle(
    code: string
  ) {
    this.store.dispatch(TransactionActions.wireToCircle({code}))
  }

  wireToFbo(
    code: string
  ) {
    this.store.dispatch(TransactionActions.wireToFbo({code}))
  }
}
