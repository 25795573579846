import {Blockchain} from '../enums/blockchain.enum';

export interface IGasSettings {
  id: string;
  blockchain: Blockchain;
  balance: number;
  price: number;
  minPrice: number;
  maxPrice: number;
  limit: number;
  url: string;
  updated: string;
  created: string;
}
