<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <ng-container [ngSwitch]="step">
    <h3 *ngSwitchCase="'FORM_GROUP'" class="section-number">{{ 'labels.signIn' | translate }}</h3>
    <h3 *ngSwitchCase="'TWO_FACTOR'" class="section-number">{{ 'labels.2faTitleVerify' | translate }}</h3>
    <h3 *ngSwitchCase="'SUCCESS'" class="section-number">{{ 'labels.signInSuccess' | translate }}</h3>
    <h3 *ngSwitchCase="'FORGOT_PASSWORD'" class="section-number">{{ 'labels.forgotPassword' | translate }}</h3>
  </ng-container>
</div>
<mat-dialog-content>
  <ng-container [ngSwitch]="step">
    <section *ngSwitchCase="'FORM_GROUP'">
      <form [formGroup]="formGroup" (ngSubmit)="signIn()">
        <div class="vesto-input">
          <label for="email">Email</label>
          <input type="email" focusMe="true" formControlName="email" id="email" name="email" />
        </div>

        <div class="vesto-input mt-3">
          <label for="password">Password</label>
          <gbl-input mask="password" formControlName="password" id="password" name="password"></gbl-input>
        </div>
        <a class="underline mat-small" (click)="goToForgotPassword()">{{ 'labels.forgotPassword' | translate }}?</a>

        <gbl-alert [type]="error" [message]="error"></gbl-alert>

        <button class="button button-primary w-full mt-5" type="submit">{{ 'labels.signIn' | translate }}</button>

        <span class="mat-small">Need to create an account? <a class="underline" (click)="goToSignUp()">{{ 'labels.signUp' | translate }}</a></span>
      </form>
    </section>

    <section *ngSwitchCase="'BUSY'" class="mt-16">
      <div class="placeholder-long bg-flash"></div>
      <div class="placeholder-area bg-flash mt-4"></div>
    </section>

    <section *ngSwitchCase="'TWO_FACTOR'">
      <div class="flex items-center" style="height: 310px">
        <gbl-two-factor (sendSmsCode)="sendSmsCode($event)" (code)="confirmCode($event)"></gbl-two-factor>
      </div>
      <gbl-alert [type]="error" [message]="error"></gbl-alert>
    </section>

    <section *ngSwitchCase="'SUCCESS'">
      <div style="width: 350px; height: 350px; overflow: hidden; margin: -24px auto 0">
        <gbl-lottie-player></gbl-lottie-player>
      </div>
    </section>

    <section *ngSwitchCase="'FORGOT_PASSWORD'" class="slide-in">
      Enter the email you registered with and we'll send you a link to reset your password

      <div class="bg-lilac-light rounded-lg p-3 my-4 flex text-sm items-center">
        <i class="fal fa-exclamation-triangle text-2xl mr-4"></i>
        <p>
          After you reset your password you will not be able to perform any transactions until your signing address has been swapped on the blockchain. <span class="font-bold">This could take 5-10 minutes.</span>
        </p>
      </div>
      <form [formGroup]="forgotPasswordFormGroup" (ngSubmit)="forgotPassword()">
        <div class="vesto-input mt-3">
          <input type="email" focusMe="true" formControlName="forgotPasswordEmail" id="forgotPasswordEmail" name="forgotPasswordEmail" placeholder="email" />
        </div>

        <gbl-alert [type]="error" [message]="error"></gbl-alert>
        <button class="button button-primary w-full mt-5" type="submit">{{ 'labels.forgotPassword' | translate }}</button>
      </form>
      <a class="underline mat-small" (click)="goToSignIn()"><i class="fal fa-arrow-left"></i> Go back to sign in</a>
    </section>
  </ng-container>
</mat-dialog-content>
