import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { BaseComponent, CoreService, StorageKeys, uiCloseModal } from '@vesto/xplat/core';
import { IAccount, ITransaction } from '@vesto/vesto';
import { DashboardFacade } from '@vesto/xplat/features';
import { take, takeUntil } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';
import { TransactionActions } from '@vesto/ngx-vesto/state';

@Component({
  selector: 'gbl-element-modal',
  templateUrl: './element-modal.component.html'
})
export class ElementModalComponent extends BaseComponent implements OnInit {
  step: 'FORM_GROUP' | 'TWO_FACTOR' | 'BUSY' | 'SUCCESS' | 'TRANSACTION_DETAIL' = 'FORM_GROUP';
  formGroup: UntypedFormGroup;
  selectedAccount: IAccount;
  error: string;
  transaction: ITransaction;

  constructor(private dashboardFacade: DashboardFacade) {
    super();
    this.formGroup = CoreService.formBuilder.group({
      trancheAddress: [
        null,
        {
          validators: [Validators.required, Validators.nullValidator]
        }
      ],
      principalTokenPoolAddress: [
        null,
        {
          validators: [Validators.required, Validators.nullValidator]
        }
      ],
      yieldTokenPoolAddress: [
        null,
        {
          validators: [Validators.required, Validators.nullValidator]
        }
      ]
    });
    this.dashboardFacade.selectedAccount$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.selectedAccount = result;
    });
  }

  ngOnInit() {}

  sendSmsCode(isResendSms: boolean) {
    CoreService.transactionFacade.sendSmsCode();

    if (isResendSms) {
      CoreService.uiFacade.notification(`SMS code sent!`);
    }
  }

  syncElement() {
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }

    this.step = 'TWO_FACTOR';
  }

  confirmCode(code: string) {
    this.step = 'BUSY';
    const value = this.formGroup.value;
    CoreService.transactionFacade.syncElement(this.selectedAccount.id, value.trancheAddress, value.principalTokenPoolAddress, value.yieldTokenPoolAddress);

    CoreService.actions$.pipe(ofType(TransactionActions.syncElementSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      CoreService.store.dispatch(TransactionActions.sign({ transaction: result.transaction, code, key: new Buffer(CoreService.storageService.getItem(StorageKeys.KEY), 'hex').toString() }));
    });

    CoreService.actions$.pipe(ofType(TransactionActions.syncElementFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
      this.step = 'FORM_GROUP';
    });

    CoreService.actions$.pipe(ofType(TransactionActions.signSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.step = 'SUCCESS';
      this.transaction = result.transaction;
      CoreService.windowService.setTimeout(() => {
        this.step = 'TRANSACTION_DETAIL';
      }, 2200);
    });

    CoreService.actions$.pipe(ofType(TransactionActions.signFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
    });
  }

  close() {
    uiCloseModal();
  }
}
