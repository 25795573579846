import { Component, OnInit } from '@angular/core';
import { BaseComponent, CoreService, uiCloseModal } from '@vesto/xplat/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import {take, takeUntil} from 'rxjs/operators';
import {ofType} from "@ngrx/effects";
import {InstitutionActions} from "@vesto/ngx-vesto/state";
import {IInstitution, InstitutionFeeType} from "@vesto/vesto";

@Component({
  selector: 'gbl-institution-modal',
  templateUrl: './institution-modal.component.html'
})
export class InstitutionModalComponent extends BaseComponent implements OnInit {
  step: 'FORM_GROUP' | 'BUSY' | 'SUCCESS' | 'DETAIL' = 'FORM_GROUP';
  formGroup: UntypedFormGroup;
  error: string;
  institutionName: string;
  action: 'create';
  institution: IInstitution;
  googlePlaceId: string;
  InstitutionFeeType = InstitutionFeeType;

  ngOnInit() {
    if (this.action === 'create') {
      this.formGroup = CoreService.formBuilder.group({
        name: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        adminEmail: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator, Validators.email]
          }
        ],
        iosAppId: [
          null,
        ],
        androidAppId: [
          null,
        ],
        url: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        referrer: [
          'https://api.vesto.io/*',
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        merchantEnabled: [
          false,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        feeType: [
          InstitutionFeeType.FIXED,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        feeFixed: [
          0,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        feePercent: [
          0,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ]
      });
    } else {
      this.formGroup = CoreService.formBuilder.group({
        name: [
          this.institution.name,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        iosAppId: [
          this.institution.iosAppId,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        androidAppId: [
          this.institution.androidAppId,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        url: [
          this.institution.url,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        referrer: [
          this.institution.referrer,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        merchantEnabled: [
          this.institution.merchantEnabled,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        feeType: [
          InstitutionFeeType.FIXED,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        feeFixed: [
          0,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        feePercent: [
          0,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ]
      });
    }
  }

  addressChange(event: any) {
    if (event && event.formatted_address) {
      this.formGroup.patchValue(
        {
          ...this.formGroup.value,
          address: event.formatted_address
        },
        { emitEvent: false }
      );
      this.googlePlaceId = event.place_id;
    }
  }

  submit() {
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }

    const value = this.formGroup.value;
    switch (this.action) {
      case 'create':
        this.institutionName = value.name;
        CoreService.institutionFacade.create(value.name, value.adminEmail, value.iosAppId, value.androidAppId, value.url, value.referrer, value.merchantEnabled);
        break;
      default:
        CoreService.institutionFacade.changeSettings(this.institution.id, value.name, value.address, value.iosAppId, value.androidAppId, value.referrer, value.url, value.merchantEnabled, value.instantSettleEnabled, value.settleTransactionLimit, value.settleDailyLimit, value.feeType, value.feeFixed, value.feePercent);
        break
    }
    this.step = 'BUSY';

    CoreService.actions$.pipe(ofType(InstitutionActions.createSuccess, InstitutionActions.changeSettingsSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      CoreService.windowService.setTimeout(() => {
        this.step = 'SUCCESS';
        CoreService.windowService.setTimeout(() => {
          this.step = 'DETAIL';
        }, 1600);
      }, 1600);
    });

    CoreService.actions$.pipe(ofType(InstitutionActions.createFailure, InstitutionActions.changeSettingsFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      CoreService.windowService.setTimeout(() => {
        this.error = `apiErrors.${result.apiError.error}`;
        this.step = 'FORM_GROUP';
      }, 1600);
    });
  }

  close() {
    uiCloseModal();
  }
}
