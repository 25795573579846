import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { UiState } from './ui.state';
import { UiActions } from './ui.actions';
import { ICountryCode } from '../../interfaces';
import { Actions, ofType } from '@ngrx/effects';
import { take, map } from 'rxjs/operators';
import { uiCloseModal } from './ui.util';

@Injectable({
  providedIn: 'root'
})
export class UiFacade {
  public countryCodes$: Observable<ICountryCode[]> = this.store.pipe(select(UiState.countryCodes));
  private selectOptionSubscription: any = null;

  constructor(private store: Store<UiState.IState>, private actions$: Actions, private ngZone: NgZone) {}

  go(path: any[], extras?: any) {
    this.store.dispatch(UiActions.go({ path, extras }));
  }

  back() {
    this.store.dispatch(UiActions.back());
  }

  selectOption(option: any) {
    this.store.dispatch(UiActions.selectOption({ option }));
  }

  showSpinner() {
    this.store.dispatch(UiActions.showSpinner());
  }

  hideSpinner() {
    this.store.dispatch(UiActions.hideSpinner());
  }

  showBottomOverlay(options: any[], inModal?: boolean, message?: string, showAction?: boolean): any {
    this.ngZone.run(() => {
      this.store.dispatch(UiActions.showBottomOverlay({ options, inModal, message, showAction }));
    });

    if (this.selectOptionSubscription) {
      this.selectOptionSubscription.unsubscribe();
    }
    return new Promise((resolve) => {
      this.selectOptionSubscription = this.actions$
        .pipe(
          ofType(UiActions.selectOption),
          take(1),
          map((result) => result.option)
        )
        .subscribe((result) => {
          this.selectOptionSubscription = null;
          resolve(result);
        });
    });
  }

  forward() {
    this.store.dispatch(UiActions.forward());
  }

  openCountrySelector() {
    this.store.dispatch(UiActions.openCountrySelector());
  }

  selectCountryCode(countryCode: ICountryCode) {
    this.store.dispatch(UiActions.selectCountryCode({ countryCode }));
  }

  notification(message: string) {
    this.store.dispatch(UiActions.notification({ message }));
  }

  openModal(options: UiState.IModalStateOptions) {
    this.store.dispatch(UiActions.openModal(options));
  }

  closeModal(result?: any) {
    uiCloseModal(result);
  }
}
