<div class="accounts w-full">
  <ng-container [ngSwitch]="selectedAccount?.type">
    <section *ngSwitchCase="AccountType.VBRIDGE">
      <div class="grid grid-cols-4 gap-3 mb-3">
        <div *ngFor="let contract of selectedAccount?.contracts" class="system-component shadowed rounded-lg p-3">
          <gbl-smart-contract [contract]="contract"></gbl-smart-contract>
        </div>
      </div>
    </section>

    <section *ngSwitchCase="AccountType.VLIGHTHOUSE">
      <div class="grid grid-cols-4 gap-3 mb-3">
        <div *ngFor="let contract of selectedAccount?.contracts" class="system-component shadowed rounded-lg p-3">
          <gbl-smart-contract [contract]="contract"></gbl-smart-contract>
        </div>
      </div>
    </section>

    <section *ngSwitchCase="AccountType.VDEFI">
      <div class="grid grid-cols-4 gap-3 mb-3" *ngIf="accountHeader === AccountHeader.CONTRACT">
        <div *ngFor="let contract of selectedAccount?.contracts" class="system-component shadowed rounded-lg p-3">
          <gbl-smart-contract [contract]="contract"></gbl-smart-contract>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-3 mb-3" *ngIf="accountHeader === AccountHeader.TOKEN">
        <div *ngFor="let token of yearnVaultTokens">
          <div class="shadowed rounded-lg p-3 bg-white items-justify" style="min-height: 220px">
            <div class="flex items-center mb-2 border-bottom-light pb-2">
              <img [src]="token.symbol | symbolIcon" class="w-6 mr-2" />
              <div class="flex-grow">
                {{ token.name }} <span class="text-sm"> ({{ token.symbol }})</span>
              </div>
              <a target="_blank" [href]="token?.url" (click)="onEvent($event)"><img [src]="token?.blockchain | blockchain" [style.width]="token?.blockchain === Blockchain.ETHEREUM ? '10px' : '16px'" class="mr-2 -mt-1" /></a>
              <div *ngIf="token?.status === TokenStatus.BUSY"><i class="fa fa-spinner fa-pulse"></i></div>
            </div>
            <div class="flex mt-2 text-sm">
              <div class="flex-grow">
                APY:
              </div>
              <b>{{ token.apy | percent: '1.5' }}</b>
            </div>
            <div class="flex mt-2 text-sm">
              <div class="flex-grow">
                DeFi Balance:
              </div>
              <b>{{ token.deFiUnderlyingBalance | currency }}</b>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section *ngSwitchCase="AccountType.VSWAP">
      <div class="grid grid-cols-4 gap-3 mb-3">
        <div *ngFor="let contract of selectedAccount?.contracts" class="system-component shadowed rounded-lg p-3">
          <gbl-smart-contract [contract]="contract"></gbl-smart-contract>
        </div>
      </div>
    </section>

    <section *ngSwitchCase="AccountType.VUSER">
      <div class="grid grid-cols-4 gap-3 mb-3">
        <div *ngFor="let contract of selectedAccount?.contracts" class="system-component shadowed rounded-lg p-3">
          <gbl-smart-contract [contract]="contract"></gbl-smart-contract>
        </div>
      </div>
    </section>

    <ng-container *ngSwitchCase="AccountType.VTOKEN">
      <div class="grid grid-cols-4 gap-3 mb-3" *ngIf="accountHeader === AccountHeader.CONTRACT">
        <div *ngFor="let contract of selectedAccount?.contracts" class="system-component shadowed rounded-lg p-3">
          <gbl-smart-contract [contract]="contract"></gbl-smart-contract>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-3 mb-3" *ngIf="accountHeader === AccountHeader.TOKEN">
        <div *ngFor="let token of erc20Tokens">
          <div class="shadowed rounded-lg p-3 bg-white text-sm">
            <div class="flex items-center mb-2 border-bottom-light pb-2">
              <img [src]="token.symbol | symbolIcon" class="w-6 mr-2" />
              <div class="flex-grow">
                {{ token.name }} <span class="text-xs block"> ({{ token.symbol }})</span>
              </div>
              <a target="_blank" [href]="token?.url" (click)="onEvent($event)"><img [src]="token?.blockchain | blockchain" [style.width]="token?.blockchain === Blockchain.ETHEREUM ? '10px' : '16px'" class="mr-2 -mt-1" /></a>
              <div *ngIf="token?.status === TokenStatus.BUSY"><i class="fa fa-spinner fa-pulse"></i></div>
            </div>
            <div class="flex mt-2 text-sm">
              <div class="flex-grow">
                APY:
              </div>
              <b>{{ token.apy | percent: '1.5' }}</b>
            </div>
            <div class="flex mt-1 text-sm bg-grey-50">
              <div class="flex-grow">
                Balance ({{ token.underlyingSymbol }}):
              </div>
              <b>{{ token.underlyingBalance | currency }}</b>
            </div>
            <div class="flex mt-1 text-sm">
              <div class="flex-grow">
                Total Supply:
              </div>
              <b>{{ token.totalUnderlyingSupply | currency }}</b>
            </div>
            <div class="flex mt-1 text-sm">
              <div class="flex-grow">
                Total Supply Limit:
              </div>
              <b>{{ token.totalUnderlyingSupplyLimit | currency }}</b>
            </div>
            <div class="flex mt-1 text-sm">
              <div class="flex-grow">
                Status:
              </div>
              <b>{{ token.status | replace : '_' : ' ' | titlecase }}</b>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="AccountType.VWALLET">
      <div class="grid grid-cols-4 gap-3 mb-3">
        <div *ngFor="let contract of selectedAccount?.contracts" class="system-component shadowed rounded-lg p-3">
          <gbl-smart-contract [contract]="contract"></gbl-smart-contract>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="AccountType.GAS_TANK">
      <gbl-gas-tanks></gbl-gas-tanks>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <div class="bg-white shadowed rounded-lg mb-3 p-3">
        <div class="mb-3 flex items-center">
          <div *ngIf="accountChartType === AccountChartType.BALANCES" class="flex-grow">
            <i class="fal fa-chart-line"></i> Balance: <span class="font-light">{{ selectedAccount?.balance.toFixed(2) | currency: 'USD' }}</span>
          </div>
          <div *ngIf="accountChartType === AccountChartType.REWARDS" class="flex-grow">
            <i class="fal fa-chart-line"></i> Rewards: <span class="font-light">{{ selectedAccount?.interest.toFixed(6) | currency: 'USD' }}</span>
          </div>
          <div class="chart-type-button mr-3" [class.selected]="accountChartType === AccountChartType.BALANCES" (click)="setChartSeries(AccountChartType.BALANCES)">Balances</div>
          <div class="chart-type-button" [class.selected]="accountChartType === AccountChartType.REWARDS" (click)="setChartSeries(AccountChartType.REWARDS)">Rewards</div>
        </div>
        <gbl-line-chart [series]="chartSeries" [height]="'140px'"></gbl-line-chart>
      </div>
    </ng-container>
  </ng-container>

  <div class="clearfix"></div>
</div>
