import { createAction, props } from '@ngrx/store';
import { IApiError, ISnapShot } from '@vesto/vesto';

export namespace SnapShotActions {
  export const findByAccount = createAction('[@vesto/snap-shot] findByAccount', props<{ accountId: string }>());

  export const findByAccountSuccess = createAction('[@vesto/snap-shot] findByAccountSuccess', props<{ snapShots: ISnapShot[] }>());

  export const findByAccountFailure = createAction('[@vesto/snap-shot] findByAccountFailure', props<{ apiError: IApiError }>());

  export const findByUser = createAction('[@vesto/snap-shot] findByUser');

  export const findByUserSuccess = createAction('[@vesto/snap-shot] findByUserSuccess', props<{ snapShots: ISnapShot[] }>());

  export const findByUserFailure = createAction('[@vesto/snap-shot] findByUserFailure', props<{ apiError: IApiError }>());

  export const findByInstitution = createAction('[@vesto/snap-shot] findByInstitution');

  export const findByInstitutionSuccess = createAction('[@vesto/snap-shot] findByInstitutionSuccess', props<{ snapShots: ISnapShot[] }>());

  export const findByInstitutionFailure = createAction('[@vesto/snap-shot] findByInstitutionFailure', props<{ apiError: IApiError }>());

  export const findBySystem = createAction('[@vesto/snap-shot] findBySystem');

  export const findBySystemSuccess = createAction('[@vesto/snap-shot] findBySystemSuccess', props<{ snapShots: ISnapShot[] }>());

  export const findBySystemFailure = createAction('[@vesto/snap-shot] findBySystemFailure', props<{ apiError: IApiError }>());
}
