import {Component} from "@angular/core";
import {PaginatorBaseComponent} from "@vesto/xplat/features";


@Component({
  selector: 'gbl-paginator',
  templateUrl: './paginator.component.html'
})

export class PaginatorComponent extends PaginatorBaseComponent {

}
