<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <h3 class="section-number"><i class="fal fa-coin"></i> Create and Deploy Token</h3>
</div>
<mat-dialog-content>
  <ng-container [ngSwitch]="step">
    <form [formGroup]="formGroup" (ngSubmit)="createAndDeployToken()" *ngSwitchCase="'FORM_GROUP'">
      <div class="vesto-input  vesto-input-inline my-4">
        <label for="name">Name</label>
        <input type="text" formControlName="name" id="name" name="name" />
      </div>

      <div class="vesto-input vesto-input-inline my-4">
        <label for="symbol">Symbol</label>
        <input type="text" formControlName="symbol" id="symbol" name="symbol" />
      </div>

      <div class="vesto-input vesto-input-inline my-4">
        <label for="underlyingSymbol">Underlying Symbol</label>
        <input type="text" formControlName="underlyingSymbol" id="underlyingSymbol" name="underlyingSymbol" />
      </div>

      <gbl-alert [type]="error" [message]="error"></gbl-alert>

      <button class="button button-primary w-full mt-4" type="submit">Create</button>
    </form>

    <section *ngSwitchCase="'TWO_FACTOR'">
      <gbl-two-factor (sendSmsCode)="sendSmsCode($event)" (code)="confirmCode($event)"></gbl-two-factor>
      <gbl-alert [type]="error" [message]="error"></gbl-alert>
    </section>

    <section *ngSwitchCase="'BUSY'" class="mt-4">
      <div class="placeholder-long bg-flash"></div>
      <div class="placeholder-area bg-flash mt-4"></div>
    </section>

    <section *ngSwitchCase="'SUCCESS'">
      <gbl-lottie-player></gbl-lottie-player>
    </section>

    <section *ngSwitchCase="'DETAIL'">
      <div class="slide-in">
        <div class="text-center">
          <p>Success!</p>
        </div>
        <div class="p-4 bg-trans-blue rounded-lg">
          Created {{ formGroup.controls.name.value }} token.
        </div>
        <button class="button button-primary my-4 mx-auto w-full" (click)="close()">Close</button>
      </div>
    </section>
  </ng-container>
</mat-dialog-content>
