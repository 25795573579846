import { ErrorHandler, Injectable } from '@angular/core';
import { LogService } from './log.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(private log: LogService) {
    super();
  }

  handleError(error: Error) {
    // catch all throw's in the callstack and just report them here
    this.log.error('GlobalErrorHandler', error);
    this.log.error(error.stack);
  }
}
