import {Directive, OnInit} from '@angular/core';
import {BaseComponent, CoreService, StorageKeys, uiCloseModal} from '@vesto/xplat/core';
import {take, takeUntil} from 'rxjs/operators';
import {FeatureService} from '../../../services/feature.service';
import {IAccount, IAdditionalUser, IContract, IToken, ITransaction, TransactionStatus} from '@vesto/vesto';
import {ofType} from '@ngrx/effects';
import {SystemActions, TransactionActions} from '@vesto/ngx-vesto/state';

@Directive()
export abstract class TwoFactorModalBaseComponent extends BaseComponent implements OnInit {
  step: 'TWO_FACTOR' | 'BUSY' | 'ERROR' | 'SUCCESS' | 'TRANSACTION_DETAIL' = 'TWO_FACTOR';
  action: 'sign' | 'sync apys' | 'swap vsigning address' | 'remove user' | 'sync price per tokens' | 'deploy contract' | 'deploy contracts' | 'deploy token' | 'redeem' | 'change transaction status' | 'swap signing addresses';
  transaction: ITransaction;
  token: IToken;
  selectedAccount: IAccount;
  error: string;
  additionalUser: IAdditionalUser;
  contract: IContract;
  TransactionStatus = TransactionStatus;
  status: string;

  ngOnInit() {
    FeatureService.dashboardFacade.selectedAccount$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.selectedAccount = result;
    });
    console.log('TWO FACTOR STATUS', this.status);
  }

  sendSmsCode(isResendSms: boolean) {
    if (this.action === 'deploy contract' || this.action === 'deploy contracts') {
      CoreService.systemFacade.sendSmsCode();
    } else if (this.action === 'deploy token') {
      CoreService.institutionFacade.sendSmsCode();
    }
    else {
      CoreService.transactionFacade.sendSmsCode();
    }

    if (isResendSms) {
      CoreService.uiFacade.notification(`SMS code sent!`);
    }
  }

  confirmCode(code: string) {
    this.step = 'BUSY';
    CoreService.actions$.pipe(ofType(TransactionActions.swapVSigningAddressSuccess, TransactionActions.removeUserSuccess, TransactionActions.syncApysSuccess, TransactionActions.redeemSuccess, TransactionActions.changeStatusSuccess, TransactionActions.swapSigningAddressesSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = null;
      const transaction = result.transaction;
      if (transaction && transaction.requiredSignatures > transaction.signatures.length) {
        CoreService.store.dispatch(TransactionActions.sign({ transaction, code, key: new Buffer(CoreService.storageService.getItem(StorageKeys.KEY), 'hex').toString() }));
      } else {
        this.step = 'SUCCESS';
        this.transaction = transaction;
        CoreService.windowService.setTimeout(() => {
          this.step = 'TRANSACTION_DETAIL';
        }, 2200);
      }
    });

    CoreService.actions$.pipe(ofType(TransactionActions.signSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.step = 'SUCCESS';
      this.transaction = result.transaction;
      CoreService.windowService.setTimeout(() => {
        this.step = 'TRANSACTION_DETAIL';
      }, 2200);
    });

    CoreService.actions$.pipe(ofType(TransactionActions.swapVSigningAddressFailure, TransactionActions.removeUserFailure, TransactionActions.signFailure, SystemActions.deployContractFailure, SystemActions.deployContractsFailure, TransactionActions.redeemFailure, TransactionActions.changeStatusFailure, TransactionActions.swapSigningAddressesFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.step = 'ERROR';
      this.error = `apiErrors.${result.apiError.error}`;
    });

    CoreService.actions$.pipe(ofType(SystemActions.deployContractSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.step = 'SUCCESS';
      CoreService.windowService.setTimeout(() => {
        this.step = 'TRANSACTION_DETAIL';
      }, 2200);
    });

    switch (this.action) {
      case 'sign':
        CoreService.store.dispatch(TransactionActions.sign({ transaction: this.transaction, code, key: new Buffer(CoreService.storageService.getItem(StorageKeys.KEY), 'hex').toString() }));
        break;
      case 'sync apys':
        CoreService.transactionFacade.syncApys(code);
        break;
      case 'swap vsigning address':
        CoreService.transactionFacade.swapVSigningAddress(code);
        break;
      case 'remove user':
        CoreService.transactionFacade.removeUser(this.additionalUser.id, code);
        break;
      case 'deploy contract':
        CoreService.systemFacade.deployContract(this.contract.id, code);
        break;
      case 'deploy contracts':
        CoreService.systemFacade.deployContracts(code);
        break;
      case 'redeem':
        CoreService.transactionFacade.redeem(this.transaction.id, code);
        break;
      case 'change transaction status':
        CoreService.transactionFacade.changeStatus(this.transaction.id, TransactionStatus[this.status], code);
        break;
      case 'swap signing addresses':
        CoreService.transactionFacade.swapSigningAddresses(code);
        break;
    }
  }

  close() {
    uiCloseModal();
    uiCloseModal(); // TODO: how to close all open modals?
  }

  tryAgain() {
    this.error = null;
    this.step = 'TWO_FACTOR';
  }
}
