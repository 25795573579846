import { Directive, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BaseComponent } from '@vesto/xplat/core';

@Directive()
export abstract class LineChartBaseComponent extends BaseComponent {
  @Input() height: string;
  options: any;
  // optionsAsString: string;

  @Input() set series(value: Array<any>) {
    if (value && value.length) {
      this.options = {
        chart: {
          backgroundColor: 'transparent',
          marginLeft: -3,
          marginRight: -3,
          spacingLeft: 0,
          spacingRight: 0,
          type: 'spline',
          height: this.height
        },
        title: {
          text: ''
        },
        lang: {
          noData: '&nbsp;'
        },
        noData: {
          style: {
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#303030'
          },
          useHTML: true
        },
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        tooltip: {
          formatter: function () {
            let months: any = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            let date = new Date(this.x);
            let day = date.getDate();

            let prettyDate = months[date.getMonth()] + ' ' + day + ', ' + date.getFullYear();
            let y = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(this.y);
            return prettyDate + '<br/><b>' + y + '</b>';
          }
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false
            }
          }
        },
        xAxis: {
          visible: true,
          type: 'datetime',
          labels: {
            formatter: function () {
              let months: any = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
              let date = new Date(this.value);
              let day = date.getDate();

              let prettyDate = day + ' ' + months[date.getMonth()] + ' ' + date.getFullYear().toString().slice(-2);
              return prettyDate;
            }
          },
          crosshair: true
        },
        yAxis: {
          visible: false,
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          gridLineWidth: 0
        },
        colors: ['#f6c241'],
        series: value
      };

      this.updateDisplay();
    }
  }

  updateDisplay() {
    // platforms can implement any specific display update logic as needed
  }
}
