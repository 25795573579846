import {Directive, OnInit} from "@angular/core";
import {BaseComponent} from "@vesto/xplat/core";
import {FeatureService, IPagination} from "@vesto/xplat/features";
import {filter, takeUntil} from "rxjs/operators";

@Directive()
export abstract class PaginatorBaseComponent extends BaseComponent implements OnInit {
  pagination: IPagination;

  ngOnInit() {
    FeatureService.dashboardFacade.selectedPagination$
      .pipe(
        filter(pagination => !!pagination),
        takeUntil(this.destroy$)
      )
      .subscribe(pagination => {
        this.pagination = pagination;
      });
  }

  changePageSize(
    size: number
  ) {
    this.pagination.size = size;
    FeatureService.dashboardFacade.paginate(this.pagination);
  }

  next() {
    this.pagination.page++;
    FeatureService.dashboardFacade.paginate(this.pagination);
  }

  previous() {
    if (this.pagination.page === 0) {
      return;
    }
    this.pagination.page--;
    FeatureService.dashboardFacade.paginate(this.pagination);
  }
}

