import { InjectionToken, Type } from '@angular/core';
import { IXPlatWindow } from '../interfaces';

/**
 * Various InjectionTokens shared across all platforms
 * Always suffix with 'Token' for clarity and consistency
 */

export const PlatformLanguageToken = new InjectionToken<string>('PlatformLanguageToken');
export const PlatformWindowToken = new InjectionToken<IXPlatWindow>('PlatformWindowToken');
export interface IPlatformModal {
  open: (cmpType: any, options?: any) => void;
}
export const PlatformModalToken = new InjectionToken<IPlatformModal>('PlatformModalToken');

export interface IPlatformDeviceInfo {
  // literal version identifier: usually used for api http headers
  version: string;
  // version label display (usually includes build number along with version)
  versionDisplay: string;
  // os type (could add others for web)
  os: 'ios' | 'android' | 'web';
  // various device details
  deviceDetails: string;
}

export const PlatformDeviceInfoToken = new InjectionToken<IPlatformDeviceInfo>('PlatformDeviceInfo');
export const RouterToken = new InjectionToken<any>('RouterToken');
export const ModalContextToken = new InjectionToken<any>('ModalContextToken');

export interface IOnfidoSDK {
  init: (options: { token: string; containerId: string; onComplete: (data: any) => void; onError: (error: any) => void; onModalRequestClose?: () => void }) => void;
}

export const OnfidoSDKToken = new InjectionToken<any>('OnfidoSDKToken');
