import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@vesto/xplat/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'gbl-lottie-player',
  template: '<ng-lottie [options]="options"></ng-lottie>'
})
export class LottiePlayerComponent extends BaseComponent implements OnInit {
  @Input() type = 'check';
  public options: AnimationOptions;

  ngOnInit() {
    switch (this.type) {
      case 'check':
        this.options = {
          path: '/assets/animations/lilac-check.json',
          loop: false,
        };
        break;
      case 'block':
        this.options = {
          path: '/assets/animations/blocks-moving.json',
          loop: true,
        }
        break;
      default:
        this.options = {
          path: '/assets/animations/lilac-check.json',
          loop: false,
        };
        break;
    }
  }
}
