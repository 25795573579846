import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent, CoreService } from '@vesto/xplat/core';
import { IUser } from '@vesto/vesto';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { filter, takeUntil } from 'rxjs/operators';

@Directive()
export abstract class TwoFactorBaseComponent extends BaseComponent implements OnInit {
  @Input() theme: 'dark' | 'light' = 'light';
  @Output() sendSmsCode = new EventEmitter<boolean>();
  @Output() code = new EventEmitter<string>();
  user: IUser;
  formGroup: UntypedFormGroup;
  error: string;

  ngOnInit() {
    CoreService.userFacade.user$
      .pipe(
        filter((user) => !!user),
        takeUntil(this.destroy$)
      )
      .subscribe((user) => {
        this.user = user;
      });

    this.formGroup = CoreService.formBuilder.group({
      code: [
        null,
        {
          validators: [Validators.required, Validators.minLength(6), Validators.maxLength(6)]
        }
      ]
    });
    this.sendSmsCode.emit();
  }

  confirm() {
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }

    this.code.emit(String(this.formGroup.controls.code.value));
  }
}
