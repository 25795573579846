<div class="bg-white shadowed rounded my-4 p-3">
  <h4>Alerts</h4>
  <div class="flex border-bottom-light p-4 items-center text-sm">
    <span class="font-bold">03/27/2021</span>
    <div class="flex-grow px-3">Chris McGregor has requested a limit increase</div>
    <a class="underline mr-3">View User</a>
    <i class="fal fa-check-circle mr-3 text-xl cursor-pointer circle-icon-button-color text-green-dark"></i>
    <i class="fal fa-times-circle text-red-dark text-xl circle-icon-button-color cursor-pointer"></i>
  </div>
</div>
