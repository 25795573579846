<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <h3 class="section-number"><i class="fal fa-sliders-v"></i> Daily Limits</h3>
</div>
<mat-dialog-content>
  <ng-container [ngSwitch]="step">
    <form [formGroup]="formGroup" (ngSubmit)="changeDailyLimits()" *ngSwitchCase="'FORM_GROUP'">
      <div class="my-4 vesto-input vesto-input-inline">
        <label>Email</label>
        <input type="text" formControlName="email" id="email" name="email"/>
      </div>
      <div class="my-4 vesto-input vesto-input-inline">
        <label>Daily Buy Limit</label>
        <gbl-input class="currency-input my-4" mask="currency" formControlName="dailyBuyLimit"></gbl-input>
      </div>
      <div class="my-4 vesto-input vesto-input-inline">
        <label>Daily Sell Limit</label>
        <gbl-input class="currency-input my-4" mask="currency" formControlName="dailySellLimit"></gbl-input>
      </div>
      <div class="my-4 vesto-input vesto-input-inline">
        <label>Daily Transfer Limit</label>
        <gbl-input class="currency-input my-4" mask="currency" formControlName="dailyTransferLimit"></gbl-input>
      </div>

      <gbl-alert [type]="error" [message]="error"></gbl-alert>

      <div class="text-right mt-3">
        <button class="button button-primary" type="submit">Submit</button>
      </div>
    </form>

    <section *ngSwitchCase="'TWO_FACTOR'">
      <gbl-two-factor (sendSmsCode)="sendSmsCode($event)" (code)="confirmCode($event)"></gbl-two-factor>
      <gbl-alert [type]="error" [message]="error"></gbl-alert>
    </section>

    <section *ngSwitchCase="'BUSY'" class="mt-4">
      <div class="placeholder-long bg-flash"></div>
      <div class="placeholder-area bg-flash mt-4"></div>
    </section>

    <section *ngSwitchCase="'SUCCESS'">
      <gbl-lottie-player></gbl-lottie-player>
    </section>

    <section *ngSwitchCase="'DETAIL'">
      <div class="slide-in">
        <div class="text-center">
          <p>Success!</p>
          <p class="text-sm mb-4">You transaction has been submitted to the blockchain</p>
        </div>
        <div class="p-4 bg-trans-blue rounded-lg">
          <gbl-transaction [transaction]="transaction"></gbl-transaction>
        </div>
        <button class="button button-primary my-4 mx-auto w-full" (click)="close()">Close</button>
      </div>
    </section>
  </ng-container>
</mat-dialog-content>
