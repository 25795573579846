<div class="bg-white shadowed rounded mb-3 p-3">
  <div class="mb-4" *ngIf="bankAccounts && bankAccounts.length > 0">
    <div class="list-item justify-between items-center" *ngFor="let bankAccount of bankAccounts">
      <div>
        <span class="text-lg font-bold block" (click)="setDefault(bankAccount.id)">{{ bankAccount.institution }}</span>
        <span class="text-sm">{{ bankAccount.name }} {{ bankAccount.mask }}</span>
      </div>
      <div class="flex">
        <span *ngIf="bankAccount.default" class="text-sm"><i class="fal fa-check"></i> Default</span>
        <div [matMenuTriggerFor]="menu" class="ml-5" *ngIf="!bankAccount.default">
          <i class="fal fa-ellipsis-h text-3xl"></i>
        </div>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="setDefault(bankAccount.id)">
            Set Default
          </button>
          <button mat-menu-item (click)="remove(bankAccount)">Remove</button>
        </mat-menu>
      </div>
    </div>
  </div>
  <div *ngIf="!bankAccounts || !bankAccounts.length" class="my-4 text-center w-full">
    <h4>No payment methods found.</h4>
  </div>
  <div [class.text-center]="!bankAccounts || !bankAccounts.length">
    <button class="button button-primary" (click)="openAddPaymentMethodModal()" [hidden]="user.status !== UserStatus.ACTIVE">Add Payment Method</button>
  </div>
</div>
