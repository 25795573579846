import { Component, OnInit } from '@angular/core';
import {BaseComponent, CoreService, StorageKeys, uiCloseModal} from '@vesto/xplat/core';
import {ITransaction, IUser} from '@vesto/vesto';
import {filter, take, takeUntil} from 'rxjs/operators';
import { UntypedFormGroup, Validators } from '@angular/forms';
import {ofType} from "@ngrx/effects";
import {TransactionActions} from "@vesto/ngx-vesto/state";

@Component({
  selector: 'gbl-set-daily-limits-modal',
  templateUrl: 'set-daily-limits-modal.component.html'
})
export class SetDailyLimitsModalComponent extends BaseComponent implements OnInit {
  step : 'FORM_GROUP' | 'TWO_FACTOR' | 'BUSY' | 'SUCCESS' | 'DETAIL' = 'FORM_GROUP';
  user: IUser;
  formGroup: UntypedFormGroup;
  error: string;
  transaction: ITransaction;

  ngOnInit() {
    CoreService.userFacade.user$.pipe(
      takeUntil(this.destroy$),
      filter(user => !!user)
    ).subscribe((user) => {
      this.user = user;
    });

    this.formGroup = CoreService.formBuilder.group(
      {
        email: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator, Validators.email]
          }
        ],
        dailyBuyLimit: [
          this.user.dailyBuyLimit,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        dailySellLimit: [
          this.user.dailySellLimit,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        dailyTransferLimit: [
          this.user.dailyTransferLimit,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ]
      },
      { updateOn: 'blur' }
    );
  }

  sendSmsCode(isResendSms: boolean) {
    CoreService.transactionFacade.sendSmsCode();

    if (isResendSms) {
      CoreService.uiFacade.notification(`SMS code sent!`);
    }
  }

  changeDailyLimits() {
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }

    this.error = null;
    this.step = 'TWO_FACTOR';
  }

  confirmCode(code: string) {
    this.step = 'BUSY';
    const value = this.formGroup.value;

    CoreService.transactionFacade.setDailyLimits(value.email, value.dailyBuyLimit, value.dailySellLimit, value.dailyTransferLimit, code);

    CoreService.actions$.pipe(ofType(TransactionActions.setDailyLimitsSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      CoreService.store.dispatch(TransactionActions.sign({
        transaction: result.transaction,
        code,
        key: new Buffer(CoreService.storageService.getItem(StorageKeys.KEY), 'hex').toString()
      }));
    });

    CoreService.actions$.pipe(ofType(TransactionActions.signSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.step = 'SUCCESS';
      this.transaction = result.transaction;
      CoreService.windowService.setTimeout(() => {
        this.step = 'DETAIL';
      }, 1600);
    });

    CoreService.actions$.pipe(ofType(TransactionActions.setDailyLimitsFailure, TransactionActions.signFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
      this.step = 'FORM_GROUP';
    });
  }

  requestDailyLimitsIncrease() {
    CoreService.userFacade.requestDailyLimitsIncrease();
  }

  close() {
    uiCloseModal();
  }
}
