import { Inject, Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Observable, from } from 'rxjs';
import { mergeMap, catchError, withLatestFrom } from 'rxjs/operators';
import { IEnvironment, ObjectUtil } from '@vesto/vesto';
import { InstitutionsApiService, EnvironmentToken } from '../../services';
import { VestoState } from '../vesto.state';
import { InstitutionActions } from './institution.actions';
import { ApiActions } from '../api';
import { UserFacade } from '../user';
import { InstitutionFacade } from './institution.facade';

@Injectable()
export class InstitutionEffects {
  constructor(private institutionFacade: InstitutionFacade, private userFacade: UserFacade, private actions$: Actions, private institutionsApiService: InstitutionsApiService, @Inject(EnvironmentToken) protected environment: IEnvironment, protected store: Store<VestoState.IState>) {}

  create$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(InstitutionActions.create),
        mergeMap(({ name, adminEmail, iosAppId, androidAppId, url, referrer, merchantEnabled }) => {
          return this.institutionsApiService.create(name, adminEmail, iosAppId, androidAppId, url, referrer, merchantEnabled).pipe(
            mergeMap((result) => {
              return from([InstitutionActions.createSuccess({institution: result})]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([InstitutionActions.createFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  createAndDeployToken$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(InstitutionActions.createAndDeployTokens),
        withLatestFrom(this.userFacade.token$),
        mergeMap(([{institutionId, name, symbol, underlyingSymbol, code}, token]) => {
          return this.institutionsApiService.createAndDeployTokens(institutionId, name, symbol, underlyingSymbol, token, code).pipe(
            mergeMap((result) => {
              return from([InstitutionActions.createAndDeployTokensSuccess({institution: result})])
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([InstitutionActions.createAndDeployTokensFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          )
        })
      )
  );

  changeSettings$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(InstitutionActions.changeSettings),
        mergeMap(({ id, name, address, iosAppId, androidAppId, referrer, url, merchantEnabled, instantSettleEnabled, settleTransactionLimit, settleDailyLimit, feeType, feeFixed, feePercentage }) => {
          return this.institutionsApiService.changeSettings(id, name, address, iosAppId, androidAppId, referrer, url, merchantEnabled, instantSettleEnabled, settleTransactionLimit, settleDailyLimit, feeType, feeFixed, feePercentage).pipe(
            mergeMap((result) => {
              return from([InstitutionActions.changeSettingsSuccess({ institution: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([InstitutionActions.changeSettingsFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  changeTemplate$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(InstitutionActions.changeTemplate),
        mergeMap(({ templateId, referenceId }) => {
          return this.institutionsApiService.changeTemplate(templateId, referenceId).pipe(
            mergeMap((result) => {
              return from([InstitutionActions.changeTemplateSuccess({ institution: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([InstitutionActions.changeTemplateFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  changeAml$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(InstitutionActions.changeAml),
        withLatestFrom(this.institutionFacade.institution$),
        mergeMap(([{ defaultDailyBuyLimit, defaultDailySellLimit, defaultDailyTransferLimit, defaultDailyReceiveLimit, plaidEnabled }, institution]) => {
          return this.institutionsApiService.changeAml(institution.id, defaultDailyBuyLimit, defaultDailySellLimit, defaultDailyTransferLimit, defaultDailyReceiveLimit, plaidEnabled).pipe(
            mergeMap((result) => {
              return from([InstitutionActions.changeAmlSuccess({ institution: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([InstitutionActions.changeAmlFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  changeDeFi$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(InstitutionActions.changeDeFi),
        withLatestFrom(this.institutionFacade.institution$),
        mergeMap(([{ apy }, institution]) => {
          return this.institutionsApiService.changeDeFi(institution.id, apy).pipe(
            mergeMap((result) => {
              return from([InstitutionActions.changeDeFiSuccess({ institution: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([InstitutionActions.changeDeFiFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  find$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(InstitutionActions.find),
        withLatestFrom(this.userFacade.user$, this.institutionFacade.institution$),
        mergeMap(([{}, user, institution ]) => {
          return this.institutionsApiService.find(user.id, institution.id).pipe(
            mergeMap((result) => {
              return from([InstitutionActions.findSuccess({ institution: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([InstitutionActions.findFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  findByUser$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(InstitutionActions.findByUser),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{}, user]) => {
          return this.institutionsApiService.findByUser(user.id).pipe(
            mergeMap((result) => {
              return from([InstitutionActions.findByUserSuccess({ institution: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([InstitutionActions.findByUserFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  findAll$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(InstitutionActions.findAll),
        mergeMap(({}) => {
          return this.institutionsApiService.findAll().pipe(
            mergeMap((result) => {
              return from([InstitutionActions.findAllSuccess({ institutions: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([InstitutionActions.findAllFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );


  sendSmsCode$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(InstitutionActions.sendSmsCode),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{}, user]) => {
          return this.institutionsApiService.sendSmsCode(user.id).pipe(
            mergeMap((result) => {
              return from([InstitutionActions.sendSmsCodeSuccess({ token: result.token  })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([InstitutionActions.sendSmsCodeFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );
}
