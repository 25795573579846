import {Component, OnInit} from "@angular/core";
import {BaseComponent, CoreService, StorageKeys, uiCloseModal} from "@vesto/xplat/core";
import {FormGroup, Validators} from "@angular/forms";
import {filter, take, takeUntil} from "rxjs/operators";
import {ofType} from "@ngrx/effects";
import {TransactionActions} from "@vesto/ngx-vesto/state";
import {Blockchain, ITransaction} from "@vesto/vesto";

@Component({
  selector: 'gbl-set-supply-limit-modal',
  templateUrl: 'set-supply-limit-modal.component.html'
})

export class SetSupplyLimitModalComponent extends BaseComponent implements OnInit {
  step: 'FORM_GROUP' | 'TWO_FACTOR' | 'BUSY' | 'SUCCESS' | 'TRANSACTION_DETAIL' = 'FORM_GROUP';
  formGroup: FormGroup;
  error: string;
  token = [];
  transaction: ITransaction;

  ngOnInit() {
    this.formGroup = CoreService.formBuilder.group({
      symbol: [
        null,
        {
          validators: [Validators.required]
        }
      ],
      amount: [
        null,
        {
          validators: [Validators.required]
        }
      ]
    });

    CoreService.institutionFacade.institution$
      .pipe(
        filter(institution => !!institution),
        takeUntil(this.destroy$)
      )
      .subscribe(institution => {
        institution.tokens.forEach(token => {
          if (token.blockchain === Blockchain.POLYGON) {
            this.token.push(token.symbol);
          }
        });
      });
  }

  update() {
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }
    this.step = 'TWO_FACTOR';
  }

  sendSmsCode(isResendSms: boolean) {
    CoreService.transactionFacade.sendSmsCode();

    if (isResendSms) {
      CoreService.uiFacade.notification(`SMS code sent!`);
    }
  }

  confirmCode(code: string) {
    this.step = 'BUSY';
    const value = this.formGroup.value;
    CoreService.actions$.pipe(ofType(TransactionActions.setUnderlyingSupplyLimitSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.transaction = result.transaction;
      CoreService.store.dispatch(TransactionActions.sign({ transaction: this.transaction, code, key: new Buffer(CoreService.storageService.getItem(StorageKeys.KEY), 'hex').toString() }));
    });
    CoreService.actions$.pipe(ofType(TransactionActions.signSuccess), take(1), takeUntil(this.destroy$)).subscribe(() => {
      this.step = 'SUCCESS';
      CoreService.windowService.setTimeout(() => {
        this.step = 'TRANSACTION_DETAIL';
      }, 1600);
    });
    CoreService.actions$.pipe(ofType(TransactionActions.setUnderlyingSupplyLimitFailure, TransactionActions.signFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
      this.step = 'FORM_GROUP';
    });

    CoreService.transactionFacade.setUnderlyingSupplyLimit(value.symbol, value.amount, code);
  }

  close() {
    uiCloseModal();
  }
}
