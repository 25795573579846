import { UserActions } from './user';
import { VestoState } from './vesto.state';
import { TransactionActions } from './transaction';
import {AccountActions} from './account';
import { Action, createReducer, on } from '@ngrx/store';
import { InstitutionActions } from './institution';
import { BankAccountActions } from './bank-account';
import { MarketDataActions } from './market-data';
import { SnapShotActions } from './snap-shot';
import { ApiActions } from './api';
import { SignUpActions } from './sign-up';
import { SystemActions } from './system/system.actions';
import {MerchantActions} from './merchant';
import {GasTankActions} from './gas-tanks';
import {StoreActions} from './store';

const reducer = createReducer(
  VestoState.initialState,
  /**
   * ApiActions
   */
  on(UserActions.reset, (state, { resetState }) => {
    return {
      ...resetState,
    };
  }),
  on(ApiActions.setError, (state, { apiError }) => {
    return {
      ...state,
      apiError: { ...apiError }
    };
  }),
  on(ApiActions.clearError, (state) => {
    return {
      ...state,
      apiError: null
    };
  }),
  /**
   * SystemActions
   */
  on(SystemActions.set, SystemActions.findSuccess, SystemActions.initializeContractsSuccess, SystemActions.deployContractSuccess, (state, { system }) => {
    return {
      ...state,
      system: { ...system }
    };
  }),
  on(SystemActions.sendSmsCodeSuccess, (state, { token }) => {
    return {
      ...state,
      token: token
    };
  }),
  on(SystemActions.getFiatBalancesSuccess, (state, { fiatBalances }) => {
    return {
      ...state,
      fiatBalances: {...fiatBalances}
    };
  }),
  /**
   * GasTankActions
   */
  on(GasTankActions.findActiveSuccess, (state, { gasTanks }) => {
    return {
      ...state,
      gasTanks: [...gasTanks ]
    };
  }),
  on(GasTankActions.set, GasTankActions.changeSettingsSuccess, (state, { gasTank }) => {
    const gasTanks = state.gasTanks.map(_gasTank => (_gasTank.id === gasTank.id ? {...gasTank} : {..._gasTank}));
    return {
      ...state,
      gasTanks: [...gasTanks ]
    };
  }),
  /**
   * Institution
   */
  on(InstitutionActions.set, InstitutionActions.changeDeFiSuccess, InstitutionActions.changeAmlSuccess, InstitutionActions.changeSettingsSuccess, InstitutionActions.findByUserSuccess, (state, { institution }) => {
    return {
      ...state,
      institution: { ...institution }
    };
  }),
  on(InstitutionActions.setToken, (state, { token }) => {
    if (!!state.institution) {
      const institution = state.institution;
      institution.tokens = institution.tokens.map(_token => (_token.id === token.id ? {...token} : {..._token}));
      let shouldAdd = !institution.tokens.find(_token => _token.id === token.id) && token.institutionId === institution.id;
      institution.tokens = shouldAdd ? [token, ...institution.tokens] : [...institution.tokens];

      const institutions = state.institutions.map(_institution => {
        _institution.tokens = _institution.tokens.map(_token => (_token.id === token.id ? {...token} : {..._token}));
        shouldAdd = !_institution.tokens.find(_token => _token.id === token.id) && token.institutionId === _institution.id;
        _institution.tokens = shouldAdd ? [token, ..._institution.tokens] : [..._institution.tokens];
        return _institution;
      });

      return {
        ...state,
        institution: {...institution},
        institutions: [...institutions]
      };
    } else {
      return {
        ...state
      };
    }
  }),
  on(InstitutionActions.findAllSuccess, (state, { institutions }) => {
    return {
      ...state,
      institutions: [...institutions]
    };
  }),
  on(InstitutionActions.createAndDeployTokensSuccess, InstitutionActions.changeTemplateSuccess, (state, { institution }) => {
    const institutions = state.institutions.map(_institution => (_institution.id === institution.id ? { ...institution } : { ..._institution }));
    return {
      ...state,
      institution: state.institution.id === institution.id ? { ...institution } : { ...state.institution },
      institutions: [...institutions]
    };
  }),
  on(InstitutionActions.createSuccess, (state, { institution }) => {
    const institutions = [institution, ...state.institutions];
    institutions.sort((a, b) => {
      const aName = a.name.toLowerCase();
      const bName = b.name.toLowerCase();
      if (aName.toLowerCase() < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }
      return 0;
    });

    return {
      ...state,
      institutions: [...institutions]
    };
  }),
  on(InstitutionActions.sendSmsCodeSuccess, (state, { token }) => {
    return {
      ...state,
      token: token
    };
  }),
  /**
   * Merchants
   */
  on(MerchantActions.findByInstitutionSuccess, (state, { merchants }) => {
    return {
      ...state,
      merchants: [...merchants]
    };
  }),
  on(MerchantActions.createSuccess, (state, { merchant }) => {
    const merchants = [merchant, ...state.merchants];
    merchants.sort((a, b) => {
      const aName = a.name.toLowerCase();
      const bName = b.name.toLowerCase();
      if (aName.toLowerCase() < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }
      return 0;
    });

    return {
      ...state,
      merchants: [...merchants]
    };
  }),
  on(MerchantActions.changeSettingsSuccess, (state, { merchant }) => {
    const merchants = state.merchants.map(_merchant => (_merchant.id === merchant.id ? { ...merchant } : { ..._merchant }));
    return {
      ...state,
      merchants: [...merchants]
    };
  }),
  /**
   * Stores
   */
  on(StoreActions.findSuccess, StoreActions.changeSettingsSuccess, (state, { store }) => {
    const stores = state.stores.map(_store => (_store.id === store.id ? { ...store } : { ..._store }));
    return {
      ...state,
      stores: [...stores]
    };
  }),
  on(StoreActions.findByMerchantSuccess, (state, { stores }) => {
    return {
      ...state,
      stores: [...stores]
    };
  }),
  on(StoreActions.createSuccess, (state, { store }) => {
    const stores = [store, ...state.stores];
    stores.sort((a, b) => {
      const aName = a.name.toLowerCase();
      const bName = b.name.toLowerCase();
      if (aName.toLowerCase() < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }
      return 0;
    });

    return {
      ...state,
      stores: [...stores]
    };
  }),
  on(StoreActions.removeSuccess, (state, { store }) => {
    const stores = state.stores.filter(_store => _store.id !== store.id);
    return {
      ...state,
      stores: [...stores]
    };
  }),
  /**
   * SignUp
   */
  on(SignUpActions.findUserByTokenSuccess, (state, { token, user }) => {
    return {
      ...state,
      token: token,
      // privateKey: null,
      user: { ...user }
    };
  }),
  on(SignUpActions.signUpSuccess, (state, { token, user, password }) => {
    return {
      ...state,
      token: token,
      user: { ...user },
      password: password
    };
  }),
  on(SignUpActions.sendSmsCodeSuccess, SignUpActions.verifySmsCodeSuccess, SignUpActions.sendEmailSuccess, (state, { token, user }) => {
    return {
      ...state,
      token: token,
      user: { ...user }
    };
  }),
  on(SignUpActions.verifyEmailSuccess, (state, { user }) => {
    return {
      ...state,
      user: { ...user },
      password: null,
      token: null
    };
  }),
  on(SignUpActions.cancel, (state) => {
    return VestoState.initialState;
  }),
  /**
   * UserActions
   */
  on(UserActions.set, UserActions.findSuccess, UserActions.verifyIdentitySuccess, UserActions.changePersonalInfoSuccess, UserActions.verifyChangeEmailSuccess, UserActions.verifyChangeMobileSuccess, UserActions.kycClearSuccess, UserActions.kycConsiderSuccess, UserActions.suspendSuccess, UserActions.activateSuccess, (state, { user }) => {
    return {
      ...state,
      user: user ? { ...user } : null
    };
  }),
  on(UserActions.authenticate, (state) => {
    return {
      ...state,
      authenticated: true
    };
  }),
  on(UserActions.initialize, (state, { }) => {
    return {
      ...state,
      initialized: true,
      authenticated: !!state.user?.id
    };
  }),
  on(UserActions.findByMerchantSuccess, (state, { users }) => {
    return {
      ...state,
      users: [...users]
    };
  }),
  on(UserActions.signInSuccess, (state, { token, user, password }) => {
    return {
      ...state,
      token: token,
      user: { ...user },
      password: password
    };
  }),
  on(UserActions.verifyTwoFactorCodeSuccess, (state, { user }) => {
    return {
      ...state,
      user: { ...user },
      password: null,
    };
  }),
  on(UserActions.signOut, (state) => {
    return {
      ...state,
      ...VestoState.initialState
    };
  }),
  on(UserActions.changeMobileSuccess, (state, { token }) => {
    return {
      ...state,
      token: token
    };
  }),
  on(UserActions.typeaheadSuccess, (state, { users }) => {
    return {
      ...state,
      users: [...users]
    };
  }),
  on(UserActions.selectUser, (state, { user }) => {
    return {
      ...state,
      adminUser: user ? { ...state.user } : null,
      user: user ? { ...user } : { ...state.adminUser }
    };
  }),
  on(UserActions.clearUsers, (state, {}) => {
    return {
      ...state,
      users: []
    };
  }),
  /**
   * AccountActions
   */
  on(AccountActions.createSuccess, (state, { account }) => {
    const accounts = [account, ...state.accounts];
    accounts.sort((a, b) => {
      const aName = a.name.toLowerCase();
      const bName = b.name.toLowerCase();
      if (aName.toLowerCase() < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }
      return 0;
    });

    return {
      ...state,
      accounts: [...accounts]
    };
  }),
  on(AccountActions.findSuccess, AccountActions.suspendUserSuccess, AccountActions.activateUserSuccess, AccountActions.changeSettingsSuccess, (state, { account }) => {
    const accounts = state.accounts.map(_account => _account.id === account.id ? { ...account } : { ..._account });
    return {
      ...state,
      accounts: [...accounts]
    };
  }),
  on(AccountActions.set, (state, { account }) => {
    const accounts = state.accounts.map(_account => _account.id === account.id ? { ...account } : { ..._account });
    return {
      ...state,
      accounts: [...accounts]
    };
  }),
  on(AccountActions.setContract, (state, {contract}) => {
    const accounts = state.accounts.map(_account => {
      _account.contracts = _account.contracts.map(_contract => _contract.id === contract.id ? {...contract} : {..._contract});
      const shouldAdd = !_account.contracts.find(_contract => _contract.id === contract.id) && contract.accountId === _account.id;
      _account.contracts = shouldAdd ? [contract, ..._account.contracts] : [..._account.contracts];
      return _account;
    });

    return {
      ...state,
      accounts: [...accounts]
    };
  }),
  on(AccountActions.findByUserSuccess, (state, { accounts }) => {
    return {
      ...state,
      accounts: [...accounts]
    };
  }),
  /**
   * BankAccountActions
   */
  on(BankAccountActions.findByUserSuccess, (state, { bankAccounts }) => {
    return {
      ...state,
      bankAccounts: [...bankAccounts]
    };
  }),
  on(BankAccountActions.createSuccess, (state, { bankAccount }) => {
    return {
      ...state,
      bankAccounts: [bankAccount, ...state.bankAccounts]
    };
  }),
  on(BankAccountActions.setDefaultSuccess, (state, { bankAccount }) => {
    const bankAccounts = state.bankAccounts.map(_bankAccount => {
      _bankAccount.default = _bankAccount.id === bankAccount.id ? true : false;
      return _bankAccount;
    });
    return {
      ...state,
      bankAccounts: [...bankAccounts]
    };
  }),
  on(BankAccountActions.removeSuccess, (state, { bankAccount }) => {
    const bankAccounts = state.bankAccounts.filter(_bankAccount => _bankAccount.id !== bankAccount.id);
    return {
      ...state,
      bankAccounts: [...bankAccounts]
    };
  }),
  /**
   * TransactionActions
   */
  on(TransactionActions.set, TransactionActions.findSuccess, TransactionActions.changeStatusSuccess, (state, {transaction}) => {
    const transactions = state.transactions.map(_transaction => _transaction.id === transaction.id ? {...transaction} : {..._transaction});
    return {
      ...state,
      transactions: [...transactions]
    };
  }),
  on(TransactionActions.add, (state, {transaction}) => {
    return {
      ...state,
      transactions: [transaction, ...state.transactions]
    };
  }),
  on(TransactionActions.findBuyOrSellActionRequiredSuccess, TransactionActions.findByAccountSuccess, TransactionActions.findByAccountAndSymbolSuccess, TransactionActions.findByTypeAndStatusSuccess, (state, { transactions }) => {
    return {
      ...state,
      transactions: [...transactions]
    };
  }),
  on(TransactionActions.setSettleLimitsSuccess, (state, { institution, transaction }) => {
    return {
      ...state,
      institution: {...institution}
    };
  }),
  on(TransactionActions.addUserSuccess, TransactionActions.removeUserSuccess, (state, { account, transaction }) => {
    const accounts = state.accounts.map((_account) => (_account.id === account.id ? { ...account } : { ..._account }));
    return {
      ...state,
      accounts: [...accounts]
    };
  }),
  on(TransactionActions.sendSmsCodeSuccess, (state, { token }) => {
    return {
      ...state,
      token: token
    };
  }),
  on(TransactionActions.signSuccess, (state, { transaction }) => {
    const transactions = state.transactions.map((_transaction) => (_transaction.id === transaction.id ? { ...transaction } : { ..._transaction }));
    return {
      ...state,
      transactions: [...transactions]
    };
  }),
  /**
   * SnapShot
   */
  on(SnapShotActions.findByAccountSuccess, (state, { snapShots }) => {
    return {
      ...state,
      accountSnapShots: [...snapShots]
    };
  }),
  on(SnapShotActions.findByUserSuccess, (state, { snapShots }) => {
    return {
      ...state,
      userSnapShots: [...snapShots]
    };
  }),
  on(SnapShotActions.findByInstitutionSuccess, (state, { snapShots }) => {
    return {
      ...state,
      institutionSnapShots: [...snapShots]
    };
  }),
  on(SnapShotActions.findBySystemSuccess, (state, { snapShots }) => {
    return {
      ...state,
      systemSnapShots: [...snapShots]
    };
  }),
  /**
     /**
     * MarketData
     */
  on(MarketDataActions.getDeFiSuccess, (state, { data }) => {
    return {
      ...state,
      deFi: { ...data }
    };
  }),
  on(MarketDataActions.getDeFiHistorySuccess, (state, { histories }) => {
    return {
      ...state,
      deFiHistories: [...histories]
    };
  })
);
export function vestoReducer(state: VestoState.IState | undefined, action: Action) {
  return reducer(state, action);
}
