<div class="signup-page">
  <div class="p-3">
    <div class="logo" routerLink="/">
      <img src="/assets/images/logo.svg" class="w-48" />
    </div>
  </div>

  <div class="timeline-container flex" *ngIf="user">
    <div class="timeline-tick timeline-tick-completed flex-grow"></div>
    <div class="timeline-tick flex-grow ml-2" [class.timeline-tick-completed]="user && user.status === UserStatus.EMAIL_NOT_VERIFIED"></div>
    <div class="timeline-tick flex-grow ml-2"></div>
  </div>

  <ng-container [ngSwitch]="step">
    <section class="bg-black text-white big-fluffy-shadow rounded-lg mb-4 p-4 mt-3 mx-auto">
      <gbl-create-user *ngSwitchCase="'CREATE_USER'"></gbl-create-user>
      <gbl-verify-mobile *ngSwitchCase="'VERIFY_MOBILE'"></gbl-verify-mobile>
      <gbl-verify-email *ngSwitchCase="'VERIFY_EMAIL'"></gbl-verify-email>

      <div *ngSwitchCase="'LOADING'" class="pb-10 text-center">
        <h3 class="mt-2">Finalizing your registration</h3>
        <p class="mt-2 mb-10">please wait...</p>
        <gbl-lottie-player [type]="'block'"></gbl-lottie-player>
        <gbl-alert [type]="error" [message]="error"></gbl-alert>
      </div>
    </section>
  </ng-container>

  <div *ngIf="user" class="text-center">
    <small class="text-lilac uppercase text-xs font-bold" (click)="cancel()">Cancel Sign Up</small>
  </div>
</div>
