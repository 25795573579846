import { Component, OnInit } from '@angular/core';
import { BaseComponent, CoreService } from '@vesto/xplat/core';
import {filter, takeUntil} from 'rxjs/operators';
import { UserActions, UserFacade } from '@vesto/ngx-vesto/state';
import {ISystem, IUser, RoleType, SystemStatus, UserStatus, UserUtil} from '@vesto/vesto';
import {DashboardFacade, FeatureService, Section} from '@vesto/xplat/features';

@Component({
  selector: 'gbl-dashboard-header',
  templateUrl: 'dashboard-header.component.html'
})
export class DashboardHeaderComponent extends BaseComponent implements OnInit {
  user: IUser;
  UserStatus = UserStatus;
  adminUser: IUser;
  system: ISystem;
  SystemStatus = SystemStatus;
  UserUtil = UserUtil;
  RoleType = RoleType;

  ngOnInit() {
    CoreService.userFacade.user$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.user = result;
    });
    CoreService.userFacade.adminUser$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.adminUser = result;
    });
    CoreService.systemFacade.system$
      .pipe(
        filter((system) => !!system),
        takeUntil(this.destroy$)
      )
      .subscribe((system) => {
        this.system = system;
      });
  }

  openOrGoToBuySell() {
    FeatureService.dashboardFacade.openOrGoToBuySell();
  }

  openOrGoToSendReceive() {
    FeatureService.dashboardFacade.openOrGoToSendReceive();
  }

  selectProfile() {
    FeatureService.dashboardFacade.selectSection(Section.SETTINGS_PROFILE);
  }

  signOut() {
    CoreService.store.dispatch(UserActions.signOut());
  }

  deployContracts() {
    FeatureService.dashboardFacade.openOrGoToTwoFactor('deploy contracts');
  }

  initialize() {
    CoreService.systemFacade.initializeContracts();
  }
}
