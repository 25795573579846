import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { UserFacade } from '@vesto/ngx-vesto/state';
import { Router } from '@angular/router';
import { SignInActions } from '@vesto/xplat/features';
import { SignInModalComponent } from '../components/sign-in-modal/sign-in-modal.component';
import { SetPasswordModalComponent } from '../components/set-password-modal/set-password-modal.component';
import { Store } from '@ngrx/store';
import { UiActions, uiCloseModalAction } from '@vesto/xplat/core';
import { uxSettings } from '../../utils';

const modalOptions = uxSettings.modalOptions;

@Injectable()
export class WebSignInEffects {
  constructor(private store: Store, private actions$: Actions, private router: Router, private userFacade: UserFacade) {}

  openSignInModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignInActions.openOrGoToSignIn),
      uxSettings.modalRxFlow(),
      map(() =>
        UiActions.openModal({
          cmpType: SignInModalComponent,
          modalOptions: {
            ...modalOptions,
            minHeight: '465px'
          }
        })
      )
    )
  );

  openSetPasswordModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignInActions.openOrGoToSetPassword),
      uxSettings.modalRxFlow(),
      map(({ token }) =>
        UiActions.openModal({
          cmpType: SetPasswordModalComponent,
          modalOptions,
          props: {
            token
          }
        })
      )
    )
  );

  closeModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignInActions.closeModal),
      filter(() => this.isValidRoute()),
      map(() => uiCloseModalAction())
    )
  );

  private isValidRoute(): boolean {
    return !this.router.url.startsWith('/dashboard') && !this.router.url.startsWith('/sign-up');
  }
}
