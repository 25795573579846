import { Directive, OnInit } from '@angular/core';
import { BaseComponent, CoreService, UiActions, uiCloseModal } from '@vesto/xplat/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { FormGroupUtil } from '@vesto/xplat/utils';
import { take, takeUntil, filter } from 'rxjs';
import { ofType } from '@ngrx/effects';
import { UserActions, VestoSelectors } from '@vesto/ngx-vesto/state';
import { SignInActions } from '../state/sign-in.actions';
import { uxSetupUserTwoFactor } from '../utils';
import { select } from '@ngrx/store';

@Directive()
export abstract class SignInBaseComponent extends BaseComponent implements OnInit {
  step: 'FORM_GROUP' | 'BUSY' | 'TWO_FACTOR' | 'SUCCESS' | 'FORGOT_PASSWORD' = 'FORM_GROUP';
  public formGroup: UntypedFormGroup;
  public forgotPasswordFormGroup: UntypedFormGroup;
  public error: string;

  ngOnInit() {
    this.formGroup = CoreService.formBuilder.group(
      {
        email: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator, Validators.email]
          }
        ],
        password: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ]
      },
      { updateOn: 'change' } // TODO: do we want to do it this way?
    );

    this.forgotPasswordFormGroup = CoreService.formBuilder.group({
      forgotPasswordEmail: [
        null,
        {
          validators: [Validators.required, Validators.nullValidator, Validators.email]
        }
      ]
    });
    uxSetupUserTwoFactor(this.destroy$)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result.success) {
          this.step = 'SUCCESS';
          CoreService.windowService.setTimeout(() => {
            CoreService.store
              .pipe(select(VestoSelectors.authenticated))
              .pipe(
                filter((authenticated) => !!authenticated),
                take(1)
              )
              .subscribe(() => {
                CoreService.store.dispatch(SignInActions.closeModal());
                CoreService.store.dispatch(
                  UiActions.go({
                    path: ['/dashboard'],
                    extras: {
                      animated: false,
                      clearHistory: true,
                      replaceUrl: true
                    }
                  })
                );
              });
          }, 2200);
        } else if (result.error) {
          this.error = `apiErrors.${result.error}`;
          if (result.error === 'InvalidOrExpiredToken') {
            this.step = 'FORM_GROUP';
          } else {
            this.step = 'TWO_FACTOR';
          }
        }
      });
  }

  sendSmsCode(isResendSms: boolean) {
    CoreService.userFacade.sendSmsCode();

    if (isResendSms) {
      CoreService.uiFacade.notification(`SMS code sent!`);
    }
  }

  signIn() {
    this.error = null;
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
        CoreService.progress.toggleSpinner(false);
      });
      return;
    }

    this.step = 'BUSY';
    CoreService.actions$.pipe(ofType(UserActions.signInSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.step = 'TWO_FACTOR';
    });

    CoreService.actions$.pipe(ofType(UserActions.signInFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
      this.step = 'FORM_GROUP';
    });

    const value = FormGroupUtil.trim(this.formGroup.value);
    CoreService.windowService.setTimeout(() => {
      CoreService.store.dispatch(UserActions.signIn({ email: value.email, password: value.password }));
    }, 500);
  }

  confirmCode(code: string) {
    this.step = 'BUSY';
    CoreService.windowService.setTimeout(() => {
      CoreService.userFacade.verifyTwoFactorCode(code);
    });
  }

  goToForgotPassword() {
    this.error = null;
    this.forgotPasswordFormGroup.controls.forgotPasswordEmail.setValue(this.formGroup.value.email);
    this.step = 'FORGOT_PASSWORD';
  }

  goToSignUp() {
    CoreService.ngRouter.navigate(['/sign-up']);
    this.close();
  }

  forgotPassword() {
    const { ok, error$ } = CoreService.formService.validate(this.forgotPasswordFormGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }

    const value = FormGroupUtil.trim(this.forgotPasswordFormGroup.value);
    CoreService.userFacade.forgotPassword(value.forgotPasswordEmail);
    if (CoreService.platformDeviceInfo.deviceDetails === 'browser') {
      CoreService.uiFacade.closeModal();
    } else {
      this.goToSignIn();
    }
    CoreService.uiFacade.notification(`Password reset instructions sent to ${value.forgotPasswordEmail}`);
  }

  goToSignIn() {
    this.step = 'FORM_GROUP';
  }

  close() {
    uiCloseModal();
  }
}
