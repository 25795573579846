import { Directive, OnInit } from '@angular/core';
import { BaseComponent, CoreService } from '@vesto/xplat/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FormGroupUtil, strongPasswordPattern } from '@vesto/xplat/utils';
import { IUser } from '@vesto/vesto';
import { takeUntil, filter, take } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';
import { SignUpActions } from '@vesto/ngx-vesto/state';
import { FeatureService } from '../../services/feature.service';

@Directive()
export abstract class CreateUserBaseComponent extends BaseComponent implements OnInit {
  user: IUser;
  formGroup: UntypedFormGroup;
  launchOptions: any;
  error: string;

  ngOnInit() {
    this.formGroup = CoreService.formBuilder.group(
      {
        firstName: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        lastName: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        email: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator, Validators.email]
          }
        ],
        password: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            (control: UntypedFormControl) => {
              return !control.root.get('password') || (control.root.get('password') && strongPasswordPattern.test(control.root.get('password').value)) ? null : { invalid: true };
            }
          ])
        ],
        merchantId: [
          null,
          {
            validators: [Validators.nullValidator]
          }
        ]
      },
      { updateOn: 'change' } // TODO: Do we want to do it this way?
    );

    CoreService.userFacade.user$
      .pipe(
        filter((user) => !!user),
        takeUntil(this.destroy$)
      )
      .subscribe((user) => {
        this.user = user;
        this.formGroup.patchValue(
          {
            email: user.email
          },
          {
            emitEvent: true
          }
        );
      });
  }

  validateAndSignUp() {
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }
    CoreService.progress.toggleSpinner(true);
    const user = {
      ...FormGroupUtil.trim(this.formGroup.value)
    };
    FeatureService.signUpFacade.signUp(user, user.password);

    CoreService.actions$.pipe(ofType(SignUpActions.signUpFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
      CoreService.progress.toggleSpinner(false); // TODO: replace with busy step
    });
  }

  signIn() {
    CoreService.uiFacade.go(['/']);
    FeatureService.signInFacade.openOrGoToSignIn();
  }
}
