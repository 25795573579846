<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <h3 class="section-number"><i class="fal fa-cube"></i> {{ action | titlecase }}</h3>
</div>
<mat-dialog-content class="synchronize-modal">
  <ng-container [ngSwitch]="step">
    <section *ngSwitchCase="'CHOOSE_BLOCKCHAIN'">
      <p class="text-sm">Choose a blockchain to {{ action }}:</p>
      <div class="p-3 br-10 mt-3 bg-ethereum" (click)="chooseBlockchain(Blockchain.ETHEREUM)"><img src="./assets/icons/ethereum.svg" class="w-3 mr-1 -mt-1" /> Ethereum</div>

      <div class="p-3 br-10 mt-3 bg-polygon" (click)="chooseBlockchain(Blockchain.POLYGON)"><img src="./assets/icons/polygon-icon.png" class="w-4 mr-1 -mt-1" /> Polygon</div>
      <gbl-alert [type]="error" [message]="error"></gbl-alert>
    </section>

    <section *ngSwitchCase="'TWO_FACTOR'">
      <gbl-two-factor (sendSmsCode)="sendSmsCode($event)" (code)="confirmCode($event)"></gbl-two-factor>
      <gbl-alert [type]="error" [message]="error"></gbl-alert>
    </section>

    <section *ngSwitchCase="'BUSY'" class="mt-4">
      <div class="placeholder-long bg-flash"></div>
      <div class="placeholder-area bg-flash mt-4"></div>
    </section>

    <section *ngSwitchCase="'SUCCESS'">
      <div style="width: 350px; height: 350px; overflow: hidden; margin: -24px auto 0">
        <gbl-lottie-player></gbl-lottie-player>
      </div>
    </section>

    <section *ngSwitchCase="'TRANSACTION_DETAIL'" class="slide-in">
      <div class="text-center">
        <p class="mt-4">Success!</p>
        <p class="text-sm mb-4">You transaction has been submitted to the blockchain</p>
      </div>
      <div class="p-4 bg-trans-blue rounded-lg">
        <gbl-transaction [transaction]="transaction"></gbl-transaction>
      </div>
      <button class="button button-primary my-4 mx-auto w-full" (click)="close()">Close</button>
    </section>
  </ng-container>
</mat-dialog-content>
