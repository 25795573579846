import {IEnvironment} from '../interfaces/environment.interface';
import {IHttpClient} from '../interfaces/http.interface';
import {BaseApi} from './base-api';

export class StoresApi extends BaseApi {
  constructor(protected environment: IEnvironment, protected httpClient: IHttpClient) {
    super(environment);
  }

  find(
    id: string
  ) {
    return this.httpClient.get(
      this.formatUrl(`/v${this.environment.vesto.version}/stores/find/${id}`),
      {
        headers: this.getHeaders()
      });
  }

  findByMerchant(
    merchantId: string
  ) {
    return this.httpClient.get(
      this.formatUrl(`/v${this.environment.vesto.version}/stores/find-by-merchant/${merchantId}`),
      {
        headers: this.getHeaders()
      });
  }

  create(
    merchantId: string,
    name: string,
    address: string
  ) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/stores/create/${merchantId}`),
      {
        name,
        address
      },
      {
        headers: this.getHeaders()
      });
  }

  changeSettings(
    id: string,
    name: string,
    address: string
  ) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/stores/change-settings/${id}`),
      {
        name,
        address
      },
      {
        headers: this.getHeaders()
      });
  }

  remove(
    id: string
  ) {
    return this.httpClient.delete(
      this.formatUrl(`/v${this.environment.vesto.version}/stores/remove/${id}`),
      {
        headers: this.getHeaders()
      });
  }
}
