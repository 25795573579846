<div class="accounts">
  <h4>Current Interest Rates</h4>
  <div class="flex mt-3">
    <div *ngFor="let asset of assets" class="flex mr-6 text-xs currency-item">
      <div class="mr-3 text-center"><img [src]="asset.symbol | symbolIcon" class="block w-8" />{{ asset.symbol }}</div>
      <div>
        <b class="text-xl">{{ asset.apy | percent }}</b>
      </div>
    </div>
  </div>
</div>
