import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ObjectUtil} from '@vesto/vesto';
import {Action, Store} from '@ngrx/store';
import {from, Observable} from 'rxjs';
import {catchError, mergeMap, withLatestFrom} from 'rxjs/operators';
import {ApiActions} from '../api';
import {UserFacade} from '../../state/user';
import {VestoState} from '../vesto.state';
import {CoreActions} from '@vesto/xplat/core';
import {StoreApiService} from '../../services/store-api.service';
import {StoreActions} from '../store/store.actions';

@Injectable()
export class StoreEffects {
  constructor(private actions$: Actions, protected store: Store<VestoState.IState>, private storeApiService: StoreApiService) {
  }

  find$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(StoreActions.find),
        mergeMap(({id}, user) => {
          return this.storeApiService.find(id).pipe(
            mergeMap((result) => {
              return from([StoreActions.findSuccess({store: result})]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([StoreActions.findFailure({apiError}), ApiActions.setError({apiError})]);
            })
          );
        })
      )
  );

  findByMerchant$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(StoreActions.findByMerchant),
        mergeMap(({merchantId}, user) => {
          return this.storeApiService.findByMerchant(merchantId).pipe(
            mergeMap((result) => {
              return from([StoreActions.findByMerchantSuccess({stores: result})]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([StoreActions.findByMerchantFailure({apiError}), ApiActions.setError({apiError})]);
            })
          );
        })
      )
  );

  create$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(StoreActions.create),
        mergeMap(({merchantId, name, address}, user) => {
          return this.storeApiService.create(merchantId, name, address).pipe(
            mergeMap((result) => {
              return from([StoreActions.createSuccess({store: result})]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([StoreActions.createFailure({apiError}), ApiActions.setError({apiError})]);
            })
          );
        })
      )
  );

  changeSettings$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(StoreActions.changeSettings),
        mergeMap(({id, name, address}, user) => {
          return this.storeApiService.changeSettings(id, name, address).pipe(
            mergeMap((result) => {
              return from([StoreActions.changeSettingsSuccess({store: result})]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([StoreActions.changeSettingsFailure({apiError}), ApiActions.setError({apiError})]);
            })
          );
        })
      )
  );

  remove$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(StoreActions.remove),
        mergeMap(({id}, user) => {
          return this.storeApiService.remove(id).pipe(
            mergeMap((result) => {
              return from([StoreActions.removeSuccess({store: result})]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([StoreActions.removeFailure({apiError}), ApiActions.setError({apiError})]);
            })
          );
        })
      )
  );
}
