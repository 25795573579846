import { IEnvironment } from '@vesto/vesto';

/**
 * Reduce the most commonly used environment values here
 */
export const environmentBase: IEnvironment = {
  production: false,
  enableAnalytics: false,
  name: 'develop',
  uriScheme: 'vesto',
  appId: 'io.vesto.appdev',
  vesto: {
    url: 'https://api-develop.vesto.io',
    wsUrl: 'wss://api-develop.vesto.io/ws',
    version: 1,
    institutionId: 'd9472c8c-f24e-48df-965c-fcb2113cb77a',
    email: 'info@vesto.io'
  },
  cloudFront: {
    url: 'https://d1np4nlhjg69pk.cloudfront.net'
  },
  plaid: {
    publicKey: 'e9278653f6e9ce853085557fe84126',
    env: 'sandbox'
  },
  google: {
    apiKey: 'AIzaSyDmAgMstUFjIUQcPuby2BK8NJIaXCANxb8'
  },
  nx: {
    cloudReadWrite: 'ZTc5ZjFkOTYtODI3Ni00OGU2LWEwYzItMzJlNDM1MjhiNzE4fHJlYWQtd3JpdGU=',
    cloudRead: 'ZDM0NTg2ODYtZjk1Yy00NzM3LThjYWQtMmQyMzA1Y2YxMzkzfHJlYWQ='
  },
  onfido: {
    api: 'https://api.onfido.com/v2',
    apiKey: 'api_sandbox.TlnUCxxjM3B.USz2M3G4BaGEWVetbryGRwulvK2JmqGd'
  },
  intercom: {
    appID: 'awwu3oew'
  },
  ethereum: {
    webUrl: 'https://goerli.etherscan.io'
  },
  enableSignIn: true
};
