import { BuySellModalComponent } from './buy-sell/buy-sell-modal.component';
import { SendReceiveModalComponent } from './send-receive/send-receive-modal.component';
import { AddPaymentMethodModalComponent } from './add-payment-method/add-payment-method-modal.component';
import { CreateAccountModalComponent } from './create-account/create-account-modal.component';
import { TransactionDetailModalComponent } from './transaction-detail/transaction-detail-modal.component';
import { VerifyIdentityModalComponent } from './verify-identity/verify-identity-modal.component';
import { AddUserModalComponent } from './add-user/add-user-modal.component';
import { ChangePasswordModalComponent } from './change-password/change-password-modal.component';
import { ChangePersonalInfoModalComponent } from './change-personal-info/change-personal-info-modal.component';
import { ChangeEmailModalComponent } from './change-email/change-email-modal.component';
import { ChangeMobileModalComponent } from './change-mobile/change-mobile-modal.component';
import { UserSearchModalComponent } from './user-search/user-search-modal.component';
import { KycClearConsiderModalComponent } from './kyc-clear-consider/kyc-clear-consider-modal.component';
import { SuspendActivateModalComponent } from './suspend-activate/suspend-activate-modal.component';
import { ChangeAmlModalComponent } from './change-aml/change-aml-modal.component';
import { InstitutionModalComponent } from './institution/institution-modal.component';
import { InviteUserModalComponent } from './invite-user/invite-user-modal.component';
import { ElementModalComponent } from './element/element-modal.component';
import { ChangeGasTankModalComponent } from './change-gas-tank/change-gas-tank-modal.component';
import { ChangeCurrencyModalComponent } from './change-currency/change-currency-modal.component';
import { ChooseBlockchainModalComponent } from './choose-blockchain/choose-blockchain-modal.component';
import { TwoFactorModalComponent } from './two-factor/two-factor-modal.component';
import { ChooseTokenModalComponent } from './choose-token/choose-token-modal.component';
import {AddYearnVaultModalComponent} from './add-yearn-vault/add-yearn-vault-modal.component';
import {TransferGasModalComponent} from "./transfer-gas/transfer-gas-modal.component";
import {MerchantModalComponent} from "./merchant/merchant-modal.component";
import {ChangeRoleModalComponent} from "./change-role/change-role-modal.component";
import {SetSettleLimitsModalComponent} from "./set-settle-limits/set-settle-limits-modal.component";
import {CreateAndDeployTokenModalComponent} from "./create-and-deploy-token/create-and-deploy-token-modal.component";
import {FiatBalancesModalComponent} from "./fiat-balances/fiat-balances-modal.component";
import {SetDailyLimitsModalComponent} from "./set-daily-limits/set-daily-limits-modal.component";
import {AdditionalUserModalComponent} from "./additional-users/additional-user-modal.component";
import {SwapSigningAddressModalComponent} from "./swap-signing-address/swap-signing-address-modal.component";
import {AddToMerchantModalComponent} from "./add-to-merchant/add-to-merchant-modal.component";
import {SetSupplyLimitModalComponent} from "./set-supply-limit/set-supply-limit-modal.component";
import {StoreModalComponent} from "./store/store-modal.component";

export const DASHBOARD_MODALS_COMPONENTS = [AddYearnVaultModalComponent, ChooseTokenModalComponent, SetDailyLimitsModalComponent, SuspendActivateModalComponent, KycClearConsiderModalComponent, UserSearchModalComponent, ChangeEmailModalComponent, ChangePersonalInfoModalComponent, ChangeMobileModalComponent, BuySellModalComponent, SendReceiveModalComponent, AddPaymentMethodModalComponent, CreateAccountModalComponent, TransactionDetailModalComponent, VerifyIdentityModalComponent, AddUserModalComponent, ChangePasswordModalComponent, ChangeAmlModalComponent, InstitutionModalComponent, InviteUserModalComponent, ElementModalComponent, ChangeGasTankModalComponent, ChangeCurrencyModalComponent, ChooseBlockchainModalComponent, TwoFactorModalComponent, TransferGasModalComponent, MerchantModalComponent, ChangeRoleModalComponent, SetSettleLimitsModalComponent, CreateAndDeployTokenModalComponent, FiatBalancesModalComponent, AdditionalUserModalComponent, SwapSigningAddressModalComponent, AddToMerchantModalComponent, SetSupplyLimitModalComponent, StoreModalComponent];

export * from './add-yearn-vault/add-yearn-vault-modal.component';
export * from './buy-sell/buy-sell-modal.component';
export * from './send-receive/send-receive-modal.component';
export * from './add-payment-method/add-payment-method-modal.component';
export * from './create-account/create-account-modal.component';
export * from './transaction-detail/transaction-detail-modal.component';
export * from './verify-identity/verify-identity-modal.component';
export * from './add-user/add-user-modal.component';
export * from './change-password/change-password-modal.component';
export * from './change-personal-info/change-personal-info-modal.component';
export * from './change-email/change-email-modal.component';
export * from './change-mobile/change-mobile-modal.component';
export * from './user-search/user-search-modal.component';
export * from './kyc-clear-consider/kyc-clear-consider-modal.component';
export * from './suspend-activate/suspend-activate-modal.component';
export * from './set-daily-limits/set-daily-limits-modal.component';
export * from './institution/institution-modal.component';
export * from './change-aml/change-aml-modal.component';
export * from './invite-user/invite-user-modal.component';
export * from './element/element-modal.component';
export * from './change-gas-tank/change-gas-tank-modal.component';
export * from './change-currency/change-currency-modal.component';
export * from './choose-blockchain/choose-blockchain-modal.component';
export * from './two-factor/two-factor-modal.component';
export * from './choose-token/choose-token-modal.component';
export * from './transfer-gas/transfer-gas-modal.component';
export * from './merchant/merchant-modal.component';
export * from './change-role/change-role-modal.component';
export * from './set-settle-limits/set-settle-limits-modal.component';
export * from './create-and-deploy-token/create-and-deploy-token-modal.component';
export * from './fiat-balances/fiat-balances-modal.component';
export * from './additional-users/additional-user-modal.component';
export * from './swap-signing-address/swap-signing-address-modal.component';
export * from './add-to-merchant/add-to-merchant-modal.component';
export * from './set-supply-limit/set-supply-limit-modal.component';
export * from './store/store-modal.component';
