import {Inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType, OnInitEffects} from '@ngrx/effects';
import {mergeMap, withLatestFrom} from 'rxjs/operators';
import {DashboardActions} from './dashboard.actions';
import {from, Observable} from 'rxjs';
import {Action, select, Store} from '@ngrx/store';
import {CoreActions, IPlatformDeviceInfo, PlatformDeviceInfoToken, UiActions} from '@vesto/xplat/core';
import {
  AccountActions,
  AccountFacade,
  ApiActions,
  GasTankActions,
  InstitutionActions,
  MerchantActions,
  BankAccountActions,
  BankAccountFacade,
  SignUpActions,
  SnapShotActions,
  StoreActions,
  SystemActions,
  TransactionActions,
  TransactionFacade,
  UserActions,
  UserFacade,
  VestoSelectors
} from '@vesto/ngx-vesto/state';
import {DashboardFacade} from './dashboard.facade';
import {AccountHeader, DashboardState, IPagination, Section} from './dashboard.state';
import {AccountType, IAccount, RoleType, UserUtil} from '@vesto/vesto';
import {SignInActions} from '../../sign-in/state';

@Injectable()
export class DashboardEffects implements OnInitEffects {

  constructor(private store: Store<DashboardState.IState>, private actions$: Actions, private userFacade: UserFacade, private bankAccountFacade: BankAccountFacade, private accountFacade: AccountFacade, private transactionFacade: TransactionFacade, private dashboardFacade: DashboardFacade, @Inject(PlatformDeviceInfoToken) private platformDeviceInfo: IPlatformDeviceInfo) {
  }

  refresh$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(DashboardActions.refresh),
        withLatestFrom(this.userFacade.user$, this.dashboardFacade.selectedMerchant$),
        mergeMap(([{}, user, selectedMerchant]) => {
          if (!user || !user.id) {
            return from([CoreActions.noop()]);
          }
          if (UserUtil.hasRole(user, RoleType.SUPER_ADMIN)) {
            return from([SystemActions.find(), InstitutionActions.findAll(), InstitutionActions.findByUser(), UserActions.find(), BankAccountActions.findByUser(), AccountActions.findByUser()]);
          } else if (UserUtil.hasRole(user, RoleType.AUDITOR)) {
            return from([SystemActions.find(), UserActions.find(), BankAccountActions.findByUser(), AccountActions.findByUser()]);
          } else if (UserUtil.hasRole(user, RoleType.ADMIN)) {
            return from([InstitutionActions.findByUser(), UserActions.find(), BankAccountActions.findByUser(), MerchantActions.findByInstitution(), AccountActions.findByUser()]);
          } else {
            const actions: Action[] = [AccountActions.findByUser(), UserActions.find(), BankAccountActions.findByUser(), AccountActions.findByUser()];
            if (!!user.merchant && !selectedMerchant) {
              actions.push(DashboardActions.selectMerchant({merchant: user.merchant}));
            }
            return from(actions);
          }
        })
      )
  );

  verifyEmailSuccess$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(SignUpActions.verifyEmailSuccess),
        mergeMap(({user}) => {
          return from([DashboardActions.refresh(), DashboardActions.selectSection({section: Section.PERSONAL_ACCOUNTS})]);
        })
      )
  );

  setError$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(ApiActions.setError),
        withLatestFrom(this.store.pipe(select(VestoSelectors.authenticated))),
        mergeMap(([{apiError}, authenticated]) => {
          if ((apiError.error === 'InvalidSignature' && authenticated) || apiError.error === 'SessionExpired') {
            return from([UserActions.signOut(), DashboardActions.closeModal(), SignInActions.openOrGoToSignIn(), UiActions.notification({message: 'Your session expired. please sign-in again.'})]);
          } else {
            return from([CoreActions.noop()]);
          }
        })
      )
  );

  selectSection$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(DashboardActions.selectSection),
        withLatestFrom(this.userFacade.user$, this.accountFacade.accounts$, this.dashboardFacade.filter$, this.dashboardFacade.sectionAccounts$),
        mergeMap(([{section}, user, accounts, filter, sectionAccounts]) => {
          const sectionAccount = sectionAccounts.find(_sectionAccount => _sectionAccount.section === section);
          let account: IAccount = null;

          switch (section) {
            case Section.OVERVIEW:
              if (UserUtil.hasRole(user, RoleType.SUPER_ADMIN)) {
                return from([SnapShotActions.findBySystem(), SnapShotActions.findByInstitution(), SnapShotActions.findByUser()]);
              } else if (UserUtil.hasRole(user, RoleType.SUPER_ADMIN)) {
                return from([SnapShotActions.findByInstitution(), SnapShotActions.findByUser()]);
              } else {
                return from([SnapShotActions.findByUser()]);
              }
            case Section.PERSONAL_ACCOUNTS:
              account = sectionAccount ? sectionAccount.account : accounts.find(account => account.id === user.defaultAccountId);
              return from([account ? DashboardActions.selectAccount({account}) : CoreActions.noop()]);
            case Section.ADMIN_ACCOUNTS:
              account = sectionAccount ? sectionAccount.account : accounts.find(account => account.type === AccountType.FIAT);
              return from([account ? DashboardActions.selectAccount({account}) : CoreActions.noop()]);
            case Section.SMART_CONTRACTS:
              account = sectionAccount ? sectionAccount.account : accounts.find(account => account.type === AccountType.VLIGHTHOUSE);
              return from([account ? DashboardActions.selectAccount({account}) : CoreActions.noop()]);
            case Section.GLOBAL_TRANSACTIONS:
              return from([DashboardActions.filter({filter})]);
            case Section.SETTINGS_BANK_ACCOUNTS:
              if (UserUtil.hasRole(user, RoleType.SUPER_ADMIN) || UserUtil.hasRole(user, RoleType.AUDITOR)) {
                return from([SystemActions.find()]);
              } else {
                return from([CoreActions.noop()]);
              }
            case Section.GAS_TANKS:
              account = accounts.find(_account => _account.type === AccountType.GAS_TANK);
              return account ? from([GasTankActions.findActive(), DashboardActions.selectAccount({account})]) : from([GasTankActions.findActive()]);
            case Section.INSTITUTION_MERCHANTS:
              return from([MerchantActions.findByInstitution()]);
            default:
              return from([CoreActions.noop()]);
          }
        })
      )
  );

  selectAccount$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(DashboardActions.selectAccount),
        withLatestFrom(this.dashboardFacade.selectedSymbol$, this.dashboardFacade.selectedAccountHeader$, this.dashboardFacade.selectedSection$, this.dashboardFacade.isAdminView$),
        mergeMap(([{account}, selectedSymbol, selectedAccountHeader, selectedSection, isAdminView]) => {
          if (!account) {
            return from([CoreActions.noop]);
          }
          const actions = [];
          if (account.type === AccountType.GAS_TANK && (selectedAccountHeader !== AccountHeader.ETHEREUM && selectedAccountHeader !== AccountHeader.POLYGON)) {
            actions.push(DashboardActions.selectAccountHeader({accountHeader: AccountHeader.ETHEREUM}));
          } else if (account.type !== AccountType.GAS_TANK && (selectedAccountHeader !== AccountHeader.TOKEN && selectedAccountHeader !== AccountHeader.CONTRACT)) {
            actions.push(DashboardActions.selectAccountHeader({accountHeader: AccountHeader.TOKEN}));
          }
          actions.push(AccountActions.find({accountId: account.id}));
          actions.push(SnapShotActions.findByAccount({accountId: account.id}));
          actions.push(DashboardActions.selectSymbol({symbol: account.type !== AccountType.WALLET ? 'all' : selectedSymbol}));
          actions.push(DashboardActions.selectSectionAccount({
            sectionAccount: {
              isAdminView,
              section: selectedSection,
              account,
              selected: true
            }
          }));
          return from(actions);
        })
      )
  );

  setIsAdminView$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(DashboardActions.setIsAdminView),
        withLatestFrom(this.dashboardFacade.sectionAccounts$),
        mergeMap(([{isAdminView}, sectionAccounts]) => {
          const sectionAccount = sectionAccounts.find(_sectionAccount => _sectionAccount.isAdminView === isAdminView && _sectionAccount.selected);
          if (sectionAccount) {
            return from([DashboardActions.selectSection({section: sectionAccount.section})]);
          }
          return from([DashboardActions.selectSection({section: isAdminView ? Section.ADMIN_ACCOUNTS : Section.PERSONAL_ACCOUNTS})]);
        })
      )
  );

  selectSymbol$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(DashboardActions.selectSymbol),
        withLatestFrom(this.dashboardFacade.selectedAccount$, this.dashboardFacade.paginations$),
        mergeMap(([{symbol}, selectedAccount, paginations]) => {
          if (!selectedAccount) {
            return from([CoreActions.noop()]);
          }
          const pagination = this.getPagination(paginations, TransactionActions.findByAccountAndSymbol, selectedAccount.id);
          return from([
            DashboardActions.selectPagination({pagination}),
            TransactionActions.findByAccountAndSymbol({
              accountId: selectedAccount.id,
              symbol,
              page: pagination.page,
              size: pagination.size
            })
          ]);
        })
      )
  );

  filter$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(DashboardActions.filter),
        withLatestFrom(this.dashboardFacade.paginations$),
        mergeMap(([{filter}, paginations]) => {

          switch (filter.action) {
            case TransactionActions.findByTypeAndStatus: {
              const pagination = this.getPagination(paginations, TransactionActions.findByTypeAndStatus, 'TRANSACTIONS_TYPE_AND_STATUS');
              return from([
                TransactionActions.findByTypeAndStatus({
                  _type: filter.type,
                  status: filter.status,
                  page: pagination.page,
                  size: pagination.size
                }),
                DashboardActions.selectPagination({
                  pagination: {
                    ...pagination,
                    action: TransactionActions.findByTypeAndStatus
                  }
                })
              ]);
            }
            case TransactionActions.findBuyOrSellActionRequired: {
              const pagination = this.getPagination(paginations, TransactionActions.findBuyOrSellActionRequired, 'TRANSACTIONS_ACTION_REQUIRED');
              return from([
                TransactionActions.findBuyOrSellActionRequired({
                  page: pagination.page,
                  size: pagination.size
                }),
                DashboardActions.selectPagination({
                  pagination: {
                    ...pagination,
                    action: TransactionActions.findBuyOrSellActionRequired
                  }
                })
              ]);
            }
          }
        })
      )
  );

  paginate$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(DashboardActions.paginate),
        withLatestFrom(this.dashboardFacade.selectedAccount$, this.dashboardFacade.selectedSymbol$, this.dashboardFacade.filter$),
        mergeMap(([{pagination}, selectedAccount, selectedSymbol, filter]) => {
          if (!selectedAccount) {
            return from([CoreActions.noop()]);
          }

          switch (pagination.action) {
            case TransactionActions.findByAccountAndSymbol:
              return from([
                TransactionActions.findByAccountAndSymbol({
                  accountId: pagination.id,
                  symbol: selectedSymbol,
                  page: pagination.page,
                  size: pagination.size
                })
              ]);
            case TransactionActions.findByTypeAndStatus:
              return from([
                TransactionActions.findByTypeAndStatus({
                  _type: filter.type,
                  status: filter.status,
                  page: pagination.page,
                  size: pagination.size
                })
              ]);
            case TransactionActions.findBuyOrSellActionRequired:
              return from([
                TransactionActions.findBuyOrSellActionRequired({
                  page: pagination.page,
                  size: pagination.size
                })
              ]);
            default:
              return from([CoreActions.noop()]);
          }
        })
      )
  );

  verifyIdentity$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(DashboardActions.verifyIdentity),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{address, googlePlaceId}, user]) => {
          return from([
            DashboardActions.closeModal(),
            UserActions.set({
              user: {
                ...user,
                address: address,
                google: {
                  ...user.google,
                  placeId: googlePlaceId
                }
              }
            }),
            UserActions.createOnfidoSdkToken({device: this.platformDeviceInfo.os})
          ]);
        })
      )
  );

  onfidoSuccess$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(DashboardActions.onfidoSuccess),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{data}, user]) => {
          return [
            UserActions.verifyIdentity({
              address: user?.address,
              googlePlaceId: user?.google?.placeId,
              documentType: data?.document_front?.type,
              documentFrontId: data?.document_front?.id,
              documentBackId: data?.document_back ? data?.document_back?.id : null,
              faceId: data?.face?.id
            })
          ]
        })
      )
  );

  onfidoFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActions.onfidoFailure),
        mergeMap(({error}) => {
          return from([UiActions.hideSpinner()]);
        })
      ),
    {dispatch: false}
  );

  selectMerchant$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(DashboardActions.selectMerchant),
        mergeMap(({merchant}) => {
          return from([
            UserActions.findByMerchant({merchantId: merchant.id}),
            StoreActions.findByMerchant({merchantId: merchant.id})
          ]);
        })
      )
  );

  ngrxOnInitEffects(): Action {
    return DashboardActions.refresh();
  }

  private getPagination(
    paginations: IPagination[],
    action: Action,
    id: string
  ): IPagination {
    let pagination = paginations.find(_pagination => _pagination.action === action && _pagination.id === id);
    if (!pagination) {
      pagination = {
        action,
        id,
        page: 0,
        size: 15
      };
    }
    return pagination;
  }
}
