import {Inject, Injectable} from '@angular/core';
import {VestoState} from '../vesto.state';
import { ApiActions } from '../api';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {EnvironmentToken, FeesApiService} from '../../services';
import {IEnvironment, ObjectUtil} from '@vesto/vesto';
import {Action, Store} from '@ngrx/store';
import {from, Observable, withLatestFrom} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {FeeActions} from './fee.actions';
import {InstitutionFacade, UserFacade} from "@vesto/ngx-vesto/state";

@Injectable()
export class FeeEffects {
  constructor(private userFacade: UserFacade, private actions$: Actions, @Inject(EnvironmentToken) protected environment: IEnvironment, protected store: Store<VestoState.IState>, private feesApiService: FeesApiService) {
  }

  calculate$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(FeeActions.calculate),
        mergeMap(({amount}) => {
          return this.feesApiService.calculate(amount).pipe(
            mergeMap((result) => {
              return from([FeeActions.calculateSuccess({fees: result})]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([FeeActions.calculateFailure({apiError}), ApiActions.setError({apiError})]);
            })
          );
        })
      )
  );
}
