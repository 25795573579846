import {inject, Injectable} from "@angular/core";
import { Actions, createEffect, ofType, OnInitEffects } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { distinctUntilChanged, map, switchMap, tap } from "rxjs/operators";
import {HydrationActions} from './hydration.actions';
import {StorageKeys, StorageService} from '@vesto/xplat/core';

@Injectable()
export class HydrationEffects implements OnInitEffects {
  hydrate$ = createEffect(() =>
    this.action$.pipe(
      ofType(HydrationActions.hydrate),
      map(() => {
        const storageValue = this.storageService.getItem(StorageKeys.STATE);
        if (storageValue) {
          try {
            const state = JSON.parse(storageValue);
            return HydrationActions.hydrateSuccess({ state });
          } catch {
            this.storageService.removeItem(StorageKeys.STATE);
          }
        }
        return HydrationActions.hydrateFailure();
      })
    )
  );

  serialize$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(HydrationActions.hydrateSuccess, HydrationActions.hydrateFailure),
        switchMap(() => this.store),
        distinctUntilChanged(),
        tap((state) => this.storageService.setItem(StorageKeys.STATE, JSON.stringify(state)))
      ),
    { dispatch: false }
  );

  private storageService = inject(StorageService);

  constructor(private action$: Actions, private store: Store<any>) {}

  ngrxOnInitEffects(): Action {
    return HydrationActions.hydrate();
  }
}
