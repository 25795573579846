import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { VerifyMobileBaseComponent } from '@vesto/xplat/features';

@Component({
  selector: 'gbl-verify-mobile',
  templateUrl: 'verify-mobile.component.html',
  animations: [
    trigger('expandCollapseFaq', [
      state(
        'void',
        style({
          height: '0px',
          overflow: 'hidden'
        })
      ),
      //element being added into DOM.
      transition(':enter', [
        animate(
          '500ms ease-in-out',
          style({
            height: '*',
            overflow: 'hidden'
          })
        )
      ]),
      //element being removed from DOM.
      transition(':leave', [
        animate(
          '500ms ease-in-out',
          style({
            height: '0px',
            overflow: 'hidden'
          })
        )
      ])
    ])
  ]
})
export class VerifyMobileComponent extends VerifyMobileBaseComponent implements OnInit {}
