import { createAction, props } from '@ngrx/store';
import { IApiError, IUser } from '@vesto/vesto';

export namespace SignUpActions {
  export const findUserByToken = createAction('[@vesto/sign-up] findUserByToken', props<{ token: string }>());

  export const findUserByTokenSuccess = createAction('[@vesto/sign-up] findUserByTokenSuccess', props<{ token: string; user: IUser }>());

  export const findUserByTokenFailure = createAction('[@vesto/sign-up] findUserByTokenFailure', props<{ apiError: IApiError }>());

  export const signUp = createAction('[@vesto/sign-up] signUp', props<{ user: IUser; password: string }>());

  export const signUpSuccess = createAction('[@vesto/sign-up] signUpSuccess', props<{ token: string; user: IUser; password: string }>());

  export const signUpFailure = createAction('[@vesto/sign-up] signUpFailure', props<{ apiError: IApiError }>());

  export const sendSmsCode = createAction('[@vesto/sign-up] sendSmsCode', props<{ mobile: string }>());

  export const sendSmsCodeSuccess = createAction('[@vesto/sign-up] sendSmsCodeSuccess', props<{ token: string; user: IUser }>());

  export const sendSmsCodeFailure = createAction('[@vesto/sign-up] sendSmsCodeFailure', props<{ apiError: IApiError }>());

  export const verifySmsCode = createAction('[@vesto/sign-up] verifySmsCode', props<{ code: string }>());

  export const verifySmsCodeSuccess = createAction('[@vesto/sign-up] verifySmsCodeSuccess', props<{ token: string; user: IUser }>());

  export const verifySmsCodeFailure = createAction('[@vesto/sign-up] verifySmsCodeFailure', props<{ apiError: IApiError }>());

  export const sendEmail = createAction('[@vesto/sign-up] sendEmail');

  export const sendEmailSuccess = createAction('[@vesto/sign-up] sendEmailSuccess', props<{ token: string; user: IUser }>());

  export const sendEmailFailure = createAction('[@vesto/sign-up] sendEmailFailure', props<{ apiError: IApiError }>());

  export const verifyEmail = createAction('[@vesto/sign-up] verifyEmail', props<{ token: string }>());

  export const verifyEmailSuccess = createAction('[@vesto/sign-up] verifyEmailSuccess', props<{ privateKey: string; user: IUser }>());

  export const verifyEmailFailure = createAction('[@vesto/sign-up] verifyEmailFailure', props<{ apiError: IApiError }>());

  export const cancel = createAction('[@vesto/sign-up] cancel');
}
