<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <h3 class="section-number"><i class="fal fa-check-circle"></i> Confirm</h3>
</div>
<mat-dialog-content>
  <p *ngIf="message" [innerHTML]="message"></p>
  <p *ngIf="html" [innerHTML]="html"></p>
  <form [hidden]="!authentication" [formGroup]="formGroup">
    <span class="text-sm mb-3"
      >Please enter the code sent to mobile ending in <b>{{ user?.mobileLast4 }}</b></span
    >
    <div class="vesto-input vesto-input-inline">
      <label for="code">Code</label>
      <gbl-input mask="code" type="code" focusMe="true" formControlName="code" id="code" name="code"> </gbl-input>
    </div>
    <div class="mat-small mt-4">Didn't get a code? <a class="underline" (click)="sendSmsCode()">Click here to resend.</a></div>
  </form>
  <div class="flex content-between flex-wrap mt-10 mb-1">
    <div class="w-full md:w-1/2">
      <button mat-button class="button outline-blue" (click)="close()">Cancel</button>
    </div>
    <div class="md:w-1/2 text-right">
      <button mat-button class="button button-primary" (click)="confirm()">Confirm</button>
    </div>
  </div>
</mat-dialog-content>
