import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgxVestoModule } from '../ngx-vesto.module';
import { vestoReducer } from './vesto.reducer';
import { UserEffects } from './user/user.effects';
import { InstitutionEffects } from './institution/institution.effects';
import { TransactionEffects } from './transaction/transaction.effects';
import { AccountEffects } from './account/account.effects';
import { BankAccountEffects } from './bank-account/bank-account.effects';
import { VestoState } from './vesto.state';
import { SnapShotEffects } from './snap-shot/snap-shot.effects';
import { MarketDataEffects } from './market-data/market-data.effets';
import { SignUpEffects } from './sign-up/sign-up-effects';
import { SystemEffects } from './system/system.effects';
import { ContactsEffects } from './contact/contacts.effects';
import {MerchantEffects} from './merchant/merchant.effects';
import {FeeEffects} from './fee/fee.effects';
import {GasTankEffects} from './gas-tanks/gas-tank.effects';
import {StoreEffects} from './store/store.effects';

@NgModule({
  imports: [
    CommonModule,
    NgxVestoModule,
    StoreModule.forFeature('vesto', vestoReducer, {
      initialState: VestoState.initialState
    }),
    EffectsModule.forFeature([StoreEffects, GasTankEffects, FeeEffects, MerchantEffects, ContactsEffects, SystemEffects, SignUpEffects, UserEffects, InstitutionEffects, AccountEffects, BankAccountEffects, TransactionEffects, SnapShotEffects, MarketDataEffects])
  ]
})
export class VestoStateModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: VestoStateModule
  ) {
    if (parentModule) {
      throw new Error(`VestoStateModule has already been loaded. Import in the AppModule only.`);
    }
  }
}
