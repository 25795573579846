import { Directive, OnInit } from '@angular/core';
import { BaseComponent, CoreService, uiCloseModal } from '@vesto/xplat/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { IUser } from '@vesto/vesto';
import { takeUntil } from 'rxjs/operators';

@Directive()
export abstract class ConfirmDialogBaseComponent extends BaseComponent implements OnInit {
  public formGroup: UntypedFormGroup;
  public user: IUser;

  constructor() {
    super();
    CoreService.userFacade.user$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.user = result;
    });
  }

  ngOnInit() {
    this.formGroup = CoreService.formBuilder.group(
      {
        code: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ]
      },
      { updateOn: 'change' }
    );
    //this.handleFormGroupValidation(this.formGroup);
  }

  sendSmsCode() {}

  close() {
    uiCloseModal();
  }
}
