import { NgModule } from '@angular/core';
// xplat
import { UIModule, DashboardModule, SignUpModule, SignInModule } from '@vesto/xplat/web/features';

const MODULES = [UIModule, SignUpModule, SignInModule, DashboardModule];

@NgModule({
  imports: [...MODULES],
  exports: [...MODULES]
})
export class SharedModule {}
