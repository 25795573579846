import {Component, OnInit} from '@angular/core';
import {BaseComponent, CoreService, uiCloseModal} from '@vesto/xplat/core';
import {IUser, UserStatus} from '@vesto/vesto';
import {take, takeUntil} from 'rxjs/operators';
import {UntypedFormGroup, Validators} from '@angular/forms';
import {ofType} from "@ngrx/effects";
import {UserActions} from "@vesto/ngx-vesto/state";

@Component({
  selector: 'gbl-suspend-activate-consider-modal',
  templateUrl: 'suspend-activate-modal.component.html'
})
export class SuspendActivateModalComponent extends BaseComponent implements OnInit {
  step: 'FORM_GROUP' | 'BUSY' | 'SUCCESS' | 'DETAIL' = 'FORM_GROUP';
  user: IUser;
  UserStatus = UserStatus;
  formGroup: UntypedFormGroup;
  error: string;
  icon: string;
  suspendOrActivate: string;

  ngOnInit() {
    CoreService.windowService.setTimeout(() => {
      switch (this.user.status) {
        case UserStatus.ACTIVE:
          this.icon = 'fa-user-lock';
          this.suspendOrActivate = 'Suspend';
          return;
        case UserStatus.SUSPENDED:
          this.icon = 'fa-user-unlock';
          this.suspendOrActivate = 'Activate';
      }
    }, 100);

    CoreService.userFacade.user$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.user = result;
    });
    this.formGroup = CoreService.formBuilder.group(
      {
        memo: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ]
      },
      { updateOn: 'change' }
    );
  }

  suspendActive() {
    this.step = 'BUSY';
    if (this.user.status === UserStatus.ACTIVE) {
      CoreService.userFacade.suspend();
    } else {
      CoreService.userFacade.activate();
    }

    CoreService.actions$.pipe(ofType(UserActions.suspendSuccess, UserActions.activateSuccess), take(1), takeUntil(this.destroy$)).subscribe(() => {
      this.step = 'SUCCESS';
      CoreService.windowService.setTimeout(() => {
        this.step = 'DETAIL';
      }, 1600);
    });

    CoreService.actions$.pipe(ofType(UserActions.suspendFailure, UserActions.activateFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
      this.step = 'FORM_GROUP';
    });
  }

  close() {
    uiCloseModal();
  }
}
