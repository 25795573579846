<div class="container">
  <div class="flex justify-between items-center mt-20">
    <img class="w-48 hidden lg:block xl:block mr-2" src="./assets/images/signup1-left.svg" />
    <div class="w-full lg:w-3/5 xl:w-3/5 text-center">
      <span class="fa-stack fa-2x">
        <i class="far fa-search fa-stack-2x"></i>
        <i class="fas fa-question fa-stack-1x opacity-50 -ml-2 -mt-2"></i>
      </span>
      <h2 class="text-3xl mt-6 mb-3">Oops, we can't find that.</h2>
      <p class="text-sm underline"><a routerLink="/">Take me home</a></p>
    </div>
    <img class="w-48 hidden lg:block xl:block ml-2" src="./assets/images/signup1-right.svg" />
  </div>
</div>
