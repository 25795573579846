import { Component } from '@angular/core';
import { uiCloseModal } from '@vesto/xplat/core';
import { ConfirmDialogBaseComponent } from '@vesto/xplat/features';
import { FormGroupUtil } from '@vesto/xplat/utils';

@Component({
  selector: 'gbl-confirm-dialog',
  templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent extends ConfirmDialogBaseComponent {
  message: string;
  html: string;
  authentication: false;

  confirm() {
    if (this.authentication) {
      // if (!CoreService.formService.validate()) {
      //   return;
      // // }

      const value = FormGroupUtil.trim(this.formGroup.value);
      // Constants.userFacade.verifySmsCode(value.code);
    } else this.close();
  }
}
