import { Pipe, PipeTransform } from '@angular/core';
import { AccountType, IAccount, ITransaction, TransactionStatus, TransactionType } from '@vesto/vesto';

@Pipe({
  name: 'transactionText',
  pure: true
})
export class TransactionTextPipe implements PipeTransform {
  transform(transaction: ITransaction, account: IAccount): any {
    if (!transaction) {
      return '';
    }

    switch (transaction.type) {
      case TransactionType.TRANSFER:
      case TransactionType.PAYMENT:
        if ([TransactionStatus.ERROR].includes(transaction.status)) {
          return `Payment Error`;
        }
        if (account && ((transaction.accountId === account.id && transaction.recipientAccountId) || (transaction.accountId === account.id && !transaction.recipientAccountId))) {
          if (transaction?.recipientUser?.merchant) {
            return `Payment Sent`;
          }
          return `Sent ${transaction.token.symbol}`;
        }

        if (transaction?.recipientUser?.merchant) {
          return `Payment Received`;
        }
        return `Received ${transaction.token.symbol}`;
      case TransactionType.BUY:
        return `Funded Wallet`;//`Bought ${transaction.token.symbol}`;
      case TransactionType.SELL:
        return `Transferred`;//`Sold ${transaction.token.symbol}`;
      case TransactionType.INTEREST_PAYMENT:
        return `${transaction.token.symbol} Reward`;
      case TransactionType.MINT:
        return `Minted ${transaction.token.symbol}`;
      case TransactionType.DEPLOY_VWALLET:
        return 'VWallet Deployed';
      case TransactionType.FEE:
        return !transaction.referenceTransaction ? '' : `Collected ${transaction.token.symbol}`;
      case TransactionType.SYNC:
        if (account.type === AccountType.VLIGHTHOUSE) {
          return 'Synced';
        }
        return `Synced ${transaction?.token?.symbol}`;
      case TransactionType.SYNC_PRICE_PER_TOKENS:
        return 'Synced Price Per Tokens';
      case TransactionType.SYNC_ADDRESSES:
        return 'Synced Addresses';
      case TransactionType.SYNC_IMPLEMENTATION_ADDRESSES:
        return 'Synced Implementation Addresses';
      case TransactionType.ADD_USER:
      case TransactionType.ADD_ADDITIONAL_USER:
        return 'Added User';
      case TransactionType.REMOVE_ADDITIONAL_USER:
        return 'Removed User';
      case TransactionType.ADD_YEARN_VAULT:
        return 'Added Yearn Vault';
      case TransactionType.PAYOUT:
        return 'Payout';
      case TransactionType.SWAP_SIGNING_ADDRESSES:
        return 'Swap Signing Address';
      case TransactionType.TIP:
        return 'Tip';
      default:
        return '';
    }
  }
}
