import {Component, OnInit} from "@angular/core";
import {BaseComponent, CoreService, uiCloseModal} from "@vesto/xplat/core";
import {FormGroup, UntypedFormControl, Validators} from "@angular/forms";
import {filter, take, takeUntil} from "rxjs/operators";
import {MerchantActions, UserActions} from "@vesto/ngx-vesto/state";
import {ofType} from "@ngrx/effects";
import {IMerchant, IUser, RoleType, UserUtil} from "@vesto/vesto";

@Component({
  selector: 'gbl-add-to-merchant',
  templateUrl: 'add-to-merchant-modal.component.html'
})

export class AddToMerchantModalComponent extends BaseComponent implements OnInit {
  step : 'FORM_GROUP' | 'BUSY' | 'SUCCESS' | 'DETAIL' = 'FORM_GROUP';
  // formControl = new UntypedFormControl();
  error: string;
  inviteEmail: string;
  inviteMerchant: IMerchant;
  merchant: IMerchant;
  merchants: IMerchant[] = [];
  user: IUser;
  users$ = CoreService.userFacade.users$;
  formGroup: FormGroup;
  UserUtil = UserUtil;
  RoleType = RoleType;

  ngOnInit() {
    this.formGroup = CoreService.formBuilder.group({
      merchantId: [
        null,
        {
          validators: [Validators.required]
        }
      ],
      email: [
        null,
        {
          validators: [Validators.required, Validators.email]
        }
      ]
    });

    CoreService.userFacade.user$
      .pipe(
        filter(user => !!user),
        takeUntil(this.destroy$)
      )
      .subscribe((user) => {
        this.user = user;
        this.formGroup.controls.merchantId.setValue(this.user.merchant.id);
    });
    CoreService.merchantFacade.merchants$
      .pipe(
        filter((merchants) => !!merchants),
        takeUntil(this.destroy$)
      )
      .subscribe((merchants) => {
        this.merchants = merchants;
    });

    // this.formControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((result) => {
    //   if (typeof result === 'string') {
    //     this.search(result);
    //   }
    // });
  }

  // getOptionText(option) {
  //   return option ? `${option.firstName} ${option.lastName} ${option.email}` : null;
  // }
  //
  // search(keyword: string) {
  //   if (!keyword) {
  //     return;
  //   }
  //   CoreService.userFacade.typeahead(keyword);
  // }

  inviteToMerchant() {
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }

    this.step = 'BUSY';
    const value = this.formGroup.value;
    CoreService.actions$.pipe(ofType(UserActions.inviteToMerchantSuccess), take(1), takeUntil(this.destroy$)).subscribe(() => {
      this.inviteEmail = value.email;
      if (this.merchants.length > 0) {
        this.merchants.forEach(merchant => {
          if (merchant.id === value.merchantId) {
            this.inviteMerchant = merchant;
          }
        });
      } else {
        this.inviteMerchant = this.user.merchant
      }
      this.step = 'SUCCESS';
      CoreService.windowService.setTimeout(() => {
        this.step = 'DETAIL';
      }, 1600);
    });
    CoreService.actions$.pipe(ofType(UserActions.inviteToMerchantFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.step = 'FORM_GROUP';
      this.error = `apiErrors.${result.apiError.error}`;
    });

    CoreService.userFacade.inviteToMerchant(value.merchantId, value.email);
  }

  // addToMerchant(user: IUser) {
  //   this.step = 'BUSY';
  //
  //   CoreService.actions$.pipe(ofType(MerchantActions.addUserSuccess), take(1), takeUntil(this.destroy$)).subscribe(() => {
  //     this.step = 'SUCCESS';
  //     CoreService.windowService.setTimeout(() => {
  //       this.step = 'DETAIL';
  //     }, 1600);
  //   });
  //   CoreService.actions$.pipe(ofType(MerchantActions.addUserFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
  //     this.step = 'FORM_GROUP';
  //     this.error = `apiErrors.${result.apiError.error}`;
  //   });
  //
  //   CoreService.merchantFacade.addUser(this.merchant.id, user.id);
  // }

  close() {
    uiCloseModal();
  }
}
