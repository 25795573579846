<div class="text-center">
  <img src="./assets/images/2fa-large.svg" class="w-25 mb-4 mt-2" />
  <h3 class="mb-2">Two Factor Authentication</h3>
  <div class="mb-2">Let's make sure your account is secure!</div>
  <hr class="border w-24 mt-6" />
</div>

<div class="text-sm mb-3" *ngIf="!smsCodeSent; else confirmCodeTempalate">Enter your mobile number to receive a code:</div>

<ng-template #confirmCodeTempalate>
  <p class="text-sm mb-3">
    Enter the code sent to your mobile ending in <span *ngIf="user?.mobileLast4">{{ user.mobileLast4 }}</span>
  </p>
</ng-template>

<!--<div class="tab-header-container tabs-small flex">
  <div (click)="activeTab = 'app'" class="tab-header flex-grow text-sm" [class.tab-header-active]="activeTab == 'app'">
    App
  </div>
  <div (click)="activeTab = 'sms'" class="tab-header flex-grow text-sm" [class.tab-header-active]="activeTab == 'sms'">
    SMS
  </div>
</div>-->

<div>
  <form [formGroup]="formGroup" [hidden]="smsCodeSent" (ngSubmit)="validateAndSendSmsCode()">
    <div class="flex mb-3 items-end form-dark">
      <div class="flex-grow">
        <div class="vesto-input w-full mt-2">
          <label for="mobile">Mobile</label>
          <gbl-input mask="mobile" id="mobile" formControlName="mobile" [inputDark]="true"></gbl-input>
        </div>
      </div>

      <button class="button button-lilac ml-3" type="submit">Submit</button>
    </div>
    <gbl-alert [type]="error" [message]="error" [dark]="true"></gbl-alert>
  </form>

  <form [formGroup]="codeFormGroup" [hidden]="!smsCodeSent" (ngSubmit)="validateAndVerifyCode()">
    <div class="flex justify-around form-dark">
      <div class="vesto-input vesto-input-inline">
        <label for="code">Code</label>
        <gbl-input mask="code" id="code" name="code" formControlName="code"></gbl-input>
      </div>
      <button class="button button-lilac ml-3" type="submit">Submit</button>
    </div>

    <div *ngIf="error" class="mt-4">
      <gbl-alert [type]="error" [message]="error" [dark]="true"> </gbl-alert>
    </div>

    <div class="text-center"><hr class="border w-24 mt-10" /></div>
    <div class="flex items-center justify-center">
      <span class="text-grey-dark text-sm">Didn't receive a code? </span>
      <button class="button-sm button button-xs outline-lilac ml-2" (click)="smsCodeSent = false">Try Again</button>
    </div>
  </form>
</div>

<div class="signup-help text-black shadowed rounded-lg p-4">
  <div class="cursor-pointer" (click)="show2faAnswer = !show2faAnswer">
    <div class="faq-question pb-2">
      Why do I need to use two-factor authentication?
      <div class="faq-chevron" [class.faq-opened]="show2faAnswer">
        <i class="far fa-chevron-down"></i>
      </div>
    </div>
    <div class="faq-answer pt-2 text-sm" *ngIf="show2faAnswer" [@expandCollapseFaq]>
      Security is of the utmost importance at Vesto, and we want to make sure your funds are kept safe!<br />
      Using just an email and password for logging in makes your account vulnerable to security threats.
    </div>
  </div>
</div>
