import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IEnvironment, ObjectUtil } from '@vesto/vesto';
import { Action, Store } from '@ngrx/store';
import { EnvironmentToken, AccountsApiService } from '../../services';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { AccountActions } from './account.actions';
import { ApiActions } from '../api';
import { UserFacade } from '../../state/user';
import { VestoState } from '../vesto.state';
import { CoreActions } from '@vesto/xplat/core';

@Injectable()
export class AccountEffects {
  constructor(private userFacade: UserFacade, private actions$: Actions, @Inject(EnvironmentToken) protected environment: IEnvironment, protected store: Store<VestoState.IState>, private accountsApiService: AccountsApiService) {}

  find$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(AccountActions.find),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{ accountId }, user]) => {
          return this.accountsApiService.find(user.id, accountId).pipe(
            mergeMap((result) => {
              return from([AccountActions.findSuccess({ account: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([AccountActions.findFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  findByUser$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.findByUser),
      withLatestFrom(this.userFacade.user$),
      mergeMap(([{}, user]) => {
        if (!user) {
          return from([CoreActions.noop()]);
        }
        return this.accountsApiService.findByUser(user.id).pipe(
          mergeMap((result) => {
            return from([AccountActions.findByUserSuccess({ accounts: result })]);
          }),
          catchError((error) => {
            const apiError = ObjectUtil.toApiError(error);
            return from([AccountActions.findByUserFailure({ apiError }), ApiActions.setError({ apiError })]);
          })
        );
      })
    )
  );

  create$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.create),
      withLatestFrom(this.userFacade.user$),
      mergeMap(([{ name }, user]) => {
        return this.accountsApiService.create(user.id, name).pipe(
          mergeMap((result) => {
            return from([AccountActions.createSuccess({ account: result })]);
          }),
          catchError((error) => {
            const apiError = ObjectUtil.toApiError(error);
            return from([AccountActions.createFailure({ apiError }), ApiActions.setError({ apiError })]);
          })
        );
      })
    )
  );

  changeSettings$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.changeSettings),
      mergeMap(({ id, name }) => {
        return this.accountsApiService.changeSettings(id, name).pipe(
          mergeMap((result) => {
            return from([AccountActions.changeSettingsSuccess({ account: result })]);
          }),
          catchError((error) => {
            const apiError = ObjectUtil.toApiError(error);
            return from([AccountActions.changeSettingsFailure({ apiError }), ApiActions.setError({ apiError })]);
          })
        );
      })
    )
  );

  suspendUser$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.suspendUser),
      withLatestFrom(this.userFacade.user$),
      mergeMap(([{ additionalUserId }, user]) => {
        return this.accountsApiService.suspendUser(user.id, additionalUserId).pipe(
          mergeMap((result) => {
            return from([AccountActions.suspendUserSuccess({ account: result })]);
          }),
          catchError((error) => {
            const apiError = ObjectUtil.toApiError(error);
            return from([AccountActions.suspendUserFailure({ apiError }), ApiActions.setError({ apiError })]);
          })
        );
      })
    )
  );

  activeUser$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.activateUser),
      withLatestFrom(this.userFacade.user$),
      mergeMap(([{ additionalUserId }, user]) => {
        return this.accountsApiService.activateUser(user.id, additionalUserId).pipe(
          mergeMap((result) => {
            return from([AccountActions.activateUserSuccess({ account: result })]);
          }),
          catchError((error) => {
            const apiError = ObjectUtil.toApiError(error);
            return from([AccountActions.activateUserFailure({ apiError }), ApiActions.setError({ apiError })]);
          })
        );
      })
    )
  );
}
