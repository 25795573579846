/**
 *
 */
export interface IEnvironment {
  production?: boolean;
  enableAnalytics?: boolean;
  name?: 'local' | 'develop' | 'staging' | 'production';
  uriScheme?: string;
  appId?: string;
  vesto?: {
    url: string;
    wsUrl: string;
    version?: number;
    institutionId?: string;
    email?: string;
  };
  google?: {
    apiKey: string;
  };
  plaid?: {
    publicKey: string;
    env: string;
  };
  cloudFront?: {
    url: string;
  };
  sentry?: {
    dsn: string;
  };
  placeholder?: string;
  nx?: {
    cloudReadWrite: string;
    cloudRead: string;
  };
  onfido?: {
    api: string;
    apiKey?: string;
  };
  intercom?: {
    appID: string;
  };
  ethereum?: {
    webUrl: string;
  };
  freshchat?: {
    appId?: string;
    appKey?: string;
    domain?: string;
  };
  enableSignIn?: boolean;
}
