import { Pipe, PipeTransform } from '@angular/core';
import { IAccount, ITransaction, TransactionType } from '@vesto/vesto';
import { CoreService } from '@vesto/xplat/core';

@Pipe({
  name: 'transactionIcon',
  pure: true
})
export class TransactionIconPipe implements PipeTransform {
  transform(transaction: ITransaction, account: IAccount): any {
    if (!transaction) {
      return '';
    }
    if (CoreService.platformDeviceInfo.deviceDetails === 'browser') {
      switch (transaction.type) {
        case TransactionType.TRANSFER:
          if (account && transaction.accountId === account.id) {
            return 'fal fa-paper-plane';
          }
          return ''; // TODO : Can we create custom icon for font-awesome?
        case TransactionType.BUY:
          return 'fal fa-arrow-circle-down';
        case TransactionType.SELL:
          return 'fal fa-arrow-circle-up';
        case TransactionType.INTEREST_PAYMENT:
          return 'fal fa-chart-line';
        case TransactionType.MINT:
          return 'fal fa-coins';
        case TransactionType.FEE:
          return 'fal fa-hand-holding-usd';
        case TransactionType.SYNC:
        case TransactionType.SYNC_ADDRESSES:
        case TransactionType.SYNC_IMPLEMENTATION_ADDRESSES:
        case TransactionType.SYNC_PRICE_PER_TOKENS:
        case TransactionType.SYNC_APYS:
          return 'fal fa-sync-alt';
        case TransactionType.ADD_USER:
        case TransactionType.ADD_ADDITIONAL_USER:
          return 'fal fa-user-plus';
        case TransactionType.CHANGE_ROLE:
          return 'fal fa-user-edit';
        case TransactionType.REMOVE_ADDITIONAL_USER:
          return 'fal fa-user-minus';
        case TransactionType.DEPLOY_VWALLET:
          return 'fal fa-wallet';
        case TransactionType.SWAP_SIGNING_ADDRESSES:
        case TransactionType.SWAP_VSIGNING_ADDRESS:
          return 'fal fa-file-contract fa-fw';
        case TransactionType.PAYOUT:
          return 'fal fa-envelope-open-dollar';
        case TransactionType.ADD_YEARN_VAULT:
          return 'fal fa-piggy-bank';
        case TransactionType.DEPLOY_VLIGHTHOUSE:
        case TransactionType.DEPLOY_VUSER:
        case TransactionType.DEPLOY_VDEFI:
        case TransactionType.DEPLOY_VBRIDGE:
        case TransactionType.DEPLOY_VSWAP:
        case TransactionType.DEPLOY_VERC20:
        case TransactionType.DEPLOY_YEARN_VAULT:
        case TransactionType.DEPLOY_VLIGHTHOUSE_PROXY:
        case TransactionType.DEPLOY_VERC20_PROXY:
        case TransactionType.DEPLOY_VWALLET_PROXY:
          return 'fal fa-external-link';
        case TransactionType.INITIALIZE:
          return 'fal fa-play-circle';
        case TransactionType.SETTLE:
          return 'fal fa-handshake';
        case TransactionType.SET_SETTLE_LIMITS:
        case TransactionType.SET_DAILY_LIMITS:
        case TransactionType.SET_UNDERLYING_SUPPLY_LIMIT:
          return 'fal fa-sliders-v';
        case TransactionType.WIRE:
          return 'fal fa-paper-plane';
        default:
          return '';
      }
    }

    switch (transaction.type) {
      case TransactionType.TRANSFER:
        if (account && transaction.accountId === account.id) {
          return String.fromCharCode(0xf1d8);
        }
        break; // TODO : Jeff what about this case????
      case TransactionType.BUY:
        return String.fromCharCode(0xf0ab);
      case TransactionType.SELL:
        return String.fromCharCode(0xf0aa);
      case TransactionType.INTEREST_PAYMENT:
        return String.fromCharCode(0xf201);
      case TransactionType.MINT:
        return String.fromCharCode(0xf51e);
      case TransactionType.DEPLOY_VWALLET:
        return String.fromCharCode(0xf56c);
      case TransactionType.ADD_ADDITIONAL_USER:
        return String.fromCharCode(0xf234);
      default:
        return String.fromCharCode(0xf51e);
    }
  }
}
