import {IPlaid} from './plaid.interface';
import {BankAccountStatus} from "../enums/bank-account-status.enum";
import {BankAccountType} from "../enums/bank-account-type.enum";

export interface IBankAccount {
  id?: string;
  plaid?: IPlaid;
  type?: BankAccountType;
  status?: BankAccountStatus;
  subType?: string;
  name?: any;
  institution: string;
  mask?: string;
  ach?: {
    account: string,
    routing: string
  },
  updated?: string;
  created?: string;
  // Transients.
  default?: boolean;
}
