import {Component, OnInit} from "@angular/core";
import {BaseComponent, CoreService, StorageKeys, UiActions} from "@vesto/xplat/core";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {IUser, RoleType, UserUtil} from "@vesto/vesto";
import {filter, take, takeUntil} from "rxjs/operators";
import {ofType} from "@ngrx/effects";
import {TransactionActions} from "@vesto/ngx-vesto/state";

@Component({
  selector: 'gbl-promote-user-modal',
  templateUrl: './change-role-modal.component.html'
})

export class ChangeRoleModalComponent extends BaseComponent implements OnInit {
  step: 'FORM_GROUP' | 'TWO_FACTOR' | 'BUSY' | 'SUCCESS' | 'DETAIL' = 'FORM_GROUP';
  formGroup: UntypedFormGroup;
  UserUtil = UserUtil;
  error: string;
  recipientEmail: string;
  RoleType = RoleType;
  users$ = CoreService.userFacade.users$;
  selectedUser: IUser = null;
  currentRole: string;
  formControl = new UntypedFormControl();

  ngOnInit() {
    this.formGroup = CoreService.formBuilder.group({
      email: [
        null,
        {
          validators: [Validators.required, Validators.nullValidator, Validators.email]
        }
      ],
      roleType: [
        null,
        {
          validators: [Validators.required]
        }
      ]
    });

    this.formControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      if (typeof result === 'string') {
        this.search(result);
      }
    });
  }

  getOptionText(option) {
    return option ? `${option.firstName} ${option.lastName} ${option.email}` : null;
  }

  search(keyword: string) {
    if (!keyword) {
      return;
    }
    CoreService.userFacade.typeahead(keyword);
  }

  selectUser(user: IUser) {
    this.selectedUser = user;
    if (UserUtil.hasRole(user, RoleType.SUPER_ADMIN)) {
      this.currentRole = RoleType.SUPER_ADMIN;
    } else if (UserUtil.hasRole(user, RoleType.ADMIN) && !UserUtil.hasRole(user, RoleType.SUPER_ADMIN)) {
      this.currentRole = RoleType.ADMIN;
    } else if (UserUtil.hasRole(user, RoleType.AUDITOR)) {
      this.currentRole = RoleType.AUDITOR;
    } else {
      this.currentRole = RoleType.USER;
    }
    this.formGroup.controls.email.setValue(user.email);
  }

  close() {
    CoreService.store.dispatch(UiActions.closeModal());
  }

  sendSmsCode(isResendSms: boolean) {
    CoreService.transactionFacade.sendSmsCode();

    if (isResendSms) {
      CoreService.uiFacade.notification(`SMS code sent!`);
    }
  }

  changeRole() {
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }

    this.step = 'TWO_FACTOR';
  }

  confirmCode(code: string) {
    this.step = 'BUSY';
    CoreService.actions$.pipe(ofType(TransactionActions.changeRoleSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = null;
      CoreService.store.dispatch(TransactionActions.sign({ transaction: result.transaction, code, key: new Buffer(CoreService.storageService.getItem(StorageKeys.KEY), 'hex').toString() }));
    });

    CoreService.actions$.pipe(ofType(TransactionActions.signSuccess), take(1), takeUntil(this.destroy$)).subscribe(() => {
      this.step = 'SUCCESS';
      this.recipientEmail = value.email;
      CoreService.windowService.setTimeout(() => {
        this.step = 'DETAIL';
      }, 1600);
    });

    CoreService.actions$.pipe(ofType(TransactionActions.changeRoleFailure, TransactionActions.signFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors${result.apiError.error}`;
      this.step = 'FORM_GROUP';
    });

    const value = this.formGroup.value;
    CoreService.transactionFacade.changeRole(this.selectedUser.id, value.roleType, code);
  }
}
