import { Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent, CoreService, NetworkService, WindowService } from '@vesto/xplat/core';
import { take } from 'rxjs';

@Directive()
export abstract class MaintenanceBaseComponent extends BaseComponent {
  title: string;
  endpoint: string;

  constructor(protected activeRoute: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.endpoint = CoreService.windowService.maintenanceApiErrorUrl;
    this.activeRoute.queryParams.pipe(take(1)).subscribe((params) => {
      if (params && params['offline']) {
        this.title = CoreService.translateService.instant('apiErrors.OfflineOrNetworkProblems');
      } else {
        this.title = CoreService.translateService.instant('alerts.ServerMaintenance');
      }
    });
  }

  retry() {
    CoreService.networkService.retry();
  }
}
