import {Directive, OnInit} from '@angular/core';
import {BaseComponent, CoreService, StorageKeys, uiCloseModal} from '@vesto/xplat/core';
import {AccountStatus, AccountType, IAccount, IAsset, ITransaction, IUser, Symbol, UserStatus} from '@vesto/vesto';
import {UntypedFormGroup, Validators} from '@angular/forms';
import {distinctUntilChanged, filter, take, takeUntil} from 'rxjs/operators';
import {ofType} from '@ngrx/effects';
import {FeeActions, TransactionActions} from '@vesto/ngx-vesto/state';
import {FeatureService} from '../../../services/feature.service';

@Directive()
export abstract class SendReceiveModalBaseComponent extends BaseComponent implements OnInit {
  step: 'FORM_GROUP' | 'TWO_FACTOR' | 'BUSY' | 'SUCCESS' | 'TRANSACTION_DETAIL' = 'FORM_GROUP';
  accounts: IAccount[] = [];
  selectedAccount: IAccount;
  selectedAsset: IAsset;
  copied = false;
  selectedTab = 'send';
  amount: number;
  formGroup: UntypedFormGroup;
  addMemo = false;
  targetAddress = '';
  transaction: ITransaction;
  error: string;
  user: IUser;
  UserStatus = UserStatus;
  AccountType = AccountType;
  AccountStatus = AccountStatus;
  fee = 0;
  total = 0;


  ngOnInit() {
    this.selectedTab = this.selectedTab || 'send';
    CoreService.userFacade.user$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((result) => {
      this.user = result;
    });
    CoreService.accountFacade.accounts$
      .pipe(
        filter(accounts => !!accounts),
        takeUntil(this.destroy$)
      )
      .subscribe((accounts) => {
        this.accounts = [];
        accounts.forEach(account => {
          if (account.type === AccountType.WALLET || account.type === AccountType.OPERATIONAL || account.type === AccountType.PAYOUT) {
            this.accounts.push(account);
          }
        });
      });
    FeatureService.dashboardFacade.selectedAccount$
      .pipe(
        filter(selectedAccount => !!selectedAccount),
        takeUntil(this.destroy$)
      )
      .subscribe(selectedAccount => {
        if (selectedAccount.type === AccountType.WALLET || selectedAccount.type === AccountType.OPERATIONAL || selectedAccount.type === AccountType.PAYOUT) {
          this.selectedAccount = selectedAccount;
        } else {
          this.accounts.forEach(account => {
            if (account.id === this.user.defaultAccountId) {
              this.selectedAccount = account;
              return;
            }
          });
        }
        this.selectedAccount.assets.forEach((asset) => {
          if (asset.symbol === Symbol.VSUSD) {
            this.selectedAsset = asset;
            return;
          }
        });
      });

    this.formGroup = CoreService.formBuilder.group(
      {
        amount: [
          0,
          {
            validators: [Validators.required, Validators.nullValidator, Validators.min(1), Validators.max(this.selectedAccount.balance - this.fee)]
          }
        ],
        target: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        accountId: [
          this.selectedAccount ? this.selectedAccount.id : null,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        symbol: [ // TODO: Change to asset
          this.selectedAsset.symbol,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        tip: [0],
        memo: [null]
      },
      { updateOn: 'change' }
    );

    this.formGroup.controls.accountId.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((result) => {
      this.accounts.forEach((account) => {
        if (account.id === result) {
          this.selectedAccount = account;
          FeatureService.dashboardFacade.selectAccount(this.selectedAccount);
          return;
        }
      });
    });
    this.formGroup.controls.amount.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(() => {
      CoreService.feeFacade.calculate(this.formGroup.controls.amount.value);
    });
    CoreService.actions$.pipe(ofType(FeeActions.calculateSuccess), takeUntil(this.destroy$)).subscribe((result) => {
      this.fee = 0;
      result.fees.forEach((fee) => {
        this.fee += fee.fiatAmount;
      });
      this.total = parseFloat(this.formGroup.controls.amount.value) + this.fee;
    });
    CoreService.actions$.pipe(ofType(FeeActions.calculateFailure), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
    })
    if (this.selectedAccount.contracts.length > 0) {
      this.targetAddress = this.selectedAccount?.contracts[0].address;
    }
  }

  sendSmsCode(isResendSms: boolean) {
    CoreService.transactionFacade.sendSmsCode();

    if (isResendSms) {
      CoreService.uiFacade.notification(`SMS code sent!`);
    }
  }

  transfer() {
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }
    this.step = 'TWO_FACTOR';
  }

  confirmCode(code: string) {
    const value = this.formGroup.value;
    if (value.target.startsWith('0x')) {
      CoreService.transactionFacade.transferToAddress(value.accountId, value.target, value.symbol, value.amount, value.memo, code);
    } else {
      CoreService.transactionFacade.transferToEmail(value.accountId, value.target, value.symbol, value.amount, value.memo, code);
    }
    this.step = 'BUSY';

    CoreService.actions$.pipe(ofType(TransactionActions.transferToEmailSuccess, TransactionActions.transferToAddressSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      if (result.transaction.requiredSignatures > result.transaction.signatures.length) {
        CoreService.store.dispatch(TransactionActions.sign({ transaction: result.transaction, code, key: new Buffer(CoreService.storageService.getItem(StorageKeys.KEY), 'hex').toString() }));
      } else {
        this.transaction = result.transaction;
        this.step = 'SUCCESS';
        CoreService.windowService.setTimeout(() => {
          this.step = 'TRANSACTION_DETAIL';
        }, 1600);
      }
    });

    CoreService.actions$.pipe(ofType(TransactionActions.signSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.transaction = result.transaction;
      this.step = 'SUCCESS';
      CoreService.windowService.setTimeout(() => {
        this.step = 'TRANSACTION_DETAIL';
      }, 1600);
    });

    CoreService.actions$.pipe(ofType(TransactionActions.transferToEmailFailure, TransactionActions.transferToAddressFailure, TransactionActions.signFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.step = 'FORM_GROUP';
      this.error = `apiErrors.${result.apiError.error}`;
    });
  }

  getAddress(address: string) {
    this.targetAddress = address;
  }

  sendMax() {
    // TODO: how to calculate fee for this?
    this.formGroup.controls.amount.setValue(this.selectedAccount.balance - this.fee);
  }

  close() {
    uiCloseModal();
  }
}
