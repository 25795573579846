import { Injectable, Inject, ViewContainerRef, PLATFORM_ID } from '@angular/core';
import { isObject, isNativeScript } from '@vesto/xplat/utils';
import { IXPlatWindow } from '../interfaces';
import { PlatformWindowToken } from './tokens';
import { Subject } from 'rxjs';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

export interface IActionOptions {
  message: string;
  cancelButtonText: string;
  actions: Array<string>;
}

export interface IVestoCustomWindow extends IXPlatWindow {
  appVersion: string;
  maintenanceMode?: boolean;
  maintenanceApiErrorUrl?: string;
  isDialogOpen?: boolean;
  process?: any;
  require?: any;
  history?: any;
  outerWidth?: number;
  isConfirmOpen?: boolean;
  scrollY?: number;
  Intercom?: any;
  intercomSettings?: any;
  branch?: any;
  Onfido?: any;
  pageYOffset?: number;
  open?(...args: Array<any>): any;
  action?(msg: any): any;

  /* used with {N} standard alerts where needed */
  alertOldStyle?(msg: any): any;

  hideDialog?(): Promise<any>;

  /* reset state of window (often used with dialogs) */
  reset?(): any;

  encodeURIComponent?(uri: string): string;

  scrollTo?(x: number, y: number): any;

  // public StripeCheckout: any;
  oncontextmenu?(): any;
}

export type VestoWindow = Partial<Pick<Window, Exclude<keyof Window, keyof IVestoCustomWindow>>> & IVestoCustomWindow;

@Injectable()
export class WindowService implements IVestoCustomWindow {
  appVersion: string;
  // helps maintenance mode for apps
  maintenanceMode = false;
  // helps report which endpoint may have caused app to go into maintenance mode
  maintenanceApiErrorUrl;
  // useful for global interceptor handling where app version upgrades should be suggested
  needsUpdate = false;
  _suggestVersionUpgrade$: Subject<boolean>;

  private _isBrowser = false;
  private _isServer = false;
  private _isBrowserDialogOpen = false;
  public activeRoute: ActivatedRoute;

  constructor(@Inject(PlatformWindowToken) private _platformWindow: VestoWindow, @Inject(PLATFORM_ID) private platformId: any) {
    this._isBrowser = isPlatformBrowser(this.platformId);
    this._isServer = isPlatformServer(this.platformId);
  }

  get navigator() {
    return this._platformWindow.navigator;
  }

  get localStorage() {
    return this._platformWindow.localStorage;
  }

  get location() {
    return this._platformWindow.location;
  }

  get isBrowser() {
    return this._isBrowser;
  }

  get isServer() {
    return this._isServer;
  }

  get pageYOffset() {
    return this._platformWindow.pageYOffset;
  }

  get isDialogOpen() {
    // if (this.isBrowser) {
    //   return this._isBrowserDialogOpen;
    // } else {
    // only other platforms can control this since web dialogs are synchronous
    return this._platformWindow.isDialogOpen;
    // }
    return false;
  }

  get process() {
    return this._platformWindow.process;
  }

  get require() {
    return this._platformWindow.require;
  }

  get outerWidth() {
    return this._platformWindow.outerWidth;
  }

  get suggestVersionUpgrade$() {
    if (!this._suggestVersionUpgrade$) {
      this._suggestVersionUpgrade$ = new Subject();
    }
    return this._suggestVersionUpgrade$;
  }

  get Onfido() {
    return this._platformWindow.Onfido;
  }

  scroll(x: number, y: number) {
    this._platformWindow.scroll(x, y);
  }

  open(...args: any[]) {
    this._platformWindow.open(args);
  }

  alert(msg: any, actions?: Array<any>): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isServer) {
        resolve();
      } else {
        // if (this.isBrowser) {
        //   this._isBrowserDialogOpen = true;
        // }
        const result: any = this._platformWindow.alert(msg, actions);
        if (isObject(result) && result.then) {
          // console.log('WindowService -- using result.then promise');
          result.then(resolve, reject);
        } else {
          // if (this.isBrowser) {
          //   this._isBrowserDialogOpen = false;
          // }
          resolve();
        }
      }
    });
  }

  confirm(msg: any, action?: Function /* used for fancyalerts on mobile*/, okText?: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.isServer) {
        resolve(false);
      } else {
        // if (this.isBrowser) {
        //   this._isBrowserDialogOpen = true;
        // }
        const result: any = (<any>this._platformWindow).confirm(msg, isNativeScript() ? action : undefined, isNativeScript() ? okText : undefined);
        if (isObject(result) && result.then) {
          result.then(resolve, reject);
        } else if (result) {
          if (action) {
            action();
          }
          resolve(result);
        } else {
          resolve(false);
        }
        // if (this.isBrowser) {
        //   this._isBrowserDialogOpen = false;
        // }
      }
    });
  }

  hideDialog(): Promise<void> {
    return new Promise((resolve) => {
      if (this._platformWindow.hideDialog) {
        this._platformWindow.hideDialog().then(() => {
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  action(options: IActionOptions): Promise<void> {
    return new Promise((resolve, reject) => {
      const result: any = (<any>this._platformWindow).action(options);
      if (isObject(result) && result.then) {
        result.then(resolve, reject);
      } else if (result) {
        resolve();
      } else {
        reject();
      }
    });
  }

  scrollTo(x: number, y: number) {
    if (!this.isServer) {
      this._platformWindow.scrollTo(x, y);
    }
  }

  public setTimeout(handler: (...args: any[]) => void, timeout?: number): number {
    return this._platformWindow.setTimeout(handler, timeout);
  }

  public clearTimeout(timeoutId: number): void {
    return this._platformWindow.clearTimeout(timeoutId);
  }

  public setInterval(handler: (...args: any[]) => void, ms?: number, ...args: any[]): number {
    return this._platformWindow.setInterval(handler, ms, args);
  }

  public clearInterval(intervalId: number): void {
    return this._platformWindow.clearInterval(intervalId);
  }
}

/**
 * For SSR (Universal and node environments)
 */
export class VestoServerWindow implements IVestoCustomWindow {
  appVersion = 'web';
  navigator: any = {
    userAgent: ''
  };
  location: any = {
    href: '',
    pathname: '',
    search: ''
  };
  history: any = {};
  scrollY = 0;
  localStorage: any = {
    getItem: (key: string): any => {
      return null;
    },
    setItem: (key: string, value: any) => {},
    removeItem: (key: string) => {},
    clear: () => {}
  };
  outerWidth = 0;
  intercomSettings: any = null;
  branch: any = {
    init: () => {}
  };
  Intercom: any = () => {};

  open(...args: Array<any>): any {}

  alert(msg: any) {
    return Promise.resolve();
  }

  confirm(msg: any) {
    return Promise.resolve();
  }

  reset() {} /* reset state of window (often used with dialogs) */
  encodeURIComponent(uri: string): string {
    return '';
  }

  scroll(x: number, y: number) {}

  scrollTo(x: number, y: number) {}

  setTimeout(handler: (...args: any[]) => void, timeout?: number) {
    if (handler) {
      handler();
    }
    return 0;
  }

  clearTimeout(timeoutId: number) {}

  setInterval(handler: (...args: any[]) => void, ms?: number, ...args: any[]) {
    if (handler) {
      handler();
    }
    return 0;
  }

  clearInterval(intervalId: number) {}
}
