import { Component, OnInit } from '@angular/core';
import { CoreService } from '@vesto/xplat/core';
import { filter, takeUntil } from 'rxjs/operators';
import { Intercom } from 'ng-intercom';
import {DashboardBaseComponent, DashboardFacade, FeatureService} from '@vesto/xplat/features';
import { IAccount, IUser, UserStatus } from '@vesto/vesto';

@Component({
  selector: 'gbl-dashboard',
  templateUrl: 'dashboard.component.html'
})
export class DashboardComponent extends DashboardBaseComponent implements OnInit {
  selectedAccount: IAccount;
  user: IUser;
  adminUser: IUser;
  UserStatus = UserStatus;

  constructor(public intercom: Intercom) {
    super();
  }

  ngOnInit() {
    CoreService.userFacade.user$.pipe(
      filter(user => !!user),
      takeUntil(this.destroy$)
    ).subscribe((user) => {
      this.user = user;
      this.intercom.boot({
        name: `${this.user.firstName} ${this.user.lastName}`,
        email: this.user.email,
        user_id: this.user.id,
        widget: {
          activator: '#intercom'
        }
      });
    });
    CoreService.userFacade.adminUser$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.adminUser = result;
    });
    FeatureService.dashboardFacade.selectedAccount$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.selectedAccount = result;
    });
  }

  openOrGoToVerifyIdentity() {
    FeatureService.dashboardFacade.openOrGoToVerifyIdentity();
  }

  clearUser() {
    CoreService.userFacade.selectUser(null);
    FeatureService.dashboardFacade.refresh();
  }

  kycClearConsider() {
    FeatureService.dashboardFacade.openOrGoToKycClearConsider();
  }

  suspendOrActivate() {
    FeatureService.dashboardFacade.openOrGoToSuspendActivate();
  }

  increaseLimits() {
    FeatureService.dashboardFacade.openSetDailyLimits();
  }
}
