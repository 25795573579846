import { createReducer, on, Action } from '@ngrx/store';
import { DashboardActions } from './dashboard.actions';
import { DashboardState } from './dashboard.state';
import {
  AccountActions,
  InstitutionActions,
  BankAccountActions, UserActions
} from '@vesto/ngx-vesto/state';

const reducer = createReducer(
  DashboardState.initialState,
  on(DashboardActions.init, (state) => state),
  on(DashboardActions.reset, UserActions.signOut, (state) => {
    return DashboardState.initialState;
  }),
  on(DashboardActions.selectSection, (state, { section }) => {
    return {
      ...state,
      selectedSection: section
    };
  }),
  on(DashboardActions.selectAccount, (state, { account }) => {
    return {
      ...state,
      selectedAccount: account
    };
  }),
  on(DashboardActions.selectSectionAccount, (state, { sectionAccount }) => {
    let sectionAccounts = state.sectionAccounts
      .map(_sectionAccount => (_sectionAccount.isAdminView === sectionAccount.isAdminView ? { ..._sectionAccount, selected: false} : { ..._sectionAccount }))
      .map(_sectionAccount => (_sectionAccount.section === sectionAccount.section ? {...sectionAccount} : { ..._sectionAccount }));
    return {
      ...state,
      sectionAccounts: sectionAccounts.find(_sectionAccount => _sectionAccount.section === sectionAccount.section) ? [...sectionAccounts] : [sectionAccount, ...sectionAccounts]
    };
  }),
  on(DashboardActions.selectAccountHeader, (state, { accountHeader }) => {
    return {
      ...state,
      selectedAccountHeader: accountHeader
    };
  }),
  on(DashboardActions.selectSymbol, (state, { symbol }) => {
    return {
      ...state,
      selectedSymbol: symbol
    };
  }),
  on(DashboardActions.selectMerchant, (state, { merchant }) => {
    return {
      ...state,
      selectedMerchant: {...merchant}
    };
  }),
  on(DashboardActions.setIsAdminView, (state, {isAdminView}) => {
    return {
      ...state,
      isAdminView
    };
  }),
  on(AccountActions.set, AccountActions.findSuccess, (state, { account }) => {
    return {
      ...state,
      selectedAccount: account.id === state.selectedAccount.id ? account : state.selectedAccount
    };
  }),
  on(AccountActions.setContract, (state, { contract }) => {
    const account = state.selectedAccount;
    if (!!account) {
      account.contracts = account.contracts.map(_contract => (_contract.id === contract.id ? {...contract} : {..._contract}));
      let shouldAdd = !account.contracts.find(_contract => _contract.id === contract.id) && contract.accountId === account.id;
      account.contracts = shouldAdd ? [contract, ...account.contracts] : [...account.contracts];
    }

    return {
      ...state,
      selectedAccount: {...account}
    };
  }),
  on(BankAccountActions.findByUserSuccess, (state, { bankAccounts }) => {
    const selectedBankAccount = bankAccounts.find(_bankAccount => _bankAccount.default);

    return {
      ...state,
      selectedBankAccount: {...selectedBankAccount}
    };
  }),
  on(BankAccountActions.setDefaultSuccess, (state, { bankAccount }) => {
    return {
      ...state,
      selectedBankAccount: {...bankAccount}
    };
  }),
  on(DashboardActions.selectBankAccount, (state, { bankAccount }) => {
    return {
      ...state,
      selectedBankAccount: {...bankAccount}
    };
  }),
  on(DashboardActions.selectInstitution, (state, { institution }) => {
    return {
      ...state,
      selectedInstitution: institution
    };
  }),
  on(DashboardActions.selectToken, (state, { token }) => {
    return {
      ...state,
      selectedToken: token
    };
  }),
  on(DashboardActions.selectTransaction, (state, { transaction }) => {
    return {
      ...state,
      selectedTransaction: transaction
    };
  }),
  on(InstitutionActions.findByUserSuccess, (state, { institution }) => {
    let selectedToken = state.selectedToken;
    if (!selectedToken && institution && institution.tokens.length > 0) {
      selectedToken = institution.tokens[0];
    }

    return {
      ...state,
      selectedToken: selectedToken
    };
  }),
  on(InstitutionActions.findAllSuccess, (state, { institutions }) => {
    let selectedInstitution = state.selectedInstitution;
    for (const institution of institutions) {
      if (selectedInstitution && selectedInstitution.id === institution.id) {
        selectedInstitution = institution;
        break;
      }
    }
    if (!selectedInstitution && institutions.length > 0) {
      selectedInstitution = institutions[0];
    }
    return {
      ...state,
      selectedInstitution: selectedInstitution
    };
  }),
  on(DashboardActions.selectPagination, DashboardActions.paginate, (state, { pagination }) => {
    let paginations = state.paginations;
    if (!paginations.find(_pagination => _pagination.id === pagination.id)) {
      paginations.push(pagination);
    } else {
      paginations = paginations.map(_pagination => (_pagination.id === pagination.id ? { ...pagination } : { ..._pagination }));
    }

    return {
      ...state,
      selectedPagination: {...pagination},
      paginations: [...paginations]
    };
  }),

  on(DashboardActions.filter, (state, { filter }) => {
    return {
      ...state,
      filter: {...filter}
    };
  }),
);

export function dashboardReducer(state: DashboardState.IState | undefined, action: Action) {
  return reducer(state, action);
}
