import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {VestoState} from '@vesto/ngx-vesto/state';
import {FeeActions} from './fee.actions';

@Injectable({
  providedIn: 'root'
})
export class FeeFacade {

  constructor(private store: Store<VestoState.IState>) {
  }

  calculate(
    amount: number,
  ) {
    this.store.dispatch(FeeActions.calculate({amount}));
  }
}
