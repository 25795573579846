import { Component } from '@angular/core';
import { AlertBaseComponent } from '@vesto/xplat/features';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'gbl-alert',
  templateUrl: 'alert.component.html',
  animations: [
    trigger('expandCollapse', [
      //element being added into DOM.
      transition(':enter', [
        animate(
          '300ms ease-in-out',
          style({
            height: '*',
            overflow: 'hidden'
          })
        )
      ]),
      //element being removed from DOM.
      transition(':leave', [
        animate(
          '300ms ease-in-out',
          style({
            height: '0px',
            overflow: 'hidden'
          })
        )
      ])
    ])
  ]
})
export class AlertComponent extends AlertBaseComponent {
  constructor() {
    super();
  }
}
