import {Component, OnInit} from '@angular/core';
import {BaseComponent, CoreService, uiCloseModal} from '@vesto/xplat/core';
import {UntypedFormGroup, Validators} from '@angular/forms';
import {filter, take, takeUntil} from 'rxjs/operators';
import {IInstitution, IMerchant, IUser, RoleType, UserUtil} from '@vesto/vesto';
import {ofType} from "@ngrx/effects";
import {UserActions} from "@vesto/ngx-vesto/state";

@Component({
  selector: 'gbl-invite-user-modal',
  templateUrl: './invite-user-modal.component.html'
})
export class InviteUserModalComponent extends BaseComponent implements OnInit {
  step : 'FORM_GROUP' | 'BUSY' | 'SUCCESS' | 'DETAIL' = 'FORM_GROUP';
  formGroup: UntypedFormGroup;
  institutions: IInstitution[] = [];
  merchants: IMerchant[] = [];
  UserUtil = UserUtil;
  RoleType = RoleType;
  error: string;
  user: IUser;
  inviteEmail: string;
  inviteToMerchant = false;

  ngOnInit() {
    this.formGroup = CoreService.formBuilder.group({
      email: [
        null,
        {
          validators: [Validators.required, Validators.nullValidator, Validators.email]
        }
      ],
      institutionId: [
        null
      ],
      merchantId: [null]
    });

    CoreService.userFacade.user$
      .pipe(
        takeUntil(this.destroy$),
        filter((user) => !!user)
      )
      .subscribe((user) => {
        this.user = user;
        this.formGroup.controls.institutionId.setValue(this.user.institutionId);
      });
    CoreService.institutionFacade.institutions$
      .pipe(
        takeUntil(this.destroy$),
        filter((institution) => !!institution)
      )
      .subscribe((result) => {
        this.institutions = result;
      });
    CoreService.merchantFacade.merchants$
      .pipe(
        takeUntil(this.destroy$),
        filter((merchant) => !!merchant)
      )
      .subscribe((result) => {
        this.merchants = result
      });
  }

  inviteUser() {
    this.step = 'BUSY';
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      this.step = 'FORM_GROUP';
      return;
    }

    CoreService.actions$.pipe(ofType(UserActions.inviteSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.step = 'SUCCESS';
      CoreService.windowService.setTimeout(() => {
        this.step = 'DETAIL';
      }, 1600);
    });
    CoreService.actions$.pipe(ofType(UserActions.inviteFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.step = 'FORM_GROUP';
      this.error = `apiErrors.${result.apiError.error}`;
    });

    const value = this.formGroup.value;
    this.inviteEmail = this.formGroup.value.email;
    CoreService.userFacade.invite(value.institutionId, value.email);
  }

  close() {
    uiCloseModal();
  }
}
