import {Component, OnInit} from "@angular/core";
import {BaseComponent, CoreService} from "@vesto/xplat/core";
import {FeatureService} from "@vesto/xplat/features";
import {filter, take, takeUntil} from "rxjs/operators";
import {IUser, RoleType, UserUtil} from "@vesto/vesto";
import {ofType} from "@ngrx/effects";
import {MerchantActions} from "@vesto/ngx-vesto/state";

@Component({
  selector: 'gbl-users',
  templateUrl: 'users.component.html'
})

export class UsersComponent extends BaseComponent implements OnInit {
  user: IUser;
  users: IUser[] = [];
  UserUtil = UserUtil;
  RoleType = RoleType;

  ngOnInit() {
    CoreService.userFacade.user$
      .pipe(
        filter((user) => !!user),
        takeUntil(this.destroy$)
      )
      .subscribe((user) => {
        this.user = user
      });

    CoreService.userFacade.findByMerchant(this.user.merchant.id);

    CoreService.userFacade.users$
      .pipe(
        filter((users) => !!users),
        takeUntil(this.destroy$)
      )
      .subscribe((users) => {
        this.users = users;
      });
  }

  openInvite() {
    FeatureService.dashboardFacade.openOrGoToInviteToMerchant();
  }

  removeUser(userId: string) {
    CoreService.actions$.pipe(ofType(MerchantActions.removeUserSuccess), take(1), takeUntil(this.destroy$)).subscribe(() => {
      CoreService.uiFacade.notification('User Removed');
    });
    CoreService.actions$.pipe(ofType(MerchantActions.removeUserFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      CoreService.uiFacade.notification(`apiErrors.${result.apiError.error}`);
    });

    CoreService.merchantFacade.removeUser(this.user.merchant.id, userId);
  }
}
