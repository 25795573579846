import { Injectable } from '@angular/core';
import { DashboardFacade, SignInFacade } from '@vesto/xplat/features';
import { SignUpFacade } from '@vesto/ngx-vesto/state';

export class FeatureService {
  static dashboardFacade: DashboardFacade;
  static signUpFacade: SignUpFacade;
  static signInFacade: SignInFacade;
}

@Injectable({
  providedIn: 'root'
})
export class FeatureServices {
  constructor(private dashboardFacade: DashboardFacade, private signUpFacade: SignUpFacade, private signInFacade: SignInFacade) {
    FeatureService.dashboardFacade = dashboardFacade;
    FeatureService.signUpFacade = signUpFacade;
    FeatureService.signInFacade = signInFacade;
  }
}
