export interface IContentPage {
  /**
   * Groups({"cms:read"})
   * Read-only
   */
  title?: string;
  /**
   * Groups({"cms:read"})
   * Read-only
   */
  subTitle?: string;
  /**
   * Groups({"cms:read"})
   * Read-only
   */
  seoTitle?: string;
  /**
   * Groups({"cms:read"})
   * Read-only
   */
  seoDescription?: string;
  /**
   * Groups({"cms:read"})
   * Read-only
   */
  content?: string;
  /**
   * Groups({"cms:read"})
   */
  id?: number;
  /**
   * Groups({"cms:read"})
   */
  slug?: string;
  /**
   * The name of the template to use to render this page.
   *
   * Groups({"cms:read"})
   */
  template?: string;
}
