import { IEnvironment } from '../interfaces/environment.interface';
import { IHttpClient } from '../interfaces/http.interface';
import { BaseApi } from './base-api';
import { MerchantFeeType } from '../enums/merchant-fee-type.enum';

export class MerchantsApi extends BaseApi {
  constructor(protected environment: IEnvironment, protected httpClient: IHttpClient) {
    super(environment);
  }

  findByInstitution(
    institutionId: string
  ) {
    return this.httpClient.get(this.formatUrl(`/v${this.environment.vesto.version}/merchants/find-by-institution/${institutionId}`), { headers: this.getHeaders() });
  }

  create(
    institutionId: string,
    name: string,
    address: string,
    feeType: MerchantFeeType,
    feeFixed: number,
    feePercentage: number,
    email: string
  ) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/merchants/create/${institutionId}`),
      {
        name,
        address,
        feeType,
        feeFixed,
        feePercentage,
        email
      },
      {
        headers: this.getHeaders()
      });
  }

  changeSettings(
    id: string,
    name: string,
    address: string,
    feeType: MerchantFeeType,
    feeFixed: number,
    feePercentage: number
  ) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/merchants/change-settings/${id}`),
      {
        name,
        address,
        feeType,
        feeFixed,
        feePercentage,
      },
      {
        headers: this.getHeaders()
      });
  }

  addUser(
    merchantId: string,
    userId: string
  ) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/merchants/add-user/${merchantId}/${userId}`),
      null,
      {
        headers: this.getHeaders()
      });
  }

  removeUser(
    merchantId: string,
    userId: string
  ) {
    return this.httpClient.delete(this.formatUrl(`/v${this.environment.vesto.version}/merchants/remove-user/${merchantId}/${userId}`),
      {
        headers: this.getHeaders()
      });
  }

  promoteUser(
    merchantId: string,
    userId: string
  ) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/merchants/promote-user/${merchantId}/${userId}`),
      null,
      {
        headers: this.getHeaders()
      });
  }

  demoteUser(
    merchantId: string,
    userId: string
  ) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/merchants/demote-user/${merchantId}/${userId}`),
      null,
      {
        headers: this.getHeaders()
      });
  }
}
