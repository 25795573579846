import { IEnvironment } from '../interfaces/environment.interface';
import { IHttpClient } from '../interfaces/http.interface';
import { BaseApi } from './base-api';

export class SnapShotsApi extends BaseApi {
  constructor(protected environment: IEnvironment, protected httpClient: IHttpClient) {
    super(environment);
  }

  findByAccount(accountId: string) {
    return this.httpClient.get(this.formatUrl(`/v${this.environment.vesto.version}/snap-shots/find-by-account/${accountId}`), { headers: this.getHeaders() });
  }

  findByUser(userId: string) {
    return this.httpClient.get(this.formatUrl(`/v${this.environment.vesto.version}/snap-shots/find-by-user/${userId}`), { headers: this.getHeaders() });
  }

  findByInstitution(institutionId: string) {
    return this.httpClient.get(this.formatUrl(`/v${this.environment.vesto.version}/snap-shots/find-by-institution/${institutionId}`), { headers: this.getHeaders() });
  }

  findBySystem() {
    return this.httpClient.get(this.formatUrl(`/v${this.environment.vesto.version}/snap-shots/find-by-system`), { headers: this.getHeaders() });
  }
}
