import {Component, OnInit} from "@angular/core";
import {BaseComponent, CoreService, WindowService} from "@vesto/xplat/core";
import {IMerchant, IStore, IUser, MerchantFeeType, RoleType, UserUtil} from "@vesto/vesto";
import {filter, take, takeUntil} from "rxjs/operators";
import {FeatureService} from "@vesto/xplat/features";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ofType} from "@ngrx/effects";
import {MerchantActions, StoreActions} from "@vesto/ngx-vesto/state";

@Component({
  selector: 'gbl-merchants',
  templateUrl: './merchants.component.html',
  animations: [
    trigger('expandCollapseDetails', [
      state(
        'void',
        style({
          height: '0px',
          overflow: 'hidden'
        })
      ),
      //element being added into DOM.
      transition(':enter', [
        animate(
          '500ms ease-in-out',
          style({
            height: '*',
            overflow: 'hidden'
          })
        )
      ]),
      //element being removed from DOM.
      transition(':leave', [
        animate(
          '500ms ease-in-out',
          style({
            height: '0px',
            overflow: 'hidden'
          })
        )
      ])
    ])
  ]
})

export class MerchantsComponent extends BaseComponent implements OnInit {
  merchants: IMerchant[] = [];
  users: IUser[] = [];
  stores: IStore[] = [];
  selectedMerchant: IMerchant;
  UserUtil = UserUtil;
  RoleType = RoleType;
  MerchantFeeType = MerchantFeeType;
  error: string;

  ngOnInit() {
    CoreService.merchantFacade.merchants$
      .pipe(
        filter((merchants) => !!merchants),
        takeUntil(this.destroy$)
      )
      .subscribe((merchants) => {
        this.merchants = merchants;
    });
    CoreService.userFacade.users$
      .pipe(
        filter((users) => !!users),
        takeUntil(this.destroy$)
      )
      .subscribe((users) => {
        this.users = users;
    });
    FeatureService.dashboardFacade.selectedMerchant$
      .pipe(
        filter((selectedMerchant) => !!selectedMerchant),
        takeUntil(this.destroy$)
      )
      .subscribe((selectedMerchant) => {
        this.selectedMerchant = selectedMerchant
    });
    CoreService.storeFacade.stores$
      .pipe(
        filter(store => !!store),
        takeUntil(this.destroy$)
      )
      .subscribe((stores) => {
        this.stores = stores;
        }
      );
  }

  selectMerchant(merchant: IMerchant) {
    FeatureService.dashboardFacade.selectMerchant(merchant);
  }

  createMerchant() {
    FeatureService.dashboardFacade.openOrGoToMerchant('create');
  }

  changeSettings(merchant: IMerchant) {
    FeatureService.dashboardFacade.openOrGoToMerchant(null, merchant);
  }

  promoteUser(userId: string) {
    CoreService.actions$.pipe(ofType(MerchantActions.promoteUserSuccess), take(1), takeUntil(this.destroy$)).subscribe(() => {
      CoreService.uiFacade.notification('User Promoted!');
    });
    CoreService.actions$.pipe(ofType(MerchantActions.promoteUserFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      CoreService.uiFacade.notification(`apiErrors.${result.apiError.error}`);
    });

    CoreService.merchantFacade.promoteUser(this.selectedMerchant.id, userId);
  }

  demoteUser(userId: string) {
    CoreService.actions$.pipe(ofType(MerchantActions.demoteUserSuccess), take(1), takeUntil(this.destroy$)).subscribe(() => {
      CoreService.uiFacade.notification('User Demoted');
    });
    CoreService.actions$.pipe(ofType(MerchantActions.demoteUserFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
      CoreService.uiFacade.notification(this.error);
    });

    CoreService.merchantFacade.demoteUser(this.selectedMerchant.id, userId);
  }

  removeUser(userId: string) {
    CoreService.actions$.pipe(ofType(MerchantActions.removeUserSuccess), take(1), takeUntil(this.destroy$)).subscribe(() => {
      CoreService.uiFacade.notification('User Removed');
    });
    CoreService.actions$.pipe(ofType(MerchantActions.removeUserFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
      CoreService.uiFacade.notification(this.error);
    });

    CoreService.merchantFacade.removeUser(this.selectedMerchant.id, userId);
  }

  openCreateStore() {
    FeatureService.dashboardFacade.openOrGoToStore(this.selectedMerchant);
  }

  openChangeStore(store: IStore) {
    FeatureService.dashboardFacade.openOrGoToStore(this.selectedMerchant, store);
  }

  removeStore(id: string) {
    // CoreService.actions$.pipe(ofType(StoreActions.removeSuccess), take(1)).subscribe((result) => {
    //   CoreService.uiFacade.notification(`Successfully removed ${result.store.name}`);
    // });
    // CoreService.actions$.pipe(ofType(StoreActions.removeFailure), take(1)).subscribe(() => {
    //   CoreService.uiFacade.notification('An error occurred, please try again');
    // });
    //
    // CoreService.storeFacade.remove(id);
  }

  openInviteToMerchant() {
    FeatureService.dashboardFacade.openOrGoToInviteToMerchant();
  }
}
