<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <h3 class="section-number"><i class="fal fa-mobile"></i> Change Mobile</h3>
</div>
<mat-dialog-content>
  <ng-container [ngSwitch]="step">
    <section *ngSwitchCase="'FORM_GROUP'">
      <div class="text-sm mb-3">
        Enter your new mobile number to receive a code:
      </div>

      <form [formGroup]="formGroup" (ngSubmit)="changeMobile()">
        <div class="flex mb-3 items-end">
          <div class="flex-grow">
            <div class="vesto-input w-full mt-2">
              <label for="mobile">Mobile</label>
              <gbl-input mask="mobile" id="mobile" formControlName="mobile"></gbl-input>
            </div>
          </div>

          <gbl-alert [type]="error" [message]="error"></gbl-alert>

          <button class="button button-primary ml-3" type="submit">Submit</button>
        </div>
      </form>
    </section>

    <section *ngSwitchCase="'TWO_FACTOR'">
      <gbl-two-factor (sendSmsCode)="sendSmsCode($event)" (code)="confirmCode($event)"></gbl-two-factor>
      <gbl-alert [type]="error" [message]="error"></gbl-alert>
    </section>

    <section *ngSwitchCase="'SUCCESS'" class="slide-in">
      <p class="text-center">Success!</p>
      <div class="p-4 bg-trans-blue rounded-lg flex items-center mt-2">
        <i class="fal fa-check-circle text-3xl mr-4"></i>
        <span class="text-sm">You changed your mobile number to {{newMobile}}</span>
      </div>
      <button class="button button-primary mt-4 mx-auto w-full" (click)="close()">Close</button>
    </section>
  </ng-container>
</mat-dialog-content>
