import { IToken } from './token.interface';

export interface IAsset {
  id: string;
  name: string;
  symbol: string;
  fiatBalance: number;
  balance: number;
  underlyingBalance: number;
  interest: number;
  updated: string;
  created: string;
  // Joins.
  tokens: IToken[];
  // Transients.
  apy: number;
}
