import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import {AccountHeader, DashboardState, IPagination, ISectionAccount, Section} from './dashboard.state';
import {
  IAccount,
  IToken,
  IInstitution,
  IBankAccount,
  ITransaction,
  IAdditionalUser, IGasTank, Blockchain, IMerchant, IContract, TransactionStatus, IStore
} from '@vesto/vesto';
import {DashboardActions} from './dashboard.actions';
import { BankAccountActions } from '@vesto/ngx-vesto/state';
import {CoreService} from '@vesto/xplat/core';
import IFilter = DashboardState.IFilter;

@Injectable({
  providedIn: 'root'
})
export class DashboardFacade {
  selectedSection$: Observable<Section> = this.store.pipe(select(DashboardState.selectedSection));
  selectedAccount$: Observable<IAccount> = this.store.pipe(select(DashboardState.selectedAccount));
  sectionAccounts$: Observable<ISectionAccount[]> = this.store.pipe(select(DashboardState.sectionAccounts));
  selectedAccountHeader$: Observable<AccountHeader> = this.store.pipe(select(DashboardState.selectedAccountHeader));
  selectedSymbol$: Observable<string> = this.store.pipe(select(DashboardState.selectedSymbol));
  selectedBankAccount$: Observable<IBankAccount> = this.store.pipe(select(DashboardState.selectedBankAccount));
  selectedInstitution$: Observable<IInstitution> = this.store.pipe(select(DashboardState.selectedInstitution));
  selectedToken$: Observable<any> = this.store.pipe(select(DashboardState.selectedToken));
  selectedTransaction$: Observable<ITransaction> = this.store.pipe(select(DashboardState.selectedTransaction));
  paginations$: Observable<IPagination[]> = this.store.pipe(select(DashboardState.paginations));
  selectedPagination$: Observable<IPagination> = this.store.pipe(select(DashboardState.selectedPagination));
  selectedMerchant$: Observable<IMerchant> = this.store.pipe(select(DashboardState.selectedMerchant));
  isAdminView$: Observable<boolean> = this.store.pipe(select(DashboardState.isAdminView));
  filter$: Observable<IFilter> = this.store.pipe(select(DashboardState.filter));

  constructor(private store: Store<DashboardState.IState>) {}

  refresh() {
    this.store.dispatch(DashboardActions.refresh());
  }

  selectSection(section: Section) {
    this.store.dispatch(DashboardActions.selectSection({ section }));
  }

  selectAccount(account: IAccount) {
    this.store.dispatch(DashboardActions.selectAccount({ account }));
  }

  selectAccountHeader(accountHeader: AccountHeader) {
    this.store.dispatch(DashboardActions.selectAccountHeader({ accountHeader }));
  }

  selectSymbol(symbol: string) {
    this.store.dispatch(DashboardActions.selectSymbol({ symbol }));
  }

  selectInstitution(institution: IInstitution) {
    this.store.dispatch(DashboardActions.selectInstitution({ institution }));
  }

  selectToken(token: IToken) {
    this.store.dispatch(DashboardActions.selectToken({ token }));
  }

  selectTransaction(transaction: ITransaction) {
    this.store.dispatch(DashboardActions.selectTransaction({ transaction }));
  }

  selectMerchant(merchant: IMerchant) {
    this.store.dispatch(DashboardActions.selectMerchant({ merchant }));
  }

  paginate(
    pagination: IPagination
  ) {
    this.store.dispatch(DashboardActions.paginate({pagination}));
  }

  openOrGoToVerifyIdentity() {
    this.store.dispatch(DashboardActions.openOrGoToVerifyIdentity());
  }

  openOrGoToSetSettleLimits() {
    this.store.dispatch(DashboardActions.openOrGoToSetSettleLimits());
  }

  openOrGoToCreateAndDeployTokens(institutionId: string) {
    this.store.dispatch(DashboardActions.openOrGoToCreateAndDeployTokens({institutionId}));
  }

  openPlaid(inModal?: boolean) {
    // TODO: Might want to take another look at this.
    if (CoreService.platformDeviceInfo.deviceDetails === 'browser') {
      this.store.dispatch(DashboardActions.openPlaid({}));
    } else {
      this.store.dispatch(BankAccountActions.createLinkToken({ extras: { inModal } }));
    }
  }

  openOrGoToBuySell(selectedTab?: string) {
    this.store.dispatch(DashboardActions.openOrGoToBuySell({ selectedTab }));
  }

  openOrGoToSendReceive(selectedTab?: string) {
    this.store.dispatch(DashboardActions.openOrGoToSendReceive({ selectedTab }));
  }

  openOrGoToTransferGas(blockchain: Blockchain, gasTankId: string) {
    this.store.dispatch(DashboardActions.openOrGoToTransferGas({ blockchain, gasTankId }));
  }

  openOrGoToCreateAccount() {
    this.store.dispatch(DashboardActions.openOrGoToCreateAccount());
  }

  openOrGoToAddPaymentMethod() {
    this.store.dispatch(DashboardActions.openOrGoToAddbankAccount());
  }

  openOrGoToAddUser() {
    this.store.dispatch(DashboardActions.openOrGoToAddUser());
  }

  selectLinkAccount(bankAccount: IBankAccount) {
    this.store.dispatch(DashboardActions.selectBankAccount({ bankAccount }));
  }

  openOrGoToTransactionDetail(transaction: ITransaction) {
    this.store.dispatch(DashboardActions.openOrGoToTransactionDetail({ transaction }));
  }

  openOrGoToChangePersonalInfo() {
    this.store.dispatch(DashboardActions.openOrGoToChangePersonalInfo());
  }

  openOrGoToChangeEmail() {
    this.store.dispatch(DashboardActions.openOrGoToChangeEmail());
  }

  openOrGoToChangeMobile() {
    this.store.dispatch(DashboardActions.openOrGoToChangeMobile());
  }

  openOrGoToChangePassword() {
    this.store.dispatch(DashboardActions.openOrGoToChangePassword());
  }

  verifyIdentity(address: string, googlePlaceId: string) {
    this.store.dispatch(DashboardActions.verifyIdentity({ address: address, googlePlaceId }));
  }

  openOrGoToUserSearch() {
    this.store.dispatch(DashboardActions.openOrGoToUserSearch());
  }

  openOrGoToKycClearConsider() {
    this.store.dispatch(DashboardActions.openOrGoToKycClearConsider());
  }

  openOrGoToSuspendActivate() {
    this.store.dispatch(DashboardActions.openOrGoToSuspendActivate());
  }

  openSetDailyLimits() {
    this.store.dispatch(DashboardActions.openSetDailyLimits());
  }

  openOrGoToChangeAml() {
    this.store.dispatch(DashboardActions.openOrGoToChangeAml());
  }

  openOrGoToCreateInstitution() {
    this.store.dispatch(DashboardActions.openOrGoToCreateInstitution());
  }

  openOrGoToInviteUser() {
    this.store.dispatch(DashboardActions.openOrGoToInviteUser());
  }

  openOrGoToInviteToMerchant() {
    this.store.dispatch(DashboardActions.openOrGoToInviteToMerchant());
  }

  openOrGoToElement() {
    this.store.dispatch(DashboardActions.openOrGoToElement());
  }

  openOrGoToMerchant(action?: string, merchant?: IMerchant) {
    this.store.dispatch(DashboardActions.openOrGoToMerchant({action, merchant}));
  }

  openOrGoToStore(merchant: IMerchant, store?: IStore) {
    this.store.dispatch(DashboardActions.openOrGoToStore({merchant, store}));
  }

  openOrGoToGasTank(
    blockchain: Blockchain,
    gasTank: IGasTank
  ) {
    this.store.dispatch(DashboardActions.openOrGoToChangeGasTank({blockchain, gasTank}));
  }

  openOrGoToInstitution(
    action?: string,
    institution?: IInstitution
  ) {
    this.store.dispatch(DashboardActions.openOrGoToInstitution({action, institution}));
  }

  openOrGoToAddYearnVault() {
    this.store.dispatch(DashboardActions.openOrGoToAddYearnVault());
  }

  openOrGoToSetSupplyLimit() {
    this.store.dispatch(DashboardActions.openOrGoToSetSupplyLimit());
  }

  openChangeRole() {
    this.store.dispatch(DashboardActions.openChangeRole());
  }

  openOrGoToSwapSigningAddress() {
    this.store.dispatch(DashboardActions.openOrGoToSwapSigningAddress());
  }

  openFiatBalances() {
    this.store.dispatch(DashboardActions.openFiatBalances());
  }

  openAdditionalUsers() {
    this.store.dispatch(DashboardActions.openAdditionalUsers());
  }

  openOrGoToChangeTokenApy(token: IToken) {
    this.store.dispatch(DashboardActions.openOrGoToChangeTokenApy({ token }));
  }

  openOrGoToChooseBlockchain(data: any) {
    this.store.dispatch(DashboardActions.openOrGoToChooseBlockchain({ data }));
  }

  openOrGoToChooseToken(data: any) {
    this.store.dispatch(DashboardActions.openOrGoToChooseToken({ data }));
  }

  openOrGoToChoosePlaidAccount(bankAccounts: IBankAccount[]) {
    this.store.dispatch(DashboardActions.openOrGoToChoosePlaidAccount({ bankAccounts }));
  }

  openOrGoToRequestReport(title: string, documentName: string) {
    this.store.dispatch(DashboardActions.openOrGoToRequestReport({ title, documentName }));
  }

  openOrGoToTwoFactor(action: string, transaction?: ITransaction, token?: IToken, additionalUser?: IAdditionalUser, contract?: IContract) {
    this.store.dispatch(DashboardActions.openOrGoToTwoFactor({ action, transaction, token, additionalUser, contract}));
  }

  closeModal() {
    this.store.dispatch(DashboardActions.closeModal());
  }

  setIsAdminView(isAdminView: boolean) {
    this.store.dispatch(DashboardActions.setIsAdminView({isAdminView}));
  }

  filter(filter: IFilter) {
    this.store.dispatch(DashboardActions.filter({filter}));
  }
}
