import { createAction, props } from '@ngrx/store';
import {
  IAccount,
  IBankAccount,
  ITransaction,
  IInstitution,
  IToken,
  IAdditionalUser, IGasTank, Blockchain, IMerchant, IContract, TransactionStatus, IStore
} from '@vesto/vesto';
import {AccountHeader, DashboardState, IPagination, ISectionAccount, Section} from './dashboard.state';

export namespace DashboardActions {
  import IFilter = DashboardState.IFilter;
  export const init = createAction('[@vesto/dashboard] init');

  export const reset = createAction('[@vesto/dashboard] reset');

  export const openOrGoToCreateAccount = createAction('[@vesto/dashboard] openOrGoToCreateAccount');

  export const openOrGoToAddbankAccount = createAction('[@vesto/dashboard] openOrGoToAddbankAccount');

  export const openOrGoToAddUser = createAction('[@vesto/dashboard] openOrGoToAddUser');

  export const openOrGoToChoosebankAccount = createAction('[@vesto/dashboard] openOrGoToChoosebankAccount', props<{ bankAccounts: IBankAccount[] }>());

  export const openOrGoToVerifyIdentity = createAction('[@vesto/dashboard] openOrGoToVerifyIdentity');

  export const openOrGoToSetSettleLimits = createAction('[@vesto/dashboard] openOrGoToSetSettleLimits');

  export const openOrGoToCreateAndDeployTokens = createAction('[@vesto/dashbaord] openOrGoToCreateAndDeployTokens', props<{ institutionId: string }>());

  export const openOrGoToBuySell = createAction('[@vesto/dashboard] openOrGoToBuySell', props<{ selectedTab?: string }>());

  export const openOrGoToSendReceive = createAction('[@vesto/dashboard] openOrGoToSendReceive', props<{ selectedTab?: string }>());

  export const openOrGoToTransactionDetail = createAction('[@vesto/dashboard] openOrGoToTransactionDetail', props<{ transaction: ITransaction }>());

  export const openOrGoToTransferGas = createAction('[@vesto/dashboard] openOrGoToTransferGas', props<{ blockchain: Blockchain, gasTankId: string }>());

  export const openOrGoToChangePersonalInfo = createAction('[@vesto/dashboard] openOrGoToChangePersonalInfo');

  export const openOrGoToChangeEmail = createAction('[@vesto/dashboard] openOrGoToChangeEmail');

  export const openOrGoToChangeMobile = createAction('[@vesto/dashboard] openOrGoToChangeMobile');

  export const openOrGoToChangePassword = createAction('[@vesto/dashboard] openOrGoToChangePassword');

  export const refresh = createAction('[@vesto/dashboard] refresh');

  export const selectSection = createAction('[@vesto/dashboard] selectSection', props<{ section: Section }>());

  export const selectAccount = createAction('[@vesto/dashboard] selectAccount', props<{ account: IAccount }>());

  export const selectSectionAccount = createAction('[@vesto/dashboard] selectSectionAccount', props<{ sectionAccount: ISectionAccount }>());

  export const selectAccountHeader = createAction('[@vesto/dashboard] selectAccountHeader', props<{ accountHeader: AccountHeader }>());

  export const selectSymbol = createAction('[@vesto/dashboard] selectSymbol', props<{ symbol: string }>());

  export const selectInstitution = createAction('[@vesto/dashboard] selectInstitution', props<{ institution: IInstitution }>());

  export const selectToken = createAction('[@vesto/dashboard] selectToken', props<{ token: IToken }>());

  export const selectTransaction = createAction('[@vesto/dashboard] selectTransaction', props<{ transaction: ITransaction }>());

  export const selectMerchant = createAction('[@vesto/dashboard] selectMerchant', props<{ merchant: IMerchant }>());

  export const openPlaid = createAction('[@vesto/dashboard] openPlaid', props<{ inModal?: boolean; token?: string }>());

  export const plaidSuccess = createAction('[@vesto/dashboard] plaidSuccess', props<{ bankAccounts: IBankAccount[] }>());

  export const openOnfido = createAction('[@vesto/dashboard] openOnfido', props<{ token?: string }>());

  export const openOrGoToChoosePlaidAccount = createAction('[@vesto/dashboard] openOrGoToChoosePlaidAccount', props<{ bankAccounts: IBankAccount[]}>());

  export const selectBankAccount = createAction('[@vesto/dashboard] selectBankAccount', props<{ bankAccount: IBankAccount }>());

  export const verifyIdentity = createAction('[@vesto/dashboard] verifyIdentity', props<{ address: string; googlePlaceId: string; }>());

  export const onfidoSuccess = createAction('[@vesto/dashboard] onfidoSuccess', props<{ data: any }>());

  export const onfidoFailure = createAction('[@vesto/dashboard] onfidoFailure', props<{ error: any }>());

  export const openOrGoToUserSearch = createAction('[@vesto/dashboard] openOrGoToUserSearch');

  export const openOrGoToKycClearConsider = createAction('[@vesto/dashboard] openOrGoToKycClearConsider');

  export const openOrGoToSuspendActivate = createAction('[@vesto/dashboard] openOrGoToSuspendActivate');

  export const openSetDailyLimits = createAction('[@vesto/dashboard] openSetDailyLimits');

  export const openOrGoToChangeAml = createAction('[@vesto/dashboard] openOrGoToChangeAml');

  export const openOrGoToCreateInstitution = createAction('[@vesto/dashboard] openOrGoToCreateInstitution');

  export const openOrGoToInviteUser = createAction('[@vesto/dashboard] openOrGoToInviteUser');

  export const openOrGoToInviteToMerchant = createAction('[@vesto/dashboard] openOrGoToInviteToMerchant');

  export const openOrGoToElement = createAction('[@vesto/dashboard] openOrGoToElement');

  export const openOrGoToMerchant = createAction('[@vesto/dashboard] openOrGoToMerchant', props<{ action?: string, merchant?: IMerchant }>());

  export const openOrGoToStore = createAction('[@vesto/dashboard] openOrGoToStore', props<{ merchant: IMerchant, store?: IStore }>());

  export const openOrGoToChangeGasTank = createAction('[@vesto/dashbaord] openOrGoToChangeGasTank', props<{ blockchain: Blockchain, gasTank: IGasTank }>());

  export const openOrGoToInstitution = createAction('[@vesto/dashbaord] openOrGoToInstitution', props<{ action?: string, institution?: IInstitution }>());

  export const openOrGoToChooseBlockchain = createAction('[@vesto/dashboard] openOrGoToChooseBlockchain', props<{ data: any }>());

  export const openOrGoToChooseToken = createAction('[@vesto/dashboard] openOrGoToChooseToken', props<{ data: any }>());

  export const openOrGoToRequestReport = createAction('[@vesto/dashboard] openOrGoToRequestReport', props<{ title: string; documentName: string }>());

  export const openOrGoToChangeTokenApy = createAction('[@vesto/dashbaord] openOrGoToChangeTokenApy', props<{ token: IToken }>());

  export const openOrGoToTwoFactor = createAction('[@vesto/dashboard] openOrGoToTwoFactor', props<{ action: string; transaction?: ITransaction; token?: IToken; additionalUser?: IAdditionalUser; contract?: IContract }>());

  export const openOrGoToAddYearnVault = createAction('[@vesto/dashboard] openOrGoToAddYearnVault');

  export const openOrGoToSetSupplyLimit = createAction('[@vesto/dashboard] openOrGoToSetSupplyLimit');

  export const openChangeRole = createAction('[@vesto/dashboard] openChangeRole');

  export const openOrGoToSwapSigningAddress = createAction('[@vesto/dashboard] openOrGoToSwapSigningAddress');

  export const openFiatBalances = createAction('[@vesto/dashboard] openFiatBalances');

  export const openAdditionalUsers = createAction('[@vesto/dashboard] openAdditionalUsers');

  export const confirm = createAction('[@vesto/dashboard] confirm');

  export const closeModal = createAction('[@vesto/dashboard] closeModal');

  export const setIsAdminView = createAction('[@vesto/dashbaord] setIsAdminView', props<{ isAdminView: boolean }>());

  export const selectPagination = createAction('[@vesto/dashboard] selectPagination', props<{ pagination: IPagination }>());

  export const paginate = createAction('[@vesto/dashboard] paginate', props<{ pagination: IPagination }>());

  export const filter = createAction('[@vesto/dashboard] filter', props<{ filter: IFilter }>());
}
