import { Directive, Input } from '@angular/core';
import { BaseComponent, CoreService } from '@vesto/xplat/core';
import { takeUntil } from 'rxjs/operators';
import { DashboardFacade } from '../state';
import { IInstitution, IUser, UserUtil, RoleType } from '@vesto/vesto';
import { ActivatedRoute } from '@angular/router';
import {ofType} from "@ngrx/effects";
import {UserActions} from "@vesto/ngx-vesto/state";
import {take} from "rxjs";

@Directive()
export abstract class ProfileBaseComponent extends BaseComponent {
  @Input() activeRoute: ActivatedRoute;
  user: IUser;
  institution: IInstitution;
  UserUtil = UserUtil;
  RoleType = RoleType;

  constructor(protected dashboardFacade: DashboardFacade) {
    super();
    CoreService.userFacade.user$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.user = result;
    });
    CoreService.institutionFacade.institution$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.institution = result;
    });
  }

  openOrGoToChangePassword() {
    this.dashboardFacade.openOrGoToChangePassword();
  }

  openOrGoToChangePersonalInfo() {
    this.dashboardFacade.openOrGoToChangePersonalInfo();
  }

  openOrGoToChangeEmail() {
    this.dashboardFacade.openOrGoToChangeEmail();
  }

  openOrGoToChangeMobile() {
    this.dashboardFacade.openOrGoToChangeMobile();
  }

  openOrGoToLimits() {
    this.dashboardFacade.openSetDailyLimits();
  }

  requestLimitsIncrease() {
    CoreService.userFacade.requestDailyLimitsIncrease();

    CoreService.actions$.pipe(ofType(UserActions.requestDailyLimitsIncreaseSuccess), take(1), takeUntil(this.destroy$)).subscribe(() => {
      CoreService.uiFacade.notification('Your request has been submitted');
    });

    CoreService.actions$.pipe(ofType(UserActions.requestDailyLimitsIncreaseFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      CoreService.uiFacade.notification(`apiErrors.${result.apiError.error}`);
    });
  }
}
