import {Component, OnInit} from "@angular/core";
import {BaseComponent, CoreService} from "@vesto/xplat/core";
import {IMerchant} from "@vesto/vesto";
import {filter, takeUntil} from "rxjs/operators";

@Component({
  selector: 'gbl-merchant-settings',
  templateUrl: 'merchant-settings.component.html'
})

export class MerchantSettingsComponent extends BaseComponent implements OnInit {
  merchant: IMerchant;

  ngOnInit() {
    CoreService.userFacade.user$
      .pipe(
        takeUntil(this.destroy$),
        filter((user) => !!user)
      )
      .subscribe((user) => {
        this.merchant = user.merchant;
      })
  }
}
