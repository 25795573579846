import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IUser } from '@vesto/vesto';
import { Observable } from 'rxjs';
import { UserActions } from './user.actions';
import { VestoSelectors, VestoState } from '../vesto.state';
import { Actions } from '@ngrx/effects';

@Injectable({
  providedIn: 'root'
})
export class UserFacade {
  initialized$: Observable<boolean> = this.store.pipe(select(VestoSelectors.initialized));
  authenticated$: Observable<boolean> = this.store.pipe(select(VestoSelectors.authenticated));
  user$: Observable<IUser> = this.store.pipe(select(VestoSelectors.user));
  adminUser$: Observable<IUser> = this.store.pipe(select(VestoSelectors.adminUser)); // TODO: remove this?
  users$: Observable<IUser[]> = this.store.pipe(select(VestoSelectors.users));
  token$: Observable<string> = this.store.pipe(select(VestoSelectors.token));

  constructor(private actions$: Actions, private store: Store<VestoState.IState>) {}

  set(
    user: IUser
  ) {
    this.store.dispatch(UserActions.set({ user }));
  }

  signOut() {
    this.store.dispatch(UserActions.signOut());
  }

  findByMerchant(
    merchantId: string
  ) {
    this.store.dispatch(UserActions.findByMerchant({ merchantId }));
  }

  invite(
    institutionId: string,
    email: string
  ) {
    this.store.dispatch(UserActions.invite({ institutionId, email }));
  }

  inviteToMerchant(
    merchantId: string,
    email: string
  ) {
    this.store.dispatch(UserActions.inviteToMerchant({ merchantId, email }));
  }

  sendSmsCode() {
    this.store.dispatch(UserActions.sendSmsCode());
  }

  verifyTwoFactorCode(code: string) {
    this.store.dispatch(UserActions.verifyTwoFactorCode({ code }));
  }

  verifyIdentity(address: any, googlePlaceId: string, documentType: string, documentFrontId: string, documentBackId: string, faceId: string, dob?: string, ssn?: string) {
    this.store.dispatch(
      UserActions.verifyIdentity({
        address: address,
        googlePlaceId,
        documentType,
        documentFrontId,
        documentBackId,
        faceId,
        dob,
        ssn
      })
    );
  }

  forgotPassword(email: string): void {
    this.store.dispatch(UserActions.forgotPassword({ email }));
  }

  setPassword(token: string, password: string) {
    this.store.dispatch(UserActions.setPassword({ token, password }));
  }

  changePersonalInfo(firstName: string, lastName: string, address: string, googlePlaceId: string) {
    this.store.dispatch(UserActions.changePersonalInfo({ firstName, lastName, address: address, googlePlaceId }));
  }

  changeEmail(email: string) {
    this.store.dispatch(UserActions.changeEmail({ email }));
  }

  verifyChangeEmail(token: string) {
    this.store.dispatch(UserActions.verifyChangeEmail({ token }));
  }

  changeMobile(mobile: string) {
    this.store.dispatch(UserActions.changeMobile({ mobile }));
  }

  verifyChangeMobile(code: string) {
    this.store.dispatch(UserActions.verifyChangeMobile({ code }));
  }

  changePassword(oldPassword: string, newPassword: string) {
    this.store.dispatch(UserActions.changePassword({ oldPassword, newPassword }));
  }

  verifyChangePassword(code: string) {
    this.store.dispatch(UserActions.verifyChangePassword({ code }));
  }

  typeahead(keyword: string) {
    this.store.dispatch(UserActions.typeahead({ keyword }));
  }

  selectUser(user: IUser) {
    this.store.dispatch(UserActions.selectUser({ user }));
  }

  kycClear() {
    this.store.dispatch(UserActions.kycClear());
  }

  kycConsider() {
    this.store.dispatch(UserActions.kycConsider());
  }

  suspend() {
    this.store.dispatch(UserActions.suspend());
  }

  activate() {
    this.store.dispatch(UserActions.activate());
  }

  requestDailyLimitsIncrease() {
    this.store.dispatch(UserActions.requestDailyLimitsIncrease());
  }
}
