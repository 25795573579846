import { Directive, OnInit } from '@angular/core';
import { BaseComponent, CoreService, UiActions } from '@vesto/xplat/core';
import { IUser } from '@vesto/vesto';
import { FeatureService } from '../../services/feature.service';
import { filter, takeUntil } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';
import { SignUpActions } from '@vesto/ngx-vesto/state';

@Directive()
export abstract class VerifyEmailBaseComponent extends BaseComponent implements OnInit {
  user: IUser;
  error: string;

  ngOnInit() {
    FeatureService.signUpFacade.user$
      .pipe(
        filter((user) => !!user),
        takeUntil(this.destroy$)
      )
      .subscribe((user) => {
        this.user = user;
      });

    CoreService.actions$.pipe(ofType(SignUpActions.sendEmailFailure, SignUpActions.verifyEmailFailure), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
    });
  }

  sendEmail() {
    FeatureService.signUpFacade.sendEmail();
    CoreService.uiFacade.notification(`Email sent to ${ this.user?.email }`);
  }
}
