import { Directive, OnInit } from '@angular/core';
import { BaseComponent, CoreService, uiCloseModal } from '@vesto/xplat/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FormGroupUtil, strongPasswordPattern } from '@vesto/xplat/utils';
import { SignInActions, SignInFacade } from '../state';
import { take, takeUntil } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';
import { UserActions } from '@vesto/ngx-vesto/state';

@Directive()
export abstract class SetPasswordBaseComponent extends BaseComponent {
  step: 'FORM_GROUP' | 'BUSY' | 'SUCCESS' = 'FORM_GROUP';
  formGroup: UntypedFormGroup;
  token: string;
  error: string;

  constructor(protected signInFacade: SignInFacade) {
    super();
    this.formGroup = CoreService.formBuilder.group(
      {
        password: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            (control: UntypedFormControl) => {
              return !control.root.get('password') || (control.root.get('password') && strongPasswordPattern.test(control.root.get('password').value)) ? null : { invalid: true };
            }
          ])
        ]
      },
      { updateOn: 'blur' }
    );
  }

  setPassword(showSpinner?: boolean) {
    this.step = 'BUSY';
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
        this.step = 'FORM_GROUP';
      });
      return;
    }

    CoreService.actions$.pipe(ofType(UserActions.setPasswordSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.step = 'SUCCESS';
      CoreService.progress.toggleSpinner(false);
      CoreService.windowService.setTimeout(() => {
        uiCloseModal();
        CoreService.uiFacade.notification(CoreService.translateService.instant(`labels.passwordNew`));
        SignInActions.openOrGoToSignIn(); //TODO: is this right?
      }, 2200);
    });

    CoreService.actions$.pipe(ofType(UserActions.setPasswordFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      CoreService.progress.toggleSpinner(false);
      this.error = `apiErrors.${result.apiError.error}`;
      this.step = 'FORM_GROUP';
    });

    if (showSpinner) {
      CoreService.progress.toggleSpinner(true);
    }
    setTimeout(() => {
      // helps ensure spinner shows before doing cryptographic operations
      const value = FormGroupUtil.trim(this.formGroup.value);
      CoreService.userFacade.setPassword(this.token, value.password);
    });
  }

  close() {
    uiCloseModal();
  }
}
