import { QueryList } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, NgControl } from '@angular/forms';
import { CoreService } from '@vesto/xplat/core';

export function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    throw new Error(`${moduleName} has already been loaded. Import ${moduleName} in the AppModule only.`);
  }
}

/**
 * ValidatorsUtil
 */
export class ValidatorsUtil {
  /**
   *
   * @param {FormControl} control
   * @returns {any}
   */
  public static notZero(control: UntypedFormControl) {
    return control.value > 0 ? null : { notZero: true };
  }

  /**
   *
   * @param control
   */
  public static email(control: UntypedFormControl) {
    const value = !!control.value ? control.value.toLowerCase() : null;
    if (/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(value)) {
      return null;
    }

    return { invalidEmail: true };
  }

  /**
   *
   * @param control
   */
  public static mobile(control: UntypedFormControl) {
    if (/^(\+?(\d{1}|\d{2}|\d{3})[ ]?)?\d{3}[ - ]?\d{3}[ - ]?\d{4}$/.test(control.value)) {
      return null;
    }

    return { invalidMobile: true };
  }

  /**
   *
   * @param control
   */
  public static creditCard(control: UntypedFormControl) {
    if (/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/.test(control.value)) {
      return null;
    }
    return { invalidCreditCard: true };
  }

  /**
   *
   * @param control
   */
  public static firstOrLastName(control: UntypedFormControl) {
    if (/^([a-z]+[,.]?[ ]?|[A-Z]+['-]?)+$/.test(control.value)) {
      return null;
    }

    return { invalidFirstOrLastName: true };
  }

  /**
   *
   * @param control
   */
  public static address(control: UntypedFormControl) {
    if (/^[#.0-9a-zA-Z\s,-]+$/.test(control.value)) {
      return null;
    }

    return { invalidAddress: true };
  }

  /**
   *
   * @param control
   */
  public static city(control: UntypedFormControl) {
    if (/^[a-zA-Z.-]+(?:[\s-][\/a-zA-Z.]+)*$/.test(control.value)) {
      return null;
    }

    return { invalidCity: true };
  }

  /**
   *
   * @param control
   */
  public static cvc(control: UntypedFormControl) {
    if (/^[0-9]{3,4}$/.test(control.value)) {
      return null;
    }

    return { invalidCvc: true };
  }

  /**
   *
   * @param control
   */
  public static password(control: UntypedFormControl) {
    if (/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/.test(control.value)) {
      return null;
    }

    return { invalidPassword: true };
  }

  /**
   *
   * @param control
   */
  public static zipCode(control: UntypedFormControl) {
    if (/^(\d{5}-\d{4}|\d{5})$/.test(control.value)) {
      return null;
    }

    return { invalidZipCode: true };
  }

  /**
   *
   * @param control
   * @returns {any}
   */
  public static alphanumeric(control: UntypedFormControl) {
    if (/^[a-zA-Z0-9]*$/.test(control.value)) {
      return null;
    }

    return { invalidAlphanumeric: true };
  }
}

export class FormGroupUtil {
  static trim(value: any): any {
    const _value = { ...value };
    for (const key of Object.keys(_value)) {
      if (!_value[key] || typeof _value[key] !== 'string') {
        continue;
      }
      _value[key] = _value[key].trim();
    }
    return _value;
  }

  static getFirstInvalidControl(formGroup: UntypedFormGroup): AbstractControl {
    for (const key of Object.keys(formGroup.controls)) {
      const control = formGroup.get(key);
      if (!control.validator) {
        continue;
      }
      const validationResult = control.validator(control);
      if (validationResult == null) {
        continue;
      }
      control.markAsTouched();
      (control as any).nativeElement.focus();
      return control;
    }
    return null;
  }

  static getControl(controlName: string, formGroup: UntypedFormGroup): any {
    for (const key of Object.keys(formGroup.controls)) {
      const control = formGroup.get(key);
      if (key === controlName) {
        return control;
      }
    }
    return null;
  }
}
