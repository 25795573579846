<div class="bg-light-gray">
  <div class="container pt-32" [class.dark-mode]="darkMode">
    <div class="flex pt-5 px-2">
      <div class="flex-1 md:flex">
        <a href="http://linkedin.com/company/vesto-llc" target="_blank" class="mb-3 mr-3 block"><i class="fab fa-linkedin text-2xl"></i></a>
        <a href="https://twitter.com/vestoprotocol" target="_blank" class="mb-3 mr-3 block"><i class="fab fa-twitter-square text-2xl"></i></a>
        <a href="mailto:info@vesto.io?subject=I’d like to learn more about Vesto" class="block"><i class="fas fa-envelope-square text-2xl"></i></a>
      </div>
      <div>
        <a routerLink="/about-us" class="mb-3 block text-sm">About Us</a>
        <a routerLink="/news" class="mb-3 mt-3 block text-sm">News</a>
        <a routerLink="/reports" class="mb-3 mt-3 block text-sm">Reports</a>
        <a routerLink="/webinars" class="mb-3 mt-3 block text-sm">Webinars</a>
        <a routerLink="/legal" class="mb-3 mt-3 block text-sm">Legal</a>
        <a routerLink="/FDIC-disclosure" class="mb-3 mt-3 block text-sm">FDIC Disclosure</a>
      </div>
    </div>
    <div class="mt-10 text-center opacity-50 text-sm border-top-light p-2">&copy; {{ year }} Vesto LLC</div>
  </div>
</div>
