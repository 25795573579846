import {Injectable} from '@angular/core';
import {
  ApiActions,
  InstitutionFacade,
  MerchantActions, UserFacade,
  VestoState
} from '@vesto/ngx-vesto/state';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ObjectUtil} from '@vesto/vesto';
import {Action, Store} from '@ngrx/store';
import {from, Observable} from 'rxjs';
import {catchError, mergeMap, withLatestFrom} from 'rxjs/operators';
import {MerchantsApiService} from '@vesto/ngx-vesto';

@Injectable()
export class MerchantEffects {
  constructor(private institutionFacade: InstitutionFacade, private actions$: Actions, protected store: Store<VestoState.IState>, private merchantsApiService: MerchantsApiService, private userFacade: UserFacade) {
  }

  findByInstitution$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MerchantActions.findByInstitution),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{}, user]) => {
          return this.merchantsApiService.findByInstitution(user.institutionId).pipe(
            mergeMap((result) => {
              return from([MerchantActions.findByInstitutionSuccess({merchants: result})]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([MerchantActions.findByInstitutionFailure({apiError}), ApiActions.setError({apiError})]);
            })
          );
        })
      )
  );

  create$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MerchantActions.create),
        withLatestFrom(this.institutionFacade.institution$),
        mergeMap(([{name, address, feeType, feeFixed, feePercentage, email}, institution]) => {
          return this.merchantsApiService.create(institution.id, name, address, feeType, feeFixed, feePercentage, email).pipe(
            mergeMap((result) => {
              return from([MerchantActions.createSuccess({merchant: result})]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([MerchantActions.createFailure({apiError}), ApiActions.setError({apiError})]);
            })
          );
        })
      )
  );

  changeSettings$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MerchantActions.changeSettings),
        mergeMap(({merchantId, name, address, feeType, feeFixed, feePercentage}) => {
          return this.merchantsApiService.changeSettings(merchantId, name, address, feeType, feeFixed, feePercentage).pipe(
            mergeMap((result) => {
              return from([MerchantActions.changeSettingsSuccess({merchant: result})]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([MerchantActions.changeSettingsFailure({apiError}), ApiActions.setError({apiError})]);
            })
          );
        })
      )
  );

  addUser$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MerchantActions.addUser),
        mergeMap(({merchantId, userId}) => {
          return this.merchantsApiService.addUser(merchantId, userId).pipe(
            mergeMap((result) => {
              return from([MerchantActions.addUserSuccess()]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([MerchantActions.addUserFailure({apiError}), ApiActions.setError({apiError})]);
            })
          );
        })
      )
  );

  removeUser$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MerchantActions.removeUser),
        mergeMap(({merchantId, userId}) => {
          return this.merchantsApiService.removeUser(merchantId, userId).pipe(
            mergeMap((result) => {
              return from([MerchantActions.removeUserSuccess()]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([MerchantActions.removeUserFailure({apiError}), ApiActions.setError({apiError})]);
            })
          );
        })
      )
  );

  promoteUser$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MerchantActions.promoteUser),
        mergeMap(({merchantId, userId}) => {
          return this.merchantsApiService.promoteUser(merchantId, userId).pipe(
            mergeMap((result) => {
              return from([MerchantActions.promoteUserSuccess()]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([MerchantActions.promoteUserFailure({apiError}), ApiActions.setError({apiError})]);
            })
          );
        })
      )
  );

  demoteUser$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(MerchantActions.demoteUser),
        mergeMap(({merchantId, userId}) => {
          return this.merchantsApiService.demoteUser(merchantId, userId).pipe(
            mergeMap((result) => {
              return from([MerchantActions.demoteUserSuccess()]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([MerchantActions.demoteUserFailure({apiError}), ApiActions.setError({apiError})]);
            })
          );
        })
      )
  );
}
