import { IEnvironment } from '../interfaces/environment.interface';
import { IHttpClient } from '../interfaces/http.interface';
import { BaseApi } from './base-api';

export class MarketDataApi extends BaseApi {
  constructor(protected environment: IEnvironment, protected httpClient: IHttpClient) {
    super(environment);
  }

  getDeFi() {
    return this.httpClient.get(this.formatUrl(`/v${this.environment.vesto.version}/market-data/defi`), { headers: this.getHeaders() });
  }

  /**
   *
   * @param period - 1w, 1m, 3m, 1y or all
   */
  getDeFiHistory(period: string) {
    return this.httpClient.get(this.formatUrl(`/v${this.environment.vesto.version}/market-data/defi-history/${period}`), { headers: this.getHeaders() });
  }
}
