import { FeeType, IPaymentInfo, ITransaction } from '@vesto/vesto';

export function calculateTotalForTransaction(transaction: IPaymentInfo | ITransaction, isMerchantDisplay?: boolean) {
  const processingFee = isMerchantDisplay ? 0 : transaction.fees
    .filter((fee) => fee.type !== FeeType.MERCHANT)
    .map((fee) => fee.fiatAmount)
    .reduce((partialSum, a) => partialSum + a, 0);
  const merchantFee = transaction.fees.find((fee) => fee.type === FeeType.MERCHANT);
  return {
    processingFee,
    merchantFee: merchantFee?.fiatAmount || 0,
    fees: processingFee + merchantFee?.fiatAmount || 0,
    total: ((<ITransaction>transaction).fiatAmount ?? transaction.amount) + processingFee + (merchantFee?.fiatAmount || 0)
  };
}
