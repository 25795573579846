import { enableProdMode, Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { LogService } from './log.service';
// import { join } from 'path';

/**
 * This can be used to remotely load environment config and used as APP_INITIALIZER for web or mobile apps
 */
@Injectable()
export class ConfigService {
  private configSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient, @Inject(PLATFORM_ID) private _platformId: Object, private log: LogService) {}

  public get environment$() {
    return this.configSubject.pipe(filter((config) => !!config));
  }

  public get environment() {
    // tslint:disable-next-line: rxjs-no-subject-value
    return this.configSubject.getValue();
  }

  private onConfigLoad(config: any): void {
    this.configSubject.next(config);
    if (config.production) {
      enableProdMode();
    }
  }

  private onConfigLoadFailure(configPath: string, error: any): void {
    console.error(`failed to load configuration at path ${configPath}: ${error}`);
  }

  /**
   * Used for web apps which play well with delayed APP_INITIALIZER
   */
  public load(configPath): Promise<any> {
    let promise: Promise<any>;
    if (isPlatformServer(this._platformId)) {
      promise = new Promise((resolve, reject) => {
        // TODO
        // try {
        //   const serverDistFolder = join(process.cwd(), 'dist/server');
        //   const fullPath = `${serverDistFolder}${configPath}`;
        //   this.log.debug(`Attempting to read ${fullPath}`);
        //   const fs = require('fs');
        //   const raw = fs.readFileSync(fullPath);
        //   const config = JSON.parse(raw);
        //   this.log.debug(`Config read as ${raw}`);
        //   this.onConfigLoad(config);
        //   resolve(config);
        // } catch (error) {
        //   this.onConfigLoadFailure(configPath, error);
        //   reject(error);
        // }
      });
    } else {
      const configObservable = this.http.get(configPath);
      configObservable
        .pipe(
          catchError((error) => {
            this.onConfigLoadFailure(configPath, error);
            return of();
          })
        )
        .subscribe((config: any) => {
          this.onConfigLoad(config);
        });

      promise = configObservable.toPromise();
    }
    return promise;
  }

  /**
   * Often used with mobile apps since their environments are better baked into the build
   */
  public loadStatic(environment): Promise<any> {
    this.configSubject.next(environment);
    return Promise.resolve(environment);
  }
}
