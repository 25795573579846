import {createFeatureSelector, createSelector} from '@ngrx/store';
import {
  IAccount, IBankAccount,
  IInstitution,
  IMerchant,
  IToken,
  ITransaction,
  TransactionStatus,
  TransactionType
} from '@vesto/vesto';
import {Action} from '@ngrx/store/src/models';
import {TransactionActions} from '@vesto/ngx-vesto/state';

export enum Section {
  OVERVIEW = 'OVERVIEW',
  PERSONAL_ACCOUNTS = 'PERSONAL_ACCOUNTS',
  ADMIN_ACCOUNTS = 'ADMIN_ACCOUNTS',
  SETTINGS = 'SETTINGS',
  SETTINGS_PROFILE = 'SETTINGS_PROFILE',
  SETTINGS_BANK_ACCOUNTS = 'SETTINGS_BANK_ACCOUNTS',
  SETTINGS_ADDITIONAL_USERS = 'SETTINGS_ADDITIONAL_USERS',
  USERS = 'USERS',
  INSTITUTION = 'INSTITUTION',
  INSTITUTION_SETTINGS = 'INSTITUTION_SETTINGS',
  INSTITUTIONS = 'INSTITUTIONS',
  INSTITUTION_MERCHANTS = 'INSTITUTION_MERCHANTS',
  GAS_TANKS = 'GAS_TANKS',
  ALERTS = 'ALERTS',
  SMART_CONTRACTS = 'SMART_CONTRACTS',
  MERCHANT_SETTINGS = 'MERCHANT_SETTINGS',
  GLOBAL_TRANSACTIONS = 'GLOBAL_TRANSACTIONS',
  STORES = 'STORES'
}

export enum AccountHeader {
  TOKEN = 'TOKEN',
  CONTRACT = 'CONTRACT',
  ETHEREUM = 'ETHEREUM',
  POLYGON = 'POLYGON'
}

export interface IPagination {
  action: Action;
  id: string;
  page: number;
  size: number;
}

export interface ISectionAccount {
  isAdminView: boolean;
  section: Section;
  account: IAccount;
  selected: boolean;
}

export namespace DashboardState{

  export interface IFilter {
    action: Action;
    status: TransactionStatus;
    type: TransactionType;
  }

  export interface IState {
    selectedSection: Section;
    selectedAccount: IAccount;
    sectionAccounts: ISectionAccount[];
    selectedAccountHeader: AccountHeader;
    selectedSymbol: string;
    selectedBankAccount: IBankAccount;
    selectedInstitution: IInstitution;
    selectedToken: IToken;
    selectedTransaction: ITransaction;
    selectedPagination: IPagination;
    paginations: IPagination[];
    selectedMerchant: IMerchant;
    isAdminView: boolean;
    filter: IFilter;
  }

  export const initialState: IState = {
    selectedSection: Section.PERSONAL_ACCOUNTS,
    selectedAccount: null,
    sectionAccounts: [],
    selectedAccountHeader: AccountHeader.TOKEN,
    selectedSymbol: 'all',
    selectedBankAccount: null,
    selectedInstitution: null,
    selectedToken: null,
    selectedTransaction: null,
    selectedPagination: null,
    paginations: [],
    selectedMerchant: null,
    isAdminView: false,
    filter: {
      action: TransactionActions.findBuyOrSellActionRequired,
      status: TransactionStatus.TRANSFERRING_TO_CIRCLE,
      type: TransactionType.WIRE
    }
  };

  export const featureState = createFeatureSelector<IState>('dashboard');
  export const selectedSection = createSelector(featureState, (state: IState) => state.selectedSection);
  export const selectedAccount = createSelector(featureState, (state: IState) => state.selectedAccount);
  export const sectionAccounts = createSelector(featureState, (state: IState) => state.sectionAccounts);
  export const selectedAccountHeader = createSelector(featureState, (state: IState) => state.selectedAccountHeader);
  export const selectedSymbol = createSelector(featureState, (state: IState) => state.selectedSymbol);
  export const selectedBankAccount = createSelector(featureState, (state: IState) => state.selectedBankAccount);
  export const selectedInstitution = createSelector(featureState, (state: IState) => state.selectedInstitution);
  export const selectedToken = createSelector(featureState, (state: IState) => state.selectedToken);
  export const selectedTransaction = createSelector(featureState, (state: IState) => state.selectedTransaction);
  export const selectedPagination = createSelector(featureState, (state: IState) => state.selectedPagination);
  export const paginations = createSelector(featureState, (state: IState) => state.paginations);
  export const selectedMerchant = createSelector(featureState, (state: IState) => state.selectedMerchant);
  export const isAdminView = createSelector(featureState, (state: IState) => state.isAdminView);
  export const filter = createSelector(featureState, (state: IState) => state.filter);
}
