export enum TemplateType {
  SIGNUP_VERIFY_EMAIL= 'SIGNUP_VERIFY_EMAIL',
  USER_CHANGE_EMAIL = 'USER_CHANGE_EMAIL',
  USER_FORGOT_PASSWORD = 'USER_FORGOT_PASSWORD',
  USER_INVITE = 'USER_INVITE',
  USER_REQUEST_DAILY_LIMITS_INCREASE = 'USER_REQUEST_DAILY_LIMITS_INCREASE',
  USER_CHANGE_DAILY_LIMITS = 'USER_CHANGE_DAILY_LIMITS',
  USER_SUSPEND = 'USER_SUSPEND',
  USER_ACTIVATE = 'USER_ACTIVATE',
  USER_KYC_CLEAR = 'USER_KYC_CLEAR',
  USER_KYC_CONSIDER = 'USER_KYC_CONSIDER',
  ACCOUNT_SUSPEND = 'ACCOUNT_SUSPEND',
  ACCOUNT_ACTIVATE = 'ACCOUNT_ACTIVATE',
  ACCOUNT_ADD_USER = 'ACCOUNT_ADD_USER',
  ACCOUNT_REMOVE_USER = 'ACCOUNT_REMOVE_USER',
  MERCHANT_PROMOTE_USER = 'MERCHANT_PROMOTE_USER',
  MERCHANT_DEMOTE_USER = 'MERCHANT_DEMOTE_USER',
  MERCHANT_ADD_USER = 'MERCHANT_ADD_USER',
  MERCHANT_REMOVE_USER = 'MERCHANT_REMOVE_USER',
  TRANSACTION_TRANSFER = 'TRANSACTION_TRANSFER'
}
