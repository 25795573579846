<div class="bg-white shadowed rounded mb-3 p-3 accounts">
  <div *ngFor="let account of accounts">
    <div *ngIf="!account.shared">
      <div class="flex items-center mb-2">
        <h4 class="flex-grow">{{ account.name }}</h4>
        <a class="text-sm underline" ><i class="fal fa-user-plus md:mr-2"></i><span class="hidden md:inline">Add User</span></a>
      </div>
      <ng-container *ngIf="user.id == account.user.id && account.additionalUsers && account.additionalUsers.length > 0; else noUserTemplate">
        <div class="mb-8">
          <div *ngFor="let additionalUser of account?.additionalUsers" class="transaction-item flex items-center text-sm">
            <div class="flex-grow">
              <ng-container *ngIf="additionalUser.user?.firstName; else inviteeTemplate">
                <span class="font-bold" >{{ additionalUser.user?.firstName }} {{ additionalUser.user?.lastName }}</span> {{ additionalUser.user?.email}}
              </ng-container>
              <ng-template #inviteeTemplate><span class="font-bold">Invitee</span></ng-template>
              <br />
              {{ additionalUser.inviteEmail }}
            </div>
            <div class="mr-8">
              {{ additionalUser.status }}
            </div>
            <div [matMenuTriggerFor]="menu">
              <i class="fal fa-ellipsis-h text-2xl"></i>
            </div>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="suspendOrReactivateUser(additionalUser)" *ngIf="additionalUser.status !== AdditionalUserStatus.PENDING">
                {{ additionalUser.status === AdditionalUserStatus.ACTIVE ? 'Suspend' : 'Activate' }}
              </button>
              <button mat-menu-item (click)="removeUser(additionalUser)">Remove</button>
            </mat-menu>
          </div>
        </div>
      </ng-container>
      <ng-template #noUserTemplate>
        <div class="transaction-item flex items-center text-sm mb-8">No additional users</div>
      </ng-template>
    </div>
  </div>
</div>
