import {Component, OnInit} from "@angular/core";
import {BaseComponent, CoreService, uiCloseModal} from "@vesto/xplat/core";
import {IFiatBalances, ITransaction} from "@vesto/vesto";
import {filter, take, takeUntil} from "rxjs/operators";
import {ofType} from "@ngrx/effects";
import {TransactionActions} from "@vesto/ngx-vesto/state";

@Component({
  selector: 'gbl-fiat-balances-modal',
  templateUrl: './fiat-balances-modal.component.html'
})

export class FiatBalancesModalComponent extends BaseComponent implements OnInit {
  step : 'FORM_GROUP' | 'TWO_FACTOR' | 'BUSY' | 'SUCCESS' | 'DETAIL' = 'BUSY';
  fiatBalances: IFiatBalances;
  error: string;
  transaction: ITransaction;
  action: string;

  ngOnInit() {
    CoreService.systemFacade.getFiatBalances();
    CoreService.systemFacade.fiatBalances$.pipe(
      filter( fiatBalances => !!fiatBalances),
      takeUntil(this.destroy$)
    ).subscribe((fiatBalances) => {
      this.fiatBalances = fiatBalances;
      CoreService.windowService.setTimeout(() => {
        this.step = 'FORM_GROUP';
      }, 1000);
    });
  }

  sendSmsCode(isResendSms: boolean) {
    CoreService.systemFacade.sendSmsCode();

    if (isResendSms) {
      CoreService.uiFacade.notification(`SMS code sent!`);
    }
  }

  wire(action: string) {
    this.error = null;
    this.action = action;
    this.step = 'TWO_FACTOR';
  }

  confirmCode(code: string) {
    this.step = 'BUSY';
    CoreService.actions$.pipe(ofType(TransactionActions.wireToCircleSuccess, TransactionActions.wireToFboSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.transaction = result.transaction;
      this.step = 'SUCCESS';
      CoreService.windowService.setTimeout(() => {
        this.step = 'DETAIL';
      }, 1600);
    });

    CoreService.actions$.pipe(ofType(TransactionActions.wireToCircleFailure, TransactionActions.wireToFboFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
      this.step = 'FORM_GROUP';
    });

    switch (this.action) {
      case 'circle':
        CoreService.transactionFacade.wireToCircle(code);
        break;
      case 'fbo':
        CoreService.transactionFacade.wireToFbo(code);
        break;
    }
  }

  close() {
    uiCloseModal();
  }
}
