import { ofType } from '@ngrx/effects';
import { UserActions } from '@vesto/ngx-vesto/state';
import { CoreService } from '@vesto/xplat/core';
import { Observable, Subject, take, takeUntil } from 'rxjs';

export function uxSetupUserTwoFactor(destroy$: Subject<boolean>): Observable<{ success?: true; error?: string }> {
  return new Observable((observer) => {
    CoreService.actions$.pipe(ofType(UserActions.verifyTwoFactorCodeSuccess), take(1), takeUntil(destroy$)).subscribe((result) => {
      observer.next({ success: true });
      observer.complete();
    });

    CoreService.actions$.pipe(ofType(UserActions.verifyTwoFactorCodeFailure), takeUntil(destroy$)).subscribe((result) => {
      observer.next({ error: result?.apiError?.error });
      CoreService.progress.toggleSpinner(false);
    });
  });
}
