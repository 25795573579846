import { Inject, Injectable, NgZone } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { from, Observable } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { LocaleService } from '../../services/locale.service';
import { ProgressService } from '../../services/progress.service';
import { UiActions } from './ui.actions';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { ICountryCode } from '../../interfaces/country-code.interface';
import { LogService } from '../../services/log.service';
import { WindowService } from '../../services/window.service';
import { RouterToken } from '../../services/tokens';
import { Router } from '@angular/router';
import { isObject } from '@vesto/xplat/utils';
import { Location } from '@angular/common';
import { ModalService } from '../../services/modal.service';

@Injectable()
export class UiEffects implements OnInitEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<any>,
    private _localeService: LocaleService,
    private _translateService: TranslateService,
    private progress: ProgressService,
    private httpClient: HttpClient,
    @Inject(RouterToken)
    private _router: any,
    private _ngRouter: Router,
    private _location: Location,
    private _log: LogService,
    private _ngZone: NgZone,
    private _modal: ModalService,
    public win: WindowService
  ) {}

  showSpinner$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(UiActions.showSpinner),
        map(() => {
          this.progress.toggleSpinner(true);
        })
      ),
    { dispatch: false }
  );

  hideSpinner$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(UiActions.hideSpinner),
        map(() => {
          this.progress.toggleSpinner(false);
        })
      ),
    { dispatch: false }
  );

  initLocale$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(UiActions.initLocale),
        map(() => {
          return UiActions.setLocale({ locale: this._localeService.locale });
        })
      )
  );

  setLocale$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(UiActions.setLocale),
        map(({ locale }) => {
          this._localeService.locale = locale;
          this._translateService.use(locale);
          return UiActions.setLocaleSuccess();
        })
      )
  );

  initCountryCodes$ = createEffect((): any =>
    this._actions$.pipe(
      ofType(UiActions.initCountryCodes),
      mergeMap(({}) => {
        return this.httpClient.get((this.win.isBrowser ? '' : '~/') + 'assets/country-codes.json').pipe(
          mergeMap((result: ICountryCode[]) => {
            return from([UiActions.initCountryCodesSuccess({ countryCodes: result })]);
          }),
          catchError((error) => {
            return from([UiActions.initCountryCodesFailure({ error })]);
          })
        );
      })
    )
  );

  go$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(UiActions.go),
        map(({ type, path, extras }) => {
          if (!this._router) {
            // on web we just rely on injecting @angular/router
            // avoids having to create a factory to create it in core.module
            // on mobile, this will be defined as RouterExtensions
            this._router = this._ngRouter;
          }
          if (LogService.DEBUG.LEVEL_4) {
            this._log.debug(`${type} from: `, this._ngRouter.url);
            this._log.debug(`${type} to: `, isObject(path) ? JSON.stringify(path) : path);
          }
          extras = extras ? { ...extras } : undefined; // must be explicitly undefined (angular issue)
          this._ngZone.run(() => {
            this._router.navigate(path, extras);
          });
        })
      ),
    { dispatch: false }
  );

  back$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(UiActions.back),
        tap(() => this._location.back())
      ),
    { dispatch: false }
  );

  forward$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(UiActions.forward),
        tap(() => this._location.forward())
      ),
    { dispatch: false }
  );

  modalOpen$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UiActions.openModal),
      map((options) => {
        const details = this._modal.open(options);
        return UiActions.openedModal({
          open: true,
          cmpType: details.cmpType,
          latestResult: null // always reset when opening
        });
      })
    )
  );

  modalClose$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UiActions.closeModal),
      tap((latestResult) => {
        this._modal.close(latestResult);
      })
    )
  , { dispatch: false});

  ngrxOnInitEffects(): Action {
    return UiActions.initCountryCodes();
  }
}
