import { IAdditionalUser } from './additional-user.interface';
import { IAsset } from './asset.interface';
import { IUser } from './user.interface';
import { IContract } from './contract.interface';
import { AccountStatus } from '../enums/account-status.enum';
import { AccountType } from '../enums/account-type.enum';

export interface IAccount {
  id: string;
  user: IUser;
  type: AccountType;
  status: AccountStatus;
  name: string;
  balance: number;
  interest: number;
  suspended: string;
  reactivated: string;
  error: string;
  updated: string;
  created: string;
  requiresSignatures: boolean;
  // Joins.
  assets: IAsset[];
  contracts: IContract[];
  additionalUsers: IAdditionalUser[];
  // Transients.
  shared: boolean;
  additionalUserSuspended: boolean;
}
