import { Directive, Input, OnInit } from '@angular/core';
import {
  IAccount,
  ITransaction,
  IUser,
  TransactionType,
  TransactionStatus,
  SignatureType,
  calculateTotalForTransaction,
  UserUtil, RoleType
} from '@vesto/vesto';
import { BaseComponent, CoreService } from '@vesto/xplat/core';
import { FeatureService } from '../../services';
import {filter, takeUntil} from 'rxjs/operators';

@Directive()
export abstract class TransactionBaseComponent extends BaseComponent {
  @Input() shouldShowIcon = false;
  user: IUser;
  selectedAccount: IAccount;
  TransactionType = TransactionType;
  TransactionStatus = TransactionStatus;
  SignatureType = SignatureType;
  fee = 0;
  total = 0;
  processingFee = 0;
  UserUtil = UserUtil;
  RoleType = RoleType;
  private _transaction: ITransaction;

  @Input()
  set transaction(value: ITransaction) {
    this._transaction = value;
    if (this._transaction) {
      this.setup();
    }
  }

  get transaction() {
    return this._transaction;
  }

  constructor() {
    super();
    FeatureService.dashboardFacade.selectedAccount$
      .pipe(
        filter((selectedAccount) => !!selectedAccount),
        takeUntil(this.destroy$)
      )
      .subscribe((selectedAccount) => {
        this.selectedAccount = selectedAccount;
      });
    CoreService.userFacade.user$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.user = result;
    });
  }

  setup() {
    const amounts = calculateTotalForTransaction(this.transaction, !!this.user?.merchant);
    this.fee = amounts.fees;
    this.total = amounts.total;
    this.processingFee = amounts.processingFee;
  }

  changeStatus(status: string) {
    console.log(status);
  }
}
