import { IEnvironment } from '../interfaces/environment.interface';
import { IHttpClient } from '../interfaces/http.interface';
import { BaseApi } from './base-api';
import { ethers } from 'ethers';
import * as CryptoJS from 'crypto-js';

export class UsersApi extends BaseApi {
  constructor(protected environment: IEnvironment, protected httpClient: IHttpClient) {
    super(environment);
  }

  signIn(email: string, password: string) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/users/sign-in`),
      {
        email,
        passwordHash: ethers.utils.keccak256(ethers.utils.toUtf8Bytes(password))
      },
      { headers: this.getHeaders() }
    );
  }

  sendSmsCode(token: string) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/users/send-sms-code/${token}`), null, { headers: this.getHeaders() });
  }

  verifyTwoFactorCode(token: string, code: string) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/users/verify-two-factor-code/${token}/${code}`), null, {
      headers: this.getHeaders()
    });
  }

  find(userId: string) {
    return this.httpClient.get(this.formatUrl(`/v${this.environment.vesto.version}/users/find/${userId}`), {
      headers: this.getHeaders()
    });
  }

  findByMerchant(
    merchantId: string
  ) {
    return this.httpClient.get(
      this.formatUrl(`/v${this.environment.vesto.version}/users/find-by-merchant/${merchantId}`),
      {
        headers: this.getHeaders()
      }
    );
  }

  invite(
    institutionId: string,
    email: string
  ) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/users/invite/${institutionId}`),
      {
        email
      },
      { headers: this.getHeaders() }
    );
  }

  inviteToMerchant(
    merchantId: string,
    email: string
  ) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/users/invite-to-merchant/${merchantId}`),
      {
        email
      },
      { headers: this.getHeaders() }
    );
  }

  /**
   *
   * @param userId user's ID
   * @param data object provided by Onfido's SDK onComplete().
   */
  verifyIdentity(userId: string, address: string, googlePlaceId: string, documentType: string, documentFrontId: string, documentBackId: string, faceId: string, dob?: string, ssn?: string) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/users/verify-identity/${userId}`),
      {
        address,
        googlePlaceId,
        documentType,
        documentFrontId,
        documentBackId,
        faceId,
        dob,
        ssn
      },
      {
        headers: this.getHeaders()
      }
    );
  }

  forgotPassword(email: string) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/users/forgot-password`), { email }, { headers: this.getHeaders() });
  }

  setPassword(token: string, password: string) {
    const wallet = ethers.Wallet.createRandom();
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/users/set-password/${token}`),
      {
        passwordHash: ethers.utils.keccak256(ethers.utils.toUtf8Bytes(password)),
        signingAddress: wallet.address,
        privateKey: CryptoJS.AES.encrypt(wallet.privateKey, password.trim()).toString()
      },
      { headers: this.getHeaders() }
    );
  }

  changePassword(userId: string, oldPassword: string, newPassword: string) {
    const wallet = ethers.Wallet.createRandom();
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/users/change-password/${userId}`),
      {
        oldPasswordHash: ethers.utils.keccak256(ethers.utils.toUtf8Bytes(oldPassword)),
        newPasswordHash: ethers.utils.keccak256(ethers.utils.toUtf8Bytes(newPassword)),
        signingAddress: wallet.address,
        privateKey: CryptoJS.AES.encrypt(wallet.privateKey, newPassword.trim()).toString()
      },
      { headers: this.getHeaders() }
    );
  }

  changePersonalInfo(userId: string, firstName: string, lastName: string, address: string, googlePlaceId: string) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/users/change-personal-info/${userId}`),
      {
        firstName,
        lastName,
        address: address,
        googlePlaceId
      },
      {
        headers: this.getHeaders()
      }
    );
  }

  changeEmail(userId: string, email: string) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/users/change-email/${userId}`),
      {
        email
      },
      {
        headers: this.getHeaders()
      }
    );
  }

  verifyChangeEmail(token: string) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/users/verify-change-email/${token}`), null, {
      headers: this.getHeaders()
    });
  }

  changeMobile(userId: string, mobile: string) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/users/change-mobile/${userId}`),
      {
        mobile
      },
      {
        headers: this.getHeaders()
      }
    );
  }

  verifyChangeMobile(token: string, code: string) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/users/verify-change-mobile/${token}/${code}`), null, {
      headers: this.getHeaders()
    });
  }

  changeDailyLimits(userId: string, dailyBuyLimit: number, dailySellLimit: number, dailyTransferLimit: number, dailyReceiveLimit: number, memo?: string) {
    return this.httpClient.post(
      this.formatUrl(`/v${this.environment.vesto.version}/users/change-daily-limits/${userId}`),
      {
        dailyBuyLimit,
        dailySellLimit,
        dailyTransferLimit,
        dailyReceiveLimit,
        memo
      },
      {
        headers: this.getHeaders()
      }
    );
  }

  requestDailyLimitsIncrease(userId: string) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/users/request-daily-limits-increase/${userId}`), null, {
      headers: this.getHeaders()
    });
  }

  typeahead(userId: string, keyword: string) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/users/typeahead/${userId}`), { keyword }, { headers: this.getHeaders() });
  }

  suspend(userId: string, memo?: string) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/users/suspend/${userId}`), { memo }, { headers: this.getHeaders() });
  }

  activate(userId: string, memo?: string) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/users/activate/${userId}`), { memo }, { headers: this.getHeaders() });
  }

  kycClear(userId: string, memo?: string) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/users/kyc-clear/${userId}`), { memo }, { headers: this.getHeaders() });
  }

  kycConsider(userId: string, memo?: string) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/users/kyc-consider/${userId}`), { memo }, { headers: this.getHeaders() });
  }

  setDefaultAccount(userId: string, accountId: string) {
    return this.httpClient.post(this.formatUrl(`/v${this.environment.vesto.version}/users/set-default-account/${userId}/${accountId}`), null, { headers: this.getHeaders() });
  }
}
