import {Component, OnInit} from "@angular/core";
import {BaseComponent, CoreService, StorageKeys, uiCloseModal} from "@vesto/xplat/core";
import {UntypedFormGroup, Validators} from "@angular/forms";
import {IInstitution, ITransaction} from "@vesto/vesto";
import {filter, take, takeUntil} from "rxjs/operators";
import {ofType} from "@ngrx/effects";
import {TransactionActions} from "@vesto/ngx-vesto/state";

@Component({
  selector: 'gbl-set-settle-limits',
  templateUrl: './set-settle-limits-modal.component.html'
})

export class SetSettleLimitsModalComponent extends BaseComponent implements OnInit {
  step : 'FORM_GROUP' | 'TWO_FACTOR' |'BUSY' | 'SUCCESS' | 'DETAIL' = 'FORM_GROUP';
  formGroup: UntypedFormGroup;
  institution: IInstitution;
  error: string;
  transaction: ITransaction;

  ngOnInit() {
    CoreService.institutionFacade.institution$
      .pipe(
        filter(institution => !!institution),
        takeUntil(this.destroy$)
      )
      .subscribe((institution) => {
        this.institution = institution;
    });

    this.formGroup = CoreService.formBuilder.group({
      settleTransactionLimit: [
        this.institution.settleTransactionLimit,
        {
          validators: [Validators.required, Validators.nullValidator]
        }
      ],
      settleDailyLimit: [
        this.institution.settleDailyLimit,
        {
          validators: [Validators.required, Validators.nullValidator]
        }
      ],
      instantSettleEnabled: [
        this.institution.instantSettleEnabled,
        {
          validators: [Validators.required, Validators.nullValidator]
        }
      ]
    });
  }

  sendSmsCode(isResendSms: boolean) {
    CoreService.transactionFacade.sendSmsCode();

    if (isResendSms) {
      CoreService.uiFacade.notification(`SMS code sent!`);
    }
  }

  updateLimits() {
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        console.log(result);
      });
      return;
    }
    this.step = 'TWO_FACTOR';
  }

  confirmCode(code: string) {
    this.step = 'BUSY';
    const value = this.formGroup.value;
    CoreService.transactionFacade.setSettleLimits(value.instantSettleEnabled, value.settleTransactionLimit, value.settleDailyLimit, code); // TODO: Jeff add enabled slider!!!!!

    CoreService.actions$.pipe(ofType(TransactionActions.setSettleLimitsSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      CoreService.store.dispatch(TransactionActions.sign({
        transaction: result.transaction,
        code,
        key: new Buffer(CoreService.storageService.getItem(StorageKeys.KEY), 'hex').toString()
      }));
    });

    CoreService.actions$.pipe(ofType(TransactionActions.signSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.step = 'SUCCESS';
      this.transaction = result.transaction;
      CoreService.windowService.setTimeout(() => {
        this.step = 'DETAIL';
      }, 1600);
    });

    CoreService.actions$.pipe(ofType(TransactionActions.setSettleLimitsFailure, TransactionActions.signFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
      this.step = 'FORM_GROUP';
    });
  }

  close() {
    uiCloseModal();
  }
}
