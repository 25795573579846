import { Directive, inject } from '@angular/core';
import { BaseComponent, PlatformDeviceInfoToken } from '@vesto/xplat/core';

@Directive()
export abstract class SettingsBaseComponent extends BaseComponent {
  versionDisplay = inject(PlatformDeviceInfoToken).versionDisplay;

  constructor() {
    super();
  }
}
