import { Component } from '@angular/core';
import {FeatureService, PaymentMethodsBaseComponent} from '@vesto/xplat/features';

@Component({
  selector: 'gbl-payment-methods',
  templateUrl: 'payment-methods.component.html'
})
export class PaymentMethodsComponent extends PaymentMethodsBaseComponent {
  openAddPaymentMethodModal() {
    FeatureService.dashboardFacade.openOrGoToAddPaymentMethod();
  }
}
