<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <h3 class="section-number"><i class="fal fa-envelope-open-text"></i> Change Email</h3>
</div>
<mat-dialog-content>
  <ng-container [ngSwitch]="step">
    <form *ngSwitchCase="'FORM_GROUP'" [formGroup]="formGroup" [hidden]="emailSent" (ngSubmit)="changeEmail()">
      <p class="text-sm">Enter your new email address below and we will send you an email with a confirmation link.</p>
      <div class="vesto-input mt-3">
        <input type="text" formControlName="email" id="email" name="email" />
      </div>

      <gbl-alert [type]="error" [message]="error"></gbl-alert>

      <button class="button button-primary mt-5 w-full" type="submit">Change Email</button>
    </form>

    <div *ngSwitchCase="'SUCCESS'" class="text-center">
    <span class="fa-stack fa-3x inline-block my-6">
      <i class="fal fa-circle-notch fa-stack-2x fa-spin text-gold"></i>
      <i class="fal fa-envelope-open-text fa-stack-1x"></i>
    </span>
      <h3 class="mb-5">Confirm Your Email</h3>
      <small>We've sent an email to <strong>{{ newEmail }}</strong>. Click the link in the email to confirm your new email address.</small>
    </div>
  </ng-container>
</mat-dialog-content>
