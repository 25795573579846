import { Component, OnInit } from '@angular/core';
import { BaseComponent, CoreService, StorageKeys, UiActions } from '@vesto/xplat/core';
import { Blockchain, ISystem, ITransaction } from '@vesto/vesto';
import { take, takeUntil } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';
import { TransactionActions } from '@vesto/ngx-vesto/state';

@Component({
  selector: 'gbl-choose-blockchain-modal',
  templateUrl: './choose-blockchain-modal.component.html'
})
export class ChooseBlockchainModalComponent extends BaseComponent {
  step: 'CHOOSE_BLOCKCHAIN' | 'TWO_FACTOR' | 'BUSY' | 'SUCCESS' | 'TRANSACTION_DETAIL' = 'CHOOSE_BLOCKCHAIN';
  action: 'sync implementation addresses' | 'sync addresses';
  Blockchain = Blockchain;
  blockchain: Blockchain;
  error: string;
  transaction: ITransaction;

  sendSmsCode(isResendSms: boolean) {
    CoreService.transactionFacade.sendSmsCode();

    if (isResendSms) {
      CoreService.uiFacade.notification(`SMS code sent!`);
    }
  }

  chooseBlockchain(blockchain: Blockchain) {
    this.blockchain = blockchain;
    this.step = 'TWO_FACTOR';
  }

  confirmCode(code: string) {
    this.step = 'BUSY';
    switch (this.action) {
      case 'sync implementation addresses':
        CoreService.transactionFacade.syncImplementationAddresses(this.blockchain, code);
        break;
      case 'sync addresses':
        CoreService.transactionFacade.syncAddresses(this.blockchain, code);
        break;
    }

    CoreService.actions$.pipe(ofType(TransactionActions.syncImplementationAddressesSuccess, TransactionActions.syncAddressesSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      CoreService.store.dispatch(TransactionActions.sign({ transaction: result.transaction, code, key: new Buffer(CoreService.storageService.getItem(StorageKeys.KEY), 'hex').toString() }));
    });

    CoreService.actions$.pipe(ofType(TransactionActions.syncImplementationAddressesFailure, TransactionActions.syncAddressesFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
      this.step = 'CHOOSE_BLOCKCHAIN';
    });

    CoreService.actions$.pipe(ofType(TransactionActions.signSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.step = 'SUCCESS';
      this.transaction = result.transaction;
      CoreService.windowService.setTimeout(() => {
        this.step = 'TRANSACTION_DETAIL';
      }, 2200);
    });

    CoreService.actions$.pipe(ofType(TransactionActions.signFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
    });
  }

  close() {
    CoreService.store.dispatch(UiActions.closeModal());
  }
}
