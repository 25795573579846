<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <h3 class="section-number"><i class="fal fa-user-plus"></i> Invite User</h3>
</div>
<mat-dialog-content>
  <ng-container [ngSwitch]="step">
<!--    <div *ngIf="UserUtil.hasRole(user, RoleType.MERCHANT)" class="my-4 flex text-sm items-center">
      <span class="mr-3">Invite to {{ user?.merchant }}</span>
      <mat-slide-toggle [(ngModel)]="inviteToMerchant"></mat-slide-toggle>

      <p class="text-sm mb-3" *ngIf="inviteToMerchant">This user will be able to make transactions on behalf of {{ user?.merchant.name }}, any sales they make will got to {{ user?.merchant.name }}'s operational account.</p>
    </div>-->

    <form [formGroup]="formGroup" (ngSubmit)="inviteUser()" *ngSwitchCase="'FORM_GROUP'">
      <div class="vesto-input mb-4">
        <label for="email">Email</label>
        <input type="text" focusMe="true" formControlName="email" id="email" name="email" />
      </div>

      <div *ngIf="UserUtil.hasRole(user, RoleType.SUPER_ADMIN)" class="vesto-input vesto-select mb-4">
        <ng-select formControlName="institutionId" placeholder="Choose institution (optional)">
          <ng-option *ngFor="let institution of institutions" [value]="institution.id">
            {{ institution.name }}
          </ng-option>
        </ng-select>
      </div>

      <div *ngIf="UserUtil.hasRole(user, RoleType.ADMIN) && !UserUtil.hasRole(user, RoleType.SUPER_ADMIN)" class="vesto-input vesto-select mb-4">
        <ng-select formControlName="institutionId" placeholder="Choose merchant (optional)">
          <ng-option *ngFor="let merchant of merchants" [value]="merchant.id">
            {{ merchant.name }}
          </ng-option>
        </ng-select>
      </div>

      <gbl-alert [type]="error" [message]="error"></gbl-alert>

      <button class="button button-primary w-full mt-4" type="submit">Invite</button>
    </form>

    <section *ngSwitchCase="'BUSY'" class="mt-4">
      <div class="placeholder-long bg-flash"></div>
      <div class="placeholder-area bg-flash mt-4"></div>
    </section>

    <section *ngSwitchCase="'SUCCESS'">
      <gbl-lottie-player></gbl-lottie-player>
    </section>

    <section *ngSwitchCase="'DETAIL'" class="slide-in">
      <div class="text-center">
        <p class="mt-4">Success!</p>
      </div>
      <div class="p-4 bg-trans-blue rounded-lg text-center">
        <p class="text-sm">
          You invited {{ inviteEmail }}
        </p>
      </div>
      <button class="button button-primary my-4 mx-auto w-full" (click)="close()">Close</button>
    </section>
  </ng-container>
</mat-dialog-content>
