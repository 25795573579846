import { ViewContainerRef } from '@angular/core';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ICountryCode } from '../../interfaces';

export namespace UiState {
  export const Locale = Object.freeze({
    en: 'en'
    // es: 'es',
    // fr: 'ru,',
    // it: 'zh'
  });

  export type Locale = keyof typeof Locale;

  export interface IModalOptions {
    size?: 'lg' | 'md' | 'sm';
    width?: any;
    height?: any;
    minHeight?: any;
    keyboard?: boolean;
    backdrop?: 'static';
    backdropClass?: string;
    panelClass?: string;
    viewContainerRef?: ViewContainerRef;
    fullscreen?: boolean;
    target?: any;
  }

  export interface IModalStateOptions<T = any> {
    cmpType: any;
    props?: T;
    modalOptions?: IModalOptions;
  }

  export interface IModalState {
    /**
     * Whether modal is open or not
     */
    open: boolean;
    /**
     * Component to open
     */
    cmpType?: any;
    /**
     * Latest result passed back when the modal closed (if any)
     */
    latestResult?: any;
  }

  export interface IState {
    locale: string;
    spinner: boolean;
    countryCodes: ICountryCode[];
    modal: IModalState;
  }

  export const initialState: IState = {
    locale: Locale.en,
    spinner: false,
    countryCodes: [],
    modal: {
      open: false
    }
  };

  export const featureState = createFeatureSelector<IState>('ui');
  export const locale = createSelector(featureState, (state: IState) => state.locale);
  export const spinner = createSelector(featureState, (state: IState) => state.spinner);
  export const countryCodes = createSelector(featureState, (state: IState) => state.countryCodes);
  export const modal = createSelector(featureState, (state: IState) => state.modal);
  export const selectModalResult = createSelector(modal, (modal: UiState.IModalState) => modal.latestResult);
}
