import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { LogService, WindowService, EventBusService, OnfidoService } from '@vesto/xplat/core';
import { DOCUMENT } from '@angular/common';
import { take, filter } from 'rxjs/operators';
import { appLaunchParams } from '@vesto/xplat/utils';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class WebHelperService {
  // lazily loaded js scripts
  private _lazyLoadedScripts: {
    [key: string]: boolean;
  };
  // web sdk instances
  private _onfidoInstance: any;
  // route control
  routeFlags = {
    isPendingPage: false
  };
  routeScrollTopPrevent: boolean;
  private _routeScrollTopPreventTimeout: number;

  constructor(
    private _store: Store<any>,
    private _log: LogService,
    private _win: WindowService,
    private _eventBus: EventBusService,
    private _ngRouter: Router,
    private _onfido: OnfidoService,
    @Inject(DOCUMENT)
    private _document: Document
  ) {
    // various web prep and/or initializers of services
    this._prepareOnfido();
    this._initRouterEvents();
  }

  /**
   * Web parse for supported query params
   */
  parseUrlParams(): {
    queryParams: any;
    isVerifySignUpEmail: boolean;
    isVerifyChangeEmail: boolean;
    isForgotPassword: boolean;
    isInviteUser: boolean;
  } {
    const url = this._win.location.href;
    if (url.indexOf('?') > -1) {
      const allParams = url.split('?')[1].split('&');
      const queryParams: any = {};
      allParams.forEach((p) => {
        const keyValue = p.split('=');
        queryParams[keyValue[0]] = keyValue[1];
      });
      return {
        queryParams,
        isVerifySignUpEmail: url.indexOf(appLaunchParams.verifySignUpEmailToken) > -1,
        isVerifyChangeEmail: url.indexOf(appLaunchParams.verifyChangeEmailToken) > -1,
        isForgotPassword: url.indexOf(appLaunchParams.forgotPasswordToken) > -1,
        isInviteUser: url.indexOf(appLaunchParams.inviteUserToken) > -1
      };
    }
    return null;
  }

  adjustScrollTop(yTop: number = 0, delay: number = 100, preventRoute?: boolean) {
    const fragment = this._win.location.hash;
    if (fragment) {
      return;
    }
    const winScrollTop = () => {
      this._win.setTimeout(() => {
        this._win.scrollTo(0, yTop);
      }, delay);
    };
    if (preventRoute) {
      this.routeScrollTopPrevent = true;
      // preventing route handling to instead handle manually
      winScrollTop();
    }
    if (!this.routeScrollTopPrevent) {
      winScrollTop();
    } else {
      if (typeof this._routeScrollTopPreventTimeout !== 'undefined') {
        this._win.clearTimeout(this._routeScrollTopPreventTimeout);
        this._routeScrollTopPreventTimeout = undefined;
      }
      this._routeScrollTopPreventTimeout = this._win.setTimeout(() => {
        this.routeScrollTopPrevent = false;
      }, 1000);
    }
  }

  loadScriptAsync(
    scriptPath: string,
    options?: {
      id?: string;
      attributes?: any;
      onload?: () => void;
    }
  ) {
    if (this._win.isBrowser && scriptPath) {
      if (!this._lazyLoadedScripts) {
        this._lazyLoadedScripts = {};
      }
      if (this._lazyLoadedScripts[scriptPath]) {
        // already loaded
        if (options && options.onload) {
          options.onload();
        }
      } else {
        // prevent loading scripts repeatedly
        this._lazyLoadedScripts[scriptPath] = true;
        const lazyScript = this._document.createElement('script');
        lazyScript.type = 'text/javascript';
        lazyScript.defer = true;
        lazyScript.src = '//' + scriptPath;
        // example:
        // lazyScript.src =
        //   ('https:' == document.location.protocol ? 'https://' : 'http://') +
        //   'www.paypalobjects.com/api/checkout.min.js';
        if (options) {
          if (options.id) {
            lazyScript.id = options.id;
          }
          if (options.attributes) {
            for (const key in options.attributes) {
              lazyScript.setAttribute(key, options.attributes[key]);
              // example:
              // lazyScript.setAttribute('data-version-4', 'data-version-4');
            }
          }
          if (options.onload) {
            lazyScript.onload = options.onload;
          }
        }
        const s = this._document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(lazyScript, s);
      }
    }
  }

  private _initRouterEvents() {
    this._ngRouter.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((evt: NavigationEnd) => {
      this.adjustScrollTop();
      if (this._ngRouter.url) {
        this.routeFlags.isPendingPage = this._ngRouter.url.indexOf('pending') > -1;
      }
    });
  }

  private _prepareOnfido() {
    this._onfido.token$.subscribe((token) => {
      if (this._win.isBrowser) {
        this.loadScriptAsync(`${this._win.location.host}/lazy-onfido.js`, {
          onload: () => {
            //this._initOnfidoSDK(token);
          }
        });
      }
    });
  }

  // private _initOnfidoSDK(token: string) {
  //   if (this._win.Onfido) {
  //     if (!this._document.getElementById('onfido-mount')) {
  //       const onfidoNode = this._document.createElement('div');
  //       onfidoNode.setAttribute('id', 'onfido-mount');
  //       onfidoNode.setAttribute('class', 'onfido-mount');
  //       this._document.getElementsByTagName('body')[0].appendChild(onfidoNode);
  //     }
  //     this._store.pipe(select(UIState.getLocale), take(1)).subscribe(locale => {
  //       this._log.debug('locale:', locale);
  //       this._progress.toggleSpinner(false);
  //       this._onfidoInstance = this._win.Onfido.init({
  //         token,
  //         containerId: 'onfido-mount',
  //         useModal: true,
  //         isModalOpen: true,
  //         shouldCloseOnOverlayClick: false,
  //         smsNumberCountryCode: 'US', // TODO: make i18n
  //         language: locale,
  //         // {
  //         //   locale,
  //         //   phrases: {
  //
  //         //   },
  //         //   mobilePhrases: {
  //
  //         //   }
  //         // },
  //         userDetails: {
  //           smsNumber: this._userService.user.mobile
  //         },
  //         steps: [
  //           {
  //             type: 'welcome',
  //             options: {
  //               title: `In order to create a Vesto account we must first verify your identity.`,
  //               descriptions: [
  //                 `Click the button below to begin this quick and secure process.`
  //               ]
  //             }
  //           },
  //           'document',
  //           'face'
  //         ],
  //         onComplete: (data: any) => {
  //           this._log.debug('onfido onComplete:', data);
  //           this._eventBus.emit(this._eventBus.types.onfidoComplete, data);
  //           if (this._onfidoInstance) {
  //             this._onfidoInstance.setOptions({ isModalOpen: false });
  //
  //             // TODO: determine if we really need to tear whole sdk down here
  //             this._onfidoTeardown();
  //           }
  //         },
  //         onError: (error: any) => {
  //           this._log.debug('onfido onError:', error);
  //           this._eventBus.emit(this._eventBus.types.onfidoError, error);
  //         },
  //         onModalRequestClose: () => {
  //           this._log.debug('onfido onModalRequestClose');
  //           this._eventBus.emit(this._eventBus.types.onModalRequestClose);
  //           if (this._onfidoInstance) {
  //             this._onfidoInstance.setOptions({ isModalOpen: false });
  //
  //             // TODO: determine if we really need to tear whole sdk down here
  //             this._onfidoTeardown();
  //           }
  //         }
  //       });
  //     });
  //   }
  // }

  private _onfidoTeardown() {
    if (this._onfidoInstance) {
      if (this._onfidoInstance.tearDown) {
        this._onfidoInstance.tearDown();
        this._onfidoInstance = null;
      }
    }
  }
}
