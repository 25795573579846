import { NgModule } from '@angular/core';
import { SignInModule as SharedSignInModule } from '@vesto/xplat/features';
import { UIModule } from '../ui/ui.module';
import { SIGN_IN_COMPONENTS } from './components';
import { EffectsModule } from '@ngrx/effects';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { WebSignInEffects } from './state/web-sign-in.effects';

@NgModule({
  imports: [SharedSignInModule, MatSnackBarModule, UIModule, EffectsModule.forFeature([WebSignInEffects])],
  declarations: [...SIGN_IN_COMPONENTS],
  exports: [...SIGN_IN_COMPONENTS]
})
export class SignInModule {}
