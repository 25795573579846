import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LogService } from './log.service';
import { environment } from '../environments/environment';
import { OnfidoSDKToken, IOnfidoSDK } from './tokens';
import { EventBusService } from './eventbus.service';
import { IUser } from '@vesto/vesto';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { WindowService } from './window.service';
import { Store } from '@ngrx/store';
import { UserFacade, UserActions } from '@vesto/ngx-vesto/state';
import { Actions, ofType } from '@ngrx/effects';

@Injectable()
export class OnfidoService {
  // private _onfidoInstance: any;
  private _token$: Subject<string>;
  private _subsDone$: Subject<boolean>;

  constructor(private _store: Store<any>, private _actions$: Actions<any>, private _log: LogService, private _http: HttpClient, private _win: WindowService, private _eventBus: EventBusService, private _userFacade: UserFacade, @Inject(OnfidoSDKToken) private _onfidoSdk: IOnfidoSDK) {}

  get token$() {
    if (!this._token$) {
      this._token$ = new Subject();
    }
    return this._token$;
  }

  set token(value: string) {
    if (this._token$) {
      this._token$.next(value);
    }
  }

  init(applicantId: string) {
    return new Promise((resolve, reject) => {
      //this._progress.toggleSpinner(true);
      // const apiKey = this._win.isBrowser
      //   ? environment.production
      //     ? environment.onfido.web.live
      //     : environment.onfido.web.sandbox
      //   : environment.production
      //   ? environment.onfido.mobile.live
      //   : environment.onfido.mobile.sandbox;

      this._actions$.pipe(ofType(UserActions.createOnfidoSdkTokenSuccess), take(1)).subscribe(({ token }) => {
        if (token) {
          this._cleanup();
          this._log.debug('onfido token:', token);
          this.token = token;
          resolve(token);
        }
      });

      if (!this._subsDone$) {
        this._subsDone$ = new Subject();
        this._actions$.pipe(ofType(UserActions.createOnfidoSdkTokenFailure), takeUntil(this._subsDone$)).subscribe(({ apiError }) => {
          if (apiError) {
            this._log.debug('onfido error:', apiError);
            this._cleanup();
            this._win.alert(`We encountered an issue. Please try again.`);
          }
        });
      }
      //   this._userFacade.createOnfidoSdkToken(applicantId);
    });
  }

  private _cleanup() {
    if (this._subsDone$) {
      this._subsDone$.next(true);
      this._subsDone$.complete();
      this._subsDone$ = null;
    }
  }
}
