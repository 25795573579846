import { Pipe, PipeTransform } from '@angular/core';
import { AccountType, IAccount, ITransaction, TransactionType } from '@vesto/vesto';

@Pipe({
  name: 'transactionText',
  pure: true
})
export class TransactionTextPipe implements PipeTransform {
  transform(transaction: ITransaction, account: IAccount): any {
    if (!transaction) {
      return '';
    }
    switch (transaction.type) {
      case TransactionType.TRANSFER:
      case TransactionType.PAYMENT:
        if (account && transaction.accountId === account.id) {
          return `Sent ${transaction.token?.symbol}`;
        } else return `Received ${transaction.token?.symbol}`;
      case TransactionType.BUY:
        return `Bought ${transaction.token.symbol}`;
      case TransactionType.SELL:
        return `Sold ${transaction.token.symbol}`;
      case TransactionType.INTEREST_PAYMENT:
        return `${transaction.token.symbol} Reward`;
      case TransactionType.MINT:
        return `Minted ${transaction.token.symbol}`;
      case TransactionType.DEPLOY_VWALLET:
        return 'VWallet Deployed';
      case TransactionType.FEE:
        return !transaction.referenceTransaction ? '' : `Collected ${transaction.token.symbol}`;
      case TransactionType.SYNC:
        return transaction.token ? `Synced ${transaction?.token?.symbol}` : 'Synced';
      case TransactionType.SYNC_PRICE_PER_TOKENS:
        return 'Synced Price Per Tokens';
      case TransactionType.SYNC_ADDRESSES:
        return 'Synced Addresses';
      case TransactionType.SYNC_IMPLEMENTATION_ADDRESSES:
        return 'Synced Implementation Addresses';
      case TransactionType.ADD_USER:
      case TransactionType.ADD_ADDITIONAL_USER:
        return 'Added User';
      case TransactionType.REMOVE_ADDITIONAL_USER:
        return 'Removed User';
      case TransactionType.ADD_YEARN_VAULT:
        return 'Added Yearn Vault';
      case TransactionType.PAYOUT:
        return 'Payout';
      case TransactionType.SWAP_SIGNING_ADDRESSES:
        return 'Swap Signing Addresses';
      case TransactionType.DEPLOY_VLIGHTHOUSE:
        return 'Deployed VLighthouse';
      case TransactionType.DEPLOY_VUSER:
        return 'Deployed VUser';
      case TransactionType.DEPLOY_VDEFI:
        return 'Deployed VDeFi';
      case TransactionType.DEPLOY_VBRIDGE:
        return 'Deployed VBridge';
      case TransactionType.DEPLOY_VSWAP:
        return 'Deployed VSwap';
      case TransactionType.DEPLOY_VERC20:
        return 'Deployed VERC20';
      case TransactionType.DEPLOY_YEARN_VAULT:
        return 'Deployed Yearn Vault';
      case TransactionType.DEPLOY_VLIGHTHOUSE_PROXY:
        return 'Deployed VLighthouse Proxy';
      case TransactionType.DEPLOY_VERC20_PROXY:
        return 'Deployed VERC20 Proxy';
      case TransactionType.DEPLOY_VWALLET_PROXY:
        return 'Deployed VWallet Proxy';
      case TransactionType.INITIALIZE:
        return 'Contract Initialized';
      case TransactionType.CHANGE_ROLE:
        return 'Changed User Role';
      case TransactionType.SETTLE:
        return `Settled ${transaction.token.symbol}`;
      case TransactionType.SET_SETTLE_LIMITS:
        return 'Updated Settle Limits';
      case TransactionType.SET_DAILY_LIMITS:
        return 'Updated Daily Limits';
      case TransactionType.WIRE:
        return `Wire from ${transaction.address} to ${transaction.recipientAddress}`;
      case TransactionType.TIP:
        return 'Tip Received';
      case TransactionType.SYNC_APYS:
        return 'Synced APYs';
      case TransactionType.SET_UNDERLYING_SUPPLY_LIMIT:
        return 'Set Underlying Supply Limit';
      default:
        return '';
    }
  }
}
