<div class="flex items-center border-bottom-light pb-2">
  <p class="flex-grow text-sm">
    {{ getType(contract) }}<span class="block text-xs">{{ isProxy(contract) ? '(Proxy)' : '(Implementation)' }}</span>
  </p>
  <div *ngIf="contract.error" [matTooltip]="contract.error"><i class="fad fa-exclamation-triangle text-gold"></i></div>
  <button *ngIf="UserUtil.hasRole(user, RoleType.SUPER_ADMIN) && !isProxy(contract)" class="icon-button text-vesto-primary" (click)="deployContract(contract)" matTooltip="Deploy">
    <i class="fal fa-external-link"></i>
  </button>
</div>
<ng-container *ngIf="contract.status !== ContractStatus.BUSY; else contractBusyTemplate">
  <div class="flex mt-3 text-sm justify-between">
    <div>Version:</div>
    <b>{{ contract.version }}</b>
  </div>
  <div class="flex mt-3 text-sm justify-between items-center">
    <div>Blockchain:</div>
    <div class="flex">
      <a target="_blank" (click)="onEvent($event)" [href]="contract.url"><img [src]="contract.blockchain | blockchain" [style.width]="contract.blockchain === Blockchain.ETHEREUM ? '10px' : '16px'" class="mr-2 -mt-1" />{{ contract.blockchain | titlecase }}</a>
    </div>
  </div>
  <div class="flex mt-3 text-sm justify-between">
    <div>Created:</div>
    <b>{{ contract.created | date: 'short' }}</b>
  </div>
</ng-container>

<ng-template #contractBusyTemplate>
  <div class="text-center p-4 mt-10">
    <i class="fa fa-spinner fa-pulse fa-2x"></i>
  </div>
</ng-template>
