import { Component, OnInit } from '@angular/core';
import { BaseComponent, CoreService, UiActions } from '@vesto/xplat/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import {take, takeUntil} from 'rxjs/operators';
import {Blockchain, IGasSettings, IGasTank, ISystem} from '@vesto/vesto';
import {ofType} from "@ngrx/effects";
import {GasTankActions} from '@vesto/ngx-vesto/state';

@Component({
  selector: 'gbl-change-gas-tank-modal',
  templateUrl: './change-gas-tank-modal.component.html'
})
export class ChangeGasTankModalComponent extends BaseComponent implements OnInit {
  step: 'FORM_GROUP' | 'BUSY' | 'SUCCESS' | 'DETAIL' = 'FORM_GROUP'
  formGroup: UntypedFormGroup;
  confirmCode = true;
  error: string;
  gasTank: IGasTank;
  blockchain: Blockchain;
  gasSettings: IGasSettings;

  ngOnInit() {
    this.gasTank.gasSettings.forEach(gasSettings => {
      if (this.blockchain === gasSettings.blockchain) {
        this.gasSettings = gasSettings;
        return;
      }
    });
    this.formGroup = CoreService.formBuilder.group({
      minPrice: [
        this.gasSettings.minPrice,
        {
          validators: [Validators.required, Validators.nullValidator]
        }
      ],
      maxPrice: [
        this.gasSettings.maxPrice,
        {
          validators: [Validators.required, Validators.nullValidator]
        }
      ],
      limit: [
        this.gasSettings.limit,
        {
          validators: [Validators.required, Validators.nullValidator]
        }
      ]
    });
  }

  changeGasLimits() {
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }

    CoreService.actions$.pipe(ofType(GasTankActions.changeSettingsSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      CoreService.windowService.setTimeout(() => {
        this.step = 'SUCCESS';
        CoreService.windowService.setTimeout(() => {
          this.step = 'DETAIL';
        }, 1600);
      }, 1600);
    });

    CoreService.actions$.pipe(ofType(GasTankActions.changeSettingsFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.step = 'FORM_GROUP'
      this.error = `apiErrors.${result.apiError.error}`;
    });

    this.confirmCode = true;
    const value = this.formGroup.value;
    CoreService.gasTankFacade.changeSettings(this.gasTank.id, this.blockchain, value.minPrice, value.maxPrice, value.limit);
    this.step = 'BUSY';
  }

  close() {
    CoreService.store.dispatch(UiActions.closeModal());
  }
}
