import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IEnvironment, ObjectUtil } from '@vesto/vesto';
import { Action, Store } from '@ngrx/store';
import { BankAccountActions } from './bank-account.actions';
import { EnvironmentToken, BankAccountsApiService } from '../../services';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { ApiActions } from '../api';
import { UserFacade } from '../../state/user';
import { VestoState } from '../vesto.state';

@Injectable()
export class BankAccountEffects {
  constructor(private userFacade: UserFacade, private actions$: Actions, @Inject(EnvironmentToken) protected environment: IEnvironment, protected store: Store<VestoState.IState>, private bankAccountsApiService: BankAccountsApiService) {}

  createLinkToken$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(BankAccountActions.createLinkToken),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{ extras }, user]) => {
          return this.bankAccountsApiService.createLinkToken(user.id).pipe(
            mergeMap((result) => {
              return from([BankAccountActions.createLinkTokenSuccess({ token: result.token, extras })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([BankAccountActions.createLinkTokenFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  findByUser$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(BankAccountActions.findByUser),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{}, user]) => {
          return this.bankAccountsApiService.findByUser(user.id).pipe(
            mergeMap((result) => {
              return from([BankAccountActions.findByUserSuccess({ bankAccounts: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([BankAccountActions.findByUserFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  create$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(BankAccountActions.create),
        withLatestFrom(this.userFacade.user$),
        mergeMap(([{ bankAccount }, user]) => {
          return this.bankAccountsApiService.create(user.id, bankAccount).pipe(
            mergeMap((result) => {
              return from([BankAccountActions.createSuccess({ bankAccount: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([BankAccountActions.createFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  setDefault$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(BankAccountActions.setDefault),
        mergeMap(({ id }) => {
          return this.bankAccountsApiService.setDefault(id).pipe(
            mergeMap((result) => {
              return from([BankAccountActions.setDefaultSuccess({ bankAccount: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([BankAccountActions.setDefaultFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );

  remove$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(BankAccountActions.remove),
        mergeMap(({ id }) => {
          return this.bankAccountsApiService.remove(id).pipe(
            mergeMap((result) => {
              return from([BankAccountActions.removeSuccess({ bankAccount: result })]);
            }),
            catchError((error) => {
              const apiError = ObjectUtil.toApiError(error);
              return from([BankAccountActions.removeFailure({ apiError }), ApiActions.setError({ apiError })]);
            })
          );
        })
      )
  );
}
