import {Directive, OnInit} from '@angular/core';
import {BaseComponent, CoreService, uiCloseModal} from '@vesto/xplat/core';
import {
  IAccount,
  ITransaction, IUser, RoleType, TransactionStatus, TransactionType, UserUtil,
} from '@vesto/vesto';
import {take, takeUntil} from "rxjs";
import {ofType} from "@ngrx/effects";
import {TransactionActions} from "@vesto/ngx-vesto/state";

@Directive()
export abstract class TransactionDetailModalBaseComponent extends BaseComponent implements OnInit {
  step: 'TRANSACTION' | 'TWO_FACTOR' | 'BUSY' | 'SUCCESS' | 'DETAIL' = 'TRANSACTION';
  user: IUser;
  selectedAccount: IAccount;
  transaction: ITransaction;
  TransactionType = TransactionType;
  TransactionStatus = TransactionStatus;
  buttonText: string;
  UserUtil = UserUtil;
  RoleType = RoleType;
  error: string;
  shouldShowMemo = false;

  ngOnInit() {
    if (this.transaction.token?.ceFi && this.transaction.type === TransactionType.BUY && this.transaction.status === TransactionStatus.TRANSFERRING_TO_VESTO) {
      this.buttonText = 'Set to Transferred to Vesto';
    } else if (this.transaction.type === TransactionType.WIRE && this.transaction.status === TransactionStatus.PENDING) {
      this.buttonText = 'Set to Transferring to Circle';
      this.shouldShowMemo = true;
    }
  }

  updateStatus() {
    this.step = 'TWO_FACTOR';
  }

  sendSmsCode(isResendSms: boolean) {
    CoreService.transactionFacade.sendSmsCode();

    if (isResendSms) {
      CoreService.uiFacade.notification(`SMS code sent!`);
    }
  }

  confirmCode(code: string) {
    this.step = 'BUSY';
    CoreService.actions$.pipe(ofType(TransactionActions.changeStatusSuccess), take(1), takeUntil(this.destroy$)).subscribe(() => {
      this.step = 'SUCCESS';
      CoreService.windowService.setTimeout(() => {
        this.step = 'DETAIL';
      }, 1600);
    });
    CoreService.actions$.pipe(ofType(TransactionActions.changeStatusFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
      this.step = 'TRANSACTION';
    });

    // BUY TRANSACTIONS
    if (this.transaction.type === TransactionType.BUY && this.transaction.status === TransactionStatus.TRANSFERRING_TO_VESTO) {
      CoreService.transactionFacade.changeStatus(this.transaction.id, TransactionStatus.TRANSFERRED_TO_VESTO, code);
    }

    // WIRE TRANSACTIONS
    if (this.transaction.type === TransactionType.WIRE && this.transaction.status === TransactionStatus.PENDING) {
      CoreService.transactionFacade.changeStatus(this.transaction.id, TransactionStatus.TRANSFERRING_TO_CIRCLE, code);
    }

    // SELL TRANSACTIONS
    if (this.transaction.type === TransactionType.SELL && this.transaction.status === TransactionStatus.TRANSFERRING_TO_CIRCLE) {
      CoreService.transactionFacade.changeStatus(this.transaction.id, TransactionStatus.TRANSFERRED_TO_CIRCLE, code);
    }
    if (this.transaction.type === TransactionType.SELL && this.transaction.status === TransactionStatus.TRANSFERRED_TO_VESTO) {
      CoreService.transactionFacade.changeStatus(this.transaction.id, TransactionStatus.TRANSFERRING_TO_USER, code);
    }
    if (this.transaction.type === TransactionType.SELL && this.transaction.status === TransactionStatus.TRANSFERRING_TO_USER) {
      CoreService.transactionFacade.changeStatus(this.transaction.id, TransactionStatus.SUCCESS, code);
    }
  }

  close() {
    uiCloseModal();
  }
}
