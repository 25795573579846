import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IStore} from '@vesto/vesto';
import {select, Store} from '@ngrx/store';
import {VestoSelectors, VestoState} from '../vesto.state';
import {StoreActions} from './store.actions';

@Injectable({
  providedIn: 'root'
})
export class StoreFacade {
  stores$: Observable<IStore[]> = this.store.pipe(select(VestoSelectors.stores));

  constructor(private store: Store<VestoState.IState>) {
  }

  find(
    id: string
  ) {
    this.store.dispatch(StoreActions.find({ id }));
  }

  findByMerchant(
    merchantId: string
  ) {
    this.store.dispatch(StoreActions.findByMerchant({merchantId}));
  }

  create(
    merchantId: string,
    name: string,
    address: string
  ) {
    this.store.dispatch(StoreActions.create({merchantId, name, address}));
  }


  changeSettings(
    id: string,
    name: string,
    address: string
  ) {
    this.store.dispatch(StoreActions.changeSettings({id, name, address}));
  }

  remove(
    id: string
  ) {
    this.store.dispatch(StoreActions.remove({id}));
  }
}
