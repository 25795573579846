import {Directive, OnInit} from '@angular/core';
import {BaseComponent, CoreService, StorageKeys, uiCloseModal} from '@vesto/xplat/core';
import {
  AccountStatus,
  AccountType,
  IAccount,
  IAsset,
  IBankAccount,
  ITransaction,
  IUser, Symbol,
  UserStatus
} from '@vesto/vesto';
import {UntypedFormGroup, Validators} from '@angular/forms';
import {distinctUntilChanged, filter, take, takeUntil} from 'rxjs/operators';
import {ofType} from '@ngrx/effects';
import {BankAccountActions, TransactionActions} from '@vesto/ngx-vesto/state';
import {FeatureService} from '../../../services';
import {DashboardActions} from '../../state';

@Directive()
export abstract class BuySellModalBaseComponent extends BaseComponent implements OnInit {
  step: 'FORM_GROUP' | 'TWO_FACTOR' | 'BUSY' | 'SUCCESS' | 'TRANSACTION_DETAIL' | 'CHOOSE_CREATE_PAYMENT_METHOD_TYPE' | 'CHOOSE_PLAID_ACCOUNT' = 'FORM_GROUP';
  selectedTab = 'buy';
  accounts: IAccount[] = [];
  selectedAccount: IAccount;
  bankAccounts: IBankAccount[] = [];
  plaidBankAccounts: IBankAccount[] = [];
  selectedBankAccount: IBankAccount;
  formGroup: UntypedFormGroup;
  paymentMethodFormGroup: UntypedFormGroup;
  selectedAsset: IAsset;
  error: string;
  transaction: ITransaction;
  user: IUser;
  UserStatus = UserStatus;
  AccountType = AccountType;
  AccountStatus = AccountStatus;
  showAddBankAccount = false;


  ngOnInit() {
    this.selectedTab = this.selectedTab || 'buy';
    this.formGroup = CoreService.formBuilder.group(
      {
        amount: [
          0,
          {
            validators: [Validators.required, Validators.nullValidator, Validators.min(1)]
          }
        ],
        accountId: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        symbol: [ // TODO: Change to asset
          null,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        bankAccountId: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ]
      },
      { updateOn: 'blur' }
    );

    this.paymentMethodFormGroup = CoreService.formBuilder.group(
      {
        institution: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator]
          }
        ],
        routing: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator, Validators.minLength(9), Validators.maxLength(9)]
          }
        ],
        account: [
          null,
          {
            validators: [Validators.required, Validators.nullValidator, Validators.minLength(5)]
          }
        ],
        // confirmAccount: [
        //   null,
        //   {
        //     validators: [Validators.required, Validators.nullValidator, Validators.minLength(5)]
        //   }
        // ],
      },
      { updateOn: 'blur' }
    );
    CoreService.userFacade.user$
      .pipe(
        filter(user => !!user),
        takeUntil(this.destroy$)
      )
      .subscribe((user) => {
        this.user = user;
      });
    CoreService.accountFacade.accounts$
      .pipe(
        filter((accounts) => !!accounts),
        takeUntil(this.destroy$)
      )
      .subscribe((accounts) => {
        this.accounts = [];
        accounts.forEach(account => {
          if (account.type === AccountType.WALLET || account.type === AccountType.OPERATIONAL || account.type === AccountType.PAYOUT) {
            this.accounts.push(account);
          }
        });
      });
    FeatureService.dashboardFacade.selectedAccount$
      .pipe(
        filter(selectedAccount => !!selectedAccount),
        takeUntil(this.destroy$)
      )
      .subscribe(selectedAccount => {
        if (selectedAccount.type === AccountType.WALLET) {
          this.selectedAccount = selectedAccount;
          this.formGroup.controls.accountId.setValue(this.selectedAccount.id);
        } else {
          this.accounts.forEach(account => {
            if (account.id === this.user.defaultAccountId) {
              this.selectedAccount = account;
              return;
            }
          });
        }
        this.selectedAccount.assets.forEach((asset) => {
          if (asset.symbol === Symbol.VSUSD) {
            this.selectedAsset = asset;
            this.formGroup.controls.symbol.setValue(this.selectedAsset.symbol);
            return;
          }
        });
      });

    FeatureService.dashboardFacade.selectedBankAccount$
      .pipe(
        filter(bankAccount => !!bankAccount),
        takeUntil(this.destroy$),
      )
      .subscribe(bankAccount => {
        CoreService.ngZone.run(() => {
          this.selectedBankAccount = bankAccount;
          this.formGroup.controls.bankAccountId.setValue(this.selectedBankAccount.id);
        });
    });

    this.formGroup.controls.accountId.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((result) => {
      this.accounts.forEach((account) => {
        if (account.id === result) {
          this.selectedAccount = account;
          FeatureService.dashboardFacade.selectAccount(this.selectedAccount);
          return;
        }
      });
    });

    CoreService.bankAccountFacade.bankAccounts$
      .pipe(
        filter(bankAccounts => !!bankAccounts),
        takeUntil(this.destroy$)
      )
      .subscribe((bankAccounts) => {
        CoreService.ngZone.run(() => {
          this.bankAccounts = bankAccounts;
          // if (this.formGroup && (this.paymentMethods = paymentMethods) && this.paymentMethods.length > 0) {
          //   this.formGroup.patchValue(
          //     {
          //       bankAccountId: this.selectedPaymentMethod?.id //TODO: possible race condition? use combine?
          //     },
          //     { emitEvent: false }
          //   );
          // }
        });
      });
  }

  chooseCreatePaymentMethodType() {
    this.step = 'CHOOSE_CREATE_PAYMENT_METHOD_TYPE';
  }

  createPaymentMethod() {
    const { ok, error$ } = CoreService.formService.validate(this.paymentMethodFormGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }

    this.step = 'BUSY';
    const value = this.paymentMethodFormGroup.value;
    const bankAccount: IBankAccount = {
      institution: value.institution,
      ach: {
        account: value.account,
        routing: value.routing
      },
      mask: value.account.slice(-4)
    };
    CoreService.actions$.pipe(ofType(BankAccountActions.createSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      bankAccount.name = bankAccount.institution;
      CoreService.store.dispatch(DashboardActions.selectBankAccount({ bankAccount }));
      CoreService.uiFacade.notification(`Successfully added account ending in ${bankAccount.mask}.`);
      this.step = 'FORM_GROUP';
    });

    CoreService.actions$.pipe(ofType(BankAccountActions.createFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
    });
    CoreService.bankAccountFacade.create(bankAccount);
  }

  openPlaid() {
    FeatureService.dashboardFacade.openPlaid();

    CoreService.actions$.pipe(ofType(DashboardActions.plaidSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.plaidBankAccounts = result.bankAccounts;
      this.step = 'CHOOSE_PLAID_ACCOUNT';
    });
  }

  choosePlaidPaymentMethod(bankAccount: IBankAccount) {
    this.step = 'BUSY';
    CoreService.bankAccountFacade.create(bankAccount);
    CoreService.actions$.pipe(ofType(BankAccountActions.createSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      CoreService.store.dispatch(DashboardActions.selectBankAccount({ bankAccount }));
      CoreService.uiFacade.notification(`Successfully added ${bankAccount.institution} ${bankAccount.name} ending in ${bankAccount.mask}`);
      this.step = 'FORM_GROUP';
    });

    CoreService.actions$.pipe(ofType(BankAccountActions.createFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
    });
  }

  buyOrSell() {
    const { ok, error$ } = CoreService.formService.validate(this.formGroup);
    if (!ok) {
      error$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.error = result;
      });
      return;
    }
    this.step = 'TWO_FACTOR';
  }

  confirmCode(code: string) {
    const value = this.formGroup.value;
    if (this.selectedTab == 'buy') {
      CoreService.transactionFacade.buy(value.accountId, value.symbol, value.bankAccountId, value.amount, null, code);
    } else {
      CoreService.transactionFacade.sell(value.accountId, value.symbol, value.bankAccountId, value.amount, null, code);
    }
    this.step = 'BUSY';

    CoreService.actions$.pipe(ofType(TransactionActions.buySuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.step = 'SUCCESS';
      this.transaction = result.transaction;
      CoreService.windowService.setTimeout(() => {
        this.step = 'TRANSACTION_DETAIL';
      }, 1600);
    });

    CoreService.actions$.pipe(ofType(TransactionActions.sellSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      CoreService.store.dispatch(TransactionActions.sign({ transaction: result.transaction, code, key: new Buffer(CoreService.storageService.getItem(StorageKeys.KEY), 'hex').toString() }));
    });

    CoreService.actions$.pipe(ofType(TransactionActions.signSuccess), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.transaction = result.transaction;
      CoreService.windowService.setTimeout(() => {
        this.step = 'TRANSACTION_DETAIL';
      }, 1600);
    });

    CoreService.actions$.pipe(ofType(TransactionActions.buyFailure, TransactionActions.sellFailure, TransactionActions.signFailure), take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.error = `apiErrors.${result.apiError.error}`;
      this.step = 'FORM_GROUP';
    });
  }

  sendSmsCode(isResendSms: boolean) {
    CoreService.transactionFacade.sendSmsCode();

    if (isResendSms) {
      CoreService.uiFacade.notification(`SMS code sent!`);
    }
  }

  selectAccount(account: IAccount) {
    FeatureService.dashboardFacade.selectAccount(this.selectedAccount);
  }

  resetSteps() {
    this.step = 'FORM_GROUP';
  }

  close() {
    uiCloseModal();
  }
}
