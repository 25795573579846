import { IEnvironment } from '../interfaces/environment.interface';
import { IHttpClient } from '../interfaces/http.interface';
import { BaseApi } from './base-api';

export class ExchangesApi extends BaseApi {
  constructor(protected environment: IEnvironment, protected httpClient: IHttpClient) {
    super(environment);
  }

  findBySymbol(symbol: string) {
    return this.httpClient.get(this.formatUrl(`/v${this.environment.vesto.version}/exchanges/find-by-symbol/${symbol}`), { headers: this.getHeaders() });
  }

  findCurrentBySymbolPair(symbol: string, pairedSymbol: string) {
    return this.httpClient.get(this.formatUrl(`/v${this.environment.vesto.version}/exchanges/find-current-by-symbol-pair/${symbol}/${pairedSymbol}`), { headers: this.getHeaders() });
  }

  findBySymbolPair(symbol: string, pairedSymbol: string) {
    return this.httpClient.get(this.formatUrl(`/v${this.environment.vesto.version}/exchanges/find-by-symbol-pair/${symbol}/${pairedSymbol}`), { headers: this.getHeaders() });
  }
}
