import {Component, OnInit} from '@angular/core';
import {SignUpBaseComponent} from '@vesto/xplat/features';
import {Router} from '@angular/router';

@Component({
  selector: 'gbl-sign-up',
  templateUrl: 'sign-up.component.html'
})
export class SignUpComponent extends SignUpBaseComponent implements OnInit {

  constructor(private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.router.url.endsWith('verify-email')) {
      this.step = 'LOADING';
    }
  }
}
