<div class="mb-3 text-right">
  <a class="text-sm underline" (click)="createInstitution()"><i class="fal fa-plus-circle"></i> Create Institution</a>
</div>
<div class="bg-white shadowed rounded mb-3 p-3">
  <ng-container *ngIf="institutions">
    <div *ngFor="let institution of institutions; let i = index">
      <div class="p-4 bg-trans-blue cursor-pointer"  [class.mt-3]="i > 0" (click)="selectInstitution(institution.id)">
        <div class="flex justify-between transition-all" [ngClass]="institution.id === selectedInstitution ? 'pb-2 border-bottom-light' : ''">
          <div class="text-lg font-bold" >{{institution.name}}</div>
          <i class="fal fa-chevron-down" [class.rotate-180]="institution.id === selectedInstitution"></i>
        </div>
        <div *ngIf="institution.id === selectedInstitution" class="grid grid-cols-4 gap-3 mt-3" [@expandCollapseDetails]>
          <div class="text-sm">
            <p>Created:</p>
            <b>{{ institution.created | date }}</b>
          </div>
          <div class="text-sm">
            <p>Updated:</p>
            <b>{{ institution.updated | date }}</b>
          </div>
          <div class="text-sm">
            <p>Status:</p>
            <b>{{ institution.status | titlecase }}</b>
          </div>
          <div class="text-sm">
            <p>URL:</p>
            <b>{{ institution.url }}</b>
          </div>

          <div class="text-sm">
            <p>Fee Type:</p>
            <b>{{ institution.feeType | titlecase }}</b>
          </div>
          <div class="text-sm" *ngIf="institution.feeType !== InstitutionFeeType.PERCENTAGE">
            <p>Fixed Fee:</p>
            <b>{{ institution.feeFixed | currency }}</b>
          </div>
          <div class="text-sm" *ngIf="institution.feeType !== InstitutionFeeType.FIXED">
            <p>Fee Percent:</p>
            <b>{{ institution.feePercentage }}%</b>
          </div>
          <div [ngClass]="institution.feeType !== InstitutionFeeType.BOTH ? 'col-span-2' : ''"></div>

          <div class="text-sm">
            <p>Default Buy Limit:</p>
            <b>{{ institution.defaultDailyBuyLimit }}</b>
          </div>
          <div class="text-sm">
            <p>Default Sell Limit:</p>
            <b>{{ institution.defaultDailySellLimit }}</b>
          </div>
          <div class="text-sm">
            <p>Default Receive Limit:</p>
            <b>{{ institution.defaultDailyReceiveLimit }}</b>
          </div>
          <div class="text-sm">
            <p>Default Transfer Limit:</p>
            <b>{{ institution.defaultDailyTransferLimit }}</b>
          </div>

          <div class="col-span-3 text-right">
            <button (click)="openOrGoToInstitution(institution)" class="button button-primary button-sm">Edit</button>
          </div>
          <div>
            <button (click)="openOrGoToCreateAndDeployTokens(institution.id)" class="button button-primary button-sm">Create and Deploy Tokens</button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
