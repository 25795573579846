import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { dashboardReducer } from './state/dashboard.reducer';
import { DashboardContactEffects, DashboardFacade, DashboardState } from './state';
import { EffectsModule } from '@ngrx/effects';
import { DashboardEffects } from './state/dashboard.effects';
import { DashboardAccountEffects } from './state/dashboard-account.effects';
import { DashboardInstitutionEffects } from './state/dashboard-institution.effects';
import { DashboardTransactionEffects } from './state/dashboard-transaction.effects';
import { DashboardUserEffects } from './state/dashboard-user.effects';
import { DashboardMerchantEffects } from "./state/dashboard-merchant.effects";

@NgModule({
  imports: [
    StoreModule.forFeature('dashboard', dashboardReducer, {
      initialState: DashboardState.initialState
    }),
    EffectsModule.forFeature([DashboardMerchantEffects, DashboardContactEffects, DashboardEffects, DashboardAccountEffects, DashboardInstitutionEffects, DashboardTransactionEffects, DashboardUserEffects])
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [DashboardFacade]
})
export class DashboardModule {}
