<div class="dialog-header">
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <h3 class="section-number"><img [src]="token?.blockchain | blockchain" [style.width]="token?.blockchain === Blockchain.ETHEREUM ? '16px' : '32px'" class="mr-2 -mt-2" /> Change Currency APY</h3>
</div>
<mat-dialog-content>
  <form [formGroup]="formGroup" (ngSubmit)="changeToken()" *ngIf="!confirmCode">
    <div class="vesto-input vesto-input-inline mb-4">
      <label for="apy">{{ token?.symbol }} APY</label>
      <gbl-input id="apy" focusMe="true" formControlName="apy" mask="percent"></gbl-input>
    </div>
    <div class="vesto-input vesto-input-inline mb-3">
      <label for="redeemAddress">Redeem Address</label>
      <input type="text" formControlName="redeemAddress" id="redeemAddress" name="redeemAddress" />
    </div>

    <gbl-alert [type]="error" [message]="error"></gbl-alert>

    <div class="text-right mt-3">
      <button class="button button-primary" type="submit">Change</button>
    </div>
  </form>

  <div *ngIf="confirmCode" class="mt-4">
    <gbl-two-factor></gbl-two-factor>
  </div>
</mat-dialog-content>
